import { FormValidation } from "components/form/FormikForm"
import { useEffect, useState } from "react"
import { Input } from "reactstrap"

interface ListImagesProps {
  validation: FormValidation
  dataLoadable: any
  fieldValue: string
}

export const ListImages = (props: ListImagesProps) => {
  const { validation, dataLoadable, fieldValue } = props
  const [imageState, setImageState] = useState('')

  useEffect(() => {
    let listImages: string[] = []
    let product = validation.values
    // product?.publicationChannels?.forEach((item: any) => {
    //   product?.productType?.imageSuffixes?.forEach((imageSuffix: string) => {
    //     let image = `${item?.imageBaseUrl}/${product?.artwork?.sku}/${product?.artwork?.sku}${imageSuffix}`
    //     listImages.push(image)
    //   })
    // })

      product?.productType?.imageSuffixes?.forEach((imageSuffix: string) => {
        listImages.push(imageSuffix)
      })
    


    let imageString = ''
    listImages?.forEach((li: any, index: number) => imageString += li + '\n')
    setImageState(imageString)
  }, [ validation.values?.artwork]);

  return (
    <>
      <Input type="textarea" rows="6" value={imageState} disabled={true}/>
    </>
  )
}