import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Label, Row } from "reactstrap";
import { t } from "../../core/translations";
import { Loadable, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { fulfillProductIdSelector } from "../../data/atoms/fulfill-product.atom";
import Breadcrumb from "components/layout/Breadcrumb";
import { LoadableComponent } from "../../components/common/LoadableComponent";
import { FormikHelpers, useFormik } from "formik";
import { Labels } from "../../common/labels";
import { FulfillProductDto } from "../../data/services/fulfill-product.service";
import { FulfillProductActions } from "../../data/actions/fulfill-product.action";
import { Toasts } from "../../core/notification/notifications";
import { FormikInput } from "../../components/form/FormikInput";
import Select from "react-select";
import { FulfillServiceActions } from "../../data/actions/fulfill-service.action";
import { FulfillServiceDto } from "data/services/fulfill-service.service";
import * as _ from "lodash";
import { isEmpty, pick } from "lodash";
import { FormikForm, FormValidation } from "../../components/form/FormikForm";
import { Forms } from "../../utils/forms";
import { FormLayout, FormLayoutMain } from "../../components/layout/FormLayout";
import { Option } from "../../common/types";
import { FulfillVariantDto } from "../../data/services/fulfill-variation.service";
import { Metafield } from "../../types/metafield.type";
import { fulfillVariantByFulfillProductIdSelector } from "../../data/atoms/fulfill-variation.atom";
import { fulfillProductSchema } from "./validations/fulfill-product.validation";
import { RouteNames } from "../../routes";
import { BiLinkExternal } from "react-icons/bi";
import { SearchableSelect } from "components/input/SearchableSelect";
import ActionButton from "components/input/ActionButton";
import { Messages } from "common/messages";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { withPermission } from "common/hooks/use-permission";
import { CreatableMultiValues } from "components/common/CreatableMultiValues";
import { FulfillVariantTable } from "./components/fulfill-variant-table";
import { preprocessPage } from "utils/utils";
import { FaRegCopy } from "react-icons/fa";
import { createLinkEmbedParam, createUrlEmbed } from "utils/embed";

export interface FulfillProductPageProps {
  data?: FulfillProductDto;
}

export const FulfillProductPage = (props: FulfillProductPageProps) => {
  const history = useHistory()
  const params = useParams<Record<string, any>>();
  const isEditing = +params.id > 0;
  const location = useLocation();
  const parentFulfillProduct = (location.state as any)?.fulfillProduct
  const [fulfillProduct, setFulfillProduct] = useState<any>({});
  const variantsLoadable = useRecoilValueLoadable(
    fulfillVariantByFulfillProductIdSelector(
      withPermission(ActionEntities.read, ResourceEntities.fulfillVariantEntity) && params.id
    )
  );
  const dataLoadable = useRecoilValueLoadable(fulfillProductIdSelector(params.id));
  const refreshVariants = useRecoilRefresher_UNSTABLE(fulfillVariantByFulfillProductIdSelector(params.id))

  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(fulfillProductIdSelector(params.id))
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[params.id])

  useEffect(() => {
    if (variantsLoadable.state == "hasValue") {
      setFulfillProduct((current: any) => ({
        ...current,
        variants: variantsLoadable.contents.pageItems || []
      }));
    }
  }, [variantsLoadable.state]);

  useEffect(() => {
    if (dataLoadable.state == "hasValue") {
      setFulfillProduct((current: any) => ({
        ...current,
        ...(dataLoadable.contents || {})
      }));
    }
  }, [dataLoadable.state]);

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.fulfill_product), model: fulfillProduct})
  }, [fulfillProduct])

  
  useEffect(() => {
    const newFulfillProduct = pick(parentFulfillProduct, ['baseCost', 'name', 'variantOptions', 'productTypeKey', 'optionKeys', 'fulfillService'])
    setFulfillProduct(newFulfillProduct)
  }, [parentFulfillProduct])


  const validation = useFormik({
    initialValues: {...fulfillProduct, parentId: parentFulfillProduct?.id},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: fulfillProductSchema,
    onSubmit: (value, helpers) => handleSubmit(value, helpers)
  });

  const handleSubmit = async (values: FulfillProductDto, helpers: FormikHelpers<any>) => {
    fulfillProduct.variants = fulfillProduct.variants?.map((variant: FulfillVariantDto) => {
      return variant.id ? variant : { baseCost: fulfillProduct.baseCost, ...variant }
    })
    let filteredValues = filterValues(values)
    let changedData = isEditing ? Forms.getChangedValues(filteredValues, fulfillProduct, ["variants"]) : filteredValues;

    try {
      helpers.setErrors({});
      helpers.setSubmitting(true);

      let savedData = await FulfillProductActions.save(fulfillProduct.id || "", changedData);
      if (savedData?.id) {
        setFulfillProduct((current: any) => ({
          ...current,
          ...savedData
        }));
        refreshVariants()
        Toasts.success(t(Messages.save_fulfill_product_successfully));

        // TODO optimize not using setTimeout
        !isEditing && setTimeout(() => {
          history.replace(RouteNames.FULFILL_PRODUCT.replace(":id",savedData.id));
        }, 100);
      }
    } catch (e) {
      helpers.setSubmitting(false);
    }
  };

  const filterValues = (values: any) => {
    let newValues = values
    let variantFiltered = values?.variants 
      ? values?.variants?.map((v: any) => {
        let tempV = v
        if (v.hasOwnProperty('numberKey')) {
          delete tempV['numberKey'];
        }
        if (v.hasOwnProperty('create')) {
          delete tempV['create'];
        }
        return tempV
      })
      : null
    if(variantFiltered) {
      newValues = {...values, variants: variantFiltered}
    }
    return newValues
  }

  const handleClone = () => {
    history.push(createLinkEmbedParam(`${RouteNames.FULFILL_PRODUCTS}/create`).to, { fulfillProduct })
  }

  const handleCreateProductTemplate = () => {
    history.push(createLinkEmbedParam(`${RouteNames.PRODUCTS_TEMPLATES}/create`).to, { fulfillProduct })
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{paddingBottom: 100}}>
        <Container>
          <Breadcrumb goBack={RouteNames.FULFILL_PRODUCTS} title={t(Labels.edit_fulfill_product)}>
            { !isEditing ? null :
              <WithPermission action={ActionEntities.update} resource={ResourceEntities.productTemplateEntity}>
                <Button className="me-2" color="success" onClick={() => handleCreateProductTemplate()}>
                  <p className="mb-0" style={{color: 'white'}}>{t(Labels.create_product_template)}</p>
                </Button>
              </WithPermission>
            }
            { isEditing && (
              <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillProductEntity}>
                <Button className="me-2" color="info" onClick={handleClone}>
                  <i className="bx bx-duplicate font-size-16 align-middle me-1"></i>
                  {t(Labels.clone)}
                </Button>
              </WithPermission>
            )}
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.fulfillProductEntity}>
              <ActionButton
                onClick={() => validation.submitForm()}
                color="success"> 
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable}>
            <FormikForm
              validation={validation}
            >
              <FormLayout>
                <FormLayoutMain>
                  <FulfillProductForm validation={validation} fulfillVariantsLoadable={variantsLoadable} isEditing={isEditing}/>
                </FormLayoutMain>
              </FormLayout>
            </FormikForm>
          </LoadableComponent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export interface FulfillProductFormProps {
  validation: FormValidation;
  fulfillVariantsLoadable: Loadable<any>;
  isEditing?: boolean
}

export const FulfillProductForm = (props: FulfillProductFormProps) => {
  const { validation } = props;

  const getVariantOptions = () => {
    let variantOptions = validation.values.variantOptions?.filter((option: any) => option.name) || []
    if (!variantOptions.length || variantOptions[variantOptions.length - 1]?.name) {
      variantOptions.push({});
    }
    return variantOptions
  }

  const removeOptions = (removeIndex: number) => {
    let newOptions = validation.values.variantOptions?.filter((_item: any, index: number) => index != removeIndex)
    let newVariant = validation.values?.variants?.map((variant: any, index: number) => {
      let newVariantOptions = variant?.variantOptions?.filter((vo: any) => 
        newOptions?.filter((no: any) => no?.name == vo?.name)?.length > 0
      )
      return {...variant, variantOptions: newVariantOptions}
    })
    validation.setFieldValue('variants', newVariant)
    validation.setFieldValue("variantOptions", newOptions);
  }

  const createUpdateOptions = (index: number, name?: string, values?: Option | Option[], options?: Metafield[]) => {
    let newValues = Array.isArray(values) ? values?.map(value => value.value || value) : values?.value || values;
    let newOptions: Metafield[] = [...(options || [])];
    let option = {
      name: name ?? newOptions[index]?.name,
      value: newValues || newOptions[index]?.value || []
    };
    newOptions[index] = option;
    newOptions = newOptions.filter(item => item.name);
    return newOptions;
  }

  // const handleChangeOption = () => {
  //   let listVariants: FulfillVariantDto[] = validation.values?.variants
  //   let variantOptions = validation.values?.variantOptions
  //   let newListVariants: Array<any> = []
  //   listVariants?.forEach((variant: any, index: number) => {
  //     let variantOptionsFiltered = variant?.variantOptions?.map((vo: VariantOptionsType) => {
  //       let temp = (variantOptions?.filter((v: any) => v.name == vo.name)?.length > 0
  //         && variantOptions?.filter((v: any) => v.name == vo.name))[0]
  //       if (!temp?.value?.includes(vo.value)) {
  //         return {name: vo.name, value: null}
  //       }
  //       return vo
  //     })
  //     newListVariants.push({
  //       ...listVariants[index],
  //       variantOptions: variantOptionsFiltered
  //     })
  //   })
  //   validation.setFieldValue('variants', newListVariants)
  // }
  //
  // useEffect(() => {
  //   handleChangeOption()
  // }, [validation.values?.variantOptions])

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle>{t(Labels.fulfill_product)}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <FormikInput
                name="name"
                type="text"
                label={t(Labels.name)}
                validation={validation}
                placeholder={t(Labels.product_type_hint)}
              />
            </Col>
            <Col>
              <FormikInput
                name={"baseCost"}
                type={"number"}
                label={t(Labels.base_cost)}
                style={{ height: 38 }}
                placeholder={t(Labels.base_cost_hint)}
                required={true}
                validation={validation}
                value={validation.values.baseCost}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormikInput
                validation={validation}
                name="fulfillService"
                label={t(Labels.fulfill_services)}
                required={true}
                customInput={({ handleChange, handleBlur, errorData }) => (
                  <SearchableSelect
                    cacheOptions
                    defaultOptions={true}
                    value={validation.values.fulfillService}
                    getOptionValue={(option: FulfillServiceDto) => "" + option?.id}
                    getOptionLabel={(option: FulfillServiceDto) => t(option?.name)}
                    loadOptions={FulfillServiceActions.search}
                    onBlur={handleBlur}
                    onChange={(value: any) => handleChange(value)}
                    styles={errorData.reactSelectErrorStyle}
                  />
                  
                )}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>{t(Labels.options)}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              {
                getVariantOptions().map((option: Option, index: number) => 
                <VariantOptionLine  
                  key={`${option.value}-${index}`} 
                  option={option}
                  createUpdateOptions={createUpdateOptions}
                  removeOptions={removeOptions}
                  validation={validation}
                  index={index}
                  // handleChangeOption={handleChangeOption}
                />)
              }
            </Col>
          </Row>
          <div className="dropdown-divider"></div>
          <Col className="mt-2">
            <FormikInput
              validation={validation}
              name="productTypeKey"
              label={t(Labels.product_type_option)}
              customInput={({ handleChange, handleBlur, errorData }) => (
                <Select
                  isSearchable={true}
                  options={validation.values.variantOptions}
                  value={validation.values?.productTypeKey ? { name: validation.values?.productTypeKey } : undefined}
                  getOptionValue={(option: any) => option.name || ""}
                  getOptionLabel={(option: any) => t(option.name || "")}
                  maxMenuHeight={120}
                  onBlur={handleBlur}
                  onChange={(value: any) => {
                    handleChange(value.name || value);
                  }}
                  styles={errorData.reactSelectErrorStyle}
                />
              )}
            />
          </Col>
        </CardBody>
      </Card>
      {
        !props.isEditing ? null : (
          <Card>
            <CardHeader>
              <CardTitle>{t(Labels.fulfill_variants)}</CardTitle>
            </CardHeader>
            <CardBody>
              <LoadableComponent loadable={props.fulfillVariantsLoadable}>
                <div className="d-flex" style={{ overflowX: "auto" }}>
                  <Col>
                    <FulfillVariantForm
                      data={validation.values.variants}
                      validation={validation}
                    />
                  </Col>
                </div>
              </LoadableComponent>
            </CardBody>
          </Card>
        )
      }
    </Fragment>
  );
};

interface FulfillVariantFormProps {
  data: any[],
  validation: FormValidation
}

const FulfillVariantForm = (props: FulfillVariantFormProps) => {
  const { validation } = props

  const createVariants = (currentVariants = validation.values.variants) => {
    let variants = !currentVariants ? [] : [...currentVariants]
    if (!variants?.length || !isEmpty(variants[variants.length - 1])) {
      variants.push({} as any)
    }
    return variants
  }

  const [variants, setVariants] = useState(createVariants())
  useEffect(() => setVariants(createVariants()), [validation.values.variants])

  const createNewVariants = (prev: any, newVariant: any, newIndex: number) => {
    let newVariants = []
    if (Array.isArray(newVariant)) {
      newVariants = newVariant
    }

    if (!newVariant) {
      newVariants = prev.map((item: any) => item)
    } else if (newIndex >= prev.length) {
      let newVariants = prev.map((item: any) => item)
      newVariants.push(newVariant)
    } else {
      newVariants = prev.map((item: any, preIndex: any) => {
        return preIndex == newIndex ? {
          ...item,
          ...newVariant
        } : item
      })
    }

    return newVariants
  }

  const onChange = (newVariant?: any, index: number = -1, submitChange = true) => {
    if(submitChange) {
      handleInputBlur(createNewVariants(variants, newVariant, index))
    } else {
      setVariants((prev: any) => createNewVariants(prev, newVariant, index))
    }
  }

  const handleInputBlur = (value = variants) => {
    let variantSkuCounts = value.reduce((curr: any, variant: any) => ({
      ...curr,
      [variant.sku]: (curr[variant.sku] || 0) + 1
    }), {})

    let newValues = value
      .filter((variant: any) => !isEmpty(variant))
      .map((variant: any) => variantSkuCounts[variant.sku] > 1 ? { ...variant, __duplicated: true } : (variant.__duplicated ? _.omit(variant,["__duplicated"]) : variant) );
    setVariants(newValues)
    validation.setFieldValue('variants', newValues)
  }

  const handleRemoveLine = (index: number) => {
    let variant = variants[variants.length - 1];
    if(variant.id || (index == variants.length - 1 && !variant?.sku)) return

    let newVariants = variants.filter((item: any, i: number) => i != index)
    handleInputBlur(newVariants)
  }
  
  return (
    <Fragment>
      <FulfillVariantTable validation={validation}/>
      {/* {
        variants?.map((variant: FulfillVariantDto, variantIndex: number) => (
          <Fragment key={`${variantIndex}${variant.id}`}>
            <FulfillVariantLine
              variant={variant}
              variantIndex={variantIndex}
              validation={validation}
              onChange={onChange}
              onInputBlur={handleInputBlur}
              handleDelete={handleRemoveLine}
            />
          </Fragment>
        ))
      } */}
    </Fragment>
  )
}

interface FulfillVariantLineProps {
  variantIndex: number,
  validation: FormValidation,
  variant: FulfillVariantDto
  onChange: (value: any, index: number, submitChange: boolean) => void
  onInputBlur?: () => void
  handleDelete?: (index: number) => void
}

const FulfillVariantLine = (props: FulfillVariantLineProps) => {
  let { variantIndex, validation } = props;
  let [variant, setVariant] = useState({ ...props.variant })

  useEffect(() => setVariant(props.variant), [props.variant])

  const onVariantsChange = (newVariant: any, submitChange = true) => {
    setVariant(newVariant)
    props.onChange(newVariant, variantIndex, submitChange)
  };

  const handleInputBlur = () => {
    props.onInputBlur && props.onInputBlur()
  }

  const createUpdateOptions = (index: number, name: string, values: Option | Option[], currentOptions: Metafield[] = []) => {
    if (!name) return currentOptions;

    let current = [...currentOptions];
    let newValues = Array.isArray(values) ? values?.map(value => value.value || value) : values?.value || values;
    let newOptions: Metafield[] = [...(currentOptions || [])];
    let option = {
      name: name,
      value: newValues || newOptions[index]?.value || []
    };

    newOptions[index] = option;
    return newOptions;
  };

  const createUpdateVariants = (value: FulfillVariantDto) => {
    let currentVariant = validation.values.variants && validation.values.variants[variantIndex] || {} as Option;
    let newVariant = {
      ...currentVariant,
      ...(value || {})
    };
    if(!newVariant?.baseCost){
      newVariant.baseCost = validation.values.baseCost
    }

    // Logger.debug("on change", value);

    return newVariant
  };

  return (
    <div className="d-flex mb-3">
      <FormikInput
        name={"variants"}
        key={`variant-${variantIndex}`}
        label={variantIndex == 0 ? t(Labels.sku) : ""}
        style={{ height: 38, minWidth: 312 }}
        placeholder={t(Labels.fulfill_sku_hint)}
        validation={validation}
        value={variant.sku}
        hiddenError={true}
        invalid={(variant as any).__duplicated}
        onChange={(value: any) => onVariantsChange(createUpdateVariants({ sku: value.trim() }), false)}
        onBlur={handleInputBlur}
      >
      </FormikInput>
      {
        validation.values.variantOptions?.map((option: Metafield, index: number) => (!option.name ? null :
            <Row key={index} className="ps-1" style={{ minWidth: index == 0 ? 200 : 165 }}>
              <FormikInput
                validation={validation}
                name="variants"
                label={variantIndex == 0 ? option.name : ""}
                hiddenError={true}
                onChange={(value: any) => {
                  onVariantsChange(createUpdateVariants(
                    {
                      variantOptions: createUpdateOptions(index, option.name, value, variant.variantOptions)
                    }
                  ));
                }}
                customInput={({ handleChange, handleBlur, errorData }) => (
                  <Select
                    isSearchable={true}
                    menuPortalTarget={document.body}
                    blurInputOnSelect={true}
                    openMenuOnFocus={true}
                    options={Forms.createSelectOptions(option.value)}
                    value={variant.variantOptions?.filter(vo => vo && vo.name == option?.name).pop()}
                    getOptionValue={(option: any) => option?.value || option}
                    getOptionLabel={(option: any) => t(option?.value || option)}
                    onBlur={handleBlur}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    onChange={(value) => handleChange(value)}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Row>
        ))
      }
      <div className="ps-1">
        <FormikInput
          name={"variants"}
          type={"number"}
          hiddenError={true}
          label={variantIndex == 0 ? t(Labels.base_cost) : ""}
          defaultValue={validation.values.baseCost}
          style={{ height: 38, width: 80 }}
          placeholder={t("0")}
          validation={validation}
          value={variant.baseCost}
          onChange={(value: any) => {
            onVariantsChange(createUpdateVariants({ baseCost: +value }));
          }}
        />
      </div>
      <Col className="d-flex align-items-end mb-2">
        {
          variant.id ? (
          <div className={`text-center`} style={{ width: 30 }}>
              <Link target="_blank" to={{pathname: createUrlEmbed(`${RouteNames.FULFILL_VARIANTS}/${variant.id}`)}} >
                <BiLinkExternal  size={16}/>
              </Link>
          </div>) : (
            <div className={`text-center`} style={{ width: 30 }}>
            <Link
              to={"#"}
              onClick={() => props.handleDelete && props.handleDelete(variantIndex)} >
              <i className="mdi mdi-delete font-size-18" />
            </Link>
          </div>
          )
        }
      </Col>
    </div>
  );
};

const VariantOptionLine = (props: any) => {
  const {option, createUpdateOptions, removeOptions, validation, index} = props
  const [valueName, setValueName] = useState(option.name)

  useEffect(() => {
    setValueName(option.name)
  }, [option.name])

  const handleBlur = () => {
    validation.setFieldValue("variantOptions", createUpdateOptions(index, valueName, undefined, validation.values.variantOptions));
  }

  const handleCopy = useCallback((e: any, value: string) => {
    e.preventDefault();
    navigator.clipboard.writeText(value)
    Toasts.info(Labels.copied_to_clipboard)
  }, [])
  return (
    <Row className="mb-3">
      <Col>
        <Row>
          <Col xs={12} sm={3} className="pe-0 pe-1">
            <Col>
              <Label>{t(Labels.option_name)}</Label>
            </Col>
            <Col>
              <FormikInput
                name={"option.name"}
                style={{ height: 38 }}
                placeholder={t(Labels.option_name_hint)}
                validation={validation}
                value={valueName}
                hiddenError={true}
                onChange={(value: any) => {
                  setValueName(value);
                }}
                onBlur={handleBlur}
              >
              </FormikInput>
            </Col>
          </Col>
          <Col xs={12} sm={9} className="px-0">
            <Col >
              <Label>{t(Labels.option_values)}</Label>
              <a className="ms-1" onClick={e => {
                if(option.value?.join(',')){
                  handleCopy(e, option.value?.join('\n'))
                }
                else {
                  Toasts.warning(Labels.no_value_to_copy )
                }
              }}><FaRegCopy size={16} /></a>
            </Col>
            <Col >
              <FormikInput
                validation={validation}
                name="options.value"
                hiddenError={true}
                customInput={({ handleChange, handleBlur, errorData }) => (
                  <CreatableMultiValues 
                    handleBlur={handleBlur}
                    value={option.value?.map((item: string) => ({value: item, label: item}))}
                    options={option.value?.map((item: string) => ({value: item, label: item}))}
                    onChange={(values: any) => {
                      let value = createUpdateOptions(index, undefined, values.map((value: any) => value.value || value), validation.values.variantOptions)
                      let valueConverted = value?.map((item: any) => ({...item, value: (Forms.handleMultiValue(item?.value)?.map((i: any) => i.value || i))}))
                      handleChange(valueConverted, "variantOptions");
                    }}
                  />
                )}
              />
            </Col>
          </Col>
        </Row>
      </Col>
      <Col xs={"auto"} className="d-flex align-items-center justify-content-end flex-column">
        <div className={`text-center mb-1 ${option.name ? "" : "invisible"}`}>
          <Link
            to={"#"}
            onClick={() => removeOptions(index)}
          >
            <i className="mdi mdi-delete font-size-18" />
          </Link>
        </div>
      </Col>
    </Row>
  )
}
