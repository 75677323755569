export const RestApiErrors = {
  unknown: 'Something went wrong !!!',
  network_error: 'Không thể kết nối đến máy chủ.',
  forbidden: 'Bạn không có quyền truy cập thực thực hiện tác vụ.',
  unauthorized: 'Phiên làm việc đã hết hạn, vui lòng đăng nhập lại.',
  internal_server_error: 'Hệ thống xử lý yêu cầu không thành công!',
  not_found: 'Không thể tải dữ liệu từ máy chủ',
  bad_request: 'Dữ liệu không hợp lệ',
  unknown_error: 'Hệ thống không thể xử lý yêu cầu. Vui lòng thử lại sau!',
  server_unknown_error: 'The request cannot be handled. Error detail {{code}}',
  er_duplicate_entry: 'Dữ liệu đã tồn tại. Vui lòng kiểu tra lại dữ liệu',
};
