import { CardText, CardTitle, Col, Row } from "reactstrap";
import { Component } from "react";

export interface TwoColumnLayoutProps {
  title: string | Component,
  subtitle?: string | Component
  menu?: Component
  single?: boolean, // Hide divider on single mode
  children: Component | any
  type?: "two_columns" | undefined
}

export const SectionLayout = (props: TwoColumnLayoutProps) => {
  let { children } = props;

  return (
    <Row xs={12} className="pt-3">
      <Col xs={12} md={4} className="pe-3">
        <Col>
          <CardTitle>
            {props.title}
          </CardTitle>
          {
            !props.subtitle ? null : (
              <CardText> {props.subtitle} </CardText>
            )
          }
        </Col>
      </Col>
      <Col xs={12} md={8} className="ps-2 mb-2">
        { children }
      </Col>
      {
        !props.single && <div className="dropdown-divider"></div>
      }
    </Row>
  );
};