import { Labels } from "common/labels"
import { WithPermission } from "components/common/WithPermission"
import Breadcrumb from "components/layout/Breadcrumb"
import RemoteTable, { createDeleteAction } from "components/table/RemoteTable"
import { ActionOption } from "components/table/SelectedRowAction"
import { SearchOption } from "components/table/TableFilter"
import { t } from "core/translations"
import { ProductTemplateActions } from "data/actions/product-template.action"
import { productTemplatesQueryState, productTemplatesSelector } from "data/atoms/product-template.atom"
import { ProductTemplateDto } from "data/services/product-template.service"
import React, { useEffect } from "react"
import { ColumnDescription } from "react-bootstrap-table-next"
import { Link } from "react-router-dom"
import { Col, Row, Button, Container } from "reactstrap"
import { RouteNames } from "routes"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { createDocumentPageName } from "utils/utils"
import EmptyImage from '../../assets/images/empty_image.png'
import { createLinkEmbedParam } from "utils/embed"

const ProductTemplatesPage = () => {
  
  useEffect(() => { document.title = createDocumentPageName(t(Labels.product_templates)) }, [])

  let columns: ColumnDescription[] = [
    {
      dataField: "imageUrls",
      text: t(Labels.image),
      headerStyle: { width: 120 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <img
            style={{ width: '100px', height: '100px', objectFit:"cover", borderRadius: '5px'}}
            src= {cell?.length ? cell[0] : EmptyImage}
          />
        </>;
      }
    },
    {
      dataField: "name",
      text: t(Labels.name),
      headerStyle: { width: 550 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Link style={{ fontWeight: 600, color: 'unset' }} to={createLinkEmbedParam(`${RouteNames.PRODUCTS_TEMPLATES}/${row?.id}`).to} className="product-name">
            {row?.name}
          </Link>
        </>;
      }
    },
    {
      dataField: "categories",
      text: t(Labels.categories),
      headerStyle: { width: 250 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: ProductTemplateDto) => {
        return row.categories?.length ? row.categories.join(', ') : ''
      }
    },
    {
      dataField: "tags",
      text: t(Labels.tags),
      headerStyle: { width: 250 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: ProductTemplateDto) => {
        return row.tags?.length ? row.tags.join(', ') : ''
      }
    },
    {
      dataField: "fulfillProduct",
      text: t(Labels.fulfill_product),
      headerStyle: { width: 170 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return row?.fulfillProduct?.name || "";
      },
      hidden: true,
    },
    {
      dataField: "fulfillService",
      text: t(Labels.fulfill_service),
      headerStyle: { width: 130 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return row?.fulfillService?.name || "";
      },
      hidden: true,
    },
    {
      dataField: "price",
      text: t(Labels.price),
      headerStyle: { width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: ProductTemplateDto) => (
        <p className="m-0">{`$${row?.regularPrice || row?.minPrice} - ${row?.salePrice || row?.maxPrice}`}</p>
        )
      },
    ];
    // <Row className="d-flex flex-column">
    //   <Col>{row?.regularPrice ? `$${row?.regularPrice}` : null}</Col>
    //   <Col>{row?.salePrice ? `$${row?.salePrice}` : null}</Col>
    // </Row>
    
  let searchOptions: SearchOption[] = [
    {
      field: "name",
      label: t(Labels.product_template_name)
    },
  ];

  const actionOptions: ActionOption[] = [
    createDeleteAction(ProductTemplateActions, ResourceEntities.productTemplateEntity)
  ];


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={t(Labels.product_templates)}
          >
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.productTypeEntity}>
              <Link {...createLinkEmbedParam(`${RouteNames.PRODUCTS_TEMPLATES}/create`)}>
                <Button
                  type="button"
                  color="success"
                >
                  <i className="bx bx-plus font-size-16 align-middle"></i>
                  {t(Labels.create)}
                </Button>
              </Link>
            </WithPermission>
          </Breadcrumb>
          <div className="remote-table-product-template">
            <RemoteTable
              dataLoader={[productTemplatesSelector, productTemplatesQueryState]}
              searchOptions={searchOptions}
              filterOptions={[]}
              tabOptions={[]}
              actionOptions={actionOptions}
              searchHint={t(Labels.search_by_name)}
              defaultSearchField={"name"}
              keyField={"id"}
              columns={columns}
              filterKey={'productTemplates'}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductTemplatesPage