import { TeamDto } from './../../pages/settings/components/teams.select';
import { apiBaseUrls } from "../rest-api-config"
import { CrudService } from "./crud.service"
import { RequestOptions } from "../../core/rest-api/rest.api"

export interface Setting {
  key: string
  value?: any
}

export interface SettingDto {
  value: {
    customDataKeys?: Array<any>
    variantOptionKeys?: Array<any>
    sku: {
      noneProductSkus?: Array<any>
      skuCleanerSplitter?: Array<any>
    }
  }
  teamId: string | number
}

export interface NewStorageSettingDto {
  teamId?: string,
  value: {
    [key: string]: {
      config: {
        drivedId: string,
        credentials: any,
        readOnlyDriveIds?: Array<any>,
        credentialsFileName?: string
      }
    }
  }
}

export interface StorageSettingDto {
  type: string,
  config: {
    drivedId: string,
    credentials: any,
    readOnlyDriveIds?: Array<any>,
    credentialsFileName?: string
  }
}

export interface UpdateStorageSettingDto {
  value?: StorageSettingDto
}

export interface UpdateSettingDto {
  // key?: string
  // name?: string
  teamId?: string
  value?: SettingDto
  // desc?: string
}

export const SettingApiPaths = {
  BASE: "/common/settings",
  WITH_ID: "/common/settings/{id}",
  QUERY: "/common/settings/query",
  BULK: "/common/settings/bulk",
}

export class SettingService extends CrudService<Setting, Setting, Setting> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: SettingApiPaths.WITH_ID,
        updateById: SettingApiPaths.WITH_ID,
        deleteById: SettingApiPaths.WITH_ID,
        query: SettingApiPaths.QUERY,
        base: SettingApiPaths.BASE,
        deleteMany: SettingApiPaths.BULK,
        updateMany: SettingApiPaths.BULK,
      },
    })
  }

  async sync(key: string, options?: RequestOptions) {
    let response = await this.restApi.get(
      SettingApiPaths.WITH_ID,
      options || {}
    )
    return this.returnDataOrThrow(response)
  }

  async updateOneByKey(
    key: string,
    updateData: UpdateSettingDto,
    options: RequestOptions = {}
  ) {
    let response = await this.restApi.put(this.pathNames.updateById, {
      params: { id: key }, // gan ten gia tri key la id
      data: updateData,
      ...options,
    })
    return this.returnDataOrThrow(response)
  }

  async updateOneStorageSettingByKey(
    key: string,
    updateData: UpdateStorageSettingDto,
    options: RequestOptions = {}
  ) {
    let response = await this.restApi.put(this.pathNames.updateById, {
      params: { id: key }, // gan ten gia tri key la id
      data: updateData,
      ...options,
    })
    return this.returnDataOrThrow(response)
  }
}

export const SettingServices = new SettingService()
