import { Labels } from "common/labels"
import ActionButton from "components/input/ActionButton"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import { t } from "core/translations"
import { forwardRef, useState } from "react"
import { format } from "date-fns";
import { FormGroup, Input, Label } from "reactstrap"
import { FulfillmentOrderActions } from "data/actions/fulfillment-order.action"
import { useSetRecoilState } from "recoil"
import { portableModalState } from "data/atoms/app.atom"
import { DateInput } from "./date-input"
import { Toasts } from "core/notification/notifications"



export const UpdateFulfillmentDate = (props: any) => {
    const { selectedIds, refresh } = props
    const [date, setDate] = useState<any>()

  const setPortableModal = useSetRecoilState(portableModalState)

    return (
      <>
        <ModalContent>
          <FormGroup>
            <Label>{Labels.change_fulfillment_date}</Label>
            <DateInput value={date} onChange={setDate} className="w-100 inputDatePicker" />
          </FormGroup>
            
        </ModalContent>
        <ModalButtons hiddenConfirmButton={true}>
          <ActionButton className="btn btn-success" onClick={async () => {
            if(date){
              const result = await FulfillmentOrderActions.bulkUpdateDate(selectedIds, date)
              if(result){
                refresh && refresh()
                setPortableModal({open: false, title:""})
              }
            }
            else{
              Toasts.error(Labels.fulfilmentDate_required)
            }
          }}>{t(Labels.save)}</ActionButton>
        </ModalButtons>
      </>
    )
  }