import { Labels } from "common/labels"
import { SelectOption } from "common/types"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import { t } from "core/translations"
import { FulfillOrderActions } from "data/actions/fulfill-order.action"
import { isEmpty } from "lodash"
import { useState } from "react"
import Select from "react-select"
import { FulfillDesignStatusName, FulfillStatusIssueName, FulfillStatusName } from "types/fulfill-status.type"

export interface BulkChangeFulfillStatusSelectProps {
  options?: Record<string, string> | SelectOption[]
  placeholder?: string
  label: string
  keyValue: string
  setValueSubmit: any
}

export const BulkChangeFulfillStatusSelect = ({
  options = [],
  placeholder,
  label,
  keyValue,
  setValueSubmit,
}: BulkChangeFulfillStatusSelectProps) => {
  const [value, setValue] = useState<any>(undefined)
  options = Array.isArray(options) ? options : Object.entries(options).map(([key, value]) => ({ label: value, value: key }))

  const handleSetSubmitValue = (value: any) => {
    setValueSubmit((prev: any) => ({
      ...prev,
      [keyValue]: value,
    }))
    setValue(value?.value === "NO_OPTION" ? null : value)
  }
  return (
    <div className="mb-3">
      <h6>{label}</h6>
      <Select
        placeholder={placeholder}
        name={keyValue}
        getOptionLabel={option => t(option.label)}
        getOptionValue={option => option.value}
        value={value}
        options={options}
        onChange={option => handleSetSubmitValue(option)}
      />
    </div>
  )
}

export interface BulkChangeFulfillStatusSelectPopopProps {
  selectedIds: string[] | number[]
  refresh?: Function
}

interface valueSubmit {
  fulfillStatus: any
  fulfillStatusIssue: any
  fulfillDesignStatus: any
}

export const BulkChangeFulfillStatusSelectPopup = (props: BulkChangeFulfillStatusSelectPopopProps) => {
  const { selectedIds, refresh } = props
  const [valueSubmit, setValueSubmit] = useState<valueSubmit>()

  return (
    <>
      <ModalContent>
        <BulkChangeFulfillStatusSelect
          options={{ NO_OPTION: `${t(Labels.choose_status_hint)}...`, ...(FulfillStatusName as any) }}
          label={t(Labels.fulfill_status)}
          placeholder={`${t(Labels.choose_status_hint)}...`}
          keyValue={"fulfillStatus"}
          setValueSubmit={setValueSubmit}
        />

        <BulkChangeFulfillStatusSelect
          options={{ NO_OPTION: `${t(Labels.choose_status_issue_hint)}...`, ...(FulfillStatusIssueName as any) }}
          label={t(Labels.fulfill_status_issue)}
          placeholder={`${t(Labels.choose_status_issue_hint)}...`}
          keyValue={"fulfillStatusIssue"}
          setValueSubmit={setValueSubmit}
        />
        <BulkChangeFulfillStatusSelect
          options={{ NO_OPTION: `${t(Labels.choose_design_status)}...`, ...(FulfillDesignStatusName as any) }}
          label={t(Labels.fulfill_design_status)}
          placeholder={`${t(Labels.choose_design_status)}...`}
          keyValue={"fulfillDesignStatus"}
          setValueSubmit={setValueSubmit}
        />
      </ModalContent>
      <ModalButtons
        confirm={async () => {
          if (valueSubmit) {
            let updateData: any = {}
            const valueFulfillStatus = valueSubmit.fulfillStatus?.value
            const valueFulfillStatusIssue = valueSubmit.fulfillStatusIssue?.value
            const valueFulfillDesignStatus = valueSubmit.fulfillDesignStatus?.value
            
            if (valueFulfillStatus && valueFulfillStatus !== "NO_OPTION") {
              updateData.status = valueFulfillStatus
            }

            if (valueFulfillStatusIssue && valueFulfillStatusIssue !== "NO_OPTION") {
              updateData.statusIssue = valueFulfillStatusIssue
            }
            
            if (valueFulfillDesignStatus && valueFulfillDesignStatus !== "NO_OPTION") {
              updateData.designStatus = valueFulfillDesignStatus || null
            }

            if (!isEmpty(updateData)) {
              await FulfillOrderActions.bulkUpdateByIds(selectedIds, updateData)
              refresh && refresh()
            }
          }
        }}
      />
    </>
  )
}
