import React, { Fragment, useEffect } from "react";

import { Alert, Button, Card, CardBody, Col, Container, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";

import { SectionLayout } from "../../components/layout/SectionLayout";
import { FormikInput } from "../../components/form/FormikInput";
import { t } from "../../core/translations";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { Labels } from "../../common/labels";
import { useHistory, useParams } from "react-router-dom";
import { Toasts } from "../../core/notification/notifications";
import { RouteNames } from "../../routes";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { saleChannelIdSelector } from "../../data/atoms/sale-channel.atom";
import Breadcrumb from "components/layout/Breadcrumb";
import { LoadableComponent } from "../../components/common/LoadableComponent";
import { SaleChannelActions } from "../../data/actions/sale-channel.action";
import { CreateSaleChannelDto, UpdateSaleChannelDto } from "../../data/services/sale-channel-service";
import { Messages } from "../../common/messages";
import Select from "react-select";
import { saleChannelSchema } from "./validations/sale-channel.vavlidation";
import { isEmpty, omit } from "lodash";
import ActionButton from "components/input/ActionButton";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import { FormikForm, FormValidation } from "components/form/FormikForm";
import { MetaTable } from "components/table/MetaTable";
import { preprocessPage } from "utils/utils";
import { SearchableSelect } from "components/input/SearchableSelect";
import { AccountActions } from "data/actions/account.action";
import TeamsSelect, { TeamDto } from "pages/settings/components/teams.select";
import { option } from "yargs";
import { ChannelOrderTypeOptions, defaulPermissionOptions, permissionOptions } from "./types/sale-channel.type";
import { trackingServiceOptions } from "../../common/constants";

const SaleChannelPage = (props:any) => {
  const params = useParams<Record<string, any>>();
  const history = useHistory();
  const isEditing = +params.id > 0;
  const dataLoadable = useRecoilValueLoadable(saleChannelIdSelector(params.id))

  if(params.id != "create" && !(+params.id)){
    history.replace(`${history.location.pathname}/404`)
  }

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.sale_channel), model: dataLoadable?.contents})
  }, [dataLoadable?.state])

  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(saleChannelIdSelector(params.id))
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[params.id])

  const validation = useFormik({
    initialValues: dataLoadable?.contents || {},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: saleChannelSchema,
    onSubmit: (value, helpers) => handleSubmit(value, helpers)
  });

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    try {
      helpers.setErrors({});
      helpers.setSubmitting(true);

      let result: any = null;
      if (isEditing) {
        let updateData: UpdateSaleChannelDto = {
          name: values.name,
          domain: values.domain,
          teamId: values.teamId,
          apiKey: values.apiKey,
          apiSecret: values.apiSecret,
          permissions: {
            order: values.permissions?.order,
            storage: values.permissions?.storage,
            saleChannel: values.permissions?.saleChannel
          },
          trackingConfig: {
            apiKey: values?.trackingConfig?.apiKey,
            trackingService: values?.trackingConfig?.trackingService,
            baseApiUrl: values?.trackingConfig?.baseApiUrl
          },
          type: values.type,
          active: values.active,
          imageBaseUrl: values.imageBaseUrl,
          ownerId: values.owner?.id,
          options: {
            fulfillFromOrderId: values.options?.fulfillFromOrderId,
            channelOrderType: values.options?.channelOrderType,
            useArtworkSkuForNoCustomLineItem: values.options?.useArtworkSkuForNoCustomLineItem
          }
        };
        result = await SaleChannelActions.update(+params.id, updateData, {});
        if (result?.id) {
          Toasts.success(t(Messages.update_sale_channel_succeeded));
        }
      } else {
        let createData: CreateSaleChannelDto = {
          name: values.name,
          domain: values.domain,
          teamId: values.teamId,
          apiKey: values.apiKey,
          apiSecret: values.apiSecret,
          permissions: {
            order: values.permissions?.order,
            storage: values.permissions?.storage,
            saleChannel: values.permissions?.saleChannel
          },
          options: {
            fulfillFromOrderId: values.options?.fulfillFromOrderId,
            channelOrderType: values.options?.channelOrderType,
            useArtworkSkuForNoCustomLineItem: values.options?.useArtworkSkuForNoCustomLineItem

          },
          type: 'woocommerce',
          active: values.active,
          trackingConfig: {
            apiKey: values?.trackingConfig?.apiKey,
            trackingService: values?.trackingConfig?.trackingService,
            baseApiUrl: values?.trackingConfig?.trackingConfig
          },
          imageBaseUrl: values.imageBaseUrl,
        };
        result = await SaleChannelActions.create(createData, {});
        if (result?.id) {
          // TODO optimize not using setTimeout
          setTimeout(() => {
            history.replace(RouteNames.STORES_DETAIL.replace(":id",result.id));
            Toasts.success(t(Messages.create_sale_channel_succeeded));
          }, 100)
        }
      }
    } catch (e) {
      helpers.setSubmitting(false);
    }
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumb 
            goBack={RouteNames.STORES}
            title={
              isEditing 
              ? t(Labels.title_edit_channel) 
              : t(Labels.title_create_channel)}
          >
            <div className="d-flex gap-2">
              <WithPermission action={ActionEntities.update} resource={ResourceEntities.saleChannelEntity}>
                <ActionButton
                  color='primary'
                  onClick={() => SaleChannelActions.sync(validation.values?.id)}
                >
                  <i className="bx bx-refresh font-size-16 align-middle px-1"/>
                  {t(Labels.sync_orders)}
                </ActionButton>
              </WithPermission>
              <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.saleChannelEntity}>
                <ActionButton
                  color='success'
                  onClick={() => validation.submitForm()}
                >
                  <i className="bx bx-save font-size-16 align-middle me-1"></i>
                  {isEditing ? t(Labels.save) : t(Labels.create)}
                </ActionButton>
              </WithPermission>
            </div>
          </Breadcrumb>
          <Card>
            <CardBody>
              <LoadableComponent loadable={dataLoadable}>
                <FormikForm validation={validation}>
                  <SaleChannelForm data={dataLoadable?.contents} validation={validation} id={params.id} />
                </FormikForm>
              </LoadableComponent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export const SaleChannelForm = (props: any) => {
  const { validation } = props
  const params = useParams<Record<string, any>>();
  const isEditing = +params.id > 0;

  useEffect(() => {
    if(!isEditing){
      validation.setFieldValue('permissions.order', defaulPermissionOptions.value)
      validation.setFieldValue('permissions.storage', defaulPermissionOptions.value)
      validation.setFieldValue('permissions.saleChannel', defaulPermissionOptions.value)
    }
  }, [])

  return (
    <>
      <SectionLayout title={Labels.title_sale_channel}>
        <Form 
          onChange={() => validation.setErrors({})}
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          return false;
        }}>
          <Row>
            {
              <Row className="mb-3">
                <TeamsSelect
                  validation={validation}
                  label={t(Labels.teams)}
                  teamId={validation.values?.teamId}
                  onChange={(value: TeamDto) => {validation.setFieldValue('teamId', value.id)}}
                />
              </Row>
            }
            <Col md={6}>
              <Row className="mb-3">
                <Label htmlFor="formrow-firstname-Input">{ t(Labels.sale_channels_name) }</Label>
                <FormikInput
                  name="name"
                  type="text"
                  validation={validation}
                  placeholder={t(Labels.sale_channels_name_hint)}
                />
              </Row>
            </Col>
            <Col md={6}>
              <Row className="mb-3">
                <Label htmlFor="formrow-firstname-Input">{ t(Labels.domain) }</Label>
                <FormikInput
                  name="domain"
                  type="text"
                  validation={validation}
                  placeholder={t(Labels.domain_hint)}
                />
              </Row>
            </Col>
          </Row>
          <Row className="d-flex flex-column">
            <Col className="mb-3">
              <Label htmlFor="formrow-firstname-Input">{ t(Labels.api_key) }</Label>
              <FormikInput
                name="apiKey"
                type="password"
                validation={validation}
                placeholder={t(Labels.api_key_hint)}
              />
            </Col>
            <Col className="mb-3">
              <Label htmlFor="formrow-firstname-Input">{ t(Labels.api_secret) }</Label>
              <FormikInput
                name="apiSecret"
                type="password"
                validation={validation}
                placeholder={t(Labels.api_secret_hint)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <FormikInput
                name="owner"
                validation={validation}
                label={t(Labels.sale_channel_owner)}
                required={false}
                placeholder={t(Labels.sale_channel_owner_hint)}
                customInput={({handleChange, handleBlur, errorData}) => (
                  <SearchableSelect
                    value={validation.values.owner}
                    getOptionLabel={(option: any) => t(option?.name || option?.username)}
                    getOptionValue={(option: any) => option?.name || option?.username}
                    onChange={(value: any) => validation.setFieldValue('owner', omit({...value, options: value.newOptions}, 'newOptions'))}
                    onBlur={handleBlur}
                    placeholder={Labels.user_hint}
                    loadOptions={(value: string) => AccountActions.getUserByUsername(value)}
                  />
                )}
              />
            </Col>
            <Col md={6} className="mt-1">
              <FormikInput
                name="saleChannel"
                type="text"
                validation={validation}
                label={t(Labels.sale_channel_permission)}
                required={false}
                placeholder={t(Labels.sale_channel_permission_hint)}
                customInput={({handleChange, handleBlur, errorData}) => (
                  <Select
                    isSearchable={true}
                    options={permissionOptions}
                    value={permissionOptions.filter((item: any) => item.value === validation.values?.permissions?.saleChannel)}
                    getOptionLabel={(option: any) => t(option.label)}
                    getOptionValue={(option: any) => option.value}
                    onBlur={handleBlur}
                    onChange={(values: any) => validation.setFieldValue('permissions.saleChannel', values.value)}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Col>
            <Col md={6}>
              <FormikInput 
                name="order"
                type="text"
                validation={validation}
                label={t(Labels.order_permission)}
                required={false}
                placeholder={t(Labels.order_permission_hint)}
                customInput={({handleChange, handleBlur, errorData}) => (
                  <Select 
                    isSearchable={true}
                    options={permissionOptions}
                    value={permissionOptions.filter((item: any) => item.value === validation.values?.permissions?.order)}
                    getOptionLabel={(option: any) => t(option.label)}
                    getOptionValue={(option: any) => option.value}
                    onBlur={handleBlur}
                    onChange={(values: any) => validation.setFieldValue('permissions.order', values.value)}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Col>
            <Col md={6} className="mt-1">
              <FormikInput 
                name="storage"
                type="text"
                validation={validation}
                label={t(Labels.storage_permission)}
                required={false}
                placeholder={t(Labels.storage_permission_hint)}
                customInput={({handleChange, handleBlur, errorData}) => (
                  <Select 
                    isSearchable={true}
                    options={permissionOptions}
                    value={permissionOptions.filter((item: any) => item.value === validation.values?.permissions?.storage)}
                    getOptionLabel={(option: any) => t(option.label)}
                    getOptionValue={(option: any) => option.value}
                    onBlur={handleBlur}
                    onChange={(values: any) => validation.setFieldValue('permissions.storage', values.value)}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Col>
          </Row>
        </Form>
      </SectionLayout>
        <SectionLayout title={Labels.sync_orders}>
          <Form>
            <Row className="mb-3">
              <Col>
                <FormGroup check inline>
                  <Input
                    type="checkbox"
                    name="active"
                    checked={validation.values.active}
                    value={validation.values.active}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  <Label check>{t(Labels.automatic_data_sync)}</Label>
                </FormGroup>
              </Col>
            </Row>
            {validation.values.active ? (
              <>
                <FormikInput
                  label={t(Labels.start_sync_from_order_id)}
                  name="options.fulfillFromOrderId"
                  type="number"
                  value={+validation.values.options?.fulfillFromOrderId}
                  onChange={(value: any) =>
                    validation.setFieldValue("options", { ...validation.values.options, fulfillFromOrderId: value })
                  }
                  validation={validation}
                  placeholder={t(Labels.id)}
                />
                <FormikInput
                  label={t(Labels.channel_order_type)}
                  name="options.channelOrderType"
                  validation={validation}
                  customInput={({ handleChange, handleBlur, errorData }) => {
                    const value = ChannelOrderTypeOptions.find(item => item.value === validation.values.options?.channelOrderType)
                    return (
                      <Select
                        value={value}
                        options={ChannelOrderTypeOptions}
                        onChange={(option: any) => {
                          validation.setFieldValue("options", { ...validation.values.options, channelOrderType: option.value })
                        }}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                      />
                    )
                  }}
                />
                <Col className="mb-2 mt-3">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      name="active"
                      checked={validation.values.options?.useArtworkSkuForNoCustomLineItem}
                      value={validation.values.options?.useArtworkSkuForNoCustomLineItem}
                      onChange={(e) => {
                        validation.setFieldValue("options", { ...validation.values.options, useArtworkSkuForNoCustomLineItem: e.target.checked })
                      }}
                      onBlur={validation.handleBlur}
                    />
                    <Label check>{t(Labels.use_artwork_sku_for_no_custom_line_item)}</Label>
                  </FormGroup>  
                </Col>
                <Label>{t(Labels.completion_time)}</Label>
                <Input disabled={true} className="mb-2" type="text" value={validation.values?.lastSyncTime} />
                <Label>{t(Labels.sync_orders_status)}</Label>
                <Input disabled={true} className="mb-2" type="text" value={validation.values?.lastSyncStatus} />
                <Label>{t(Labels.detail)}</Label>
                <Alert color="primary">
                  <FormText>{validation.values?.lastSyncMessage}</FormText>
                </Alert>
              </>
            ) : (
              <></>
            )}
          </Form>
        </SectionLayout>
      <SectionLayout title={Labels.products}>
        <Form>
          <Row className="mb-2">
            <FormikInput
              label={t(Labels.default_image_base_url)}
              name="imageBaseUrl"
              type="text"
              validation={validation}
              placeholder={t(Labels.default_image_base_url_hint)}
            />
          </Row>
        </Form>
      </SectionLayout>
      <SectionLayout title={Labels.shipment_tracking}>
        <FulfillmentTrackingSetting validation={validation} />
      </SectionLayout>
    </>
  )
};

export interface FulfillmentTrackingSettingProps {
  validation: FormValidation
}

export const FulfillmentTrackingSetting = (props: FulfillmentTrackingSettingProps) => {
  const { validation } = props
  return (
    <Fragment>
      <Col className="mb-3">
        <FormikInput
          validation={validation}
          name="trackingConfig.trackingService"
          label={t(Labels.tracking_services)}
          placeholder={t(Labels.tracking_services_hint)}
          customInput={({ handleChange, handleBlur, errorData }) => (
            <Select
              isSearchable={true}
              options={trackingServiceOptions}
              value={trackingServiceOptions.filter(item => item.value === validation.values?.trackingConfig?.trackingService)}
              getOptionLabel={(option: any) => t(option.label)}
              getOptionValue={(option: any) => t(option.value)}
              onBlur={handleBlur}
              onChange={(value: any) => handleChange(value.value)}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>
      <Col className="mb-3">
        <FormikInput 
          validation={validation}
          name="trackingConfig.apiKey"
          label={t(Labels.api_key)} 
          placeholder={t(Labels.api_key_hint)}
          customInput={({ handleChange, handleBlur, errorData }) => (
            <Input
              value={validation.values?.trackingConfig?.apiKey}
              onChange={(value: any) => 
                validation.setFieldValue('trackingConfig', 
                {...validation.values.trackingConfig, apiKey: value.target.value})
              }
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>
      <Col className="mb-3">
        <FormikInput
          validation={validation}
          name="trackingConfig.baseApiUrl"
          label={t(Labels.base_api_url)}
          placeholder={t(Labels.base_api_url_hint)}
          customInput={({ handleChange, handleBlur, errorData }) => (
            <Input
              value={validation.values?.trackingConfig?.baseApiUrl}
              onChange={(value: any) =>
                validation.setFieldValue('trackingConfig',
                  {...validation.values.trackingConfig, baseApiUrl: value.target.value})
              }
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>
    </Fragment>
  )
}


export default SaleChannelPage;

