import { SelectOption } from '../../common/types'
import { Input, Label, Row } from 'reactstrap'
import { Logger } from "../../core/logger";

interface CheckboxGroupProps {
    name: string;
    items: SelectOption[];
    checkedItems: SelectOption[];
    onChange: (name: any, value: any) => void;
}

export default function CheckboxGroup({ name, items, onChange, checkedItems }: CheckboxGroupProps) {
    return (
        <>
            {items.map((item, index: number) => (
              <Row key={item.value}>
                <div className="form-check form-check-inline mb-3 ms-2" key={item.value}>
                    <Input
                        type="checkbox"
                        id={`${name}-${item.value}`}
                        name={name}
                        // xuanduc edit show label in modal filter selected
                        onChange={(event) => onChange(name, { value: item.value, index, checked: event.target.checked, label: item.label })}
                        checked={checkedItems?.map(c => c.value).includes(item.value)}
                    />
                    <Label
                        className="form-check-label"
                        htmlFor={`${name}-${item.value}`}
                    >
                        {item.label}
                    </Label>
                </div>
              </Row>
            ))}
        </>
    )
}
