import { atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { ArtworkTemplateServices } from "data/services/artwork-template.service";

export const artworkTemplateQueryState = atomFamily<
  QueryParams<any>,
  QueryParams<any>
  >({
  key: "artworkTemplateQueryState",
  default: (params: any) => ({ ...params }),
})

export const artworkTemplateIdState = atomFamily<QueryParams<any>, any>({
  key: 'artworkTemplateIdState',
  default: (id: any) => {
    return id;
  }
})

export const artworkTemplateSelector = selectorFamily({
  key: 'artworkTemplateSelector',
  get: (id: any) => async ({ get }) => {
    let artworkTemplateId = get(artworkTemplateIdState(id));

    if (+artworkTemplateId > 0) {
      let data = await ArtworkTemplateServices.getById(+artworkTemplateId, { snoozeErrorMessage: false });
      return data
    }

    return {};
  }
})

export const artworkTemplatesSelector = selector({
  key: "artworkTemplatesSelector",
  get: async ({ get }) => {
    let query = get(artworkTemplateQueryState(defaultPageInfo))
    let pageData = await ArtworkTemplateServices.query(query, {
      snoozeErrorMessage: false,
    })
    return pageData
  },
})

