import * as Logger from 'loglevel';
import { LogLevelNumbers } from "loglevel";

if(process.env.REACT_APP_LOG_LEVEL) {
  Logger.setDefaultLevel(+process.env.REACT_APP_LOG_LEVEL as LogLevelNumbers)
} else {
  Logger.setDefaultLevel(Logger.levels.DEBUG)
}

if((process.env.REACT_APP_ENV || process.env.NODE_ENV) == 'production') {
  Logger.setDefaultLevel(Logger.levels.ERROR)
}

export {
  Logger
}