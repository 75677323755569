import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, Col } from "reactstrap";
import { SmallContainer } from "components/layout/SmallContainer";
import { FormikInput } from "components/form/FormikInput";
import { t } from "../../core/translations";
import { FormikHelpers, useFormik } from "formik";
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { SettingKeys, settingSelector } from "../../data/atoms/setting.atom";
import { LoadableComponent } from "../../components/common/LoadableComponent";
import { MultiValue } from "react-select";
import Creatable from "react-select/creatable";
import { channelOrderSettingState } from "data/atoms/app.atom";
import { Labels } from "common/labels";
import Breadcrumb from "components/layout/Breadcrumb";
import { FormikForm, FormValidation } from "components/form/FormikForm";
import { SettingDto, UpdateSettingDto } from "data/services/setting-service";
import { SettingActions } from "../../data/actions/setting.action";
import { Toasts } from "../../core/notification/notifications";
import { channelOrderSettingSchema } from "./validations/channel-order-setting.validation";
import { Messages } from "../../common/messages";
import { FormLayout, FormLayoutMain } from "components/layout/FormLayout";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { createDocumentPageName } from "../../utils/utils";
import { isEmpty } from "lodash";

export const ChannelOrderSettingPage = () => {
  const dataLoadable = useRecoilValueLoadable(settingSelector(SettingKeys.ORDER));
  const isEditing = true;
  const [setting, setSetting] = useState<any>({});
  const validation = useFormik({
    initialValues: {
      teamId: setting?.teamId,
      value: setting?.value
    } || {},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: channelOrderSettingSchema,
    onSubmit: (value, helpers) => handleSubmit(value, helpers)
  });

  useEffect(() => {
    dataLoadable.state === "hasValue" && setSetting(dataLoadable?.contents);
  }, [dataLoadable.state]);

  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(settingSelector(SettingKeys.ORDER))
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[SettingKeys.ORDER])

  useEffect(() => { document.title = createDocumentPageName(t(Labels.channel_order_setting)) }, [])

  const handleSubmit = async (value: SettingDto, helpers: FormikHelpers<any>) => {
    try {
      helpers.setErrors({});
      helpers.setSubmitting(true);

      let updateData: UpdateSettingDto = {
        value: validation.values?.value,
        teamId: validation.values?.teamId
      };
      if (isEditing) {
        let result = await SettingActions.updateByKey(SettingKeys.ORDER, updateData);
        if (result?.id) { // Nếu cập nhật thành công thì sẽ trả về object có property là id
          setSetting((prev: any) => ({
            ...prev,
            ...result?.value
          }));
          // TODO optimize code not using setTimeout
          setTimeout(() => Toasts.success(t(Messages.update_successfully)), 100);
        }
      }
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <SmallContainer>
          <Breadcrumb
            title={`${isEditing ? t(Labels.edit) : t(Labels.create)} ${t(Labels.channel_order_setting)}`}
          >
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.settingEntity}>
              <Button
                color="success"
                onClick={() => validation.submitForm()}
              >
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </Button>
            </WithPermission>
          </Breadcrumb>
          <Card>
            <CardBody>
              <LoadableComponent loadable={dataLoadable}>
                <FormikForm validation={validation}>
                  <FormLayout>
                    <FormLayoutMain>
                      <SettingForm validation={validation} />
                    </FormLayoutMain>
                  </FormLayout>
                </FormikForm>
              </LoadableComponent>
            </CardBody>
          </Card>
        </SmallContainer>
      </div>
    </React.Fragment>
  );
};

export interface SettingFormProps {
  validation: FormValidation;
}

export const SettingForm = (props: SettingFormProps) => {
  const { validation } = props;
  const channelOrderSetting = useRecoilValue(channelOrderSettingState);

  return (
    <Fragment>
      {/*<Col className="mb-3">*/}
      {/*  <TeamsSelect*/}
      {/*    label={t(Labels.teams)}*/}
      {/*    validation={validation}*/}
      {/*    teamId={validation.values?.teamId}*/}
      {/*    onChange={(value: any) => validation.setFieldValue('teamId', value.id)}*/}
      {/*  />*/}
      {/*</Col>*/}
      
      <Col className="mb-3">
        <FormikInput
          validation={validation}
          name="artworkSkuSplitter"
          label={t(Labels.artwork_sku_splitter)}
          required={false}
          customInput={({ handleChange, handleBlur, errorData  }) => (
            <Creatable
              isMulti={true}
              options={channelOrderSetting?.sku?.artworkSkuSplitter?.map((value: string) => ({ value }))}
              formatCreateLabel={value => `${t(Labels.create)} "${value}"`}
              value={validation.values?.value?.sku?.artworkSkuSplitter.map((value: any) => ({ value }))}
              getOptionValue={(option: any) => option.value || option}
              getOptionLabel={(option: any) => t(option.label || option.value || option)}
              onBlur={handleBlur}
              onChange={(values: MultiValue<any>) => {
                handleChange(
                  {
                    ...(validation.values?.value.sku || {}),
                    artworkSkuSplitter: values.map(item => item.value)
                  },
                  "value.sku"
                );
              }}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>
      <Col className="mb-3">
        <FormikInput
          validation={validation}
          name="value.customFeeSkus"
          label={t(Labels.customization_fee_sku)}
          required={false}
          customInput={({ handleChange, handleBlur, errorData  }) => (
            <Creatable
              isMulti={true}
              options={channelOrderSetting?.customFeeSkus?.map((value: string) => ({ value }))}
              formatCreateLabel={value => `${t(Labels.create)} "${value}"`}
              value={validation.values?.value?.customFeeSkus?.map((value: any) => ({ value }))}
              getOptionValue={(option: any) => option.value || option}
              getOptionLabel={(option: any) => t(option.label || option.value || option)}
              onBlur={handleBlur}
              onChange={(values: MultiValue<any>) => {
                handleChange(values.map(option => option.value ? option.value : option));
              }}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>
      <Col className="mb-3">
        <FormikInput
          validation={validation}
          name="value.customDataKeys"
          label={t(Labels.custom_data_keys)}
          required={false}
          customInput={({ handleBlur, handleChange, errorData  }) => (
            <Creatable
              isMulti={true}
              options={channelOrderSetting?.customDataKeys?.map((value: string) => ({ value }))}
              formatCreateLabel={value => `${t(Labels.create)} "${value}"`}
              value={validation.values?.value?.customDataKeys}
              getOptionValue={(option: any) => option.value || option}
              getOptionLabel={(option: any) => t(option.label || option.value || option)}
              onBlur={handleBlur}
              onChange={(values: MultiValue<any>) => {
                handleChange(values.map(option => option.value ? option.value : option));
              }}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>
      <Col className="mb-3">
        <FormikInput
          validation={validation}
          name="value.variantOptionKeys"
          label={t(Labels.variant_option_keys)}
          required={false}
          customInput={({ handleBlur, handleChange, errorData  }) => (
            <Creatable
              isMulti={true}
              options={channelOrderSetting?.variantOptionKeys?.map((value: string) => ({ value }))}
              formatCreateLabel={value => `${t(Labels.create)} "${value}"`}
              value={validation.values?.value?.variantOptionKeys}
              getOptionValue={(option: any) => option.value || option}
              getOptionLabel={(option: any) => t(option.label || option.value || option)}
              onBlur={handleBlur}
              onChange={(values: MultiValue<any>) => {
                handleChange(values.map(option => option.value ? option.value : option));
              }}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>
    </Fragment>
  );
};
