import React, { useEffect, useState } from "react"
import { UploadForm } from "../../../components/common/UploadForm"
import { t } from "../../../core/translations"
import { useRecoilState } from "recoil"
import { portableModalState, viewExcelState } from "../../../data/atoms/app.atom"
import { ModalContent } from "../../../components/modal/portable-modal"
import { FulfillOrderActions } from "../../../data/actions/fulfill-order.action"
import { ProductTypeActions } from "../../../data/actions/product-type.action"
import { FulfillProductActions } from "../../../data/actions/fulfill-product.action"
import { ArtworkActions } from "../../../data/actions/artwork.action"
import { Labels } from "common/labels"
import { FulfillmentOrderActions } from "data/actions/fulfillment-order.action"
import { AdminUtils, MimeTypes } from "utils/admin-utils"
import { Alert, Button, Col, Row } from "reactstrap"
import { Messages } from "../../../common/messages"
import { Link } from "react-router-dom"
import { RouteNames } from "routes"
import { BulkEditorView } from "components/bulk/bulk-editor.type"
import { SourceActions } from "data/actions/source.action"
import { read, utils, write } from "xlsx"
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai"
import { BiLinkExternal } from "react-icons/bi"
import { Storages } from "utils/storages"
import { Toasts } from "core/notification/notifications"
import { isObject } from "lodash"
import { createUrlEmbed } from "utils/embed"

export interface ImportedResultEditor {
  basePath: string
  editType: string
}

export interface HandleFileResultType {
  rawData?: any
  stats?: any
  success?: boolean
  message?: string
  fileName?: string
}

export enum ImportType {
  NULL = "null",
  FULFILLED = "fulfilled",
  FULFILLING = "fulfilling",
  PRODUCT_TYPE = "product_type",
  FULFILL_PRODUCT = "fulfill_product",
  ARTWORK = "artwork",
  SUBMITTED_FULFILLMENT_ORDER = "submitted_fulfillment_order",
  COMPLETED_FULFILLMENT_ORDER = "completed_fulfillment_order",
  EXTERNAL_FULFILLMENT_ORDER = "external_fulfillment_order",
  SHOPBASE_FULFILLMENT_ORDER = "import_shopbase_fulfillment_order",
  SOURCE = "source",
  UPDATE_BASE_COST = "update_base_cost",
}

export interface ImportModalProps {
  type: ImportType
  title?: string
  onClose: Function
  replaceable?: boolean
  onDone?: Function
}

export const ImportModal = (props: ImportModalProps) => {
  const { replaceable } = props
  const [modalState, setPortableModal] = useRecoilState(portableModalState)
  const [open, setOpen] = useState(false)
  const isImporting = () => props.type != ImportType.NULL

  const getImportTitle = () => {
    switch (props.type) {
      case ImportType.FULFILLING:
        return t(Labels.import_fulfilling_order)
      case ImportType.FULFILLED:
        return t(Labels.import_fulfilled_order)
      case ImportType.PRODUCT_TYPE:
        return t(Labels.import_product_type)
      case ImportType.FULFILL_PRODUCT:
        return t(Labels.import_fulfill_product)
      case ImportType.ARTWORK:
        return t(Labels.import_artwork)
      case ImportType.COMPLETED_FULFILLMENT_ORDER:
        return t(Labels.import_completed_request_from_excel)
      case ImportType.SUBMITTED_FULFILLMENT_ORDER:
        return t(Labels.import_submitted_request_from_excel)
      case ImportType.EXTERNAL_FULFILLMENT_ORDER:
        return t(Labels.import_external_request_from_excel)
      case ImportType.SHOPBASE_FULFILLMENT_ORDER:
        return t(Labels.import_shopbase_fulfillment_order)
      case ImportType.SOURCE:
        return t(Labels.import_source)
      case ImportType.UPDATE_BASE_COST:
        return t(Labels.import_update_base_cost)
      default:
        return t(Labels.input_data)
    }
  }

  useEffect(() => setOpen(isImporting()), [props.type])
  useEffect(() => setOpen(modalState.open && isImporting()), [modalState])
  useEffect(() => {
    setPortableModal({
      open,
      size: "lg",
      title: getImportTitle(),
      content: (
        <ModalContent>
          {props.type == ImportType.FULFILLING ? (
            <UploadForm
              title={t("")}
              description={t(Labels.import_fulfilling_order_from_excel)}
              onSubmit={handleSubmit}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.FULFILLED ? (
            <UploadForm
              title={t("")}
              description={t(Labels.import_fulfilled_order_from_excel)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/1MWzuLhaVJ9QHeBq3ihtiWI2HRwWpSJen6naC7bCZDsg/edit?usp=sharing"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.PRODUCT_TYPE ? (
            <UploadForm
              title={t("")}
              description={t(Labels.import_product_type_from_excel)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/1FVmIpelRdR1jB7V6NFS-VoeLK9BUAcWtQ8Fk1HI7Hx0/edit?usp=sharing"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.FULFILL_PRODUCT ? (
            <UploadForm
              title={t("")}
              description={t(Labels.import_fulfill_product_from_excel)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/1utnmK0mLYRU2sP2RtDFZoxMVqujYGbPhOHSS-UFJeYs/edit?usp=sharing"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.ARTWORK ? (
            <UploadForm
              title={t("")}
              description={t(Labels.import_artwork_from_excel)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/1WYEnbVJqM5t5dEOD5yUZzKJIb7ONSJp2X9VXQKb-at8/edit#gid=0"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.COMPLETED_FULFILLMENT_ORDER ? (
            <UploadForm
              title={t("")}
              multi={true}
              description={t(Labels.import_completed_order_from_excel_desc)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/1DgMVeIJfvv4WBcEBrezP2H3Gwlncp6ZeaGBr8Dm-UJM/edit?usp=sharing"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.SUBMITTED_FULFILLMENT_ORDER ? (
            <UploadForm
              title={t("")}
              multi={true}
              description={t(Labels.import_submitted_order_from_excel_desc)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/1qQvq29GlzJLjVz8R9HA4UTEFcaX7w3vcXPgWsFz8by4/edit?usp=sharing"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.EXTERNAL_FULFILLMENT_ORDER ? (
            <UploadForm
              title={t("")}
              description={t(Labels.import_external_request_from_excel_desc)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/19BT8f24nXtiBRYuoy1FZ6MfrhG-XE3uwhWBYfSUauog/edit?usp=sharing"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.SHOPBASE_FULFILLMENT_ORDER ? (
            <UploadForm
              title={t("")}
              description={t(Labels.import_shopbase_fulfillment_order)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/1opDIMSI7n_q1dLX2XVXhybdJssVbLs0bDsNqdEwWmEw/edit?usp=sharing"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.SOURCE ? (
            <UploadForm
              title={t("")}
              description={t(Labels.import_source)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/1GgDm_UWrmcgz-Li6BDAD95IynotE1ra3qbxQWQW3LiM/edit?usp=sharing"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
          {props.type == ImportType.UPDATE_BASE_COST ? (
            <UploadForm
              title={t("")}
              description={t(Labels.import_update_base_cost)}
              onSubmit={handleSubmit}
              templateUrl={"https://docs.google.com/spreadsheets/d/1DgMVeIJfvv4WBcEBrezP2H3Gwlncp6ZeaGBr8Dm-UJM/edit?usp=sharing"}
              onCancel={() => setOpen(false)}
              replaceable={replaceable}
            />
          ) : null}
        </ModalContent>
      ),
    })

    if (!open) {
      props.onClose()
    }
  }, [open])

  const handleDownload = (data: any, fileNamePrefix: string) => {
    AdminUtils.startAutoDownloadOutputFile(data, fileNamePrefix)
  }

  const handleSubmit = async (selectedFiles: any[], replaceable: boolean) => {
    let importResult: HandleFileResultType = null as any
    let importedEditor: ImportedResultEditor = null as any
    let fileNamePrefix: string = ""

    let data: any = null
    switch (props.type) {
      case ImportType.FULFILLING:
        data = await FulfillOrderActions.importFulfillingOrders(selectedFiles, replaceable)
        importResult = AdminUtils.handleImportResponse(data, "fulfilling_orders_result")
        break
      case ImportType.FULFILLED:
        data = await FulfillOrderActions.importFulfilledOrders(selectedFiles, replaceable)
        const defaultExtData = { data: data?.data || data, ext: data?.data?.ext || data.ext || ".csv" }
        importResult = AdminUtils.handleImportResponse(defaultExtData, "fulfilled_orders_result")
        break
      case ImportType.PRODUCT_TYPE:
        data = await ProductTypeActions.importProductTypes(selectedFiles[0], replaceable)
        importResult = AdminUtils.handleImportResponse(data, "import_product_type_result")
        break
      case ImportType.FULFILL_PRODUCT:
        data = await FulfillProductActions.importFulfillProducts(selectedFiles[0], replaceable)
        importResult = AdminUtils.handleImportResponse(data, "import_fulfill_product_result")
        break
      case ImportType.ARTWORK:
        data = await ArtworkActions.importArtworks(selectedFiles[0], replaceable)
        importResult = AdminUtils.handleImportResponse(data, "import_artworks_result")
        break
      case ImportType.EXTERNAL_FULFILLMENT_ORDER:
        data = await FulfillmentOrderActions.importExternalFulfillmentOrder(selectedFiles[0], replaceable)
        importResult = AdminUtils.handleImportResponse(data, "import_external_order_result")
        importedEditor = {
          basePath: RouteNames.FULFILLMENT_ORDERS,
          editType: BulkEditorView.FULFILLMENT_ORDER,
        }
        break
      case ImportType.COMPLETED_FULFILLMENT_ORDER:
        data = await FulfillmentOrderActions.importCompletedFulfillmentOrder(selectedFiles, replaceable)
        importResult = AdminUtils.handleImportResponse(data, "import_completed_order_result")
        break
      case ImportType.SUBMITTED_FULFILLMENT_ORDER:
        data = await FulfillmentOrderActions.importSubmittedFulfillmentOrder(selectedFiles, replaceable)
        importResult = AdminUtils.handleImportResponse(data, "import_submitted_order_result")
        importedEditor = {
          basePath: RouteNames.FULFILLMENT_ORDERS,
          editType: BulkEditorView.FULFILLMENT_ORDER,
        }
        break
      case ImportType.SHOPBASE_FULFILLMENT_ORDER:
        data = await FulfillmentOrderActions.importShopbaseFulfillmentOrder(selectedFiles[0], replaceable)
        importResult = AdminUtils.handleImportResponse(data, "import_shopbase_order_result")
        importedEditor = {
          basePath: RouteNames.FULFILLMENT_ORDERS,
          editType: BulkEditorView.FULFILLMENT_ORDER,
        }
        break
      case ImportType.SOURCE:
        data = await SourceActions.importSources(selectedFiles[0], replaceable)
        importResult = AdminUtils.handleImportResponse(data, "import_source_result")
        break
      case ImportType.UPDATE_BASE_COST:
        data = await FulfillmentOrderActions.importUpdateBaseCost(selectedFiles, replaceable)
        importResult = AdminUtils.handleImportResponse(data, "import_update_base_cost_result")
        break
    }

    if(importResult?.success){
      props.onDone && props.onDone()
      setPortableModal({
        ...modalState,
        open: true,
        size: 'lg',
        title: t(Labels.import_result),
        preventToggle: true,
        content: (
          <ImportResultModal
            importResult={importResult}
            editor={importedEditor}
            onClose={props.onClose}
          />
        )
      })
      return <></>
    }

    if (data.error) {
      Toasts.error(data.error?.message || data.error?.code)
    } else {
      props.onDone && props.onDone()
      setPortableModal({
        ...modalState,
        open: true,
        size: "sm",
        title: t(Labels.import),
        preventToggle: true,
        style: { minWidth: 450 },
        content: <ImportResultModalAsync onClose={props.onClose} />,
      })
    }
  }
  return <></>
}

export interface ImportResultFulfillmentOrderProps {
  importResult?: HandleFileResultType
  editor?: ImportedResultEditor
  onClose: Function
}

export const ImportResultModal = (props: ImportResultFulfillmentOrderProps) => {
  const { importResult, editor, onClose} = props
  return (
    <>
      <ModalContent>
        <div style={{maxHeight: 550}} >
          <Row>
            <div className="d-flex flex-column">
              <h5 className="mb-4">{importResult?.stats ? t(Labels.finish_importing_reports) : importResult?.message || t(Messages.import_successfully)}</h5>
              <div className="d-flex flex-column">
                {
                  importResult?.stats ? (
                    <>
                      <p className="mb-3 me-2 fw-bold">{t(Labels.total_rows)}: {importResult?.stats?.total}</p>
                      <p className="mb-3 fw-bold">{t(Labels.successfully_imported)}: {importResult?.stats?.success}</p>
                      <p className="mb-3 fw-bold">{t(Labels.failed)}: {importResult?.stats?.failed}</p>
                      <p className="mb-4 fw-bold">{t(Labels.skipped)}: {importResult?.stats?.skipped}</p>
                    </>
                  ) : null
                }
                { importResult?.stats?.alerts?.length && editor
                  ? Object.entries(importResult?.stats?.alerts).map((item: any) => {
                    return (
                      <Alert key={item[1]} color="warning">
                        {item[0]}: {item[1].length}
                        {
                          <Link
                            target="_blank"
                            to={`${editor.basePath}?ids=${item[1].join(",")}&editType=${editor.editType}`}
                          >{` (${t(Labels.click_to_edit)})`}</Link>
                        }
                      </Alert>
                    )
                  }) : null}
                <div className="mb-2">
                  <p className="fw-bold d-inline">{t(Labels.output_file)}:</p>
                  <p className="ms-1 d-inline">{t(Labels.click_to_dowload_again)}</p>
                  <span
                    className="ms-1 d-inline"
                    style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => AdminUtils.startAutoDownloadOutputFile(importResult?.rawData, importResult?.fileName)}
                  >
                    {t(Labels.retry_to_dowload)}
                  </span>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mb-2">
              <Button color="secondary" onClick={() => onClose()}>{t(Labels.close)}</Button>
            </Col>
          </Row>
        </div>
      </ModalContent>
    </>
  )
}

export const ImportResultModalAsync = (props: ImportResultFulfillmentOrderProps) => {
  const {  onClose } = props

  return (
    <>
      <ModalContent>
        <div style={{ maxHeight: 550 }}>
          <Col className="w-100 justify-content-center align-items-center">
            <div className="d-flex justify-content-center">
              <span
                className="rounded-circle d-flex justify-content-center align-items-center mb-4"
                style={{ width: 50, height: 50, background: "#dcfce7" }}
              >
                <AiOutlineCheck color="green" size={22} />
              </span>
            </div>
            <p>{Messages.import_confirm}</p>
          </Col>
          <Col className="d-flex justify-content-center ">
            <Col className="w-100 mb-2 d-flex">
              <div className="w-50 pe-2">

              <a target="_blank" rel="noreferrer" href={createUrlEmbed(RouteNames.REPORTS_IMPORTS)} className="text-white">
                <Button color="primary" className=" w-100">
                  <BiLinkExternal className="me-1" />
                  {t(Labels.reports)}
                </Button>
              </a>
              </div>
              <div className="w-50 ps-2">

              <Button color="secondary" className="w-100"  onClick={() => onClose()}>
                {t(Labels.close)}
              </Button>
              </div>
            </Col>
          </Col>
        </div>
      </ModalContent>
    </>
  )
}

export const convertCsvToExcelBuffer = (csvString: string) => {
  const arrayOfArrayCsv = csvString.split("\n").map((row: string) => {
    return row.split(",")
  })
  const wb = utils.book_new()
  const newWs = utils.aoa_to_sheet(arrayOfArrayCsv)
  utils.book_append_sheet(wb, newWs)
  const rawExcel = write(wb, { type: "base64" })
  return read(rawExcel)
}
