import { Labels } from "common/labels"
import { CloseModalButtons, ModalContent } from "components/modal/portable-modal"
import { t } from "core/translations"
import { Row } from "reactstrap"
import { ResultDetailComponent, resultModuleProps } from "./result-module"



export const ResultUpdateVendor = (props: resultModuleProps) => {
    const { data, linkViewDetail, onClose } = props
    return (
      <>
        <ModalContent>
          <Row>
            {
              <div className="d-flex flex-column">
                <div className="mb-2 me-2 d-flex">
                  <p className="fw-bold">{t(Labels.total) + ` (${data?.all?.length || 0}): `}</p>
                  <ResultDetailComponent linkViewDetail={linkViewDetail} ids={data?.all} />
                </div>
                <div className="mb-2 me-2 d-flex">
                  <p className="fw-bold">{t(Labels.success) + ` (${data?.success?.length || 0}): `}</p>
                  <ResultDetailComponent linkViewDetail={linkViewDetail} ids={data?.success} />
                </div>
                <div className="mb-2 me-2 d-flex">
                  <p className="fw-bold">{t(Labels.not_found) + ` (${data?.notFound?.length || 0}): `}</p>
                  <ResultDetailComponent linkViewDetail={linkViewDetail} ids={data?.notFound} />
                </div>
                <div className="mb-2 me-2 d-flex">
                  <p className="fw-bold">{t(Labels.skipped) + ` (${data?.skipped?.length || 0}): `}</p>
                  <ResultDetailComponent linkViewDetail={linkViewDetail} ids={data?.skipped} />
                </div>
                <div className="mb-2 me-2 d-flex">
                  <p className="fw-bold mb-1">{t(Labels.failed) + ` (${data?.failed?.length || 0}): `} </p>
                  <ResultDetailComponent linkViewDetail={linkViewDetail} ids={data?.failed} />
                </div>
              </div>
            }
          </Row>
          <CloseModalButtons cancel={onClose} />
        </ModalContent>
      </>
    )
  }