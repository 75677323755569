import { CrudActions } from "./crud.action"
import {
  CreateFulfillmentDto,
  FulfillmentService,
  FulfillmentServices,
  UpdateFulfillmentDto,
} from "../services/fulfillment.service"
import { Filter } from "core/rest-api/query-builder"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { withPermission } from "common/hooks/use-permission"
import {OrderActionEnum} from "../services/order.service";
import {FulfillOrderApiPaths, FulfillOrderServices} from "../services/fulfill-order.service";
import {Toasts} from "../../core/notification/notifications";
import {t} from "../../core/translations";
import {Messages} from "../../common/messages";

class FulfillmentAction extends CrudActions<
  FulfillmentService,
  CreateFulfillmentDto,
  UpdateFulfillmentDto
> {
  constructor() {
    super(FulfillmentServices)
  }

  async search(searchValue: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillmentEntity,
      async () => {
        let filter: Filter<CreateFulfillmentDto> = {}
        if (searchValue) {
          filter.name = { contains: searchValue }
        }
        let pageData = await FulfillmentServices.query({ filter, page: 1 })
        return pageData?.pageItems || []
      })
  }

  async uploadTrackingNumberToChannel(ids: string[] | number[], refresh: Function) {
    return withPermission(
      ActionEntities.update,
      ResourceEntities.fulfillmentEntity,
      async () => {
        let result = await FulfillmentServices.uploadTrackingNumberToChannel(ids)
        if(result?.success) {
          Toasts.success(t(Messages.upload_tracking_to_channel_enqueued, { number: ids.length }))
          refresh && refresh()
        } else {
          Toasts.error(t((Messages.your_request_execute_unsuccessfully)))
        }
      })
  }

  async updateFulfillmentStatus(ids: string[] | number[], refresh: Function) {
    return withPermission(
      ActionEntities.update,
      ResourceEntities.fulfillmentEntity,
      async () => {
        let result = await FulfillmentServices.updateFulfillmentStatus(ids)
        if(result?.success) {
          Toasts.success(t(Messages.update_fulfillment_status_enqueued, { number: ids.length }))
          refresh && refresh()
        } else {
          Toasts.error(t((Messages.your_request_execute_unsuccessfully)))
        }
      })
  }
}

export const FulfillmentActions = new FulfillmentAction()
