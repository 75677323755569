import { RecoilState, useRecoilRefresher_UNSTABLE, useRecoilState, useSetRecoilState } from "recoil"
import { filter, isEmpty, isEqual, omit, pick } from "lodash"
import { BulkEditingState, BulkEditorView, defaultBulkEditingState } from "../../../components/bulk/bulk-editor.type"
import React, {
  createContext,
  createRef, ForwardedRef,
  forwardRef,
  Fragment,
  MutableRefObject,
  RefObject,
  useContext,
  useEffect, useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { BaseBulkEditorProps, createCustomSelectStyles, defaultSubmitDataBuilder, ROW_HEIGHT } from "../../../components/bulk/BulkEditor"
import DataGrid, {
  CalculatedColumn,
  CheckboxFormatterProps,
  Column,
  CopyEvent,
  EditorProps,
  FillEvent,
  HeaderRendererProps,
  PasteEvent,
  RowsChangeData,
  SelectColumn,
  TextEditor
} from "react-data-grid";
import { Link } from "react-router-dom"
import { t } from "core/translations"
import { Button, Card, Input, Label, Row } from "reactstrap";
import { FulfillOrderDto } from "../../../data/services/fulfill-order.service"
import { flattenNestedObject, unflattenNestedObject } from "../../../utils/utils"
import { Labels } from "common/labels"
import { RouteNames } from "routes"
import { Confirmations, Toasts } from "core/notification/notifications";
import { Messages } from "common/messages"
import { BiHistory, BiLinkExternal, BiSave } from "react-icons/bi"
import AsyncSelect from "react-select/async"
import { FulfillServices } from "data/services/fulfill-service.service"
import { FulfillVariantServices } from "data/services/fulfill-variation.service"
import FulfillVariantSelect from "./fulfill-variation.select";
import { FulfillmentOrderDto } from "../../../data/services/fulfillment-order.service";
import { TooltipComponent } from "../../../components/common/TooltipComponent";
import { portableModalState } from "data/atoms/app.atom";
import { BulkUpdateSelectPopup } from "components/modal/bulk-update-popup";
import { FulfillServiceSelect } from "./fulfill-service.select";
import { FulfillmentOrderActions } from "data/actions/fulfillment-order.action";
import DropdownComponent from "components/dropdown/DropdownComponent";
import ReactDataGridCustomize from "components/bulk/react-data-grid-customize";
import { BsClipboardCheck } from "react-icons/bs"
import Select from "react-select";
import { ModalButtons, ModalContent } from "components/modal/portable-modal";
import ActionButton from "components/input/ActionButton";
import { BulkEditingType } from "../fulfill-orders.page";
import { ActionUploadDesign } from "./upload-design";
import { HiOutlineRefresh } from "react-icons/hi";
import { bulkEditFulfillmentOrderSelector } from "data/atoms/fulfillment-order.atom";
import { createLinkEmbedParam } from "utils/embed";

const ActionsComponent = ({ row, onRowChange }: any = {}) => {

  const setPortableModal = useSetRecoilState(portableModalState)
  const [statusSaveAsSuggestion, setStatusSaveAsSuggestion] = useState<Record<string, any>>({loading: false, error: false})

  return (
    <div className="text-center h-100 d-flex justify-content-center align-items-center">
      <span>
        {
          !(["1", "true"].includes(`${row["_bulkUpdated"]}`) && !["1", "true"].includes(`${row["saveAsSuggestion"]}`)) ? null : (
            <div>
	            <Link
              to={"#"}
              onClick={async () => {
                const payload = {
                  productVariation: row.productVariationTitle,
                  fulfillVariantId: row.fulfillVariantId,
                  fulfillServiceId: row.fulfillServiceId,
                  fulfillVariantName: row.fulfillVariant?.variantName,
                  teamId: row.teamId,
                }

                setStatusSaveAsSuggestion((prev) => ({...prev, loading: true}))
                const result: any = await FulfillmentOrderActions.variantMapper(payload)
                setStatusSaveAsSuggestion((prev) => ({...prev, loading: false}))

                if (result?.error?.code === "er_duplicate__resource") {
                  setPortableModal({
                    open: true,
                    title: t(Labels.duplicate_data),
                    content: (
                      <>
                        <ModalContent>
                          {/* <Label>{t(Messages.duplicate_data_variant_mapper, { variantMapper: row.productVariationTitle, newVariantMapper: row.fulfillVariant?.variantTitle })}</Label> */}
                          <Label>
                          Dữ liệu mapper <span className="text-danger">{row.productVariationTitle} {row.fulfillServiceName ? `(${row.fulfillServiceName})` : ""}</span> đã tồn tại. Bạn có muốn ghi đè variant mapping từ dữ liệu cũ sang <span className="text-danger">{row.fulfillVariant?.variantTitle} {row.fulfillVariant?.fulfillService?.id ? `(${row.fulfillVariant?.fulfillService?.name})` : ""}</span> không?
                          </Label>
                        </ModalContent>
                        <ModalButtons hiddenConfirmButton={true} cancelText={Labels.no}>
                          <ActionButton
                            className="btn btn-success"
                            onClick={async () => {
                              const data =  await FulfillmentOrderActions.updateVariantMapper(result.data?.id, payload)
                              if(data?.id) {
                                onRowChange({ ...row, saveAsSuggestion: true, __changedKeys: ["saveAsSuggestion"] }, true)
                                setPortableModal({open: false, title: ''})
                              }
                              else if(result?.error?.code){
                                setStatusSaveAsSuggestion((prev) => ({...prev, error: true}))
                              }
                            }}
                          >
                            {t(Labels.yes)}
                          </ActionButton>
                        </ModalButtons>
                      </>
                    ),
                  })
                }
                else if(result?.error?.code || !result){
                  setStatusSaveAsSuggestion((prev) => ({...prev, error: true}))
                }
                else if (result?.data?.id) {
                  setStatusSaveAsSuggestion((prev) => ({...prev, error: false}))
                  onRowChange({ ...row, saveAsSuggestion: true, __changedKeys: ["saveAsSuggestion"] }, true)
                }
              }}
            >
              {statusSaveAsSuggestion.loading ? (
                <span className="d-flex justify-content-center me-2">
                  <div className="text-center spinner-border"  style={{ width: "1rem", height: "1rem" }} role="status" />
                </span>
              ) : (
                <TooltipComponent
                  tooltip={t(Messages.click_to_unsave_suggest, { productVariationTitle: row["productVariationTitle"] || "" })}
                >
                  <BiSave size={20} className="me-2" color={statusSaveAsSuggestion.error ? 'red' : "blue"} />
                </TooltipComponent>
              )}
            </Link>
          </div>
        )}
        {!(["1", "true", true].includes(`${row["_bulkUpdated"]}`) && ["1", "true", true].includes(`${row["saveAsSuggestion"]}`)) ? null : (
          <div>
            <Link
              to={"#"}
              onClick={() => onRowChange({ ...row, saveAsSuggestion: false, __changedKeys: ["discardSaveAsSuggestion"] }, true)}
            >
              <TooltipComponent tooltip={t(Messages.click_to_discard_suggest)}>
                <BsClipboardCheck size={18} className="me-2" color="blue" />
              </TooltipComponent>
            </Link>
          </div>
        )}
      </span>
      <span>
        {!row["_bulkUpdated"] ? null : (
          <div>
            <Link {...createLinkEmbedParam("#")} onClick={() => {
                setStatusSaveAsSuggestion({loading: false, error: false})
              onRowChange({ ...row, __changedKeys: ["__bulkResetRowChange"] }, true)}
            }>
              <TooltipComponent tooltip={t(Labels.reset_change)}>
                <BiHistory size={22} className="me-2" />
              </TooltipComponent>
            </Link>
          </div>
        )}
      </span>
    </div>
  )
}
const FilterContext = createContext<any>(undefined);

const editFulfillmentOrderColumns: any = (data: any, setFilters: any) => {

  return [
    SelectColumn,
    {
      editable: true,
      key: "saveAsSuggestion",
      minWidth: 80,
      width: 80,
      frozen: true,
      name: t(Labels.actions),
      formatter: ({ row, onRowChange }: any = {}) => <ActionsComponent row={row} onRowChange={onRowChange}/>,
      editor: ({ row, onRowChange }: any = {}) => <ActionsComponent row={row} onRowChange={onRowChange}/>,
      editorOptions: {
        editOnClick: true
      },
      headerCellClass: "filter-cell",
    },
    {
      key: "productVariationTitle",
      minWidth: 350,
      name: t(Labels.product_variation_name),
      headerCellClass: "filter-cell",
      editable: true,
      editor: ({ row, onRowChange }: any = {}) => (
        <Input value={row.productVariationTitle} onChange={() => {}}/>
      ),
      headerRenderer: (p: any) => (
        <FilterRenderer<Row, unknown, HTMLInputElement> {...p}>
          {({ filters, setSelectedRows,...rest }) => (
            <input
              {...rest}
              placeholder={t(Labels.filter_product_variation_title)}
              className={"filterClassname font-size-13"}
              value={filters?.productVariationTitle}
              onChange={(e) => {
                setFilters((prev: any) => ({
                  ...prev,
                  productVariationTitle: e.target.value
                }))
                setSelectedRows(() => new Set()) 
              }}
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      )
    },
    {
      key: "teamId",
      name: t(Labels.vendor),
      editable: true,
      minWidth: 150,
      // eslint-disable-next-line react/display-name
      formatter: ({ row }: any) => {
        return <span>{`${t(Labels.team)} ${row.team?.name || row.teamId || 'Unknown'} ${row.vendor?.name ? "- " + row.vendor?.name : ""}`}</span>
      },
      editor: ({ row, onRowChange }: any = {}) => {
        const value = `${t(Labels.team)} ${row.team?.name || row.teamId || 'Unknown'} ${row.vendor?.name ? "- " + row.vendor?.name : ""}`;
        return <Input value={value} onChange={() => {}}/>
      },
      headerCellClass: "filter-cell",
    },
    {
      key: "customerNote",
      minWidth: 250,
      name: t(Labels.customer_note),
      editable: true,
      editor: ({ row, onRowChange }: any = {}) => (
        <Input value={row.customerNote} onChange={() => {}}/>
      ),
      headerCellClass: "filter-cell",
    },
    {
      key: "productName",
      minWidth: 250,
      name: t(Labels.product_name),
      editable: true,
      editor: ({ row, onRowChange }: any = {}) => (
        <Input value={row.productName} onChange={() => {}}/>
      ),
      headerCellClass: "filter-cell",
    },
    {
      key: "fulfillVariant",
      name: t(Labels.fulfill_variant),
      headerCellClass: "filter-cell",
      minWidth: 400,
      frozen: true,
      // eslint-disable-next-line react/display-name
      formatter: ({ row }: any) => {
        let isValidFulfillVariant = row.fulfillVariantName && row.fulfillServiceName;
        return isValidFulfillVariant ? `${row.fulfillVariantName} (${row.fulfillServiceName})` : (
          <p style={{ opacity: 0.7 }}>{t(Labels.choose_fulfill_variant)}</p>
        )
      },
      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: EditorProps<any>) => {
        return (
          <div style={{ zIndex: Number.MAX_SAFE_INTEGER }}>
            <FulfillVariantSelect
              embedHeight={ROW_HEIGHT - 1}
              value={row.fulfillVariant}
              onChange={optionValue => {onRowChange({ ...row, fulfillVariant: optionValue, __changedKeys: ["fulfillVariant"] }, true)}}
              select
              placeholder={t(Labels.select_fulfill_variant_hint)}
            />;
          </div>
        )
      },
      // editorOptions: { editOnClick: true },
      headerRenderer: (p: any) => {
        const defaultValue = { code: "all", name: "All" }
        const options = data?.reduce(
          (result: Record<string, any>[], item: any) => {
            if (!result.find(fulfillService => fulfillService?.code === item.fulfillService?.code)) {
              result.push(item.fulfillService)
            }
            return result
          },
          [defaultValue]
        )
        return (
          <FilterRenderer<Row, unknown, HTMLInputElement> {...p}>
            {({ filters, setSelectedRows, ...rest }) => {
              return (
                <select
                  value={filters?.fulfillService?.code || defaultValue?.code}
                  style={{inlineSize: '100%'}}
                  onChange={e => {
                    setFilters((prev: any) => ({
                      ...prev,
                      fulfillService: options?.find((option: any) => option?.code === e.target.value),
                    }))
                    setSelectedRows(() => new Set())
                    
                  }}
                >
                  {options?.filter((option: any) => option)?.map((option: any) => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
                </select>
              )
            }}
          </FilterRenderer>
        )
      }
    },
    {
      key: "orderItemSku",
      minWidth: 150,
      name: t(Labels.order_item_sku),
      editable: true,
      editor: ({ row, onRowChange }: any = {}) => (
        <Input value={row.orderItemSku} onChange={() => {}}/>
      ),
      headerCellClass: "filter-cell",

    },
    {
      key: "orderNumber",
      minWidth: 150,
      name: t(Labels.order_number),
      editable: true,
      editor: ({ row, onRowChange }: any = {}) => (
        <Input value={row.orderNumber} onChange={() => {}}/>
      ),
      headerCellClass: "filter-cell",
    },
    {
      key: "id",
      minWidth: 120,
      width: 120,
      editable: false,
      name: t(Labels.id),
      formatter: ({ row }: { row: FulfillOrderDto }) => (
        <>
          <Link
            target="_blank"
            to={createLinkEmbedParam(`${RouteNames.FULFILLMENT_ORDERS_DETAIL.replace(":id", row.id as string)}`).to}
            className="me-1 text-info fw-bold"
          >
            <BiLinkExternal size="16" />
          </Link>
          {row.id}
        </>
      ),
      headerCellClass: "filter-cell",
    },
  ]
}

const createEditData = (data: (any | FulfillmentOrderDto)[]) => {
  // let editData = []
  // for (let item of data) {
  //   const customFieldProperties: any = {}
  //   let editingItem = { ...item, ...customFieldProperties };
  //   editData.push(editingItem)
  // }
  //
  // return editData

  return data
}


export interface FulfillmentOrderBulkEditorRef {
  fillFulfillVariantSuggestion: () => void
  replaceFulfillVariantSuggestion: () => void
  changeFulfillService: () => void
}

export interface FulfillmentOrderBulkEditorProps extends BaseBulkEditorProps<FulfillOrderDto> {
  ref: any
  setBulkEditSelectedIds?: (selectedIds: string[]) => any
}

export const FulfillmentOrderBulkEditor = forwardRef((props: FulfillmentOrderBulkEditorProps, ref: any) => {

  const [filters, setFilters] = useState<Record<string, any>>({
    fulfillService: {name: 'All', code: 'all'},
    productVariationTitle: ""
  });
  const [editData, setEditData] = useState(createEditData(props.data))
  const columns = useMemo(() => editFulfillmentOrderColumns(editData, setFilters), [editData])

  useEffect(() => setEditData(createEditData(props.data)), [props.data])

  const submitDataBuilder = (data: any) => {
    let newData = {} as any

    for (let id in data) {
      let updatedData = data[id]
      let submitData = updatedData
      let rawData: {} = editData.find(item => item.id == id)
      if (rawData) {
        if(updatedData.hasOwnProperty('fulfillService')){
          submitData = omit(updatedData, 'fulfillService')
        }
        if(updatedData.hasOwnProperty('fulfillVariant')){
          submitData = omit(submitData, 'fulfillVariant')
        }
      }
      newData[id] = submitData
    }

    return newData
  }

  const updateRowDataBuilder = (key: string, cellValue: any, rowData: any) => {
    let newData: any = {}
    if (cellValue == null || cellValue == undefined) return newData

    if (key === "fulfillVariant") {
      newData["fulfillVariantId"] = cellValue?.id
      newData["fulfillServiceId"] = cellValue?.fulfillServiceId
      newData['orderId'] = rowData.orderId
      newData['orderItemSku'] = rowData.orderItemSku
      newData['orderNumber'] = rowData.orderNumber
      newData['fulfillOrderId'] = rowData.fulfillOrderId
      newData['productVariation'] = rowData.productVariation
      newData['productName'] = rowData.productName
    }

    return newData
  }

  return (
    <Fragment>
        <FulfillmentOrderGrid
          {...props}
          ref={ref}
          data={editData}
          columns={{ [BulkEditorView.FULFILLMENT_ORDER]: columns }}
          bulkEditSelector={props.bulkEditSelector}
          submitDataBuilder={submitDataBuilder}
          updateRowDataBuilder={updateRowDataBuilder}
          setBulkEditSelectedIds={props.setBulkEditSelectedIds}
          filters={filters}
        />
    </Fragment>
  )
})

interface RowsMultiChangeData<R, SR = unknown> {
  indexes: number[];
  columns: Array<CalculatedColumn<R, SR>>
}

export interface BulkEditorProps<T> extends BaseBulkEditorProps<T> {
  columns: Record<string, Column<T, any>[]>
  customOptions?: any
  nestedFields?: Record<string, string[]>
  customRowDataBuilder?: (currentRowData: T, newRowData: T) => { customOptions: any; newRowData: any }
  updateRowDataBuilder?: (key: string, cellValue: any, rowData: T) => any
  submitDataBuilder?: (data: T, nestedObjectKeys?: string[], rowData?: any) => any
  setBulkEditSelectedIds?: (selectedIds: string[]) => any
  filters: Record<string, any>
}

const FulfillmentOrderGrid = forwardRef((props: BulkEditorProps<any>, ref) => {
  let { submitDataBuilder = defaultSubmitDataBuilder, filters } = props
  const [bulkEditingState, setBulkEditingState] = useRecoilState<BulkEditingState>(props.bulkEditSelector)
  const columns = props.columns[props.editor] || []
  const nestedObjectKeys = (props.nestedFields && props.nestedFields[props.editor]) || []
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(() => new Set());
  const setPortableModal = useSetRecoilState(portableModalState)

  // useEffect(() => {
  //   props.setBulkEditSelectedIds && props.setBulkEditSelectedIds(Array.from(selectedRows))
  // }, [selectedRows])

  useImperativeHandle(ref, () => ({
    changeFulfillService: () => handleChangeFulfillService(),
    fillFulfillVariantSuggestion: () => handleFillFulfillVariantSuggestion(),
    replaceFulfillVariantSuggestion: () => handleFillFulfillVariantSuggestion(true),
  }))

  const handleFillFulfillVariantSuggestion = async (replace?: boolean) => {
    let rowData = []
    const listId = replace ? Array.from(selectedRows) : props.data?.map((item: any) => item.id)
    if(replace && !Array.from(selectedRows).length){
      return Toasts.warning(t(Messages.please_select_least_one_item))
    }
    let responseData = await FulfillmentOrderActions.getSuggestionFulfillVariant(listId, replace)

    for (let item of props.data) {
      if(replace && !selectedRows.has(item.id)) continue;

      let editingItem = { ...item };
      let suggestVariant = responseData && responseData[`${editingItem?.id}`]

      if(!isEmpty(suggestVariant) && ((replace && editingItem.fulfillVariantId != suggestVariant.id) || (!editingItem.fulfillVariantId && !editingItem.fulfillVariant))) {
        editingItem.fulfillVariant = suggestVariant
        editingItem.fulfillVariantId = suggestVariant?.id
        editingItem.fulfillVariantName = suggestVariant.variantName
        editingItem.fulfillService = suggestVariant.fulfillService
        editingItem.fulfillServiceName = suggestVariant.fulfillService?.name || ''
        editingItem.saveAsSuggestion  = false
      } else if(!isEmpty(suggestVariant) && (replace && editingItem.fulfillVariantId == suggestVariant.id)) {
        editingItem = {...item}
        editingItem.__changedKeys  = ["__bulkResetRowChange"]
      }

      rowData.push(editingItem)
    }

    if(rowData?.length) {
      let indexes = rowData.map((item, index) => item.hasOwnProperty('saveAsSuggestion') || item.hasOwnProperty('__changedKeys') ? index : -1).filter(index => index >= 0)
      onRowsChange(rowData, { indexes, columns: [
        { key: 'fulfillVariant' } as any, { key: 'saveAsSuggestion' } as any
      ]}
      )
    }
  }

  const handleChangeFulfillService = () => {
    /**
     * TODO implement change fulfill service
     * B1. Show popup change choose fulfill service
     * B2. Load fulfillVariant for fulfillment order by fulfill service
     * B3. Thực hiện set fulfillVaraint + saveAsSuggestion tương tụ fillFulfillVariantSuggestion
     */
    // Toasts.info(t(Messages.coming_soon))
    let responseData = {} as any
    setPortableModal({
      open: true,
      title: t(Labels.change_fulfill_service),
      content: (
        <BulkUpdateSelectPopup
          placeholder={`${t(Labels.choose_fulfill_service)}...`}
          selectBuilder={(onChange) => (
            <FulfillServiceSelect
              isMulti={false}
              autoFocus={true}
              onChange={onChange}
            />
          )}
          onSubmit={async (value: any) => {
            let listIds = Array.from(selectedRows)
            if (!listIds?.length) {
              Toasts.warning(t(Messages.please_select_least_one_item))
            } else {
              if (value) {
                responseData = await FulfillmentOrderActions.bulkEditChangeFulfillService(listIds, value?.id)
                if (!isEmpty(responseData)) {
                  let rowData = []
                  for (const [key, value] of Object.entries(responseData)) {
                    let editingItem = { ...value as any };
                    let newRowChange = {...props.data?.find((item: any) => {
                      return item.id === key
                    })}
                    
                    newRowChange.fulfillVariant = editingItem
                    newRowChange.fulfillVariantId = editingItem.id
                    newRowChange.fulfillVariantName = editingItem.variantName
                    newRowChange.fulfillServiceId = editingItem.fulfillServiceId
                    newRowChange.fulfillService = editingItem.fulfillService
                    newRowChange.fulfillServiceName = editingItem.fulfillService?.name || ''

                    rowData.push(newRowChange)
                  }
                  if(rowData?.length) {
                    let indexes = rowData.map((item, index) => index)
                    onRowsChange(rowData, { indexes, columns: [
                      { key: 'fulfillVariant' } as any, { key: 'fulfillServiceId' } as any, { key: 'fulfillService' } as any
                    ]}
                    )
                  }
                }
              }
            }
          }}
          confirmText={Labels.change}
          
          // onDone={refresh}
        />
      ),
    })
  }

  const createGridRowData = (filters: any) => {
    let data = props.data || []
    let rows: any[] = []
    let updateDataById = {} as any

    for (let i = 0; i < data.length; i++) {
      let row = {
        ...data[i],
        ...flattenNestedObject(data[i], nestedObjectKeys),
      }


      let updatedData = { ...(bulkEditingState.updatedData[row.id] || {}) }
      if (!isEmpty(updatedData)) {
        updateDataById[row.id] = updatedData
        rows.push({
          ...row,
          ...updatedData,
          _bulkUpdated: true,
        })
      } else {
        rows.push({
          ...row,
          _bulkUpdated: false,
        })
      }
    }

    // rows.forEach((row: any) => {
    //   if (row.fulfillService) {
    //     row.fulfillVariant = {
    //       ...row.fulfillVariant,
    //       fulfillService: row.fulfillService
    //     }
    //   }
    // })
    // console.log('createGridRowData', rows);

    if(filters.productVariationTitle){
      rows = rows.filter((row: any) => row.productVariationTitle?.toLowerCase()?.search(filters.productVariationTitle.toLowerCase()) > -1)
    }

    if(filters.fulfillService?.code !== 'all'){
      rows = rows.filter((row: any) => row.fulfillService?.code === filters.fulfillService?.code)
    }
    if(Array.from(selectedRows)?.length){
      let newSelectedRows = Array.from(selectedRows)?.filter((item) => rows?.find((row: any) => row.id === item))
      !isEqual(Array.from(selectedRows), newSelectedRows) && setSelectedRows(new Set(newSelectedRows))
    }

    return rows
  }

  const onRowsChange = (rows: any, data: RowsMultiChangeData<any> | RowsChangeData<any>) => {
    let indexes = data?.indexes
    let columns = (data as any).column ? [(data as any).column] : (data as any).columns || []

    setBulkEditingState((current: any) => {
      let newBulkUpdateData = { ...current.updatedData }
      for (let index of indexes) {
        let rowData = rows[index]
        if (!rowData) continue
        if (rowData.__changedKeys?.includes("__bulkResetRowChange")) {
          rowData.__changedKeys.length = 0 // Reset array
          newBulkUpdateData = omit(newBulkUpdateData, rowData.id)
          continue
        }

        let changedData = {} as any
        let rowSaveAsSuggestion = {}
        for(let column of columns) {
          if (column) {
            let value = rowData[column.key]
            if (value != null || value != undefined) {
              changedData[column.key] = value
              let customUpdateData = props.updateRowDataBuilder ? props.updateRowDataBuilder(column.key, value, rowData) : {}
              changedData = {
                ...changedData,
                ...(customUpdateData || {}),
              }
            }
          } else if (rowData.__changedKeys) {
            for (let key of rowData.__changedKeys) {
              changedData[key] = rowData[key]
            }
          }

          if(column.key == 'fulfillVariant') {
            changedData['fulfillVariant'] = rowData.fulfillVariant
            changedData['fulfillVariantName'] = rowData.fulfillVariant?.variantName || ''
            changedData['fulfillServiceName'] = rowData.fulfillVariant?.fulfillService?.name || rowData.fulfillServiceName || ''
            changedData['designSku'] = rowData.designSku
            changedData['saveAsSuggestion'] = false
            // changedData['suggestion'] = true
          }
          if (rowData.__changedKeys?.includes("saveAsSuggestion")) {
            changedData['saveAsSuggestion'] = true
            // rowSaveAsSuggestion = rows?.reduce((result: any , row: any) => {
            //   if(row._bulkUpdated && row.fulfillVariant && row.productVariationTitle === rowData.productVariationTitle){
            //     return {...result, [row.id]: {...row, saveAsSuggestion: true}}
            //   }
            //   return result
            // }, {})
            continue
          }
          if (rowData.__changedKeys?.includes("discardSaveAsSuggestion")) {
            changedData['saveAsSuggestion'] = false
            // rowSaveAsSuggestion = rows?.reduce((result: any , row: any) => {
            //   if(row._bulkUpdated && row.fulfillVariant && row.productVariationTitle === rowData.productVariationTitle){
            //     return {...result, [row.id]: {...row, saveAsSuggestion: false}}
            //   }
            //   return result
            // }, {})
            continue
          }
        }

        let nestedChangeData = unflattenNestedObject(rowData, changedData, nestedObjectKeys)
        newBulkUpdateData = {
          ...newBulkUpdateData,
          [rowData.id]: { ...(bulkEditingState.updatedData[rowData.id] || {}), ...changedData, ...nestedChangeData },
          ...rowSaveAsSuggestion
        }
      }

      let newSubmitData = submitDataBuilder(newBulkUpdateData, nestedObjectKeys)
      return {
        ...current,
        updatedData: newBulkUpdateData,
        submitData: newSubmitData,
      }
    })
  }

  function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<any>) {

    if (columnKey === "fulfillVariant") {
      const fulfillVariant = sourceRow[columnKey]
      return {
        ...targetRow,
        [columnKey]: fulfillVariant,
        fulfillVariantId: fulfillVariant?.id,
        fulfillVariantName: fulfillVariant?.variantName,
        fulfillServiceId: sourceRow.fulfillServiceId,
        fulfillService: sourceRow.fulfillService,
        fulfillServiceName: sourceRow.fulfillServiceName,
      }
    }
    return { ...targetRow, [columnKey]: sourceRow[columnKey] }
  }

  const handlePaste = ({ sourceColumnKey, sourceRow, targetColumnKey, targetRow }: PasteEvent<any>) => {
    const incompatibleColumns = ["id"]
    if (
      sourceColumnKey !== targetColumnKey &&
      (incompatibleColumns.includes(targetColumnKey) || incompatibleColumns.includes(sourceColumnKey))
    ) {
      return targetRow
    }

    if (sourceColumnKey === "fulfillVariant") {
      const fulfillVariant = sourceRow[sourceColumnKey]
      return {
        ...targetRow,
        [targetColumnKey]: fulfillVariant,
        fulfillVariantId: fulfillVariant?.id,
        fulfillVariantName: fulfillVariant?.variantName,
        fulfillServiceId: sourceRow.fulfillServiceId,
        fulfillService: sourceRow.fulfillService,
        fulfillServiceName: sourceRow.fulfillServiceName,
      }
    }
    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] }
  }

  function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<any>): void {
    if (window.isSecureContext) {
      let value = sourceRow[sourceColumnKey]
      navigator.clipboard.writeText(value)
    }
  }

  function rowKeyGetter(row: any) {
    return row.id
  }

  return (
    <div style={{ height: "100%" }}>
      <Card>
        <FilterContext.Provider value={{filters, setSelectedRows}}>
          <ReactDataGridCustomize
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            columns={columns}
            rows={createGridRowData(filters)}
            rowKeyGetter={rowKeyGetter}
            onRowsChange={onRowsChange}
            onFill={handleFill}
            onCopy={handleCopy}
            onPaste={handlePaste}
            rowHeight={ROW_HEIGHT}
            defaultColumnOptions={{ resizable: true }}
            className={"fill-grid rdg-dark rdg-light RDGcustomCheckbox"}
            style={{ height: "100%", blockSize: "100%" }}
            rowClass={(row: any) => (row._bulkUpdated ? "react-data-grid-updated-row" : "react-data-grid-normal-row")}
            headerRowHeight={ROW_HEIGHT * 2}
          />
        </FilterContext.Provider>
      </Card>
    </div>
  )
})

function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.stopPropagation();
  }
}


function FilterRenderer<R, SR, T extends HTMLOrSVGElement>({
  isCellSelected,
  column,
  children
}: HeaderRendererProps<R, SR> & {
  children: (args: {
    filters: any;
    setSelectedRows: any
  }) => React.ReactElement;
}) {
  const {filters, setSelectedRows} = useContext(FilterContext);

  return (
    <>
      <div>{column.name}</div>
      {<div>{children({ filters, setSelectedRows })}</div>}
    </>
  );
}

export interface BulkEditActionFulfillmentOrderProps<T> {
  bulkEditSelector: RecoilState<T>
  dataRefresh: () => void
  onSubmit: (data: Record<string, T>) => Promise<boolean | undefined> // true if updated, false otherwise
  onExit: () => void
  editorRef: RefObject<FulfillmentOrderBulkEditorRef>
  handleChangeFulfillService?: () => void
  bulkEditorState?: BulkEditingType | any
}

export const FulfillmentOrderBulkEditorActions = (props: BulkEditActionFulfillmentOrderProps<any>) => {
  const [updating, setUpdating] = useState(false)
  const [bulkEditingState, setBulkEditingState] = useRecoilState<BulkEditingState>(props.bulkEditSelector)
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(bulkEditFulfillmentOrderSelector([] as any));


  const handleSaveBulkUpdateData = async () => {
    try {
      setUpdating(true)
      let updated = await props.onSubmit(bulkEditingState.submitData)
      if (updated) {
        setBulkEditingState((current: any) => ({ ...current, ...defaultBulkEditingState, updatedData: {} }))
        props.dataRefresh()
      }
    } finally {
      setUpdating(false)
    }
  }

  const handleCancelBulkEdit = () => {
    if (!isEmpty(bulkEditingState.submitData)) {
      Confirmations.confirm(t(Messages.do_you_want_to_discard_the_updated_information), () => {
        setBulkEditingState(defaultBulkEditingState)
        props.onExit()
      })
    } else {
      setBulkEditingState(defaultBulkEditingState)
      props.onExit()
    }
  }

  return (
    <Fragment>
      {props.bulkEditorState?.editor === BulkEditorView.EDIT_DESIGN_OPTION ? <ActionUploadDesign actions={props} updating={updating} /> : (
        <DropdownComponent onMenuItemClick="toggle" alignRight>
          <DropdownComponent.Toggle>
            <Button outline color="secondary" className="me-2 h-100">
              {t(Labels.actions)}
              <i className="bx bx-caret-down font-size-16 align-middle" />
            </Button>
          </DropdownComponent.Toggle>
          <DropdownComponent.Menu>
            <div className="mb-0 p-2 overflow-auto bg-white" style={{ maxHeight: 295 }}>
              <Button
                color="light"
                outline
                className="border-0 d-block w-100 text-start"
                disabled={updating}
                onClick={() => {
                  props.editorRef.current?.changeFulfillService()
                }}
              >
                {t(Labels.change_fulfill_service)}
              </Button>
              <Button
                color="light"
                outline
                className="border-0 d-block w-100 text-start"
                disabled={updating}
                onClick={() => {
                  props.editorRef.current?.fillFulfillVariantSuggestion()
                }}
              >
                {t(Labels.fill_suggestion)}
              </Button>
              <Button
                color="light"
                outline
                className="border-0 d-block w-100 text-start"
                disabled={updating}
                onClick={() => {
                  props.editorRef.current?.replaceFulfillVariantSuggestion()
                }}
              >
                {t(Labels.replace_with_suggestion)}
              </Button>
            </div>
          </DropdownComponent.Menu>
        </DropdownComponent>
      )}
      {/* <Button
        color="info"
        className="me-2"
        disabled={updating}
        onClick={() => {
          props.editorRef.current?.changeFulfillService()
          // props.handleChangeFulfillService ? props.handleChangeFulfillService() : null
        }}
      >
        {t(Labels.change_fulfill_service)}
      </Button>
      <Button
        color="primary"
        className="me-2"
        disabled={updating}
        onClick={() => {
          props.editorRef.current?.fillFulfillVariantSuggestion()
        }}
      >
        {t(Labels.fill_suggestion)}
      </Button> */}
      <Button color="info" className="me-2" disabled={updating} onClick={() => fakeRefreshDataLoadable()}>
        <HiOutlineRefresh size={16} className="me-1 mb-1" />
        {t(Labels.refresh)}
      </Button>
      <Button color="success" className="me-2" disabled={updating} onClick={handleSaveBulkUpdateData}>
        <i className="bx bx-save pt-1 me-1"></i>
        {t(Labels.save)}
      </Button>
      <Button color="danger" className="me-2" disabled={updating} onClick={handleCancelBulkEdit}>
        <i className="bx bx-x pt-1 me-1"></i>
        {t(Labels.close)}
      </Button>
    </Fragment>
  )
}
