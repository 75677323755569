export const Labels = {
  app_name: 'Grcommerce',
  app_title: 'Grcommerce Admin',
  welcome: "Wellcome",
  welcome_hint: "Sign-in with Grcommerce",
  username: 'User Name',
  username_hint: 'Input user name',
  username_required: "Please input username",
  password: 'Password',
  password_hint: 'Input password',
  password_required: "Please input the Password",
  register_now: "Register now",
  does_not_have_account: "You don't have account, please contact admin to get one now!",
  forgot_password: "Forgot your password?",
  login: "Login",
  remember_signin: "Remember Me",
  home: "Home",
  orders: "Orders",
  settings: "Settings",
  setting: "Setting",
  woocommerce_store: "Woocommerce",
  customers: "Customers",
  accounts: "Accounts",
  fulfillments: "Fulfillments",
  fulfillment: "Fulfillment",
  products: "Products",
  order_items: "Order Items",
  fulfill_variants: "Fulfillment Variants",
  product_types: "Product Types",
  channel_orders: "Channel Orders",
  storage: "Storage",
  storage_deprecated: "Storage (Deprecated)",
  artworks: "Artworks",
  fulfill_products: "Fulfillment Products",
  fulfill_services: "Fulfill Services",
  all_orders: "All orders",
  no_data_available: "No data avaiable",
  more_filters: "More filters",
  sort: "Sort",
  reset: "Reset",
  reset_all: "Reset all",
  apply: "Apply",
  choose_actions: "Choose actions",
  sort_order_create_newest: "Created Order (newest first)",
  sort_order_create_oldest: "Created Order (oldest first)",
  sort_created_newest: "Created (newest first)",
  sort_created_oldest: "Created (oldest first)",
  sort_updated_newest: "Updated (newest first)",
  sort_updated_oldest: "Updated (oldest first)",
  sort_key_field_asc: "Sort {{field}} A-Z",
  sort_key_field_desc: "Sort {{field}} Z-A",
  code_required: "Please input value for Code",
  regular_price_required: "Please input value for Regular price",
  name_required: "Please input value for Name",
  fulfill_product_required: "Please input fulfill product",
  fulfill_service_required: "Please input fulfill service",
  option_name_required: "Please input option name",
  product_type_option_required: "Please input key for product type option",
  product_type_values_required: "Please input value for product type",
  variant_options_required: "Please input 1 option at least",
  title_sale_channel: "Sale Channels",
  sale_channels_name: "Sale channel name",
  api_key_required: "Please input api key",
  domain_required: "Please input domain",
  api_secret_required: "Please input api secret",
  create_new: "Create",
  bulk_create: "Bulk Create",
  create: 'Create',
  domain: "Domain",
  api_key: "API key",
  api_secret:"API Secret",
  search_by_name: "Search by name",
  title_create_channel: "Create sale channel",
  title_edit_channel: "Edit sale channel",
  automatic_data_sync: "Enable auto sync sale channel orders",
  sync_orders: "Sync Orders",
  completion_time: "Completed time",
  sync_orders_status: "Sync orders status",
  detail:"Details",
  delete_selected_item:"Remove selected",
  recreate_order_item: "Update Order Item Data",
  create_order_item: "Create Order Item",
  // xuanduc edit
  fulfill_product_select_required: "Please choose the fulfill product",
  fulfill_variant_select_required: "Please choose the fulfill variant",
  product_type_value_required: "Please choose the Product Type",
  artwork_templates: "Artwork Template",
  fulfillment_tracking:"Fulfillment Tracking",
  product: "Product",
  funnel_product: "Funnel Product",
  funnel_product_type: "Funnel Product Type",
  sale_channel_required: "Please input 1 Sale Channel at least" ,
  name: 'Name',
  product_type_hint: "Input Product Type Name",
  fulfill_product: "Fulfillment Product",
  default_fulfill_product: "Default Fulfillment Product",
  fulfillable_Products: "Fulfillable Products", 
  sku: 'Sku',
  title: 'Title',
  description: 'Description',
  regular_price: 'Regular Price',
  sale_price: "Sale Price",
  images: 'Images',
  delete_image: 'Delete image',
  add_media_from_url: 'Add media from URL',
  product_status: 'Product status',
  publish_status: 'Publish Status',
  stores: 'Stores',
  product_organization: 'Product Organization',
  artwork_desgin: 'Artwork Design',
  vendor: 'Vendor',
  categories: 'Categories',
  tags: 'Tags',
  line_items: 'Line items',
  order_number: 'Order Number',
  quantity: 'Quantity',
  status: 'Status',
  save: 'Save',
  clone: 'Clone',
  order_information: 'Order Information',
  order: 'Order',
  product_name: 'Product Name',
  fulfill_information: 'Fulfill Information',
  fulfill_skus: 'Fulfill Skus',
  fulfill_sku: 'Fulfill Sku',
  fulfill_sku_hint: 'Input Fulfill Sku',
  artwork_sku: 'Artwork Sku',
  artwork_sku_hint: 'Input Artwork Sku',
  artwork_url: 'Artwork Url',
  default_artwork_url: 'Default Artwork Url',
  artwork_url_hint: 'Input Artwork Url',
  mockup_url: 'Mockup Url',
  mockup_url_hint: 'Input Mockup Url',
  design_url: 'Design Url',
  fulfill_mockup: 'Fulfill Mockup',
  fulfill_design: 'Fulfill Design',
  shipping_information: 'Shipping Information',
  shipping_method: 'Shipping Method',
  shipping_method_hint: 'Input Shipping Method',
  phone: 'Phone',
  phone_hint: 'Input Phone Number',
  first_name: 'First Name',
  first_name_hint: 'Input First Name',
  last_name: 'Last Name',
  last_name_hint: 'Input Last Name',
  company: 'Company',
  company_hint: 'Input Company',
  address: 'Address',
  address_hint: 'Input Address',
  city: 'City',
  city_hint: 'Input City',
  state: 'State',
  state_hint: 'Input State',
  country_code: 'Country',
  province_code: 'Province Code',
  zip_code: 'Zip Code',
  country_code_hint: 'Input Country',
  postcode: 'Postcode',
  postcode_hint: 'Input Postcode',
  fulfill_status: 'Fulfill Status',
  fulfill_status_hint: 'Input Fulfill Status',
  status_issue: 'Status Issue',
  status_issue_hint: 'Input Status Issue',
  degign_status: 'Design Status',
  desgin_status_hint: 'Input Design Status',
  customer_note: 'Customer Note',
  tracking_status: 'Tracking status',
  order_note: 'Order Note',
  fulfill_design_issue: 'Fulfill Design Issue',
  notes: 'Notes',
  notes_hint: 'Input Notes',
  base_cost: 'Base Cost',
  base_cost_hint: 'Input Base Cost',
  shipment_status: 'Shipment Status',
  shipment_status_hint: 'Input Shipment Status',
  tracking_number: 'Tracking Number',
  tracking_number_hint: 'Input Tracking Number',
  carrier_name: 'Carrier Name',
  carrier_name_hint: 'Input Carrier Name',
  carrier_code: 'Carrier Code',
  carrier_code_hint: 'Input Carrier Code',
  select_order: 'Select Order',
  select_order_hint: 'Input Select Order',
  edit: "Edit",
  shipment: 'Shipment',
  tracking_url: 'Tracking Url',
  tracking_url_hint: 'Input Tracking Url',
  option_name: 'Option Name',
  option_name_hint: 'Input Option Name',
  option_values: 'Option Values',
  option_values_hint: 'Input Option Values',
  options: 'Options',
  edit_fulfill_product: "Edit Fulfillment Product",
  product_type_option: 'Product type option',
  edit_fulfill_variant: "Edit Fulfill Variation",
  create_fulfill_variant: "Create Fulfill Variation",
  variant_options: 'Variant Options',
  email: 'Email',
  role: 'Role',
  edit_account: "Edit Account",
  storage_setting: 'Storage Setting',
  type: 'Type',
  credentials: 'Credentials',
  upload_drive_id: 'Upload Drive Id',
  upload_drive_id_hint: 'Input Drive Id',
  read_only_drive_id: 'Read Only Drive Id',
  read_only_drive_id_hint: 'Input Read Only Drive Id',
  upload_folder_name: 'Upload Folder Name',
  folder_name_hint: 'Input Folder Name',
  channel_order_setting: "Channel Order Setting",
  artwork_sku_splitter: 'Artwork Sku Splitter',
  customization_fee_sku: "Customization Fee Sku",
  custom_data_keys: "Custom Data Keys",
  variant_option_keys: "Variant Option Keys",
  tracking_services: "Tracking Services",
  tracking_services_hint: "Input Tracking Services",
  titles: 'Titles',
  order_permission: 'Order Permission',
  order_permission_hint: 'Input Order Permission',
  storage_permission: 'Storage Permission',
  storage_permission_hint: 'Input Storage Permission',
  sale_channel_permission: 'Sale Channel Permission',
  sale_channel_permission_hint: 'Input Sale Channel Permission',
  sync_all: "Sync All",
  issue_and_design: "Issue/Design",
  total_price: "Total Price",
  order_time: "Order Time",
  order_date: "Order Created Date",
  design_status: "Design Status",
  design_file_status: "Design File Status",
  fulfill_status_issue: "Fulfill Status Issue",
  line_item_count: "Line Item Count",
  order_same_customer: "Order Same Customer",
  fulfill_service: "Fulfill Service",
  design_and_mockup: 'Design/Mockup',
  artwork: 'Artwork',
  customize: 'Customize',
  customize_text: 'Customize Text',
  change_fulfill_status: 'Change Fulfill Status',
  change_fulfill_status_issue: "Change Fulfill Status Issue",
  change_fulfill_service: "Change Fulfill Service",
  choose_fulfill_service: "Choose Fulfill Service",
  variant_title: 'Variant Title',
  edit_shipping_address: 'Edit Shipping Address',
  edit_fulfill_information: 'Edit Fulfill Information',
  create_fulfill_design: 'Upload Fulfill Design',
  request_fulfill: 'Request Fulfill',
  set_fulfill_design_url: 'Set Fulfill Design Url',
  update_fulfill_design_by_sku: 'Update Fulfill Design By SKU',
  export_for_fulfill_service: "Export For Fulfill Service",
  export_for_auto_design: "Export For Auto Design",
  export_personal_custom_design: "Export Personal Custom Design",
  all: 'All',
  import: 'Import',
  export_all: "Export All",
  export_run_design_auto: "Export for auto design (deprecated)",
  change_fulfill_design_status: "Change Fulfill Design Status",
  choose_design_status: "Choose design status",
  artwork_mockup: 'Artwork Mockup',
  one_line: 'One Line',
  multi_lines: 'Multi Lines',
  one_order: 'One Order',
  multi_orders: 'Multi Orders',
  yes: 'Yes',
  no: 'No',
  channel_uploaded: 'Channel uploaded',
  missing_fulfill_design: "Missing fulfill design",
  has_fulfill_design: "Has Fulfill Design",
  has_design_mockup_error: "Has Design Mockup Error",
  no_artwork: "No Artwork",
  has_artwork: "Has Artwork",
  has_artwork_no_img_url: "Has Artwork (No Image Url)",
  no_custom: 'No Custom',
  default_custom: 'Default Custom',
  generated_custom: 'Generated Custom',
  personal_custom: "Personal Custom",
  unknown_artwork: 'Unknown Artwork',
  fulfill_time: 'Fulfill Time',
  option_keys: "Option Keys",
  product_type_key: "Product Type Key",
  fulfill_variant_sku: "Fulfillment Variant Sku",
  change_base_cost: "Change base cost",
  id: 'Id',
  code: "Code",
  custom_sku_position: 'Custom Sku position',
  last_login: 'Last Login',
  last_sync: 'Last Sync',
  action: 'Action',
  search_by_fulfill_sku: "Search by fulfill Sku",
  change_password: 'Change password',
  change_teams: 'Switch Workspace',
  choose_teams: 'Choose Workspace',
  logout: 'Logout',
  action_running: "Please wait...",
  reset_change: "Reset change",
  actions: 'Actions',
  cancelled: 'Cancelled',
  replace_existed_data: "Replace existed data",
  in_progress: 'In progress',
  input_data: 'Import data',
  drop_or_click_to_upload: 'Drop or click to upload',
  close: 'Close',
  closed: 'Closed',
  click_to_view_a: 'Click to view a ',
  sample_CSV_template: 'sample CSV template',
  to_see_an_example_of_format_required: ' to see an example of the format required',
  notifications: 'Notifications',
  order_placed: 'Your order is placed',
  if_serveral_languages_cloalesce_grammar: 'If several languages coalesce the grammar',
  three_min_ago: "3 min ago",
  it_will_seem_like_simplified_english: 'It will seem like simplified English',
  one_hour_ago: '1 hour ago',
  item_shipped: "Your item is shipped",
  view_all: 'View all',
  as_a_skeptical_cambridge_friend_of_mine_occidental: 'As a skeptical Cambridge friend of mine occidental',
  old_password: 'Old Password',
  old_password_hint: 'Input Old Password',
  new_password: 'New Password',
  new_password_hint: 'Input New Password',
  reenter_password: 'Retype Password',
  reenter_password_hint: 'Input the retype password',
  reentered_password_doesnt_match: 'Password re-enter does not match',
  paid_custom_fee: 'Paid custom fee',
  parent_order_item: 'Order Item Id',
  order_item_information: 'Order Item Information',
  address_1: 'Address 1',
  address_2: 'Address 2',
  custom_text: 'Custom Text',
  preview_image: 'Preview image',
  production_image: 'Production image',
  fulfill_variant: 'Fulfillment Variant',
  variantion: 'Variation',
  modify_note: 'Modify Note',
  stage: "Stage",
  output_folder: 'Output Folder',
  output_folder_hit: 'Input Output Folder',
  desgin_output_folder: 'Design Output Folder',
  config_path_template: 'Config Path Template',
  config_path_template_hint: 'Input Config Path Template',
  number_of_option: 'Number of Option',
  number_of_option_hint: 'Input Number of Option',
  export: 'Export',
  export_only: 'Export Only',
  export_and_mark_as_submitting: 'Export & Mark as submitting',
  import_fulfilling_order: 'Import fulfilling order',
  import_fulfilled_order: "Import fulfilled order (tracking)",
  import_product_type: "Import product type",
  import_fulfill_product: "Import fulfill product",
  import_artwork: "Import artwork",
  import_fulfilling_order_from_excel: "Import fulfilling order from excel file",
  import_fulfilled_order_from_excel: "Import fulfilled order (tracking) from excel file",
  import_product_type_from_excel: "Import product type from excel file",
  import_fulfill_product_from_excel: "Import fulfill product from excel file",
  import_artwork_from_excel: "Import artwork data from excel file",
  hide_remaining_line_item: "Hide remaining line lineItem meta...",
  show_remaining_line_item: "Show remaining line lineItem meta...",
  subtotal: 'Subtotal',
  shipping: "Shipping",
  total: 'Total',
  custom_fields: 'Custom Fields',
  order_status: "Order status",
  fulfillment_status: 'Fulfillment status',
  customer_notes: "Customer Notes",
  no_note_customer: "No notes from customer",
  view_on_metorik: "View on Metorik",
  view_on_store_admin: "View on Store Admin",
  contact_infomation: "Contact information",
  shipping_address: 'Shipping address',
  billing_address: "Billing address",
  channel_order: 'Channel Order',
  date: 'Date',
  items: 'Items',
  customer: 'Customer',
  create_order_items: 'Create Order Items',
  change_order_status: 'Change order status',
  change_fulfillment_status: 'Change fulfillment status',
  num: "Num",
  custom_template: "Custom Template",
  custom_template_hint: "Choose custom template...",
  customily_template: "Customily Template",
  customily_template_hint: "Choose Customily Template",
  customily_product_id: "Customily Product Id",
  bulk_create_artwork: 'Bulk Create Artwork',
  tracking_more: "Tracking More",
  grcommerce_tracking: "Grcommerce Tracking",
  not_eligible: "Error order",
  new_order: 'New order',
  wait_for_fulfill: 'Wait for fulfill',
  fulfilling: "Fulfilling",
  failure_fulfill: 'Failed',
  fulfilled: 'Fulfilled',
  on_hold: 'On Hold',
  wait_for_design: "Wait for design",
  designing: 'Designing',
  designing_auto: 'Designing (Auto)',
  designed: 'Designed',
  designed_auto: 'Designed (Auto)',
  generated: 'Generated',
  wait_for_review: 'Wait for review',
  ok: 'Ok',
  no_line_items: 'No line items',
  multi_fulfill_product: 'Multi fulfill product',
  no_fulfill_product: 'No fulfill product',
  multi_design_same_sku: 'Multi design same Sku',
  key: 'Key',
  value: 'Value',
  key_duplicated: 'Key duplicated',
  add_property: 'Add property',
  something_went_wrong: 'Oops! Something went wrong...',
  back_to_home: 'Back to Home',
  try_again: 'Try Again',
  bucket_id: 'Bucket Id',
  no_artwork_template: 'No Artwork Template',
  variant: 'Variant',
  variants: 'Variants',
  draft: 'Draft',
  active: 'Active',
  published: 'Published',
  partial: 'Partial',
  pending: 'Pending',
  add_image: 'Add Images',
  list_url: "List URL",
  product_type: "Product Type",
  product_type_and_artwork: 'Product Type / Artwork',
  product_title_and_sku: 'Product Title / Sku',
  image: 'Image',
  read: 'Read',
  write: 'Write',
  read_write: 'Read/Write',
  custom_option: 'Custom Option',
  default_image_suffixes: 'Default image suffixes',
  sku_uppercase: 'SKU',
  metafields: 'Metafieds',
  publication: 'Publication',
  published_channel: 'Published channel',
  publish_issues: 'Publish issues',
  publish_products: 'Publish products',
  type_option_values: 'Type option values',
  edit_product_type: 'Edit product type',
  create_product_type: 'Create product type',
  general_information: 'General information',
  variant_option_values_note: 'If you want the variant option value getting the default value by option key, please left it empty',
  add_variant_option: "Add variant option",
  unknown: 'Unknown',
  normal_production: 'Normal production',
  fast_production: 'Fast production',
  group: 'Group',
  estimate_product_time: 'Estimate production time',
  image_suffixes: 'Image Suffixes',
  default_option_values: 'Default option values',
  default_price: 'Default price',
  default_price_hint: 'Input default price',
  fulfill_option: 'Fulfill option',
  select: 'Select',
  fulfill_value: "Fulfill value",
  value_by_store: 'Value by store',
  variant_option_values: 'Variant option values',
  default_value: 'Default value',
  default_value_hint: 'Input default value',
  view: 'View',
  variant_option_value_transform_table: 'Variant option value transform table',
  variant_option_value_transform_desc: 'Please input only the value need to be transformed, default left it empty',
  fulfill_variant_mapping: 'Fulfill variants mapping',
  add_fulfill_variant_mapping: 'Add fulfill variants mapping',
  add_value_transform: 'Add value transform',
  artwork_template: "Artwork Template",
  design: 'Design',
  artwork_info: 'Artwork information',
  artwork_store_service: "Artwork Store service",
  product_sku: 'Product Sku',
  status_and_publish: 'Status/Publish',
  use_custom_options:'Use custom options',
  has_custom_option: 'Has custom option',
  no_custom_option: 'No custom',
  custom_option_keys: 'Custom option keys',
  custom_option_keys_required: 'Custom option keys is required',
  custom_option_count: 'Custom option count',
  artwork_templates_name: 'Artwork Templates Name',
  design_sku: 'Design SKU',
  no_design_image_url_for: 'No design image url for',
  design_and_customize: 'Design & Customize',
  default_image_base_url: 'Default Image Base Url',
  storage_service: 'Storage Service',
  view_design: "View design",
  issue: 'Issue',
  welcome_home_page: 'Welcome to Grcommerce Admin',
  cancel: 'Cancel',
  product_sku_hint: "Input product sku",
  product_title_hint: "Input product title",
  regular_price_hint: "Input product regular price",
  sale_price_hint: "Input product sale price",
  categories_hint: "Input categories",
  tags_hint: "Input tags",
  product_name_hint: 'Input Product Name',
  fulfill_information_hint: 'Input Fulfill Information',
  fulfill_variants_hint: "Input Fulfillment Variants",
  fulfill_services_hint: "Input Fulfill Services",
  quantity_hint: 'Input Quantity',
  design_url_hint: 'Input Design Url',
  fulfill_mockup_hint: 'Input Fulfill Mockup',
  fulfill_design_hint: 'Input Fulfill Design',
  status_hint: 'Input Status',
  fulfill_product_sku_hint: "Input the fulfill product sku",
  account_name_hint: "Input the account name",
  email_hint: 'Input the account email',
  api_key_hint: "Input API key",
  base_api_url: "Base Api Url",
  base_api_url_hint: "Input Base Api Url",
  sale_channels_name_hint: "Input sale channel name",
  domain_hint: "Input domain",
  api_secret_hint: 'Input API secret',
  choose_status_hint: "Choose status",
  choose_status_issue_hint: "Choose status issue",
  new_base_cost_hint: 'Input the new base cost',
  search_by_variation: 'Search by variation',
  search_by: 'Search by',
  select_account_role_hint: 'Select the account role',
  select_fulfill_product_hint: "Choose fulfill product",
  select_fulfill_variant_hint: "Choose fulfill variant",
  select_provider_hint: 'Select fulfill service',
  product_types_hint: "Choose Product Types",
  search_by_username: 'Search by username',
  sale_channels_select_hint:'Select sale channels',
  template_name: 'Template name',
  publish: 'Publish',
  custom_type: 'Custom Type',
  product_type_name: 'Product Type Name',
  product_type_value: 'Product Type Value',
  product_type_code: 'Product Type Code',
  product_type_code_hint: 'Input the product type code',
  product_type_name_hint: 'Input the product type name',
  search_by_product_type_name: 'Search by product type name',
  product_type_regular_price_hint: 'Input the product type regular price',
  product_type_sale_price_hint: 'Input the product type sale price',
  product_type_group_hint: 'Input the product type group',
  estimate_production_time_hint: 'Input the product type estimate production time',
  fulfill_option_hint: 'Select Fulfill option',
  product_type_image_suffixes_hint: 'Input the product type default image suffixes',
  variant_option_keys_hint: 'Input variant option keys',
  product_type_desc_hint: 'Input the product type description',
  option_value_hint: 'Select from option value',
  variant_option_values_hint: 'Input variant option values',
  variant_option_hint: 'Style, Size, Color...',
  artwork_title_hint: "Input artwork title",
  artwork_categories_hint: "Input artwork categories",
  artwork_tags_hint: "Input artwork tags",
  url_hint: 'Input url',
  customily_product_id_hint: "Input Customily Product Id",
  default_image_base_url_hint: 'Input the product type default image base url',
  artwork_template_hint: "Input artwork template",
  artwork_description_template_hint: "Input artwork template description",
  choose_product_type: 'Choose product type',
  upsell_order: "Upsell Order",
  has_upsell_order: "Has Upsell Order",
  parent_order: "Parent Order",
  add_column: 'Add Column',
  clear_all_filter: 'Clear all filter',
  upload_tracking_to_channel: 'Upload Tracking To Store',
  update_fulfillment_status: 'Update fulfillment status',
  default_sku: "Default SKU",
  default_sku_input_help: "Default SKU used when the line item SKU is empty in Error Order, left it empty by default for otherwise.",
  default_sku_input_hint: "Input default SKU",
  fulfill: 'Fulfill',
  wait_for_export: "Wait for export",
  fulfill_processing: "Fulfill processing",
  fulfill_rejected: "Fulfill rejected",
  replace_product_images: "Replace existed images in the product",
  publish_and_replace_images: "Publish & Replace Images",
  any_select_hint: 'Select ',
  sku_hint: 'Input Sku',
  deprecated_options: 'Deprecated options',
  choose_fulfill_variants:"Choose Fulfillment Variants",
  fulfill_option_label: "Fulfill Options",
  columns: 'Columns',
  reset_to_default: 'Reset to Default',
  billing: 'Billing',
  product_variant: 'Product Variant',
  bulk_edit: "Bulk Edit",
  other_action: "Other Action",
  product_title : "Product Title",
  publishing: "Publishing",
  publish_error: "Publish Error",
  message: "Message",
  default_image_by_product_type: "Default image by product type",
  shipment_tracking: "Shipment Tracking",
  edit_personal_custom_data: 'Edit Personal Custom Data',
  custom_status: 'Custom Status',
  messages: "Messages",
  unused_custom_fields: "Unused Custom Fields",
  unpaid_custom_fields: "Unpaid Custom Fields",
  create_personal_custom_data: "Create Personal Custom Data",
  reviewed: "Reviewed",
  custom_created: "Custom created",
  custom_not_created: "Custom isn't created",
  personal_custom_data: "Personal Custom Data",
  personal_custom_status: "Personal Custom Status",
  custom_review: "Custom review",
  custom_fee :"Custom Fee",
  current: "Current",
  by_artwork: "By artwork",
  all_custom_fields: "All custom fields",
  products_types: 'Product Types',
  fulfillment_orders: 'Fulfillment Orders',
  fulfiller: 'Fulfiller',
  fulfiller_hint: 'Input Fulfiller',
  submit_time: 'Submit Time',
  completed_time: 'Completed Time',
  empty: 'Empty',
  // create_fulfillment_order: 'Create Fulfillment Order',
  create_fulfillment_order: 'Create Fulfillment Order',
  // create_fulfillment_order: 'Create Fulfillment Order',
  change_fulfill_variant: 'Change Fulfillment Variant',
  edit_fulfillment_order: 'Edit Fulfillment Order',
  // edit_fulfillment_order: 'Edit Fulfillment Order',
  fulfill_order_number_hint: 'Input Order Number',
  fulfillment_order_information: 'Fulfillment Order Information',
  // fulfillment_order_information: 'Fulfillment Order Information',
  update_request_data: 'Update Request Data',
  submit_data: 'Submit Data',
  completed_data: 'Completed Data',
  new: 'New',
  accepted: 'Accepted',
  submitted: 'Submitted',
  rejected: 'Rejected',
  declined: 'Declined',
  completed: 'Completed',
  fulfillment_order_data: 'Fulfillment Order Data',
  // fulfillment_order_data: 'Fulfillment Order Data',
  import_submitted_fulfillment_orders: 'Import Submitted Fulfillment Orders',
  import_completed_fulfillment_orders: 'Import Completed Fulfillment Orders',
  import_external_fulfillment_orders: 'Import External Fulfillment Orders',
  import_submitted_request_from_excel: "Import Submitted request from excel file",
  import_external_request_from_excel: "Import external fulfillment order from excel file",
  import_external_request_from_excel_desc: "Import external fulfillment order from excel file. Please make sure that the order id, order number and sku is unique.",
  import_completed_request_from_excel: "Import completed request from excel file",
  update_fulfillment_order: 'Update Fulfillment Order',
  // update_fulfillment_order: 'Update Fulfillment Order',
  request_fulfill_selected_orders: 'Request Fulfillment',
  // fulfillment_order_selected_orders: 'Request Fulfill Selected Orders',
  import_result: 'Import result',
  want_to_download_result_file: 'Do you want to download result file ? Click here',
  error_import_result: 'Import file failed, please try again! Error code',
  finish_importing_reports: 'Finish importing reports',
  download_result_file: 'Download result file',
  submitting: 'Submitting',
  request_sku: 'Request Sku',
  export_fulfillment_order_for_submitting: 'Export For Submitting',
  export_fulfillment_order_for_review: 'Export For Review',
  change_request_status: 'Change Request Status',
  choose_fulfillment_order_status: 'Choose Fulfillment Order status',
  // choose_fulfillment_order_status: 'Choose Fulfillment Order status',
  submitted_time: 'Submitted Time',
  total_rows: 'Total rows',
  successfully_imported: 'Successfully imported',
  failed: 'Failed',
  skipped: 'Skipped',
  output_file: 'Output File',
  click_to_dowload_again: 'If the download does not start automatically, click here to',
  retry_to_dowload: 'retry the download',
  order_item: "Order Item",
  account: 'Account',
  sale_channel: 'Sale Channel',
  add_sale_channel: 'Add Sale Channel',
  remove_sale_channel: 'Remove Sale Channel',
  edit_selected_artwork: 'Edit selected Artwork',
  bulk_update_artwork: 'Bulk Update Artwork',
  add_row: 'Add Row',
  warning: "Warning",
  status_message: "Status Message",
  personal_custom_reviewed :"Personal Custom Reviewed",
  personal_custom_text :"Personal Custom text",
  personal_custom_fields :"Personal Custom Fields",
  personal_custom_history :"Personal Custom History",
  order_id: 'Order Id',
  channel_order_id: 'Channel Order Id',
  unpaid: 'Unpaid',
  unused: 'Unused',
  click_to_view_detail: "Click to view detail",
  used: "Used",
  has_unpaid_custom_fields:"Has unpaid custom fields",
  has_unused_custom_fields:"Has unused custom fields",
  all_custom_fields_have_been_used: 'All custom fields have been used',
  fulfill_design_status: 'Fulfill Design Status',
  change_status: 'Change Status',
  null: 'Null',
  woofunnels_default_value : "Woofunnels Default Value",
  woofunnels_default_value_hit: "Input woofunnels default value",
  add_fulfill_variant: "Add fulfill variant",
  add_rows: "Add rows",
  optionName_is_unique: "Option Name is unique",
  change_custom_type: "Change Custom Type",
  choose_custom_type: "Choose Custom Type",
  order_product: 'Order/Product',
  fulfillment_order: 'Fullfillment Order',
  // fulfillment_order: 'Fullfill Request',
  fulfillment_order_hint: 'Input Fullfillment Order',
  // fulfillment_order_hint: 'Input Fullfill Request',
  external_order :"External order",
  wait :"Wait",
  request_fulfill_order_result_summary: 'Request fulfillment order result summary',
  view_details: 'View details',
  click_to_copy_ids: 'Click to copy ids',
  success: 'Success',
  not_found: 'Not found',
  update_fulfill_order_data: 'Update Order Item Data',
  internal_order: 'Internal Order',
  order_item_type: "Order Item Type",
  external_fulfill_order: 'External Fulfillment Order',
  internal_fulfill_order: 'Internal Fulfillment Order',
  match: "Match",
  not_match: "Not Match",
  order_item_hint: "Input Order Item",
  export_fulfillment_order_result: 'Export Fulfillment Order result',
  // export_fulfillment_order_result: 'Export Fulfillment Order result',
  finish_exporting: 'Finish exporting',
  submitted_data: 'Submitted Data',
  external_request: "External Request",
  request_sku_hint: 'Input Request Sku',
  order_item_sku: 'Order Item Sku',
  fulfillment_sku: 'Fulfillment Sku',
  customize_hint: 'Input Customize',
  design_product_url: "Design Product Url ",
  design_product_url_hint: "Input Design Product Url",
  design_preview_url: 'Design Preview Url',
  design_preview_url_hint: 'Input Design Preview Url',
  fulfill_base_cost: "Fulfill Base Cost",
  fulfill_service_hint: "Input Fulfill Service",
  fulfill_variant_hint: "Input Fulfillment Variant",
  id_status: "Id/Status",
  tracking: "Tracking",
  created: "Created",
  fulfill_base_cost_hint: "Input Fulfill Base Cost",
  fulfillment_sku_hint: "Input Fulfillment Sku",
  import_submitted_order_from_excel_desc: "Import fulfillment orders which have been submitted to a fulfillment service. Importing file must be formatted same as submitting files that exported from our system",
  import_completed_order_from_excel_desc: "Import fulfillment orders which have been completed from fulfillment service. Importing file must be formatted same as submitting files that exported from our system and contains the shipment information in 2 columns Tracking Number, Base Cost.",
  choose_fulfill_variant: "Choose Fulfillment Variant",
  product_variation_name: "Product Variation",
  click_to_edit: "click to edit",
  import_shopbase_fulfillment_order: 'Import ShopBase Fulfillment Orders',
  modify_note_hint: 'Input modify note',
  processing_time_depends_on_the_number: 'Processing time depends on the number of {{resourceName}} selected. Exporting > 1000 {{resourceName}} may take a few minutes',
  current_page: 'Current page',
  all_pages: 'All pages',
  selected: 'Seleted',
  excel_file: 'Excel file',
  plain_csv_file: 'Plain CSV file',
  export_as: 'Export as',
  fulfill_order_resource: 'order items',
  save_as_suggestion: "Save as suggestion",
  fulfillment_orders_resource: 'fulfillment orders',
  custom_export_data: 'Custom Export Data',
  // export_for_review_fulfillment_order: "Export For Review",
  // export_for_submitting_fulfillment_order: "Export For Submitting",
  export_for_review_fulfillment_order: "Export For Review",
  export_for_submitting_fulfillment_order: "Export For Submitting",
  csv_file: "CSV file",
  product_variation: "Product Variation",
  fulfill_variation: "Fulfill Variation",
  other_option: 'Other option',
  province_code_hint: "Input Province Code",
  fill_suggestion: "Fill Empty With Suggestion",
  replace_with_suggestion: "Replace With Suggestion",
  incompatible_pasting_value: "Incompatible pasting value",
  click_to_unsave: 'Click to skip saving suggest fulfill variant for this product variation',
  export_completed_requests: 'Export Fulfillment Orders',
  all_order_items: 'All Order Items',
  change: "Change",
  fulfill_item: 'Fulfill Item',
  fulfillment_products: "Fulfillment Products",
  for_review: "For Review",
  for_auto_design: "For Auto Design",
  for_review_fulfillment_order: "For Review",
  for_submitting_fulfillment_order: "For Submitting",
  completed_requests: 'Fulfillment Orders',
  personal_custom_design: "Personal Custom Design",
  submitted_fulfillment_orders: 'Submitted Fulfillment Orders',
  completed_fulfillment_orders: 'Completed Fulfillment Orders',
  external_fulfillment_orders: 'External Fulfillment Orders',
  submitted_request_from_excel: "Submitted request from excel file",
  shopbase_fulfillment_order: 'ShopBase Fulfillment Orders',
  fulfilling_order: 'Fulfilling order item',
  fulfilled_order: "Fulfilled order item (tracking)",
  campaigns: 'Campaigns',
  users: 'Users',
  campaign: 'Campaign',
  edit_user: 'Edit User',
  user_id: "User Id",
  import_campaign: 'Import Campaign',
  create_campaign: 'Create Campaign',
  campaign_hint: 'Input campaign',
  campaign_required: "Please input campaign",
  create_campaign_success: 'Create campaign successfully',
  user: 'User',
  user_hint: 'Input User',
  copied_to_clipboard: 'Copied to clipboard',
  no_fulfillment_variant: "No Fulfillment Variant",
  fulfillment_variants: "Fulfillment Variants",
  sale_channel_owner: 'Sale channel owner',
  sale_channel_owner_hint: 'Choose sale channel owner',
  last_modified: 'Last modified',
  teams: "Teams",
  select_teams_hint: "Select Teams",
  go_back: 'Go back',
  no_status_messages: 'No status messages',
  fulfillment_and_shipment: 'Fulfillment & Shipment',
  note_and_messages: 'Notes & Messages',
  no_note_from_customer: 'No note from customer',
  no_fulfill_messages: 'No fulfill messages',
  change_vendor: "Change Vendor",
  choose_vendor: "Choose vendor",
  fill_suggestion_successfully: 'Fill suggestion successfully, please review before save.',
  fill_suggestion_unsuccessfully: "No fulfillment order has been fill suggestion",
  filter_product_variation_title: "Filter Product Variation",
  duplicate_data: "Dữ liệu đã tồn tại",
  change_fulfillment_date: "Change Fulfillment Date",
  status_invoice: "Trang Thái Hóa Đơn",
  not_created_yet: "Chưa Tạo",
  fulfillment_date: 'Fulfillment Date',
  fulfilmentDate_required: "please choose fulfillment date",
  replace_fulfillment_date_in_file: "Replace fulfillment date in file",
  fulfillment_invoices: "Fulfillment Invoices",
  invoices: "Invoices",
  paid: 'Đã thanh toán',
  vendor_Id: 'Vendor Id',
  create_invoices: "Create Invoices",
  invoiced_date: 'Invoiced Date',
  vendor_order: "Vendor/Order",
  discount: "Discount",
  cancled: "Cancled",
  money_goods: "Tiền Hàng",
  according_seller: "Theo người bán",
  according_producer: 'Theo nhà sản xuất',
  goods_bill: "Hóa đơn tiền hàng",
  overview: 'Tổng quan',
  seller: 'Người bán',
  not_yet_pay: 'Chưa thanh toán',
  total_money: "Tổng tiền",
  broke: 'Đã hủy',
  propduct_name: 'Tên nhà sản xuất',
  propduct: "Nhà sản xuất",
  the_state: 'Trạng thái',
  unit_price: 'Đơn giá',
  produce: 'sản phẩm',
  into_money: 'Thành tiền',
  Taxes_Fees: 'Thuế /Phí',
  note: 'Ghi chú',
  release_date: 'Ngày phát hành',
  accomplish_title: "Hoàn Thành",
  unresolved_title: "Chưa Giải Quyết",
  subtotal_title: 'Tổng Phụ',
  order_title: 'Đơn Hàng',
  add_invoice: 'Hóa đơn gốc',
  add_invoice_select: 'Vui lòng chọn hóa đơn gốc',
  order_fulfill: 'Đơn hàng fulfill',
  order_fulfill_select: 'Vui lòng chọn đơn hàng fulfill',
  amount: 'Số lượng',
  price_order: "Giá đơn hàng",
  promotion: 'Giảm giá',
  propduct_hint: "Input propduct",
  seller_hint: "Input người bán",
  amount_hint: "Input số lượng",
  price_order_hint: "Input giá đơn hàng",
  promotion_hint: "Input giảm giá",
  into_money_hint: "Input thành tiền",
  Invoice_issue_date: "Ngày phát hành hóa đơn",
  design_storage: 'Design Storage',
  google_drive: 'Google Drive',
  do_spaces: 'DigitalOcean Spaces',
  change_business: 'Switch Business',
  switch_team: "Switch Team",
  switch_team_hint: "Choose switch team",
  switch_business_hint: "Choose switch business",
  team: 'Team',
  manage_teams: 'Manage teams',
  owner: 'Owner',
  team_and_role: 'Team/Role',
  name_and_email: 'Name/Email',
  no_value_to_copy:'No value to copy',
  start_sync_from_order_id: 'Bắt đầu đồng bộ từ đơn Id',
  woo: 'Woo',
  woocommerce_funnel_kit: 'Woocommerce funnel kit',
  channel_order_type: 'Channel order type',
  design_folder: "Design Folder",
  design_folder_hint: "Input design folder",
  uploading: "Uploading",
  upload_design_file: 'Upload Design File',
  design_status_hint: 'Input Design Status',
  uploaded: 'Uploaded',
  access_id: 'Access ID',
  access_id_hint: 'S3 or Spaces access ID',
  access_key: 'Access Key',
  access_key_hint: 'S3 or Spaces access secret key',
  storage_endpoint: 'Storage Endpoint',
  storage_endpoint_hint: 'S3 Server, Spaces original endpoint,...',
  root_folder: 'Root Folder',
  root_folder_hint: 'Upload root folder, default is empty',
  storage_bucket: 'Storage bucket',
  storage_bucket_hint: 'Storage bucket',
  products_templates: 'Products Templates',
  product_templates: 'Product Templates',
  product_template_name: 'Product Template Name',
  product_template_value: 'Product Template Value',
  product_template_code: 'Product Template Code',
  product_template_option: 'Product template option',
  price: 'Price',
  product_template: 'Product Template',
  product_template_code_hint: 'Input the product template code',
  product_template_name_hint: 'Input the product template name',
  search_by_product_template_name: 'Search by product template name',
  product_template_regular_price_hint: 'Input the product template regular price',
  product_template_sale_price_hint: 'Input the product template sale price',
  product_template_group_hint: 'Input the product template group',
  product_template_desc_hint: 'Input the product template description',
  edit_product_template: 'Edit product template',
  create_product_template: 'Create Product Template',
  image_url: 'Image URL',
  image_url_hint: 'Input image URL',
  click_to_copy_variant_option_value: 'Click to copy value',
  click_to_copy_categories: 'Click to copy categories',
  click_to_copy_tags: 'Click to copy tags',
  copied_fail: 'Nothing to copy',
  value_hint: 'Please input value',
  name_hint: 'Please input name',
  product_code: 'Product code',
  product_code_hint: 'Please input product code',
  min_price: 'Minimum Price',
  max_price: "Maxinum Price",
  min_price_hint: "Input product minimum price",
  max_price_hint: "Input product maximum price",
  title_hint: 'Input title',
  price_hint: 'Input price',
  rows: 'rows',
  input_variant_item_required: 'Vui lòng nhập đầy đủ thông tin Variant',
  input_option_item_required: 'Vui lòng nhập đầy đủ thông tin Option',
  design_folder_url: 'Design Folder Url',
  utm_source: 'Utm Source',
  utm_sources: "Utm Sources",
  utm_tracking: 'UTM Tracking',
  utm_source_hint: 'Input Utm Source',
  utm_source_required: 'Please input Utm Source',
  import_source: 'Import Utm Source',
  create_source: 'Create Utm Source',
  create_source_success: 'Create utm source successfully',
  max_price_less_min_price: 'Vui lòng nhập giá trị Maxinum Price lớn hơn hoặc bằng giá trị Minimum Price',
  created_order: "Đã tạo hóa đơn",
  invoice_created_yet: "Chưa tạo hóa đơn",
  change_vendor_status: 'Chuyển TTHD người bán',
  change_fulfill_service_status: 'Chuyển TTHD nhà cung cấp',
  pending_process: 'Chờ xử lý',
  finish: 'Hoàn thành',
  revoke: 'Hủy bỏ',
  create_invoice: 'Create Invoice',
  invoice: 'Invoice',
  invoice_created: "Invoice Created",
  no_invoice_yet: "No Invoice yet",
  invoice_status: "Invoice Status",
  create_invoice_result: 'Result create invoice',
  promotion_less_price_order: 'Giá trị giảm giá không thể lớn hơn giá trị đơn hàng',
  total_revenue_title: 'Tổng Thu',
  no_processed_title: "Chưa Xử Lý",
  settlemented: 'Đã quyết toán',
  pending_payment: 'Chưa xử lý',
  order_fulfillment: 'Đơn hàng fulfillment',
  order_fulfillment_select: 'Vui lòng chọn đơn hàng fulfillment',
  edit_invoices: 'Edit Invoice',
  new_create: 'Tạo mới',
  column_hint: 'Please input column',
  key_hint: 'Please input key',
  edit_fulfill_service: "Edit Fulfill Service",
  create_fulfill_service: 'Create Fulfill Service',
  design_storage_type: 'Design Storage Type',
  column: 'Column',
  mapping_data: 'Mapping Data',
  code_hint: 'Please input Code',
  design_storage_type_hint: 'Please input Design Storage Type',
  sku_position: 'Custom Sku Position',
  sku_search_text: 'Custom Sku Search Text',
  sku_position_hint: 'Please input Custom Sku Position',
  sku_search_text_hint: 'Please input Custom Sku Search Text',
  fill_default_mapping_data: 'Fill Default Mapping Data',
  sku_position_required: "Please input value for Sku Position",
  sku_search_text_required: "Please input value for Sku Search Text",
  fulfillment_services: "Fulfillment Services",
  fulfill_variant_mapper: 'Fulfillment Mapping',
  fulfill_variant_mapper_id: 'Fulfillment Mapping Id',
  team_id: 'Team Id',
  fulfill_variant_name: 'Fulfill Variant Name',
  fulfill_variant_title: 'Fulfill Variant Title',
  team_hint: "Input Team",
  product_variation_hint: 'Input Product Variation',
  all_team: 'All Team',
  vendor_status: 'TTHD người bán',
  fulfill_service_status: 'TTHD nhà cung cấp',
  fulfillment_order_id: 'Fulfillment Order Id',
  edit_fulfillment_variant: 'Edit Fulfillment Variant',
  this_month: 'This Month',
  upload_design_option: 'Upload Design Options',
  filter_option_design_name: "Filter Option Design Name",
  filter_option_mockup_name: "Filter Option Mockup Name",
  design_name: 'Design Name',
  mockup_name: 'Mockup Name',
  choose_design_name: "Choose Design Name",
  choose_mockup_name: "Choose Mockup Name",
  edit_design_option: 'Choose Upload Design File',
  click_to_copy_url: 'Click to copy Url',
  click_to_edit_url: 'Click to edit Url',
  click_to_delete_image: 'Click to delete image',
  edit_url: 'Edit Url',
  auto_design_name: 'Auto Design Name',
  auto_mockup_name: 'Auto Mockup Name',
  auto_design_and_mockup: 'Auto Design and Mockup',
  refresh: 'Refresh',
  fill_design_status_unsuccess: "No fulfillment order has been fill design name",
  fill_mockup_unsuccess: "No fulfillment order has been fill mockup name",
  fill_design_status_and_mockup_unsuccess: "No fulfillment order has been fill design name and  mockup name",
  export_manual: 'Export Manual Custom Design',
  manual_custom_design: "Manual Custom Design",
  export_manual_custom_design: "Export Manual Custom Design",
  filter_fulfillment_sku: "Filter Fulfillment Sku",
  invoice_hint: "Input invoice",
  tax: 'Thuế',
  fee: 'Phí',
  tax_hint: "Input thuế",
  fee_hint: "Input phí",
  other_information: 'Other Information',
  update_vendor: 'Update Vendor',
  auto_update_vendor: 'Auto update vendor for order item',
  choose_vendor_for_order_item: "Choose vendor for order item",
  import_settled_invoices: 'Import Settled Invoices',
  update_base_cost: 'Update Base Cost',
  import_update_base_cost: 'Import Update Base Cost',
  select_hint: 'Select...',
  please_choose_vendor: 'Please choose vendor',
  update_vendor_result: 'Result update vendor',
  copy_to_clipboard: 'Copy to clipboard',
  use_artwork_sku_for_no_custom_line_item: 'Use artwork sku for no custom line item',
  username_and_email: 'Username/Email',
  customer_text: 'Custom Text',
  processing: 'Processing',
  last_update: 'Last Update',
  files: 'Files',
  reports: 'Reports',
  review_excel: 'Review Excel',
  download_output_file: 'Download Output File',
  review_output_file: 'Review Output File',
  download: 'Download',
  report: 'Report',
  progress: 'Progress',
  import_confirm: 'Import confirm',
  input_file: 'Input File',
  retry: 'Retry',
  view_file: 'View File',
  download_file: 'Download File',
  cancelling: 'Cancelling',
  retry_import: "Retry import",
  cancel_import: 'Cancel import',
  imports: 'Imports',
  update_design_url_by_sku: 'Update Design Url By Sku'
};
