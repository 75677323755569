import React from "react";
import Dashboard from "../pages/dashboard";
import Login from "../pages/auth/login";
import { IndexPage } from "../pages";
import Logout from "../pages/auth/logout";
import Page404 from "../components/page/404";
import { ChannelOrderSettingPage } from "../pages/settings/channel-order-setting.page";
import Woocommerce from "../pages/sale-channel/sale-channels";
import SaleChannelPage from "../pages/sale-channel/sale-channel";
import OrdersPage from "../pages/order/orders.page";
import { AccountsPage } from "../pages/accounts/accounts.page";
import { FulfillmentsPage } from "../pages/fulfillments/fulfillments.page";
import { FulfillOrdersPage } from "../pages/fulfillments/fulfill-orders.page";
import { FulfillServicesPage } from "../pages/fulfillments/fulfill-services.page";
import { FulfillVariantsPage } from "../pages/fulfillments/fulfill-variants.page";
import { ProductTypesPage } from "../pages/products/product-types.page";
import { ProductTypePage } from "../pages/products/product-type.page";
import { OrderPage } from "../pages/order/order.page";
import { FulfillOrderPage } from "../pages/fulfillments/fulfill-order.page";
import { FulfillServicePage } from "../pages/fulfillments/fulfill-service.page";
import { FulfillVariantPage } from "../pages/fulfillments/fulfill-variant.page";
import { FulfillProductPage } from "../pages/fulfillments/fulfill-product.page";
import { FulfillProductsPage } from "../pages/fulfillments/fulfill-products.page";
import { FulfillmentPage } from "../pages/fulfillments/fulfillment.page";
import { ArtworkPage } from "../pages/products/artwork.page"
import ArtworksPage from "../pages/products/artworks.page";
import { StorageSettingPage } from "pages/settings/storage-setting.page";
import AccountPage from "pages/accounts/account.page";
import ArtworkTemplatesPage from "pages/products/artwork-templates.page";
import ArtworkTemplatePage from "pages/products/artwork-template.page";
import {FulfillmentTrackingSettingPage} from "pages/settings/fulfillment-tracking-setting.page";
import { ArtworkBulkCreatePage } from "../pages/products/artwork-bulk-create.page";
import { ProductsPage } from "pages/products/products.page";
import { ProductPage } from "pages/products/product.page";
import { FulfillmentOrdersPage } from "pages/fulfillments/fulfillment-orders.page";
import { FulfillmentOrderPage } from "pages/fulfillments/fulfillment-order.page";
import SourcesPage from "pages/accounts/sources-page";
import { DesignStorageSettingPage } from "pages/settings/design-storage-setting.page";
import ProductTemplatesPage from "pages/products/product-templates.page";
import ProductTemplatePage from "pages/products/product-template.page";
import MaintenancePages from "pages/maintenance";
import BaseCostPages from "pages/reports/base-costs.page";
import FulfillmentInvoicesPage from "pages/fulfillments/fulfillment-invoices.page";
import { FulfillVariantMappersPage } from "pages/fulfillments/fulfill-variant-mappers";
import ReportsPage from "pages/reports/reports.page";
import { ProxyAuth } from "components/page/ProxyAuth";


export interface RouteOption {
  path: any,
  component: any,
  exact?: boolean
}

export const RouteNames = {
  ORDERS: '/orders',
  ORDERS_DETAIL: '/orders/:id',
  SIGN_OUT: '/logout',
  SIGN_IN: '/login',
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
  STORES: '/stores',
  STORES_DETAIL: '/stores/:id',
  ACCOUNTS: '/accounts',
  ACCOUNT:'/accounts/:id',
  FULFILLMENTS: '/fulfillments',
  FULFILLMENTS_DETAILS: '/fulfillments/:id',
  ORDER_ITEMS: '/orders/items',
  ORDER_ITEM_DETAIL: '/orders/items/:id',
  FULFILL_SERVICES: '/fulfill/services',
  FULFILL_SERVICES_DETAIL: '/fulfill/services/:id',
  FULFILL_VARIANTS: '/fulfill/products/variants',
  FULFILL_VARIANT_MAPPING: '/fulfill/products/variants/mapping',
  FULFILL_VARIANT: '/fulfill/products/variants/:id',
  FULFILL_PRODUCTS: '/fulfill/products',
  FULFILL_PRODUCT: '/fulfill/products/:id',
  PRODUCT_TYPES: '/products/types',
  PRODUCT_TYPES_DETAIL: '/products/types/:id',
  SETTINGS_CHANNEL_ORDER: '/settings/channel-orders',
  ARTWORKS: '/artworks',
  ARTWORKS_BULK_CREATE: '/artworks/bulk-create',
  ARTWORKS_DETAIL: '/artworks/:id',
  // xuanduc edit
  SETTING_STORAGE: '/settings/storage',
  SETTING_DESIGN_STORAGE: '/settings/design-storage',
  ARTWORK_TEMPLATES_DETAIL: '/artwork-templates/:id',
  ARTWORK_TEMPLATES: '/artwork-templates',
  SETTINGS_FULFILLMENT_TRACKING: '/settings/fulfillment-tracking',
  PRODUCT: '/products',
  PRODUCT_DETAIL: '/products/:id',
  FULFILLMENT_ORDERS: '/fulfillment/orders',
  FULFILLMENT_ORDERS_DETAIL: '/fulfillment/orders/:id',
  SOURCES: '/sources',
  SOURCE: '/sources/:id',
  USERS: '/users',
  USER:'/users/:id',
  PRODUCTS_TEMPLATES: '/products/templates',
  PRODUCTS_TEMPLATES_DETAIL: '/products/templates/:id',
  MAINTENANCE: '/maintenance',
  REPORTS_BASE_COST: '/reports/base-cost',
  REPORTS_INVOICES: '/reports/invoices',
  REPORTS_IMPORTS: '/reports/imports',
  UNAUTHORIZED: '/407'
}

/**
 * Note: Order route path right way
 * If has 2 paths /products and /products/variations, /products/variations order must be before /products
 */
const authProtectedRoutes: RouteOption[] = [
  { path: RouteNames.SIGN_OUT, component: Logout },
  { path: RouteNames.DASHBOARD, component: Dashboard },
  { path: RouteNames.SETTINGS_CHANNEL_ORDER, component: ChannelOrderSettingPage },
  { path: RouteNames.ORDER_ITEMS, component: FulfillOrdersPage },
  { path: RouteNames.ORDER_ITEM_DETAIL, component: FulfillOrderPage },
  { path: RouteNames.ORDERS, component: OrdersPage },
  { path: RouteNames.STORES, component: Woocommerce },
  { path: RouteNames.STORES_DETAIL, component: SaleChannelPage },
  { path: RouteNames.ORDERS_DETAIL, component: OrderPage },
  { path: RouteNames.USERS, component: AccountsPage },
  { path: RouteNames.USER, component:AccountPage},
  { path: RouteNames.FULFILL_SERVICES, component: FulfillServicesPage },
  { path: RouteNames.FULFILL_SERVICES_DETAIL, component: FulfillServicePage },
  { path: RouteNames.FULFILL_VARIANTS, component: FulfillVariantsPage },
  { path: RouteNames.FULFILL_VARIANT_MAPPING, component: FulfillVariantMappersPage },
  { path: RouteNames.FULFILL_VARIANT, component: FulfillVariantPage },
  { path: RouteNames.FULFILL_PRODUCTS, component: FulfillProductsPage },
  { path: RouteNames.FULFILL_PRODUCT, component: FulfillProductPage },
  { path: RouteNames.FULFILLMENT_ORDERS, component: FulfillmentOrdersPage},
  { path: RouteNames.FULFILLMENT_ORDERS_DETAIL, component: FulfillmentOrderPage},
  { path: RouteNames.FULFILLMENTS, component: FulfillmentsPage },
  { path: RouteNames.FULFILLMENTS_DETAILS, component: FulfillmentPage },
  { path: RouteNames.PRODUCT_TYPES, component: ProductTypesPage },
  { path: RouteNames.PRODUCT_TYPES_DETAIL, component: ProductTypePage },
  { path: RouteNames.PRODUCTS_TEMPLATES, component: ProductTemplatesPage},
  { path: RouteNames.PRODUCTS_TEMPLATES_DETAIL, component: ProductTemplatePage},
  { path: RouteNames.ARTWORKS, component: ArtworksPage },
  { path: RouteNames.ARTWORKS_BULK_CREATE, component: ArtworkBulkCreatePage },
  { path: RouteNames.ARTWORKS_DETAIL, component: ArtworkPage },
  { path: RouteNames.SETTING_DESIGN_STORAGE, component: DesignStorageSettingPage },
  { path: RouteNames.SETTING_STORAGE, component: StorageSettingPage },
  { path: RouteNames.ARTWORK_TEMPLATES, component: ArtworkTemplatesPage },
  { path: RouteNames.ARTWORK_TEMPLATES_DETAIL, component: ArtworkTemplatePage },
  { path: RouteNames.SETTINGS_FULFILLMENT_TRACKING, component: FulfillmentTrackingSettingPage},
  { path: RouteNames.PRODUCT, component: ProductsPage},
  { path: RouteNames.PRODUCT_DETAIL, component: ProductPage},
  { path: RouteNames.SOURCES, component: SourcesPage},
  { path: RouteNames.REPORTS_BASE_COST, component: BaseCostPages},
  { path: RouteNames.REPORTS_INVOICES, component: FulfillmentInvoicesPage},
  { path: RouteNames.REPORTS_IMPORTS, component: ReportsPage},
]

const publicRoutes: RouteOption[] = [
  { path: RouteNames.SIGN_IN, exact: true, component: Login },
  { path: RouteNames.MAINTENANCE, component: MaintenancePages},
  { path: RouteNames.UNAUTHORIZED, component: ProxyAuth },
  { path: "/", exact: true, component: IndexPage },
  { path: "*", component: Page404 },
]


export { publicRoutes, authProtectedRoutes }
