import { ActionMeta, SingleValue } from "react-select";
import React, { ComponentProps } from "react";
import { FormikHandlers, FormikState } from "formik/dist/types";
import { ProductTypeDto } from "../../../data/services/product-type.service";
import { FormikHelpers } from "formik";
import { OrderDto } from "../../../data/services/order.service";
import { OrderActions } from "../../../data/actions/order.action";
import { SearchableSelect } from "components/input/SearchableSelect";

export interface OrderSelectProps extends ComponentProps<any> {
  validation?: FormikHandlers & FormikState<any> & FormikHelpers<any>
  value?: OrderDto | any
  getOptionValue?:Function
  getOptionLabel?:Function
  onChange: (value: SingleValue<ProductTypeDto>, actionMeta?: ActionMeta<any>) => void
}

export const OrderSelect = (props: OrderSelectProps) => {
  let { value, validation, onChange,getOptionValue,
    getOptionLabel, ...rest} = props
  return (
   
    <SearchableSelect
      cacheOptions
      defaultOptions={true}
      value={value}
      defaultInputValue={value?.orderNumber || ""}
      getOptionValue={getOptionValue ? ((option: OrderDto) => option?.number || "") : undefined}
      getOptionLabel={getOptionLabel ? ((option: OrderDto) => option?.number || "") : undefined}
      loadOptions={OrderActions.searchByOrderNumber}
      onBlur={validation?.handleBlur}
      onChange={props.onChange}
      {...rest}
    />
  )
}