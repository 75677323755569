import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { FulfillServices } from "../services/fulfill-service.service";
import { FulfillServiceActions } from "data/actions/fulfill-service.action";

export const fulfillServiceQueryState = atomFamily<QueryParams<any>, any>({
  key: 'fulfillServiceQueryState',
  default: (params: any) => {
    return {...params}
  }
})

export const fulfillServiceSelector = selector({
  key: 'fulfillServiceSelector',
  get: async ({ get }) => {
    let query = get(fulfillServiceQueryState(defaultPageInfo))
    let pageData = await FulfillServices.query(query, { snoozeErrorMessage: true })
    return pageData;
  },
});

export const allFulfillServicessState = atom({
  key: 'allFulfillServicessState',
  default: { ...defaultPageInfo, limit: 250, initial: true}
})

export const allFulfillServicesSelector = selector({
  key: 'allFulfillServicesSelector',
  get: async ({ get }) => {
    let query = get(allFulfillServicessState)
    let pageData = await FulfillServices.query(query, { snoozeErrorMessage: true })
    return pageData?.pageItems || [];
  },
});

export const fulfillServiceIdState = atomFamily<QueryParams<any>, any>({
  key: "fulfillServiceIdState",
  default: (id: any) => {
    return id;
  }
});

export const fulfillServiceByIdSelector = selectorFamily({
  key: "fulfillServicesByIdSelector",
  get: (id: any) => async ({ get }) => {
    let serviceId = get(fulfillServiceIdState(id));
    
    if (+serviceId > 0) {
      let data = await FulfillServices.getById(+serviceId, { snoozeErrorMessage: false });
      return data;
    }

    return {};
  }
});

export const mappingDataState = atom({
  key: 'mappingDataState',
  default: {}
})

export const mappingDataSelector = selector({
  key: 'mappingDataSelector',
  get: async ({ get }) => {
    let response = await FulfillServiceActions.getDefaultMappingData()
    return response || []
  },
});
