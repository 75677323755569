import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { FulfillProductServices } from "../services/fulfill-product.service";
import { BulkEditingState, BulkEditorView } from "../../components/bulk/bulk-editor.type";

export const fulfillProductQueryState = atomFamily<QueryParams<any>, any>({
  key: 'fulfillProductQueryState',
  default: (params: any) => {
    return {...params}
  }
})

export const fulfillProductSelector = selector({
  key: 'fulfillProductSelector',
  get: async ({ get }) => {
    let query = get(fulfillProductQueryState(defaultPageInfo))
    let pageData = await FulfillProductServices.query(query, { snoozeErrorMessage: true })
    return pageData;
  },
});

export const bulkEditFulfillProductVariantsAtom = atom<BulkEditingState | any>({
  key: 'bulkEditFulfillProductVariantsAtom',
  default: {
    editing: false,
    updatedData: {},
    submitData: {}
  }
});

export const fulfillProductIdState = atomFamily<QueryParams<any>, any>({
  key: "fulfillProductIdState",
  default: (id: any) => {
    return id;
  }
});

export const fulfillProductIdSelector = selectorFamily({
  key: "fulfillProductIdSelector",
  get: (id: any) => async ({ get }) => {
    let fulfillProductId = get(fulfillProductIdState(id));

    if (+fulfillProductId > 0) {
      let data = await FulfillProductServices.getById(+fulfillProductId, { snoozeErrorMessage: false });
      return data;
    }

    return {};
  }
});