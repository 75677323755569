import { Logger } from "core/logger";
import { SourceDto, SourceService, SourceServices } from "data/services/source.service"
import { CrudActions } from "./crud.action"

class SourceAction extends CrudActions<
  SourceService,
  SourceDto,
  SourceDto
> {
  constructor() {
    super(SourceServices)
  }

  
  async importSources(file: any, replaceable?: boolean) {
    try {
      const formData = new FormData();
      formData.append('file', file)
      return await SourceServices.importSources(formData, replaceable, { snoozeErrorMessage: true, snoozeAllErrorMessage: true })
    } catch (e) {
      Logger.warn('import submitted request failed: ', e)
    }
  }

}

export const SourceActions = new SourceAction()
