
import { apiBaseUrls } from "../rest-api-config";
import { CrudService } from "./crud.service";
import { FulfillVariantDto } from "./fulfill-variation.service";
import { Metafield } from "../../types/metafield.type";
import { RequestOptions } from "../../core/rest-api/rest.api";
import { ProductTypeApiPaths } from "./product-type.service";

export interface FulfillProductDto {
  id: string | number,
  code?: string
  name?: string
  productTypeKey?: string
  optionKeys?: string[]
  variantOptions?: Metafield[]
  variants?: FulfillVariantDto[]
  fulfillServiceId?: number
  fulfillService?:any
  baseCost?: number
}
export interface CreateFulfillProductDto {
  code?: string
  name?: string
  productTypeKey?: string
  optionKeys?: string[]
  variantOptions?: Metafield[]
  variants?: FulfillVariantDto[]
  fulfillService?:any
}

export interface UpdateFulfillProductDto extends CreateFulfillProductDto {}

export const FulfillProductApiPaths = {
  BASE: '/fulfillment/products',
  WITH_ID: '/fulfillment/products/{id}',
  QUERY: '/fulfillment/products/query',
  BULK: '/fulfillment/products/bulk',
  IMPORT: '/fulfillment/products/import',
}

export class FulfillProductService extends CrudService<FulfillProductDto, CreateFulfillProductDto, UpdateFulfillProductDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: FulfillProductApiPaths.WITH_ID,
        updateById: FulfillProductApiPaths.WITH_ID,
        deleteById: FulfillProductApiPaths.WITH_ID,
        query: FulfillProductApiPaths.QUERY,
        base: FulfillProductApiPaths.BASE,
        deleteMany: FulfillProductApiPaths.BULK,
        updateMany: FulfillProductApiPaths.BULK
      }
    });
  }

  importFulfillProducts(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    return super.import(data, `${FulfillProductApiPaths.IMPORT}?replace_existed=${replaceExisted}`, options)
  }
}

export const FulfillProductServices = new FulfillProductService()

