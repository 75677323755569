import * as Yup from 'yup'
import { Messages } from 'common/messages'
import { TestContext } from 'yup/lib/util/createValidation';
import { t } from "../../../core/translations";
import { Labels } from 'common/labels';

const isValidJSON = (text: string) => {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
}

export const designStorageSettingSchema = Yup.object({
  value: Yup.object({
    ['do-spaces']: Yup.object({
      accessId: Yup.string().min(1).required(t(Messages.field_required, { field: `${t(Labels.access_id)} ${t(Labels.do_spaces)}` })),
      accessKey: Yup.string().min(1).required(t(Messages.field_required, { field: `${t(Labels.access_key)} ${t(Labels.do_spaces)}` })),
      endpoint: Yup.string().required(t(Messages.field_required, { field: `${t(Labels.storage_endpoint)} ${t(Labels.do_spaces)}`})),
      rootFolder: Yup.string().required(t(Messages.field_required, { field: `${t(Labels.root_folder)} ${t(Labels.do_spaces)}`})),
    }),
    ['google-drive']: Yup.object({
      credentials: Yup.string().min(1).required(t(Messages.field_required, { field: `${t(Labels.credentials)} ${t(Labels.google_drive)}` })),
      driveId: Yup.string().required(t(Messages.field_required, { field: `${t(Labels.bucket_id)} ${t(Labels.google_drive)}` })),
      parentFolder: Yup.string().required(t(Messages.field_required, { field: `${t(Labels.upload_folder_name)} ${t(Labels.google_drive)}`})),
    })
      .test('storageGoogleDrive', (value: any, context: TestContext) => {
        if(!context.parent?.['google-drive'] || !isValidJSON(context.parent['google-drive']?.credentials)) {
          return context.createError({ message: t(Messages.error_type_storage_credentials) })
        }
        return true
      })
  })
})
