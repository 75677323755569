import { CrudActions } from "./crud.action"
import {
  SettingService,
  SettingServices,
  UpdateSettingDto,
  Setting,
  UpdateStorageSettingDto,
} from "../services/setting-service"
import { Logger } from "../../core/logger"
import { t } from "../../core/translations"
import { Messages } from "../../common/messages"

class SettingAction extends CrudActions<
  SettingService,
  Setting,
  UpdateSettingDto
> {
  constructor() {
    super(SettingServices)
  }

  async updateByKey(
    key: string,
    payload: UpdateSettingDto,
    hooks: { setError?: Function } = {}
  ) {
    let { setError } = hooks
    try {
      const response: any = await SettingServices.updateOneByKey(key, payload, {
        snoozeErrorMessage: false,
      })
      Logger.debug("update result: ", response)
      if (response) {
        return response
      }
    } catch (error: any) {
      setError &&
        setError(error.message || t(Messages.delete_resource_unsuccessfully))
      Logger.trace(error)
    }
  }

  async updateStorageSettingByKey(
    key: string,
    payload: UpdateStorageSettingDto,
    hooks: { setError?: Function } = {}
  ) {
    let { setError } = hooks
    try {
      const response: any = await SettingServices.updateOneStorageSettingByKey(key, payload, {
        snoozeErrorMessage: false,
      })
      Logger.debug("update result: ", response)
      if (response) {
        return response
      }
    } catch (error: any) {
      setError &&
        setError(error.message || t(Messages.delete_resource_unsuccessfully))
      Logger.trace(error)
    }
  }
}

export const SettingActions = new SettingAction()
