import { Labels } from "common/labels";
import Breadcrumb from "components/layout/Breadcrumb";
import RemoteTable, { createDeleteAction } from "components/table/RemoteTable";
import { ActionOption } from "components/table/SelectedRowAction";
import { SearchOption } from "components/table/TableFilter";
import { t } from "core/translations";
import { ArtworkActions } from "data/actions/artwork.action";
import { artworksSelector } from "data/atoms/artwork.atom";
import { ImportType } from "pages/fulfillments/components/import-modal";
import { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { RouteNames } from "routes";
import { artworkTemplateQueryState, artworkTemplatesSelector } from "data/atoms/artwork-template.atom";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import { createDocumentPageName } from "../../utils/utils";
import { createLinkEmbedParam } from "utils/embed";

const ArtworkTemplatesPage = () => {

  useEffect(() => { document.title = createDocumentPageName(t(Labels.artwork_templates)) }, [])

  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { width: 100 },
      //   eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.ARTWORK_TEMPLATES}/${row.id}`)}>
              {" "}
              {`${row.id}`}
            </Link>
          </>
        )
      },
    },
    {
      dataField: "name",
      text: t(Labels.name),
    },
    {
      dataField: "description",
      text: t(Labels.description),
      headerStyle: { width: 200 },
      //   eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <span
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            {row.description}
          </span>
        )
      },
    },
    {
      dataField: "amount",
      text: t(Labels.custom_option_count),
      headerStyle: { width: 200 },
      //   eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <span
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            {(row.customOptions || []).filter((item: any) => Object.keys(item).length).length}
          </span>
        )
      },
    },

    
  ]

  let searchOptions: SearchOption[] = [
    {
      field: "name",
      label: t(Labels.artwork_templates_name),
    },
  ]

  const refresh = useRecoilRefresher_UNSTABLE(artworksSelector)
  const [importType, setImportType] = useState<ImportType>(ImportType.NULL)
  const actionOptions: ActionOption[] = [createDeleteAction(ArtworkActions, ResourceEntities.artworkTemplateEntity)]
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={t(Labels.artwork_templates)}>
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.artworkTemplateEntity}>
              <Link {...createLinkEmbedParam(`${RouteNames.ARTWORK_TEMPLATES}/create`)}>
                <Button type="button" className="btn btn-success">
                  <i className="bx bx-plus font-size-16 align-middle"></i>
                  {t(Labels.create_new)}
                </Button>
              </Link>
            </WithPermission>
          </Breadcrumb>
          <RemoteTable
            dataLoader={[artworkTemplatesSelector, artworkTemplateQueryState]}
            searchOptions={searchOptions}
            filterOptions={[]}
            tabOptions={[]}
            actionOptions={actionOptions}
            searchHint={t(Labels.search_by_name)}
            defaultSearchField={"name"}
            keyField={"id"}
            columns={columns}
            refresh={refresh}
            filterKey={'artworkTemplates'}
          />
        </Container>
      </div>
    </>
  )
}

export default ArtworkTemplatesPage
