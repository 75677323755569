import { Col, Row } from "reactstrap";

export interface FormLayoutProps {
  children?: any
}

export const FormLayout = (props: FormLayoutProps) => {
  let { children } = props
  let components = Array.isArray(children) ? children : [children]
  let mainComp = components.filter(comp => comp.type.name == FormLayoutMain.name)
  let rightComp = components.filter(comp => comp.type.name == FormLayoutRight.name)

  return rightComp?.length ? (
    <Row xs={12}>
      <Col xs={12} md={8}>
        { mainComp }
      </Col>
      <Col xs={12} md={4} className="ps-2">
        { rightComp }
      </Col>
    </Row>
  )
  :
  (
    <Row xs={12}>
      <Col xs={12}>
        { mainComp }
      </Col>
    </Row>
  )
}

// eslint-disable-next-line react/display-name
export const FormLayoutMain = ({ children }: any) => {
  return (
    <>
      { children }
    </>
  )
}

// eslint-disable-next-line react/display-name
export const FormLayoutRight = ({ children }: any) => {
  return (
    <>
      { children }
    </>
  )
}

