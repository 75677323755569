import { Labels } from "common/labels"

export const permissionValues = {
  READ: "read",
  WRITE: "write",
  READ_WRITE: "read-write",
}

export const permissionName: Record<string, string> = {
  [permissionValues.READ]: Labels.read,
  [permissionValues.WRITE]: Labels.write,
  [permissionValues.READ_WRITE]: Labels.read_write,
}

export const permissionOptions = [
  { label: permissionName[permissionValues.READ], value: permissionValues.READ },
  { label: permissionName[permissionValues.WRITE], value: permissionValues.WRITE },
  { label: permissionName[permissionValues.READ_WRITE], value: permissionValues.READ_WRITE },
]

export const defaulPermissionOptions = {
  label: permissionName[permissionValues.READ_WRITE],
  value: permissionValues.READ_WRITE,
}

export enum ChannelOrderTypeValues {
  WOOCOMMERCE = "woo",
  WOOCOMMERCE_WITH_FUNNEL_KIT = "woo_funnel_kit",
}

export const ChannelOrderTypeName: Record<string, string> = {
    [ChannelOrderTypeValues.WOOCOMMERCE]: Labels.woo,
    [ChannelOrderTypeValues.WOOCOMMERCE_WITH_FUNNEL_KIT]: Labels.woocommerce_funnel_kit,
  }
  

export const ChannelOrderTypeOptions = [
    { label: ChannelOrderTypeName[ChannelOrderTypeValues.WOOCOMMERCE], value: ChannelOrderTypeValues.WOOCOMMERCE },
    { label: ChannelOrderTypeName[ChannelOrderTypeValues.WOOCOMMERCE_WITH_FUNNEL_KIT], value: ChannelOrderTypeValues.WOOCOMMERCE_WITH_FUNNEL_KIT },
  ]
