import { Logger } from 'core/logger';
import { FulfillmentOrderActions } from 'data/actions/fulfillment-order.action';
import { selectorFamily } from 'recoil';
import { defaultPageInfo } from 'components/table/RemoteTable';
import { selector } from 'recoil';
import { QueryParams } from 'core/rest-api/query-builder';
import { atomFamily } from 'recoil';
import { FulfillmentOrderServices } from 'data/services/fulfillment-order.service';
import { BulkEditByIdsType } from './fulfill-order.atom';
import { BulkEditingState, BulkEditorView } from 'components/bulk/bulk-editor.type';


export const fulfillmentOrderQueryState = atomFamily<QueryParams<any>, any>({
  key: 'fulfillmentOrderQueryState',
  default: (params: any) => {
    return {...defaultPageInfo, ...(params || {})}
  }
})

export const fulfillmentOrderSelector = selector({
  key: 'fulfillmentOrderSelector',
  get: async ({ get }) => {
    let query = get(fulfillmentOrderQueryState(defaultPageInfo))
    let pageData = await FulfillmentOrderServices.query(query)
    return pageData;
  },
});

export const fulfillmentOrderByIdState = atomFamily<QueryParams<any>, any>({
  key: "fulfillmentOrderByIdState",
  default: (id: any) => {
    return id;
  }
});

export const fulfillmentOrderByIdSelector = selectorFamily({
  key: "fulfillmentOrderByIdSelector",
  get: (id: any) => async ({ get }) => {
    let requestId = get(fulfillmentOrderByIdState(id));

    if (+requestId > 0) {
      let data = await FulfillmentOrderServices.getById(+requestId, { snoozeErrorMessage: false });
      return data;
    }

    return {};
  }
})

export const fulfillmentOrderStatusCountSelector = selector({
  key: 'fulfillmentOrderStatusCountSelector',
  get: async ({ get }) => {
    let query = get(fulfillmentOrderQueryState(defaultPageInfo))
    let countByStatus = await FulfillmentOrderServices.getCountByStatus()
    return countByStatus;
  },
});


export const bulkEditFulfillmentOrderState = atomFamily<BulkEditByIdsType, any>({
  key: 'bulkEditFulfillmentOrderState',
  default: (state: BulkEditByIdsType) => {
    return state
  }
})

export const bulkEditFulfillmentOrderSelector = selectorFamily({
  key: 'bulkEditFulfillmentOrderSelector',
  get: (state: BulkEditByIdsType) => async ({ get }) => {
    let editState = get(bulkEditFulfillmentOrderState(state))
    if (editState?.ids) {
      return FulfillmentOrderActions.queryForBulkEdit(editState?.ids, editState.editor, { snoozeErrorMessage: true })
    }
  },
});


export const bulkEditFulfillmentOrderAtom = atomFamily<BulkEditingState | any, any>({
  key: 'bulkUpdateFulfillmentOrderState',
  default: (editor?: BulkEditorView) => ({
    editor: editor || BulkEditorView.FULFILLMENT_ORDER,
    editing: false,
    updatedData: {},
    submitData: {}
  })
});
