import { apiBaseUrls } from "../rest-api-config";
import { CrudService } from "./crud.service";
import { Metafield } from "../../types/metafield.type";
import { FulfillProductDto } from "./fulfill-product.service";
import { IdBaseType } from "../../types/id-base.type";
import { FulfillServiceDto } from "./fulfill-service.service";
import { RequestOptions } from "../../core/rest-api/rest.api";

export interface FulfillVariantDto {
  id?: string | number
  sku?: string
  baseCost?: number
  fulfillProductId?: number
  fulfillService?: FulfillServiceDto
  fulfillProduct?: FulfillProductDto
  variantOptions?: Metafield[]
  variantCode?: string
  variantName?: string
  variantTitle?: string
}

export interface CreateFulfillVariantDto {
  sku?: string
  baseCost?: number
  fulfillProduct?: IdBaseType | any
  variantOptions?: Metafield[]
}

export interface UpdateFulfillVariantDto {
  sku?: string
  baseCost?: number
  fulfillProduct?: IdBaseType | any
  variantOptions?: Metafield[]
}

export const FulfillVariantApiPaths = {
  BASE: '/fulfillment/products/variants',
  WITH_ID: '/fulfillment/products/variants/{id}',
  QUERY: '/fulfillment/products/variants/query',
  SEARCH: '/fulfillment/products/variants/search',
  BULK: '/fulfillment/products/variants/bulk'
}

export class FulfillVariantService extends CrudService<FulfillVariantDto, CreateFulfillVariantDto, UpdateFulfillVariantDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: FulfillVariantApiPaths.WITH_ID,
        updateById: FulfillVariantApiPaths.WITH_ID,
        deleteById: FulfillVariantApiPaths.WITH_ID,
        query: FulfillVariantApiPaths.QUERY,
        base: FulfillVariantApiPaths.BASE,
        deleteMany: FulfillVariantApiPaths.BULK,
        updateMany: FulfillVariantApiPaths.BULK
      }
    });
  }

  async getByFulfillProductId(fulfillProductId: number, options?: RequestOptions) {
    return this.query({
      limit: 250,
      filter: { fulfillProductId: { equalTo: fulfillProductId }}
    }, options)
  }

  async searchFulfillVariantsByVariantTitle(searchValue: string, fulfillServiceId?: any) {
    let filter: any = {
      variantTitle: { contains: searchValue },
      ...(fulfillServiceId ? { fulfillServiceId: { equalTo: fulfillServiceId } } : {}),
    }

    let pageData = await this.query({ filter, page: 1, limit: 50 }, { baseUrl: FulfillVariantApiPaths.SEARCH });
    return pageData || [];
  }
}

export const FulfillVariantServices = new FulfillVariantService()
