import React, { ChangeEventHandler } from "react";
import { CardLink, Col, Label, Row } from "reactstrap";
import { FormikInput } from "./FormikInput";
import { FormikHandlers, FormikState } from "formik/dist/types";
import { FormikHelpers } from "formik";
import googleDriveImage from '../../assets/images/brands/google-drive.jpg'
import { Link } from "react-router-dom";
import { BiLinkExternal } from "react-icons/bi";
import { OptionalLabel } from "components/common/OptionalLabel";
import { FormikInputField } from "./FormikInputField";

interface SingleLineImageProps {
  name: string;
  label: string;
  placeholder: string;
  url?: string;
  required?: boolean;
  onChange?:ChangeEventHandler<any> | ((value: any) => void);
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  images?: any
}

export const SingleLineImage = (props: SingleLineImageProps) => {
  const {
    label,
    name,
    placeholder,
    url,
    onChange,
    required,
    validation
  } = props;

  // const isDrive = (url || validation.values[name] || "").startsWith("https://drive.google.com")
  const isDrive = false
  
  return (
    <Row style={{ minHeight: 100}}>
      <Col xs={3} style={{ padding: 10}}>
        {
          isDrive ?
          <iframe style={{ maxHeight: 140,  border: "1 solid rgb(239, 242, 247)"}}
          src= {url || validation.values[name] || '' }
          width={ "100%" }/> :
          <img
          style={{ maxHeight: 140, objectFit:"cover", border: "1 solid rgb(239, 242, 247)"}}
          src= {url?.startsWith("https://drive.google.com") ? googleDriveImage : validation.values[name] || '' }
          width={ "100%" }
          />
      }
      </Col>
      <Col xs={9} className="pt-2">
        <Row>
          <Col xs={'auto'} className="pe-0">
          {
            required === false
            ? <OptionalLabel label={label} className='qq'/>
            : label && <Label>{label}</Label>
          }
          </Col>
          <Col className="p-0">
            <CardLink
              target="_blank"
              href={url}
              className="ps-1"
            >
              <span> </span>
              <BiLinkExternal size="18" />
            </CardLink>
          </Col>
        </Row>
        <FormikInputField
          name={name}
          placeholder={placeholder}
          validation={validation}
          onChange={onChange}
          value={url || validation.values[name]}
        />
      </Col>
      <div className="dropdown-divider"/>
    </Row>
  );
};