import React from "react"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import { FormikInput } from "./FormikInput"
import { t } from "../../core/translations"
import { FormikHandlers, FormikState } from "formik/dist/types"
import { ShippingAddressType } from "../../types/address.type"
import { FormikHelpers } from "formik"
import * as Yup from "yup"
import { Messages } from "common/messages"
import { Labels } from "common/labels"

interface ShippingProps {
  title?: string
  methodName: string
  addressName: string
  isEditing?:boolean
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>
  preventEdit?: boolean
}

export const shippingAddressSchema = Yup.object({
  firstName: Yup.string().required(
    t(Messages.field_required, { field: t(Labels.first_name) })
  ),
  lastName: Yup.string(),
  company: Yup.string().nullable(),
  address1: Yup.string().required(
    t(Messages.field_required, { field: t(Labels.address) })
  ),
  city: Yup.string().required(t(Messages.field_required, { field: t(Labels.city) })),
  provinceCode: Yup.string(),
  countryCode: Yup.string().required(
    t(Messages.field_required, { field: t(Labels.country_code) })
  ),
  zip: Yup.string().required(
    t(Messages.field_required, { field: t(Labels.postcode) })
  ),
})

export const ShippingAddress = (props: ShippingProps) => {
  const { methodName, addressName, validation, isEditing, preventEdit } = props
  const handleNestedFieldChange = (value: any, nestedKey: string) => {
    if (preventEdit) return
    validation.setFieldValue(addressName, {
      ...(validation?.values[addressName] || {}),
      [nestedKey]: value,
    })
  }

  return (
    <Card className="overflow-hidden border border-1">
      <CardHeader className={`fw-400 fs-5 text-info ${!props?.title && 'd-none'}`}>
        <Col>
          <h4 className="m-0 py-2 fw-400 fs-5 text-info">{props?.title}</h4>
        </Col>
      </CardHeader>
      <CardBody>
        <Row className="mb-2">
          <Col>
            <FormikInput
              name={methodName}
              value={isEditing ? validation?.values[methodName] :validation.values.order?.shippingLines[0]?.methodTitle || ''}
              label={t(Labels.shipping_method)}
              required={false}
              placeholder={t(Labels.shipping_method_hint)}
              validation={validation}
              readOnly={preventEdit}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <FormikInput
              type={"text"}
              name="shippingAddress.firstName"
              label={t(Labels.first_name)}
              value={validation?.values[addressName]?.firstName || ''}
              placeholder={t(Labels.first_name_hint)}
              validation={validation}
              onChange={(event: any) =>
                handleNestedFieldChange(event, "firstName")
              }
            />
          </Col>
          <Col>
            <FormikInput
              type={"text"}
              name="shippingAddress.lastName"
              label={t(Labels.last_name)}
              value={validation?.values[addressName]?.lastName || ''}
              placeholder={t(Labels.last_name_hint)}
              validation={validation}
              required={false}
              onChange={(event: any) =>
                handleNestedFieldChange(event, "lastName")
              }
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <FormikInput
              type={"text"}
              name="shippingAddress.company"
              label={t(Labels.company)}
              required={false}
              value={validation?.values[addressName]?.company || ''}
              placeholder={t(Labels.company_hint)}
              validation={validation}
              onChange={(event: any) => handleNestedFieldChange(event, "company")}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <FormikInput
              type={"text"}
              name="shippingAddress.address1"
              label={t(Labels.address)}
              value={validation?.values[addressName]?.address1 || ''}
              placeholder={t(Labels.address)}
              validation={validation}
              onChange={(event: any) =>
                handleNestedFieldChange(event, "address1")
              }
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <FormikInput
              type={"text"}
              name="shippingAddress.city"
              label={t(Labels.city)}
              value={validation?.values[addressName]?.city || ''}
              placeholder={t(Labels.city_hint)}
              validation={validation}
              onChange={(event: any) => handleNestedFieldChange(event, "city")}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <FormikInput
              type={"text"}
              name="shippingAddress.provinceCode"
              label={t(Labels.state)}
              value={validation?.values[addressName]?.provinceCode || ''}
              placeholder={t(Labels.state_hint)}
              validation={validation}
              onChange={(event: any) =>
                handleNestedFieldChange(event, "provinceCode")
              }
            />
          </Col>
          <Col>
            <FormikInput
              type={"text"}
              name="shippingAddress.countryCode"
              label={t(Labels.country_code)}
              value={validation?.values[addressName]?.countryCode || ''}
              placeholder={t(Labels.country_code_hint)}
              validation={validation}
              onChange={(event: any) =>
                handleNestedFieldChange(event, "countryCode")
              }
            />
          </Col>
          <Col>
            <FormikInput
              type={"text"}
              name="shippingAddress.zip"
              label={t(Labels.postcode)}
              value={validation?.values[addressName]?.zip || ''}
              placeholder={t(Labels.postcode_hint)}
              validation={validation}
              onChange={(event: any) => handleNestedFieldChange(event, "zip")}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <FormikInput
              type={"text"}
              name="shippingAddress.phone"
              label={t(Labels.phone)}
              required={false}
              value={isEditing 
                ? validation?.values[addressName]?.phone 
                : validation.values.order?.shippingAddress?.phone 
                || validation.values.order?.billingAddress?.phone || ''}
              placeholder={t(Labels.phone)}
              validation={validation}
              onChange={(event: any) => handleNestedFieldChange(event, "phone")}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
