import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { Dates } from "../../../utils/dates";

interface propsInputDate {
  value: Date | undefined
  onChange: Function
  className: string
  placeholderText?: string 
}
export const DateInput = (props: propsInputDate) => {
  const { value, onChange, className, placeholderText } = props

  return (
    <DatePicker
      dateFormat={"dd/MM/yyyy"}
      selected={value}
      onChange={(date: Date) => {
        onChange(date && Dates.toDefaultLocaleDate(date))
      }}
      className={className}
      placeholderText={placeholderText || "--/--/----"}
    />
  )
}
