import { Labels } from "common/labels";
import { t } from "core/translations";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { Loadable, useRecoilRefresher_UNSTABLE } from "recoil";
import { Loading } from "./Loading";
import errorImg from "../../assets/images/error-img.png";
import { useEffect } from "react";
import { isEmpty } from "lodash";

export interface CheckDataLoadableProps {
  loadable: Loadable<any>;
  children: any;
  goBack?: string
  selector: any
  selectorParams: string
}

const CheckDataLoadable = (props: CheckDataLoadableProps) => {
  const { loadable, children, goBack, selector, selectorParams } = props
  const history = useHistory()

  // TODO remove fakeRefreshDataLoadable
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(selector(selectorParams))
  useEffect(() => {
    loadable.state == "hasValue" && !isEmpty(loadable.contents) && fakeRefreshDataLoadable()
  }, [selectorParams])
  
  const getPageInfo = () => {
    if (!loadable || loadable.state == "loading") {
      return (
        <div className="mt-4">
          <Loading />
        </div>
      )
    } else {
      if (loadable?.contents?.code == 'network_error' || loadable?.contents?.code == 'unknown_error' || !loadable?.contents) {
        return (
          <>
            <div className={`d-flex justify-content-center align-items-center gap-2 flex-column mt-4`}>
              <h3 className="mt-4">{t(Labels.no_data_available)}</h3>
              <Button className="btn btn-primary mt-2" onClick={() => {goBack ? history.replace(goBack) : history.goBack()}}>
                <i className="dripicons-backspace me-2"></i>
                {t(Labels.go_back)}
              </Button>
              <Row className="justify-content-center w-100">
                <Col md="8" xl="6">
                  <div className="mt-4 d-flex justify-content-center">
                    <img src={errorImg} alt="" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )
      } else {
        return <>{children}</>
      }
    }
  }

  return (
    <>{ getPageInfo() }</>
  )
}

export default CheckDataLoadable