import { Labels } from "common/labels";
import { t } from "core/translations";
import React, { Component } from "react";
import { Container } from "reactstrap";
import { createDocumentPageName } from "../../utils/utils";

class Dashboard extends Component {
  componentDidMount(): void { document.title = t(Labels.app_title) }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container>
              <h4 className="text-center">{t(Labels.welcome_home_page)}</h4>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;
