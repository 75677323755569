import { RestApiErrors } from "../core/rest-api/rest-api.error";

export const Messages = {
  ...RestApiErrors,
  coming_soon: 'This feature is coming soon',
  form_has_invalid_field: 'Thông tin nhập không hợp lệ vui lòng kiểm tra thông báo',
  login_unsuccessfully: 'Đăng nhập không thành công',
  create_resource_unsuccessfully: 'Tạo không thành công',
  update_resource_unsuccessfully: 'Cập nhật không thành công',
  delete_resource_unsuccessfully: 'Xóa không thành công',
  action_unsuccessfully: 'Thao tác không thành công',
  confirm_remove_selected_items: 'Bạn có chắc chắn muốn xóa tất cả các hàng đã chọn?',
  remove_selected_items_successfully: 'Đã xóa thành công {{number}} mục đã chọn?',
  create_sale_channel_succeeded: "Tạo kênh bán hàng thành công",
  update_sale_channel_succeeded: "Cập nhật kênh bán hàng thành công",
  sync_sale_channel_order_successfully: "Yêu cầu đồng bộ đơn hàng từ kênh đã thêm vào hàng đợi",
  export_successfully: "Xuất dữ liệu thành công",
  export_invalid: "Vui lòng chọn điều kiện xuất dữ liệu",
  export_unsuccessfully: "Xuất dữ liệu không thành công",
  update_successfully: "Cập nhật thành công",
  bulk_action_successfully: "Tác vụ đã thực hiện thành công",
  update_unsuccessfully: "Cập nhật không thành công",
  invalid_update_data: "Invalid updating data",
  status_is_up_to_date: "Status is up to date",
  bulk_action_unsuccessfully: "Tác vụ thực hiện không thành công",
  field_required: "Vui lòng nhập giá trị {{field}}",
  field_required_min: "Vui lòng nhập giá trị {{field}} lớn hơn hoặc bằng {{min}}",
  field_required_max: "Vui lòng nhập giá trị {{field}} nhỏ hơn hoặc bằng {{max}}",
  field_required_range: "Vui lòng nhập giá trị {{field}} từ {{min}}-{{max}}",
  create_artwork_successfully: "Create artwork successfully",
  save_fulfill_successfully: "Lưu đơn fulfill thành công",
  create_fulfill_design_successfully: "Create fulfill design successfully",
  request_fulfill_successfully: "Gửi yêu cầu fulfill thành công",
  no_notes_from_customer: "No notes from customer",
  save_fulfill_product_successfully: "Lưu fulfill product thành công",
  multiple_fulfillment_variant_with_sku: "Nhiều Fulfillment Variant cùng SKU. Vi lòng kiểm tra lại",
  enter_enough_value_for_all_variant: "Vui lòng nhập đủ giá trị cho tất cả Fulfillment Variant",
  update_product_successfully: "Cập nhật sản phẩm thành công",
  create_product_successfully: "Tạo sản phẩm thành công",
  please_enter_password: "Vui lòng nhập password",
  save_account_successfully: "Lưu Account thành công",
  update_setting_successfully: "Cập nhật cài đặt thành công",
  your_request_execute_unsuccessfully: "Yêu cầu thực hiện không thành công",
  error_type_storage_credentials: 'Nhập sai kiểu Storage Credentials, vui lòng kiểm tra lại',
  do_you_want_to_discard_the_updated_information: 'Bạn có chắc chắn muốn HỦY các các thông tin đã được cập nhật?',
  really_want_to_continue_this_action: 'Do you really want to continue this action?',
  required_upload_design_image: 'Yêu cầu tải ảnh design cho {{number}} đơn đã được thêm vào hàng đợi',
  required_upload_fulfill_design_done: "Yêu cầu upload fulfill design cho {{number}} đơn đã thực hiện xong",
  recreate_fulfill_order_selected: "Bạn có chắc chắn muốn tự động tạo lại đơn fulfill đã chọn?",
  process_recreate_fulfill_order_successfully: "Xử lý tạo lại đơn fulfill cho {{number}} đơn hàng thành công",
  change_password_successfully: 'Thay đổi mật khẩu thành công',
  change_password_unsuccessfully: 'Thay đổi mật khẩu không thành công',
  required_fulfill_order: 'Yêu cầu fulfill {{number}} đơn đã được thêm vào hàng đợi',
  import_successfully: 'Nhập liệu thành công. Vui lòng kiểm tra file kết quả',
  handle_import_response_data_failed: 'Handle import response data failed',
  save_product_successfully: 'Lưu Product thành công',
  save_artwork_successfully: "Lưu Artwork thành công",
  custom_data_keys: 'custom data keys',
  variant_option_keys: 'variant option keys',
  sku_is_not_product: "Sku không phải sản phẩm",
  sku_length_validate: "Chỉ lấy SKU đến ký tự",
  sale_price_validation: 'Vui lòng nhập thông tin sale price',
  estimate_production_time_required: 'Vui lòng chọn thông tin estimate production time',
  group_validation: 'Vui lòng nhập thông tin group',
  choose_custom_tempalte: 'Choose custom template...',
  your_request_execute_successfully: "Yêu cầu thực hiện thành công",
  upload_tracking_to_channel_enqueued: 'Request upload tracking number for {{number}} fulfillment already added to queue',
  update_fulfillment_status_enqueued: 'Request update fulfillment status for {{number}} fulfillment already added to queue',
  publish_products_confirm_message: 'Bạn có muốn xuất bản và cập nhật ảnh của sản phẩm của kênh bán hàng?',
  save_shipping_address_successfully: 'Lưu shipping address thành công',
  save_fulfillment_successfully: "Lưu fulfillment thành công",
  change_team_successfully: "Change business successfully",
  invalid_workspace: "Please choose your business to continue.",
  bulk_edit_is_limited_to__n_item: "Bulk edit is limited to {{n}} items",
  modified: "Modified",
  personal_custom_reviewed: "Personal custom reviewed",
  personal_custom_wait_for_review: "Personal custom wait for review",
  update_artwork_successfully: "Update artwork successfully",
  default_product_type_image_create_instructions: 'Default image create for product will generated by "DefaultBaseImageUrl + Image Suffix". DefaultBaseImageUrl setting up in sale channel.',
  save_fulfillment_order_successfully: "Lưu Fulfillment order thành công",
  // save_fulfillment_order_successfully: "Lưu Fulfillment Order thành công",
  required_update_fulfillment_order_done: "Yêu cầu update fulfillment order cho {{number}} đơn đã thực hiện xong",
  // required_update_fulfillment_order_done: "Yêu cầu update fulfillment order cho {{number}} đơn đã thực hiện xong",
  confirm_mask_exporting_fulfillment_order_as_submitting: 'Do you want to mask all exporting fulfillment order as submitting when export done?',
  // confirm_mask_exporting_fulfillment_order_as_submitting: 'Do you want to mask all exporting fulfillment order as submitting when export done?',
  import_unsuccessfully: 'Nhập liệu không thành công',
  fulfill_order__tab_is_ready_only: "Order item tab {{tab}} is read only",
  the_ids_is_copied_to_clipboard: 'The ids is copied to clipboard',
  This_order_is_not_linked_to_any_fulfill_variant: 'This order is not linked to any fulfill variant',
  please_input_request_status: 'Please input request status',
  please_input_modify_note: 'Please input modify note',
  confirm_request_fulfill_order: 'Are you sure want to fulfill this order?',
  required_fulfillment_order_done: "Yêu cầu fulfillment order cho {{number}} đơn đã thực hiện xong",
  // required_fulfillment_order_done: "Yêu cầu fulfillment order cho {{number}} đơn đã thực hiện xong",
  please_select_least_one_item: 'Vui lòng chọn ít nhất 1 fulfillment order',
  changed_fulfill_service_in_bulk_fulfillment_order: 'New fulfill variant by fulfill service already apply to editing table, please review before save.',
  // changed_fulfill_service_in_bulk_fulfillment_order: 'New fulfill variant by fulfill service already apply to editing table, please review before save.'
  start_download_file_unsuccessfully: 'Start download file unsuccessfully',
  click_to_unsave_suggest: 'Click to save suggest fulfill variant for variation {{productVariationTitle}}',
  click_to_discard_suggest: 'Click to discard suggestion',
  duplicate_data_variant_mapper: 'Dữ liệu {{variantMapper}} đã tồn tại. Bạn có muốn ghi đè variant mapping từ {{variantMapper}} sang {{newVariantMapper}} không',
  load_bulkd_edit_data_error: 'Không thể tải được dữ liệu từ máy chủ',
  invalid_team: "Please choose your team to continue.",
  update_team_successfully: "Change team successfully",
  request_upload_design_accepted: "Yêu cầu tạo đường dẫn thiết kế thành công.",
  request_failed: "Yêu cầu thực hiện không thành công.",
  description_validation: 'Vui lòng nhập thông tin description',
  sku_validation: 'Vui lòng nhập thông tin sku',
  min_price_validation: 'Vui lòng nhập thông tin min price',
  max_price_validation: 'Vui lòng nhập thông tin max price',
  really_want_to_delete_option: 'Bạn có thực sự muốn xoá option?',
  cannot_delete_option: 'Không thể xoá option này vì có giá trị tồn tại trong Variants',
  please_change_option_name: 'Tên đã tồn tại trước đó, vui lòng nhập tên hợp lệ',
  variant_duplicated: 'Có giá trị variant bị trùng lặp, vui lòng kiểu tra lại',
  really_want_to_delete_value: 'Bạn có thực sự muốn xoá {{value}}? Nếu xoá {{value}} thì các variant có chứa {{value}} sẽ bị xoá theo!',
  order_number_is_copied_to_clipboard: 'Order number is copied to clipboard',
  confirm_change_vendor_status: "Bạn có chắc chắn muốn thay đổi trạng thái hóa đơn cho người bán thành {{vendorInvoiceStatus}}",  
  confirm_change_invoice_status: "Bạn có chắc chắn muốn thay đổi trạng thái hóa đơn cho nhà cung cấp thành {{fulfillInvoiceStatus}}",
  save_fulfillment_invoice_successfully: "Lưu hóa thành công",
  create_fulfillment_invoice_successfully: "Tạo hóa thành công",
  create_invoice_successfully: "Tạo hóa đơn cho {{number}} đơn đã thực hiện xong. Vui lòng kiểm tra kết quả",
  mapping_duplicated: 'Có giá trị mapping bị trùng lặp, vui lòng kiểu tra lại',
  mapping_required: 'Please input value for Mapping Data',
  input_mapping_item_required: 'Vui lòng nhập đầy đủ thông tin Mapping Data',
  save_service_successfully: 'Lưu Service thành công',
  fill_default_mapping_data_error: 'Máy chủ lỗi, không thể tải Default Mapping Data. Vui lòng thử lại sau',
  fill_default_mapping_data_success: 'Fill Default Mapping Data thành công',
  save_fulfill_variant_mapper_successfully: "Lưu fulfill variant mapping thành công",
  really_want_to_delete_image: 'Bạn có thực sự muốn xoá ảnh này ?',
  import_confirm: "Import request is being processed. Please check at the report",
  do_you_want_to_import_retry: "Bạn có muốn thử import lại file",
  do_you_want_cancel_import: "Bạn có muốn hủy bỏ quá trình import file"
};
