import { Loadable } from "recoil";
import React, { useEffect, useState } from "react";
import { Loading } from "./Loading";
import { t } from "../../core/translations";
import { Labels } from "../../common/labels";

export interface LoadableComponentProps {
  loadable?: Loadable<any>;
  noDataValidator?: (data: any) => boolean;
  noDataIndication?: React.Component<any>;
  reloadSilent?: boolean
  children?: any;
}

export const LoadableComponent = (props: LoadableComponentProps) => {
  let [dataLoaded, setDataLoaded] = useState(false)
  let { children, noDataIndication, noDataValidator = value => !value, loadable, ...containerProps } = props;

  if (!loadable || loadable.state == "loading") {
    return (
      <Loading />
    )
  }
  else if(dataLoaded) {
    if(loadable?.contents?.code == 'network_error' || loadable?.contents?.code == 'unknown_error') {
      return (
        <>
          { noDataIndication ? noDataIndication :
            (
              <div className={`d-flex justify-content-center`}>
                <p className="text-danger">{t(Labels.no_data_available)}</p>
              </div>
            )
          }
        </>
      )
    } else {
      return (
        <>
          {children}
        </>
      )
    }
  } 
  else if (noDataValidator(loadable?.contents)) {
    return (
      <>
        { noDataIndication ? noDataIndication :
          (
            <div className={`d-flex justify-content-center`}>
              <p className="text-danger">{t(Labels.no_data_available)}</p>
            </div>
          )
        }
      </>
    )
  } else {
    setDataLoaded(true)
    return <Loading />
  }
};