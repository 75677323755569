import { Labels } from "common/labels";
import { ROW_HEIGHT } from "components/bulk/BulkEditor";
import { FormValidation } from "components/form/FormikForm";
import { t } from "core/translations";
import { FulfillVariantServices } from "data/services/fulfill-variation.service";
import { isEmpty, isPlainObject, omit, startsWith, uniqBy } from "lodash";
import { getFulfillProductLabel } from "pages/fulfillments/components/fulfill-variation.select";
import { useEffect, useState } from "react";
import DataGrid, { Column, CopyEvent, EditorProps, FillEvent, PasteEvent, RowsChangeData } from "react-data-grid";
import { Col, Label } from "reactstrap";
import { useRecoilValueLoadable } from "recoil";
import { SelectEditor } from "../artwork-bulk-create.page";
import {
  fulfillVariantByFulfillProductIdsSelector,
  fulfillVariantByIdsSelector
} from "data/atoms/fulfill-variation.atom";
import { Logger } from "../../../core/logger";
import { withPermission } from "../../../common/hooks/use-permission";
import { ActionEntities, ResourceEntities } from "../../../types/permission-type";
import { Toasts } from "core/notification/notifications";

interface FulfillOptionFormProps {
  validation: FormValidation
  isCreateFulfillOption: Boolean
}

export const FulfillOptionForm = (props: FulfillOptionFormProps) => {
  const { validation, isCreateFulfillOption } = props
  const [optionFulfillVariant, setOptionFulfillVariant] = useState([])
  const [fulfillOptions, setFulfillOptions] = useState<any>([])
  const listIdFulfillableProducts = validation.values.fulfillableProducts?.map((item: any) => item.id)
  const listFulfillVariantId = validation.values.fulfillOptions?.map((value: any) => value.fulfillVariantId)
  const fulfillVariantLoadable = useRecoilValueLoadable(fulfillVariantByFulfillProductIdsSelector(
    withPermission(ActionEntities.read, ResourceEntities.fulfillProductEntity) ? listIdFulfillableProducts : null
  ))

  const fulfillVariantLoadableByIds = useRecoilValueLoadable(fulfillVariantByIdsSelector(
    withPermission(ActionEntities.read, ResourceEntities.fulfillVariantEntity) ? listFulfillVariantId : null
  ))

  useEffect(() => {
    // set option select
    if (fulfillVariantLoadable.state == "hasValue") {
      // const newOptionFulfillVariant: any = [];
      // (fulfillVariantLoadable.contents?.pageItems || []).forEach((data: any) => {
      //   const isCheckOptionFulfillVariant = validation.values.fulfillOptions?.every(
      //     (fulfillOption: any) => fulfillOption.fulfillVariant?.id != data.id
      //   )
      //   if (isCheckOptionFulfillVariant) {
      //     newOptionFulfillVariant.push(data)
      //   }
      // })
      const newOptionFulfillVariant: any = fulfillVariantLoadable.contents?.pageItems || [];
      setOptionFulfillVariant(newOptionFulfillVariant)
    }
  }, [fulfillVariantLoadable.contents, validation.values.fulfillOptions, validation.values.fulfillableProducts])

  useEffect(() => {
    // check if isEmpty fulfillableProducts =>  reset fulfillProduct + fulfillOption
    if (!validation.values.fulfillableProducts && validation.values.fulfillProduct) {
      validation.setFieldValue("fulfillableProducts", [validation.values.fulfillProduct])
    } else {
      const findFulfillProduct = validation.values.fulfillableProducts?.find(
        (fulfillableProduct: any) => fulfillableProduct?.id === validation.values.fulfillProduct?.id
      )
      if (!findFulfillProduct) {
        validation.setFieldValue("fulfillProduct", null)
      }
    }
  }, [validation.values.fulfillableProducts, validation.values.fulfillProduct])

  const createVariantsMapper = () => {
    let current = validation.values.fulfillOptions
    let newFulfillOptions = !current ? [{}] : [...current]
    if (!newFulfillOptions?.length || !isEmpty(omit(newFulfillOptions[newFulfillOptions.length - 1], "idx"))) {
      newFulfillOptions.push({} as any)
    }
    return newFulfillOptions.map((item: any, idx: number) => ({ ...item, idx }))
  }

  useEffect(() => {
    setFulfillOptions(createVariantsMapper())
  }, [validation.values.fulfillOptions])

  useEffect(() => {
    if (fulfillVariantLoadableByIds.state == "hasValue") {
      const newFulfillOptions: any = validation.values.fulfillOptions
        ?.filter((value: any) => !isEmpty(omit(value, ["idx"])))
        .map((value: any) => {
          const findItem = fulfillVariantLoadableByIds.contents.pageItems.find((item: any) => item.id === value.fulfillVariantId)
          if (findItem) {
            return { fulfillVariant: findItem, ...value }
          }
          return value
        })
      validation.setFieldValue("fulfillOptions", newFulfillOptions)
    }
  }, [fulfillVariantLoadableByIds.contents, JSON.stringify(fulfillOptions)])

  const idFulfillProduct = +validation.values.fulfillProduct?.id
  useEffect(() => {
    // load data FulfillVariant by id idFulfillProduct
    (async () => {
      if (idFulfillProduct && isCreateFulfillOption) {
        const data = await FulfillVariantServices.getByFulfillProductId(idFulfillProduct)
        let fulfillOptions =
          validation.values.fulfillOptions?.map((item: any) => omit(item, ["idx"])).filter((item: any) => !isEmpty(item)) || []
        let newFulfillOptions = data.pageItems || []

        newFulfillOptions.forEach((item: any) => {
          const isCheckChildFulfillOption = validation.values.fulfillOptions?.every(
            (fulfillOption: any) => fulfillOption.fulfillVariantId !== item.id
          )
          if (isCheckChildFulfillOption) {
            fulfillOptions.push({ fulfillVariantId: item.id })
          }
        })
        validation.setFieldValue("fulfillOptions", fulfillOptions)
      }
    })()
  }, [idFulfillProduct])

  useEffect(() => {
    (async () => {
      if (fulfillVariantLoadable.state == "hasValue") {
        const idsFulfillableProducts = validation.values.fulfillableProducts
        if (!isEmpty(idsFulfillableProducts)) {
          const newFulfillProduct: any = []
          validation.values.fulfillOptions?.forEach((fulfillOption: any) => {
            const findFulfillOption = fulfillVariantLoadable.contents?.pageItems?.find(
              (data: any) => data.id === fulfillOption?.fulfillVariantId
            )

            if (findFulfillOption) {
              newFulfillProduct.push({ ...fulfillOption, fulfillVariant: findFulfillOption })
            }
          })
          validation.setFieldValue("fulfillOptions", newFulfillProduct)
        } else {
          validation.setFieldValue("fulfillOptions", [{}])
        }
      }
    })()
  }, [fulfillVariantLoadable.contents, validation.values.fulfillableProducts])

  const removeOption = (idx: any) => {
    let fulfillOptions = validation.values.fulfillOptions.filter((item: any, index: number) => index !== idx)

    fulfillOptions = isEmpty(fulfillOptions) ? [{}] : fulfillOptions

    validation.setFieldValue("fulfillOptions", fulfillOptions)
  }

  function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<any>) {
    if (columnKey.startsWith("variantOptions")) {
      const idxVariant = +columnKey.split(".")[1]
      const nameVariantOption = columnKey.split(".").slice(2, columnKey.split(".").length).join("")

      let idxVariantOptionChange = idxVariant || 0
      let valueVariantOptionChange = {}

      sourceRow.variantOptions?.forEach((item: any, idx: any) => {
        if (item?.optionName === nameVariantOption) {
          idxVariantOptionChange = idx
          valueVariantOptionChange = item
        }
      })

      const newVariantOptions = [...(targetRow?.variantOptions || [])]
      newVariantOptions[idxVariantOptionChange] = valueVariantOptionChange

      return {
        ...targetRow,
        variantOptions: newVariantOptions,
      }
    }

    if (columnKey === "fulfillVariant") {
      return { ...targetRow, [columnKey]: sourceRow[columnKey], fulfillVariantId: sourceRow[columnKey]?.id }
    }
    return { ...targetRow, [columnKey]: sourceRow[columnKey] }
  }

  const handlePaste = ({ sourceColumnKey, sourceRow, targetColumnKey, targetRow, productType }: PasteEvent<any> & {productType: any}) => {

    if (targetColumnKey.startsWith("variantOptions")) {
      // const nameVariantOption = sourceColumnKey.split(".")[1]
      const idxVariant = +targetColumnKey.split(".")[1]
      const nameVariantOption = sourceColumnKey.split(".").slice(2, sourceColumnKey.split(".").length).join("")
      const nameVariantOptionTarget = targetColumnKey.split(".").slice(2, sourceColumnKey.split(".").length).join("")
      let countCheckValueInVariantOptions = 0
      let idxVariantOptionChange = idxVariant || 0
      let valueVariantOptionChange: any = {}

      sourceRow.variantOptions?.forEach((item: any, idx: any) => {
        if (item?.optionName === nameVariantOption) {
          valueVariantOptionChange = item
        }
      })

      if(sourceColumnKey === 'fulfillVariant' && sourceRow.fulfillVariant?.variantTitle){
        valueVariantOptionChange.values = [sourceRow.fulfillVariant.variantTitle]
      }

      const variantOptionValuesByName = productType.variantOptions?.find((item: any) => item.name === nameVariantOptionTarget)?.optionValues

      valueVariantOptionChange.values?.forEach((item: string) => !variantOptionValuesByName?.includes(item) && countCheckValueInVariantOptions++ )

      if (countCheckValueInVariantOptions) {
        Toasts.error(t(Labels.incompatible_pasting_value))
        return targetRow
      } 

      let newVariantOptions = [...(targetRow?.variantOptions || [])]
      if(nameVariantOption === nameVariantOptionTarget){
        newVariantOptions[idxVariantOptionChange] = valueVariantOptionChange
        
      }
      else {
        newVariantOptions[idxVariantOptionChange] = {
          optionName: nameVariantOptionTarget, 
          values: valueVariantOptionChange.values
        }
      }

      for (let index = 0; index < idxVariantOptionChange; index++) {
        if(!newVariantOptions[index]){
          newVariantOptions[index] = {}
        }
      }
      

      return {
        ...targetRow,
        variantOptions: newVariantOptions,
      }
    }
    
    if(targetColumnKey === 'fulfillVariant' && sourceColumnKey !== targetColumnKey && !isPlainObject(sourceRow['variantOptions']) && sourceRow['variantOptions']) {
      Toasts.error(t(Labels.incompatible_pasting_value))
      return targetRow
    }
    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] }
  }

  function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<any>): void {
    if (window.isSecureContext) {
      let value = sourceRow[sourceColumnKey]
      navigator.clipboard.writeText(value)
    }
  }

  function rowKeyGetter(row: any = {}) {
    return `${row.fulfillVariant?.id}-${row?.idx}`
  }

  const onRowsChange = (rows: any, data: RowsChangeData<any>) => {
    validation.setFieldValue("fulfillOptions", rows)
  }

  const [columns, setColumns] = useState<any>([])

  useEffect(() => {
    const columnDataGrid: Column<any, any>[] = [
      {
        key: "fulfillVariant",
        name: t(Labels.fulfill_variants),
        minWidth: 200,

        // eslint-disable-next-line react/display-name
        formatter: ({ row }: any = {}) => {
          return row?.fulfillVariant ? (
            <div>{t(getFulfillProductLabel(row?.fulfillVariant))}</div>
          ) : (
            <p style={{ opacity: 0.7 }}>{t(Labels.choose_fulfill_variants)}</p>
          )
        },
        // eslint-disable-next-line react/display-name
        editor: ({ row, onRowChange }: any = {}) => {
          return (
            <SelectEditor
              openMenuOnFocus={true}
              embedHeight={ROW_HEIGHT}
              value={row?.fulfillVariant}
              options={optionFulfillVariant}
              onChange={value => {
                onRowChange({ ...row, fulfillVariant: value, fulfillVariantId: value.id }, true)
              }}
              getOptionValue={(item: any) => item.id}
              getOptionLabel={(item: any) => t(getFulfillProductLabel(item))}
            />
          )
        },
      },
      ...(validation.values.variantOptions || [])
        .filter((item: any) => item.name)
        .map((variant: any = {}, index: number) => ({
          // key: `variantOptions.${variant.name}.${index}`,
          key: createDynamicGridKey('variantOptions', index, variant.name),
          name: t(variant.name),
          minWidth: 200,
          // eslint-disable-next-line react/display-name
          formatter: ({ row }: any = {}) => {
            const variantOptions = row?.variantOptions?.find((option: any) => option?.optionName === variant.name)
            return (
              <div>
                {row.variantOptions ? (
                  (variantOptions || row.variantOptions[index])?.values?.filter((value: any) => variant?.optionValues?.includes(value)).length ? (
                    `${(variantOptions || row.variantOptions[index])?.values.filter((value: any) => variant?.optionValues?.includes(value)).join(", ")}`
                  ) : (
                    <p style={{ opacity: 0.7 }}>{`Choose ${variant.name}`}</p>
                  )
                ) : (
                  <p style={{ opacity: 0.7 }}>{`Choose ${variant.name}`}</p>
                )}
              </div>
            )
          },
          // eslint-disable-next-line react/display-name
          editor: ({ row, onRowChange }: EditorProps<any>) => {
            const variantOptions = row?.variantOptions?.find((option: any) => option?.optionName === variant.name)
            const options = variant?.optionValues?.map((item: string) => ({ value: item, label: item })) || []
            let values = row?.variantOptions
              ? (variantOptions || row?.variantOptions[index])?.values
                  ?.filter((value: any) => variant?.optionValues?.includes(value))
                  .map((value: string) => ({ value, label: value }))
              : []
            return (
              <SelectEditor
                value={values}
                isMulti
                options={options}
                openMenuOnFocus={true}
                getOptionLabel={(option: any) => option.value}
                getOptionValue={(option: any) => option.value}
                onChange={values => {
                  let variantOptions = [...(row.variantOptions || [])]
                  variantOptions[index] = { optionName: variant.name, values: values.map((item: any) => item.value) }
                  for (let idx = 0; idx < variantOptions.length; idx++) {
                    if(!variantOptions[idx]){
                      variantOptions[idx] = {}
                    }
                  }
                  
                  onRowChange({ ...row, variantOptions })
                }}
                embedHeight={ROW_HEIGHT}
                styles={{
                  control: prev => ({
                    ...prev,
                    "> div:first-of-type": {
                      overflow: "hidden auto",
                    },
                  }),
                }}
                closeMenuOnSelect={false}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            )
          },
        })),
      {
        key: "remove",
        width: 50,
        // eslint-disable-next-line react/display-name
        formatter: ({ row }: any = {}) => {
          const indexLastChlid = fulfillOptions?.length - 1
          if (row?.idx == fulfillOptions[indexLastChlid]?.idx) return <></>

          return (
            <a
              onClick={() => removeOption(row.idx)}
              className="w-100 border-0 bg-transparent h-100 d-flex align-items-center justify-content-center "
            >
              <i className="mdi mdi-delete font-size-18 danger text-danger" />
            </a>
          )
        },
      },
    ]
    setColumns(columnDataGrid)
  }, [validation.values.variantOptions, fulfillOptions])

  return (
    <>
      <Col>
        <Label className="col-form-label">{t(Labels.fulfill_option_label)}</Label>
        <DataGrid
          columns={columns}
          rows={fulfillOptions || [{}]}
          rowKeyGetter={rowKeyGetter}
          onRowsChange={onRowsChange}
          onFill={handleFill}
          onCopy={handleCopy}
          onPaste={(props) => handlePaste({...props, productType: validation.values || {}})}
          rowHeight={ROW_HEIGHT}
          defaultColumnOptions={{ resizable: true }}
          className={"fill-grid rdg-dark rdg-light"}
          style={{ height: "100%", blockSize: "100%" }}
          rowClass={(row: any) => "react-data-grid-normal-row"}
        />
      </Col>
    </>
  )
}


export const createDynamicGridKey = (startsWith: string, index: number, name?: string) => {
  // startsWith no dot
  return dynamicGridKey(startsWith, index, name)
}

export const dynamicGridKey = (startsWith: string, index: number, name?: string) => {
  return `${startsWith}.${index}.${name}`;
}