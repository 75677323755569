import { useEffect, useLayoutEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { viewExcelState } from "data/atoms/app.atom"
import { ModalContent } from "./portable-modal"
import { Button, Col, Label, Modal, ModalBody, Row, Toast } from "reactstrap"
import XLSX, { read, utils } from "xlsx"
import axios from "axios"
import { Link } from "react-router-dom"
import { Labels } from "common/labels"
import { ReportServices } from "data/services/reports.service"
import { AdminUtils } from "utils/admin-utils"
import { t } from "core/translations"
import { Toasts } from "core/notification/notifications"
import { Loading } from "components/common/Loading"

const defaultDataSheet = {
  name: "Profiles", //Worksheet name
  color: "", //Worksheet color
  index: 0, //Worksheet index
  status: 1, //Worksheet active status
  order: 0, //The order of the worksheet
  hide: 0, //Whether worksheet hide
  defaultRowHeight: 22, //Customized default row height
  defaultColWidth: 80, //Customized default column width
  celldata: [], //Initial the cell data
  config: {
    merge: {}, //merged cells
    rowlen: {}, //Table row height
    columnlen: {}, //Table column width
    rowhidden: {}, //hidden rows
    colhidden: {}, //hidden columns
    borderInfo: {}, //borders
    authority: {}, //Worksheet protection
  },
  scrollLeft: 0, //Left and right scroll bar position
  scrollTop: 0, //Up and down scroll bar position
  luckysheet_select_save: [], //selected area
  calcChain: [], //Formula chain
  isPivotTable: false, //Whether is pivot table
  pivotTable: {}, //Pivot table settings
  filter_select: {}, //Filter range
  filter: null, //Filter configuration
  luckysheet_alternateformat_save: [], //Alternate colors
  luckysheet_alternateformat_save_modelCustom: [], //Customize alternate colors
  luckysheet_conditionformat_save: {}, //condition format
  frozen: {}, //freeze row and column configuration
  chart: [], //Chart configuration
  zoomRatio: 1, // zoom ratio
  image: [], //image
  showGridLines: 1, //Whether to show grid lines
}

const luckyCss: any = {
  margin: "60px 0px 0px",
  padding: "0px",
  position: "absolute",
  width: "100%",
  height: "100%",
  left: "0px",
  top: "0px",
  backgroundColor: "#f8f8fb",
}

export const ViewExcel = () => {
  const [modalState, setModal] = useRecoilState(viewExcelState)
  const luckysheet = (window as any).luckysheet

  return modalState.open && (modalState.url || modalState.data) ? (
    <Col style={{ width: "100%", height: "100%", zIndex: 1070, top: 0, backgroundColor: "#f8f8fb" }} className="position-absolute">
      <Col className="mt-3 d-flex justify-content-between px-3">
        <div className="d-flex">
          <a
            href={"#"}
            onClick={e => {
              luckysheet.exitEditMode();
              e.preventDefault(); 
              setModal({ open: false, url: "" })
            }}
            className="me-4 btn btn-danger " 
            style={{padding: "6px 12px"}}
          >
            {t(Labels.close)}
          </a>
          <h3 className="flex-grow-1 font-size-18 title-page d-flex align-items-center fw-normal">{modalState.title || Labels.review_excel}</h3>
        </div>
        <Col className="flex-grow-0 d-flex">
          {modalState.url ? (
            <a href={modalState.url} download={modalState.url} className="btn btn-info " style={{padding: "6px 12px"}}>
              {t(Labels.download)}
            </a>
          ) : (
            <Button color="secondary" className=" " style={{padding: "6px 12px"}} onClick={() => modalState.data?.actionDownload()}>
              {t(Labels.download)}
            </Button>
          )}
        </Col>
      </Col>
      <div>
        <Luckysheet url={modalState.url || ""} workbook={modalState.data?.workbook} />
      </div>
    </Col>
  ) : (
    <></>
  )
}

const Luckysheet = (props: { url: string; workbook: XLSX.WorkBook }) => {
  const { url, workbook } = props
  const luckysheet = (window as any).luckysheet
  const [modalState, setModal] = useRecoilState(viewExcelState)
  const [dataLuckysheet, setDataLuckysheet] = useState<any>()
  const [loading, setLoading] = useState(true)

  useLayoutEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        url && setDataLuckysheet(await axiosXlsx(url))
      } catch (error: any) {
        Toasts.error(error.message)
      } finally {
        setLoading(false)
      }
    })()
  }, [url])

  useLayoutEffect(() => {
    workbook && setDataLuckysheet(getDataLuckysheet(workbook))
  }, [workbook])

  useLayoutEffect(() => {
    dataLuckysheet &&
      luckysheet?.create({
        container: "luckysheet",
        data: dataLuckysheet,
        showinfobar: false,
      })
      document.addEventListener('copy', handleCopyEvent);
      return () => {
        document.removeEventListener('copy', handleCopyEvent);
      };
  }, [dataLuckysheet])

  function handleCopyEvent(event: any) {
    const selection = luckysheet?.getluckysheet_select_save();
    if (selection.length > 0) {
      const { row, column } = selection[0];
      const cellValue = luckysheet.getCellValue(row[0], column[0]);
      event.preventDefault();
      event.clipboardData.setData('text/plain', cellValue);
    }
  }

  useEffect(() => {
    if (modalState.open) {
      window.addEventListener("popstate", function (event) {
        // window.location.assign('window.location.href');
        setModal({ open: false })
      })
    }
    return () => window.removeEventListener("popstate", () => {})
  }, [])

  async function axiosXlsx(url: string) {
    const options: any = {
      url,
      responseType: "arraybuffer",
    }
    let axiosResponse = await ReportServices.getDataFileExcel(options)
    const workbook: any = read(axiosResponse.data)

    return getDataLuckysheet(workbook)
  }

  return (
    <Col>
      <div id="luckysheet" style={luckyCss} />
      {!dataLuckysheet ? (
        <div className="z-4 position-relative">
          {loading ? (
            <Loading />
          ) : (
            <div className={`d-flex justify-content-center`}>
              <p className="text-danger">{t(Labels.no_data_available)}</p>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </Col>
  )
}

export const getDataLuckysheet = (workbook: XLSX.WorkBook) => {
  const data: any[] = []

  if (workbook) {
    for (const [sheetName, sheet] of Object.entries(workbook.Sheets)) {
      const celldata: any[] = []
      const sheetRows = utils.sheet_to_json(sheet, { dateNF: "MM/dd/yyyy" })
      const sheetHeader = utils.sheet_to_json(sheet, { header: 1 })[0] as string[]

      sheetHeader.forEach((item, idx) => celldata.push({ r: 0, c: idx, v: { m: item, v: item } }))
      sheetRows.forEach((row: any, idx) => {
        celldata.push(
          ...Object.keys(row).map((cell: string, idxCell) => ({
            r: idx + 1,
            c: sheetHeader.indexOf(cell),
            v: { m: row[cell], v: row[cell], ct: { fa: "General", t: "g" } },
          }))
        )
      })
      // defaultDataSheet
      data.push({ ...defaultDataSheet, index: workbook.SheetNames.indexOf(sheetName) + 1, name: sheetName, celldata })
    }
  }

  return data
}
