import { LoadableComponent } from "components/common/LoadableComponent";
import { FormikForm } from "components/form/FormikForm";
import ActionButton from "components/input/ActionButton";
import Breadcrumb from "components/layout/Breadcrumb";
import { FormLayout, FormLayoutMain } from "components/layout/FormLayout";
import { SmallContainer } from "components/layout/SmallContainer";
import { t } from "core/translations";
import { FormikHelpers, useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { RouteNames } from "routes";
import * as Yup from "yup";
import { artworkTemplateSelector } from "data/atoms/artwork-template.atom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FormikInput } from "components/form/FormikInput";
import { CustomizeForm } from "./artwork.page";
import { ArtworkTemplateDto } from "data/services/artwork-template.service";
import { Forms } from "utils/forms";
import { ArtworkTemplateActions } from "data/actions/artwork-template.action";
import { Toasts } from "core/notification/notifications";
import { Messages } from "../../common/messages";
import { Labels } from "../../common/labels";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { isEmpty } from "lodash";
import { preprocessPage } from "utils/utils";

const ArtworkTemplatePage = () => {
  const history = useHistory()
  const params = useParams<Record<string, any>>()
  const isEditing = +params.id > 0
  const [artworkTemplate, setArtworkTemplate] = useState<any>({})
  const dataLoadable = useRecoilValueLoadable(artworkTemplateSelector(params.id))

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.artwork_template), model: artworkTemplate})
  }, [artworkTemplate])

  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(artworkTemplateSelector(params.id))
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[params.id])


  const artworkTemplateSchema = Yup.object({
    name: Yup.string().required(t(Messages.field_required, { field: t(Labels.template_name) })),
    description: Yup.string().nullable(),
    customOptions: Yup.array().nullable()
  })

  useEffect(() => {
    if (dataLoadable.state == "hasValue") {
      setArtworkTemplate((prevState: any) => ({
        ...(prevState || {}),
        ...(dataLoadable.contents)
      }));
    }
  }, [dataLoadable.state]);


  const validation = useFormik({
    initialValues: artworkTemplate,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: artworkTemplateSchema,
    onSubmit: (values, helpers) => handleSubmit(values, helpers),
  })

  const handleSubmit = async (values: ArtworkTemplateDto, helpers: FormikHelpers<any>) => {
    let changedData = isEditing ? Forms.getChangedValues(values, artworkTemplate) : values;

    try {
      helpers.setErrors({});
      helpers.setSubmitting(true);

      let savedData = await ArtworkTemplateActions.save(params.id, changedData);
      if (savedData?.id) {
        setArtworkTemplate((current: any) => ({
          ...current,
          ...savedData
        }));
        Toasts.success(t(Messages.save_artwork_successfully));

        // Optimize not using setTimeout
        !isEditing && setTimeout(() => {
          history.replace(RouteNames.ARTWORK_TEMPLATES_DETAIL.replace(':id', savedData.id))
        }, 100);
      }
    } catch (e) {
      // Show toast error
      Toasts.error(t(Messages.your_request_execute_unsuccessfully));
    } finally {
      helpers.setSubmitting(false);
    }
  }

  return (
    <Fragment>
      <div className="page-content">
        <SmallContainer>
          <Breadcrumb goBack={RouteNames.ARTWORK_TEMPLATES} title={`${isEditing ? t(Labels.edit) : t(Labels.create)} ${t(Labels.artwork_templates)}`.trim()}>
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.artworkTemplateEntity}>
              <ActionButton color="success" onClick={() => validation.submitForm()}>
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable}>
            <FormikForm validation={validation}>
              <FormLayout>
                <FormLayoutMain>
                  <ArtworkTemplatePageForm validation={validation} />
                </FormLayoutMain>
              </FormLayout>
            </FormikForm>
          </LoadableComponent>
        </SmallContainer>
      </div>
    </Fragment>
  )
}

const ArtworkTemplatePageForm = (props: any) => {
  const { validation } = props
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col >
              <FormikInput 
                name="name" 
                type="text" 
                validation={validation} 
                placeholder={t(Labels.artwork_template_hint)} 
                label={t(Labels.name)} 
                required={false}
              />
            </Col>
          </Row>
          <Row>
            {/* <Label className="col-sm-3 col-form-label">{t("Description")}</Label> */}
            <Col >
              <FormikInput name="description" label={t(Labels.description)}  type="text" validation={validation} placeholder={t(Labels.artwork_description_template_hint)} />
            </Col>
          </Row>

          <CustomizeForm validation={validation} />
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default ArtworkTemplatePage
