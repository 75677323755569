import { Labels } from "common/labels";
import { Messages } from "common/messages";
import { FormikForm } from "components/form/FormikForm";
import { FormikInput } from "components/form/FormikInput";
import { portableModalState } from "data/atoms/app.atom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Container, ModalFooter, Row } from "reactstrap";
import { useSetRecoilState } from "recoil";
import * as Yup from 'yup';
import { t } from "../../../core/translations";
import { ExportType } from "../../../types/export.type";
import { BaseModalExport, exportRadioDataTypeValues } from "./export-modal";

interface ExportDesignPopupProps{
  filter?: number[] | string[] | Object
  type: ExportType,
  handleChange?: any
}

export const ExportDesignPopup = (props: ExportDesignPopupProps) => {
  const setModal = useSetRecoilState(portableModalState);
  const validationExportDesign = useFormik({
    initialValues: {
      // xuanduc edit
      outputFolder: 'D:\\AUTO OUTPUT',
      configPathTemplate: 'D:\\AUTO\\Templates\\AutoMaster\\{designSku}\\{designSku}-config.json',
      numberOfOption: 5
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: Yup.object({
      outputFolder: Yup.string().required(t(Messages.field_required, { field: t(Labels.desgin_output_folder) })),
      configPathTemplate: Yup.string().required(t(Messages.field_required, { field: t(Labels.config_path_template) })),
      numberOfOption: Yup.number().required(t(Messages.field_required, { field: t(Labels.number_of_option) }))
    }),
    // onSubmit: (values, helpers) => {handleExport(props.type, props.filter, values)}
    onSubmit: (values, helpers) => {}
  })

  useEffect(() => {
    props.handleChange(validationExportDesign?.values)
  }, [validationExportDesign?.values])
  
  return(
    <Container className="p-0">
      <FormikForm validation={validationExportDesign}>
        <Row className="mb-2">
          <FormikInput
            label={t(Labels.output_folder)}
            name="outputFolder"
            type="text"
            validation={validationExportDesign}
            placeholder={t(Labels.output_folder_hit)}
          />
        </Row>
        <Row>
          <FormikInput
            label={t(Labels.config_path_template)}
            name="configPathTemplate"
            type="text"
            validation={validationExportDesign}
            placeholder={t(Labels.config_path_template_hint)}
          />
        </Row>
        <Row>
          <FormikInput
            label={t(Labels.number_of_option)}
            name="numberOfOption"
            type="number"
            validation={validationExportDesign}
            placeholder={t(Labels.number_of_option_hint)}
          />
        </Row>
      </FormikForm>
    </Container>
  )
}

interface propsModalExportFulfillOrder {
  type: string
  onClose: Function
  handleExport: Function
  selectedIds: Array<any>
  totalItems?: number
}

export const ModalExportFulfillOrder = (props: propsModalExportFulfillOrder) => {
  const { type } = props
  const [exportDesignState, setExportDesignState] = useState({})

  const [titleModal, setTitleModal] = useState<string>()

  useEffect(() => {
    switch(type) {
      case ExportType.FOR_AUTO_DESIGN:
        setTitleModal(t(Labels.export_for_auto_design))
        return
      case ExportType.FOR_FULFILL_SERVICE:
        setTitleModal(t(Labels.export_for_fulfill_service))
        return
      case ExportType.PERSONAL_CUSTOM:
        setTitleModal(t(Labels.export_personal_custom_design))
        return
      case ExportType.MANUAL_CUSTOM:
        setTitleModal(t(Labels.export_manual))
        return
      default:
        setTitleModal('')
        return
    }
  }, [type])
  
  return (
    <BaseModalExport
      {...props}
      message={t(Labels.processing_time_depends_on_the_number, { resourceName: Labels.fulfill_order_resource })}
      titleModal={titleModal}
      childrenState={exportDesignState}
      exportDataType={exportRadioDataTypeValues.excel_file}
    >
      {type === ExportType.PERSONAL_CUSTOM ? (
        <>
          <p className="mt-1 mb-2 fw-bold">{t(Labels.custom_export_data)}</p>
          <ExportDesignPopup handleChange={setExportDesignState} type={type as ExportType} />
        </>
      ) : null}
    </BaseModalExport>
  )
}