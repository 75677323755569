import { Storages } from "../utils/storages";
import { Logger } from "../core/logger";
import { IdentityApiPaths } from "./services/identity.service";

const runtimeEnv = (window as any)._env_ || {}
export const apiBaseUrls: any = {
  identity: runtimeEnv.BASE_URL_IDENTITY_API || process.env.REACT_APP_BASE_URL_IDENTITY_API,
  admin: runtimeEnv.BASE_URL_ADMIN_API || process.env.REACT_APP_BASE_URL_ADMIN_API,
}

console.log('runtimeEnv', runtimeEnv)
console.log('apiBaseUrls', apiBaseUrls)

export const changeApiBaseUrls = () => {
  const customEnv = { ...process.env, ...runtimeEnv }
  let env = customEnv.REACT_APP_ENV || customEnv.NODE_ENV
  Logger.info('REACT_APP_ENV', env)

  if (env != 'production') {
    let newApiBaseUrls = Storages.getLocalJson('apiBaseUrls')
    if(newApiBaseUrls) {
      for (let key in apiBaseUrls) {
        if (newApiBaseUrls[key]) {
          (apiBaseUrls as any)[key] = newApiBaseUrls[key]
        }
      }
    }
  }

  Logger.info('Api base urls', JSON.stringify(apiBaseUrls))
}

changeApiBaseUrls()

export const isNoneTenantApiPath = (path: string) => {
  const noneTenantApiPaths = [
    IdentityApiPaths.LOGIN,
    IdentityApiPaths.REFRESH_TOKEN,
    IdentityApiPaths.GET_TENANTS
  ]
  return noneTenantApiPaths.includes(path)
}