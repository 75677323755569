import { CrudActions } from "./crud.action";
import { Logger } from "../../core/logger";
import { AdminUtils } from "../../utils/admin-utils";
import { ArtworkDto, ArtworkService, ArtworkServices, ArtworkTypeApiPaths, UpdateArtworkDto } from "data/services/artwork.service";
import { Filter } from "core/rest-api/query-builder";
import { t } from "../../core/translations";
import { Messages } from "../../common/messages";
import { Toasts } from "../../core/notification/notifications";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { withPermission } from "common/hooks/use-permission";
import { format } from "date-fns";

class ArtworkAction extends CrudActions<ArtworkService, ArtworkDto,UpdateArtworkDto >{
  constructor() {
    super(new ArtworkService());
  }

  async searchByArtwork(searchText: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.artworkEntity,
      async () => {
        let filter: Filter<ArtworkDto> = {};
        if (searchText) {
          filter.sku = { contains: searchText };
        }
        let pageData = await ArtworkServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async importArtworks(file: any, replaceable?: boolean) {
    try {
      const formData = new FormData();
      formData.append('file', file)
      const data: any = await ArtworkServices.importArtworks(formData, replaceable)
      return data
    } catch (e) {
      Logger.warn('importArtworks failed: ', e)
    }
    return false
  }
  async searchKeys (searchValue: string) {
    let params: any = {}
    if (searchValue) {
      params.key = 'customily-template'
    }
    let pageData = await ArtworkServices.searchKeys(params);
    return pageData || [];
  }

  async createMany(payload: ArtworkDto[], hooks: { setError?: Function } = {}) {
    let { setError } = hooks;

    try {
      const response: any = await ArtworkServices.createMany(payload, { snoozeErrorMessage: false });
      Logger.debug("create result: ", response);
      if (response) {
        Toasts.success(t(Messages.create_artwork_successfully))
        return response;
      }
    } catch (error: any) {
      setError && setError(error.message || t(Messages.create_resource_unsuccessfully));
      Logger.trace(error);
    }
  }

  async updateSaleChannel(ids: string[], publicationChannelIds: string[], refresh?: Function) {
    try {
      let result = await this.service.bulkAction(ArtworkTypeApiPaths.ACTION, ids, "add_sale_channels", { publicationChannelIds })
      if(result?.success){
        Toasts.success(t(Messages.your_request_execute_successfully))
        refresh && refresh()
        return result
      }
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  }

  async removeSaleChannel(ids: string[], publicationChannelIds: string[], refresh?: Function) {
    try {
      let result = await this.service.bulkAction(ArtworkTypeApiPaths.ACTION, ids, "remove_sale_channels", { publicationChannelIds })
      if(result?.success){
        Toasts.success(t(Messages.your_request_execute_successfully))
        refresh && refresh()
        return result
      }
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  }

    async bulkUpdateManyArtwork(payload: ArtworkDto[], hooks: { setError?: Function } = {}) {
    let { setError } = hooks;

    try {
      const response: any = await ArtworkServices.createMany(payload, { snoozeErrorMessage: false });
      Logger.debug("create result: ", response);
      if (response) {
        Toasts.success(t(Messages.update_artwork_successfully))
        return response;
      }
    } catch (error: any) {
      setError && setError(error.message || t(Messages.create_resource_unsuccessfully));
      Logger.trace(error);
    }
  }

  async getBulkEditArtwork(ids: string[]) {
    try {
      let response: any = await ArtworkServices.getBulkEditArtwork(ids)
      return response?.data
    } catch (error) {
      Logger.log('error ', error);
    }
  }
}

export const ArtworkActions = new ArtworkAction()
