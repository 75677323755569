import React, { useEffect, useState } from "react";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import { Link } from "react-router-dom";
import user1 from "../../assets/images/users/avatar-1.jpg";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { portableModalState } from "data/atoms/app.atom";
import ChangePasswordComponent from "pages/accounts/change-password.component";
import { BulkUpdateSelectPopup } from "components/modal/bulk-update-popup";
import { getListTeamAtom, getListTeamSelector, listTenantAtom, listTenantSelector } from "data/atoms/account.atom";
import { Storages } from "utils/storages";
import { userSelector, userState, userTeamSelector, userTenantSelector } from "data/atoms/auth.atom";
import { Labels } from "common/labels";
import { t } from "../../core/translations";
import { Toasts } from "../../core/notification/notifications";
import { Messages } from "../../common/messages";
import { Keys } from "../../common/keys";
import { RouteNames } from "routes";
import { omit } from "lodash";
import { ModalButtons, ModalContent } from "components/modal/portable-modal";
import Select from "react-select";
import { createLinkEmbedParam } from "utils/embed";

interface ProfileMenuProps {
  success?: any
}

const UpdateTenantPopup = () => {
  const loadTenant = useRecoilValueLoadable(listTenantSelector)
  const [listTenant, setListTenant] = useState([])
  const [, setTenant] = useRecoilState(userTenantSelector)

  useEffect(() => {
    if(loadTenant.state === "hasValue"){
      setListTenant(loadTenant.contents?.map((item: any) => ({...item, value: item, label: item.name})))
    }
  },[loadTenant.contents])

  return <BulkUpdateSelectPopup
    options={listTenant}
    placeholder={t(Labels.switch_business_hint)}
    onSubmit={async (value: any) => {
      setTenant(value)
      Toasts.success(t(Messages.change_team_successfully))
      setTimeout(() =>{
        window.location.replace(RouteNames.DASHBOARD)
      }, 100)
    }}
  />
}

const UpdateTeamPopup = () => {
  const loadTeam = useRecoilValueLoadable(getListTeamSelector)
  const [listTeam, setListTeam] = useState([])
  const [, setTeam] = useRecoilState(userTeamSelector)
  const [value, setValue] = useState<any>([]);

  useEffect(() => {
    if(loadTeam.state === "hasValue"){
      setListTeam(loadTeam.contents?.map((item: any) => ({...item, value: item, label: item.name})))
    }
  },[loadTeam.contents])

  const onSubmit = async (value: any) => {
    setTeam(omit(value, 'label'))
    Toasts.success(t(Messages.update_team_successfully ))
    setTimeout(() =>{
      window.location.replace(RouteNames.DASHBOARD)
    }, 100)
  }

  return (
    <>
      <ModalContent>
        <Select
          placeholder={t(Labels.switch_team_hint)}
          name="option_value"
          getOptionLabel={(option: any) => t(option?.label)}
          getOptionValue={(option: any) => option?.value}
          value={value}
          onChange={(option: any) => setValue(option)}
          menuPortalTarget={document.querySelector("select")}
          options={listTeam}
          isLoading={loadTeam.state == 'loading'}
        />
      </ModalContent>
      <ModalButtons
        confirm={ async () => {
          if(value != null && value != undefined) {
            await onSubmit(value.value == null || value.value == undefined ? value : value.value)
          }
        }}
      />
    </>
  )

  // return <BulkUpdateSelectPopup
  //   options={listTeam}
  //   placeholder={t(Labels.switch_team_hint)}
  //   onSubmit={async (value: any) => {
  //     setTeam(omit(value, 'label'))
  //     Toasts.success(t(Messages.update_team_successfully ))
  //     setTimeout(() =>{
  //       window.location.replace(RouteNames.DASHBOARD)
  //     }, 100)
  //   }}
  // />
}

const ProfileMenu = (props: ProfileMenuProps) => {
  const [menu, setMenu] = useState(false)
  const [t] = useTranslation()
  const [username, setUsername] = useState("")
  const [tenant] = useRecoilState(userTenantSelector)
  const [user] = useRecoilState(userSelector)
  const isMobile = window.screen.width <= 576
  const [, setTeamState] = useRecoilState(getListTeamAtom)
  const [, setTenant] = useRecoilState(listTenantAtom)
  const [team] = useRecoilState(userTeamSelector)

  const setModalChangePassword = () => {
    setPortableModal({
      open: true,
      title:t(Labels.change_password),
      content: (
        <ChangePasswordComponent onDone={() => setPortableModal({ open: false })}/>
      )
    })
  }

  const setModalTeams = () => {
    setTenant((prev) => ([...prev]))
    setPortableModal({
      open: true,
      title: t(Labels.change_business),
      content: (
        <UpdateTenantPopup/>
      ),
    })

  }
  const setModalChangeTeam = () => {
    setTeamState((prev) => ([...prev]))
    setPortableModal({
      open: true,
      title: t(Labels.switch_team),
      content: (
        <UpdateTeamPopup/>
      ),
    })
  }
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser") || '')
        setUsername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser") || '')
        setUsername(obj.username)
      }
    }
  }, [props.success])


  const jsonUser = Storages.getLocalJson("user")

  useEffect(() => {
    if(jsonUser){
      setUsername(jsonUser.name || jsonUser.username)
    }
  }, [jsonUser])

  const setPortableModal = useSetRecoilState(portableModalState)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
        direction={isMobile ? "up" : "end"}
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <Col className="d-flex flex-column justify-content-start">
            <h6 className=" d-xl-inline-block ms-2 me-1 text-start mb-0">{username}</h6>
            <span className=" d-xl-inline-block ms-2 me-1 text-start font-size-12">{tenant?.name || t(Labels.unknown)}{user?.team?.name ? ` - ${team?.name || ""}` : ""}</span>
          </Col>
          <i className={`mdi mdi-chevron-${isMobile ? 'up' : 'right'}  d-xl-inline-block`}/>
        </DropdownToggle>
        <DropdownMenu className={`${isMobile ? "" : 'dropdownMenuUser'}`}>
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {t("Profile")}{" "}
          </DropdownItem> */}
          <DropdownItem tag="button" onClick={() => setModalTeams()}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {t(Labels.change_business)}{" "}
          </DropdownItem>
          <DropdownItem tag="button" onClick={() => setModalChangeTeam()}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {t(Labels.switch_team)}{" "}
          </DropdownItem>
          <DropdownItem tag="button" onClick={setModalChangePassword}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {t(Labels.change_password)}{" "}
          </DropdownItem>

          <div className="dropdown-divider"/>
          <Link {...createLinkEmbedParam("/logout")} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>{t(Labels.logout)}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}


export default ProfileMenu
