import { Messages } from "../../common/messages";
import { t } from "../../core/translations";
import { Logger } from "../../core/logger";
import { IdentityServices } from "../services/identity.service";
import { Keys } from "../../common/keys";

export interface UserLoginHook { setUser: any, setToken: any, setLoginError: any, setTenant: any, setRoles: any, setTeam: any }

export class AuthAction {

  handleLoginResponse(response: any, hooks: UserLoginHook, refresh?: boolean) {
    const { setUser, setToken, setLoginError, setTenant, setRoles, setTeam } = hooks

    if (response?.status === 200 && response?.data?.user && response?.data?.token) {
      const data = response.data
      setLoginError("");
      setUser(data?.user);
      setRoles(data?.user);

      if(!refresh) {
        setTeam(data?.user?.team)
        setTenant(data?.user?.tenant);
      }

      setToken(data?.token);
      return true
    }

    return false
  }

  async login(payload: any, hooks: UserLoginHook) {
    let { setUser, setToken, setLoginError, setTenant, setRoles } = hooks
    try {
      const response: any = await IdentityServices.login(payload);
      let success = this.handleLoginResponse(response, hooks);
      if(success) {
        if (payload.remember) {
          localStorage.setItem(Keys.rememberLogin, `1`);
        }
      } else {
        setLoginError(response?.error?.message || t(Messages.login_unsuccessfully))
      }
      return success
    } catch (error) {
      setLoginError(t(Messages.login_unsuccessfully))
      Logger.trace(error)
    }
  }

  async refresh(hooks: UserLoginHook) {
    let response = await IdentityServices.refresh()
    return this.handleLoginResponse(response, hooks, true);
  }
}

export const IdentityActions = new AuthAction()