import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./core/translations"
import { RecoilRoot } from "recoil"
import 'bootstrap-daterangepicker/daterangepicker.css';

const app = (
  <RecoilRoot>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RecoilRoot>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
