import { Loadable } from "recoil"
import { useEffect } from "react"
import { isEmpty } from "lodash";

export const useStaticData = (loadable: Loadable<any>, setState?: any  ) => {
  if (!setState) return loadable;
  useEffect(() => {
    if (loadable.state == "hasValue" && !isEmpty(loadable.contents)) {
      setState(loadable.contents)
      return
    }
  }, [loadable.state])

  return loadable
}
