import { LoadableComponent } from "components/common/LoadableComponent";
import { FormikForm, FormValidation } from "components/form/FormikForm";
import { FormikInput } from "components/form/FormikInput";
import ActionButton from "components/input/ActionButton";
import Breadcrumb from "components/layout/Breadcrumb";
import { FormLayout, FormLayoutMain, FormLayoutRight } from "components/layout/FormLayout";
import { t } from "core/translations";
import { productSelector } from "data/atoms/product.atom";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardLink,
  CardTitle,
  Col,
  Container, Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { RouteNames } from "routes";
import { productSchema } from "./validations/product.validation";
import Select from "react-select";
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ArtworkDto } from "data/services/artwork.service";
import { ArtworkSelect } from "./components/artwork.select";
import { ProductTypeSelect } from "./components/product-type.select";
import { saleChannelSelector } from "data/atoms/sale-channel.atom";
import Creatable from "react-select/creatable";
import { Labels } from "common/labels";
import Gallery from "react-grid-gallery";
import { ModalButtons, ModalContent } from "components/modal/portable-modal";
import { portableModalState } from "data/atoms/app.atom";
import { ProductDto } from "data/services/product.service";
import { Forms } from "utils/forms";
import { ProductActions } from "data/actions/product.action";
import { Link, useHistory, useParams } from "react-router-dom";
import { Toasts } from "core/notification/notifications";
import { SaleChannelDto } from "data/services/sale-channel-service";
import { SaleChannelSelect } from "./components/sale-channel.select";
import { VendorSelect } from "./components/vendor.select";
import { OptionalLabel } from "components/common/OptionalLabel";
import { Messages } from "common/messages";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import {
  ProductStatus,
  ProductStatusName,
  ProductStatusOptions,
  PublishStatus,
  PublishStatusName,
  PublishStatusOptions
} from "./types/product-status.type";
import JsonTable from "components/table/JsonTable";
import { BiLinkExternal } from "react-icons/bi";
import { isEmpty } from "lodash";
import { ListImages } from "./components/list-images";
import { MetaTable } from "../../components/table/MetaTable";
import { withPermission } from "common/hooks/use-permission"
import Page404 from "components/page/404";
import { BulkUpdateWithReplaceConfirmationPopup } from "../../components/modal/bulk-update-popup";
import { preprocessPage } from "utils/utils";
import { SearchableSelect } from "components/input/SearchableSelect";
import { ArtworkActions } from "data/actions/artwork.action";
import { createUrlEmbed } from "utils/embed";


export const ProductPage = () => {
  const history = useHistory()
  const params = useParams<Record<string, any>>()
  const setPortableModal = useSetRecoilState(portableModalState)


  const isEditing = +params.id > 0
  const [product, setProduct] = useState<any>({})
  const dataLoadable = useRecoilValueLoadable(productSelector(params.id))

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.product), model: product})
  }, [product])

  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(productSelector(params.id))
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[params.id])
  useEffect(() => {
    if (dataLoadable.state == "hasValue") {
      setProduct((prevState: any) => ({
        ...(prevState || {}),
        ...(dataLoadable.contents)
      }));
    }
  }, [dataLoadable.state]);

  const validation = useFormik({
    initialValues: product || {},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: productSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers)
  })
  
  const handleSubmit = async (values: ProductDto, helpers: FormikHelpers<any>) => {
    let changeData = isEditing ? Forms.getChangedValues(values, product) : values

    try {
      helpers.setErrors({})
      helpers.setSubmitting(true)

      let savedData = await ProductActions.save(params.id, changeData)
      if (savedData?.id) {
        setProduct((current: any) => ({
          ...current,
          ...savedData
        }))
        Toasts.success(t(Messages.save_product_successfully))
        validation.setValues(savedData)
        !isEditing && setTimeout(() => {
          history.replace(RouteNames.PRODUCT_DETAIL.replace(':id', savedData.id))
        }, 100);
      }
    } catch (e) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    } finally {
      helpers.setSubmitting(false)
    }
  }

  const handlePublish = async () => {
    setPortableModal({
      open: true,
      title: t(Labels.publish_products),
      content: (
        <BulkUpdateWithReplaceConfirmationPopup
          replaceLabel={t(Labels.publish_and_replace_images)}
          noneReplaceLabel={t(Labels.publish)}
          confirmMessage={Messages.publish_products_confirm_message}
          onSubmit={async (replaceImages: boolean) => {
            let data = await ProductActions.publishById(params.id, replaceImages)
            data && setProduct(data)
          }}
        />
      ),
    })
  }

  return (
    <>
      <div className="page-content">
        <Container>
          <Breadcrumb
            goBack={RouteNames.PRODUCT}
            title={`${isEditing ? t(Labels.edit) : t(Labels.create)} ${t(Labels.product)}`.trim()}
          >
            {isEditing ? 
              <WithPermission action={ActionEntities.update} resource={ResourceEntities.productEntity}>
                <ActionButton color="primary" className="me-2" onClick={handlePublish} >
                  {t(Labels.publish)}
                </ActionButton>
              </WithPermission> :
              <></>
            }
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.productEntity}>
              <ActionButton color="success" onClick={() => validation.submitForm()} >
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable}>
            {/*<Form*/}
            {/*  onSubmit={(e) => {*/}
            {/*    e.preventDefault();*/}
            {/*    validation.handleSubmit();*/}
            {/*    return false;*/}
            {/*  }}>*/}
            {/*  <ProductForm validation={validation} dataLoadable={dataLoadable} isEditing={isEditing} />*/}
            {/*</Form>*/}

            <FormikForm validation={validation} >
              <ProductForm validation={validation} dataLoadable={dataLoadable} isEditing={isEditing} />
            </FormikForm>
          </LoadableComponent>
        </Container>
      </div>
    </>
  )
}

export interface productProps {
  validation: FormValidation,
  dataLoadable?: any,
  isEditing: boolean
}

export interface galleryType {
  src: string,
  thumbnail: string
  thumbnailWidth: number | string
  thumbnailHeight: number | string
  isSelected: boolean
}

export const ProductForm = (props: productProps) => {
  const { validation, dataLoadable, isEditing } = props
  const saleChannelLoadable = withPermission(ActionEntities.read, ResourceEntities.saleChannelEntity)
    && useRecoilValueLoadable(saleChannelSelector)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  // const [saleChannelValue, setSaleChannelValue] = useState([])
  const [titleState, setTitleState] = useState('')
  const [imagesState, setImagesState] = useState<galleryType[]>([])
  const [deleteImageStatus, setDeleteImageStatus] = useState(false)
  const setPortableModal = useSetRecoilState(portableModalState)

  const onEditorStateChange = (editor: EditorState) => {
    setEditorState(editor)
    validation.setFieldValue('description', editorState?.getCurrentContent()?.getPlainText())
  }

  const insertCharacter = (characterToInsert: string, editorState: EditorState) => {
    const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();
    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      characterToInsert
    );
  
    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    return  newEditorState;
  }

  useEffect(() => {
    if (dataLoadable?.state === 'hasValue' && !validation.values?.title) {
      setTitleState(dataLoadable.contents?.title)
    }

    if (dataLoadable?.state === 'hasValue' && !editorState?.getCurrentContent()?.getPlainText()) {
      let newEditorState = insertCharacter(dataLoadable.contents?.description, editorState)
      setEditorState(newEditorState)
    }
  }, [])

  useEffect(() => {
    const check = imagesState?.filter((item: galleryType) => item.isSelected === true)
    if (check?.length) {
      setDeleteImageStatus(true)
    } else {
      setDeleteImageStatus(false)
    }
  }, [imagesState])

  useEffect(() => {
    const listImageFromValidation = validation.values?.images?.map((item: string) => ({
      src: item,
      thumbnail: item,
      thumbnailHeight: 'auto',
      thumbnailWidth: 'auto',
      isSelected: false
    }))
    setImagesState(listImageFromValidation)
  }, [validation.values?.images])

  const handleSelectImage = (index: number, image: any) => {
    let newImages: galleryType[] = [...imagesState]
    newImages[index].isSelected = !imagesState[index].isSelected
    setImagesState(newImages)
  } 

  const handleDeleteImage = () => {
    const newImages = imagesState?.filter((item) => item.isSelected === false)
    validation.setFieldValue('images', newImages.map((item: galleryType) => item.src))
    setImagesState(newImages)
  }

  const handleAddMedia = () => {
    setPortableModal({
      open: true,
      title: t(Labels.add_image),
      content: (
        <>
          <AddMediaPopup validation={validation} />
        </>
      )
    })
  }

  const setStyleAddMedia = (node: any) => {
    node?.style.setProperty("min-height", "150px", "important")
  }

  return (
    <FormLayout>
      <FormLayoutMain>
        <Card>
          {
            isEditing ?
              <CardHeader>
                <CardTitle>{titleState}</CardTitle>
              </CardHeader> :
            null
          }
          <CardBody>
            <Row className="mb-3">
              <FormikInput
                name="sku"
                type="text"
                label={t(Labels.sku)}
                validation={validation}
                placeholder={t(Labels.product_sku_hint)}
              />
            </Row>
            <Row className="mb-3">
              <FormikInput
                name="title"
                type="text"
                label={t(Labels.title)}
                validation={validation}
                placeholder={t(Labels.product_title_hint)}
              />
            </Row>
            <Row className="mb-3">
              <OptionalLabel className="mb-2" label={t(Labels.description)}/>
              <div>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Col>
                <FormikInput
                  name="regularPrice"
                  type="number"
                  label={t(Labels.regular_price)}
                  validation={validation}
                  placeholder={t(Labels.regular_price_hint)}
                />
              </Col>
              <Col>
                <FormikInput
                  name="salePrice"
                  type="number"
                  label={t(Labels.sale_price)}
                  required={true}
                  validation={validation}
                  placeholder={t(Labels.sale_price_hint)}
                />
              </Col>
            </Row>

          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <Row>
              <Col className="col-5 d-flex align-items-center">
                <CardTitle>{t(Labels.images)}</CardTitle>
              </Col>
              <Col className="d-flex flex-row-reverse">
                <Button className={`m-1 ${!deleteImageStatus && 'd-none'}`} color="danger" onClick={handleDeleteImage}>{t(Labels.delete_image)}</Button>
                <Button className="m-1" color="primary" onClick={handleAddMedia}>{t(Labels.add_media_from_url)}</Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="mb-4">
              {
                imagesState?.length
                ? <Gallery images={imagesState} onSelectImage={handleSelectImage} backdropClosesModal={true}/> // fix gallery grid
                : <div>
                    <div className={`dropzone mt-1`} ref={setStyleAddMedia}>
                      <div className='dz-message needsclick mt-2'>
                        <CardLink
                          className="pt-2"
                          style={{borderBottom: '1px solid', fontSize: '15px'}}
                          onClick={handleAddMedia}
                        >
                          {t(Labels.add_media_from_url)}
                        </CardLink>
                      </div>
                    </div>
                  </div>
              }
            </Row>
            <div className='dropdown-divider'></div>
            <Row className="mt-2">
              <div className="d-flex gap-1">
                <Label>{t(Labels.default_image_by_product_type)}</Label>
                {
                  validation.values.productType?.id &&
                  <a href={RouteNames.PRODUCT_TYPES_DETAIL.replace(':id', validation.values.productType?.id)}>({t(Labels.edit_product_type)})</a>
                }
              </div>
              <Col>
                <ListImages validation={validation} fieldValue={'imageSuffixes'} dataLoadable={dataLoadable}/>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>{t(Labels.metafields)}</CardTitle>
          </CardHeader>
          <CardBody>
          <Row>
              <FormikInput
                name="metafields"
                validation={validation}
                customInput={({handleChange, handleBlur, errorData }) => {
                  return (
                    <MetaTable
                      json={validation.values.metafields || []}
                      onChange={value => validation.setFieldValue("metafields", value) }
                    />

                    // <JsonTable
                    //   json={validation.values.metafields || []}
                    //   keyField={"key"}
                    //   valueField={"value"}
                    //   renderInCard={false}
                    //   onChange={value => {
                    //     validation.setFieldValue("metafields", value)
                    //   }}
                    // />
                  )
                }}
                />
            </Row>
          </CardBody>
        </Card>
      </FormLayoutMain>
      <FormLayoutRight>
        <Card>
          <CardHeader>
            <CardTitle>{t(Labels.product_status)}</CardTitle>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <FormikInput
                name="status"
                validation={validation}
                label={t(Labels.product_status)}
                customInput={({handleChange, handleBlur, errorData}) => (
                  <Select
                    isSearchable={true}
                    defaultValue={{ label: ProductStatusName[ProductStatus.ACTIVE], value: ProductStatus.ACTIVE}}
                    options={ProductStatusOptions}
                    value={ProductStatusOptions?.filter((option: any) => option.value == validation.values?.status)}
                    getOptionLabel={(option: any) => t(option.label || option.value)}
                    getOptionValue={(option: any) => option.value}
                    onBlur={handleBlur}
                    onChange={(value: any) => handleChange(value.value)}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Row>
            <Row className="mb-3">
              <FormikInput
                name="publishStatus"
                validation={validation}
                label={t(Labels.publish_status)}
                customInput={({handleChange, handleBlur, errorData}) => (
                  <Select
                    isSearchable={true}
                    options={PublishStatusOptions}
                    defaultValue={{ label: PublishStatusName[PublishStatus.PENDING], value: PublishStatus.PENDING}}
                    value={PublishStatusOptions?.filter((option) => option.value == validation.values?.publishStatus)}
                    getOptionLabel={(option: any) => t(option.label || option.value)}
                    getOptionValue={(option: any) => option.value}
                    onBlur={handleBlur}
                    onChange={(value: any) => handleChange(value.value)}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Row>
            <Row className="mb-3">
              <Label className="mb-2">{t(Labels.stores)}</Label>
              <SaleChannelSelect
                isMulti={true}
                value={validation.values.publicationChannels}
                getOptionLabel={(option: SaleChannelDto) => option.domain}
                getOptionValue={(option: SaleChannelDto) => option.id}
                onChange={(value: any) => {
                  validation.setFieldValue('publicationChannels', value)
                  validation.setErrors({})
                }}
              />
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>{t(Labels.publication)}</CardTitle>
          </CardHeader>
          <CardBody>
            {
              validation.values.publishedChannels?.length > 0 ?
                <div className="mb-3">
                  <Label className="mb-2">{t(Labels.published_channel)}</Label>
                  <div className="ms-2 mb-2">

                      {
                        validation.values.publishedChannels.map((item: {channelId: string | number, productUrl: string}) => {
                          const publicationChannelById =  validation.values.publicationChannels.find((publicationChannel: any) => publicationChannel.id === item.channelId)
                          return (
                            <Row key={item.channelId}>
                              <Col xs={'auto'} className="pe-0">
                                    <span>{publicationChannelById?.name || ''}</span>
                              </Col>
                              <Col className="ps-1">
                                <CardLink
                                  href={item.productUrl}
                                  target="_blank"
                                  >
                                  <BiLinkExternal />
                                </CardLink>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      </div>
                </div>
                : <></>
            }
            {
              validation.values.publishIssues ?
                <div className='mb-4'>
                  <Label className="mb-2">{t(Labels.publish_issues)}</Label>
                  <div>
                      {validation.values.publishIssues?.map((publishIssue: string) => {
                        return <div className='alert-danger mb-2 p-2 rounded-3' key={publishIssue}  >{publishIssue}</div>
                      })}
                    </div>
                </div>
              : <></>
            }
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>{t(Labels.product_organization)}</CardTitle>
          </CardHeader>
          <CardBody>
            <Row className="mb-4">
              <Col>
                <Label className="mb-2">{t(Labels.product_types)}</Label>
                <a target="_blank" rel="noreferrer" href={createUrlEmbed(RouteNames.PRODUCT_TYPES_DETAIL.replace(':id', validation.values.productType?.id))} className="ms-1">
                <BiLinkExternal />
                </a>
              </Col>
              <ProductTypeSelect
                value={validation.values?.productType}
                onChange={(value: any) => {
                  validation.setFieldValue('productType', value)
                  validation.setErrors({})
                }}
              />
            </Row>
            <Row className="mb-3">
              <Col>
                <Label className="mb-2">{t(Labels.artwork_desgin)}</Label>
                <a target="_blank" rel="noreferrer" href={createUrlEmbed(RouteNames.ARTWORKS_DETAIL.replace(':id', validation.values.artwork?.id))} className="ms-1" >
                <BiLinkExternal />
                </a>
              </Col>
              <SearchableSelect
                className="mb-2"
                value={validation.values?.artwork}
                getOptionLabel={(option: ArtworkDto) => option?.sku}
                getOptionValue={(option: any) => option?.sku}
                loadOptions={(searchText: string) => ArtworkActions.searchByArtwork(searchText)}
                onChange={(value: any) => {
                  validation.setFieldValue('artwork', value)
                  validation.setErrors({})
                }}
              />
            </Row>
            <Row className="mb-4">
              <Label className="mb-2">{t(Labels.vendor)}</Label>
              <VendorSelect
                value={validation.values?.owner}
                onChange={(value: any) => {
                  validation.setFieldValue('owner', value)
                  validation.setErrors({})
                }}
              />
            </Row>
            <Row className="mb-3">
              <FormikInput
                name='categories'
                validation={validation}
                label={t(Labels.categories)}
                required={false}
                customInput={({handleChange, handleBlur, errorData}) => (
                  <Creatable
                    isMulti={true}
                    formatCreateLabel={(value) => `${t(Labels.create)} "${value}"`}
                    placeholder={t(Labels.categories_hint)}
                    value={validation.values?.categories?.map((item: string) => ({value: item}))}
                    getOptionLabel={(option: any) => t(option.label || option.value)}
                    getOptionValue={(option: any) => option.value}
                    onBlur={handleBlur}
                    onChange={(values: any) => handleChange(values.map((item: any) => item.value))}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Row>
            <Row className="mb-4">
              <FormikInput
                name='tags'
                validation={validation}
                label={t(Labels.tags)}
                required={false}
                customInput={({handleChange, handleBlur, errorData}) => (
                  <Creatable
                    isMulti={true}
                    formatCreateLabel={(value) => `${t(Labels.create)} "${value}"`}
                    placeholder={t(Labels.tags_hint)}
                    value={validation.values?.tags?.map((item: string) => ({value: item}))}
                    getOptionLabel={(option: any) => t(option.label || option.value)}
                    getOptionValue={(option: any) => option.value}
                    onBlur={handleBlur}
                    onChange={(values: any) => handleChange(values.map((item: any) => item.value))}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Row>
          </CardBody>
        </Card>
      </FormLayoutRight>
    </FormLayout>
  )
}

export interface AddMediaPopupProps {
  validation: FormValidation
}

export const AddMediaPopup = (props: AddMediaPopupProps) => {
  const {validation} = props
  const [listImageUrl, setListImageUrl] = useState('')

  const handleChangeImageUrl = (value: any) => {
    setListImageUrl(value.target.value)
  }

  const handleSubmitPopup = () => {
    const images = (listImageUrl.trim().replace(/\s/g, ',').split(','))?.filter((item: string) => item !== '')
    const imagesFilter = images.filter((item) => item.startsWith('https://'))

    const temp = [
      ...(validation.values?.images ? validation.values?.images : []),
      ...imagesFilter
    ]
    validation.setFieldValue('images', temp)
  }

  return (
    <>
      <ModalContent>
        <FormGroup className="mb-3">
          <Label>{t(Labels.list_url)}</Label>
          <Input type="textarea" rows="6" value={listImageUrl} onChange={handleChangeImageUrl}/>
        </FormGroup>
      </ModalContent>
      <ModalButtons
        confirm={handleSubmitPopup}
      />
    </>
  )
} 