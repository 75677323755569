import { apiBaseUrls } from "data/rest-api-config";
import { CrudService } from "./crud.service";

export interface ArtworkTemplateDto {
  name: string
  description: string
  customOptions: any
}

export interface UpdateArtworkTemplateDto extends ArtworkTemplateDto { }

export const ArtworkTypeApiPaths = {
  BASE: '/artworks/templates',
  WITH_ID: '/artworks/templates/{id}',
  QUERY: '/artworks/templates/query',
  BULK: '/artworks/templates/bulk',
  IMPORT: '/artworks/templates/import',
}

export class ArtworkTemplateService extends CrudService<any, ArtworkTemplateDto, ArtworkTemplateDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: ArtworkTypeApiPaths.WITH_ID,
        updateById: ArtworkTypeApiPaths.WITH_ID,
        deleteById: ArtworkTypeApiPaths.WITH_ID,
        query: ArtworkTypeApiPaths.QUERY,
        base: ArtworkTypeApiPaths.BASE,
        deleteMany: ArtworkTypeApiPaths.BULK,
        updateMany: ArtworkTypeApiPaths.BULK
      }
    });
  }
}

export const ArtworkTemplateServices = new ArtworkTemplateService()