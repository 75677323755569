import { apiBaseUrls } from "../rest-api-config";
import { CrudService } from "./crud.service";
import { RequestOptions } from "../../core/rest-api/rest.api";
import { QueryParams } from "core/rest-api/query-builder";
import { ChannelOrderTypeValues } from "pages/sale-channel/types/sale-channel.type";

export interface SaleChannelDto {
  name: string,
  domain: string,
  apiKey: string,
  apiSecret: string,
  type?: string,
  active?: boolean,
  id?: string
  imageBaseUrl?: string
}

export interface CreateSaleChannelDto {
  name: string,
  domain: string,
  teamId: string,
  apiKey: string,
  apiSecret: string,
  permissions: {
    order: string,
    storage: string,
    saleChannel: string
  }
  type?: string,
  active?: boolean,
  trackingConfig: {
    apiKey: string,
    trackingService: string,
    baseApiUrl: string
  }
  imageBaseUrl?: string,
  ownerId?: string,
  owner?: any,
  options?: {
    channelOrderType?: ChannelOrderTypeValues
    fulfillFromOrderId?: number | string
    useArtworkSkuForNoCustomLineItem: boolean
  }
}

export interface UpdateSaleChannelDto extends CreateSaleChannelDto {
}

export const SaleChannelApiPaths = {
  BASE: '/sale-channels',
  WITH_ID: '/sale-channels/{id}',
  QUERY: '/sale-channels/query',
  BULK: '/sale-channels/bulk',
  SYNC: '/sale-channels/{id}/sync',
  SYNC_ALL: '/sale-channels/sync-all'
}

export class SaleChannelService extends CrudService<SaleChannelDto, CreateSaleChannelDto, UpdateSaleChannelDto> {
  constructor() {
    super({
        baseUrl: apiBaseUrls.admin,
        snoozeErrorMessage: false,
        pathNames: {
          getById: SaleChannelApiPaths.WITH_ID,
          updateById: SaleChannelApiPaths.WITH_ID,
          deleteById: SaleChannelApiPaths.WITH_ID,
          query: SaleChannelApiPaths.QUERY,
          base: SaleChannelApiPaths.BASE,
          deleteMany: SaleChannelApiPaths.BULK,
          updateMany: SaleChannelApiPaths.BULK
        }
    });
  }

  async sync(id: number, options?: RequestOptions) {
    let response = await this.restApi.get(SaleChannelApiPaths.SYNC, {
      params: { id },
      ...options
    })
    return this.returnDataOrThrow(response)
  }

  async syncAll(options?: RequestOptions) {
    let response = await this.restApi.get(SaleChannelApiPaths.SYNC_ALL, options || {})
    return this.returnDataOrThrow(response)
  }

  async queryForBulkEdit(ids: string[] | number[], options?: RequestOptions) {
    let query: QueryParams<any> = { filter: { id: { contains: ids } }, limit: Math.max(200, ids.length )}
    return await this.query(query, options)
  }
}

export const SaleChannelServices = new SaleChannelService()
