import { useState } from "react";
import DropdownComponent from "../dropdown/DropdownComponent";
import { FilterInput } from "./FilterInput";
import { FilterOption, FilterValue } from "./TableFilter";

export interface DropdownFilterProps {
  filterOptions: any
  currentFilters: any
  onClose: any
  onFilterChange: (key: string, value: any, autoSubmit: boolean) => void
  changeFilterFields: any
}

export const DropdownTableFilter = (props: DropdownFilterProps) => {
  let { filterOptions, currentFilters, onClose, onFilterChange, changeFilterFields } = props;
  let [dropdownOpen, setDropdownOpen] = useState<boolean>();

  const getStyleFilter = (idx: number) => {
    switch (idx) {
      case 0: {
        return {
          borderLeft: "1px solid",
          borderRight: "1px solid",
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        }
      }
      default:
        return {
          borderRight: "1px solid",
          borderLeft: 0,
          borderRadius: 0,
        }
    }

  }

  return <div>
    {
      filterOptions.filter((option: FilterOption) => !option.hidden)
        .map((filter: any, idx: number) =>
        <DropdownComponent
          key={`${filter.name}${idx}`}
          alignRight={true}
          onClose={onClose}
          opening={dropdownOpen}
        >
          <DropdownComponent.Toggle>
            <button className="btn btn-outline-secondary" disabled={filter.disabled} style={getStyleFilter(idx)}>
              {filter.label}<i className="ms-1 bx bx-caret-down font-size-16 align-middle" />
            </button>
          </DropdownComponent.Toggle>
          <DropdownComponent.Menu>
            <FilterInput
              filter={filter}
              currentFilters={currentFilters}
              changeFilterFields={changeFilterFields}
              onFilterChange={(key: any, value: any) => onFilterChange(key, value, true)}
              hideFilterInput={setDropdownOpen} />
          </DropdownComponent.Menu>
        </DropdownComponent>
      )
    }
  </div>;
};