import { atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { OrderServices } from "../services/order.service";
import { FulfillOrderServices } from "../services/fulfill-order.service";
import { fulfillOrderQueryState } from "./fulfill-order.atom";

export const ordersQueryState = atomFamily<QueryParams<any>, any>({
  key: 'orderQueryState',
  default: (params: any) => {
    return {...params}
  }
})

export const ordersSelector = selector({
  key: 'ordersSelector',
  get: async ({ get }) => {
    let query = get(ordersQueryState(defaultPageInfo))
    let orders = await OrderServices.query(query, { snoozeErrorMessage: true })
    return orders;
  },
});

export const orderFulfillmentStatusCountSelector = selector({
  key: 'orderFulfillmentStatusCountSelector',
  get: async ({ get }) => {
    let query = get(ordersQueryState(defaultPageInfo))
    let countByStatus = await OrderServices.getCountByFulfillmentStatus()
    return countByStatus;
  },
});

export const orderIdState = atomFamily<QueryParams<any>, any>({
  key: "orderIdState",
  default: (id: any) => {
    return id;
  }
});

export const orderByIdSelector = selectorFamily({
  key: "orderByIdSelector",
  get: (id: any) => async ({ get }: any) => {
    let orderId = get(orderIdState(id));

    if (+orderId > 0) {
      let data = await OrderServices.getById(+orderId, { snoozeErrorMessage: false });
      return data;
    }
    return {};
  }
});