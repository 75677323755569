import AsyncSelect from "react-select/async";
import { t } from "core/translations"
import { ProductServices } from "data/services/product.service"
import { FormikHandlers, FormikHelpers, FormikState } from "formik"
import { ComponentProps } from "react"
import { ActionMeta } from "react-select"
import { Labels } from "common/labels";
import { AccountServices } from "data/services/account.service";
import { AccountActions } from "data/actions/account.action";

export interface AccountDto {
  id: string 
  username: string
  name: string
}

export interface AccountSelectProps extends ComponentProps<any> {
  validation?: FormikHandlers & FormikState<any> & FormikHelpers<any>
  value?: AccountDto | any
  onChange: (value: any, actionMeta?: ActionMeta<any>) => void
}

export const AccountSelect = (props: AccountSelectProps) => {
  let { value, validation, onChange, getOptionValue, getOptionLabel, ...rest} = props

  return (
    <AsyncSelect
      isSearchable
      cacheOptions
      defaultOptions={true}
      value={value || null}
      getOptionValue={(option: AccountDto) => option ? option?.username : ''}
      getOptionLabel={(option: AccountDto) => option ? (option?.name ? `${option?.username} - ${option?.name}` : `${option?.username}` ) : ''}
      loadOptions={async (searchText: any) => AccountActions.getUserByUsername(searchText) }
      placeholder={t(Labels.search_by_username)}
      onBlur={validation?.handleBlur}
      onChange={props.onChange}
      {...rest}
    />
  )
}