import { LoadableComponent } from "components/common/LoadableComponent"
import { FormikForm, FormValidation } from "components/form/FormikForm"
import { FormikInput } from "components/form/FormikInput"
import ActionButton from "components/input/ActionButton"
import Breadcrumb from "components/layout/Breadcrumb"
import { FormLayout, FormLayoutMain } from "components/layout/FormLayout"
import { SmallContainer } from "components/layout/SmallContainer"
import { t } from "core/translations"
import { accountIdSelector, getListTeamAtom, getListTeamSelector, listRoleAtom, listRoleSelector } from "data/atoms/account.atom"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Card, CardBody, Col, Label } from "reactstrap"
import {  useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil"
import * as Yup from "yup"
import Select from "react-select"
import { AccountActions } from "data/actions/account.action"
import { Toasts } from "core/notification/notifications"
import { TestContext } from 'yup/lib/util/createValidation';
import { Messages } from "common/messages"
import { isEmpty } from "lodash"
import { RouteNames } from "../../routes";
import { useStaticData } from "common/hooks/use-static-data"
import { Labels } from "common/labels"
import { OptionalLabel } from "components/common/OptionalLabel"
import { WithPermission } from "components/common/WithPermission"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { preprocessPage } from "utils/utils"



const accountSchema = Yup.object({
  name: Yup.string().nullable().required(t(Messages.field_required, { field: t(Labels.name) })),
  username: Yup.string().required(t(Messages.field_required, { field: t(Labels.username) })),
  email: Yup.string().email().required(t(Messages.field_required, { field: t(Labels.email) })),
  role: Yup.string().nullable().required(t(Messages.field_required, { field: t(Labels.role) })),
  password: Yup.string().nullable().test("checkIsEdit", (value: any,conText: TestContext) => {
    if(conText.parent.isEdit && isEmpty(value)) {
      return conText.createError({ message: t(Messages.please_enter_password) })
    }
    return true
  }).min(8)
})

const AccountPage = () => {
  const history = useHistory()
  const params = useParams<Record<string, any>>()
  const isEditing = +params.id > 0
  const dataLoadable = useRecoilValueLoadable(accountIdSelector(params.id))
  const [user, setUser] = useState<any>({isEdit:false})

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.users), model: user, primaryProperty: 'name'})
  }, [user])

  useStaticData(useRecoilValueLoadable(listRoleSelector),useSetRecoilState(listRoleAtom))

  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(accountIdSelector(params.id))
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[params.id])

  useEffect(() => {
    if (dataLoadable.state === "hasValue") {
      setUser((current: any) => {
        return {
          ...current,
          ...(dataLoadable.contents || {}),
        }
      })
    }
  }, [dataLoadable.contents])


  const validation = useFormik({
    initialValues: user,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: accountSchema,
    onSubmit: (value, helpers) => handleSubmit(value, helpers),
  })

  const handleSubmit = async (value: any, helpers: any) => {
    try {
      helpers.setErrors({})
      helpers.setSubmitting(true)
      let savedData = await AccountActions.save(params.id, {
        ...value,
      })
      if (savedData?.id) {
        Toasts.success(t(Messages.save_account_successfully))
        !isEditing && setTimeout(() => {
          history.replace(RouteNames.USER.replace(":id", savedData.id));
        }, 100);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <SmallContainer>
          <Breadcrumb goBack={RouteNames.USERS} title={t(Labels.edit_user)}>
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.userEntity}>
              <ActionButton onClick={() => validation.submitForm()} color="success">
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable}>
            <FormikForm validation={validation}>
              <FormLayout>
                <FormLayoutMain>
                  <AccountForm validation={validation} isEditing={isEditing} />
                </FormLayoutMain>
              </FormLayout>
            </FormikForm>
          </LoadableComponent>
        </SmallContainer>
      </div>
    </React.Fragment>
  )
}

export interface AccountFormProps {
  validation: FormValidation
  isEditing?: boolean
}

const AccountForm = (props: AccountFormProps) => {
  const { validation } = props
  const roles = useRecoilValue(listRoleAtom)
  const teamsData = useRecoilValueLoadable(getListTeamSelector)
  const [teams, setTeams] = useState([])

  useEffect(() => {
    if(teamsData.state === 'hasValue'){
      setTeams(teamsData.contents)
    }
  }, [teamsData.contents])

  return (
    <>
      <Card>
        <CardBody>
          <>
            <div className="row mb-4">
              <Label className="col-sm-3 col-form-label">{t(Labels.teams)}</Label>
              <Col sm={9}>
                <FormikInput
                  name="team"
                  type="text"
                  validation={validation}
                  customInput={({handleBlur, handleChange, errorData}) => {
                    // const value = teams?.filter((team: any) => validation.values.teamIds?.includes(team.id))
                    return (
                      <Select
                        value={validation.values.team}
                        isMulti
                        placeholder={t(Labels.select_teams_hint)}
                        options={teams}
                        getOptionValue={(option: any) => option.id || ""}
                        getOptionLabel={(option: any) => t(option.name || "")}
                        onBlur={handleBlur}
                        onChange={(values: any) => {
                          validation.setFieldValue("teamIds", values.map((item: any) => item.id) || [])
                        }}
                        styles={errorData.reactSelectErrorStyle}
                      />
                    )
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label className="col-sm-3 col-form-label">{t(Labels.name)}</Label>
              <Col sm={9}>
                <FormikInput
                  name="name"
                  type="text"
                  validation={validation}
                  placeholder={t(Labels.account_name_hint)}
                  onChange={(value: any) => {
                    validation.setFieldValue("name", value)
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label className="col-sm-3 col-form-label">{t(Labels.username)}</Label>
              <Col sm={9}>
                <FormikInput
                  name="username"
                  type="text"
                  validation={validation}
                  placeholder={t(Labels.username_hint)}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label className="col-sm-3 col-form-label">{t(Labels.email)}</Label>
              <Col sm={9}>
                <FormikInput
                  name="email"
                  type="text"
                  validation={validation}
                  placeholder={t(Labels.email_hint)}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label className="col-sm-3 col-form-label">{t(Labels.role)}</Label>
              <Col sm={9}>
                <FormikInput
                  name="role"
                  validation={validation}
                  customInput={({ handleChange, handleBlur, errorData }) => {
                    const valueSelect = roles?.find((item: any) => item.code === validation.values.role)
                    return (
                      <Select
                        value={valueSelect}
                        placeholder={t(Labels.select_account_role_hint)}
                        options={roles}
                        getOptionValue={(option: any) => option.code || ""}
                        getOptionLabel={(option: any) => t(option.name || "")}
                        onBlur={handleBlur}
                        onChange={(value: any) => {
                          validation.setFieldValue("role",value.code || "")
                        }}
                        styles={errorData.reactSelectErrorStyle}
                      />
                    )
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label className="col-sm-3 col-form-label">{t(Labels.password)}</Label>
              <Col sm={9}>
                <FormikInput
                  name="password"
                  type="password"
                  validation={validation}
                  placeholder={t(Labels.password_hint)}
                />
              </Col>
            </div>
          </>
        </CardBody>
      </Card>
    </>
  )
}

export default AccountPage
