import { CrudActions } from "./crud.action";
import {
  CreateFulfillVariantDto,
  FulfillVariantDto,
  FulfillVariantService,
  FulfillVariantServices,
  UpdateFulfillVariantDto
} from "../services/fulfill-variation.service";
import { Filter } from "../../core/rest-api/query-builder";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { withPermission } from "common/hooks/use-permission";

class FulfillVariantAction extends CrudActions<FulfillVariantService, CreateFulfillVariantDto, UpdateFulfillVariantDto>{
  constructor() {
    super(FulfillVariantServices);
  }

  async search(searchValue: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillVariantEntity,
      async () => {
        // let filter: Filter<FulfillVariantDto> = {}
        // if (searchValue) {
        //   filter.variantTitle = { contains: searchValue }
        // }
        // let pageData = await FulfillVariantServices.query({ filter, page: 1 });
        // return pageData?.pageItems || [];

        let pageData = await FulfillVariantServices.searchFulfillVariantsByVariantTitle(searchValue)
        return pageData
      })
  }

  async searchById(id: string){
    withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillVariantEntity,
      async () => {
        if(!id){
          return []
        } else{
          let pageData = await FulfillVariantServices.getById(parseInt(id))
          return pageData
        }
      })
  }
}

export const FulfillVariantActions = new FulfillVariantAction()