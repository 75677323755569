import DropdownComponent from "../dropdown/DropdownComponent";
import { t } from "../../core/translations";
import { Labels } from "../../common/labels";
import { Card, Input, Label } from "reactstrap";
import { SortOption } from "./TableFilter";
import { isEqual } from "lodash";

export interface TableSorterProps {
  sortOptions?: SortOption[]
  onTableSortChange?: Function
  tableSort?: SortOption
}

export const TableSorter = (props: TableSorterProps) => {
  return (
    <DropdownComponent alignRight>
      <DropdownComponent.Toggle>
        <button className="btn btn-outline-secondary" style={
          {
            borderRight: "1px solid",
            borderLeft: 0,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }
        }>
          <i className="bx bx-sort font-size-16 align-middle" />
          {t(Labels.sort)}
        </button>
      </DropdownComponent.Toggle>
      <DropdownComponent.Menu>
        <Card className="mb-0 p-4 overflow-auto">
          {
            props.sortOptions?.map((sortOption: any, index: any) => (
              <div className="form-check form-check-inline py-1 mb-3" key={`${sortOption.label}${index}`}>
                <Input
                  type="radio"
                  id={sortOption.label}
                  name="sort"
                  className="form-check-input"
                  checked={isEqual(props.tableSort, sortOption)}
                  onChange={() => props.onTableSortChange && props.onTableSortChange(sortOption)}
                />
                <Label
                  className="form-check-label"
                  htmlFor={sortOption.label}
                >
                  {sortOption.label}
                </Label>
              </div>
            ))
          }
        </Card>
      </DropdownComponent.Menu>
    </DropdownComponent>
  )
}
