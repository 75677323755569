import { FormFeedback, FormGroup, Input, InputGroup, Label } from "reactstrap";
import { InputProps } from "reactstrap/types/lib/Input";
import { FormikHandlers, FormikState } from "formik/dist/types";
import React, { ChangeEventHandler, Fragment, memo, useEffect, useRef, useState } from "react";
import { t } from "../../core/translations";
import { FormikHelpers } from "formik";
import { isObject, isString } from "lodash";
import { OptionalLabel } from "components/common/OptionalLabel";
import { LabelTooltip } from "components/common/LabelTooltip";
import { Link } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import { TooltipComponent } from "components/common/TooltipComponent";
import { Labels } from "common/labels";
import { Toasts } from "core/notification/notifications";
import { createLinkEmbedParam } from "utils/embed";

export interface FormikInputProps extends InputProps {
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  name: string;
  label?: string;
  readOnly?: boolean;
  errors?: any;
  hiddenError?: boolean
  startComponent?: any;
  endComponent?: any;
  required?: boolean;
  tooltip?: string;
  onChange?: ChangeEventHandler<any> | ((value: any) => void);
  customInput?: ({ handleBlur, handleChange }: any) => any;
  copyable?: boolean;
}

export const FormikCheckboxInput = (props: FormikInputProps) => {
  let { readOnly, label, children, name, validation, errors, ...restProps } = props;
  return (
    <div className="my-3">
      <FormGroup
        check
        inline
      >
        <Input
          type="checkbox"
          name={name}
          checked={validation.values[name]}
          value={validation.values[name]}
          onChange={readOnly ? () => {
          } : event => validation.setFieldValue(name, event.target.checked)}
          onBlur={validation.handleBlur}
        />
        <Label check>
          {t(label || "")}
        </Label>
      </FormGroup>
    </div>
  );
};

// xuanduc edit
const getErrorMessageGroup = (errors: any, name: string): any => {
  let message = getErrorMessage(errors, name)
  return message
}

const getErrorMessage = (errors: any, name: string): any => {
  if(isString(errors)) return errors;
  if (!errors) return errors;

  if (Array.isArray(errors)) {
    for (let error of errors) {
      const msg: any = getErrorMessage(error, name);
      if (msg) return msg;
    }
  } else {
    let error = errors[name];
    if (isObject(error)) {
      return getErrorMessage(error, name);
    } else {
      return error;
    }
  }
};

const FormikInputComponent = (props: FormikInputProps) => {
  const {
    readOnly,
    label,
    children,
    name,
    validation,
    errors,
    onChange,
    startComponent,
    endComponent,
    customInput,
    required,
    tooltip,
    onFocus,
    copyable,
    ...restProps
  } = props;
  const isSubmitting = useRef<any>(null)

  const [valueCopy, setValueCopy] = useState('')
  const [isFocusInput, setIsFocusInput] = useState(false)
  useEffect(() => {
    setValueCopy((props.value ||  validation?.values[name])?.toString() || "")
  }, [props.value, validation?.values[name]])

  useEffect(() => {
    if(validation.isSubmitting){
      isSubmitting.current = true
    }
  }, [validation.isSubmitting])
  const handleChange = (value: any, fieldName = name) => {
    fieldName = isString(fieldName) ? fieldName : name
    if (onChange) {
      onChange(value);
    } else {
      validation.setFieldValue(fieldName, value);
    }

    validation.setFieldError(fieldName, undefined);
  };

  const handleBlur = () => {
    validation?.setFieldTouched(name, true);
  };

  const PreInputComponent = startComponent || Fragment;
  const EndInputComponent = endComponent || Fragment;

  function isInvalidInput() {
    return props.invalid || props.hiddenError ? false : !!(validation && (validation.touched[name] || isSubmitting.current) && validation.errors[name] );
  }

  const getReactSelectErrorStyle = (base: any) => {
    return {
      ...base,
      borderColor: "#f46a6a",
      boxShadow: "unset",
      '&:hover': {
        boxShadow: "0 0 0 1px #f46a6a",
        borderColor: "#f46a6a"
      }
    }
  }

  const errorData = {
    error: isInvalidInput(),
    errorStyle: {
      boxShadow: "0 0 0 1px #f46a6a",
      borderColor: "#f46a6a"
    },
    reactSelectErrorStyle: isInvalidInput() ? {
      control: (base: any) => getReactSelectErrorStyle(base)
    } : { control: (base: any) => base }
  }

  
  const handleCopyValue = (value: string) => {
    if (value) {
      navigator.clipboard.writeText(value)
      Toasts.info(Labels.copied_to_clipboard)
    } else {
      Toasts.warning(Labels.copied_fail)
    }
  }
  const hiddenIconCopy = !(validation && validation.errors[name] && (validation.touched[name] || isSubmitting.current)) && copyable

  return props.type == "checkbox" || props.type == "radio" ? <FormikCheckboxInput {...props} /> : (
    <>
      <div className={label ? "mb-2" : ""}>
        {
          tooltip
            ? 
              required == false
              ? <LabelTooltip tooltip={tooltip as string}>
                  <OptionalLabel label={label}/>
                </LabelTooltip>
              : <LabelTooltip tooltip={tooltip as string}>
                  {label && <Label>{label}</Label>}
                </LabelTooltip>
            : 
              required == false
              ? <OptionalLabel label={label} />
              : label && <Label>{label}</Label>
        }
        {
          customInput ? (
              customInput({ handleBlur, handleChange, errorData})
            )
            :
            <InputGroup>
              <PreInputComponent />
              <Input
                name={name}
                onChange={readOnly ? (() => {
                }) : (event) => {handleChange(event.target.value); setValueCopy(event.target.value)}}
                onBlur={(e) => {validation?.handleBlur(e), setIsFocusInput(false)}}
                {...(props.hasOwnProperty("value") ? {} : { value: validation?.values[name] || "" })}
                onFocus={(e) => {onFocus && onFocus(e); setIsFocusInput(true)}}
                {...{ className: `form-control ${ hiddenIconCopy ? 'pe-5': "" }`, ...restProps }}
                invalid={isInvalidInput()}
              />
              {
                hiddenIconCopy ? <div className="d-inline input-group-append position-absolute top-50 translate-middle" onClick={() => handleCopyValue(valueCopy)} style={{right: 10, ...(isFocusInput ? {zIndex: 4} : {})}}><TooltipComponent className="d-inline-block " tooltip={t(Labels.copy_to_clipboard)}>
                  <Link {...createLinkEmbedParam("#")} className=""><FaRegCopy size={16} className='d-inline-block' /></Link>
                </TooltipComponent></div> : <></>
              }
              <EndInputComponent />
            </InputGroup>
        }
        {
          // xuanduc edit
          props.hiddenError || !(validation && validation.errors[name] && (validation.touched[name] || isSubmitting.current)) ? null :
            <FormFeedback className="d-block" type="invalid">{getErrorMessageGroup(validation?.errors, name)}</FormFeedback>
        }
      </div>
    </>
  );
};



// {...(props.hasOwnProperty('value') ? { } : { value: validation?.values[name] || ""})}
export const FormikInput = memo(FormikInputComponent);