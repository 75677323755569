import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userSelector, userTenantSelector } from "../data/atoms/auth.atom";
import { RouteNames } from "../routes";

export const IndexPage = () => {
  const history = useHistory()
  const [user] = useRecoilState(userSelector)
  const [tenant] = useRecoilState(userTenantSelector);
  const searchParams = new URLSearchParams(document.location.search)
  const embedToken = searchParams.get('embedToken')

  useEffect(() => {
    setTimeout(() => {
      if(!embedToken && (!user || !tenant)) {
        window.location.replace(RouteNames.SIGN_IN)
      } else {
        window.location.replace(RouteNames.DASHBOARD)
      }
    }, 200)
  }, [])

  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    </>
  )
}