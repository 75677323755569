import { useEffect, useState } from "react";
import DataGrid, { DataGridProps } from "react-data-grid";

const containerId = `data-grid-` + Date.now
// https://www.bennadel.com/blog/3460-automatically-scroll-the-window-when-the-user-approaches-the-viewport-edge-in-javascript.htm
const ReactDataGridCustomize = (props: any) => {
  const [insideCard, setInsideCard] = useState(false)
  const [mouseDown, setMouseDown] = useState(false)
  const [click, setClick] = useState(false)

  const handleMouseEnter = () => { setInsideCard(true) }

  const handleMouseLeave = () => { setInsideCard(false) }

  const handleMouseDown = () => { setMouseDown(true) } 
  
  const handleMouseUp = () => { setMouseDown(false) }
  
  useEffect(() => {
    (document.getElementById(containerId) as HTMLInputElement)
      ?.addEventListener("mouseenter", handleMouseEnter);
    (document.getElementById(containerId) as HTMLInputElement)
      ?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      (document.getElementById(containerId) as HTMLInputElement)
        ?.removeEventListener("mouseenter", handleMouseEnter);
      (document.getElementById(containerId) as HTMLInputElement)
        ?.removeEventListener("mouseleave", handleMouseLeave);
    }
  }, [])

  useEffect(() => {
    document.querySelector('.rdg-cell-drag-handle')
      ?.addEventListener('mousedown', handleMouseDown)

    return () => {
      document.querySelector('.rdg-cell-drag-handle')
        ?.removeEventListener('mousedown', handleMouseDown)
    }
  }, [click])


  useEffect(() => {
    document?.addEventListener('mouseup', handleMouseUp)
    
    return () => {
      document?.removeEventListener('mouseup', handleMouseUp)
    }
  }, [mouseDown])

  const handleMoving = (event: any) => {
    if (event.clientY >= window.innerHeight - 30) {
      setTimeout(() => window.scrollBy({left: 0, top: 80, behavior: 'smooth'}), 10)
    } 
    if (event.clientY <= 30) {
      setTimeout(() => window.scrollBy({left: 0, top: -80, behavior: 'smooth'}), 10)
    }
  }

  useEffect(() => {
    if (mouseDown && insideCard) {
      (document.getElementById(containerId) as HTMLInputElement)?.addEventListener('mousemove', handleMoving)
    }
    return () => {
      (document.getElementById(containerId) as HTMLInputElement)?.removeEventListener('mousemove', handleMoving)
    }
  }, [mouseDown, insideCard])

  const handleRowClick = () => { setClick(!click) }

  return (
    <div id={containerId}>
      <DataGrid
        {...props}
        onRowClick={handleRowClick}
      />
    </div>
  )
}

export default ReactDataGridCustomize
