import { Button } from "reactstrap"
import DropdownComponent from "../dropdown/DropdownComponent"
import { Labels } from "../../common/labels"
import { t } from "../../core/translations"
import { useEffect, useMemo, useState } from "react";
import { SetterOrUpdater } from "recoil"
import ActionComponent from "components/input/ActionComponent"
import { delay } from "utils/utils"
import { Logger } from "../../core/logger";
import { TabOption } from "./TableFilter";

export interface ActionOption {
  name: "separator" | "group" | string
  label: string
  groupName?: string
  enableCondition?: (action: ActionOption, selectData: RemoteTableSelectionData) => boolean
  customRenderer?: Function
  onClick: (props: RemoteTableSelectionData) => any | Promise<void>
  option?: {
    listOption: any[]
    onClickOption: Function
  }
}

export interface RemoteTableSelectionData {
  activeTab?: TabOption
  selectedIds: string[] | number[]
  selectedItems: any[]
  selectedIdsUpdater: SetterOrUpdater<any>
  actions?: ActionOption[]
  refresh: Function
}

export const SelectedRowAction = (props: RemoteTableSelectionData) => {
  let [open, setOpen] = useState<boolean>(false)
  let [disabled, setDisabled] = useState<boolean>(false)
  useEffect(() => setOpen(props.selectedIds.length > 0), [props.selectedIds])
  let otherActionLabel = t(Labels.other_action);

  const isGroupAction = Boolean(props.actions?.find(action => action.groupName))
  const groupNames: any[] = [...new Set(props.actions?.map(action => action.groupName).filter(Boolean))]
  groupNames.push(otherActionLabel) // Other Action always at the end

  return !open ? null : (
    <div
      className="btn-group me-3 mb-2"
      role="group"
      aria-label="Basic example"
    >
      <Button
        outline
        color="secondary"
        className="btn btn-outline-secondary mb-2"
      >
        {props.selectedIds.length} selected
      </Button>
      {
        isGroupAction ? (
          groupNames.map((groupName: string, idx: number) => {
            return <DropdownComponentSelect
              key={idx}
              actions={props.actions?.filter(action => action.groupName === groupName || (groupName === otherActionLabel && !action.groupName))}
              labelSelect={groupName}
              disabled={disabled}
              setDisabled={setDisabled}
              tableSelectionData={props}
            />
          })
        )
        :
        <DropdownComponentSelect actions={props.actions} disabled={disabled} setDisabled={setDisabled} tableSelectionData={props}  />
      }
    </div>
  )
}
interface DropdownComponentSelectProps {
  disabled: boolean
  setDisabled: any
  actions?: ActionOption[]
  tableSelectionData: RemoteTableSelectionData
  labelSelect?: string
}

const DropdownComponentSelect = (props: DropdownComponentSelectProps) => {
  const otherActionLabel = t(Labels.other_action);
  const {disabled, setDisabled, tableSelectionData, labelSelect } = props;
  const [execute, setExecute] = useState<boolean>(false)
  const handleClick = async (action: ActionOption) => {
    setExecute(true)
    setDisabled(true)
    try {
      await action.onClick(tableSelectionData)
    } catch (error) {
      Logger.error(error)
    } finally {
      setExecute(false)
      setDisabled(false)
    }
  }
  
  return (
    <ActionComponent
      execute={execute && disabled}
      exComponent={
        <button disabled className="btn btn-outline-secondary mb-2 btn btn-outline-secondary">
          {t(Labels.action_running)}
        </button>
      }
    >
      <DropdownComponent onMenuItemClick="toggle">
        <DropdownComponent.Toggle>
          <button
            type="button"
            className="btn btn-outline-secondary mb-2"
            style={
              !labelSelect || labelSelect === otherActionLabel
                ? {
                    borderRight: "1px solid",
                    borderLeft: 0,
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                  }
                : { borderLeft: 0, borderRadius: 0 }
            }
            disabled={disabled}
          >
            {t(labelSelect || Labels.choose_actions)} <i className="bx bx-caret-down font-size-16 align-middle" />
          </button>
        </DropdownComponent.Toggle>
        <DropdownComponent.Menu>
          <div className="mb-0 py-2 overflow-auto bg-white" style={{ maxHeight: 295 }}>
            { props.actions?.map((action: ActionOption, index: number) => {
              const enable = useMemo(() => {
                return action.enableCondition ? action.enableCondition(action, tableSelectionData) : true
              }, [tableSelectionData.selectedIds, tableSelectionData.activeTab, tableSelectionData.selectedItems])

              if (action.name === "separator") {
                return <hr key={index} className="my-1" />
              } else if (action.name === "group") {
                return (
                  <div key={index} className="m-2 font-size-16">
                    {action.label}
                  </div>
                )
              } else {
                let component = (
                  <Button
                    outline
                    key={index}
                    color="light"
                    className="border-0 d-block w-100 text-start px-3 py-2 "
                    disabled={!enable}
                    style={enable ? {} : { color: 'rgba(0, 0, 0, 0.9)' }}
                    onClick={() => {
                      if(enable) {
                        handleClick(action)
                      }
                    }}
                  >
                    {action.label}
                  </Button>
                )
                return action?.customRenderer ? action.customRenderer(component, index) : component
              }
            })}
          </div>
        </DropdownComponent.Menu>
      </DropdownComponent>
    </ActionComponent>
  )
}
