export enum ShipmentStatus {
  PENDING = 'pending',
  INFO_RECEIVED = 'info_received',
  IN_TRANSIT = 'in_transit',
  OUT_FOR_DELIVERY = 'out_for_delivery',
  DELIVERED = 'delivered',
  FAILED_ATTEMPT = 'failed_attempt',
  EXCEPTION = 'exception',
  EXPIRED = 'expired',
  PICKUP = 'pickup',
}

export const ShipmentStatusValues = [
  ShipmentStatus.PENDING,
  ShipmentStatus.INFO_RECEIVED,
  ShipmentStatus.IN_TRANSIT,
  ShipmentStatus.PICKUP,
  ShipmentStatus.OUT_FOR_DELIVERY,
  ShipmentStatus.DELIVERED,
  ShipmentStatus.FAILED_ATTEMPT,
  ShipmentStatus.EXCEPTION,
  ShipmentStatus.EXPIRED,
]

export const ShipmentStatusName: Record<string, string> = {
  [ShipmentStatus.PENDING]: 'Chờ', // This is new order
  [ShipmentStatus.INFO_RECEIVED]: 'Đã tiếp nhận',
  [ShipmentStatus.IN_TRANSIT]: 'Đang vận chuyển',
  [ShipmentStatus.OUT_FOR_DELIVERY]: 'Đang giao',
  [ShipmentStatus.DELIVERED]: 'Đã giao',
  [ShipmentStatus.FAILED_ATTEMPT]: 'Không thể giao',
  [ShipmentStatus.EXCEPTION]: 'Đơn Lỗi',
  [ShipmentStatus.EXPIRED]: 'Hết hạn',
  [ShipmentStatus.PICKUP]: 'Chờ giao hàng',
}

export const ShipmentStatusColor: Record<string, string> = {
  [ShipmentStatus.PENDING]: 'light', // This is new order
  [ShipmentStatus.INFO_RECEIVED]: 'info',
  [ShipmentStatus.PICKUP]: 'info',
  [ShipmentStatus.IN_TRANSIT]: 'primary',
  [ShipmentStatus.OUT_FOR_DELIVERY]: 'primary',
  [ShipmentStatus.DELIVERED]: 'success',
  [ShipmentStatus.FAILED_ATTEMPT]: 'danger',
  [ShipmentStatus.EXCEPTION]: 'danger',
  [ShipmentStatus.EXPIRED]: 'dark',
}