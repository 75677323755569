import { atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { ArtworkServices } from "data/services/artwork.service";
import { defaultPageInfo } from "../../components/table/RemoteTable";

export const artworkQueryState = atomFamily<
  QueryParams<any>,
  QueryParams<any>
  >({
  key: "artworkQueryState",
  default: (params: any) => ({ ...params }),
})

export const artworkIdState = atomFamily<QueryParams<any>, any>({
  key: 'artworkIdState',
  default: (id: any) => {
    return id;
  }
})

export const artworkSelector = selectorFamily({
  key: 'artworkSelector',
  get: (id: any) => async ({ get }) => {
    let artworkId = get(artworkIdState(id));

    if (+artworkId > 0) {
      let data = await ArtworkServices.getById(+artworkId, { snoozeErrorMessage: false });
      return data
    }

    return {};
  }
})

export const artworksSelector = selector({
  key: "artworksSelector",
  get: async ({ get }) => {
    let query = get(artworkQueryState(defaultPageInfo))
    let pageData = await ArtworkServices.query(query, {
      snoozeErrorMessage: false,
    })
    return pageData
  },
})


export const getProductSelector = selector({
  key: 'getProductSelector',
  get: async ({ get }) => {
    let productData = await ArtworkServices.getDataProduct()
    return productData;
  },
});


export const customilyTemplateNameSelector = selector({
  key: 'customilyTemplateNameSelector',
  get: async ({ get }) => {
    let params = {
      key: 'customily-template'
    }
    let productData = await ArtworkServices.searchKeys(params)
    return productData;
  },
})