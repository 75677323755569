import { CrudActions } from "./crud.action";
import { Filter } from "../../core/rest-api/query-builder";
import {
  CreateFulfillServiceDto,
  FulfillService,
  FulfillServices,
  UpdateFulfillServiceDto
} from "data/services/fulfill-service.service";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { withPermission } from "common/hooks/use-permission";

class FulfillServiceAction extends CrudActions<FulfillService, CreateFulfillServiceDto, UpdateFulfillServiceDto>{
  constructor() {
    super(FulfillServices);
  }

  async search(searchValue: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillServiceEntity,
      async () => {
        let filter: Filter<CreateFulfillServiceDto> = {}
        if (searchValue) {
          filter.name = { contains: searchValue }
        }
        let pageData = await FulfillServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async getDefaultMappingData() {
    let response = FulfillServices.getDefaultMappingData()
    return response
  }
}

export const FulfillServiceActions = new FulfillServiceAction()
