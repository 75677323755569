import { ActionMeta, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import React from "react";
import { FormikHandlers, FormikState } from "formik/dist/types";
import { FormikHelpers } from "formik";
import { useSetRecoilState } from "recoil";
import { portableModalState } from "../../../data/atoms/app.atom";
import { FulfillProductDto } from "../../../data/services/fulfill-product.service";
import { FulfillProductType } from "../../../types/fulfill-product-type.type";
import { FulfillProductActions } from "../../../data/actions/fulfill-product.action";
import { t } from "../../../core/translations";
import { SearchableSelect } from "components/input/SearchableSelect";
import { Labels } from "common/labels";

export interface FulfillProductSelectProps {
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>
  onChange: (value: SingleValue<FulfillProductDto>, actionMeta?: ActionMeta<any>) => void
  onBlur?: any
  value: FulfillProductDto | any
  isMulti?: boolean
  styles?: any
}

export const FulfillProductSelect = (props: FulfillProductSelectProps) => {
  let { value, validation, styles } = props
  let setPortableModal = useSetRecoilState(portableModalState)
  return (
    <SearchableSelect
      cacheOptions
      defaultOptions={true}
      menuPortalTarget={document.body}
      value={value?.id ? value : props.isMulti ? value : undefined}
      isMulti ={props.isMulti}
      getOptionValue={(option: FulfillProductType) => option?.id}
      getOptionLabel={(option: FulfillProductType) => t(option?.name && `${option?.name} (${option.fulfillService?.name || "Unknown"})`)}
      loadOptions={(search: string) => FulfillProductActions.search(search)}
      placeholder={t(Labels.select_fulfill_product_hint)}
      onBlur={props.onBlur}
      onChange={props.onChange}
      getSearchValue={(option: any) => option?.name}
      styles={styles}
    />
  )
}