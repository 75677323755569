import Select, { ActionMeta, SingleValue } from "react-select";
import React, { ComponentProps, useEffect, useState } from "react";
import { FormikHandlers, FormikState } from "formik/dist/types";
import { FormikHelpers } from "formik";
import { OrderDto } from "../../../data/services/order.service";
import { FulfillOrderLineItem } from "../../../types/fulfill-order.type";
import { OrderLineItem } from "../../../types/order.type";
import { t } from "core/translations";

export interface ProductTypeSelectProps extends ComponentProps<any> {
    order: FulfillOrderLineItem | any;
    lineItems?: OrderLineItem[];
    validation?: FormikHandlers & FormikState<any> & FormikHelpers<any>;
    onChange: (value: SingleValue<FulfillOrderLineItem>, actionMeta?: ActionMeta<any>) => void;
}

export const FulfillLineItemSelect = (props: ProductTypeSelectProps) => {
    let { lineItems, validation, order, onChange, ...rest } = props;
    let [values, setValues] = useState([])

    const getLineItems = () => {
        return order?.lineItems?.filter((lineItem: any) => lineItem.id) || []
    }
    
    const onSelectChange = (value: any) => {
        setValues(value)
        props.onChange(value)
    }

    useEffect(() => {
        let newValue = getLineItems()?.filter((item: any) => item.id && lineItems?.some(lineItem => lineItem.id == item.id))
        setValues(newValue)
    }, [order, lineItems])

    return (
        <Select
            options={getLineItems()}
            value={values}
            isMulti={true}
            getOptionValue={(option: FulfillOrderLineItem) => `${option.id}`}
            getOptionLabel={(option: FulfillOrderLineItem) => t(`${option.id} - ${option.sku || ''} - ${option.name || ''}`)}
            onBlur={validation?.handleBlur}
            onChange={onSelectChange}
            {...rest}
        />
    )
}