import { Card, Input } from "reactstrap";
import RadioGroup from "../input/RadioGroup";
import { SelectOption } from "../../common/types";
import DateRangeFilter from "./DateFilter";
import CheckboxGroup from "../input/CheckboxGroup";
import { t } from "../../core/translations";
import { Labels } from "../../common/labels";
import { Logger } from "../../core/logger";

export interface FilterInputProps {
  containerComponent?: any,
  filter: {
    componentCreator: (filterValue: any, onFilterChange: (name: string, value: any, label: any) => void) => any
  } & any,
  currentFilters: any,
  onFilterChange: any,
  changeFilterFields: (keys?: string[], autoSubmit?: boolean) => void,
  hideFilterInput?: Function,
  style?: any
}

export const ClearFilterButton = (props: { name: any, removeFilter: any }) => {
  return (
    <div
      onClick={() => props.removeFilter(props.name)}
      style={{ cursor: "pointer" }}
      className="text-primary interactive--link w-fit">
      {t(Labels.reset)}
    </div>
  );
};

export const FilterInput = (props: FilterInputProps) => {
  let { filter, currentFilters, onFilterChange, changeFilterFields, hideFilterInput } = props;
  const componentCreator = filter.componentCreator
  let style = { padding: '1.5rem 1.5rem 1rem 1.5rem', ...(props.style || {}), ...(filter.style || {}) }

  const handleResetFilter = (key: string) => {
    let keys = Object.keys(currentFilters).filter(currentKey => currentKey != key)
    changeFilterFields(keys, true)
  }

  let Component = props.containerComponent || Card;
  return (
    <>
      {
        componentCreator ? componentCreator(currentFilters[filter.name], onFilterChange) : null
      }
      {
        filter.type === "radio" && (
          <Component className="mb-1 overflow-auto" id={`radio-filter-${filter.name}`}
                     style={{ maxHeight: 250, ...style }}>
            <RadioGroup
              items={filter.value}
              name={filter.name}
              checkedValue={currentFilters[filter.name]?.value}
              onChange={(option: SelectOption) => onFilterChange(filter.name, option)}
            />
            <ClearFilterButton name={filter.name} removeFilter={handleResetFilter} />
          </Component>
        )
      }
      {
        filter.type === "text" && (
          <Component className="mb-1" style={{ ...style }}>
            <Input
              type="text"
              className="mb-3"
              onChange={(e) => onFilterChange(filter.name, { value: e.target.value })}
            />
            <ClearFilterButton name={filter.name} removeFilter={handleResetFilter} />
          </Component>
        )
      }
      {
        filter.type === "date" && (
          <Component className="mb-1" id={`date-filter-${filter.name}`} style={{ ...style }}>
            {/* // TODO set validate date */}
            <DateRangeFilter
              name={filter.name}
              currentValue={currentFilters[filter.name]?.value}
              onFilterChange={(name: string, value: any) => {
                onFilterChange(name, value);
                hideFilterInput && hideFilterInput(false); // Force hide date input
                hideFilterInput && hideFilterInput(undefined); // Reset hide date state for next show
              }}
            />
            <ClearFilterButton name={filter.name} removeFilter={handleResetFilter} />
          </Component>
        )
      }
      {
        filter.type === "checkbox" && (
          <Component className="mb-1 overflow-auto" style={{ maxHeight: 250, ...style }}>
            <CheckboxGroup
              name={filter.name}
              items={filter.value}
              checkedItems={currentFilters[filter.name] ?? []}
              onChange={(name, value) => {
                let changedValues = [...(currentFilters[filter.name] || [])];
                let { index, checked } = value || {};
                if (checked) {
                  let checkedLabels = changedValues.map(item => item.value);
                  if (!checkedLabels.includes(value.value)) {
                    changedValues.push(value);
                  }
                } else {
                  changedValues = changedValues.filter(item => item.index != index);
                }
                onFilterChange(filter.name, changedValues);
              }}
            />
            <ClearFilterButton name={filter.name} removeFilter={handleResetFilter} />
          </Component>
        )
      }
    </>
  );
}
