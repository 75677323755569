import { Labels } from "common/labels"
import { UploadForm } from "components/common/UploadForm"
import { ModalContent } from "components/modal/portable-modal"
import { t } from "core/translations"
import { useEffect, useState } from "react"
import { FormGroup, Input, Label } from "reactstrap"
import { HandleFileResultType, ImportedResultEditor, ImportResultModal, ImportResultModalAsync, ImportType } from "./import-modal"
import { useRecoilState } from "recoil"
import { portableModalState } from "data/atoms/app.atom"
import { FulfillmentOrderActions } from "data/actions/fulfillment-order.action"
import { AdminUtils } from "utils/admin-utils"
import { RouteNames } from "routes"
import { BulkEditorView } from "components/bulk/bulk-editor.type"
import moment from "moment"
import { DateInput } from "./date-input"
import { Dates } from "../../../utils/dates";
import { Toasts } from "core/notification/notifications"

export interface ImportSubmittedProps {
  title?: string
  onClose: Function
  replaceable?: boolean
  onDone?: Function
}

export const ImportSubmittedFulfillmentOrderPopup = (props: ImportSubmittedProps) => {
  const { replaceable } = props
  const [modalState, setPortableModal] = useRecoilState(portableModalState)
  const [open, setOpen] = useState(true)
  const [date, setDate] = useState<any>()
  const [replaceFulfillmentDateInFile, setReplaceFulfillmentDateInFile] = useState(false)

  useEffect(() => {
    setPortableModal({
      open,
      size: "lg",
      title: t(Labels.import_submitted_request_from_excel),
      content: (
        <ModalContent>
          <UploadForm
            title={t("")}
            multi={true}
            description={t(Labels.import_submitted_order_from_excel_desc)}
            onSubmit={handleSubmit}
            templateUrl={"https://docs.google.com/spreadsheets/d/1qQvq29GlzJLjVz8R9HA4UTEFcaX7w3vcXPgWsFz8by4/edit?usp=sharing"}
            onCancel={() => setOpen(false)}
            replaceable={replaceable}
          >
            <>
              <Label>{Labels.fulfillment_date}</Label>
              <DateInput
                value={date}
                onChange={(date: Date) => setDate(date)}
                className="w-100 inputDatePicker mb-3"
              />
              <div>
                <FormGroup check inline className="ms-1">
                  <Input
                    type='checkbox'
                    className='form-check-Input font-size-16'
                    onChange={(event) => {
                      setReplaceFulfillmentDateInFile(event.target.checked)
                    }}
                    />
                  <Label check className="mt-1 fw-normal">{t(Labels.replace_fulfillment_date_in_file)}</Label>
                </FormGroup>
              </div>
            </>
          </UploadForm>
        </ModalContent>
      ),
    })

    if (!open) {
      props.onClose()
    }
  }, [open, date, replaceFulfillmentDateInFile])

  const handleDownload = (data: any, fileNamePrefix: string) => {
    AdminUtils.startAutoDownloadOutputFile(data, fileNamePrefix)
  }

  const handleSubmit = async (selectedFiles: any[], replaceable: boolean) => {
    let importResult: HandleFileResultType = null as any
    let importedEditor: ImportedResultEditor = null as any
    let fileNamePrefix: string = ""

    const dataCheckFulfillmentDate = date ? {fulfillmentDate: Dates.toDefaultLocaleDateString(date)} : {}
    let data: any = await FulfillmentOrderActions.importSubmittedFulfillmentOrder(selectedFiles, replaceable, { ...dataCheckFulfillmentDate, replaceFulfillmentDateInFile })
    importResult = AdminUtils.handleImportResponse(data, "import_submitted_order_result")
    importedEditor = {
      basePath: RouteNames.FULFILLMENT_ORDERS,
      editType: BulkEditorView.FULFILLMENT_ORDER,
    }
    if (importResult?.success) {
      props.onDone && props.onDone()
      setPortableModal({
        ...modalState,
        open: true,
        size: 'lg',
        title: t(Labels.import_result),
        preventToggle: true,
        content: <ImportResultModal importResult={importResult} editor={importedEditor} onClose={props.onClose} />,
      })
      return ;
    }
    if (data.error) {
      Toasts.error(data.error?.message || data.error?.code)
    } else {
      props.onDone && props.onDone()
      setPortableModal({
        ...modalState,
        open: true,
        size: "sm",
        title: t(Labels.import),
        preventToggle: true,
        style: { minWidth: 450 },
        content: <ImportResultModalAsync onClose={props.onClose} />,
      })
    }
  }
  return <></>
}