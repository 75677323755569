import { Labels } from "common/labels"
import { SelectOption } from "common/types"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import { t } from "core/translations"
import { ProductServices } from "data/services/product.service"
import { VendorDto } from "pages/products/components/vendor.select"
import { useState } from "react"
import Select from "react-select"
import AsyncSelect from "react-select/async"



export interface BulkUpdateVendorSelectPopupProps {
    options?: any[]
    onSubmit: Function
    onDone?: Function
    confirmText?: string
  }
  export const BulkUpdateVendorSelectPopup = ({ options = [], onSubmit, onDone,  confirmText }: BulkUpdateVendorSelectPopupProps) => {
    const [value, setValue] = useState<any>(undefined);
  
    return (
      <>
        <ModalContent>
              <AsyncSelect
                placeholder={t(Labels.choose_vendor)}
                defaultOptions={true}
                name="option_value"
                isSearchable={true}
                getOptionValue={(option: VendorDto) => option ? option?.username : ''}
                getOptionLabel={(option: VendorDto) => option ? (option?.name ? `${option?.username} - ${option?.name}` : `${option?.username}` ) : ''}
                value={value}
                loadOptions={(searchText: any) => ProductServices.searchByUsername(searchText)}
                onChange={(option: any) => setValue(option)}
              />
        </ModalContent>
        <ModalButtons
          confirmText={confirmText}
          confirm={ async () => {
            if(value != null && value != undefined) {
              await onSubmit(value.value == null || value.value == undefined ? value : value.value)
              onDone && onDone()
            }
          }}
        />
      </>
    )
  }
  