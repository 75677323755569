import { Messages } from 'common/messages';
import * as Yup from "yup";
import { t } from "../../../core/translations";
import { Labels } from "../../../common/labels";
import { TestContext } from "yup/lib/util/createValidation";
import { isEmpty, omit } from 'lodash';


export const productTypeSchema = Yup.object({
  code: Yup.string().required(t(Labels.code_required)),
  name: Yup.string().required(t(Labels.name_required)),
  regularPrice: Yup.string().required(t(Labels.regular_price_required)),
  salePrice: Yup.number().required(t(Messages.sale_price_validation)),
  productionTime: Yup.string().required(t(Messages.estimate_production_time_required)),
  group: Yup.string().nullable(),
  // fulfillProduct: Yup.object({
  //   id: Yup.number().min(1, t(Labels.fulfill_product_select_required))
  // }),
  productTypeKey: Yup.string().required(t(Labels.product_type_option_required)),
  // productTypeValues: Yup.array().nullable().min(1, t(Labels.product_type_values_required)),
  variantOptions: Yup.array().min(1, t(Labels.variant_options_required))
    .of(
      Yup.object({}).test("uniqueOptionName", (value: any, context: TestContext) => {
        const listOptionSameName = context.parent?.filter((item: any, idx: number) => item.name === value.name)
        if (listOptionSameName?.length > 1) {
          return context.createError({ message: t(Labels.optionName_is_unique)})
        }
        return true
      })
    ),
  fulfillVariantMapper: Yup.array().min(0).nullable()
  .of(
    Yup.object({
      fulfillVariantId: Yup.string(),
      productTypeValue: Yup.string()
    }).test('emptyLastchildFulfillVariantMapper', (value: any, context: TestContext) => {
      if(isEmpty(value)) {
        return true
      }
      else{
        if(!value?.fulfillVariantId && value?.productTypeValue){
          return context.createError({ message: t(Labels.fulfill_variant_select_required) })
        }
        if(!value?.productTypeValue && value?.fulfillVariantId){
          return context.createError({ message: t(Labels.product_type_value_required) })
        }
        return true
      }
    })
  ),
  fulfillOptions: Yup.array().of(Yup.object({
    fulfillVariantId: Yup.string().test('requiredFulfillVariantId', (value: any, context: TestContext) => {
      const newFulfillVariant = omit(context.parent, ['fulfillVariant', 'idx'])
      if(!newFulfillVariant?.fulfillVariantId && Object.keys(newFulfillVariant).length > 0){
        return context.createError({ message: t(Labels.fulfill_variant_select_required) })
      }
      return true
    }),
  //   variantOptions: Yup.array().test('checkValuefulfillVariantId', (value: any, context: TestContext) => {
  //     const valueFulfillVariantId = context.parent?.fulfillVariantId
  //     if(valueFulfillVariantId && !(value?.length > 0)){
  //       return context.createError({ message: t(Labels.fulfill_variant_select_required) })
  //     }
  //     if(!valueFulfillVariantId && value?.length > 0){
  //       return context.createError({ message: t(Labels.fulfill_variant_select_required) })
  //     }
  //     return true
  //   })
  })).nullable()
    
})