import { withPermission } from "common/hooks/use-permission";
import { Logger } from "core/logger";
import { Filter } from "core/rest-api/query-builder";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { FulfillmentInvoiceDto, FulfillmentInvoiceService, FulfillmentInvoiceServiceApiPath, FulfillmentInvoiceServices } from "../services/fulfillment-invoice.service"
import { CrudActions } from "./crud.action"


export enum FulfillmentInvoiceActionEnum {
  CHANGE_FULFILL_SERVICE_STATUS = 'change_fulfill_service_status',
  CHANGE_VENDOR_STATUS = 'change_vendor_status'
}

class FulfillmentInvoiceAction extends CrudActions<FulfillmentInvoiceService, FulfillmentInvoiceDto, FulfillmentInvoiceDto> {
  constructor() {
    super(FulfillmentInvoiceServices)
  }

  
  async searchById(searchText: string) {
    let filter: Filter<FulfillmentInvoiceDto> = {}
    if (searchText) {
      filter.id = { contains: searchText }
    }
    let pageData = await FulfillmentInvoiceServices.query({ filter, page: 1 });
    return pageData?.pageItems || [];
  }

  async changeVendorStatus(ids: string[] | number[], vendorInvoiceStatus: any) {
    return super.runBulkAction(FulfillmentInvoiceServiceApiPath.ACTIONS, ids, FulfillmentInvoiceActionEnum.CHANGE_VENDOR_STATUS, {
      vendorInvoiceStatus,
    })
  }

  async changeFulfillInvoiceStatus(ids: string[] | number[], fulfillInvoiceStatus: any) {
    return super.runBulkAction(FulfillmentInvoiceServiceApiPath.ACTIONS, ids, FulfillmentInvoiceActionEnum.CHANGE_FULFILL_SERVICE_STATUS, {
      fulfillInvoiceStatus,
    })
  }

  async importSettledInvoice( files: any, payload?: Record<string, any>) {
    try {
      const formData = new FormData();
      files.forEach((file: any) => formData.append('files', file))
      if(payload){
        for (const [key, value] of Object.entries(payload)) {
          formData.append(key, value)
        }
      }
      return await FulfillmentInvoiceServices.importSettledInvoice(formData)
    } catch (e) {
      Logger.warn('import settled invoice request failed: ', e)
    }

  }
}

export const FulfillmentInvoiceActions = new FulfillmentInvoiceAction()
