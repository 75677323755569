import { CrudService } from "./crud.service";
import { apiBaseUrls } from "data/rest-api-config";
import { RequestOptions } from "core/rest-api/rest.api";


export interface SourceDto {
  username?: string
  user?: any,
  id?: string,
  source?: string 
}

export const SourceServiceApiPath = {
  BASE: "/user/utm",
  WITH_ID: "/user/utm/{id}",
  QUERY: "/user/utm/query",
  BULK: "/user/utm/bulk",
  IMPORT_SOURCE: '/user/utm/import'
}

export class SourceService extends CrudService<any, any, any> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: SourceServiceApiPath.WITH_ID,
        updateById: SourceServiceApiPath.WITH_ID,
        deleteById: SourceServiceApiPath.WITH_ID,
        query: SourceServiceApiPath.QUERY,
        base: SourceServiceApiPath.BASE,
        deleteMany: SourceServiceApiPath.BULK,
        updateMany: SourceServiceApiPath.BULK,
      },
    })
  }
  async handleImport(data: FormData, apiPath: string, options?: RequestOptions) {
    let response = await this.restApi.post(apiPath, {
      data: data,
      timeout: 60 * 60000,
      ...(options || {})
    })
    return response
  }

  importSources(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    return this.handleImport(data, `${SourceServiceApiPath.IMPORT_SOURCE}?replace_existed=${replaceExisted}`, options)
  }

  async handleCreateSource(data: any, options: RequestOptions = {}) {
    let response = await this.restApi.post(SourceServiceApiPath.BASE, { data })
    return this.returnDataOrThrow(response)
  }

}

export const SourceServices = new SourceService()
