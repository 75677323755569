import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { FilterOption, SearchOption } from "../../components/table/TableFilter";
import { ActionOption } from "../../components/table/SelectedRowAction";
import Breadcrumb from "../../components/layout/Breadcrumb";
import { t } from "../../core/translations";
import RemoteTable, { createDeleteAction } from "../../components/table/RemoteTable";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { RouteNames } from "../../routes";
import { fulfillProductQueryState, fulfillProductSelector } from "../../data/atoms/fulfill-product.atom";
import { FulfillProductActions } from "../../data/actions/fulfill-product.action";
import { Labels } from "../../common/labels";
import { ImportModal, ImportType } from "./components/import-modal";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import { createDocumentPageName } from "../../utils/utils";
import { allFulfillServicesSelector } from "data/atoms/fulfill-service.atom";
import { createLinkEmbedParam } from "utils/embed";

export const FulfillProductsPage = () => {
  const [importType, setImportType] = useState<ImportType>(ImportType.NULL)
  const refresh = useRecoilRefresher_UNSTABLE(fulfillProductSelector);
  const [FulfillServices, setFulfillServices] = useState([])
  const fulfillServiceLoadable = useRecoilValueLoadable(allFulfillServicesSelector)

  useEffect(() => { document.title = createDocumentPageName(t(Labels.fulfill_products)) }, [])

  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.FULFILL_PRODUCTS}/${row.id}`)}> {`${row.id}`}</Link>
        </>;
      }
    },
    {
      dataField: "name",
      text: t(Labels.name),
    },
    {
      dataField: "optionKeys",
      text: t(Labels.option_keys),
      formatter: (cell: any, row: any) => {
        return cell?.join ? cell.join(', ') : ''
      }
    },
    {
      dataField: "productTypeKey",
      text: t(Labels.product_type_key),
      headerStyle: { width: 220 },
    },
    {
      dataField: "fulfillService",
      text: t(Labels.fulfill_service),
      headerStyle: { width: 220 },
      formatter: (cell: any, row: any) => {
        return `${cell?.name || ''}`
      }
    }
  ];

  let searchOptions: SearchOption[] = [
    {
      field: "name",
      label: t(Labels.name)
    }
  ];

  let filterOptions: FilterOption[] = [
    {
      name: "fulfillServiceId",
      label: t(Labels.fulfill_services),
      type: "checkbox",
      value: FulfillServices?.map((fs: any) => ({ value: fs.id, label: fs.name })) || [],
    },
  ]

  
  useEffect(() => {
    if (fulfillServiceLoadable.state == "hasValue") {
      setFulfillServices(fulfillServiceLoadable.contents || {})
    }
  }, [fulfillServiceLoadable.state])


  const actionOptions: ActionOption[] = [
    createDeleteAction(FulfillProductActions, ResourceEntities.fulfillProductEntity)
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={t(Labels.fulfill_products)}
          >
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillProductEntity}>
              <Button
                outline
                color="secondary"
                className="me-2"
                onClick={() => {
                  setImportType(ImportType.FULFILL_PRODUCT)
                }}>
                <i className="bx bx-import font-size-16 align-middle me-1"></i>
                {t(Labels.import)}
              </Button>
            </WithPermission>
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillProductEntity}>
              <Link {...createLinkEmbedParam(`${RouteNames.FULFILL_PRODUCTS}/create`)}>
                <Button
                  type="button"
                  className="btn btn-success"
                >
                  <i className="bx bx-plus font-size-16 align-middle"></i>
                  {t(Labels.create_new)}
                </Button>
              </Link>
            </WithPermission>
          </Breadcrumb>
          <ImportModal replaceable={true} type={importType} onClose={() => setImportType(ImportType.NULL)} />
          <RemoteTable
            dataLoader={[fulfillProductSelector, fulfillProductQueryState]}
            searchOptions={searchOptions}
            filterOptions={filterOptions}
            tabOptions={[]}
            actionOptions={actionOptions}
            searchHint={t(Labels.search_by_name)}
            defaultSearchField={"name"}
            keyField={"id"}
            columns={columns}
            refresh={refresh}
            filterKey={'fulfillProducts'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}
