import { Messages } from 'common/messages';
import * as Yup from "yup";
import { t } from "../../../core/translations";
import { Labels } from "../../../common/labels";
import { TestContext } from "yup/lib/util/createValidation";
import { isEmpty, omit, sortedUniq, uniq } from 'lodash';
import { string } from 'prop-types';


export const productTemplateSchema = Yup.object({
  sku: Yup.string().required(t(Messages.sku_validation)),
  name: Yup.string().required(t(Labels.name_required)),
  minPrice: Yup.number().required(t(Messages.min_price_validation)),
  maxPrice: Yup.number().required(t(Messages.max_price_validation)).test('checkValueLessMinPrice', (value: any, context: TestContext) => {
    if(+value < +context.parent?.minPrice ){
      return context.createError({ message: t(Labels.max_price_less_min_price) })
    }
    return true
  }),
  description: Yup.string().test('requiredDescription', t(Messages.description_validation), (value: any, context: TestContext) => {
    let temp = document.createElement("DIV");
    temp.innerHTML = value || '';
    let descriptionTranser = temp.textContent || temp.innerText || "";
    if (descriptionTranser?.trim()) {
      return true
    }
    return false
  }),
  variants: Yup.array().min(1, t(Labels.variant_options_required))
    .of(
      Yup.object({
        title: Yup.string(),
        price: Yup.string(),
        sku: Yup.string(),
        options: Yup.array()
      }).test("uniqueOptionName", (value: any, context: TestContext) => {
        if (!value?.sku || !value?.price || !value?.title) {
          return context.createError({ message: t(Labels.input_variant_item_required) })
        }
        return true
      })
    ).test('checkVariantDuplicate', (value: any, context: TestContext) => {
      let lengthOfDuplicateElementRemoved = uniq(value?.map((item: any) => item?.title))?.length
      if (lengthOfDuplicateElementRemoved != value?.length) {
        return context.createError({ message: t(Messages.variant_duplicated) })
      }
      return true
    }),
  options: Yup.array().of(
    Yup.object({
      name: Yup.string(),
      optionValues: Yup.array()
    }).test('checkOptions', (value: any, context: TestContext) => {
      if (isEmpty(value) || isEmpty(value?.name)) {
        return context.createError({ message: t(Labels.input_option_item_required) })
      }
      return true
    })
  ),
  // options: Yup.array().of(Yup.object({
  //   title: Yup.string().test('requiredFulfillVariantId', (value: any, context: TestContext) => {
  //     const newFulfillVariant = omit(context.parent, ['fulfillVariant', 'idx'])
  //     if(!newFulfillVariant?.fulfillVariantId && Object.keys(newFulfillVariant).length > 0){
  //       return context.createError({ message: t(Labels.fulfill_variant_select_required) })
  //     }
  //     return true
  //   }),
  // //   variantOptions: Yup.array().test('checkValuefulfillVariantId', (value: any, context: TestContext) => {
  // //     const valueFulfillVariantId = context.parent?.fulfillVariantId
  // //     if(valueFulfillVariantId && !(value?.length > 0)){
  // //       return context.createError({ message: t(Labels.fulfill_variant_select_required) })
  // //     }
  // //     if(!valueFulfillVariantId && value?.length > 0){
  // //       return context.createError({ message: t(Labels.fulfill_variant_select_required) })
  // //     }
  // //     return true
  // //   })
  // })),
    
})