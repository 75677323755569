import React, { useEffect, useState } from "react";
import { appEventEmitter, EventName, FallbackError } from "../../core/event/app-event.emitter";
import { ErrorBoundary } from "react-error-boundary";
import { HttpStatus } from "../../core/rest-api/http-status";
import { useSetRecoilState } from "recoil";
import { userSelector, userTokenSelector } from "../../data/atoms/auth.atom";
import FallbackErrorPage from "../page/FallbackErrorPage";

export const AsyncErrorBoundary = ({ children, resetErrorBoundary }: any) => {
  let [error, setError] = useState<any>(null)
  let setUser = useSetRecoilState(userSelector)
  let setToken = useSetRecoilState(userTokenSelector)

  const handleError = (errorData: FallbackError) => {
    if(!errorData.handled) {
      errorData.handled = true
      setError(error)
    }
  }

  const onResetErrorBoundary = () => {
    setError(null)
    resetErrorBoundary && resetErrorBoundary()
  }

  useEffect(() => {
    appEventEmitter.on(EventName.ERROR, handleError)
    return () => {
      appEventEmitter.off(EventName.ERROR, handleError)
    }
  }, [])

  if(error) {
    if(error.status == HttpStatus.UNAUTHORIZED) {
      setToken('')
      setUser('')
      return null
    } else {
      return <FallbackErrorPage error={error} resetErrorBoundary={onResetErrorBoundary} forceBackToHome={true}/>
    }
  } else {
    return <>
      <ErrorBoundary
        resetKeys={[error]}
        onReset={onResetErrorBoundary}
        fallbackRender={({error, resetErrorBoundary}) => (
          <FallbackErrorPage
            error={error}
            resetErrorBoundary={resetErrorBoundary}
            forceBackToHome={true}/>
        )}
      >
        { children }
      </ErrorBoundary>
    </>
  }
}