import { CrudActions } from "./crud.action"
import { Toasts } from "core/notification/notifications"
import { t } from "core/translations"
import { Messages } from "common/messages"
import { ReportDto, ReportService, ReportServices } from "data/services/reports.service"
import { RequestOptions } from "core/rest-api/rest.api"
import { Logger } from "core/logger"

class ReportAction extends CrudActions<ReportService, ReportDto, ReportDto> {
  constructor() {
    super(new ReportService())
  }

  async cancelImport(id: string, options?: RequestOptions) {
    try {
      let data = await ReportServices.cancelImport(id, options)
      Toasts.success(t(Messages.your_request_execute_successfully))
      return data
    } catch (error) {
      Logger.error(error)
    }
  }

  async retryImport(id: string, options?: RequestOptions) {
    try {
      let data = await ReportServices.retryImport(id, options)
      Toasts.success(t(Messages.your_request_execute_successfully))
      return data
    } catch (error) {
      Logger.error(error)
    }
  }
}

export const ReportActions = new ReportAction()
