import { Label } from "reactstrap"

interface OptionalLabelProps {
  label?: string
  className?: string
  style?: any
  tooltip?: string
  children?: any
}

export const OptionalLabel = (props: OptionalLabelProps) => {
  const {label, className, style, children } = props
  return (
    <>
      <div className={className} style={style}>
        <Label className=''>{label || children}</Label>
        <span> (optional)</span>
      </div>
    </>
  )
}