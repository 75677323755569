import { useRecoilState, useRecoilValue } from "recoil";
import { bulkEditFulfillOrderAtom, fulfillOrderSelector } from "../../../data/atoms/fulfill-order.atom";
import { isEmpty, omit } from "lodash";
import { BulkEditorView } from "../../../components/bulk/bulk-editor.type";
import { skuSubfixOptions } from "./sku-subfix.options";
import { Strings } from "../../../utils/strings";
import React, { Fragment, useState } from "react";
import {
  actionColumns,
  BaseBulkEditorProps,
  BulkEditor,
  defaultSubmitDataBuilder,
  ROW_HEIGHT
} from "../../../components/bulk/BulkEditor";
import { Column, EditorProps, TextEditor } from "react-data-grid";
import { Link } from "react-router-dom";
import { t } from "core/translations";
import { Input, Row } from "reactstrap";
import FulfillVariantSelect, { getFulfillProductLabel } from "./fulfill-variation.select";
import { TooltipComponent } from "../../../components/common/TooltipComponent";
import { FulfillOrderDto } from "../../../data/services/fulfill-order.service";
import { BadgeStatus, StatusSelect } from "../../../components/common/StatusComponent";
import {
  FulfillStatusIssueColor,
  FulfillStatusIssueName,
  FulfillStatusIssueValues,
  FulfillStatusValues
} from "../../../types/fulfill-status.type";
import { omitFlattenNestedObject } from "../../../utils/utils";
import { Logger } from "../../../core/logger";
import { Labels } from "common/labels";
import { RouteNames } from "routes";
import { createLinkEmbedParam } from "utils/embed";

const addressColumns: Column<any, any>[] = [
  {
    frozen: true,
    key: 'id',
    name: 'ID',
    // eslint-disable-next-line react/display-name
    formatter: ({ row }: { row: FulfillOrderDto }) => (
      <Link {...createLinkEmbedParam(`/fulfillments/orders/${row?.id}`)} className='text-info fw-bold'>
        {`${row.id}`}
      </Link>
    )
  },
  {
    frozen: true,
    key: 'orderNumber',
    name: t(Labels.order_number),
    minWidth: 150
  },
  {
    frozen: false,
    key: 'sku',
    name: t(Labels.fulfill_sku),
    minWidth: 150
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.firstName',
    name: t(Labels.first_name),
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.lastName',
    name: t(Labels.last_name),
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.phone',
    name: t(Labels.phone),
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.address1',
    name: t(Labels.address_1),
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.address2',
    name: t(Labels.address_2),
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.company',
    name: t(Labels.company),
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.city',
    name: t(Labels.city),
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.provinceCode',
    name: t(Labels.province_code),
    minWidth: 130
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.countryCode',
    name: t(Labels.country_code),
    minWidth: 120
  },
  {
    editable: true,
    editor: TextEditor,
    key: 'shippingAddress.zip',
    name: t(Labels.zip_code),
  },
  ...actionColumns
];

const normalColumns: Column<any, any>[] = [
  {
    frozen: true,
    key: 'id',
    name: 'ID',
    // eslint-disable-next-line react/display-name
    formatter: ({ row }: { row: FulfillOrderDto }) => (
      <Link {...createLinkEmbedParam(RouteNames.ORDER_ITEM_DETAIL.replace(":id", row?.id as string ))} className='text-info fw-bold'>
        {`${row.id}`}
      </Link>
    )
  },
  {
    frozen: true,
    key: 'orderNumber',
    minWidth: 120,
    name: t(Labels.order_number)
  },
  {
    key: 'productName',
    name: t(Labels.product_name),
    cellClass: 'wrap-content-column',
    minWidth: 400
  },
  
  {
    key: 'sku',
    name: t(Labels.fulfill_sku),
    editable: true,
    minWidth: 150,
    editor: TextEditor
  },
  
  {
    editable: true,
    cellClass: 'react-data-grid-cell',
    key: 'customerNote',
    name: t(Labels.customer_note),
    minWidth: 200,
    editor: TextEditor
  },
  {
    editable: true,
    frozen: false,
    key: 'customize',
    minWidth: 100,
    name: t(Labels.customize),
    // eslint-disable-next-line react/display-name
    formatter: ({ row, onRowChange }: any = {}) => (
      <div className='text-center align-items-center'>
        <Input
          type='checkbox'
          className='form-check-Input font-size-16'
          defaultChecked={['1', 'true'].includes(`${row['customize']}`)}
          onChange={(event) => {
            onRowChange({ ...row, customize: event.target.checked, __changedKeys: ['customize'] }, true);
          }}
        />
      </div>
    ),
    editorOptions: {
      editOnClick: true
    }
  },
  {
    editable: true,
    cellClass: 'react-data-grid-cell',
    key: 'customText',
    name: t(Labels.customer_text),
    minWidth: 200,
    editor: TextEditor
  },
  // {
  //   editable: true,
  //   cellClass: 'react-data-grid-cell',
  //   editor: TextEditor,
  //   key: 'customText',
  //   name: t(Labels.custom_text),
  //   minWidth: 200,
  //   maxWidth: 500
  // },
  {
    key: 'productImage',
    name: t(Labels.images),
    // eslint-disable-next-line react/display-name
    formatter: ({ row }: { row: FulfillOrderDto }) => (
      !row.mockupUrl ? <p /> :
        <>
          <div className='d-flex gap-3 justify-content-center'>
            <a href={`${row.mockupUrl || '#'}`}
               className={row.mockupUrl ? 'text-success' : 'text-danger'}>
              <TooltipComponent tooltip={t(Labels.preview_image)}>
                <i className={`mdi ${row.mockupUrl ? 'mdi-cloud-check' : 'mdi-close-box'} font-size-18`}/>
              </TooltipComponent>
            </a>
            <a href={`${row.designUrl || '#'}`}
               className={row.designUrl ? 'text-success' : 'text-danger'}>
              <TooltipComponent tooltip={t(Labels.preview_image)}>
                <i className={`mdi ${row.designUrl ? 'mdi-cloud-check' : 'mdi-close-box'} font-size-18`} />
              </TooltipComponent>
            </a>
          </div>
        </>
    )
  },
  {
    frozen: false,
    key: 'statusIssue',
    name: t(Labels.status_issue),
    minWidth: 180,
    // eslint-disable-next-line react/display-name
    formatter: ({ row }: { row: FulfillOrderDto }) => {
      return <BadgeStatus status={row.statusIssue} color={FulfillStatusIssueColor} name={FulfillStatusIssueName}/>
    },
    // eslint-disable-next-line react/display-name
    editor: ({ row, onRowChange }: EditorProps<any>) => (
      <StatusSelect
        name='statusIssue'
        placeholder={t(Labels.status_issue_hint)}
        height={ROW_HEIGHT - 1}
        value={row.statusIssue}
        statusValues={FulfillStatusIssueValues}
        statusNames={FulfillStatusIssueName}
        autoFocus={true}
        defaultMenuIsOpen={true}
        onChange={(optionValue) => onRowChange({ ...row, statusIssue: optionValue }, true)}
      />
    ),
    editorOptions: { editOnClick: true }
  },
  {
    key: 'variationTitle',
    name: t(Labels.variantion),
    cellClass: 'wrap-content-column',
    minWidth: 160
  },
  {
    editable: true,
    key: 'fulfillVariant',
    frozen: false,
    name: t(Labels.fulfill_variant),
    minWidth: 280,
    // eslint-disable-next-line react/display-name
    formatter: ({ row }: { row: FulfillOrderDto }) => {
      return <>{getFulfillProductLabel({ ...row.fulfillVariant, fulfillService: row.fulfillService })}</>
    },
    // eslint-disable-next-line react/display-name
    editor: ({ row, onRowChange }: any = {}) => {
      return <FulfillVariantSelect
        embedHeight={ROW_HEIGHT - 1}
        value={row.fulfillVariant}
        onChange={(option: any) => {
          let changedData = {
            ...row,
            fulfillVariant: option,
            fulfillService: option.fulfillService,
            fulfillVariantId: option.id
          };
          onRowChange(changedData, true);
        }}
        select
        placeholder={t(Labels.select_fulfill_product_hint)}
      />;
    },
    editorOptions: { editOnClick: true }
  },
  {
    key: 'billingAddress',
    name: t(Labels.shipping_address),
    cellClass: 'wrap-content-column',
    minWidth: 320,
    // eslint-disable-next-line react/display-name
    formatter: ({ row }: { row: FulfillOrderDto }) => {
      return <>{Strings.getAddressWithName(row.shippingAddress)}</>
    },
  },
  {
    key: 'note',
    name: t(Labels.modify_note),
    cellClass: 'wrap-content-column',
    editable: true,
    minWidth: 150,
    editor: TextEditor
  },
  ...actionColumns
];

export const FulfillmentOrderBulkEditor = (props: BaseBulkEditorProps<FulfillOrderDto>) => {
  const nestedFieldsOptions: Record<any, any> = {
    [BulkEditorView.FULFILL_ORDER_SHIPPING_ADDRESS]: ['shippingAddress']
  }
  const [bulkEditingState, setBulkEditingState] = useRecoilState(props.bulkEditSelector);
  // const fulfillOrderPage = useRecoilValue(fulfillOrderSelector);

  const submitDataBuilder = (data: any) => {
    let newData = defaultSubmitDataBuilder(data, nestedFieldsOptions[props.editor])
    if(newData.newSupplierSku) {
      newData.sku = newData.newSupplierSku
      newData = omit(newData, 'newSupplierSku')
    }
    Logger.debug('submitDataBuilder', newData, data)
    return newData
  }

  const updateRowDataBuilder = (key: string, cellValue: any, rowData: any) => {
    let newData: any = {}
    if (cellValue == null || cellValue == undefined) return newData;

    if(key == 'fulfillVariant') {
      newData.fulfillVariantId = cellValue?.id
      newData.fulfillService = cellValue?.fulfillService
      newData.fulfillVariantName = cellValue?.variantName
    }

    if (key == "customText") {
      newData.customText = `${cellValue}`.trim(),
      newData.customFields = `${cellValue}`.trim().split("|")
        .map((str, index) => ({
          key: `custom${index + 1}`,
          label: `Custom${index + 1}`,
          value: str.trim()
        }))
    }

    return newData
  }

  const customRowDataBuilder = (currentRowData: any, newRowData: any) => {
    let customData: any = {}
    return customData
  };

  return (
    <Fragment>
      <BulkEditor
        { ...props }
        columns={{
          [BulkEditorView.FULFILL_ORDER]: normalColumns,
          [BulkEditorView.FULFILL_ORDER_SHIPPING_ADDRESS]: addressColumns
        }}
        nestedFields={nestedFieldsOptions}
        bulkEditSelector={ props.bulkEditSelector }
        submitDataBuilder={ submitDataBuilder }
        customRowDataBuilder={customRowDataBuilder}
        updateRowDataBuilder={updateRowDataBuilder}
      />
    </Fragment>
  );
};