import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import AuthMiddleware, { handleUnauthorized } from "./routes/route";
import AuthLayout from "./components/layout/AuthLayout";
import NonAuthLayout from "./components/layout/NonAuthLayout";
import { authProtectedRoutes, publicRoutes, RouteNames } from "./routes";

// Import scss
import "./assets/scss/theme.scss";
import "react-toastify/dist/ReactToastify.css";
import { AsyncErrorBoundary } from "./components/common/AsyncErrorBoundary";
import PortableModal from "./components/modal/portable-modal";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import {
  loginErrorState,
  permissionsSelector,
  userSelector,
  userTeamSelector,
  userTenantSelector,
  userTokenSelector
} from "data/atoms/auth.atom";
import { Keys } from "common/keys";
import { Storages } from "./utils/storages";
import { appEventEmitter, EventName } from "core/event/app-event.emitter";
import { HttpStatus } from "core/rest-api/http-status";
import { IdentityActions } from "./data/actions/auth.action";
import { SettingKeys, settingSelector } from "data/atoms/setting.atom";
import { Global } from "common/global";
import { Logger } from "core/logger";
import { ViewImgaes } from "components/modal/view-image-modal";
import { ViewExcel } from "components/modal/view-excel-modal";
import { navigateRouteEmbed } from "utils/embed";

const PortableComponent = () => {
  return (
    <>
      <ToastContainer />
      <PortableModal />
      <ViewImgaes />
      <ViewExcel />
    </>
  );
};

const App = (props: any) => {
  const [, setLoginError] = useRecoilState(loginErrorState);
  const [token, setToken] = useRecoilState(userTokenSelector);
  const [, setTenant] = useRecoilState(userTenantSelector);
  const [, setRoles] = useRecoilState(permissionsSelector);
  const [, setUser] = useRecoilState(userSelector);
  const [, setTeam] = useRecoilState(userTeamSelector);
  const setUserToken = useSetRecoilState(userTokenSelector);
  const history = useHistory();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const embedToken = params.get('embedToken');

  const resetIdentityData = () => {
    setUserToken("");
    setUser(null);
    setRoles(null);
    setTenant(null);
    setTeam(null)
  };

  // const settingDataLoadable = useRecoilValueLoadable(settingSelector(SettingKeys.WEB_ADMIN));
  // useEffect(() => {
  //   try {
  //     if (settingDataLoadable?.state === "hasValue") {
  //       let settings = settingDataLoadable?.contents
  //       Global.settings = {
  //         ...Global.settings,
  //         ...settings?.value
  //         // enableProductTemplateAutoVariation: false,
  //       }
  //     }
  //   } catch (err) {
  //     Logger.debug('error ', err)
  //   }
  // }, [settingDataLoadable?.state]);

  useEffect(() => {
    setRoles(Storages.getLocalJson(Keys.user));
    if(embedToken) {
      IdentityActions.login(
        { accessToken: embedToken},
        { setUser, setToken, setLoginError, setTenant, setRoles, setTeam }
      ).catch(() => handleUnauthorized(history, embedToken));
    } else {
      if (!embedToken && (token && token != "\"\"")) {
        IdentityActions.refresh({ setUser, setToken, setLoginError, setTenant, setRoles, setTeam })
          .then((logged: any) => {
            if (!logged) {
              resetIdentityData();
              history.replace(RouteNames.SIGN_IN);
            }
          }).catch(() => {
            resetIdentityData();
            history.replace(RouteNames.SIGN_IN);
          });
      }
    }
  }, []);

  // useStaticData(useRecoilValueLoadable(channelOrderSettingSelector)
  // useSetRecoilState(channelOrderSettingState))
  const handleError = (data: any) => {
    if (data?.status === HttpStatus.UNAUTHORIZED) {
      setRoles(null);
      setUser(null);
      handleUnauthorized(history, embedToken)
    }

    if(data?.status === HttpStatus.SERVICE_UNAVAILABLE) {
      window.location.replace("/maintenance")
    }
  };

  useEffect(() => {
    appEventEmitter.on(EventName.ERROR, handleError);
    return () => {
      appEventEmitter.off(EventName.ERROR, handleError);
    };
  }, []);
  return (
    <AsyncErrorBoundary>
      <React.Fragment>
        <Router>
          <Switch>
            {authProtectedRoutes.map((route, idx) => (
              <AuthMiddleware
                path={route.path}
                layout={embedToken ? NonAuthLayout : AuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))
            }

            {
              publicRoutes.map((route, idx) => (
                <AuthMiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))
            }
          </Switch>
        </Router>
        <PortableComponent />
      </React.Fragment>
    </AsyncErrorBoundary>
  );
};

export default App;
