import { flattenNestedObject, omitFlattenNestedObject, unflattenNestedObject } from "utils/utils";
import { BulkEditingState, BulkEditorView, defaultBulkEditingState } from "./bulk-editor.type";
import { RecoilState, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isEmpty, omit } from "lodash";
import DataGrid, { Column, CopyEvent, FillEvent, PasteEvent, RowsChangeData } from "react-data-grid";
import { t } from "../../core/translations";
import { TooltipComponent } from "../common/TooltipComponent";
import { Link } from "react-router-dom";
import "../../assets/scss/custom/components/_bulk-editor.scss";
import { Button, Card, CardBody } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import { bulkEditFulfillOrderAtom, fulfillOrderSelector } from "../../data/atoms/fulfill-order.atom";
import { Logger } from "../../core/logger";
import { FulfillOrderActions } from "../../data/actions/fulfill-order.action";
import { Confirmations } from "../../core/notification/notifications";
import { Labels } from "common/labels";
import { Messages } from "common/messages";
import { HiOutlineRefresh } from "react-icons/hi";

export const createCustomSelectStyles = (embedHeight: any) => {
  let styles = {
    input: (base: any) => ({
      ...base,
      opacity: "1 !important",
      input: {
        opacity: "1 !important"
      }
    }),
    menu: (provided: any) => ({
      ...provided,
      height: 200,
      marginTop: 0
    }),
    menuList: (provided: any) => ({
      ...provided,
      height: 200,
      marginTop: 0
    })
  } as any;
  if (embedHeight) {
    let embedStyles = {
      margin: 0,
      padding: 0,
      paddingBottom: 0,
      borderRadius: 0,
      height: embedHeight,
      maxHeight: embedHeight,
      minHeight: embedHeight
    }
    styles = {
      ...styles,
      control: (base: any) => ({ ...base, ...embedStyles }),
      input: (base: any) => ({ ...base, ...embedStyles }),
      container: (base: any) => ({ ...base, ...embedStyles }),
      valueContainer: (base: any) => ({ ...base, ...embedStyles, paddingLeft: 4}),
      singleValue: (base: any) => ({ ...base, ...embedStyles, paddingLeft: 1}),
      multiValue: (base: any) => ({
        ...base,
        height: 30,
        marginTop: 0,
        marginLeft: 0,
        marginRight: 2,
        marginBottom: embedHeight - 36,
        maxHeight: embedHeight
      }),
      multiValueLabel:  (base: any) => ({
        ...base,
        paddingLeft: 3,
        paddingRight: 0,
        height: 28,
        lineHeight: "24px"
      })
    };
  }

  return styles;
};

export const ROW_HEIGHT = 38;

export const actionColumns = [
  {
    key: "_bulkUpdated",
    minWidth: 80,
    name: t(Labels.actions),
    // eslint-disable-next-line react/display-name
    formatter: ({ row, onRowChange }: any = {}) => (
      <div className="text-sm-center">
        {!row["_bulkUpdated"] ? null :
          <Link
            to={"#"}
            onClick={() => onRowChange({ ...row, __changedKeys: ["__bulkResetRowChange"] }, true)}
          >
            <TooltipComponent tooltip={t(Labels.reset_change)}>
              <i className="mdi mdi-history font-size-20" />
            </TooltipComponent>
          </Link>
        }
      </div>
    )
  }
];

export interface BaseBulkEditorProps<T> {
  data: any[]
  editor: BulkEditorView
  bulkEditSelector: RecoilState<BulkEditingState>,
}

export interface BulkEditorProps<T> extends BaseBulkEditorProps<T> {
  columns: Record<string, Column<T, any>[]>
  customOptions?: any,
  nestedFields?: Record<string, string[]>
  customRowDataBuilder: (currentRowData: T, newRowData: T) => { customOptions: any, newRowData: any }
  updateRowDataBuilder: (key: string, cellValue: any, rowData: T) => any
  submitDataBuilder?: (data: T, nestedObjectKeys?: string[]) => any
}

export const defaultSubmitDataBuilder = (data: any, nestedKeys: string[]): any => omitFlattenNestedObject(data, nestedKeys)

export const BulkEditor = (props: BulkEditorProps<any>) => {
  let { submitDataBuilder = defaultSubmitDataBuilder } = props
  const [bulkEditingState, setBulkEditingState] = useRecoilState<BulkEditingState>(props.bulkEditSelector);
  const columns = props.columns[props.editor] || [];
  const nestedObjectKeys = props.nestedFields && props.nestedFields[props.editor] || [];
  let data = props.data || []

  const createGridRowData = () => {
    let rows: any[] = [];
    let updateDataById = {} as any;
    let autoSaveUpdatedData = false;

    for (let i = 0; i < data.length; i++) {
      let row = {
        ...data[i],
        ...flattenNestedObject(data[i], nestedObjectKeys)
      };

      let updatedData = { ...(bulkEditingState.updatedData[row.id] || {}) };
      let customUpdateData = props.customRowDataBuilder ? props.customRowDataBuilder(row, updatedData) : updatedData;
      updatedData = { ...updatedData, ...(customUpdateData || {}) };
      autoSaveUpdatedData = !isEmpty(customUpdateData);

      if (!isEmpty(updatedData)) {
        updateDataById[row.id] = updatedData;
        rows.push({
          ...row,
          ...updatedData,
          _bulkUpdated: true
        });
      } else {
        rows.push({
          ...row,
          _bulkUpdated: false
        });
      }
    }

    if (autoSaveUpdatedData) {
      setImmediate(() => {
        setBulkEditingState((current: BulkEditingState) => {
          let updatedData = { ...current.updatedData, ...updateDataById }
          let submitData = submitDataBuilder(updatedData, nestedObjectKeys)
          return { ...current, updatedData, submitData}
        });
      });
    }

    return rows;
  };

  const onRowsChange = (rows: any, data: RowsChangeData<any>) => {
    let { indexes, column } = data;

    setBulkEditingState((current: any) => {
      let newBulkUpdateData = { ...current.updatedData };
      for (let index of indexes) {
        let rowData = rows[index];
        if (!rowData) continue;

        if (rowData.__changedKeys?.includes("__bulkResetRowChange")) {
          rowData.__changedKeys.length = 0; // Reset array
          newBulkUpdateData = omit(newBulkUpdateData, rowData.id);
          continue;
        }

        let changedData = {} as any;
        if (column) {
          let value = rowData[column.key];
          if (value != null || value != undefined) {
            changedData[column.key] = value;
            let customUpdateData = props.updateRowDataBuilder ? props.updateRowDataBuilder(column.key, value, rowData) : {};
            changedData = {
              ...changedData,
              ...(customUpdateData || {})
            };
          }
        } else if (rowData.__changedKeys) {
          for (let key of rowData.__changedKeys) {
            changedData[key] = rowData[key];
          }
        }

        let nestedChangeData = unflattenNestedObject(rowData, changedData, nestedObjectKeys);
        newBulkUpdateData = {
          ...newBulkUpdateData,
          [rowData.id]: { ...(bulkEditingState.updatedData[rowData.id] || {}), ...changedData, ...nestedChangeData }
        };
      }

      let newSubmitData = submitDataBuilder(newBulkUpdateData, nestedObjectKeys)
      return {
        ...current,
        updatedData: newBulkUpdateData,
        submitData: newSubmitData
      };
    });
  };

  function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<any>) {
    Logger.log("handleFill: ", columnKey, sourceRow, targetRow);
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

  const handlePaste = ({
                         sourceColumnKey,
                         sourceRow,
                         targetColumnKey,
                         targetRow
                       }: PasteEvent<any>) => {
    const incompatibleColumns = ["id"];
    if (sourceColumnKey !== targetColumnKey
      && (incompatibleColumns.includes(targetColumnKey) || incompatibleColumns.includes(sourceColumnKey))) {
      return targetRow;
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
  };

  function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<any>): void {
    if (window.isSecureContext) {
      let value = sourceRow[sourceColumnKey];
      navigator.clipboard.writeText(value);
    }
  }

  function rowKeyGetter(row: any) {
    return row.id;
  }

  return (
    <div style={{ height: "100%" }}>
      <Card>
        <DataGrid
          columns={columns}
          rows={createGridRowData()}
          rowKeyGetter={rowKeyGetter}
          onRowsChange={onRowsChange}
          onFill={handleFill}
          onCopy={handleCopy}
          onPaste={handlePaste}
          rowHeight={ROW_HEIGHT}
          defaultColumnOptions={{ resizable: true }}
          className={"fill-grid rdg-dark rdg-light"}
          style={{ height: "100%", blockSize: "100%" }}
          rowClass={(row: any) => (row._bulkUpdated ? "react-data-grid-updated-row" : "react-data-grid-normal-row")}
        />
      </Card>
    </div>
  );
}


export interface DefaultBulkEditActionProps<T> {
  bulkEditSelector: RecoilState<T>
  dataRefresh: () => void
  onSubmit: (data: Record<string, T>) => Promise<boolean | undefined> // true if updated, false otherwise
  onExit: () => void
}

export const DefaultBulkEditAction = (props: DefaultBulkEditActionProps<any>) => {
  const [updating, setUpdating] = useState(false)
  const [bulkEditingState, setBulkEditingState] = useRecoilState<BulkEditingState>(props.bulkEditSelector);

  const handleSaveBulkUpdateData = async () => {
    try {
      setUpdating(true)
      let updated = await props.onSubmit(bulkEditingState.submitData)
      if(updated) {
        setBulkEditingState((current: any) => ({ ...current, ...defaultBulkEditingState }))
        props.dataRefresh()
      }
    } finally {
      setUpdating(false)
    }
  }

  const handleCancelBulkEdit = () => {
    if(!isEmpty(bulkEditingState.submitData)) {
      Confirmations.confirm(
        t(Messages.do_you_want_to_discard_the_updated_information),
        () => {
          setBulkEditingState(defaultBulkEditingState)
          props.onExit()
        }
      )
    } else {
      setBulkEditingState(defaultBulkEditingState)
      props.onExit()
    }
  }

  return (
    <Fragment>
      <Button color="info" className="me-2" disabled={updating} onClick={() => props.dataRefresh()}>
        <HiOutlineRefresh size={16} className="me-1 mb-1" />
        {t(Labels.refresh)}
      </Button>
      <Button
        color="success"
        className="me-2"
        disabled={updating}
        onClick={handleSaveBulkUpdateData}
      >
        <i className="bx bx-save pt-1 me-1"></i>
        {t(Labels.save)}
      </Button>
      <Button
        color="danger"
        className="me-2"
        disabled={updating}
        onClick={handleCancelBulkEdit}
      >
        <i className="bx bx-x pt-1 me-1"></i>
        {t(Labels.close)}
      </Button>
    </Fragment>
  )
}