import Chip from "../common/Chip";
import { Badge, Button, ButtonGroup, Col, Form, Input, InputGroup, Nav, NavItem, NavLink, Row } from "reactstrap";
import { isEmpty, omit, pick } from "lodash";
import { useEffect, useState } from "react";
import { SelectOption } from "../../common/types";
import { t } from "../../core/translations";
import { Labels } from "../../common/labels";
import {
  Comparison, ComparisonFilter,
  FieldComparison,
  Filter,
  OperatorKey,
  QueryComparisonGroup,
  QueryParams
} from "../../core/rest-api/query-builder";
import { Logger } from "../../core/logger";
import { ModalTableFilter } from "./ModalTableFilter";
import { useFormik } from "formik";
import { DropdownTableFilter } from "./DropdownTableFilter";
import { TableSorter } from "./TableSorter";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { useRecoilState } from "recoil";
import { remoteTableQuerySelector, RemoteTableQueryStateType, tableDto, tableSelector } from "data/atoms/table.atom";
import { CustomizeColumns } from "./CustomizeColumns";
import { ColumnDescription } from "react-bootstrap-table-next";
import { MdOutlineFilterAlt } from "react-icons/md";

export interface FilterOption {
  name: string;
  label: string;
  type: "checkbox" | "radio" | "text" | "date" | "custom";
  value?: SelectOption[] | SelectOption;
  hidden?: boolean,
  operator?: OperatorKey // Default is "equalTo" for single value and "contains" for multi value input type
  filterBuilder?: (value: any) => Comparison<any> | Filter<any>[] | undefined
  componentCreator?: (filter: any, onFilterChange: (name: string, value: any) => void) => any
}

export interface SortOption {
  field: string,
  label: string
  direction: string
}

export interface SearchOption {
  field: string,
  label: string,
  filterBuilder?: (value: any) => Comparison<any>
}

export interface TabOption {
  id: string,
  label: string,
  count?: number
  readonly?: boolean
  filter: Filter<any>
}

interface DataTableFilterProps {
  value: any;
  onTableFilterChange: (filter: Filter<any> | Filter<any>[], sorting?: QueryParams<any>, resetPage?: boolean) => void;
  onTableSortChange?: (sort: SortOption) => void;
  onTableTabChange?: (tab: TabOption) => void;
  filterOptions: FilterOption[];
  sortOptions?: SortOption[];
  searchOptions?: SearchOption[];
  tabOptions?: TabOption[];
  setViews?: any;
  views?: any;
  paging?: any;
  setPaging?: any;
  filterKey?: string;
  customizeColumns?: ColumnDescription[]
  handleChangeCustomizeColumn: Function
  resetColumns: Function
  filterByIds?: string
}

export interface FilterValue {
  value: any;
  label?: string;
  name?: string;
  hidden?: boolean;
}

export default function TableFilter(props: DataTableFilterProps) {
  let {
    onTableSortChange,
    onTableFilterChange,
    onTableTabChange,
    value = [],
    filterOptions = [],
    searchOptions = [],
    tabOptions = [],
    sortOptions = [],
    setViews,
    views,
    filterKey,
    customizeColumns,
    handleChangeCustomizeColumn,
    resetColumns,
    filterByIds
  } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [submittedSearchText, setSubmittedSearchText] = useState<string>('');
  const [moreFilter, setMoreFilter] = useState<boolean>(false);
  const [tableSort, setTableSort] = useState<SortOption>();
  const [tableFilter, setTableFilter] = useState<Record<string, FilterValue>>({});
  const [autoSubmitAtomic, setAutoSubmitAtomic] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<TabOption>(tabOptions![0]);
  const [tableColumn, setTableColumn] = useState(customizeColumns)
  // const [tableAtom, setTableAtom] = useRecoilState<tableDto>(tableSelector)
  let [remoteTableQuery, setRemoteTableQuery] = useRecoilState(remoteTableQuerySelector)
  const [previousFilter, setPreviousFilter] = useState<any>({})
  const listPageFilterId = ['fulfillOrders', 'fulfillmentOrder']

  const getInitialSearchOption = () => {
    if (remoteTableQuery.key == filterKey) {
      if (remoteTableQuery.query?.searchOption?.field != searchOptions[0]?.field) {
        return remoteTableQuery.query?.searchOption
      }
    }
    if (filterByIds && listPageFilterId.includes(filterKey || '')) {
      return searchOptions?.find((option: any) => option.field == 'id')
    }
    return searchOptions[0]
  }

  const [searchOption, setSearchOption] = useState(getInitialSearchOption());

  const tableSearchForm = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => handleSubmitSearch(values)
  });

  
  useEffect(() => {
    setTableColumn(customizeColumns)
  }, [customizeColumns])

  useEffect(() => tableSearchForm.handleSubmit(), [searchOption])
  useEffect(() => {
    if (remoteTableQuery.key == filterKey) {
      setActiveTab(remoteTableQuery.query?.activeTab)
      setSearchText(remoteTableQuery.query?.searchText || (filterByIds ? filterByIds : ''))
      tableSearchForm.setFieldValue('__searchValue', remoteTableQuery.query?.searchText)
      setTableSort(remoteTableQuery.query?.sort)
      setTableFilter(remoteTableQuery.query?.filter)
      setTableColumn(remoteTableQuery?.columns)
    } else {
      if (filterByIds && listPageFilterId.includes(filterKey || '')) {
        setSearchText(remoteTableQuery.query?.searchText || (filterByIds ? filterByIds : ''))
      }
    }
  }, [])

  useEffect(() => {
    setRemoteTableQuery((currVal: RemoteTableQueryStateType) => {
      return {
        key: filterKey,
        query: {
          ...currVal.query,
          activeTab,
          searchText: submittedSearchText || (filterByIds ? filterByIds : ''),
          searchOption,
          sort: tableSort,
          filter: tableFilter
        },
        columns: tableColumn
      }
    })
  }, [tableFilter, searchOption, tableSort, activeTab, submittedSearchText, tableColumn])
  // useEffect(() => {
  //   if (filterByIds) {
  //     setSearchText(filterByIds)
  //   }
  // }, [filterByIds])

  const handleFilterChange = (key: string, value: FilterValue | FilterValue[], autoSubmit: boolean = false) => {
    setTableFilter((current: any) => {
      let newState = { ...current, [key]: value };
      return newState;
    });
    if(autoSubmit) {
      setAutoSubmitAtomic(() => autoSubmitAtomic + 1)
    }
  };

  useEffect(() => {
    if (autoSubmitAtomic > 0 || (autoSubmitAtomic == 0 && tableSort)) {
      submitFilters();
    }
  }, [autoSubmitAtomic, tableSort]);

  const submitFilters = async (filterState = tableFilter, tabState = activeTab) => {
    try {
      let filter: Filter<any> = {} as any;
      for (let options of filterOptions) {
        let filterValue = filterState[options.name];
        if (filterValue) {
          let builder = options.filterBuilder;
          let values = Array.isArray(filterValue) ? filterValue.map(item => item.value == undefined ? item : item.value) : filterValue.value
          let operator = options.operator || Array.isArray(filterValue) ? 'contains' : 'equalTo'
          let filterComp: any = builder ? builder(values) : { [operator]: values };
          if(Array.isArray(filterComp)) {
            for(let filterCond of filterComp) {
              filter = { ...filter, ...filterCond }
            }
          } else {
            filter[options.name] = filterComp;
          }
        }
      }

      for (let option of searchOptions || []) {
        if (option.field == searchOption?.field) {
          let searchValue = filterState['__searchValue'];
          if (searchValue?.value) {
            let filterValue = option.filterBuilder ? option.filterBuilder(searchValue.value) : { contains: searchValue.value };
            if(filterValue) {
              filter[option.field] = filterValue
            }
          }
        }
      }

      let sorting: QueryParams<any> = tableSort ? { sortBy: tableSort.field, sortDirection: tableSort.direction } : {} as any
      let finalFilter = isEmpty(tabState?.filter) ? filter : isEmpty(filter) ? tabState?.filter : [filter, tabState?.filter];
      let filterChanged = JSON.stringify(finalFilter) != JSON.stringify(previousFilter);
      setPreviousFilter(finalFilter)
      onTableFilterChange && onTableFilterChange(finalFilter, sorting, filterChanged);
    } catch (e) {
      Logger.warn("Submit filter issue: ", e);
    }
  }

  const changeFilterFields = (keys: string[] = [], autoSubmit = false) => {
    if(!keys?.length) {
      setTableFilter({});
      if(autoSubmit) {
        setAutoSubmitAtomic(() => autoSubmitAtomic + 1)
      }
    } else {
      setTableFilter((current: any = {}) => pick(current, ...keys))
      if(autoSubmit) {
        setAutoSubmitAtomic(() => autoSubmitAtomic + 1)
      }
    }
  };

  const removeFilter = (key: string) => {
    setTableFilter((current: any) => omit(current, key));
  };

  const handleSubmitSearch = (values: any) => {
    let newState = { ...tableFilter };
    for (let key in values) {
      newState[key] = { value: values[key], hidden: true };
    }
    setSubmittedSearchText(values.__searchValue || '')
    setTableFilter(newState);
    submitFilters(newState);
  };

  const handleChangeTab = (tab: TabOption) => {
    setActiveTab(tab);
    setRemoteTableQuery((currVal => {
      return {
        ...currVal,
        key: currVal.key,
        query: {
          ...currVal.query,
          page: 1
        }
      }
    }))
    submitFilters(undefined, tab);
    onTableTabChange && onTableTabChange(tab)
  };

  const getOptionsLabels = (options: any[], keyField = 'name', labelField = 'label') => {
    return options.reduce((result, option) => ({ ...result, [option[keyField]]: option[labelField] || option[keyField] }), {})
  }

  const handleSortTable = (sort: SortOption) => {
    setTableSort(sort)
  }

  return (
    <Col style={{ marginTop: '-.5em' }}>
      {!tabOptions?.length ? null :
      <div data-simplebar>
        <SimpleBar  className="remote-table-tabs">
          <Nav tabs className="nav-tabs-custom border-0 nav-justified remote-table-tabs-nav" style={{ width: "fit-content" }}>
            {
              tabOptions.map((tab: TabOption, index: number) => {
                return (
                  <NavItem key={`${tab.id}-${index}`} className="pb-2">
                    <NavLink
                      style={{ cursor: "pointer", ...((tab.count || 0) > 0 ? { paddingTop: 4 } : {}) }}
                      active={tab.id == activeTab?.id}
                      className={tab.id == activeTab?.id ? "active" : ""}
                      onClick={() => handleChangeTab(tab)}
                    >
                    <span style={{ whiteSpace: 'nowrap' }}>
                      <span className="text-left" style={tab.readonly ? { fontWeight: 'normal'} : { fontWeight: 500 }}>
                        {tab.label}
                      </span>

                      <span>
                        {(tab.count || 0) > 0 ? <span className="ms-1">
                          <Badge
                            color={tab.readonly ? "light" : "primary"}
                            className={"text-truncate font-size-10"}
                          >
                            <span> {tab.count}</span>
                          </Badge>
                        </span> : null}
                      </span>
                    </span>
                    </NavLink>
                  </NavItem>
                );
              })
            }
          </Nav>
        </SimpleBar>
        </div>
      }
      <Row>
        <div className="d-flex mb-3 form section-filter">
          <div style={{ flex: 1 }} className="me-2">
            <Form onSubmit={(e) => {
              e.preventDefault();
              tableSearchForm.handleSubmit();
              return false;
            }}>
              <InputGroup>
                {
                  searchOptions.length > 1 ? (
                    <Select
                      options={searchOptions}
                      value={searchOption}
                      menuPortalTarget={document.body}
                      getOptionLabel={((option: SearchOption) => t(option.label))}
                      getOptionValue={((option: SearchOption) => option.field)}
                      onChange={(value: any) => {
                        setSearchOption((value || searchOptions[0]))
                      }}
                      styles={{
                        container: (base: any) => ({ ...base, zIndex: 2 }),
                        control: (base: any) => ({
                          ...base,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderColor: 'rgb(204, 204, 204)',
                          boxShadow: "none",
                          minWidth: 150
                        })
                      }}
                    />
                  ) : null
                }

                <Input
                  type={"text"}
                  name="__searchValue"
                  placeholder={`${t(Labels.search_by)} ${searchOption?.label}`}
                  value={searchText || ''}
                  style={{
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    minWidth: 150
                  }}
                  onPaste={(event) => {
                    setTimeout(() => {
                      tableSearchForm.handleSubmit()
                    }, 0)
                  }}
                  onChange={(event) => {
                    let nativeEvent: any = event.nativeEvent
                    tableSearchForm.handleChange(event)
                    setSearchText(event.target.value)
                    if (!nativeEvent?.inputType) {
                      tableSearchForm.handleSubmit();
                    } else {
                      if(!event.target.value) {
                        tableSearchForm.handleSubmit();
                      }
                    }
                  }}
                />
                <input type="submit" hidden />
              </InputGroup>
            </Form>
          </div>
          <div className="text-end" style={{ zIndex: 3 }}>
            <div
              className="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <DropdownTableFilter
                filterOptions={filterOptions}
                currentFilters={tableFilter}
                onFilterChange={handleFilterChange}
                changeFilterFields={changeFilterFields}
                onClose={submitFilters}
              />
              {
                !filterOptions?.length ? null :
                  <>
                    <Button 
                      className={"me-2"} 
                      outline 
                      color="secondary" 
                      onClick={() => setMoreFilter(t => !t)} 
                      style={filterOptions?.filter((i: any) => i.hidden != true)?.length == 0 
                        ? {
                            borderLeft: "1px solid",
                            borderRight: "1px solid",
                            borderBottomLeftRadius: '0.25rem',
                            borderTopLeftRadius: '0.25rem',
                          }
                        : {}
                        }
                    >
                      <MdOutlineFilterAlt size={16} />
                      {t(Labels.more_filters)}
                    </Button>
                    <ModalTableFilter
                      open={moreFilter}
                      onSubmit={() => {
                        setMoreFilter(false);
                        submitFilters();
                      }}
                      filters={filterOptions}
                      currentFilters={tableFilter}
                      onFilterChange={handleFilterChange}
                      removeFilter={removeFilter}
                      changeFilterFields={changeFilterFields} />
                  </>
              }
            </div>

            <div
              className="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <CustomizeColumns 
                customizeColumns={tableColumn} 
                handleChangeCustomizeColumn={handleChangeCustomizeColumn}
                resetColumns={resetColumns}
              />
              <TableSorter sortOptions={sortOptions} onTableSortChange={handleSortTable} tableSort={tableSort}/>
            </div>
          </div>
        </div>
      </Row>
      <FilterView
        tableFilter={tableFilter}
        changeFilterFields={ changeFilterFields}
        filterFieldLabels={getOptionsLabels(filterOptions)
      } />
    </Col>
  );
}

export interface FilterViewProps {
  tableFilter: any
  filterFieldLabels: any
  changeFilterFields: any
}

export const FilterView = (props: FilterViewProps) => {
  let { tableFilter, filterFieldLabels, changeFilterFields } = props;
  let renderFilterEntries = Object.entries(tableFilter)?.filter(([key, values]) => !(values as any)?.hidden);
  let [filterFields, setFilterFields] = useState<Set<string>>(new Set(Object.keys(tableFilter || {})))
  let [filterFieldChangeAtomic, setFilterFieldChangeAtomic] = useState<number>(0)

  useEffect(() => {
    setFilterFields(new Set(Object.keys(tableFilter || {})))
  }, [tableFilter])

  useEffect(() => {
    if (filterFieldChangeAtomic > 0) {
      let keys = [...filterFields]
      changeFilterFields(keys, true)
    }
  }, [filterFieldChangeAtomic])

  const handleRemoveFilter = (key: string) => {
    setFilterFields((current: Set<string>) => {
      current.delete(key)
      return new Set(current)
    })
    setFilterFieldChangeAtomic(() => filterFieldChangeAtomic + 1)
  }

  return <>
    {
      renderFilterEntries.map(([key, values], index: number) => {
        let displayValues = Array.isArray(values) ? values : [values];
        let displayLabel = displayValues.map((value: FilterValue) => value.label || value.name || value.value).join(",");

        let operator = (values as any)?.operator || (Array.isArray(values) ? "in" : "=")
        return (
          <Chip close key={index} onClose={() => handleRemoveFilter(key)}>
            {
              `${filterFieldLabels[key] || key} ${operator} ${displayLabel}`
            }
          </Chip>
        )
      })
    }
    {
      !renderFilterEntries.length ? null :
        <Button color="secondary" outline className="px-2 py-1" onClick={() => changeFilterFields([], true)}>{t(Labels.clear_all_filter)}</Button>
    }
  </>;
};
