import { FormFeedback, FormGroup, Input, InputGroup, Label } from "reactstrap";
import { InputProps } from "reactstrap/types/lib/Input";
import { FormikHandlers, FormikState } from "formik/dist/types";
import React, { ChangeEventHandler, Fragment, memo, useCallback, useEffect, useRef, useState } from "react";
import { t } from "../../core/translations";
import { FormikHelpers } from "formik";
import { isObject, isString } from "lodash";
import { OptionalLabel } from "components/common/OptionalLabel";
import { LabelTooltip } from "components/common/LabelTooltip";
import { debounce } from "../../utils/utils";

export interface FormikInputProps extends InputProps {
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  name: string;
  label?: string;
  readOnly?: boolean;
  errors?: any;
  hiddenError?: boolean;
  startComponent?: any;
  endComponent?: any;
  required?: boolean;
  tooltip?: string;
  onChange?: ChangeEventHandler<any> | ((value: any) => void);
  autoTrim?: boolean
}

// xuanduc edit
const getErrorMessageGroup = (errors: any, name: string): any => {
  let message = getErrorMessage(errors, name);
  return message;
};

const getErrorMessage = (errors: any, name: string): any => {
  if (isString(errors)) return errors;
  if (!errors) return errors;

  if (Array.isArray(errors)) {
    for (let error of errors) {
      const msg: any = getErrorMessage(error, name);
      if (msg) return msg;
    }
  } else {
    let error = errors[name];
    if (isObject(error)) {
      return getErrorMessage(error, name);
    } else {
      return error;
    }
  }
};

const FormikInputComponent = (props: FormikInputProps) => {
  const {
    readOnly,
    label,
    children,
    name,
    validation,
    errors,
    onChange,
    startComponent,
    endComponent,
    customInput,
    required,
    tooltip,
    ...restProps
  } = props;
  const PreInputComponent = startComponent || Fragment;
  const EndInputComponent = endComponent || Fragment;

  const isSubmitting = useRef<any>(null);
  const [value, setValue] = useState("");
  const [submittedValue, setSubmittedValue] = useState("");
  const [focus, setFocus] = useState(false);
  const timer = useRef<any>();

  const autoSubmitValue = (newValue: any) => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (newValue != submittedValue) {
        handleChange(newValue)
        setSubmittedValue(newValue)
      }
    }, 200);
  };

  const onInputChange = (event: any) => {
    if (readOnly) return;

    let newValue = event.target.value
    let newValueFilterd = (props?.autoTrim && (props?.type == 'text' || props?.type == 'textarea')) ? newValue?.trim() : newValue

    setValue(newValueFilterd);
    autoSubmitValue(newValueFilterd);
  };

  const handleChange = (newValue: any, fieldName = name) => {
    fieldName = isString(fieldName) ? fieldName : name;

    if (onChange) {
      onChange(newValue);
    } else {
      validation.setFieldValue(fieldName, newValue);
    }

    validation.setFieldError(fieldName, undefined);
  };

  useEffect(() => {
    if (validation.isSubmitting) {
      isSubmitting.current = true;
    }
  });

  useEffect(() => {
    if (isInputField() && !focus) {
      setValue(validation.values[name] || "");
    }
  }, [validation.values[name]]);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    validation?.setFieldTouched(name, true);
    if (isInputField()) {
      setFocus(false);
      handleChange(value);
    }
  };

  function isInvalidInput() {
    return props.invalid || props.hiddenError ? false : !!(validation && (validation.touched[name] || isSubmitting.current) && validation.errors[name]);
  }

  const isInputField = () => {
    return props.type != "radio" && !customInput;
  };

  return (
    <div className={label ? "mb-2" : ""}>
      {
        tooltip
          ?
          required == false
            ? <LabelTooltip tooltip={tooltip as string}>
              <OptionalLabel label={label} />
            </LabelTooltip>
            : <LabelTooltip tooltip={tooltip as string}>
              {label && <Label>{label}</Label>}
            </LabelTooltip>
          :
          required == false
            ? <OptionalLabel label={label} />
            : label && <Label>{label}</Label>
      }
      {
        <InputGroup>
          <PreInputComponent />
          <Input
            name={name}
            {...{ className: "form-control", ...restProps }}
            // {...(props.hasOwnProperty("value") ? {} : { value: validation?.values[name] || "" })}
            value={value || ""}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onInputChange}
            invalid={isInvalidInput()}
          />
          <EndInputComponent />
        </InputGroup>
      }
      {
        // xuanduc edit
        props.hiddenError || !(validation && validation.errors[name] && (validation.touched[name] || isSubmitting.current)) ? null :
          <FormFeedback className="d-block"
                        type="invalid">{getErrorMessageGroup(validation?.errors, name)}</FormFeedback>
      }
    </div>
  );
};

export const FormikInputField = memo(FormikInputComponent);