import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationVi from "../assets/locales/vi/translation.json";
import translationEn from "../assets/locales/en/translation.json";

const DEFAULT_LANGUAGE = 'vi'
const resources = {
  vi: {
    translation: translationVi,
  },
  en: {
    translation: translationEn,
  },
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE, // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
export const t = i18n.t.bind(i18n)
