import { Messages } from 'common/messages';
import { CrudActions } from "./crud.action";
import { CreateOrderDto, OrderDto, OrderService, OrderServices, UpdateOrderDto } from "../services/order.service";
import { Filter } from "../../core/rest-api/query-builder";
import { Confirmations, Toasts } from "../../core/notification/notifications";
import { t } from "../../core/translations";
import { ActionEntities, ResourceEntities } from 'types/permission-type';
import { withPermission } from 'common/hooks/use-permission';

class OrderAction extends CrudActions<OrderService, CreateOrderDto, UpdateOrderDto> {
  constructor() {
    super(OrderServices);
  }

  async searchByOrderNumber(searchText: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.orderEntity,
      async () => {
        let filter: Filter<OrderDto> = {};
        if (searchText) {
          filter.number = { contains: searchText };
        }
        let pageData = await OrderServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async recreateFulfillProduct(ids: string[] | number[], refresh?: Function) {
    Confirmations.confirm(
      t(Messages.recreate_fulfill_order_selected),
      async () => {
        let result = await OrderServices.fulfillOrders(ids);
        if (result?.success) {
          Toasts.success(
            t(
              Messages.process_recreate_fulfill_order_successfully,
              { number: ids.length }
            )
          );
          refresh && refresh();
        } else {
          Toasts.error(t(Messages.your_request_execute_unsuccessfully));
        }
      }
    );
  }


  async getChannelOrderIdById(id: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.orderEntity,
      async () => {
        let filter: Filter<OrderDto> = {};
        if (id) {
          filter.channelOrderId = { contains: id };
        }
        let data = await OrderServices.getOne({ filter, page: 1 });
        return data || {};
      })
  }
}

export const OrderActions = new OrderAction();
