import { atom, selector } from "recoil";
import { Storages } from "utils/storages";
import { Keys } from "../../common/keys";
import { Global } from "../../common/global";

const userTokenState = atom<string>({
  key: 'userTokenState',
  default: localStorage.getItem(Keys.token) || '',
});

export const userTokenSelector = selector({
  key: 'userTokenSelector',
  get: ({ get }) => get(userTokenState),
  set: ({ set }, newValue) => {
    localStorage.setItem(Keys.token, JSON.stringify(newValue))
    set(userTokenState, newValue + '')
  }
});

export const userState = atom<any>({
  key: 'userState',
  default: Storages.getLocalJson(Keys.user),
});

export const userSelector = selector({
  key: 'userSelector',
  get: ({ get }) => get(userState),
  set: ({ set }, newValue) => {
    Storages.setLocalJson(Keys.user, newValue)
    set(userState, newValue)
  }
});

export const loginErrorState = atom<string>({
  key: 'loginError',
  default: '',
});

const userTenantState = atom<any>({
  key: 'userTenantState',
  default: Storages.getLocalJson(Keys.tenant) || {},
  effects: [
    ({ onSet }) => {
      onSet(newTenant => {
        Global.tenant = newTenant
      });
    }
  ]
});

export const userTenantSelector = selector({
  key: 'userTenantSelector',
  get: ({ get }) => get(userTenantState),
  set: ({ set }, newValue: any) => {
    Storages.setLocalJson(Keys.tenant, newValue)
    set(userTenantState, newValue)
  }
});

const userTeamState = atom<any>({
  key: 'userTeamState',
  default: Storages.getLocalJson(Keys.team) || {},
  effects: [
    ({ onSet }) => {
      onSet(newTeam => {
        Global.team = newTeam
      });
    }
  ]
});

export const userTeamSelector = selector({
  key: 'userTeamSelector',
  get: ({ get }) => get(userTeamState),
  set: ({ set }, newValue: any) => {
    Storages.setLocalJson(Keys.team, newValue)
    set(userTeamState, newValue)
  }
});

export const permissionState = atom<any>({
  key: 'permissionState',
  default: JSON.parse(localStorage.getItem(Keys.roles) as string) || '',
})

export const permissionsSelector = selector<Record<string, any> | any>({
  key: 'permissionsSelector',
  get: ({get}) => get(permissionState),
  set: ({ set }, newValue: any) => {
    let roles: any = {}
    if (newValue) {
      newValue.permissions?.map((permission: any) => {
        permission?.actions?.map((action: string) => {
          let actionArray: Array<string> = roles[action] ? roles[action] : []
          roles[action] = actionArray.concat(permission?.resources)
        })
      })
    }
    localStorage.setItem(Keys.roles, JSON.stringify(roles))
    set(permissionState, roles)
  }
})