import { defaultPageInfo } from "../../components/table/RemoteTable";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "core/rest-api/query-builder";
import { FulfillmentInvoiceServices } from "../services/fulfillment-invoice.service";

export const fulfillmentInvoiceQueryState = atomFamily<QueryParams<any>, any>({
  key: "fulfillmentInvoiceQueryState",
  default: (params: any) => {
    return { ...params }
  },
})

export const fulfillmentInvoiceSelector = selector({
  key: "fulfillmentInvoiceSelector",
  get: async ({ get }) => {
    let query = get(fulfillmentInvoiceQueryState(defaultPageInfo))
    let pageData = await FulfillmentInvoiceServices.query(query, {
      snoozeErrorMessage: true,
    })
    return pageData
  },
})


export const baseCostQueryState = atomFamily<QueryParams<any>, any>({
  key: "baseCostQueryState",
  default: (params: any) => {
    return params 
  },
})

export const baseCostSelector = selectorFamily({
  key: "baseCostSelector",
  get: (params: any) => async ({ get }) => {
    let query = get(baseCostQueryState(params))
    let pageData = await FulfillmentInvoiceServices.getListBaseCost(query)
    
    return pageData
  },
})


export const invoiceIdState = atomFamily<QueryParams<any>, any>({
  key: "invoiceIdState",
  default: (id: any) => {
    return id;
  }
});

export const invoiceByIdSelector = selectorFamily({
  key: "invoiceByIdSelector",
  get: (id: any) => async ({ get }: any) => {
    let invoiceId = get(invoiceIdState(id));

    if (+invoiceId > 0) {
      let data = await FulfillmentInvoiceServices.getById(+invoiceId, { snoozeErrorMessage: false });
      return data;
    }
    return {};
  }
});

