import { Logger } from "../../core/logger";
import { t } from "../../core/translations";
import { Messages } from "../../common/messages";
import { CrudService } from "../services/crud.service";
import { Toasts } from "../../core/notification/notifications";
import { RemotePageData } from "../../components/table/RemoteTable";
import { isEmpty } from "lodash";
import { DefaultBulkEditActionProps } from "../../components/bulk/BulkEditor";
import { OrderActionEnum } from "../services/order.service";

export class CrudActions<Service extends CrudService<any, CreateDto, UpdateDto>, CreateDto, UpdateDto> {
  service: Service

  constructor(service: Service) {
    this.service = service;
  }

  async findById(id: string | number) {
    try {
      return await this.service.getById(+id)
    } catch (e) {
      // Do nothing
    }
  }

  async findManyByIds(ids: any[]) {
    try {
      let data: RemotePageData = await this.service.getByIds(ids)
      return data?.pageItems || []
    } catch (e) {
      return []
    }
  }

  async create(payload: CreateDto, hooks: { setError?: Function } = {}) {
    let { setError } = hooks;

    try {
      const response: any = await this.service.createOne(payload, { snoozeErrorMessage: false });
      Logger.debug("create result: ", response);
      if (response) {
        return response;
      }
    } catch (error: any) {
      setError && setError(error.message || t(Messages.delete_resource_unsuccessfully));
      Logger.trace(error);
    }
  }

  async save(id: string | number, payload: UpdateDto | CreateDto, hooks: { setError?: Function } = {}) {
    if(+id > 0) {
      return this.update(id, payload as UpdateDto, hooks)
    } else {
      return this.create(payload as CreateDto, hooks)
    }
  }

  async update(id: string | number, payload: UpdateDto, hooks: { setError?: Function } = {}) {
    let { setError } = hooks;

    try {
      const response: any = await this.service.updateOne(+id, payload, { snoozeErrorMessage: false });
      Logger.debug("update result: ", response);
      if (response) {
        return response;
      }
    } catch (error: any) {
      setError && setError(error.message || t(Messages.delete_resource_unsuccessfully));
      Logger.trace(error);
    }
  }

  async updateMany(ids: string[] | number[], payload: UpdateDto, hooks: { setError?: Function } = {}) {
    let { setError } = hooks;

    try {
      const response: any = await this.service.updateMany(ids, payload, { snoozeErrorMessage: false });
      Logger.debug("updateMany result: ", response);
      if (response) {
        return response;
      }
    } catch (error: any) {
      setError && setError(error.message || t(Messages.delete_resource_unsuccessfully));
      Logger.trace(error);
    }
  }

  async delete(id: any, hooks: { setError?: Function } = {}) {
    let { setError } = hooks;

    try {
      const response: any = await this.service.deleteOne(id, { snoozeErrorMessage: false });
      Logger.debug("delete result: ", response);
      if (response) {
        return response;
      }
    } catch (error: any) {
      setError && setError(error.message || t(Messages.delete_resource_unsuccessfully));
      Logger.trace(error);
    }
  }

  async deleteMany(ids: string[] | number[], hooks?: { setError?: Function }) {
    let { setError } = hooks || {}

    try {
      const response: any = await this.service.deleteMany(ids, { snoozeErrorMessage: false });
      Logger.debug("deleteMany result: ", response);
      if (response) {
        Toasts.success(t(Messages.remove_selected_items_successfully, { number: ids.length }));
        return response
      }
    } catch (error: any) {
      setError && setError(error.message || t(Messages.delete_resource_unsuccessfully))
      Logger.trace(error)
    }
  }

  async bulkUpdateByIds(ids: string[] | number[], updateData: UpdateDto) {
    try {
      let response = await this.service.updateMany(ids, updateData)
      let success = !!response
      success ? Toasts.success(t(Messages.update_successfully)) : Toasts.error(t(Messages.update_unsuccessfully))
      return success
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async runBulkAction(path: string, ids: string[] | number[], action: string, updateData: UpdateDto) {
    try {
      let response = await this.service.bulkAction(path, ids, action, updateData)
      let success = !!response
      success ? Toasts.success(t(Messages.bulk_action_successfully)) : Toasts.error(t(Messages.bulk_action_unsuccessfully))
      return success
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async bulkUpdate(bulkUpdateData: Record<string, UpdateDto>) {
    if (!isEmpty(bulkUpdateData)) {
      let dataById = {} as any
      for (const key in bulkUpdateData) {
        dataById[key] = bulkUpdateData[key]
      }
      const result = await this.service.bulkUpdate(dataById);
      !!result ? Toasts.success(t(Messages.update_successfully)) : Toasts.error(t(Messages.update_unsuccessfully))
      return !!result
    }
    return false
  }
}