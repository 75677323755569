interface ChipProps {
  onClose?: () => void;
  onClick?: () => void;
  color?: "primary" | "secondary" | "success" | "danger" | "warning" | "info";
  close?: boolean;
  hover?: boolean;
  disable?: boolean;
  children: string;
}

export default function Chip({ children, onClose, color, close, hover, onClick, disable }: ChipProps) {
  return (
    <div
      className={`alert alert-${color} fade show px-2 py-1 font-size-13 me-2 d-inline-block ${hover ? "interactive" : ""}`}
      style={{
        width: "fit-content",
        opacity: disable ? 0.6 : ""
      }}
      onClick={onClick}
    >
      <span className={`me-2`}>{children}</span>
      { close && (
        <i className="fas fa-times"
           style={{ cursor: "pointer" }}
           onClick={onClose}
        />
      )
      }
    </div>
  );
}

Chip.defaultProps = {
  close: false,
  color: "secondary"
};
