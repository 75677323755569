import React, { useEffect } from "react";
import { permissionsSelector, userSelector, userTeamSelector, userTenantSelector, userTokenSelector } from "../../data/atoms/auth.atom";
import { useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { RouteNames } from "../../routes";

const Logout = (props: any) => {
  const setUser = useSetRecoilState(userSelector);
  const setRoles = useSetRecoilState(permissionsSelector)
  const setUserToken = useSetRecoilState(userTokenSelector);
  const setTenant= useSetRecoilState(userTenantSelector);
  const setTeam = useSetRecoilState(userTeamSelector);
  const history = useHistory()

  useEffect(() => {
    setUserToken('');
    setUser(null);
    setRoles(null)
    setTenant(null);
    setTeam(null)
    setTimeout(() => history.replace(RouteNames.SIGN_IN), 1000)
  }, []);

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Logout;
