import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect, useHistory } from "react-router-dom"
import { useRecoilState } from "recoil";
import { userSelector, userTokenSelector } from "../data/atoms/auth.atom";
import { RouteNames } from "./index";

interface AuthMiddlewareProps {
  isAuthProtected: boolean,
  component: any,
  location: any,
  layout: any,
  path: any,
  exact: boolean
}

export const handleUnauthorized = (history: any, embedToken: any) => {
  if(embedToken) {
    window.location.replace(RouteNames.UNAUTHORIZED)
  } else {
    window.location.replace(RouteNames.SIGN_IN)
  }
}

function RedirectUnauthorized({ embedToken }: { embedToken: any }) {
  const history = useHistory()
  handleUnauthorized(history, embedToken)
  return <></>;
}

const AuthMiddleware = (props: AuthMiddlewareProps) => {
  let { component: Component, layout: Layout, isAuthProtected, ...rest } = props || {}
  let [user] = useRecoilState(userSelector)
  let [token] = useRecoilState(userTokenSelector)

  const queryString = window.location.search
  const searchParams = new URLSearchParams(document.location.search)
  const embedToken = searchParams.get('embedToken')

  return (
    <Route
      {...rest}
      render={ (props) => {
        if (isAuthProtected && (!embedToken && (!token && !user))) {
          return (
            <RedirectUnauthorized embedToken={embedToken}/>
          )
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

AuthMiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default AuthMiddleware;
