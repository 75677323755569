import { LoadableComponent } from "components/common/LoadableComponent";
import { FormikForm } from "components/form/FormikForm";
import { FormikInput } from "components/form/FormikInput";
import { FormLayout, FormLayoutMain, FormLayoutRight } from "components/layout/FormLayout";
import { BiLinkExternal } from "react-icons/bi";
import { t } from "core/translations";
import { fulfillMentIdSelector } from "data/atoms/fulfillments.atom";
import { FulfillMentDto } from "data/services/fulfillment.service";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../components/layout/Breadcrumb";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Label, Row } from "reactstrap";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import * as Yup from "yup";
import { Forms } from "utils/forms";
import { StatusSelect } from "components/common/StatusComponent";

import { FulfillmentStatusName, FulfillmentStatusValues } from "types/fulfillment-status.type";
import { Messages } from "common/messages";
import AsyncSelect from "react-select/async";
import { FulfillmentActions } from "data/actions/fulfillment.action";
import { FulfillOrderDto } from "data/services/fulfill-order.service";
import { FulfillOrderActions } from "data/actions/fulfill-order.action";
import { Toasts } from "core/notification/notifications";
import { ShippingAddress, shippingAddressSchema } from "components/form/ShippingAddress";
import { OrderApiPaths, OrderDto } from "../../data/services/order.service";
import { SearchableSelect } from "components/input/SearchableSelect";
import ActionButton from "components/input/ActionButton";
import { RouteNames } from "routes";
import { isEmpty } from "lodash";
import { FulfillOrderSelect } from "./components/fulfill-order.select";
import { MultiValue } from "react-select";
import { Labels } from "common/labels";
import { OptionalLabel } from "components/common/OptionalLabel";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import { OrderActions } from "data/actions/order.action";
import { withPermission } from "common/hooks/use-permission";
import { ShipmentStatusName, ShipmentStatusValues } from "../../types/shippment-status";
import { preprocessPage } from "utils/utils";
import { createUrlEmbed } from "utils/embed";

const FulfilmentNote = (props: { data: FulfillMentDto }) => {
  return (
    <Card>
      <CardBody>
        <div className="mt-3">
          <OptionalLabel label={t(Labels.notes)}/>
          <div
            style={{
              minHeight: 70,
              borderRadius: 5,
              padding: 10,
              border: "1px solid #f3f4f6",
            }}
          >
            <p className="text-muted mb-0">
              {props.data?.note || t(Messages.no_notes_from_customer)}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export const FulfillmentPage = () => {
  const params = useParams<Record<string, any>>()
  const id = params.id
  const isEditing = +id > 0  
  const history = useHistory()
  const fulfillmentLoadable = useRecoilValueLoadable(fulfillMentIdSelector(params.id))
  const [fulfillment, setFulfillMent] = useState<FulfillMentDto>({})

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.fulfillment), model: fulfillment, primaryProperty: 'trackingNumber'})
  }, [fulfillment])

    // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(fulfillMentIdSelector(params.id))
  useEffect(() => {
    ((fulfillmentLoadable.state == "hasValue" || fulfillmentLoadable.state == "hasError") && !isEmpty(fulfillmentLoadable.contents)) && fakeRefreshDataLoadable()
  },[params.id])


  useEffect(() => {
    if (fulfillmentLoadable.state == "hasValue") {
      setFulfillMent((current: any) => ({
        ...current,
        ...(fulfillmentLoadable.contents || {}),
      }))
    }
  }, [fulfillmentLoadable.state])

  const updateTotalBaseCost = (value: number, type: string) => {
    if (type === "baseCost") {
      validation.setFieldValue("baseCost", value >= 0 ? `${value}` : 0)
      let quantityValue = validation.values.quantity as number
      let totalBaseCost = value * quantityValue
      validation.setFieldValue("totalBaseCost", totalBaseCost >= 0 ? totalBaseCost : 0)
    }
    if (type === "quantity") {
      validation.setFieldValue("quantity", value >= 0 ? `${value}` : 0)
      let baseCost = validation.values.baseCost as number
      let totalBaseCost = value * baseCost
      validation.setFieldValue("totalBaseCost", totalBaseCost >= 0 ? totalBaseCost : 0)
    }
  }

  const fulfillmentSchema = Yup.object({
    order: Yup.object().required(
      t(Messages.field_required, { field: t(Labels.order) })
    ),
    baseCost: Yup.number()
      .min(0, t(Messages.field_required_min, { field: t(Labels.base_cost), min: 0 }))
      .required(t(Messages.field_required, { field: t(Labels.base_cost) })),
    shipmentStatus: Yup.string().required(
      t(Messages.field_required, { field: t(Labels.shipment_status) })
    ),
    trackingNumber: Yup.string().required(
      t(Messages.field_required, { field: t(Labels.tracking_number) })
    ),
    carrierName: Yup.string().required(
      t(Messages.field_required, { field: t(Labels.carrier_name) })
    ),
    carrierCode: Yup.string().required(
      t(Messages.field_required, { field: t(Labels.carrier_code) })
    ),
    shippingAddress: shippingAddressSchema,
  })

  const validation = useFormik({
    initialValues: fulfillment,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: fulfillmentSchema,
    onSubmit: (values, helpers) => handleSubmit(values, helpers),
  })

  const handleSubmit = async (
    values: FulfillMentDto,
    helpers: FormikHelpers<any>
  ) => {
    let changedData = isEditing
      ? Forms.getChangedValues(values, fulfillment)
      : values

    try {
      helpers.setErrors({})
      helpers.setSubmitting(true)
      let savedData = await FulfillmentActions.save(params.id, {
        ...changedData,
        orderId: values.fulfillOrder?.order?.id,
        shippingAddress: values.shippingAddress,
        fulfillOrderId: values.fulfillOrder?.id,
      })
      if (savedData?.id) {
        setFulfillMent((current: any) => ({ ...current, ...savedData }))
        Toasts.success(t(Messages.save_fulfillment_successfully))

        // TODO optimize not using setTimeout
        !isEditing && setTimeout(() => {
          history.replace(RouteNames.FULFILLMENTS_DETAILS.replace(":id",savedData.id));
        }, 100);
      }
    } catch (error) {
      helpers.setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumb
            goBack={RouteNames.FULFILLMENTS}
            title={`${isEditing ? t(Labels.edit) : t(Labels.create_new)} ${t(
              Labels.fulfillment
            )}`.trim()}
          >
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.fulfillmentEntity}>
              <ActionButton color="success" onClick={() => validation.submitForm()}>
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create_new)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={fulfillmentLoadable}>
            <FormikForm validation={validation} className="form-horizontal">
              <FormLayout>
                <FormLayoutMain>
                  <Card>
                    <CardHeader>
                      <CardTitle>{t(Labels.order_item_information)}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="d-flex gap-1">
                          <div className="d-flex gap-1">
                            <Label className='temp'>{t(Labels.order)}</Label>
                            <a target="_blank" rel="noreferrer" href={createUrlEmbed(`${RouteNames.ORDERS_DETAIL.replace(':id', validation.values?.order?.id as string)}`)} className="ps-1">
                              <BiLinkExternal size="16" />
                            </a>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <FormikInput
                            validation={validation}
                            name="order"
                            customInput={({ handleChange, handleBlur, errorData }) => (
                              <>
                                <SearchableSelect
                                  cacheOptions
                                  defaultOptions={true}
                                  value={validation.values.order}
                                  getOptionValue={(option: OrderDto | any) => option?.number || ''}
                                  getOptionLabel={(option: OrderDto | any) => t(option?.number || '')}
                                  loadOptions={(search: string) => OrderActions.searchByOrderNumber(search)}
                                  onBlur={handleBlur}
                                  onChange={(values: any) => {
                                    validation.setFieldValue("shippingAddress", values && values?.shippingAddress)
                                    handleChange(values)
                                  }}
                                  styles={errorData.reactSelectErrorStyle}
                                />


                                {/* <FulfillOrderSelect
                                  isMulti={true}
                                  searchField={'sku'}
                                  value={validation.values?.order}
                                  onChange={(values: MultiValue<FulfillOrderDto>) => {
                                    validation.setFieldValue("shippingAddress", values && values[0]?.shippingAddress)
                                    handleChange(values)
                                  }}
                                /> */}
                                {/*<SearchableSelect*/}
                                {/*  cacheOptions*/}
                                {/*  isMulti={true}*/}
                                {/*  defaultOptions={true}*/}
                                {/*  value={validation.values?.fulfillOrders}*/}
                                {/*  getOptionLabel={(option: FulfillOrderDto) =>*/}
                                {/*    option?.sku || ""*/}
                                {/*  }*/}
                                {/*  loadOptions={(search: string) =>*/}
                                {/*    FulfillOrderActions.searchByFulfillSku(*/}
                                {/*      search*/}
                                {/*    )*/}
                                {/*  }*/}
                                {/*  onBlur={handleBlur}*/}
                                {/*  // xuanduc edit*/}
                                {/*  onChange={(value: FulfillOrderDto) => {*/}
                                {/*    validation.setFieldValue("shippingAddress", value.shippingAddress)*/}
                                {/*    handleChange(value)*/}
                                {/*  }}*/}
                                {/*/>*/}
                              </>
                            )}
                          />
                        </Col>
                        {/*<Col>*/}
                        {/*  <FormikInput*/}
                        {/*    name={"quantity"}*/}
                        {/*    type="number"*/}
                        {/*    label={t("Quantity")}*/}
                        {/*    validation={validation}*/}
                        {/*    placeholder={t("Input the product type quantity")}*/}
                        {/*    value={validation.values.quantity}*/}
                        {/*    onChange={(value: string) => {*/}
                        {/*      updateTotalBaseCost(Number(value), "quantity")*/}
                        {/*    }}*/}
                        {/*  />*/}
                        {/*</Col>*/}
                      </Row>
                      <Row>
                        <Col>
                          <FormikInput
                            name={"baseCost"}
                            type={"number"}
                            label={t(Labels.base_cost)}
                            style={{ height: 38 }}
                            placeholder={t(Labels.base_cost_hint)}
                            validation={validation}
                            value={validation.values.baseCost}
                            // onChange={(value: string) => {
                            //   updateTotalBaseCost(Number(value), "baseCost")
                            // }}
                          />
                        </Col>
                        {/*<Col>*/}
                        {/*  <FormikInput*/}
                        {/*    name={"totalBaseCost"}*/}
                        {/*    type="number"*/}
                        {/*    label={t("Total Base Cost")}*/}
                        {/*    validation={validation}*/}
                        {/*    placeholder={t("Input the product type sku")}*/}
                        {/*    value={validation.values.totalBaseCost}*/}
                        {/*  />*/}
                        {/*</Col>*/}
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle>{t(Labels.shipment)}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormikInput
                            label={t(Labels.shipment_status)}
                            tooltip={'test tooltip shippment status'}
                            name={"shipmentStatus"}
                            validation={validation}
                            customInput={({ handleChange, handleBlur, errorData }) => (
                              <StatusSelect
                                name={"status"}
                                placeholder={t(Labels.fulfill_status_hint)}
                                value={validation.values.shipmentStatus}
                                statusValues={ShipmentStatusValues}
                                statusNames={ShipmentStatusName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                styles={errorData.reactSelectErrorStyle}
                              />
                            )}
                          />
                        </Col>
                        <Col>
                          <FormikInput
                            name="trackingNumber"
                            type="text"
                            label={t(Labels.tracking_number)}
                            validation={validation}
                            placeholder={t(Labels.tracking_number_hint)}
                            value={validation.values?.trackingNumber || ""}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormikInput
                            name="carrierCode"
                            type="text"
                            label={t(Labels.carrier_code)}
                            validation={validation}
                            placeholder={t(Labels.carrier_code_hint)}
                            value={validation.values?.carrierCode || ""}
                          />
                        </Col>
                        <Col>
                          <FormikInput
                            name="carrierName"
                            type="text"
                            label={t(Labels.carrier_name)}
                            validation={validation}
                            placeholder={t(Labels.carrier_name_hint)}
                            value={validation.values?.carrierName || ""}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormikInput
                            name="trackingUrl"
                            type="text"
                            label={t(Labels.tracking_url)}
                            required={false}
                            validation={validation}
                            placeholder={t(Labels.tracking_url_hint)}
                            value={validation.values?.trackingUrl || ""}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <ShippingAddress
                    title={t(Labels.shipping_information)}
                    methodName={"shippingMethod"}
                    addressName={"shippingAddress"}
                    validation={validation}
                  />
                </FormLayoutMain>
                <FormLayoutRight>
                  <FulfilmentNote data={fulfillmentLoadable.contents} />
                </FormLayoutRight>
              </FormLayout>
            </FormikForm>
          </LoadableComponent>
        </Container>
      </div>
    </React.Fragment>
  )
}
