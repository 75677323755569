import { Messages } from 'common/messages';
import { toast } from 'react-toastify';
import { t } from "../translations";

export class Toasts {
  static info(content: string, title?: string, duration?: number) {
    toast.success(content);
  }

  static success(content: string, title?: string, duration?: number) {
    toast.success(content);
  }

  static warning(content: string, title?: string, duration?: number) {
    toast.warning(content);
  }

  static error(content: string, title?: string, duration?: number) {
    toast.error(content);
  }
}

export class Confirmations {
  static confirm(content: string, okFn: Function) {
    content = content || t(Messages.really_want_to_continue_this_action)
    const r = window.confirm(content);
    if(r == true) {
      return okFn && okFn()
    }
  }
}
