import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { t } from "core/translations";
import { Labels } from "common/labels";
import { HiMenu, HiTrash } from 'react-icons/hi';
import { useHistory, useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { fulfillServiceByIdSelector, mappingDataSelector } from "data/atoms/fulfill-service.atom";
import { preprocessPage } from "utils/utils";
import { isEmpty, omit } from "lodash";
import { FormikHelpers, useFormik } from "formik";
import { FormikInput } from "components/form/FormikInput";
import { FormikForm, FormValidation } from "components/form/FormikForm";
import { FormLabel } from "react-bootstrap";
import { RouteNames } from "routes";
import Breadcrumb from "components/layout/Breadcrumb";
import { WithPermission } from "components/common/WithPermission";
import ActionButton from "components/input/ActionButton";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { LoadableComponent } from "components/common/LoadableComponent";
import { ColumnMapping, ColumnMappingKeyName, FulfillService, FulfillServiceDto, UpdateFulfillServiceDto } from "data/services/fulfill-service.service";
import { FormLayout, FormLayoutMain } from "components/layout/FormLayout";
import { SmallContainer } from "components/layout/SmallContainer";
import { FaTrash } from "react-icons/fa";
import Select from "react-select";
import { fulfillServiceSchema } from "./validations/fulfill-service.validation";
import { Forms } from "utils/forms";
import { FulfillServiceActions } from "data/actions/fulfill-service.action";
import { Toasts } from "core/notification/notifications";
import { Messages } from "common/messages";
import { Logger } from "core/logger";

const designStorageTypeValues = {
  FILE: "file",
  FOLDER: "folder",
};

const designStorageTypeName: Record<string, string> = {
  [designStorageTypeValues.FILE]: "File",
  [designStorageTypeValues.FOLDER]: "Folder",
};

const designStorageTypeOptions = [
  {
    label: designStorageTypeName[designStorageTypeValues.FILE], 
    value: designStorageTypeValues.FILE
  },
  {
    label: designStorageTypeName[designStorageTypeValues.FOLDER], 
    value: designStorageTypeValues.FOLDER
  },
];

const positionValues = {
  START: "start",
  END: "end",
  BEFORE: "before",
  AFTER: "after",
};

const positionName: Record<string, string> = {
  [positionValues.START]: "Start",
  [positionValues.END]: "End",
  [positionValues.BEFORE]: "Before",
  [positionValues.AFTER]: "After",
};

const positionOptions = [
  { label: positionName[positionValues.START], value: positionValues.START },
  { label: positionName[positionValues.END], value: positionValues.END },
  { label: positionName[positionValues.BEFORE], value: positionValues.BEFORE },
  { label: positionName[positionValues.AFTER], value: positionValues.AFTER },
];

export const FulfillServicePage = () => {
  const history = useHistory()
  const params = useParams<Record<string, any>>();
  const isEditing = +params.id > 0;
  const [fulfillService, setFulfillService] = useState<any>({})
  const dataLoadable = useRecoilValueLoadable(fulfillServiceByIdSelector(params.id))
  const defaultMappingDataLoadable =  useRecoilValueLoadable(mappingDataSelector)

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.fulfill_service), model: fulfillService})
  }, [fulfillService])

  // TODO remove fakeRefreshDataLoadable
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(fulfillServiceByIdSelector(params.id))
  useEffect(() => {
    (dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") 
      && !isEmpty(dataLoadable.contents) 
      && fakeRefreshDataLoadable()
  }, [params.id])

  useEffect(() => {
    if (dataLoadable.state == "hasValue") {
      setFulfillService(dataLoadable.contents || {})
    }
  }, [dataLoadable.state])

  const validation = useFormik({
    initialValues: fulfillService,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: fulfillServiceSchema,
    onSubmit: (value: any, helpers) => handleSubmit(value, helpers),
  })

  useEffect(() => {
    if (!isEditing && defaultMappingDataLoadable?.state == 'hasValue') {
      validation.setFieldValue('mapping', defaultMappingDataLoadable?.contents)
    } 
  }, [defaultMappingDataLoadable])

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    try {
      helpers.setErrors({})
      helpers.setSubmitting(true)
      
      const newMapping = values?.mapping?.map((item: any) => omit(item, '__idx'))
      values = {
        ...values,
        mapping: newMapping
      }
      let changedData: UpdateFulfillServiceDto = isEditing ? Forms.getChangedValues(values, fulfillService) : values;
      let result = await FulfillServiceActions.save(fulfillService.id || "", changedData)
      
      if (result?.id) {
        setFulfillService((current: any) => ({
          ...current,
          ...result
        }))
        !isEditing && setTimeout(() => {
          history.replace(RouteNames.FULFILL_SERVICES_DETAIL.replace(":id", result.id));
        }, 100);
        Toasts.success(t(Messages.save_service_successfully));
      }
    } catch (e) {
      helpers.setSubmitting(false);
      Logger.error("submit error", e);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <SmallContainer>
          <Breadcrumb goBack={RouteNames.FULFILL_SERVICES} title={isEditing ? t(Labels.edit_fulfill_service) : t(Labels.create_fulfill_service)}>
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.fulfillServiceEntity}>
              <ActionButton color="success" onClick={() => validation.submitForm()} >
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable}>
            <FormikForm validation={validation} className="form-horizontal">
              <FormLayout>
                <FormLayoutMain>
                  <FulfillServiceForm isEditing={isEditing} validation={validation} />
                </FormLayoutMain>
              </FormLayout>
            </FormikForm>
          </LoadableComponent>
        </SmallContainer>
      </div>
    </React.Fragment>
  )
}

export interface FulfillServiceFormProps {
  validation: FormValidation
  isEditing?: boolean
}

export const FulfillServiceForm = (props: FulfillServiceFormProps) => {
  const { validation, isEditing } = props
  const [countAddMapping, setCountAddMapping] = useState(1)
  const [maxIdx, setMaxIdx] = useState<number>(0)

  const createMappingArray = (mapping: any) => {
    let newMapping = !mapping ? [{}] : [...mapping]

    // if (!newMapping?.length || !isEmpty(omit(newMapping[newMapping.length - 1], "__idx"))) {
    //   newMapping.push({ __idx: 0 } as any)
    // }

    return newMapping.map((item: any, idx: number) => ({ ...item, __idx: idx }))
  }
  
  useEffect(() => {
    if (validation.values?.mapping) {
      let mappingData = validation.values?.mapping
      let newMapping = createMappingArray(mappingData)

      setMaxIdx(newMapping?.length - 1)
      validation.setFieldValue('mapping', newMapping)
    }
  }, [isEmpty(validation.values)])

  const addMapping = () => {
    if(countAddMapping > 0){
      let __idx = maxIdx
      const newMapping = Array.from({length: countAddMapping}, (v, idx) => {
        __idx++
        return {__idx}
      })
      setMaxIdx(__idx)
      validation.setFieldValue("mapping", [...(validation.values?.mapping || []), ...newMapping])
    }
  }

  const handleFillDefaultMappingData = async () => {
    try {
      let defaultMappingData: any = await FulfillServiceActions.getDefaultMappingData()
      
      if (!isEmpty(defaultMappingData)) {
        const currentMapping = validation.values?.mapping
        const currentKeyValues = currentMapping?.map((item: any) => item?.key)
        
        let newDefaultMappingData = defaultMappingData
          ?.filter((item: any) => !currentKeyValues?.includes(item?.key))
          ?.map((item: any, index: number) => ({ ...item, __idx: maxIdx + index + 1 }))
        setMaxIdx(maxIdx + newDefaultMappingData?.length)

        validation.setFieldValue('mapping', [ ...(currentMapping || []), ...newDefaultMappingData ])
        Toasts.success(t(Messages.fill_default_mapping_data_success));
      }
    } catch (err) {
      Toasts.error(Messages.fill_default_mapping_data_error)
    }
  }

  return (
    <Card>
      <CardBody>
        <Row className="d-flex mb-2">
          <Col className="d-flex flex-column gap-2">
            <Row>
              <Col>
                <FormikInput
                  validation={validation}
                  name={'code'}
                  label={t(Labels.code)}
                  placeholder={t(Labels.code_hint)}
                />
              </Col>
              <Col>
                <FormikInput
                  validation={validation}
                  name={'name'}
                  label={t(Labels.name)}
                  placeholder={t(Labels.name_hint)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput
                  name="skuFormat.position"
                  validation={validation}
                  label={t(Labels.sku_position)}
                  placeholder={t(Labels.sku_position_hint)}
                  customInput={({ handleChange, handleBlur, errorData }) => (
                    <Select
                      isSearchable={true}
                      options={positionOptions}
                      value={positionOptions.filter(option => 
                        option.value == validation?.values?.skuFormat?.position).pop()
                      }
                      getOptionValue={(option: any) => option.value}
                      getOptionLabel={(option: any) => option.label || option.value}
                      onBlur={handleBlur}
                      onChange={(option: any) => validation.setFieldValue('skuFormat.position', option?.value)}
                      styles={errorData.reactSelectErrorStyle}
                    />
                  )}
                />
              </Col>
              <Col>
                <FormikInput
                  validation={validation}
                  name={'skuFormat.searchText'}
                  label={t(Labels.sku_search_text)}
                  value={validation?.values?.skuFormat?.searchText || ''}
                  placeholder={t(Labels.sku_search_text_hint)}
                  onChange={(value: any) => validation.setFieldValue('skuFormat.searchText', value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput
                  name="designStorageType"
                  validation={validation}
                  label={t(Labels.design_storage_type)}
                  placeholder={t(Labels.design_storage_type_hint)}
                  customInput={({ handleChange, handleBlur, errorData }) => (
                    <Select
                      isSearchable={true}
                      options={designStorageTypeOptions}
                      value={designStorageTypeOptions.filter(option => option.value == validation?.values?.designStorageType).pop()}
                      getOptionValue={(option: any) => option.value}
                      getOptionLabel={(option: any) => option.label || option.value}
                      onBlur={handleBlur}
                      onChange={(value: any) => handleChange(value.value)}
                      styles={errorData.reactSelectErrorStyle}
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex flex-column">
          <Col>
            <Label className="col-sm-3 pt-0 mb-2 col-form-label">{t(Labels.mapping_data)}</Label>
          </Col>
          <Col>
            <MappingdataDraggableTable validation={validation}/>
          </Col>
        </Row>
        <Row className="mt-2 d-flex justify-content-between">
          <Col>
            <InputGroup className="col-auto" style={{zIndex: 1}}>
              <Input
                name={"countAddMapping"}
                values={countAddMapping}
                min={1}
                defaultValue={1}
                type="number"
                onChange={event => setCountAddMapping(+event.target.value)}
                className="d-inline-block"
                style={{ maxWidth: 80 }}
              />
              <InputGroupText>
                {t(Labels.rows)}
              </InputGroupText>
              <Button className="ms-1 flex-1" color="primary" onClick={addMapping}>
                {t(Labels.add_rows)}
              </Button>
            </InputGroup>
          </Col>
          <Col>
            <ActionButton
              onClick={() => handleFillDefaultMappingData()}
              color="primary"
              className="position-absolute me-3"
              style={{right: 0}}
            >
              {t(Labels.fill_default_mapping_data)}
            </ActionButton>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export const MappingdataDraggableTable = (props: any) => {
  const { validation } = props

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return 
    }
    
    let newRows = validation.values?.mapping
    newRows = reOrder(validation.values?.mapping, result.source.index, result?.destination?.index)

    validation.setFieldValue('mapping', newRows)
  }
  
  // a little function to help us with reordering the result
  const reOrder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onChangeValue = (value: string, index: number, keyField: string) => {
    let newMapping = validation.values?.mapping?.map((item: ColumnMapping, idx: number) => ({
      ...item,
      [`${keyField}`]: index == idx ? value : item[`${keyField}` as keyof typeof item]
    }))
    validation.setFieldValue("mapping", newMapping)
  }

  const checkEmptyRow = (row: any) => {
    let newRow = omit(row, '__idx')
    if (!isEmpty(newRow)) {
      return !Object.keys(newRow)?.some((key: string, index: number) => !isEmpty(newRow[key]))
    } else {
      return isEmpty(newRow)
    }
  }

  const handleDeleteRow = (position: number) => {
    let newMapping = validation.values?.mapping?.filter((item: any) => item?.__idx != position)
    validation.setFieldValue('mapping', newMapping)
  }

  return (
    <DragDropContext onDragEnd={(result: DropResult) => handleDragEnd(result)}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <table ref={provided.innerRef} className={'draggable-table'}>
            <thead>
              <tr>
                <th></th>
                <th>{t(Labels.key)}</th>
                <th>{t(Labels.name)}</th>
                <th>{t(Labels.column)}</th>
                <th>{t(Labels.action)}</th>
              </tr>
            </thead>
            <tbody>
              {
                validation.values?.mapping?.map((row: any, index: number) => (
                  <Draggable 
                    key={row?.__idx} 
                    index={index} 
                    draggableId={row?.__idx?.toString() || index.toString()} 
                    isDragDisabled={checkEmptyRow(row) ? true : false}
                  >
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        >
                        <td 
                          style={{ width: "50px"}} 
                          {...provided.dragHandleProps}
                          >
                          <div className="d-flex justify-content-center">
                            { checkEmptyRow(row) ? null : <HiMenu size={18}/> }
                          </div>
                        </td>
                        <td>
                          <FormikInput
                            type="text"
                            validation={validation}
                            name={'key' + index}
                            value={row.key}
                            onChange={(value: string) => onChangeValue(value, index, ColumnMappingKeyName.KEY)}
                            placeholder={t(Labels.key_hint)}
                          />
                        </td>
                        <td>
                          <FormikInput
                            validation={validation}
                            name={'fromName' + index}
                            value={row.fromName}
                            onChange={(value: string) => onChangeValue(value, index, ColumnMappingKeyName.NAME)}
                            placeholder={t(Labels.name_hint)}
                          />
                        </td>
                        <td>
                          <FormikInput
                            validation={validation}
                            name={'exportColumn' + index}
                            value={row.exportColumn}
                            onChange={(value: any) => onChangeValue(value, index, ColumnMappingKeyName.COLUMN)}
                            placeholder={t(Labels.column_hint)}
                          />
                        </td>
                        <td style={{ width: "55px"}}>
                          <div 
                            className="d-flex justify-content-center" 
                            onClick={() => handleDeleteRow(row?.__idx)}
                            style={{cursor: 'pointer'}}
                          >
                            <div className="p-2 ps-3 pe-3">
                              <FaTrash size={13} color={'red'} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  )
}