import { Labels } from "common/labels";
import { FormikInput } from "components/form/FormikInput";
import { t } from "core/translations";
import { getListTeamSelector } from "data/atoms/account.atom";
import { IdentityServices } from "data/services/identity.service";
import { FormikHandlers, FormikHelpers, FormikState } from "formik"
import { ComponentProps, useEffect, useState } from "react"
import { ActionMeta } from "react-select"
import AsyncSelect from "react-select/async";
import { useRecoilValueLoadable } from "recoil";

export interface TeamDto {
  name: string
  ownerId: number | string
  id: string,
}

export interface TeamsSelectProps extends ComponentProps<any> {
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>
  value?: TeamDto | any
  onChange: (value: any, actionMeta?: ActionMeta<any>) => void
  teamId?: string | number
  label?: string
  placeholder?: string
}

const TeamsSelect = (props: TeamsSelectProps) => {
  let { value, validation, onChange, label, placeholder, teamId, ...rest} = props
  // const teamsData = useRecoilValueLoadable(getListTeamSelector)
  const [teams, setTeams] = useState([])

  // useEffect(() => { teamsData.state === 'hasValue' && setTeams(teamsData.contents) }, [teamsData.contents])
  const loadOptions = async () => {
    const teamList = await IdentityServices.getTeamList()
    setTeams(teamList)
    return teamList
  }

  return (
    <FormikInput
      validation={validation}
      name={'teamId'}
      label={label || ''}
      customInput={({ handleChange, handleBlur, errorData }) => (
        <AsyncSelect
          isSearchable
          cacheOptions
          defaultOptions={true}
          value={value || teams?.find((team: TeamDto) => team?.id == teamId) || null}
          getOptionValue={(option: TeamDto) => option ? option?.id : ''}
          getOptionLabel={(option: TeamDto) => option ? option?.name : ''}
          loadOptions={loadOptions}
          onBlur={validation?.handleBlur}
          placeholder={placeholder || t(Labels.select_teams_hint)}
          onChange={props.onChange}
          {...rest}
        />
      )}
    />
  )
}

export default TeamsSelect