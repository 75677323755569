import { useRecoilValueLoadable } from "recoil";
import { isEmpty, omit } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { createCustomSelectStyles, ROW_HEIGHT } from "../../components/bulk/BulkEditor";
import DataGrid, {
  Column,
  CopyEvent,
  EditorProps,
  FillEvent,
  PasteEvent,
  RowsChangeData,
  TextEditor
} from "react-data-grid";
import { t } from "core/translations";
import { Button, Card, Input } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import AsyncSelect, { AsyncProps } from "react-select/async";
import { ProductTypeActions } from "../../data/actions/product-type.action";
import { customilyTemplateNameSelector } from "../../data/atoms/artwork.atom";
import ActionButton from "../../components/input/ActionButton";
import Breadcrumb from "../../components/layout/Breadcrumb";
import ReactDOM from "react-dom";
// import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { ArtworkTemplateActions } from "../../data/actions/artwork-template.action";
import { ArtworkDto } from "../../data/services/artwork.service";
import { ArtworkActions } from "../../data/actions/artwork.action";
import { Toasts } from "../../core/notification/notifications";
import { Messages } from "../../common/messages";
import { useHistory } from "react-router-dom";
import { RouteNames } from "../../routes";
import { Labels } from "common/labels";
import { SaleChannelActions } from "../../data/actions/sale-channel.action";
import { Loading } from "../../components/common/Loading";

interface MultiValueTextEditorProps {
  name: string
  value: string
  onCommit: Function
}

class MultiValueTextEditor extends React.Component {
  constructor(props: MultiValueTextEditorProps) {
    super(props);
    // eslint-disable-next-line react/prop-types
    this.state = props.value as any
  }

  getValue() {
    // eslint-disable-next-line react/prop-types
    return  {[(this.props as any).name]: this.state }
  }

  getInputNode() {
    // eslint-disable-next-line react/no-find-dom-node
    let findDOMNode: Element = ReactDOM.findDOMNode(this) as Element;
    return findDOMNode && findDOMNode.getElementsByTagName("input")[0];
  }

  handleChangeComplete = (value: string) => {
    let stateValue = value.split(',').map((str) => str.trim()).filter(str => str)
    // eslint-disable-next-line react/prop-types
    this.setState({ [(this.props as any).name]: stateValue }, () => (this.props as any).onCommit());
  };

  render() {
    return (
      <Input
        type="text"
        onChange={(event) => this.handleChangeComplete(event.target.value)}
      />
    );
  }
}

export type SelectEditorProps = { embedHeight: number } & AsyncProps<any, any, any>
export const SelectEditor = (props: SelectEditorProps) => {
  const { embedHeight, autoFocus, defaultMenuIsOpen, styles, ...rest } = props;
  const selectRef = useRef<any>();

  return (
    <Select
      ref={selectRef}
      menuPortalTarget={document.body}
      onMenuClose={() => selectRef.current.blur()}
      onKeyDown={(e: any) => {
        if(e.keyCode == 13){
          e.stopPropagation();
        }
      }}
      styles={{
        ...createCustomSelectStyles(embedHeight),
        ...(styles || {})
      }}
      {...{
        autoFocus: true,
        defaultMenuIsOpen: true,
        placeholder: "Select...",
        ...rest
      }}
      closeMenuOnSelect={false}
    />
  );
};

export type AsyncSelectEditorProps = { embedHeight: number } & AsyncProps<any, any, any>
export const AsyncSelectEditor = (props: SelectEditorProps) => {
  const { embedHeight, autoFocus, defaultMenuIsOpen, styles, ...rest } = props;
  const selectRef = useRef<any>();

  return (
    <AsyncSelect
      ref={selectRef}
      menuPortalTarget={document.body}
      onMenuClose={() => selectRef.current.blur()}
      onKeyDown={(e: any) => {
        if(e.keyCode == 13){
          e.stopPropagation();
        }
      }}
      styles={{
        ...createCustomSelectStyles(embedHeight),
        ...(styles || {})
      }}
      {...{
        autoFocus: true,
        defaultMenuIsOpen: true,
        placeholder: "Select...",
        ...rest
      }}
      closeMenuOnSelect={false}
    />
  );
};

export type CreatableSelectEditorProps = { embedHeight: number } & AsyncProps<any, any, any>
export const CreatableSelectEditor = (props: CreatableSelectEditorProps) => {
  const { embedHeight, autoFocus, defaultMenuIsOpen, styles, ...rest } = props;
  const selectRef = useRef<any>();

  return (
    <CreatableSelect
      ref={selectRef}
      isMulti={true}
      menuPortalTarget={document.body}
      styles={{
        ...createCustomSelectStyles(embedHeight),
        ...(styles || {})
      }}
      {...{
        autoFocus: true,
        placeholder: "Create...",
        ...rest
      }}
    />
  );
};

export interface ProductTypeEditorProps {
  onChange: (values: any) => void
}
//
// export const ProductTypeEditor = (props: ProductTypeEditorProps) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [options, setOptions] = useState([]);
//   const [selected, setSelected] = useState([]);
//
//   const handleSearch = async (query: string) => {
//     setIsLoading(true);
//
//     try {
//       let productTypes = await ProductTypeActions.searchByCode(query)
//       setOptions(productTypes)
//     } catch (e) {
//       Logger.error('')
//     } finally {
//       setIsLoading(false)
//     }
//   }
//
//   return (
//
//     <div style={{ position: "relative", zIndex: 9999 }}>
//       <AsyncTypeahead
//         style={{ position: "relative", zIndex: 9999 }}
//         filterBy={() => true}
//         className={'rdg-text-editor'}
//         id="async-example"
//         isLoading={isLoading}
//         labelKey="code"
//         multiple={true}
//         minLength={1}
//         onChange={props.onChange}
//         onSearch={handleSearch}
//         options={options}
//         autoFocus={true}
//         selected={selected}
//         placeholder="Search for product type..."
//       />
//     </div>
//   )
// }

interface clickPositionDto {
  id: number, 
  key: string
}

export interface ArtworkBulkCreatePageType {
  ids?: string[]
  onClose?: any
}

export interface BulkCreateDataType {
  data?: ArtworkDto[],
  loading?: boolean
}

export const ArtworkBulkCreatePage = (props: ArtworkBulkCreatePageType) => {
  const { ids } = props
  const history = useHistory()
  const [bulkCreateData, setBulkCreateData] = useState<BulkCreateDataType>({ loading: Number(ids?.length) > 0 });
  const [gridRowData, setGridRowData] = useState<any>([]);
  const [customilyTemplates, setCustomilyTemplates] = useState<any>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const customilyTemplateLoadable = useRecoilValueLoadable(customilyTemplateNameSelector);
  const [searchText, setSearchText] = useState('')
  let clickPosition = {} as clickPositionDto

  // const bulkEditFulfillOrderLoadable = useRecoilValueLoadable(saleChannelIdsSelector(bulkEditArtworkIds))
  useEffect(() => {
    if(customilyTemplateLoadable.state == 'hasValue') {
      setCustomilyTemplates(customilyTemplateLoadable.contents)
    }
  }, [customilyTemplateLoadable.contents])

  useEffect(() => {
    let data = bulkCreateData.data?.map((row: any, index: number) => ({ ...row, num: row.num || index + 1})) || []
    let lastRow = data[data.length - 1];
    if(isEmpty(data) || !isEmpty(omit(lastRow, 'num'))) {
      data.push({ num: data.length + 1 })
    }
    setGridRowData(data)
  }, [bulkCreateData.data])

  const loadArtworksByIds = async (ids: string[]) => {
    try {
      if (ids?.length) {
        setBulkCreateData({ loading: true })
        let response = await ArtworkActions.getBulkEditArtwork(ids)
        setBulkCreateData({
          data: response?.pageItems,
          loading: false
        })
      }
    } catch (e) {
      setBulkCreateData((prevState => ({
        ...prevState,
        loading: false
      })))
    }
  }

  useEffect(() => { loadArtworksByIds(ids as string[]) }, [ids])

  const removeRow = (num: number | string) => {
    setGridRowData((prev: any) => {
      let newData = prev.filter((item: any) => item.num !== num).map((item: any, idx: number) => ({...item, num: idx + 1}))
      return newData
    })

    setBulkCreateData((prev) => ({
      ...prev,
        data: (prev.data || []).filter((item: any) => item.num !== num).map((item, idx) => ({...item, num: idx + 1}))
    }))
  }

  const columns: Column<any, any>[] = [
    {
      key: "num",
      name: t(Labels.num),
      frozen: true,
      width: 50,
      minWidth: 50,
      editable: false,
      headerCellClass: 'text-center',
      // eslint-disable-next-line react/display-name
      formatter: ({ row }: any = {}) => (
        <div className='text-center align-items-center'>
          {row.num}
        </div>
      )
    },
    {
      key: "sku",
      name: t(Labels.sku),
      editable: true,
      frozen: true,
      minWidth: 120,
      editor: TextEditor,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {
            row?.sku ? <p>{row.sku}</p> : <p style={{ opacity: 0.7 }}>{"Ex: T-SHIRT"}</p>
          }
        </div>
      ),
    },
    {
      key: "title",
      name: t(Labels.title),
      editable: true,
      minWidth: 360,
      editor: TextEditor,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row.title ? row.title : <p style={{ opacity: 0.7 }}>{"Ex: Customizable T-shirt"}</p>}
        </div>
      ),
    },
    {
      key: "categories",
      name: t(Labels.categories),
      editable: true,
      minWidth: 180,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row.categories?.length ? row.categories.join(',') : <p style={{ opacity: 0.7 }}>{"Ex: Cagegory1,Category2"}</p>}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: any = {}) => (
        <Input
          autoFocus={true}
          defaultValue={row.categories?.join(',') || ''}
          onChange={(event) => {
            onRowChange({ ...row, categories: event.target.value.split(',').map(str => str?.trim()).filter(str => str) });
          }}
        />
      )
    },
    {
      key: "tags",
      name: t(Labels.tags),
      editable: true,
      minWidth: 180,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row.tags?.length ? row.tags.join(',') : <p style={{ opacity: 0.7 }}>Ex: Tag1,Tag2</p>}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: any = {}) => (
        <Input
          autoFocus={true}
          defaultValue={row.tags?.join(',') || ''}
          onChange={(event) => {
            onRowChange({ ...row, tags: event.target.value.split(',').map(str => str?.trim()).filter(str => str) })
          }}
          onBlur={(event) => onRowChange({ ...row, tags: event.target.value.split(',').map(str => str?.trim()).filter(str => str) }, true)}
        />
      )
    },
    {
      key: "template",
      name: t(Labels.custom_template),
      editable: true,
      minWidth: 250,
      cellClass: 'data-grid-cell-float',
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row.template ? row.template.name : <p style={{ opacity: 0.7 }}>{t(Labels.custom_template_hint)}</p>}
        </div>
      ),

      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: EditorProps<any>) => {
        return <AsyncSelect
          value={row.template}
          autoFocus={true}
          defaultOptions={true}
          cacheOptions={true}
          openMenuOnFocus={true}
          menuPortalTarget={document.body}
          getOptionValue={(option: any) => option?.id}
          getOptionLabel={(option: any) => option?.name}
          loadOptions={(searchText: string) => ArtworkTemplateActions.searchTemplateByName(searchText, true)}
          onChange={(value: any) => onRowChange({ ...row, template: value?.id ? value : undefined }, true)}
          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
          styles={createCustomSelectStyles(ROW_HEIGHT)}
        />
      }
    },
    {
      key: "publicationChannels",
      name: t(Labels.stores),
      editable: true,
      width: 200,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row.publicationChannels?.map ? row.publicationChannels.map((pt: any) => pt.name).join(',') : <p style={{ opacity: 0.7 }}>{t(Labels.sale_channels_select_hint)}...</p>}
        </div>
      ),

      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: EditorProps<any>) => {
        return <AsyncSelect
          isMulti={true}
          value={row.publicationChannels}
          defaultValue={row.publicationChannels}
          autoFocus={true}
          defaultOptions={true}
          cacheOptions={true}
          openMenuOnFocus={true}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          menuPortalTarget={document.body}
          getOptionValue={(option: any) => option?.id}
          getOptionLabel={(option: any) => option?.name}
          loadOptions={SaleChannelActions.searchByName}
          onChange={(values: any) => onRowChange({ ...row, publicationChannels: values }, true)}
          styles={{
            ...createCustomSelectStyles(ROW_HEIGHT), 
            control: (prev) => ({
              ...prev,
              "> div:first-child": { 
                overflow: "hidden auto"
              }
            })
          }}
          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        />
      }
    },
    {
      key: "productTypes",
      name: t(Labels.product_types),
      editable: true,
      width: 600,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row.productTypes?.map ? row.productTypes.map((pt: any) => pt.code).join(',') : <p style={{ opacity: 0.7 }}>{t(Labels.product_types_hint)}</p>}
        </div>
      ),

      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: EditorProps<any>) => {
        return <AsyncSelect
          isMulti={true}
          value={row.productTypes}
          autoFocus={true}
          defaultOptions={true}
          cacheOptions={true}
          openMenuOnFocus={true}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          menuPortalTarget={document.body}
          getOptionValue={(option: any) => option?.id}
          getOptionLabel={(option: any) => option?.code}
          loadOptions={(value: any) => ProductTypeActions.searchByCode(value)}
          onChange={(values: any) => {
            setSearchText(searchText)
            onRowChange({ ...row, productTypes: values })
          }}
          inputValue={searchText}
          onInputChange={(value: any) => {
            setSearchText(value)
          }}
          styles={{
            ...createCustomSelectStyles(ROW_HEIGHT), 
            control: (prev) => ({
              ...prev,
              "> div:first-child": { 
                overflow: "hidden auto"
              }
            })
          }}
          isSearchable
          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        />
      }
    },
    {
      key: "customilyTemplate",
      name: t(Labels.customily_template),
      editable: true,
      width: 210,
      minWidth: 210,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row.customilyTemplate ? row.customilyTemplate : <p style={{ opacity: 0.7 }}>{t(Labels.customily_template_hint)}</p>}
        </div>
      ),
      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: EditorProps<any>) => (
        <SelectEditor
          options={customilyTemplates.map((value: any) => ({ label: value, value }))}
          embedHeight={ROW_HEIGHT}
          value={{label: row.customilyTemplate, value: row.customilyTemplate }}
          onChange={(selectedItem: any, meta: any) =>
            onRowChange({ ...row, customilyTemplate: selectedItem.value }, true)
          }
          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        />
      )
    },
    {
      key: "customilyProductId",
      name: t(Labels.customily_product_id),
      editable: true,
      minWidth: 262,
      editor: TextEditor,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row.customilyProductId ? row.customilyProductId : <p style={{ opacity: 0.7 }}>Ex: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx</p>}
        </div>
      ),
    },
    {
      key: "",
      name: "",
      minWidth: 50,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => {
        const indexLastChlid = gridRowData?.length - 1
        if(row.num == gridRowData[indexLastChlid].num) return <></>

        return <button onClick={() => removeRow(row.num)} className="w-100 border-0 bg-transparent h-100 d-flex align-items-center justify-content-center ">
          <i className="mdi mdi-delete font-size-18 danger text-danger" />
        </button>
      
      }
    },
  ];

  function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<any>) {
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

  const handlePaste = ({
                         sourceColumnKey,
                         sourceRow,
                         targetColumnKey,
                         targetRow
                       }: PasteEvent<any>) => {
    const incompatibleColumns = ["num"];
    if (sourceColumnKey !== targetColumnKey
      && (incompatibleColumns.includes(targetColumnKey) || incompatibleColumns.includes(sourceColumnKey))) {
      return targetRow;
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
  };

  function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<any>): void {
    if (window.isSecureContext) {
      let value = sourceRow[sourceColumnKey];
      navigator.clipboard.writeText(value);
    }
  }

  function rowKeyGetter(row: any) {
    return `${row.num}-${row.sku}`;
  }

  const onRowsChange = (rows: any, data: RowsChangeData<any>) => {
    setBulkCreateData(prevState => ({
      ...prevState,
      data: rows,
    }))
  };

  const submitData = async () => {
    let createData: ArtworkDto[] = []
    for(let artwork of bulkCreateData.data || []) {
      let data = omit(artwork, 'num');
      if(isEmpty(data) || Object.values(data).every(value => isEmpty(value))) continue

      // Validate data
      if(!artwork.sku) {
        Toasts.warning(t(Messages.field_required, {field: t(Labels.sku)}))
        return
      }

      if(!artwork.title) {
        Toasts.warning(t(Messages.field_required, {field: t(Labels.title)}))
        return
      }

      // const products = ArtworkHelper.createProducts(artwork)
      createData.push(data)
    }

    if (ids?.length) {
      const response = await ArtworkActions.bulkUpdateManyArtwork(createData)
    } else {
      const response = await ArtworkActions.createMany(createData)
    }

    // if(response) {
    //   history.replace(RouteNames.ARTWORKS)
    // }
  }

  const addColumn = () => {
    setGridRowData((prev: any) => {
      const newData =[...prev]
      newData.push({num: newData.length + 1})
      return newData
    })
  }

  const handleRowClick = (row: any, column: any) => {
    clickPosition = {
      id: row?.num,
      key: column?.key
    }
  }

  const handleKeyDown = async (event: any) => {
    event.preventDefault()
    let charCode = String.fromCharCode(event.which).toLowerCase();

    if((event.ctrlKey || event.metaKey) && charCode === 'v') {
      let text = await navigator.clipboard.readText()
      let rowExcels = text.split(/\r\n|\n/)
      let tempGridRowData = gridRowData
      let startColumnIndex = columns.findIndex((column: any) => column.key == clickPosition?.key)
      rowExcels = rowExcels.filter((item: string) => item != '')
      const propertiesArr = ['categories', 'tags']

      for (let row = clickPosition.id; row < clickPosition.id + rowExcels.length; row++) {
        let tempArr = rowExcels[row - clickPosition.id].split('\t').slice(0, 4)
        tempGridRowData[row - 1] ? null : tempGridRowData.push({num: tempGridRowData.length + 1})
        
        for (let col = startColumnIndex; col < startColumnIndex + tempArr.length && col < columns.length - 1; col++) {
          if (propertiesArr.includes(columns[col].key)) {
            (tempGridRowData[row - 1])[columns[col].key] = (tempArr[col - startColumnIndex]).split(',')
          } else {
            (tempGridRowData[row - 1])[columns[col].key] = tempArr[col - startColumnIndex]
          }
        }
      }
      setBulkCreateData(prevState => ({
        ...prevState,
        data: tempGridRowData
      }))
    }
  }

  return (
    <div style={{ height: "100%" }} className="page-content"  >
      <Breadcrumb 
        title={ids ? t(Labels.bulk_update_artwork) : t(Labels.bulk_create_artwork)}
        goBack={ids ? false : RouteNames.ARTWORKS}
      >
        <Button color="primary" onClick={addColumn} className='me-2'>{t(Labels.add_row)}</Button>
        <ActionButton
          color="success"
          onClick={() => submitData()}
          disabled={submitting}>
          <i className="bx bx-save font-size-16 align-middle me-1"></i>
          {t(Labels.save)}
        </ActionButton>
        {props.onClose 
          ? (<Button
              color="danger"
              className="ms-2"
              onClick={props.onClose}
            >
              <i className="bx bx-x pt-1 me-1"></i>
              {t(Labels.close)}
            </Button>)
          : null
        }
      </Breadcrumb>
        {
          bulkCreateData.loading ? <Loading/> :
            <Card onKeyDown={handleKeyDown}>
              <DataGrid
                onRowClick={handleRowClick}
                columns={columns}
                rows={gridRowData}
                rowKeyGetter={rowKeyGetter}
                onRowsChange={onRowsChange}
                onFill={handleFill}
                onCopy={handleCopy}
                onPaste={handlePaste}
                rowHeight={ROW_HEIGHT}
                defaultColumnOptions={{ resizable: true }}
                className={"fill-grid rdg-dark rdg-light"}
                style={{ height: "100%", blockSize: "100%" }}
                rowClass={(row: any) => (row._bulkUpdated ? "react-data-grid-updated-row" : "react-data-grid-normal-row")}
              />
            </Card>
        }
    </div>
  );
};
