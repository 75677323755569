import { CrudService } from "./crud.service";
import { apiBaseUrls } from "data/rest-api-config";
import { RequestOptions } from "core/rest-api/rest.api";


export interface FulfillmentInvoiceDto {
  id?: string,
  tax?: number,
  note?: string,
  fulfillInvoiceStatus?: string,
  vendorInvoiceStatus?: string,
  status?: string,
  fulfillServiceId?: string
}

export const FulfillmentInvoiceServiceApiPath = {
  BASE: "/fulfillments/orders/invoices",
  WITH_ID: "/fulfillments/orders/invoices/{id}",
  QUERY: "/fulfillments/orders/invoices/query",
  BULK: "/fulfillments/orders/invoices/bulk",
  ACTIONS: '/fulfillments/orders/invoices/actions',
  BASE_COST: '/fulfillments/orders/invoices/analytics',
  IMPORT_SETTLED_INVOICES: '/fulfillments/orders/import/invoice',
}

export class FulfillmentInvoiceService extends CrudService<any, any, any> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: FulfillmentInvoiceServiceApiPath.WITH_ID,
        updateById: FulfillmentInvoiceServiceApiPath.WITH_ID,
        deleteById: FulfillmentInvoiceServiceApiPath.WITH_ID,
        query: FulfillmentInvoiceServiceApiPath.QUERY,
        base: FulfillmentInvoiceServiceApiPath.BASE,
        deleteMany: FulfillmentInvoiceServiceApiPath.BULK,
        updateMany: FulfillmentInvoiceServiceApiPath.BULK,
      },
    })
  }
  async handleImportRequest(data: FormData, apiPath: string, options?: RequestOptions) {
    let response = await this.restApi.post(apiPath, {
      data: data,
      timeout: 60 * 60000,
      ...(options || {})
    })
    return response
  }

  async getListBaseCost (query : any) {
    let response = await this.restApi.post(FulfillmentInvoiceServiceApiPath.BASE_COST, query)
    return this.returnDataOrThrow(response)
  }

  importSettledInvoice(data: FormData, options?: RequestOptions) {
    return this.handleImportRequest(data, `${FulfillmentInvoiceServiceApiPath.IMPORT_SETTLED_INVOICES}`, options)
  }
  

}

export const FulfillmentInvoiceServices = new FulfillmentInvoiceService()
