import { defaultPageInfo } from "../../components/table/RemoteTable";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "core/rest-api/query-builder";
import { ReportServices } from "../services/reports.service";
import { omit } from "lodash";

export const reportQueryState = atomFamily<QueryParams<any>, any>({
  key: "ReportQueryState",
  default: (params: any) => {
    return { ...params }
  },
})

export const reportSelector = selector({
  key: "reportSelector",
  get: async ({ get }) => {
    let query = get(reportQueryState(defaultPageInfo))
    let pageData = await ReportServices.query(query, {
      snoozeErrorMessage: true,
    })
    return pageData
  },
})

export const reportIdState = atomFamily<QueryParams<any>, any>({
  key: "reportsIdState",
  default: (id: any) => {
    return id;
  }
});

export const reportByIdSelector = selectorFamily({
  key: 'reportByIdSelector',
  get: (id: any) => async ({get}) => {
    let reportId = get(reportIdState(id));

    if (+reportId > 0) {
      let data = await ReportServices.getById(+reportId, { snoozeErrorMessage: false });
      return data
    }
    return {};
  }
})


export const reportUpdateQueryState = atomFamily<QueryParams<any>, any>({
  key: "ReportUpdateQueryState",
  default: (params: any) => {
    return { ...params, filter: {} }
  },
})

export const reportUpdateSelector = selector({
  key: "reportUpdateSelector",
  get: async ({ get }) => {
    let query = get(reportQueryState(defaultPageInfo))
    let pageData = await ReportServices.query(query, {
      snoozeErrorMessage: true,
    })
    return pageData
  },
})



