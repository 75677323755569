import MetaTags from "react-meta-tags";
import React, { useLayoutEffect, useState } from "react";

import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import {
  loginErrorState,
  permissionsSelector,
  userSelector,
  userTeamSelector,
  userTenantSelector,
  userTokenSelector
} from "../../data/atoms/auth.atom";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Labels } from "../../common/labels";
import { RouteNames } from "../../routes";
import { IdentityActions } from "../../data/actions/auth.action";
import { createLinkEmbedParam, navigateRouteEmbed } from "utils/embed";

const Login = (props: any) => {
  const history = useHistory();
  const [user, setUser] = useRecoilState(userSelector);
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useRecoilState(loginErrorState);
  const [token, setToken] = useRecoilState(userTokenSelector);
  const [tenant, setTenant] = useRecoilState(userTenantSelector);
  const [roles, setRoles] = useRecoilState(permissionsSelector)
  const [, setTeam] = useRecoilState(userTeamSelector);

  const validation = useFormik({
    initialValues: { username: "", password: "" },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      username: Yup.string().required(t(Labels.username_required)),
      password: Yup.string().required(t(Labels.password_required))
    }),
    onSubmit: (values) => handleSubmit(values)
  });

  const handleSubmit = async (values: any) => {
    try {
      onFormChange()

      setLoading(true);
      let success = await IdentityActions.login(values, { setUser, setToken, setLoginError, setTenant, setRoles, setTeam });
      setLoading(false);
      if(success) {
        history.replace(RouteNames.DASHBOARD)
      }
    } finally {
      setLoading(false);
    }
  };

  const onFormChange = () => {
    validation.setErrors({});
    setLoginError("");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t(Labels.app_name)}</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link {...createLinkEmbedParam("/")} className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t(Labels.welcome)}!</h5>
                        <p>{t(Labels.welcome_hint)}</p>
                      </div>
                    </Col>
                    <Col xs={4} className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link {...createLinkEmbedParam("/")} className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      onChange={onFormChange}
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {loginError ? <Alert color="danger">{loginError}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">{t(Labels.username)}</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder={t(Labels.username_hint)}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{t(Labels.password)}</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder={t(Labels.password_hint)}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {t(Labels.remember_signin)}
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          disabled={loading}
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {t(Labels.login)}
                        </button>
                      </div>

                      {/*<div className="mt-4 text-center">*/}
                      {/*  <Link {...createLinkEmbedParam(")}/forgot-password" className="text-muted">*/}
                      {/*    <i className="mdi mdi-lock me-1" />*/}
                      {/*    {t(Labels.forgot_password)}*/}
                      {/*  </Link>*/}
                      {/*</div>*/}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {t(Labels.does_not_have_account)}{" "}
                  {/*<Link {...createLinkEmbedParam(")}/register" className="fw-medium text-primary">*/}
                  {/*  {" "} {t(Labels.register_now)} {" "}*/}
                  {/*</Link>{" "}*/}
                </p>
                <p>
                  © {new Date().getFullYear()} {t(Labels.app_name)}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
