import * as Yup from 'yup'
import { Messages } from 'common/messages'
import { TestContext } from 'yup/lib/util/createValidation';
import { t } from "../../../core/translations";
import { Labels } from 'common/labels';

const isValidJSON = (text: string) => {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
}

export const storageSettingSchema = Yup.object({
  type: Yup.string().required(),
  config: Yup.object({
    credentials: Yup.string().min(1).required(t(Messages.field_required, { field: t(Labels.credentials) })),
    driveId: Yup.string().required(t(Messages.field_required, { field: t(Labels.bucket_id) })),
    parentFolder: Yup.string().required(t(Messages.field_required, { field: t(Labels.upload_folder_name) })),
  })
    .test('testStorage', (value: any, context: TestContext) => {
      if (isValidJSON(context.parent?.config?.credentials)) {
        return true
      } else {
        return context.createError({ message: t(Messages.error_type_storage_credentials) })
      }
    })
})
