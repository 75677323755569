import React, { useEffect, useState } from "react";

import { Alert, Badge, Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from "reactstrap";
import { FormLayout, FormLayoutMain, FormLayoutRight } from "../../components/layout/FormLayout";
import { fulfillOrderIdSelector, fulfillOrderIdState } from "../../data/atoms/fulfill-order.atom";
import { t } from "../../core/translations";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormikInput } from "../../components/form/FormikInput";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { FormikHandlers, FormikHelpers, FormikState, useFormik } from "formik";
import { FulfillLineItemSelect } from "./components/fulfill-line-item.select";
import { CreateFulfillOrderDto, FulfillOrderDto } from "../../data/services/fulfill-order.service";
import { ShippingAddress, shippingAddressSchema } from "../../components/form/ShippingAddress";
import { StatusSelect } from "../../components/common/StatusComponent";
import {
  EditableFulfillStatuses,
  FulfillDesignStatusName,
  FulfillDesignStatusValues,
  FulfillStatus,
  FulfillStatusIssueName,
  FulfillStatusIssueValues,
  FulfillStatusName,
  FulfillStatusValues
} from "../../types/fulfill-status.type";
import FulfillVariantSelect from "./components/fulfill-variation.select";
import { OrderSelect } from "../order/components/order.select";
import { Dates } from "../../utils/dates";
import { LoadableComponent } from "../../components/common/LoadableComponent";
import { SingleLineImage } from "../../components/form/SingleLineImage";
import { FulfillOrderActions } from "../../data/actions/fulfill-order.action";
import Breadcrumb from "../../components/layout/Breadcrumb";
import { Forms } from "../../utils/forms";
import { isEmpty, pick } from "lodash";
import { RouteNames } from "../../routes";
import { Confirmations, Toasts } from "../../core/notification/notifications";
import { OrderDto } from "../../data/services/order.service";
import { orderByIdSelector, orderIdState } from "../../data/atoms/orders.atom";
import { AdminUtils } from "../../utils/admin-utils";
import { Messages } from "../../common/messages";
import { FormikForm } from "../../components/form/FormikForm";
import { BiLinkExternal } from "react-icons/bi";
import { SearchableSelect } from "components/input/SearchableSelect";
import ActionButton from "components/input/ActionButton";
import { ArtworkActions } from "data/actions/artwork.action";
import { ArtworkDto } from "data/services/artwork.service";
import { Labels } from "common/labels";
import { OptionalLabel } from "components/common/OptionalLabel";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import { MetaTable } from "../../components/table/MetaTable";
import { AddressDetail } from "pages/order/order.page";
import { portableModalState, viewImagesState } from "data/atoms/app.atom";
import { ModalButtons, ModalContent } from "components/modal/portable-modal";
import { RiEdit2Line } from "react-icons/ri";
import { preprocessPage } from "utils/utils";
import { FulfillOrderCustomize } from "./components/fulfill-order-customize";
import { format } from "date-fns";
import { TooltipComponent } from "components/common/TooltipComponent";
import { BsCheckCircle, BsFillClockFill } from "react-icons/bs";
import { FormikInputField } from "components/form/FormikInputField";
import { SingleLineImageView  } from "components/form/SingleLineImageView";
import { ImagesInfoTypes } from "./components/gallery-images";
import { createLinkEmbedParam, createUrlEmbed } from "utils/embed";

export interface FulfillOrderFormProps {
  order?: OrderDto
}

const formatCustomHistoryDate = (dateStr: string) => {
  try {
    return format(new Date(dateStr), "dd MMM, HH:mm")
  } catch (e) {
    return t('Unknown')
  }
}

export const FulfillOrderPage = (props: FulfillOrderFormProps) => {
  const history = useHistory()
  const location = useLocation()
  const parentFulfillOrder = (location.state as any)?.fulfillOrder
  const params = useParams<Record<string, any>>()
  const isEditing = +params.id > 0
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))
  const [orderId, setOrderId] = useRecoilState(orderIdState(searchParams.orderId))
  const [fulfillOrderId, setFulfillOrderId] = useRecoilState(fulfillOrderIdState(params.id))
  const [fulfillOrder, setFulfillOrder] = useState<FulfillOrderDto>(AdminUtils.createDuplicateData(parentFulfillOrder))
  const loadable = useRecoilValueLoadable(fulfillOrderIdSelector(params.id))
  const orderLoadable = useRecoilValueLoadable(orderByIdSelector(searchParams.orderId))
  const [modalState, setModal] = useRecoilState(viewImagesState);
  const [imagesInfo, setImagesInfo] = useState<any[]>()

  const validation = useFormik<CreateFulfillOrderDto>({
    initialValues: { ...fulfillOrder, parentId: parentFulfillOrder?.id },
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: Yup.object({
      order: Yup.object().required(t(Messages.field_required, { field: t(Labels.order) })),
      lineItems: Yup.array().required(t(Messages.field_required, { field: t(Labels.line_items) })),
      sku: Yup.string().required(t(Messages.field_required, { field: t(Labels.sku) })),
      quantity: Yup.number().required(t(Messages.field_required, { field: t(Labels.quantity) })),
      status: Yup.string().required(t(Messages.field_required, { field: t(Labels.status) })),
    }),
    onSubmit: (value, helpers) => handleSubmit(value, helpers),
  })
  const setPortableModal = useSetRecoilState(portableModalState)

  useEffect(() => setFulfillOrder(AdminUtils.createDuplicateData(parentFulfillOrder)), [parentFulfillOrder])

  // TODO remove fakeRefreshDataLoadable
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(fulfillOrderIdSelector(params.id))
  useEffect(() => {
    (loadable.state == "hasValue" || loadable.state == "hasError") && !isEmpty(loadable.contents) && fakeRefreshDataLoadable()
  }, [params.id])

  useEffect(() => {
    if (searchParams.orderId && searchParams.orderId != orderId) {
      setOrderId(orderId)
    }
    if (params.id && params.id != fulfillOrderId) {
      setFulfillOrderId(params.id)
    }
  }, [params.id, searchParams.orderId])

  useEffect(() => {
    if (loadable.state == "hasValue") {
      setFulfillOrder((current: any) => ({
        ...(current || {}),
        ...loadable.contents,
      }))
    }
    if (searchParams.orderId && orderLoadable.state == "hasValue") {
      setFulfillOrder((current: any) => ({
        ...(current || {}),
        order: orderLoadable.contents,
      }))
    }
  }, [loadable.state, orderLoadable.state])

  useEffect(() => {
    if (!isEditing && validation.values.order) {
      let newShippingAddress = {
        ...validation.values.order.shippingAddress,
        phone: validation.values.order?.phone
      }
      validation.setFieldValue("shippingAddress", newShippingAddress)
      validation.setFieldValue("shippingMethod", validation.values.order.shippingMethod)
      validation.setFieldValue("customerNote", validation.values.order.customerNote)
    }

    validation.setErrors({})
  }, [validation.values.order])

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.order_item), model: fulfillOrder, primaryProperty: 'sku'})
  }, [fulfillOrder])

  useEffect(() => {
    let { order, lineItems } = validation.values
    if (!isEditing && !validation.values.quantity && lineItems) {
      validation.setFieldValue(
        "quantity",
        lineItems.map(lineItem => lineItem.quantity || 0)
      )
    }
    if (!isEditing && order) {
      let firstLineItem = lineItems && lineItems[0]
      validation.setFieldValue("sku", `${firstLineItem?.sku || "SKU"}-${order.id}`)
      validation.setFieldValue("artworkSku", `${(firstLineItem?.sku || "").split("-")[0]}`)
      validation.setFieldValue("productName", firstLineItem?.name)
    }
    validation.setErrors({})
  }, [validation.values.lineItems])

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    let changedData = isEditing ? Forms.getChangedValues(values, fulfillOrder) : values
    if (changedData.fulfillVariant) {
      changedData.fulfillVariant = pick(changedData.fulfillVariant, "id")
    }

    let savedData = await FulfillOrderActions.save(fulfillOrder.id || "", changedData)
    if (savedData?.id) {
      setFulfillOrder(savedData)
      // TODO optimize not using setTimeout
      setTimeout(() => {
        history.replace(createLinkEmbedParam(RouteNames.ORDER_ITEM_DETAIL.replace(":id", savedData.id)).to)
        Toasts.success(t(Messages.save_fulfill_successfully))
      }, 100)
    }
  }

  const handleClone = async () => {
    history.push(createLinkEmbedParam(`${RouteNames.ORDER_ITEMS}/create`).to, { fulfillOrder })
  }

  function getOrderLabel(option: OrderDto) {
    return [option?.number, option?.channelOrderId].filter(str => str).join(" - ")
  }

  const callApiCreateOrder =  async () => {
    let callApiCreateOrder = await FulfillOrderActions.createFulfillDesign(params.id) as {data: any,error: any}
    if (!callApiCreateOrder?.error) {
      Toasts.success(t(Messages.create_fulfill_design_successfully))
        if(!isEmpty(callApiCreateOrder.data)){
          validation.setFieldValue("fulfillDesign",callApiCreateOrder.data?.fulfillDesign)
          validation.setFieldValue("fulfillMockup",callApiCreateOrder.data?.fulfillMockup)
          validation.setFieldValue("fulfillDesignFolder",callApiCreateOrder.data?.fulfillDesignFolder)
        }
    }
  }

  const onClickOrderFulfillment = async () => {
    await Confirmations.confirm(
      t(Messages.confirm_request_fulfill_order),
      async () => {
        let result = await FulfillOrderActions.requestFulfillOrder(params.id) as {data: any,error: any}
        if (!result?.error && result?.data) {
          Toasts.success(t(Messages.request_fulfill_successfully))
          if(!isEmpty(result?.data)){
            validation.setFieldValue("status",result.data?.status)
            validation.setFieldValue("fulfillmentOrderId", result.data?.fulfillmentOrderId)
          }
        }
      }
    )
  }
  
  // eslint-disable-next-line react/display-name
  const editAddress = () => {
    setPortableModal({
      open: true,
      title: t(Labels.edit_shipping_address),
      size: 'lg',
      content: (
        <ShippingAddressPopup
          isEditing={isEditing}
          title={t(Labels.shipping_information)}
          methodName={"shippingMethod"}
          addressName={"shippingAddress"}
          validation={validation}
          id={isEditing ? params.id : null}
        />
      ),
    })
  }

  const sortTime = (array: any[]) => {
    return array
      ?.map((item: any) => ({ ...item }))
      .sort((a: any, b: any) => {
        let c = new Date(a.updatedAt).getTime()
        let d = new Date(b.updatedAt).getTime()
        return  d - c
      })
  }

  const viewImages  = [
    validation.values?.mockupUrl as any,
    validation.values?.designUrl as any,
    validation.values?.fulfillMockup as any,
    validation.values?.fulfillDesign as any
  ].filter(Boolean)

  const getLabelImage = (key: string) => {
    switch (key) {
      case 'designUrl':
        return t(Labels.design_url)
      case 'fulfillDesign':
        return t(Labels.fulfill_design)
      case 'fulfillMockup':
        return t(Labels.fulfill_mockup)
      case 'mockupUrl':
        return t(Labels.mockup_url)
      case 'fulfillDesignFolder':
        return t(Labels.design_folder_url)
      default:
        return ''
    }
  }

  useEffect(() => {
    if (validation.values) {
      let listUrls: ImagesInfoTypes[] = []
      for (const [key, value] of Object.entries(validation.values)) {
        if (key == 'designUrl' 
        || key == 'fulfillDesign' 
        || key == 'fulfillMockup' 
        || key == 'mockupUrl'
        || key == 'fulfillDesignFolder') {
          listUrls.push({
            key: key,
            label: getLabelImage(key),
            value: value
          } as ImagesInfoTypes)
        }
      }
      setImagesInfo(listUrls)
    }
  }, [validation.values?.mockupUrl,
    validation.values?.designUrl,
    validation.values?.fulfillMockup,
    validation.values?.fulfillDesign,
    validation.values?.fulfillDesignFolder
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumb
            goBack={RouteNames.ORDER_ITEMS}
            title={`${isEditing ? "" : t(Labels.create_new)} ${t(Labels.order_item)}`.trim()}
          >
            {isEditing && (
              <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillOrderEntity}>
                <Button className="me-2" color="info" onClick={handleClone}>
                  <i className="bx bx-duplicate font-size-16 align-middle me-1"></i>
                  {t(Labels.clone)}
                </Button>
              </WithPermission>
            )}
            {
              fulfillOrder.status == FulfillStatus.FULFILLABLE || fulfillOrder.status == FulfillStatus.FAILED ?
                <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillmentOrderEntity}>
                  <ActionButton color="warning" className="me-2" onClick={onClickOrderFulfillment}>
                    {t(Labels.request_fulfill)}
                  </ActionButton>
                </WithPermission>
                : null
            }

            {!isEditing || !fulfillOrder?.status || EditableFulfillStatuses.includes(fulfillOrder?.status as FulfillStatus || '') ?  (
              <>
                <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillOrderEntity}>
                  <ActionButton color="primary" onClick={callApiCreateOrder}>
                    {t(Labels.create_fulfill_design)}
                  </ActionButton>
                </WithPermission>
                <WithPermission
                  action={isEditing ? ActionEntities.update : ActionEntities.create}
                  resource={ResourceEntities.fulfillOrderEntity}
                >
                  <ActionButton color="success" className="ms-2" onClick={() => validation.submitForm()}>
                    <i className="bx bx-save font-size-16 align-middle me-1"></i>
                    {isEditing ? t(Labels.save) : t(Labels.create_new)}
                  </ActionButton>
                </WithPermission> 
              </> 
            ) : null}
          </Breadcrumb>

          <LoadableComponent loadable={loadable}>
            <FormikForm className="form-horizontal" validation={validation}>
              <FormLayout>
                <FormLayoutMain>
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col>
                          <h4 className="m-0 py-2 fw-400 fs-5 text-info"> {t(Labels.order_information)}</h4>
                        </Col>
                        <Col xs="auto" className="justify-content-center">
                          <div className="me-0 text-end py-2">{Dates.formatDate(fulfillOrder?.orderCreatedAt)}</div>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Col className="mb-3">
                        <Row>
                          <Col>
                            <Col xs={"auto"} className="pe-0">
                              <Label style={{ fontWeight: "normal" }}>{t(Labels.order)}</Label>
                              <span className="p-0">
                                <a target="_blank" rel="noreferrer" href={createUrlEmbed(`${RouteNames.ORDERS}/${validation.values.order?.id}`)} className="ps-1">
                                  <BiLinkExternal size="18" />
                                </a>
                              </span>
                            </Col>
                            <FormikInput
                              // label={t(Labels.artwork_sku)}
                              // required={false}
                              name={"order"}
                              placeholder={t(Labels.artwork_sku_hint)}
                              validation={validation}
                              customInput={({ handleChange, handleBlur, errorData }) => (
                                <OrderSelect
                                  value={validation.values.order}
                                  getOptionLabel={(option: OrderDto) => t(getOrderLabel(option))}
                                  getSearchValue={(option: any) => option?.number}
                                  onChange={(value: any) => {
                                    validation.setFieldValue("order", value)
                                    validation.setErrors({})
                                  }}
                                  styles={errorData.reactSelectErrorStyle}
                                />
                              )}
                            />
                          </Col>
                          <Col>
                            <Col xs={"auto"} className="pe-0">
                              <Label style={{ fontWeight: "normal" }}>{t(Labels.vendor)}</Label>
                            </Col>
                            <FormikInput
                              name={"vendor"}
                              validation={validation}
                              value={`${[validation.values?.vendor?.username, validation.values?.vendor?.name].filter(Boolean).join(' - ')}`}
                              disabled
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="mb-3 mt-1">
                        <Label>{t(Labels.line_items)}</Label>
                        <FulfillLineItemSelect
                          className="mb-2"
                          order={validation.values.order}
                          lineItems={validation.values.lineItems}
                          onChange={(values: any) => {
                            validation.setFieldValue("lineItems", values)
                            validation.setErrors({})
                          }}
                        />
                      </Col>
                      <Col className="mb-2 mt-1">
                        <FormikInputField
                          className="mb-2"
                          label={t(Labels.product_name)}
                          name={"productName"}
                          placeholder={t(Labels.product_name_hint)}
                          validation={validation}
                        />
                      </Col>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <Col>
                        <h4 className="m-0 py-2 fw-400 fs-5 text-info">{t(Labels.fulfill_information)}</h4>
                      </Col>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="mb-2" lg={6}>
                          <FormikInputField
                            type={'text'}
                            autoTrim={true}
                            name={"sku"}
                            label={t(Labels.fulfill_sku)}
                            value={AdminUtils.getFulfillSkuBody(validation.values.sku || "")}
                            onChange={(value: any) => {
                              validation.setFieldValue("sku", value.trim())
                            }}
                            placeholder={t(Labels.fulfill_sku_hint)}
                            validation={validation}
                          />
                        </Col>
                        <Col className="mb-2" lg={6}>
                          <FormikInputField
                            type="number"
                            min={1}
                            name={"quantity"}
                            label={t(Labels.quantity)}
                            placeholder={t(Labels.quantity_hint)}
                            validation={validation}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col className="mb-2" lg={12}>
                          <div className="d-flex gap-1">
                            <OptionalLabel label={t(Labels.fulfill_variants)} className='temp' />
                            <Link target={"_blank"} to={createLinkEmbedParam(`${RouteNames.FULFILL_VARIANTS}/${validation.values.fulfillVariant?.id}`).to}>
                              <span> </span>
                              <BiLinkExternal size="16" />
                            </Link>
                          </div>
                          <FormikInput
                            name={"fulfillVariant"}
                            // label={t(Labels.fulfill_variants)}
                            // required={false}
                            validation={validation}
                            customInput={({ handleChange, handleBlur, errorData  }) => (
                              <>
                                {/* <Link target={"_blank"} to={`${RouteNames.FULFILL_VARIANTS}/${validation.values.fulfillVariant?.id}`}>
                                  <span> </span>
                                  <BiLinkExternal size="16" />
                                </Link> */}
                                <FulfillVariantSelect
                                  value={validation?.values?.fulfillVariant}
                                  onChange={value => {
                                    validation.setFieldValue("fulfillService", value?.fulfillService)
                                    handleChange(value)
                                  }}
                                  onBlur={handleBlur}
                                  validation={validation}
                                  styles={errorData.reactSelectErrorStyle}
                                />
                              </>
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-2">
                          <div className="d-flex gap-1">
                            <OptionalLabel label={t(Labels.artwork_sku)} className="temp" />
                            <Link target={"_blank"} to={createLinkEmbedParam(`${RouteNames.ARTWORKS}/${validation.values.artwork?.id}`).to}>
                              <BiLinkExternal size="16" />
                            </Link>
                          </div>
                          <FormikInput
                            // label={t(Labels.artwork_sku)}
                            // required={false}
                            name={"artworkSku"}
                            type="text"
                            placeholder={t(Labels.artwork_sku_hint)}
                            validation={validation}
                            customInput={({ handleChange, handleBlur, errorData }) => (
                              <>
                                <SearchableSelect
                                  value={validation?.values?.artwork}
                                  getOptionValue={(option: ArtworkDto) => option?.sku || ""}
                                  getOptionLabel={(option: ArtworkDto) => option?.sku || ""}
                                  loadOptions={(searchText: string) => ArtworkActions.searchByArtwork(searchText)}
                                  onChange={(artwork: ArtworkDto) => {
                                    validation.setFieldValue("artwork", artwork)
                                  }}
                                  styles={errorData.reactSelectErrorStyle}
                                />
                              </>
                            )}
                          />
                        </Col>
                        {/*<Col className="mb-2" lg={6}>*/}
                        {/*  <FormikInput*/}
                        {/*    disabled={true}*/}
                        {/*    name={""}*/}
                        {/*    value={validation.values.fulfillService?.name || ""}*/}
                        {/*    onChange={() => {}}*/}
                        {/*    label={t(Labels.fulfill_services)}*/}
                        {/*    required={false}*/}
                        {/*    placeholder={t(Labels.fulfill_services_hint)}*/}
                        {/*    validation={validation}*/}
                        {/*  />*/}
                        {/*</Col>*/}
                      </Row>
                      <Col xs={12}>
                        <Label>{t(Labels.variant_options)}</Label>
                        <MetaTable
                          json={validation.values.variantOptions || []}
                          onChange={value => validation.setFieldValue("variantOptions", value)}
                        />
                        {/*<JsonTable*/}
                        {/*  json={validation.values.variantOptions || []}*/}
                        {/*  keyField={"key"}*/}
                        {/*  valueField={"value"}*/}
                        {/*  renderInCard={false}*/}
                        {/*  onChange={value => {*/}
                        {/*    validation.setFieldValue("variantOptions", value)*/}
                        {/*  }}*/}
                        {/*/>*/}
                      </Col>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader className="fw-400 fs-5 text-info">
                      <Col className="d-flex justify-content-between">
                        <h4 className="m-0 py-2 fw-400 fs-5 text-info">{t(Labels.design)}</h4>
                        <Button onClick={() => setModal({open: true, images: viewImages })}>{Labels.view_design}</Button>
                      </Col>
                    </CardHeader>
                    <CardBody>
                      {/* <Row>
                        <Col>
                          <GalleryImage imagesInfo={imagesInfo} validation={validation} />
                        </Col>
                      </Row> */}
                      <Row>
                        <Col xs={12}>
                          <SingleLineImageView
                            name="mockupUrl"
                            label={t(Labels.mockup_url)}
                            placeholder={t(Labels.mockup_url_hint)}
                            required={false}
                            url={validation.values?.mockupUrl}
                            validation={validation}
                            images={viewImages}
                          />
                        </Col>
                        <Col xs={12}>
                          <SingleLineImageView
                            name="designUrl"
                            label={t(Labels.design_url)}
                            placeholder={t(Labels.design_url_hint)}
                            required={false}
                            url={validation.values?.designUrl}
                            validation={validation}
                            images={viewImages}
                          />
                        </Col>
                        <Col xs={12}>
                          <SingleLineImageView
                            name="fulfillMockup"
                            label={t(Labels.fulfill_mockup)}
                            placeholder={t(Labels.fulfill_mockup_hint)}
                            required={false}
                            url={validation.values?.fulfillMockup}
                            validation={validation}
                            images={viewImages}
                          />
                        </Col>
                        <Col xs={12}>
                          <SingleLineImageView
                            name="fulfillDesign"
                            label={t(Labels.fulfill_design)}
                            placeholder={t(Labels.fulfill_design_hint)}
                            required={false}
                            url={validation.values?.fulfillDesignThumbnail || validation.values?.fulfillDesign}
                            validation={validation}
                          />
                        </Col>
                        <Col xs={12}>
                          <SingleLineImage
                            name="fulfillDesignFolder"
                            label={t(t(Labels.design_folder))}
                            placeholder={t(Labels.design_folder_hint)}
                            required={false}
                            url={validation.values?.fulfillDesignFolder}
                            validation={validation}
                            images={viewImages}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader className="fw-400 fs-5 text-info">
                      <Col>
                        <h4 className="m-0 py-2 fw-400 fs-5 text-info">{t(Labels.customize)}</h4>
                      </Col>
                    </CardHeader>
                    <CardBody>
                      <FulfillOrderCustomize validation={validation} />
                    </CardBody>
                  </Card>
                </FormLayoutMain>
                <FormLayoutRight>
                  <Card>
                    <CardBody>
                      <div>
                        <Row>
                          <Col>
                            <h4 className="card-title mb-3">{t(Labels.fulfill_status)}</h4>
                          </Col>
                          <Col xs={"12"} lg={"auto"}></Col>
                        </Row>
                        <Row>
                          <Col className="d-flex flex-column gap-2">
                            <FormikInput
                              label={t(Labels.status)}
                              name={"status"}
                              validation={validation}
                              customInput={({ handleChange, handleBlur, errorData }) => (
                                <StatusSelect
                                  name={"status"}
                                  placeholder={t(Labels.status_hint)}
                                  value={validation.values.status}
                                  statusValues={FulfillStatusValues}
                                  statusNames={FulfillStatusName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  styles={errorData.reactSelectErrorStyle}
                                />
                              )}
                            />
                            <FormikInput
                              label={t(Labels.status_issue)}
                              required={false}
                              name={"statusIssue"}
                              validation={validation}
                              customInput={({ handleChange, handleBlur, errorData  }) => (
                                <StatusSelect
                                  name={"statusIssue"}
                                  placeholder={t(Labels.status_issue_hint)}
                                  value={validation.values.statusIssue}
                                  statusValues={FulfillStatusIssueValues}
                                  statusNames={FulfillStatusIssueName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  styles={errorData.reactSelectErrorStyle}
                                />
                              )}
                            />
                            <FormikInput
                              label={t(Labels.design_status)}
                              required={false}
                              name={"designStatus"}
                              validation={validation}
                              customInput={({ handleChange, handleBlur, errorData  }) => (
                                <StatusSelect
                                  name={"designStatus"}
                                  placeholder={t(Labels.design_status)}
                                  value={validation.values.designStatus}
                                  statusValues={FulfillDesignStatusValues}
                                  statusNames={FulfillDesignStatusName}
                                  onChange={(value: any) => handleChange(value)}
                                  onBlur={handleBlur}
                                  styles={errorData.reactSelectErrorStyle}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <Row>
                        <div className="d-flex justify-content-between align-content-center ">
                          <h4 className="card-title mb-3">{t(Labels.shipping_information)}</h4>
                          <p 
                            className="text-primary mb-0 p-1" 
                            style={{cursor: 'pointer'}}
                            onClick={editAddress}
                          >
                            <RiEdit2Line size={18} className="pe-1"/>
                            {t(Labels.edit)}
                          </p>
                        </div>
                        <Row>
                          <Label className="mb-2">{t(Labels.contact_infomation)}</Label>
                          <div className="table-responsive text-muted">
                            <div className="ms-2 me-2">
                              <div><a href="#"> {validation?.values?.shippingAddress?.phone || ""}</a></div>
                              <div><a href="#"> {validation?.values?.order?.email || ""} </a></div>
                            </div>
                          </div>
                          <div className="mt-3 mb-0">
                            <Label>{t(Labels.shipping_method)}</Label>
                            <br/>
                            <span className="ms-2">{validation?.values?.shippingMethod || ""}</span>
                          </div>
                          <div className="mt-3 mb-0">
                            <Label>{t(Labels.shipping_address)}</Label>
                            <AddressDetail address={validation?.values?.shippingAddress}/>
                          </div>
                        </Row>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <Row>
                        <h4 className="card-title mb-3">{t(Labels.note_and_messages)}</h4>
                          <Col className="d-flex flex-column gap-2">
                            <FormikInputField
                              type="textarea" 
                              name="note" 
                              rows={4} 
                              label={t(Labels.order_note)} 
                              required={false} 
                              validation={validation} 
                            />
                            <FormikInputField 
                              type="textarea" 
                              name="fulfillDesignIssue" 
                              rows={4} 
                              label={t(Labels.fulfill_design_issue)} 
                              required={false} 
                              validation={validation} 
                            />
                            <Label className="mb-0">{t(Labels.customer_note)}</Label>
                            <Alert color="primary" className="mb-2 px-2 py-1">
                              {
                                validation.values?.customerNote
                                ? validation.values?.customerNote
                                : t(Labels.no_note_from_customer)
                              }
                            </Alert>
                            {
                              validation.values?.statusMessages?.length || validation.values?.fulfillMessages?.length ?
                                <>
                                  <Label className="mb-0">{t(Labels.messages)}</Label>
                                  <div>
                                    {

                                      validation.values?.statusMessages?.map((item: any, idx: number) => <Alert color="warning" className="mb-1 px-2 py-1" key={idx}>{item}</Alert>)
                                    }
                                    {
                                      validation.values?.fulfillMessages?.map((item: any, idx: number) => <Alert color="warning" className="mb-1 px-2 py-1" key={idx}>{item}</Alert>)
                                    }
                                  </div>
                                </>
                                : null
                            }
                          </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {!(parentFulfillOrder || validation.values.parentId) ? null : (
                    <Card>
                      <CardBody>
                        <FormikInput
                          readOnly={true}
                          name=""
                          rows={4}
                          value={parentFulfillOrder?.id || validation.values.parentId}
                          label={t(Labels.parent_order_item)}
                          validation={validation}
                          endComponent={() => (
                            <Button>
                              <Link {...createLinkEmbedParam(`${RouteNames.ORDER_ITEMS}/${parentFulfillOrder?.id || validation.values.parentId}`)}>
                                <i className="bx bx-show font-size-16 align-middle me-1" style={{ color: "white" }} />
                              </Link>
                            </Button>
                          )}
                        />
                      </CardBody>
                    </Card>
                  )}
                  <Card>
                    <CardBody>
                      <div>
                        <Row>
                          <Col>
                            <h4 className="card-title mb-3">{t(Labels.other_information)}</h4>
                          </Col>
                          <Col xs={"12"} lg={"auto"}></Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex flex-column gap-2">
                            <div>
                              <label className="form-label mb-0">
                                {t(Labels.fulfillment_orders)}
                                {validation.values.fulfillmentOrderId ? (
                                  <a target="_blank" rel="noreferrer" href={createUrlEmbed(RouteNames.FULFILLMENT_ORDERS_DETAIL.replace(":id", validation.values.fulfillmentOrderId))} className="ms-1" >
                                    <BiLinkExternal />
                                  </a>
                                ) : (
                                  <></>
                                )}
                              </label>
                            </div>
                            <FormikInputField
                              placeholder={t(Labels.fulfillment_order_hint)}
                              readOnly={true}
                              value={validation.values.fulfillmentOrderId || ''}
                              name={"fulfillmentOrderId"}
                              validation={validation}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex flex-column gap-2">
                            <FormikInputField 
                              placeholder={t(Labels.tracking_number_hint)}
                              label={t(Labels.tracking_number)}
                              value={validation.values.trackingNumber}
                              name={"trackingNumber"}
                              validation={validation}
                            />
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                  {!(validation.values.designCustomHistories?.length > 0) ? null : (
                    <Card>
                      <CardBody>
                      <h4 className="card-title">{t(Labels.personal_custom_history)}</h4>
                        <Row className="m-auto">
                          {sortTime(validation.values.designCustomHistories).map((item: any, idx: number) => {
                            return (
                              <Alert color="info" className="mt-3 mb-0 ps-2 pe-2" key={idx}>
                                <Label className="mb-0">
                                  <TooltipComponent
                                    className="d-inline"
                                    tooltip={t(
                                      item.toData.customReviewed
                                        ? Messages.personal_custom_reviewed
                                        : Messages.personal_custom_wait_for_review
                                    )}
                                  >
                                    <div className="d-inline-block">
                                      {item.toData.customReviewed ? (
                                        <BsCheckCircle color="green" fontSize={16} />
                                      ) : (
                                        <BsFillClockFill color="gray" fontSize={16} />
                                      )}
                                      <p className="mb-0 ms-1 d-inline">{item.toData.customText}</p>
                                    </div>
                                  </TooltipComponent>
                                </Label>
                                <Col className="mb-0 d-flex justify-content-end align-items-center mt-1 font-size-12">
                                  <Badge className="font-size-12 me-1" style={{lineHeight: 'normal'}}>{item.userName}</Badge> {formatCustomHistoryDate(item.createdAt)}
                                </Col>
                              </Alert>
                            )
                          })}
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </FormLayoutRight>
              </FormLayout>
            </FormikForm>
          </LoadableComponent>
        </Container>
      </div>
    </React.Fragment>
  )
}

interface ShippingProps {
  title?: string
  methodName: string
  addressName: string
  isEditing?:boolean
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>
  id: string
  preventEdit?: boolean
  saveData?: any
}

export const ShippingAddressPopup = (props: ShippingProps) => {
  const { methodName, addressName, validation, isEditing, id, preventEdit, saveData } = props
  const [, setModal] = useRecoilState(portableModalState)

  const addressValidation = useFormik<any>({
    initialValues: validation.values,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: Yup.object({
      shippingAddress: shippingAddressSchema
    }),
    onSubmit: (values, helpers) => handleSubmitAddressValidation(),
  })

  const handleSubmitAddressValidation = async () => {
    let changedData = {
      shippingAddress: addressValidation.values?.shippingAddress,
      [`${methodName}`]: addressValidation.values[methodName]
    }

    let savedData = await (saveData ? saveData(id || "", changedData) : FulfillOrderActions.save(id || "", changedData))
    if (savedData?.id) {
      setModal({ open: false, title: '' })
      validation.setFieldValue(methodName, savedData[methodName])
      validation.setFieldValue('shippingAddress', addressValidation.values?.shippingAddress)
      // TODO optimize not using setTimeout
      setTimeout(() => {
        Toasts.success(t(Messages.save_shipping_address_successfully))
      }, 100)
    }
  }

  return (
    <>
      <ModalContent>
        <FormikForm validation={addressValidation}>
          <ShippingAddress
            isEditing={isEditing}
            methodName={methodName}
            addressName={addressName}
            validation={addressValidation}
            preventEdit={preventEdit}
          />
        </FormikForm>
      </ModalContent>
      <ModalButtons
        closeAfterConfirm={false}
        hiddenConfirmButton={preventEdit}
        confirm={async () => {
          await addressValidation.submitForm()
        }}
      />
    </>
  )
}
