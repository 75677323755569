import { isEmpty } from 'lodash';
import { TestContext } from 'yup/lib/util/createValidation';
import { Messages } from './../../../common/messages';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { t } from "../../../core/translations";
import { Labels } from "../../../common/labels";

export const artworkSchema = Yup.object({
  sku: Yup.string()
    .min(1)
    .required(t(Messages.field_required, { field: t(Labels.sku) })),
  title: Yup.string()
    .min(1)
    .required(t(Messages.field_required, { field: t(Labels.title) })),
  url: Yup.string()
    .nullable()
    .url(),
  storageService: Yup.string().required(t(Messages.field_required, {field: t(Labels.storage_service)})),
  customOptions: Yup.array().nullable().test("requiredCustomName", (customOptions: any, context: TestContext) => {
    if (context.parent?.customize) {
      let isRequired = customOptions?.filter((item: any) => 
         !item?.hasOwnProperty("keys") )
      if (!isEmpty(isRequired)) {
        return context.createError({ message: t(Labels.custom_option_keys_required) })
      }
    }
    return true
  })
})
