import { Labels } from "common/labels"
import { t } from "core/translations"
import { useEffect, useState } from "react"
import { ExportType } from "types/export.type"
import { BaseModalExport, CustomizeDataTypeOptionsType, exportRadioDataTypeValues } from "./export-modal";


interface propsModalExportFulfillmentOrder {
    type: string
    onClose: Function
    handleExport: Function
    selectedIds: Array<any>
    totalItems?: number
    children?: any
}

export const ModalExportFulfillmentOrder = (props: propsModalExportFulfillmentOrder) => {
  const { type } = props

  const [titleModal, setTitleModal] = useState<string>()

  useEffect(() => {
    switch (type) {
      case ExportType.REVIEW_FULFILLMENT_ORDER:
        setTitleModal(t(Labels.export_for_review_fulfillment_order))
        return
      case ExportType.SUBMITTING_FULFILLMENT_ORDER:
        setTitleModal(t(Labels.export_for_submitting_fulfillment_order))
        return
      case ExportType.COMPLETED_FULFILLMENT_ORDER:
        setTitleModal(t(Labels.export_completed_requests))
        return
      default:
        setTitleModal("")
        return
    }
  }, [type])

  return (
    <BaseModalExport
      {...props}
      closeAfterConfirm={false}
      textNote={t(Labels.processing_time_depends_on_the_number, { resourceName: Labels.fulfillment_orders_resource })}
      titleModal={titleModal}
      exportDataType={type == ExportType.SUBMITTING_FULFILLMENT_ORDER ? undefined : exportRadioDataTypeValues.excel_file}
      customizeDataTypeOptions={
        type == ExportType.SUBMITTING_FULFILLMENT_ORDER
        ? {
            exportType: ExportType.REVIEW_FULFILLMENT_ORDER,
            type: exportRadioDataTypeValues.zip_file, 
            ext: '.zip'
          } as CustomizeDataTypeOptionsType
        : undefined
      }
    >
      { props.children }
    </BaseModalExport>
  )
}