import { Storages } from "../utils/storages";
import { Keys } from "./keys";

export interface GlobalTypes {
  tenant: string,
  settings?: any,
  team?: string,
  user?: string,
}

export const Global: GlobalTypes = {
  tenant: Storages.getLocalJson(Keys.tenant),
  team: Storages.getLocalJson(Keys.team),
  user: Storages.getLocalJson(Keys.user),
  settings: {
    enableProductTemplateAutoVariation: true
  }
}