export class Storages {
  static getLocalJson(key: string) {
    try {
      let value = localStorage.getItem(key)
      return value == '' ? value : value ? JSON.parse(value) : null
    } catch (e) {
      // Do nothing
    }

    return null
  }

  static setLocalJson(key: string, value: any) {
    try {
      let valueStr = value == null || value == undefined ? '' : JSON.stringify(value);
      localStorage.setItem(key, valueStr)
    } catch (e) {
      // Do nothing
    }

    return null
  }

  static getItem(key: string) {
    return localStorage.getItem(key)
  }
}