import { Labels } from "common/labels"
import { FormikInput } from "components/form/FormikInput"
import { MetaTable } from "components/table/MetaTable"
import React, { useState } from "react"
import { Col, Label } from "reactstrap"
import Select from "react-select"
import { t } from "core/translations"
import { CustomTypeOptions } from "../types/custom-type.type"
import { omit } from "lodash"

export const FulfillOrderCustomize = (props: any) => {
  const { validation } = props
  const [valueCustomText, setValueCustomText] = useState(null)

  return (
    <>
      <Col className="mb-2">
        <Label>{t(Labels.custom_fields)}</Label>
        <MetaTable
          json={validation.values.customFields || []}
          onChange={value => {
            validation.setFieldValue("customFields", value)
          }}
        />
      </Col>
      <Col>
        <FormikInput name={"paidCustomFee"} type="checkbox" validation={validation} label={t(Labels.paid_custom_fee)} />
      </Col>
      <hr />
      <Col className="mb-2">
        <Label>{t(Labels.custom_type)}</Label>
        <Select
          options={CustomTypeOptions}
          menuPortalTarget={document.body}
          blurInputOnSelect={true}
          openMenuOnFocus={true}
          value={CustomTypeOptions.filter(item => item.value === validation.values.customType)}
          getOptionValue={(option: any) => option?.value || option}
          getOptionLabel={(option: any) => t(option?.label || option)}
          onChange={option => validation.setFieldValue("customType", option?.value)}
        />
      </Col>
      <Col xs={12} className="mt-2">
        <Label>{t(Labels.personal_custom_fields)}</Label>
        <MetaTable
          json={validation.values.designCustomData?.customFields?.map((item: any) => ({ ...item, key: item.name })) || []}
          onChange={values => {
            const listCustomText = values.filter((item: any, idx: number) =>
              idx + 1 > validation.values.designCustomData?.customCount || 0 ? item.value : item
            )

            setValueCustomText(listCustomText.map((item: any) => item.value).join(" | "))

            const newValue = values?.map((value: any) => omit({ ...value, name: value.key }, ["key", "id"]))
            validation.setFieldValue("designCustomData.customFields", newValue)
          }}
        />
      </Col>
      <Col className="mb-2 mt-2">
        <FormikInput
          className="mb-2"
          value={valueCustomText || validation.values.designCustomData?.customText || ''}
          label={t(Labels.personal_custom_text)}
          name={"designCustomData.customText"}
          placeholder={t(Labels.product_name_hint)}
          validation={validation}
          readOnly={true}
        />
      </Col>
      <Col>
        <FormikInput name={"customReviewed"} type="checkbox" validation={validation} label={t(Labels.personal_custom_reviewed)} />
      </Col>
    </>
  )
}
