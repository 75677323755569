import { Card, CardBody, CardSubtitle, CardTitle, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { ReactElement, useState } from "react";
import { t } from "../../core/translations";
import { Labels } from "common/labels";
import { createUrlEmbed } from "utils/embed";


interface UploadFileProps {
  title: string,
  multi?: boolean
  templateUrl?: string
  description?: string | ReactElement,
  submitLabel?: string,
  onSubmit: Function,
  onCancel?: Function
  replaceable?: boolean
  children?: any
}

export const UploadForm = (props: UploadFileProps) => {
  const [selectedFiles, setSelectedFiles ] = useState([] as any[])
  const [replaceExisted, setReplaceExisted ] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [submittable, setSubmittable] = useState(false)

  function handleAcceptedFiles(files: any[]) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    if(props.multi) {
      setSelectedFiles(files)
    } else {
      setSelectedFiles(files.length > 0 ? files.slice(files.length - 1) : [])
    }

    setSubmittable(files.length > 0)
  }

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  async function handleSubmit() {
    try {
      setUploading(true)
      await props.onSubmit(selectedFiles, replaceExisted)
    } finally {
      setUploading(false)
    }
  }

  return (
    <>
      { !props.title ? null : <CardTitle>{ props.title }</CardTitle> }
      { !props.description && !props.templateUrl ? null : (
        <CardSubtitle className="mb-3">
          { props.templateUrl &&
            <div className="mb-1">
              {t(Labels.click_to_view_a)}
              <span>
                  <Link
                    target={"_blank"}
                    to={{pathname:createUrlEmbed(props.templateUrl)}}
                    className="link-primary">{t(Labels.sample_CSV_template)}</Link>
                  </span>
              {t(t(Labels.to_see_an_example_of_format_required))}
            </div>
          }
          {
            props.description
          }
        </CardSubtitle>
      )
      }
      <Form>
        <Dropzone
          onDrop={ (acceptedFiles: any) => handleAcceptedFiles(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }: { getRootProps: any, getInputProps: any }) => {
            return (
              <div className='dropzone'>
                <div
                  className='dz-message needsclick mt-2'
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className='mb-3'>
                    <i className='display-4 text-muted bx bxs-cloud-upload' />
                  </div>
                  <h4>{t(Labels.drop_or_click_to_upload)}</h4>
                </div>
              </div>
            );
          }}
        </Dropzone>
        <div className="dropzone-previews my-3" id="file-previews">
          {selectedFiles.map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col>
                      <Link
                        to="#"
                        className="text-muted font-weight-bold"
                      >
                        {f.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            )
          })}
        </div>
        { props.children }
        {
          props.replaceable ? (
            <FormGroup check inline className="ms-1">
              <Input
                type='checkbox'
                className='form-check-Input font-size-16'
                onChange={(event) => {
                  setReplaceExisted(event.target.checked)
                }}
              />
              <Label check className="mt-1 fw-normal">{t(Labels.replace_existed_data)}</Label>
            </FormGroup>
          ) : null
        }
      </Form>

      <div className="text-center mt-4">
        <button
          disabled={ uploading || !submittable }
          type="button"
          className="btn btn-primary "
          onClick={handleSubmit}
        >
          { uploading ? t(Labels.in_progress) + '...' : props.submitLabel || t(Labels.input_data) }
        </button>

        <button
          disabled={uploading}
          type="button"
          className="btn btn-secondary ms-2"
          onClick={() => props.onCancel && props.onCancel()}
        >
          { t(Labels.close) }
        </button>
      </div>
    </>
  )
}