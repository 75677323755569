import { Labels } from "common/labels"
import { SelectOption } from "common/types"
import { UploadForm } from "components/common/UploadForm"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import { t } from "core/translations"
import { FulfillOrderActions } from "data/actions/fulfill-order.action"
import { FulfillmentInvoiceActions } from "data/actions/fulfillment-invoice.action"
import { portableModalState } from "data/atoms/app.atom"
import { isEmpty } from "lodash"
import { useState } from "react"
import Select from "react-select"
import { Col } from "reactstrap"
import { useRecoilState, useSetRecoilState } from "recoil"
import { AdminUtils } from "utils/admin-utils"
import { HandleFileResultType, ImportedResultEditor, ImportResultModal } from "./import-modal"

export interface ImportSettledInvoicePopopProps {
  refresh: Function
}

interface valueSubmitType {
  vendorInvoiceStatus: any
  fulfillInvoiceStatus: any
}

export const ImportSettledInvoicePopup = (props: ImportSettledInvoicePopopProps) => {

  const [valueSubmit, setValueSubmit] = useState<valueSubmitType>({ vendorInvoiceStatus: "", fulfillInvoiceStatus: "" })
  const [modalState, setPortableModal] = useRecoilState(portableModalState)


  const options = [
    {
      value: "completed",
      label: Labels.completed,
    },
    {
      value: "cancelled",
      label: Labels.cancelled,
    },
  ]

  const handleSubmit = async (selectedFiles: any[]) => {
    let importResult: HandleFileResultType = null as any
    let data: any = await FulfillmentInvoiceActions.importSettledInvoice( selectedFiles, valueSubmit)
    importResult = AdminUtils.handleImportResponse(data, "import_submitted_order_result")

    const onClose = () => { 
      props.refresh && props.refresh()
      setPortableModal({open: false, title: ""})
    }

    if (importResult?.success) {
      props.refresh && props.refresh()
      setPortableModal({
        ...modalState,
        open: true,
        size: 'lg',
        title: t(Labels.import_result),
        preventToggle: true,
        content: (
          <ImportResultModal
            importResult={importResult}
            onClose={onClose}
          />
        )
      })
    }
  }

  return (
    <>
      <ModalContent>
        <Col className="mb-3">
          <UploadForm
            title={t("")}
            description={t(Labels.import_settled_invoices)}
            templateUrl={'https://docs.google.com/spreadsheets/d/1DgMVeIJfvv4WBcEBrezP2H3Gwlncp6ZeaGBr8Dm-UJM/edit?usp=sharing'}
            onSubmit={handleSubmit}
            onCancel={() => setPortableModal({ open: false, title: "" })}
          >
            <Col className="mb-3">
              <h6>{Labels.vendor_status}</h6>
              <Select
                placeholder={Labels.select_hint}
                name={"vendorInvoiceStatus"}
                getOptionLabel={(option: any) => t(option?.label)}
                getOptionValue={(option: any) => option?.value}
                value={options.find(item => item.value === valueSubmit?.vendorInvoiceStatus)}
                options={options}
                onChange={option => setValueSubmit(prev => ({ ...(prev || {}), vendorInvoiceStatus: option?.value }))}
              />
            </Col>
            <Col className="mb-3">
              <h6>{Labels.fulfill_service_status}</h6>
              <Select
                placeholder={Labels.select_hint}
                name={"fulfillInvoiceStatus"}
                getOptionLabel={(option: any) => t(option?.label)}
                getOptionValue={(option: any) => option?.value}
                value={options.find(item => item.value === valueSubmit?.fulfillInvoiceStatus)}
                options={options}
                onChange={option => setValueSubmit(prev => ({ ...(prev || {}), fulfillInvoiceStatus: option?.value }))}
              />
            </Col>
          </UploadForm>
        </Col>
      </ModalContent>
    </>
  )
}
