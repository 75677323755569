import { withPermission } from "common/hooks/use-permission";
import { Filter } from "core/rest-api/query-builder";
import { CreateFulfillVariantMapperDto, FulfillVariantMapperDto, FulfillVariantMapperService, FulfillVariantMapperServices, UpdateFulfillVariantMapperDto } from "data/services/fulfill-variant-mapper.service";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { CrudActions } from "./crud.action";


class FulfillVariantMapperAction extends CrudActions<FulfillVariantMapperService, CreateFulfillVariantMapperDto, UpdateFulfillVariantMapperDto>{
  constructor() {
    super(FulfillVariantMapperServices);
  }

  // async search(searchValue: string) {
  //   return withPermission(
  //     ActionEntities.read,
  //     ResourceEntities.fulfillVariantMapperEntity,
  //     async () => {
  //       let filter: Filter<FulfillVariantMapperDto> = {}
  //       if (searchValue) {
  //         filter.variantTitle = { contains: searchValue }
  //       }
  //       let pageData = await FulfillVariantServices.query({ filter, page: 1 });
  //       return pageData?.pageItems || [];
  //     })
  // }

  async searchById(id: string){
    withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillVariantMapperEntity,
      async () => {
        if(!id){
          return []
        } else{
          let pageData = await FulfillVariantMapperServices.getById(parseInt(id))
          return pageData
        }
      })
  }
}

export const FulfillVariantMapperActions = new FulfillVariantMapperAction()