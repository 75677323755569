import { BillingAddressType } from "../types/address.type";

export class Strings {
  static getAddress(address: BillingAddressType, spliter: string = ', ') {
    if(!address) return ''
    let addressStrs = [address.address1 , address.address2, address.city, address.provinceCode, address.countryCode, address.zip]
    return addressStrs.filter(str => str).join(spliter).trim()
  }

  static getAddressWithName(address: BillingAddressType, spliter: string = ', ') {
    if(!address) return ''
    let addressStrs = [`${address.firstName || ''} ${address.lastName || ''}`.trim(), address.address1 , address.address2, address.city, address.provinceCode, address.countryCode, address.phone]
    return addressStrs.filter(str => str).join(spliter).trim()
  }

  static getOrderBillingAdders(address: BillingAddressType) {
    if(Object.keys(address).length === 0 && address.constructor === Object) return [];
    let addressStrs = [address.address1, address.address2, address.city, address.provinceCode, address.countryCode, address.zip]
    return addressStrs.filter(str => str?.length >0)
  }

  static removeSubString = (text: string = '', fromText: string) => {
    return text.includes(fromText) ? text.substring(0, text.indexOf(fromText)) : text
  }

  static appendFileName(fileName: string, str: string) {
    const lastIndex = fileName.lastIndexOf('.')

    if (lastIndex >= 0) {
      return fileName.substring(0, lastIndex) + str + fileName.substring(lastIndex)
    } else {
      return fileName + str
    }
  }
}

