import { Labels } from "common/labels";
import { FormValidation } from "components/form/FormikForm";
import { t } from "core/translations";
import Creatable from "react-select/creatable";
import { Forms } from "utils/forms";

interface ProductTemplateOptionCreatableSelectProps {
  placeholder?: string,
  handleBlur?: any,
  value: any,
  options?: any,
  onChange: any,
  isDisabled?: any,
  inputValue?: any
}
// TODO: merge voi component CreatableMultiValues
export const ProductTemplateOptionCreatableSelect = (props: ProductTemplateOptionCreatableSelectProps) => {
  const { placeholder, handleBlur, value, options, onChange, ...restProps } = props

  return (
    <Creatable
      isMulti={true}
      placeholder={placeholder}
      value={value}
      options={options}
      formatCreateLabel={(value) => Forms.createLabelMultiValues(value)}
      getOptionValue={(option: any) => option.value}
      getOptionLabel={(option: any) => option.label || option.value}
      onBlur={handleBlur}
      onChange={onChange}
      {...restProps}
    />
  )
}