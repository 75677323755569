import React, { useEffect, useState } from "react";
import { Badge, Button, Container } from "reactstrap";
import { ColumnDescription } from "react-bootstrap-table-next";
import { t } from "../../core/translations";
import { Link } from "react-router-dom";
import { RouteNames } from "../../routes";
import {FilterOption, SearchOption, TabOption} from "../../components/table/TableFilter";
import {ActionOption, RemoteTableSelectionData} from "../../components/table/SelectedRowAction";
import Breadcrumb from "../../components/layout/Breadcrumb";
import RemoteTable, { createDeleteAction } from "../../components/table/RemoteTable";
import { Labels } from "../../common/labels";
import { FulfillmentDto } from "../../data/services/fulfillment.service";
import { fulfillmentQueryState, fulfillmentSelector, fulfillmentTrackingStatusCountSelector } from "../../data/atoms/fulfillments.atom";
import { format } from "date-fns";
import { ShipmentStatusColor, ShipmentStatusName, ShipmentStatusValues } from "../../types/shippment-status";
import { FulfillmentActions } from "../../data/actions/fulfillment.action";
import { useRecoilValueLoadable } from "recoil";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import {OrderStatusName} from "../../types/order-status.type";
import { createDocumentPageName } from "../../utils/utils";
import { createLinkEmbedParam } from "utils/embed";

export const FulfillmentsPage = () => {
  const [countByStatus, setCountByStatus] = useState<any>({})
  const countByStatusLoadable = useRecoilValueLoadable(fulfillmentTrackingStatusCountSelector)

  useEffect(() => { document.title = createDocumentPageName(t(Labels.fulfillments)) }, [])
  
  useEffect(() => {
    if (countByStatusLoadable.state == "hasValue") {
      setCountByStatus(countByStatusLoadable.contents || {})
    }
  }, [countByStatusLoadable.state])

  let columns: ColumnDescription<FulfillmentDto>[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.FULFILLMENTS}/${row.id}`)}> {`${row.id}`}</Link>
        </>;
      }
    },
    {
      dataField: "orderNumber",
      text: t(Labels.order_number),
      headerStyle: { width: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return !row.order?.id ? `${row.orderNumber}` :
          <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.ORDERS}/${row.order?.id}`)}> {`${row.orderNumber}`}</Link>
      }
    },
    {
      dataField: "trackingNumber",
      text: t(Labels.tracking_number),
    },
    {
      dataField: "baseCost",
      text: t(Labels.base_cost),
      headerStyle: { width: 120 },
      formatter: (cell: any, row: any) => {
        return `$${cell}`
        // return `$${row.baseCost} x ${row.quantity}`
      },
    },
    {
      dataField: "notifySaleChannel",
      text: t(Labels.tracking_status),
      headerStyle: { width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return cell == "1" || cell == true ?
          <Badge color='success' pill>{ t(Labels.channel_uploaded) }</Badge>
          :
          <Badge color='primary' pill>{ t(Labels.pending) }</Badge>
      },
    },
    /*{
      dataField: "totalBaseCost",
      text: t("Total Cost"),
      headerStyle: { width: 150 },
      formatter: (cell: any, row: any) => {
        return `$${cell}`
      },
    },*/
    {
      dataField: "shipmentStatus",
      text: t(Labels.shipment_status),
      headerStyle: { width: 150 },
      formatter: (cell: any, row: any) => {
        let color = ShipmentStatusColor[cell] || "light" // TODO change color by status
        return (
          <>
            <Badge color={color} pill>
              {ShipmentStatusName[cell] || cell}
            </Badge>
          </>
        )

        // TODO format status
        return cell
      },
    },
    {
      dataField: "createdAt",
      text: t(Labels.fulfill_time),
      headerStyle: { width: 150 },
      formatter: (cell: any, row: any) => {
        return format(new Date(cell), "MMM dd, yyyy HH:mm")
      },
    },
  ]

  let searchOptions: SearchOption[] = [
    {
      field: "trackingNumber",
      label: t(Labels.tracking_number),
    },
    {
      field: "orderNumber",
      label: t(Labels.order_number),
    },
  ]

  let filterOptions: FilterOption[] = [
    {
      name: "notifySaleChannel",
      label: t(Labels.tracking_status),
      type: "radio",
      value: [
        { label: t(Labels.channel_uploaded), value: "1" },
        { label: t(Labels.pending), value: "0" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
    },
  ]

  let tabOptions: TabOption[] = [
    {
      id: "all",
      label: t(Labels.all),
      filter: {},
      count: countByStatus['all'],
    },
    ...ShipmentStatusValues.map(value => ({
      id: value,
      label: t(ShipmentStatusName[value]),
      filter: { shipmentStatus: { equalTo: value } },
      count: countByStatus?.data && countByStatus?.data[value] || 0,
    })),
  ]

  const actionOptions: ActionOption[] = [
    {
      name: "upload_tracking_to_channel",
      label: t(Labels.upload_tracking_to_channel),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        await FulfillmentActions.uploadTrackingNumberToChannel(selectedIds, refresh)
      },
    },
    {
      name: "update_fulfillment_status",
      label: t(Labels.update_fulfillment_status),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        await FulfillmentActions.updateFulfillmentStatus(selectedIds, refresh)
      },
    },
    createDeleteAction(FulfillmentActions, ResourceEntities.fulfillmentEntity)
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={t(Labels.fulfillments)}>
          <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillmentEntity}>
            <Link {...createLinkEmbedParam(`${RouteNames.FULFILLMENTS}/create`)}>
              <Button type="button" className="btn btn-success">
                <i className="bx bx-plus font-size-16 align-middle"></i>
                {t(Labels.create_new)}
              </Button>
            </Link>
          </WithPermission>
          </Breadcrumb>
          <RemoteTable
            dataLoader={[fulfillmentSelector, fulfillmentQueryState]}
            searchOptions={searchOptions}
            filterOptions={filterOptions}
            tabOptions={tabOptions}
            actionOptions={actionOptions}
            searchHint={t(Labels.search_by_fulfill_sku)}
            defaultSearchField={"sku"}
            keyField={"id"}
            columns={columns}
            filterKey={'fulfillments'}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
