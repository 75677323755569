import { Labels } from "common/labels";
import RemoteTable, { defaultPageInfo } from "components/table/RemoteTable";
import { t } from "core/translations";
import { Fragment, useEffect, useRef, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Button, CardBody, Col, Container, FormText, Label, Progress, Row } from "reactstrap";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { portableModalState, viewExcelState } from "data/atoms/app.atom";
import { Link } from "react-router-dom";
import Breadcrumb from "components/layout/Breadcrumb";
import { ReportsStatus, reportsStatusName } from "types/report.type";
import { AiOutlineEye } from "react-icons/ai";
import { BsArrowDownCircle } from "react-icons/bs";
import { reportQueryState, reportSelector, reportUpdateSelector } from "data/atoms/report.atom";
import { format } from "date-fns";
import { Messages } from "common/messages";
import { MdCancel, MdCheckCircle, MdPending } from "react-icons/md";
import { HiArrowPath, HiXMark } from "react-icons/hi2";
import { ReportActions } from "data/actions/report.action";
import { ActionOption } from "../../components/table/SelectedRowAction";
import { FilterOption, SearchOption } from "../../components/table/TableFilter";
import { TooltipTippy } from "components/common/TooltipTippy";
import { createLinkEmbedParam } from "utils/embed";

const ReportsPage = () => {
  const setPortableModal = useSetRecoilState(portableModalState);
  const setViewExcelState = useSetRecoilState(viewExcelState);
  const [queryState, setQueryState] = useRecoilState(reportQueryState(defaultPageInfo));
  const loadable = useRecoilValueLoadable(reportSelector);
  const loadableReportUpdate = useRecoilValueLoadable(reportUpdateSelector);
  const [updateDataTable, setUpdateDataTable] = useState<Record<string, any>>();
  const refRefresh = useRef<any>();

  const viewReport = (row: any) => {
    setPortableModal({
      open: true,
      title: row.reportName || row.fileMetadata?.originalname,
      content: <ViewReportPopup row={row} />
    });
  };

  useEffect(() => {
    if (loadableReportUpdate.state === "hasValue") {
      const dataUpdate = loadableReportUpdate.contents?.pageItems?.reduce((state: Record<string, any>, init: Record<string, any>) => {
        if (init?.id) {
          state[init.id] = init;
        }
        return state;
      }, {});

      setUpdateDataTable(dataUpdate);
    }
  }, [loadableReportUpdate.contents]);

  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(reportUpdateSelector);

  const refreshReport = () => {
    // if (updateDataTable) {
    //   const statusRefresh = [ReportsStatus.PENDING, ReportsStatus.PROCESSING, ReportsStatus.FAILED];
    //
    //   const findProgress = Object.values(updateDataTable).find(item => statusRefresh.includes(item.status));
    //   if (!findProgress) {
    //     return true;
    //   }
    // }
    !refRefresh.current && fakeRefreshDataLoadable();
  };

  useEffect(() => {
    const refreshQuery = setInterval(() => {
      // if(updateDataTable.)
      try {
        refreshReport();
      } catch (error) {

      } finally {
        refRefresh.current = null;
      }

    }, 10000);
    return () => {
      clearInterval(refreshQuery);
    };
  }, [updateDataTable]);

  let columns: ColumnDescription[] = [
    {
      dataField: "reportName",
      text: t(Labels.name),
      headerStyle: { minWidth: 250 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Col>
            <Link {...createLinkEmbedParam("#")} className=" fw-500 mb-0" onClick={() => viewReport(row)}>
              {cell || row.fileMetadata?.originalname || ""}
            </Link>
            <p className="font-size-11 mb-0">{row.message}</p>
          </Col>
        );
      }
    },

    {
      dataField: "progress",
      text: t(Labels.progress),
      headerStyle: { minWidth: 170 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Col>
            <div className="d-flex flex-column">
              {/* <div className="text-center">{`${row.progress} of ${row.total}`}</div> */}
              <p className="text-center font-size-11 mb-2"> {row.outputData ? `Imported ${row.outputData?.stats?.success || 0} / ${row.outputData?.stats?.total}` : ""}</p>
              {
                row.total ? <Progress barStyle={{paddingTop: 1, overflow: "initial", color:"#495057", paddingLeft: "2px"}} max={row.total} className="w-100" value={row.progress}>{((row.progress / row.total)*100).toFixed()}%</Progress> : <></>
              }
            </div>
          </Col>
        );
      }
    },
    {
      dataField: "user",
      text: t(Labels.user),
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Col>
            <p className="mb-0">{cell?.name || cell?.username}</p>
          </Col>
        );
      }
    },

    {
      dataField: "status",
      text: t(Labels.status),
      headerStyle: { minWidth: 120 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <div className="d-flex">
            {IconStatus.find(item => item.value === row.status)?.icon}
            <p className="mb-0 ms-1">{reportsStatusName[cell] || ""}</p>
          </div>
        );
      }
    },
    {
      dataField: "updatedAt",
      text: t(Labels.last_update),
      headerStyle: { minWidth: 120, width: 120 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return format(new Date(cell), "MMM dd, yyyy HH:mm:ss");
      }
    },
    {
      dataField: "inputFile",
      text: t(Labels.uploaded),
      headerStyle: { minWidth: 90, width: 90 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <div className="d-inline">
            <a
              href={`https://view.officeapps.live.com/op/view.aspx?src=${cell}`}
              onClick={e => {
                e.preventDefault();
                setViewExcelState({ open: true, url: cell });
              }}
            >
              <Button outline size="sm">
                <AiOutlineEye />
              </Button>
            </a>
            <a href={cell} download={cell} className="ms-1">
              <Button outline size="sm">
                <BsArrowDownCircle />
              </Button>
            </a>
          </div>
        );
      }
    },
    {
      dataField: "outputFile",
      text: t(Labels.output_file),
      headerStyle: { minWidth: 100, width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Row>
            {cell ? (
              <Col>
                <a
                  href={`https://view.officeapps.live.com/op/view.aspx?src=${cell}`}
                  onClick={e => {
                    e.preventDefault();
                    setViewExcelState({ open: true, url: row.outputFile });
                  }}
                >
                  <Button outline size="sm">
                    <AiOutlineEye />
                  </Button>
                </a>
                <a href={row.outputFile} download={row.outputFile} className="ms-1 d-inline">
                  <Button outline size="sm">
                    <BsArrowDownCircle />
                  </Button>
                </a>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        );
      }
    },
    {
      dataField: "action",
      text: t(Labels.action),
      headerStyle: { width: 120 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Row>
            <Col className="d-flex align-items-center">
              <LoadingAction
                action={async () => {
                  const r = window.confirm(Messages.do_you_want_to_import_retry);
                  if (r == true) {
                    const result: any = await ReportActions.retryImport(row.id);
                    if (result?.id) {
                      setUpdateDataTable({ [result.id]: result });
                    }
                    return result;
                  }
                }}
              >
              <TooltipTippy label={Labels.retry_import}>
                <Link {...createLinkEmbedParam("#")} className="d-inline">
                  <Button outline size="sm px-1">
                    <HiArrowPath className="me-1" />
                    {t(Labels.retry)}
                  </Button>
                </Link>
              </TooltipTippy>
              </LoadingAction>
              <LoadingAction action={async () => {
                const r = window.confirm(Messages.do_you_want_cancel_import);
                if (r == true) {
                  const result: any = await ReportActions.cancelImport(row.id);
                  if (result?.id) {
                    setUpdateDataTable({ [result.id]: result });
                  }
                }
              }}>
                <TooltipTippy label={Labels.cancel_import}>
                <Link
                  to={"#"}
                  className="ms-1 d-inline"
                >
                  <Button color="danger" outline size="sm px-1">
                    <HiXMark className="me-1" />
                    {t(Labels.cancel)}
                  </Button>
                </Link>
                </TooltipTippy>
              </LoadingAction>
            </Col>
          </Row>
        );
      }
    }
  ];

  let searchOptions: SearchOption[] = [
    {
      field: "name",
      label: Labels.name,
      filterBuilder: (value) => {
        return { contains: value.trim() };
      }
    }
  ];

  const actionOptions: ActionOption[] = [];
  const filterOptions: FilterOption[] = [];

  const refreshData = () => {
    setQueryState((prev: any) => ({ ...prev }));
  };

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={t(Labels.reports)}></Breadcrumb>
          <RemoteTable
            dataLoadable={[loadable, setQueryState]}
            filterOptions={filterOptions}
            searchOptions={searchOptions}
            tabOptions={[]}
            keyField={"id"}
            columns={columns}
            filterKey={"reports"}
            refresh={refreshData}
            actionOptions={actionOptions}
            updatedData={updateDataTable}
          />
        </Container>
      </div>
    </Fragment>
  );
};

const LoadingIcon = ({ className }: any) => {
  return (
    <div className={`d-flex justify-content-center ${className || ""}`}>
      <div className="text-center spinner-border" style={{ width: 16, height: 16 }} role="status" />
    </div>
  );
};

const IconStatus = [
  {
    value: ReportsStatus.FAILED,
    icon: <MdCancel size={22} color="#dc3545" />
  },
  {
    value: ReportsStatus.CANCELLED,
    icon: <MdCancel size={22} color="#dc3545" />
  },
  {
    value: ReportsStatus.CANCELLING,
    icon: <MdCancel size={22} color="#dc3545" />
  },
  {
    value: ReportsStatus.PENDING,
    icon: <MdPending size={22} color="#556ee6" />
  },
  {
    value: ReportsStatus.SUCCESS,
    icon: <MdCheckCircle size={22} color="green" />
  },
  {
    value: ReportsStatus.PROCESSING,
    icon: (
      <div>
        <LoadingIcon />
      </div>
    )
  }
];

const ViewReportPopup = (props: any) => {
  const {
    row: { outputData, outputFile, total, progress, status }
  } = props;
  return (
    <CardBody>
      <div className="d-flex flex-column">
        {status === ReportsStatus.PROCESSING ? (
          <>
            <p className="mb-3 me-2 fw-bold">
              {t(Labels.progress)}: {`${progress}`}
            </p>
            {/* <div className="text-center">{`${progress} of ${total}`}</div>
            <Progress className="mb-2" max={total} value={progress} /> */}
          </>
        ) : (
          <></>
        )}
        <div className="d-flex flex-column">
          {outputData?.stats ? (
            <>
              <p className="mb-3 me-2 fw-bold">
                {t(Labels.total_rows)}: {outputData?.stats?.total}
              </p>
              <p className="mb-3 fw-bold">
                {t(Labels.successfully_imported)}: {outputData?.stats?.success}
              </p>
              <p className="mb-3 fw-bold">
                {t(Labels.failed)}: {outputData?.stats?.failed}
              </p>
              <p className="mb-4 fw-bold">
                {t(Labels.skipped)}: {outputData?.stats?.skipped}
              </p>
            </>
          ) : (
            <p className="text-danger">{t(Labels.no_data_available)}</p>
          )}
          <div className="mb-2">
            {outputFile ? (
              <a href={outputFile} download={outputFile} className=" d-inline">
                <BsArrowDownCircle className="me-1" />
                {t(Labels.download)}
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </CardBody>
  );
};

interface LoadingActionProps {
  children: any;
  action: () => Promise<any>;
}

const LoadingAction = (props: LoadingActionProps) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await props.action();
    setLoading(false);
  };

  if (loading) {
    return <span className="d-inline-block mx-1"><LoadingIcon /></span>;
  }

  return <span onClick={handleClick}>{props.children}</span>;
};

export default ReportsPage;
