import { withPermission } from "common/hooks/use-permission"
import { Labels } from "common/labels"
import { t } from "core/translations"
import { ProductTypeActions } from "data/actions/product-type.action"
import { ProductTypeDto } from "data/services/product-type.service"
import { FormikHandlers, FormikHelpers, FormikState } from "formik"
import { ComponentProps} from "react"
import { ActionMeta, SingleValue } from "react-select"
import AsyncSelect from "react-select/async";
import { ActionEntities, ResourceEntities } from "types/permission-type"


export interface ProductTypeSelectProps extends ComponentProps<any> {
  validation?: FormikHandlers & FormikState<any> & FormikHelpers<any>
  value?: ProductTypeDto | any
  onChange: (value: SingleValue<ProductTypeDto>, actionMeta?: ActionMeta<any>) => void
}

export const ProductTypeSelect = (props: ProductTypeSelectProps) => {
  let { value, validation, onChange, getOptionValue, getOptionLabel, ...rest} = props

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={true}
      value={value}
      defaultValue={value}
      getOptionValue={(option: ProductTypeDto) => option ? `${option?.name}` : ''}
      getOptionLabel={(option: ProductTypeDto) => option ? `${option?.name}` : ''}
      loadOptions={ProductTypeActions.searchByProductTypeByName}
      placeholder={t(Labels.search_by_product_type_name)}
      onBlur={validation?.handleBlur}
      onChange={props.onChange}
      {...rest}
    />
  )
}