import { withPermission } from "common/hooks/use-permission"
import { AccountDto, AccountService, AccountServices } from "data/services/account.service"
import { omit } from "lodash"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { CrudActions } from "./crud.action"

class AccountAction extends CrudActions<
  AccountService,
  AccountDto,
  AccountDto
> {
  constructor() {
    super(AccountServices)
  }
  async getUserByUsername (value: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.userEntity,
      async () => {
        let filter: any = {}
        if(value){
          filter.username = { contains: value }
        }
        const pageData = await AccountServices.query({filter,page: 1})
        return pageData?.pageItems?.map((item: any) => omit({...item, newOptions: item.options}, 'options')) || [];
      }
    )
  }
}

export const AccountActions = new AccountAction()
