import format from "date-fns/format";

export class Dates {
  static formatDate(date?: Date) {
    return date ? format(new Date(date), "MMM dd, yyyy HH:mm") : ""
  }

  static toDefaultLocaleDateString(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
  }

  static toDefaultLocaleDate(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
  }
}