import { Labels } from "common/labels"
import { SelectOption } from "common/types"
import RadioGroup from "components/input/RadioGroup"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import { t } from "core/translations"
import { portableModalState } from "data/atoms/app.atom"
import { useEffect, useState } from "react"
import { Alert, Button, ButtonGroup, Label } from "reactstrap"
import { useRecoilState } from "recoil"
import { ExportType } from "../../../types/export.type";

export interface CustomizeDataTypeOptionsType {
  exportType: ExportType
  type: string,
  ext: string
}

export enum exportRadioDataValues {
  current_page = 'current_page',
  all_pages = 'all_pages',
  selected = 'selected'
}

const exportRadioDataNames: Record<string, string> = {
  [exportRadioDataValues.current_page]: t(Labels.current_page),
  [exportRadioDataValues.all_pages]: t(Labels.all_pages),
  [exportRadioDataValues.selected]: t(Labels.selected)
}
;
export enum exportRadioDataTypeValues {
  excel_file = 'excel_file',
  csv_file = 'csv_file',
  zip_file = 'Zip'
}

const exportRadioTypeNames: Record<string, string> = {
  [exportRadioDataTypeValues.excel_file]: t(Labels.excel_file),
  [exportRadioDataTypeValues.csv_file]: t(Labels.csv_file),
};

export const exportRadioDataOptions = [
  {
    label: exportRadioDataNames[exportRadioDataValues.selected],
    value: exportRadioDataValues.selected
  },
  { 
    label: exportRadioDataNames[exportRadioDataValues.current_page],
    value: exportRadioDataValues.current_page 
  },
  {
    label: exportRadioDataNames[exportRadioDataValues.all_pages],
    value: exportRadioDataValues.all_pages
  }
];

export const exportRadioDataTypeOptions = [
  { 
    label: exportRadioTypeNames[exportRadioDataTypeValues.excel_file],
    value: exportRadioDataTypeValues.excel_file
  },
  {
    label: exportRadioTypeNames[exportRadioDataTypeValues.csv_file],
    value: exportRadioDataTypeValues.csv_file
  },
];

export interface ExportModalProps {
  resourceName?: string
  type?: string
  children?: any
  onClose: Function
  childrenState?: any
  selectedIds: Array<any>
  handleExport: Function
  exportDataType?: exportRadioDataTypeValues
  totalItems?: number
}

export const ExportModal = (props: ExportModalProps) => {
  const { resourceName, type,  onClose, childrenState, exportDataType, selectedIds, totalItems} = props
  const [modalState, setPortableModal] = useRecoilState(portableModalState)
  const [open, setOpen] = useState(false)
  const [exportedData, setExportedData] = useState<SelectOption>(exportRadioDataOptions[0])
  const [exportedFile, setExportedFile] = useState<SelectOption>(exportRadioDataTypeOptions[0])
  const isImporting = () => type != ExportType.NULL
  const [titleModal, setTitleModal] = useState<string>()

  useEffect(() => {
    switch(type) {
      case ExportType.FOR_AUTO_DESIGN:
        setTitleModal(t(Labels.export_for_auto_design))
        return
      case ExportType.FOR_FULFILL_SERVICE:
        setTitleModal(t(Labels.export_for_fulfill_service))
        return
      case ExportType.PERSONAL_CUSTOM:
        setTitleModal(t(Labels.export_personal_custom_design))
        return
      case ExportType.REVIEW_FULFILLMENT_ORDER:
        setTitleModal(t(Labels.export_for_review_fulfillment_order))
        return
      case ExportType.SUBMITTING_FULFILLMENT_ORDER:
        setTitleModal(t(Labels.export_for_submitting_fulfillment_order))
        return
      default:
        setTitleModal('')
        return
    }
  }, [type])

  const handleExport = async () => {
    props.handleExport(exportedData.value, exportedFile.value, childrenState)
  }

  const getInputDisabled = () => {
    let itemsDisable: any = []
    if (exportDataType) {
      itemsDisable = exportRadioDataTypeOptions.map((item: any) => {
        if (item.value != exportDataType) {
          return item.value
        }
      })
    }
    return itemsDisable
  }

  const getCheckValueExportDataType = () => {
    if (exportDataType) {
      let [item] = exportRadioDataTypeOptions.filter((item: any) => item.value == exportDataType)
      return item.value
    }
    return exportedFile.value
  }

  const getSideComponent = (name: string, value: string) => {
    if (value == exportRadioDataOptions[0].value) {
      return (
        <>
          <Label className="form-check-label ms-1" htmlFor={`${name}-${value}`}>{`${resourceName} (${selectedIds.length})`}</Label>
        </>
      )
    }
    if (value == exportRadioDataOptions[2].value && totalItems) {
      return (
        <>
          <Label className="form-check-label ms-1" htmlFor={`${name}-${value}`}>{`(${totalItems} ${resourceName})`}</Label>
        </>
      )
    }
    return
  }

  useEffect(() => setOpen(isImporting()), [props.type])
  useEffect(() => setOpen(modalState.open && isImporting()), [modalState])
  useEffect(() => {
    setPortableModal({
      open,
      size: 'lg',
      title: titleModal,
      content: (
        <>
          <ModalContent>
            <div className="ms-3 me-3">
              <p>{t(Labels.processing_time_depends_on_the_number, {resourceName: resourceName})}</p>
              <p className="mb-2 fw-bold">{Labels.export + ':'}</p>
              <div className="ms-1">
                <RadioGroup 
                  name={'exportRadioData'}
                  items={exportRadioDataOptions}
                  checkedValue={exportedData?.value}
                  onChange={setExportedData}
                  classNameItem={'mb-2'}
                  sideComponent={getSideComponent}
                />
              </div>
              <p className="mb-2 fw-bold">{Labels.export_as + ':'}</p>
              <div className="ms-1">
                <RadioGroup
                  name={'exportRadioType'}
                  items={exportRadioDataTypeOptions}
                  checkedValue={getCheckValueExportDataType()}
                  onChange={setExportedFile}
                  classNameItem={'mb-2'}
                  itemsDisabled={getInputDisabled()}
                />
              </div>
            </div>
          </ModalContent>
          <ModalButtons
            confirmText={t(Labels.export)}
            confirm={ async () => {
              handleExport()
            } }
          />
        </>
      )
    })
    if(!open) {
      onClose()
    }
  }, [open, exportedData, exportedFile, childrenState])
  
  useEffect(() => {
    if (!open) {
      setExportedData(exportRadioDataOptions[0])
      setExportedFile(exportRadioDataTypeOptions[0])
    }
  }, [open])

  return (
    <></>
  )
}


export interface propsSimpleExportComponent {
  type?: string
  onClose: Function
  selectedIds: Array<any>
  handleExport: Function
  exportDataType?: exportRadioDataTypeValues
  totalItems?: number
  textNote?: string
}

export const SimpleExportComponent = (props: propsSimpleExportComponent) => {

  return <BaseModalExport 
    {...props}
  />
}

interface propsBaseModalExport {
  children?: any
  childrenState?: any
  titleModal?: string
  message?: string
  customizeDataTypeOptions?: CustomizeDataTypeOptionsType
  closeAfterConfirm?: boolean 
}

export const BaseModalExport = (props: propsBaseModalExport & propsSimpleExportComponent) => {
  const { type, children, onClose, childrenState, exportDataType, selectedIds, totalItems, titleModal, message, customizeDataTypeOptions } = props
  const [modalState, setPortableModal] = useRecoilState(portableModalState)
  const [open, setOpen] = useState(false)
  const [exportedData, setExportedData] = useState<SelectOption>(exportRadioDataOptions[0])
  const [exportedFile, setExportedFile] = useState<SelectOption>(exportRadioDataTypeOptions[0])
  const isImporting = () => type != ExportType.NULL
  const [exportDataTypeOptions, setExportDataTypeOptions] = useState<SelectOption[]>(exportRadioDataTypeOptions)

  useEffect(() => {
    if (customizeDataTypeOptions) {
      if (customizeDataTypeOptions.type == exportRadioDataTypeValues.zip_file) {
        setExportDataTypeOptions([
          {value: customizeDataTypeOptions.type, label: `${customizeDataTypeOptions.type} (${customizeDataTypeOptions.ext})`},
          ...exportRadioDataTypeOptions,
        ])
      }
    } else {
      setExportDataTypeOptions(exportRadioDataTypeOptions)
    }
  }, [customizeDataTypeOptions])

  const handleExport = async () => {
    return await props.handleExport(exportedData.value, exportedFile.value, childrenState)
  }

  const getInputDisabled = () => {
    let itemsDisable: any = []
    itemsDisable = exportDataTypeOptions.map((item: any) => {
      if ((exportDataType && item.value != exportDataType) || 
          (customizeDataTypeOptions && item.value != customizeDataTypeOptions?.type)) 
      {
        return item.value
      }
    })
    return itemsDisable
  }

  const getCheckValueExportDataType = () => {
    if (exportDataType || customizeDataTypeOptions) {
      let [item] = exportDataTypeOptions.filter((item: any) => {
        if ((exportDataType && item.value == exportDataType) || 
          (customizeDataTypeOptions && item.value == customizeDataTypeOptions?.type)) 
        {
          return true
        }
      })
      return item?.value
    }
    return exportedFile.value
  }

  const getSideComponent = (name: string, value: string) => {
    if (value == exportRadioDataOptions[0].value) {
      return (
        <>
          <Label className="form-check-label ms-1" htmlFor={`${name}-${value}`}>{`items (${selectedIds.length})`}</Label>
        </>
      )
    }
    if (value == exportRadioDataOptions[2].value && totalItems) {
      return (
        <>
          <Label className="form-check-label ms-1" htmlFor={`${name}-${value}`}>{`(${totalItems} items)`}</Label>
        </>
      )
    }
    return
  }

  useEffect(() => setOpen(isImporting()), [props.type])
  useEffect(() => setOpen(modalState.open && isImporting()), [modalState])
  useEffect(() => {
    setPortableModal({
      open,
      size: 'lg',
      title: titleModal,
      content: (
        <>
          <ModalContent>
            <div className="ms-3 me-3">
              <p>{message}</p>
              <p className="mb-2 fw-bold">{Labels.export + ':'}</p>
              <div className="ms-1">
                <RadioGroup 
                  name={'exportRadioData'}
                  items={exportRadioDataOptions}
                  checkedValue={exportedData?.value}
                  onChange={setExportedData}
                  classNameItem={'mb-2'}
                  sideComponent={getSideComponent}
                />
              </div>
              <p className="mb-2 fw-bold">{Labels.export_as + ':'}</p>
              <div className="ms-1">
                <RadioGroup
                  name={'exportRadioType'}
                  items={exportDataTypeOptions}
                  checkedValue={getCheckValueExportDataType()}
                  onChange={setExportedFile}
                  classNameItem={'mb-2'}
                  itemsDisabled={getInputDisabled()}
                />
              </div>
              { children }
            </div>
          </ModalContent>
          <ModalButtons
            confirmText={t(Labels.export)}
            confirm={ async () => {
              return await handleExport()
            } }
            closeAfterConfirm={props.closeAfterConfirm}
          />
        </>
      )
    })
    if(!open) {
      onClose()
    }
  }, [open, exportedData, exportedFile, childrenState])
  
  useEffect(() => {
    if (!open) {
      setExportedData(exportRadioDataOptions[0])
      setExportedFile(exportRadioDataTypeOptions[0])
    }
  }, [open])

  return (
    <></>
  )
}