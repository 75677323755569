import { SaleChannelDto } from './../services/sale-channel-service';
import { CrudActions } from "./crud.action";
import {
  CreateSaleChannelDto,
  SaleChannelService,
  SaleChannelServices,
  UpdateSaleChannelDto
} from "../services/sale-channel-service";
import { t } from "../../core/translations";
import { Messages } from "../../common/messages";
import { Toasts } from "../../core/notification/notifications";
import { Filter } from "core/rest-api/query-builder";
import { ActionEntities, ResourceEntities } from 'types/permission-type';
import { withPermission } from 'common/hooks/use-permission';
import { omit } from "lodash";

class SaleChannelAction extends CrudActions<SaleChannelService, CreateSaleChannelDto, UpdateSaleChannelDto> {
  constructor() {
    super(SaleChannelServices);
  }

  async sync(id: string | number) {
    try {
      await this.service.sync(+id);
      Toasts.info(t(Messages.sync_sale_channel_order_successfully))
    } catch (error: any) {
      Toasts.error(t(Messages.unknown))
    }
  }

  async syncAll() {
    try {
      await this.service.syncAll();
    } catch (error: any) {
      Toasts.error(t(Messages.unknown))
      Toasts.info(t(Messages.sync_sale_channel_order_successfully))
    }
  }

  async searchByDomain(domain: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.saleChannelEntity,
      async () => {
        let filter: Filter<SaleChannelDto> = {};
        if (domain) {
          filter.domain = { contains: domain }
        }
        let pageData = await SaleChannelServices.query({ filter, page: 1})

        return pageData?.pageItems?.map((item: any) => omit(item, 'options')) || []
      })
    
  }

  async searchByName(domain: string) {
    let filter: Filter<SaleChannelDto> = {};
    if (domain) {
      filter.name = { contains: domain }
    }
    let pageData = await SaleChannelServices.query({ filter, page: 1})
    return (pageData?.pageItems || []).map((val: any) => omit(val || {}, 'options'))
  }
}

export const SaleChannelActions = new SaleChannelAction();