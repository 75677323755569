import { t } from "../../../core/translations";
import { Labels } from "../../../common/labels";

export enum ProductStatus {
  ACTIVE = 'active',
  DRAFT = 'draft'
}

export const ProductStatusName: Record<string, string> = {
  [ProductStatus.ACTIVE]: t(Labels.active),
  [ProductStatus.DRAFT]: t(Labels.draft)
}

export const ProductStatusOptions = [
  { label: ProductStatusName[ProductStatus.DRAFT], value: ProductStatus.DRAFT },
  { label: ProductStatusName[ProductStatus.ACTIVE], value: ProductStatus.ACTIVE}
]

export const ProductStatusValues = [
  ProductStatus.ACTIVE,
  ProductStatus.DRAFT
]

export enum ProductPublishStatusTab {
  UPLOADING = 'pending',
  UPLOAD_ERROR = 'issue'
}

export const ProductPublishStatusTabValues = [
  ProductPublishStatusTab.UPLOADING,
  ProductPublishStatusTab.UPLOAD_ERROR
]

export const ProductPublishStatusTabName: Record<string, string> = {
  [ProductPublishStatusTab.UPLOADING]: t(Labels.publishing),
  [ProductPublishStatusTab.UPLOAD_ERROR]: t(Labels.publish_error)
}

export enum PublishStatus {
  PUBLISHED= "published",
  PARTIAL = "partial",
  PENDING = "pending",
  ISSUE = "issue"
}

export const PublishStatusName: Record<string, string> = {
  [PublishStatus.PUBLISHED]: t(Labels.published),
  [PublishStatus.PARTIAL]: t(Labels.partial),
  [PublishStatus.PENDING]: t(Labels.pending),
  [PublishStatus.ISSUE]: t(Labels.issue)
}

export const PublishStatusOptions = [
  { label: PublishStatusName[PublishStatus.PUBLISHED], value: PublishStatus.PUBLISHED },
  { label: PublishStatusName[PublishStatus.PARTIAL], value: PublishStatus.PARTIAL},
  { label: PublishStatusName[PublishStatus.PENDING], value: PublishStatus.PENDING},
  { label: PublishStatusName[PublishStatus.ISSUE], value: PublishStatus.ISSUE}
]


export const PublishStatusColor: Record<string, string> = {
  [PublishStatus.PUBLISHED]: "success",
  [PublishStatus.PARTIAL]: "warning",
  [PublishStatus.PENDING]: "primary",
  [PublishStatus.ISSUE]: "danger"
}

export const ProductStatusColor: Record<string, string> = {
  [ProductStatus.ACTIVE]: 'success',
  [ProductStatus.DRAFT]: 'light'
}

