import { Labels } from "../common/labels";
import { t } from "../core/translations";

export enum FulfillmentStatus {
  NULL = 'null', // This is new order
  PENDING = 'pending',
  REQUEST = 'request',
  FULFILLED = 'fulfilled',
  PARTIAL = 'partial',
  ERROR = 'error',
  CANCELLED = 'cancelled',
}

export const FulfillmentStatusValues = [
  FulfillmentStatus.NULL,
  FulfillmentStatus.PENDING,
  FulfillmentStatus.REQUEST,
  FulfillmentStatus.FULFILLED,
  FulfillmentStatus.PARTIAL,
  FulfillmentStatus.ERROR,
  FulfillmentStatus.CANCELLED,
]

export const FulfillmentStatusName: Record<string, string> = {
  [FulfillmentStatus.NULL]: t(Labels.new_order), // This is new order
  [FulfillmentStatus.PENDING]: t(Labels.in_progress),
  [FulfillmentStatus.REQUEST]: t(Labels.wait_for_fulfill),
  [FulfillmentStatus.FULFILLED]: t(Labels.closed),
  [FulfillmentStatus.PARTIAL]: t(Labels.partial),
  [FulfillmentStatus.ERROR]: t(Labels.not_eligible),
  [FulfillmentStatus.CANCELLED]: t(Labels.cancelled),
}

export const FulfillmentStatusColor: Record<string, string> = {
  [FulfillmentStatus.NULL]: 'light', // This is new order
  [FulfillmentStatus.PENDING]: 'info',
  [FulfillmentStatus.REQUEST]: 'primary',
  [FulfillmentStatus.FULFILLED]: 'success',
  [FulfillmentStatus.PARTIAL]: 'secondary',
  [FulfillmentStatus.ERROR]: 'danger',
  [FulfillmentStatus.CANCELLED]: 'warning',
}