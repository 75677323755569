import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { createLinkEmbedParam } from "utils/embed";

interface BreadcrumbProps {
  title?: string,
  goBack?: boolean | string
  children?: any
}

const Breadcrumb = (props: BreadcrumbProps)  => {
  const history = useHistory()
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div>
            {
              !props.goBack ? null : (
                <Button
                type="button"
                variant="secondary"
                className="me-4 btn-goback"
                onClick={() => {
                  if (typeof(props.goBack) === "string" && history.length === 1) {
                    history.replace(createLinkEmbedParam(props.goBack).to)
                  }
                      else{  
                        history.goBack();
                      }
                    }}
                    >
                  <i className="bx bx-chevron-left font-size-16 align-middle"></i>
                </Button>
              )
            }
            {
              props.title ? <h4 className="font-size-18 title-page">{props.title}</h4> : null
            }
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              { props.children }
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Breadcrumb
