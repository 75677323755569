import { Labels } from "common/labels"
import { ROW_HEIGHT } from "components/bulk/BulkEditor"
import { t } from "core/translations"
import { FulfillVariantDto } from "data/services/fulfill-variation.service"
import _, { isEmpty } from "lodash"
import { SelectEditor } from "pages/products/artwork-bulk-create.page"
import { useEffect, useState } from "react"
import DataGrid, { Column, CopyEvent, EditorProps, FillEvent, PasteEvent, TextEditor } from "react-data-grid"
import { BiLinkExternal } from "react-icons/bi"
import { Link } from "react-router-dom"
import { RouteNames } from "routes"
import { Metafield } from "types/metafield.type"
import { Forms } from "utils/forms"
import { Button, Col, Input, InputGroup, InputGroupText} from "reactstrap";
import { MdErrorOutline } from "react-icons/md"
import { createUrlEmbed } from "utils/embed"

export interface VariantOptionsType {
  name: string,
  value: Array<string>
}

export const FulfillVariantTable = (props: any) => {
  const {validation} = props
  const [defaultBaseCost, setDefaultBaseCost] = useState<number>(0)

  useEffect(() => {
    setDefaultBaseCost(validation.values?.baseCost)
  }, [validation.values?.baseCost])
  const [variantKeys, setVariantKeys] = useState<number>(0)

  const createVariants = (currentVariants = validation.values.variants) => { 
    let count = variantKeys
    let newVariants = currentVariants?.map((v: any, index: number) => {
      if (v.hasOwnProperty('numberKey')) {
        return v
      } else {
        count ++
        return {...v, numberKey: count}
      }
    })
    let variants = !newVariants ? [] : [...newVariants]
    if ((!variants?.length || !isEmpty(variants[variants.length - 1]) 
      && (Object.keys(variants[variants.length - 1]).length > 1))) {
      variants.push({baseCost: defaultBaseCost} as any)
    }
    return {variants: variants, count: count}
  }

  const [variants, setVariants] = useState(createVariants().variants)
  const [variantOptions, setVariantOptions] = useState<VariantOptionsType[]>([])

  useEffect(() => {
    const listVariants = createVariants().variants
    const count = createVariants().count
    setVariantKeys(count)
    setVariants(listVariants)
  }, [validation.values.variants, defaultBaseCost])

  const [columns, setColumns] = useState<Column<any, any>[]>([])

  const createUpdateOptions = (value: any, item: Metafield, row: FulfillVariantDto) => {
    const updateValue = { name: item.name, value: value.value }
    let variantOptionsUpdate
    if (!row?.variantOptions) {
      variantOptionsUpdate = [updateValue]
    } else {
      let existOption = row.variantOptions?.filter((vo: any) => vo.name == updateValue.name)
      if (existOption.length > 0) {
        variantOptionsUpdate = row.variantOptions.map((vo: any) => vo.name == updateValue.name 
          ? updateValue : vo)
      } else {
        variantOptionsUpdate = [
          ...(row.variantOptions as Array<any>),
          updateValue
        ]
      }
    }
    return variantOptionsUpdate
  }

  const onRowsChange = (data: any, row: any) => {
    let deleteRow = data?.filter((i: any) => i?.hasOwnProperty('_remove'))
    let tempRow = data
    if (deleteRow?.length > 0) {
      tempRow = data.filter((item: any, index: number) => index != row.indexes[0])
    }
    let removedLastElement = (Object.keys(tempRow[tempRow?.length - 1]).length == 1)
    ? tempRow.filter((i: any) => Object.keys(i).length > 1) 
    : tempRow
    checkDuplicateSku(removedLastElement)
  }

  const checkNameExistInVariantOptions = (name: string): boolean => {
    // check column exist in array validation.valies.variantOptions
    if (validation.values?.variantOptions?.find((i: any) => i.name == name)) {
      return true
    } else {
      return false
    }
  }

  const handleFill = ({ columnKey, sourceRow, targetRow }: FillEvent<any>) => {
    if (checkNameExistInVariantOptions(columnKey)) {
      const valueVariantOptionSourceRow: Array<any> = sourceRow.variantOptions?.filter((vo: any) => vo.name == columnKey)
      const valueSelect = valueVariantOptionSourceRow ? valueVariantOptionSourceRow[0] : {}
      return { ...targetRow, variantOptions: createUpdateOptions({value: valueSelect.value}, {name: columnKey}, targetRow) }
    } else {
      return { ...targetRow, [columnKey]: sourceRow[columnKey] };
    }
  }

  const handleCopy = ({ sourceRow, sourceColumnKey }: CopyEvent<any>) => {
    if (window.isSecureContext) {
      let value
      if (checkNameExistInVariantOptions(sourceColumnKey)) {
        const valueVariantOptionSourceRow: Array<any> = sourceRow.variantOptions?.filter((vo: any) => vo.name == sourceColumnKey)
        const valueSelect = valueVariantOptionSourceRow ? valueVariantOptionSourceRow[0] : {}
        value = valueSelect.value
      } else {
        value = sourceRow[sourceColumnKey]
      }
      navigator.clipboard.writeText(value);
    }
  }

  const handlePaste = ({sourceColumnKey, sourceRow, targetColumnKey, targetRow }: PasteEvent<any>) => {
    if (checkNameExistInVariantOptions(targetColumnKey)) {
      if (sourceColumnKey == targetColumnKey) {
        const valueVariantOptionSourceRow: Array<any> = sourceRow.variantOptions?.filter((vo: any) => vo.name == sourceColumnKey)
        const valueSelect = valueVariantOptionSourceRow ? valueVariantOptionSourceRow[0] : {}
        return { ...targetRow, variantOptions: createUpdateOptions({value: valueSelect.value}, {name: sourceColumnKey}, targetRow) }
      } else {
        return { ...targetRow }
      }
    } else {
      return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    }
  }

  const rowKeyGetter = (row: any) => {
    return `${row.id} - ${row.numberKey}`;
  }

  const checkDuplicateSku = (valueVariants = validation.values?.variants) => {
    let variantSkuCounts = valueVariants.reduce((curr: any, variant: any) => ({
      ...curr,
      [variant.sku]: (curr[variant.sku] || 0) + 1
    }), {})

    let newValues = valueVariants
      .filter((variant: any) => !isEmpty(variant))
      .map((variant: any) => variantSkuCounts[variant.sku] > 1 
        ? { ...variant, __duplicated: true } 
        : (variant.__duplicated ? _.omit(variant,["__duplicated"]) : variant) );
    setVariants(newValues)
    validation.setFieldValue('variants', newValues)
  }

  useEffect(() => {
    setVariantOptions(validation.values?.variantOptions)
  }, [validation.values?.variantOptions])

  useEffect(() => {
    let tempArr: Column<any, any>[] = [
      {
        key: 'sku',
        name: t(Labels.sku),
        frozen: true,
        minWidth: 250,
        // eslint-disable-next-line react/display-name
        formatter: ({ row }: any = {}) => (
          <div className="d-flex align-items-center justify-content-between">
            {row?.sku ? row.sku :  <p style={{ opacity: 0.7 }}>{t(Labels.sku_hint)}</p>}
            {row?.__duplicated && <MdErrorOutline color="red" size={18} opacity={0.7}/>}
          </div>
        ),
        // eslint-disable-next-line react/display-name
        editor: ({ row, onRowChange }: any = {}) => (
          <Input
            autoFocus={true}
            value={row?.sku}
            onChange={(event) => {
              onRowChange({...row, sku: event.target.value}, false)
            }}
          />
        )
      }
    ]
    validation.values?.variantOptions?.forEach((item: Metafield) => {
      tempArr.push({
        key: item?.name,
        name: t(item?.name),
        minWidth: 200,
        // eslint-disable-next-line react/display-name
        formatter: ({ row }: any = {}) => {
          let value = (row?.variantOptions?.filter((vo: any) => vo && vo.name == item.name)?.length > 0
            && row?.variantOptions?.filter((vo: any) => vo && vo.name == item.name))[0]?.value
          return (
            <div>
              {
                (row?.variantOptions?.filter((vo: any) => vo 
                  && vo.name == item.name 
                  // && variantOptions?.filter((vo: VariantOptionsType) => vo?.value?.includes(value)).length > 0
                ).length > 0 && value)
                ? value
                : (<p style={{ opacity: 0.7 }}>{t(Labels.any_select_hint) + `${item.name}`}</p>)
              }
            </div>
          )
        },
        // eslint-disable-next-line react/display-name
        editor: ({row, onRowChange}: EditorProps<any>) => {
          return (
            <SelectEditor
              embedHeight={ROW_HEIGHT}
              value={() => {
                let value = row?.variantOptions?.filter((vo: any) => vo && vo?.name == item?.name)
                return {label: value?.value, value: value?.value}
              }}
              openMenuOnFocus={true}
              menuPortalTarget={document.body}
              options={Forms.createSelectOptions(validation.values 
                && variantOptions?.filter((vo: any) => vo && vo.name == item.name)[0]?.value)}
              getOptionLabel={(option: any) => option?.value || option}
              getOptionValue={(option: any) => option?.value || option}
              onChange={(value: any) => {
                onRowChange({...row, variantOptions: createUpdateOptions(value, item, row)}, true)
              }}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            />
          )
        },
      })
    })
    tempArr.push(
      {
        key: 'baseCost',
        name: t(Labels.base_cost),
        width: 130,
        minWidth: 100,
        editable: true,
        // eslint-disable-next-line react/display-name
        formatter: ({ row }: any = {}) => (
          <div className='text-center align-items-center'>
            {!isNaN(row?.baseCost) ? row.baseCost :  <p style={{ opacity: 0.7 }}>{t(Labels.base_cost_hint)}</p>}
          </div>
        ),
        // eslint-disable-next-line react/display-name
        editor: ({ row, onRowChange }: any = {}) => (
          <Input
            autoFocus={true}
            type={'number'}
            step={'0.1'}
            value={row?.baseCost}
            onChange={(event) => {
              onRowChange({
                ...row, 
                baseCost: event.target.value
                  ? parseFloat(event.target.value as string) : 0
              }, false)
            }}
          />
        )
      },
      {
        key: 'action',
        name: '',
        width: 50,
        minWidth: 50,
        editable: true,
        // eslint-disable-next-line react/display-name
        formatter: ({ row, onRowChange }: any = {}) => (
          <div className='text-center align-items-center'>
            {
              row.id ? (
                <div className={`text-center`} style={{ width: 30 }}>
                  <Link target="_blank" to={{pathname: createUrlEmbed(`${RouteNames.FULFILL_VARIANTS}/${row.id}`)}} >
                    <BiLinkExternal  size={16}/>
                  </Link>
                </div>) : (
                  <div className={`text-center`} style={{ width: 30 }}>
                  <Link
                    to={"#"}
                    onClick={() => onRowChange({...row, _remove: true}, true)} 
                  >
                    <i className="mdi mdi-delete font-size-18" />
                  </Link>
                </div>
                )
            }
          </div>
        )
      }
    )
    setColumns(tempArr)
  }, [validation.values?.variants, variantOptions, variants])

  const [countAddVariant, setCountAddVariant] = useState(1)


  const addFulfillVariant = () => {
    if(countAddVariant > 0){
      const newVariant = Array.from({length: countAddVariant}, (v, idx) => {
        return {create: true}
      })
      validation.setFieldValue("variants", [...(validation.values?.variants || []), ...newVariant])
    }
  }


  return (
    <div>
      <DataGrid
        columns={columns}
        rows={variants || []}
        rowKeyGetter={rowKeyGetter}
        onRowsChange={onRowsChange}
        onFill={handleFill}
        onCopy={handleCopy}
        onPaste={handlePaste}
        rowHeight={ROW_HEIGHT}
        defaultColumnOptions={{ resizable: true }}
        className={"fill-grid rdg-light"}
        style={{ height: "100%", blockSize: "100%" }}
        rowClass={(row: any) => "react-data-grid-normal-row"}
      />
      <Col className="d-flex mt-4 mb-4">
      <InputGroup className="col-auto" style={{zIndex: 1}}>
        <Input
          name={"countAddVariants"}
          values={countAddVariant}
          min={1}
          defaultValue={1}
          type="number"
          onChange={event => setCountAddVariant(+event.target.value)}
          className="d-inline-block"
          style={{ maxWidth: 80 }}
        />
        <InputGroupText>
          rows
        </InputGroupText>
        <Button className="ms-1 flex-1" color="primary" onClick={addFulfillVariant}>
          {t(Labels.add_rows)}
        </Button>
      </InputGroup>
      </Col>
    </div>
  )
}
