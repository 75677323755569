import { permissionsSelector, userSelector } from "data/atoms/auth.atom"
import React, { Fragment, useEffect, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { resourceGroup, ResourceGroupEntities } from "types/permission-type"

export interface PropsType {
  children: any
  resource: string
  action: string
}

export const WithPermission = (props: PropsType) => {
  let { action, resource, children } = props
  const roles = useRecoilValue(permissionsSelector)
  let display = false
  
  let arrayResource: Array<string> = []
  if (action === 'any') {
    Object.keys(roles).forEach((role: any) => {
      if (roles[role]?.includes('*')) {
        display = true
      } else {
        arrayResource = arrayResource?.concat(roles[role])
        if (roles[role]?.includes(resource)) {
          display = true
        }
      }
    })
    if (arrayResource?.length && display == false) {
      display = arrayResource?.some((item: any) => resourceGroup[resource]?.includes(item))
    }
  } else {
    display = roles[action]?.includes('*') || roles[action]?.includes(resource) || roles['*']?.includes('*') || roles['*']?.includes(resource)
  }

  return (
    <Fragment>
      {display ? children : null} 
    </Fragment>
  )
}