import { CrudService } from "./crud.service";
import { apiBaseUrls } from "data/rest-api-config";
import { RequestOptions } from "core/rest-api/rest.api";
import axios from "axios"


export interface ReportDto {

}

export const ReportServiceApiPath = {
  BASE: "/report",
  WITH_ID: "/report/{id}",
  QUERY: "/report/query",
  RETRY: "/report/{id}/retry",
  CANCEL: "/report/{id}/cancel",
}

export class ReportService extends CrudService<any, any, any> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: ReportServiceApiPath.WITH_ID,
        updateById: ReportServiceApiPath.WITH_ID,
        deleteById: ReportServiceApiPath.WITH_ID,
        query: ReportServiceApiPath.QUERY,
        base: ReportServiceApiPath.BASE,
      },
    })
  }

  async getDataFileExcel (apiPath: string, options?: RequestOptions) {
    let response = await axios(apiPath, {
      ...options,
    })
    return response
  }

  
  async retryImport(id: number | string, options: RequestOptions = {}) {
    let result = await this.restApi.put(ReportServiceApiPath.RETRY, {
      params: { id },
      ...options
    })
    return this.returnDataOrThrow(result)
  }

  async cancelImport(id: number | string, options: RequestOptions = {}) {
    let result = await this.restApi.put(ReportServiceApiPath.CANCEL, {
      params: { id },
      ...options
    })
    return this.returnDataOrThrow(result)
  }


}

export const ReportServices = new ReportService()
