import { Labels } from "common/labels";
import { t } from "../core/translations";
import { pick } from "lodash";

export enum FulfillStatus {
  NOT_ELIGIBLE = 'not-eligible',
  ELIGIBLE = 'eligible',
  IN_DESIGN = 'in-design',
  FULFILLABLE = 'fulfillable',
  SUBMITTING = 'submitting',
  SUBMITTED = 'submitted',
  FAILED = 'rejected',
  CLOSED = 'closed',
  ON_HOLD = 'on-hold',
  CANCELLED = 'cancelled',
}

export const FulfillStatusValues = [
  FulfillStatus.NOT_ELIGIBLE,
  FulfillStatus.ELIGIBLE,
  FulfillStatus.IN_DESIGN,
  FulfillStatus.FULFILLABLE,
  FulfillStatus.ON_HOLD,
  FulfillStatus.FAILED,
  FulfillStatus.SUBMITTING,
  FulfillStatus.SUBMITTED,
  FulfillStatus.CLOSED,
  FulfillStatus.CANCELLED,
]

export const FulfillStatusName: Record<string, string> = {
  [FulfillStatus.NOT_ELIGIBLE]: t(Labels.not_eligible),
  [FulfillStatus.ELIGIBLE]: t(Labels.new_order),
  [FulfillStatus.IN_DESIGN]: t(Labels.wait_for_design),
  [FulfillStatus.FULFILLABLE]: t(Labels.wait_for_fulfill),
  [FulfillStatus.SUBMITTING]: t(Labels.fulfill_processing),
  [FulfillStatus.SUBMITTED]: t(Labels.fulfilling),
  [FulfillStatus.CLOSED]: t(Labels.fulfilled),
  [FulfillStatus.FAILED]: t(Labels.failure_fulfill),
  [FulfillStatus.ON_HOLD]: t(Labels.on_hold),
  [FulfillStatus.CANCELLED]: t(Labels.cancelled),
}

export const EditableFulfillStatuses = [
  FulfillStatus.NOT_ELIGIBLE,
  FulfillStatus.ELIGIBLE,
  FulfillStatus.IN_DESIGN,
  FulfillStatus.FULFILLABLE,
  FulfillStatus.ON_HOLD,
  FulfillStatus.FAILED
]

export const EditableFulfillStatusName: Record<string, string> = pick(FulfillStatusName, ...EditableFulfillStatuses)

export const FulfillStatusColor: Record<string, string> = {
  [FulfillStatus.NOT_ELIGIBLE]: 'danger',
  [FulfillStatus.ELIGIBLE]: 'primary',
  [FulfillStatus.IN_DESIGN]: 'primary',
  [FulfillStatus.FULFILLABLE]: 'info',
  [FulfillStatus.SUBMITTED]: 'info',
  [FulfillStatus.FAILED]: 'danger',
  [FulfillStatus.CLOSED]: 'success',
  [FulfillStatus.ON_HOLD]: 'dark',
  [FulfillStatus.CANCELLED]: 'dark',
}

export enum FulfillDesignStatus {
  NULL = 'null',
  CUSTOM_REVIEW = "custom_review",
  WAIT_FOR_DESIGN = 'pending',
  DESIGNING = 'designing',
  DESIGNED = 'designed',
  DESIGNING_AUTO = 'designing_auto',
  DESIGNED_AUTO = 'designed_auto',
  GENERATED_AUTO = 'generated_auto',
  UPLOADING = 'uploading'
  // IN_REVIEW = 'in-review',
  // REVIEWED = 'reviewed',
}

export const FulfillDesignStatusValues = [
  FulfillDesignStatus.NULL,
  FulfillDesignStatus.CUSTOM_REVIEW,
  FulfillDesignStatus.WAIT_FOR_DESIGN,
  FulfillDesignStatus.DESIGNING,
  FulfillDesignStatus.DESIGNED,
  FulfillDesignStatus.DESIGNING_AUTO,
  FulfillDesignStatus.DESIGNED_AUTO,
  FulfillDesignStatus.GENERATED_AUTO,
  FulfillDesignStatus.UPLOADING
  // FulfillDesignStatus.IN_REVIEW,
  // FulfillDesignStatus.REVIEWED,
]

export const FulfillDesignStatusName: Record<string, string> = {
  [FulfillDesignStatus.NULL]: t(Labels.null),
  [FulfillDesignStatus.CUSTOM_REVIEW]: t(Labels.custom_review),
  [FulfillDesignStatus.WAIT_FOR_DESIGN]: t(Labels.wait_for_design),
  [FulfillDesignStatus.DESIGNING]: t(Labels.designing),
  [FulfillDesignStatus.DESIGNING_AUTO]: t(Labels.designing_auto),
  [FulfillDesignStatus.DESIGNED]: t(Labels.designed),
  [FulfillDesignStatus.DESIGNED_AUTO]: t(Labels.designed_auto),
  [FulfillDesignStatus.GENERATED_AUTO]: t(Labels.generated),
  [FulfillDesignStatus.UPLOADING]: t(Labels.uploading),
  // [FulfillDesignStatus.IN_REVIEW]: t(Labels.wait_for_review),
  // [FulfillDesignStatus.REVIEWED]: t(Labels.reviewed),
}

export const FulfillDesignStatusColor: Record<string, string> = {
  [FulfillDesignStatus.NULL]: 'light',
  [FulfillDesignStatus.CUSTOM_REVIEW]: 'info',
  [FulfillDesignStatus.WAIT_FOR_DESIGN]: 'primary',
  [FulfillDesignStatus.DESIGNING]: 'warning',
  [FulfillDesignStatus.DESIGNING_AUTO]: 'warning',
  [FulfillDesignStatus.DESIGNED]: 'success',
  [FulfillDesignStatus.DESIGNED_AUTO]: 'success',
  [FulfillDesignStatus.GENERATED_AUTO]: 'success',
  [FulfillDesignStatus.UPLOADING]: 'info',
  // [FulfillDesignStatus.IN_REVIEW]: 'primary',
  // [FulfillDesignStatus.REVIEWED]: 'success',
}

export enum FulfillStatusIssue {
  NONE = 'none',
  NO_LINE_ITEM = 'no_line_item',
  MULTI_FULFILL_PRODUCT = 'multi_fulfill_product',
  NO_FULFILL_PRODUCT = 'no_fulfill_product',
  MULTI_DESIGN = 'multi_design',
  WARNING = 'warning'
}

export const FulfillStatusIssueValues = [
  FulfillStatusIssue.NONE,
  FulfillStatusIssue.NO_LINE_ITEM,
  FulfillStatusIssue.MULTI_FULFILL_PRODUCT,
  FulfillStatusIssue.NO_FULFILL_PRODUCT,
  FulfillStatusIssue.MULTI_DESIGN,
  FulfillStatusIssue.WARNING
]

export const FulfillStatusIssueName: Record<string, string> = {
  [FulfillStatusIssue.NONE]: t(Labels.ok),
  [FulfillStatusIssue.NO_LINE_ITEM]: t(Labels.no_line_items),
  [FulfillStatusIssue.MULTI_FULFILL_PRODUCT]: t(Labels.multi_fulfill_product),
  [FulfillStatusIssue.NO_FULFILL_PRODUCT]: t(Labels.no_fulfill_product),
  [FulfillStatusIssue.MULTI_DESIGN]: t(Labels.multi_design_same_sku),
  [FulfillStatusIssue.WARNING]: t(Labels.warning)
}

export const FulfillStatusIssueColor: Record<string, string> = {
  [FulfillStatusIssue.NONE]: 'success',
  [FulfillStatusIssue.NO_LINE_ITEM]: 'danger',
  [FulfillStatusIssue.MULTI_FULFILL_PRODUCT]: 'warning',
  [FulfillStatusIssue.NO_FULFILL_PRODUCT]: 'danger',
  [FulfillStatusIssue.MULTI_DESIGN]: 'danger',
  [FulfillStatusIssue.WARNING]: 'warning',
}




