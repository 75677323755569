import { Labels } from "common/labels"
import { t } from "core/translations"

export enum ReportsStatus {
  PENDING = "pending",
  PROCESSING = "processing",
  SUCCESS = "success",
  FAILED = "failed",
  CANCELLED= "cancelled",
  CANCELLING= "cancelling"
}

export const reportsStatusValues = [
  ReportsStatus.PENDING,
  ReportsStatus.PROCESSING,
  ReportsStatus.SUCCESS,
  ReportsStatus.FAILED,
  ReportsStatus.CANCELLING,
  ReportsStatus.CANCELLED
]

export const reportsStatusName: Record<string, string> = {
  [ReportsStatus.PENDING]: t(Labels.pending_process),
  [ReportsStatus.PROCESSING]: t(Labels.processing),
  [ReportsStatus.SUCCESS]: t(Labels.success),
  [ReportsStatus.FAILED]: t(Labels.failed),
  [ReportsStatus.CANCELLING]: t(Labels.cancelling),
  [ReportsStatus.CANCELLED]: t(Labels.cancelled),
}

export const reportsStatusColor: Record<any, string> = {
  [ReportsStatus.PENDING]: 'primary',
  [ReportsStatus.PROCESSING]: 'primary',
  [ReportsStatus.SUCCESS]: 'success',
  [ReportsStatus.FAILED]: 'danger',
  [ReportsStatus.CANCELLED]: 'danger',
  [ReportsStatus.CANCELLING]: 'warn',
}
