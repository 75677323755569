import { omit } from "lodash";
import FileSaver from "file-saver";
import { Toasts } from "../core/notification/notifications";
import { t } from "../core/translations";
import { Messages } from "common/messages";
import { format } from "date-fns";
import { HandleFileResultType } from "../pages/fulfillments/components/import-modal";
import { Logger } from "../core/logger";
import { Strings } from "./strings";
import { Storages } from "./storages";

export interface MimeType {
  type: string
  ext: string
}

export const MimeTypes = {
  zip: { type: 'application/octet-stream', ext: '.zip' },
  xlsx: {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ext: '.xlsx'
  },
  csv: { type: 'text/csv', ext: '.csv' }
}
const mimeTypeValues = Object.values(MimeTypes)

export class AdminUtils {
  static defaultProductVariantOptions = [
    {
      optionKeys: ['pa_style', 'style'],
      name: 'Style'
    },
    {
      name: 'Size',
      optionKeys: ['pa_size', 'size', '_billing_size']
    }
  ]

  static getMimeType(ext: string) {
    if (ext) {
      const extLower = ext.toLowerCase()
      return mimeTypeValues.find(mimeType => mimeType.ext.toLowerCase() == extLower)
    }
  }

  static getSavingFileSubName() {
    return `${format(new Date(), "yyyy-MM-dd")}_${Date.now()}`
  }

  static getFulfillSkuBody(sku: string) {
    return sku?.match(/^C+[^0-9]+[-]+/) ? sku.substring(sku.indexOf('-') + 1) : sku
  }

  static createDuplicateData<T>(data: T) {
    return omit(data || {}, "id", "createdAt", "updatedAt", "deletedAt", 'status', 'designStatus', 'statusIssue') as T
  }

  static startAutoDownloadOutputFile(rawData: any, defaultFileName: string = 'output_file', mimeType: MimeType = MimeTypes.xlsx) {

    try {
      const data = rawData?.data || rawData
      if(!data) throw new Error('Empty data')

      mimeType = AdminUtils.getMimeType(rawData?.ext) || mimeType 
      const fileName = rawData.fileName || `${defaultFileName}_${AdminUtils.getSavingFileSubName()}${mimeType.ext}`
      const checkChildNumberArr = Array.isArray(data) && data?.every((item: any) => typeof item !== 'number')

      if (checkChildNumberArr) {
        let count = 1
        for (let bufferData of data) {
          this.saveFile(bufferData, Strings.appendFileName(fileName, `_${count++}`))
        }
      } else {
        this.saveFile(data, fileName)
      }
      return true
    } catch (e) {
      Logger.warn(t(Messages.start_download_file_unsuccessfully) + `: ${e}`)
    }
  }

  static handleImportResponse(resData: any, fileNamePrefix: string, showDefaultError = true) {
    let ext = resData?.data?.ext || resData?.ext
    let importResult: HandleFileResultType = { rawData: resData, fileName: fileNamePrefix }
    const disableAsyncImport = Storages.getItem('disableAsyncImport')
    if(disableAsyncImport){
      try {
        const success =this.startAutoDownloadOutputFile(resData, importResult.fileName, this.getMimeType(ext))
      if(success) {
        Toasts.success(t(Messages.import_successfully));
        importResult = { ...importResult, stats: resData.stats, success: true }
      }
    } catch (e) {
      importResult = { ...importResult, success: false, message: resData?.message || resData?.error?.message || t(Messages.handle_import_response_data_failed)}
    }

    if(!importResult.success && showDefaultError) {
      Toasts.error(importResult.message || importResult.rawData?.error?.message || Messages.import_unsuccessfully)
    }
  }

    return importResult
  }

  static saveFile(bufferData: any, fileName: string, mimeType: MimeType = MimeTypes.xlsx) {
    if(typeof bufferData == 'string') {
      FileSaver.saveAs(new Blob([bufferData], { type: mimeType.type }), `${fileName}`);
    } else {
      let blobData = Buffer.from(bufferData.data || bufferData).buffer
      const file = new Blob([blobData], { type: mimeType.type });
      FileSaver.saveAs(file, `${fileName}`);
    }
  }
}