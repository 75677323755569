import { SourceServices } from "../services/source.service";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { atom, atomFamily, selector } from "recoil";
import { QueryParams } from "core/rest-api/query-builder";

export const sourceQueryState = atomFamily<QueryParams<any>, any>({
  key: "sourceQueryState",
  default: (params: any) => {
    return { ...params }
  },
})

export const sourceSelector = selector({
  key: "sourceSelect",
  get: async ({ get }) => {
    let query = get(sourceQueryState(defaultPageInfo))
    let pageData = await SourceServices.query(query, {
      snoozeErrorMessage: true,
    })
    return pageData
  },
})


