import { Labels } from "common/labels"
import RemoteTable, { defaultPageInfo } from "components/table/RemoteTable"
import { SearchOption } from "components/table/TableFilter"
import { t } from "core/translations"
import {  baseCostQueryState, baseCostSelector } from "data/atoms/fulfillment-invoice.atom"
import { format } from "date-fns"
import { createContext, Fragment, useContext, useEffect, useState } from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import DataGrid, { Column, CopyEvent, FillEvent, PasteEvent, RowsChangeData } from "react-data-grid";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next"
import { Route, BrowserRouter as Router, Link } from "react-router-dom"
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Nav, NavItem, NavLink, Row } from "reactstrap"
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilStateLoadable, useRecoilValueLoadable, useSetRecoilState } from "recoil"
import { RouteNames } from "routes"
import AuthMiddleware from "routes/route"
import SimpleBar from "simplebar-react"
import FulfillmentInvoicesPage from "../fulfillments/fulfillment-invoices.page"
import { ROW_HEIGHT } from "components/bulk/BulkEditor"
import Breadcrumb from "components/layout/Breadcrumb"
import { WithPermission } from "components/common/WithPermission"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { portableModalState } from "data/atoms/app.atom"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import ActionButton from "components/input/ActionButton"
import { Loading } from "components/common/Loading"
import { isEmpty, isNumber, omit } from "lodash"
import { OverviewPages, totalStats } from "./analytics-overview"
import moment from 'moment';
import React from "react"

export const BaseCostContext = createContext<any>(null)

const BaseCostPages = () => {
  const [valueDate, setValueDate] = useState<Record<any, any>>({ start: moment().startOf("month"), end: moment().endOf("month"), label: Labels.this_month });
  // vendor
  const paramVendor = { data: { ...(omit(valueDate, 'label')), ...{ type: "amount_by_vendor" } } }
  const dataLoadableVendor = useRecoilValueLoadable(baseCostSelector(paramVendor))
  const [dataVendor, setDataVendor] = useState<any[]>()
  const [totalStatsVendor, setTotalStatsVendor] = useState<totalStats>()

  useEffect(() => {
    if (dataLoadableVendor.state == "hasValue") {
      setDataVendor(dataLoadableVendor.contents?.analyticsData)
      setTotalStatsVendor(dataLoadableVendor.contents?.totalStats)
    }
  }, [dataLoadableVendor.contents])

  // fulfill service
  const param = { data: { ...(omit(valueDate, 'label')), ...{ type: "amount_by_fulfill_service" } } }
  const dataLoadableFulfill = useRecoilValueLoadable(baseCostSelector(param))
  const [dataFulfillService, setDataFulfillService] = useState<any[]>()
  const [totalStats, setTotalStats] = useState<totalStats>()

  useEffect(() => {
    if (dataLoadableFulfill.state == "hasValue") {
      setDataFulfillService(dataLoadableFulfill.contents?.analyticsData)
      setTotalStats(dataLoadableFulfill.contents?.totalStats)
    }
  }, [dataLoadableFulfill.contents])

  // TODO remove fakeRefreshDataLoadableFulfill
  const fakeRefreshDataLoadableFulfill = useRecoilRefresher_UNSTABLE(baseCostSelector(param))
  // TODO remove fakeRefreshDataLoadableVendor
  const fakeRefreshDataLoadableVendor = useRecoilRefresher_UNSTABLE(baseCostSelector(paramVendor))
  useEffect(() => {
    ;(((dataLoadableVendor.state == "hasValue" || dataLoadableVendor.state == "hasError") && !isEmpty(dataLoadableVendor.contents)) ||
      !dataLoadableVendor.contents) &&
      fakeRefreshDataLoadableVendor()

    ;(((dataLoadableFulfill.state == "hasValue" || dataLoadableFulfill.state == "hasError") && !isEmpty(dataLoadableFulfill.contents)) ||
      !dataLoadableFulfill.contents) &&
      fakeRefreshDataLoadableFulfill()
  }, [valueDate])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={t(Labels.base_cost)}>
            <Row>
              <Col className="p-0 d-flex align-items-center">
                <p className="mb-0 font-size-16">{valueDate.label}</p>
              </Col>
              <Col>
              
              <DateRangePicker
                initialSettings={{
                  startDate: valueDate.start?.toDate() || new Date(0),
                  endDate: valueDate.end?.toDate(),
                  locale: { format: "DD-MM-YYYY" },
                  ranges: {
                    Today: [moment().toDate(), moment().toDate()],
                    Yesterday: [moment().subtract(1, "days").toDate(), moment().subtract(1, "days").toDate()],
                    "Last 7 Days": [moment().subtract(6, "days").toDate(), moment().toDate()],
                    "Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
                    "This Month": [moment().startOf("month").toDate(), moment().endOf("month").toDate()],
                    "Last Month": [
                      moment().subtract(1, "month").startOf("month").toDate(),
                      moment().subtract(1, "month").endOf("month").toDate(),
                    ],
                    "This Year": [moment().startOf("year").toDate(), moment().endOf("year").toDate()],
                    "Last Year": [moment().subtract(1, "year").startOf("year").toDate(), moment().subtract(1, "year").endOf("year").toDate()],
                    "All Time": [moment(new Date(0)).toDate(), moment().toDate()],
                  },
                  alwaysShowCalendars: true,
                }}
                onApply={(e: any, p: any) => {
                  setValueDate({ start: p.startDate, end: p.endDate, label: p.chosenLabel})
                }}
              >
                <input type="text" className="form-control" style={{ width: "auto", minWidth: 190 }} />
              </DateRangePicker>
              </Col>
            </Row>
          </Breadcrumb>
          {dataLoadableVendor.state === "loading" || dataLoadableFulfill.state === "loading" ? (
            <Loading />
          ) : (
            <>
              <Row>
                <Col>
                  <OverviewComponent totalStats={totalStatsVendor} title={Labels.according_seller}/>
                </Col>
                <Col>
                  <OverviewComponent totalStats={totalStats} title={Labels.according_producer}/>
                </Col>
              </Row>
              <VendorComponent dataVendor={dataVendor} />
              <FulfillServiceComponent dataFulfillService={dataFulfillService} />
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BaseCostPages



const VendorComponent = (props: { dataVendor?: any[] }) => {
  const {  dataVendor } = props

  let columns: ColumnDescription[] = [
    { 
      dataField: "vendor",
      text: t(Labels.seller),
      headerStyle: { minWidth: 230 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <div className="normalLineHeight d-flex flex-column justify-content-center h-100">
            {
              cell?.name || cell?.username ? <span className="fw-bold">{cell?.name || cell?.username}</span> : <span className="text-lowercase">{Labels.unknown}</span>
            }
          </div>
        )
      },
    },
    {
      dataField: "pending",
      text: t(Labels.not_yet_pay),
      headerStyle: { width: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <span>{isNumber(cell) ? `${roundNumber(cell)}$` : ""}</span>
          </>
        )
      },
    },
    {
      dataField: "completed",
      text: t(Labels.paid),
      headerStyle: { width: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <span>{isNumber(cell) ? `${roundNumber(cell)}$` : ""}</span>
          </>
        )
      },
    },
    {
      dataField: "subtotal",
      text: t(Labels.total_money),
      headerStyle: { width: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <span>{isNumber(cell) ? `${roundNumber(cell)}$` : ""}</span>
          </>
        )
      },
      
    },
    {
      dataField: "cancelled",
      text: t(Labels.broke),
      headerStyle: { width: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <span>{isNumber(cell) ? `${roundNumber(cell)}$` : ""}</span>
          </>
        )
      },
    }
  ]

  return (
    <Fragment>
      <div>
        <Container fluid className="px-0">
            <Card>
              <CardBody>
                <div className="mb-4 h4 card-title">{Labels.according_seller}</div>
                <BootstrapTable
                  hover={true}
                  remote={true}
                  data={dataVendor || []}
                  columns={columns}
                  keyField={"vendorId"}
                  rowStyle={{ height: 60 }}
                  wrapperClasses={"table-responsive  mh-auto"}
                  headerWrapperClasses={"table-light"}
                  noDataIndication={() => <p className="text-danger">{t(Labels.no_data_available)}</p>}
                 />
              </CardBody>
            </Card>
        </Container>
      </div>
    </Fragment>
  )
}


const FulfillServiceComponent = (props: { dataFulfillService?: any[] }) => {
  const {  dataFulfillService } = props
  
  let columns: ColumnDescription[] = [
    { 
      dataField: "vendor",
      text: t(Labels.propduct_name),
      headerStyle: { minWidth: 230 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <div className="normalLineHeight d-flex flex-column justify-content-center h-100">
            <span className="fw-bold">{row.fulfillService?.name || row.fulfillService?.username}</span>
          </div>
        )
      },
    },
    {
      dataField: "pending",
      text: t(Labels.not_yet_pay),
      headerStyle: { width: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <span>{isNumber(cell) ? `${roundNumber(cell)}$` : ""}</span>
          </>
        )
      },
    },
    {
      dataField: "completed",
      text: t(Labels.paid),
      headerStyle: { width: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <span>{isNumber(cell) ? `${roundNumber(cell)}$` : ""}</span>
          </>
        )
      },
    },
    {
      dataField: "subtotal",
      text: t(Labels.total_money),
      headerStyle: { width: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <span>{isNumber(cell) ? `${roundNumber(cell)}$` : ""}</span>
          </>
        )
      },
      
    },
    {
      dataField: "cancelled",
      text: t(Labels.broke),
      headerStyle: { width: 180 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <span>{isNumber(cell) ? `${roundNumber(cell)}$` : ""}</span>
          </>
        )
      },
    }
  ]



  return (
    <Fragment>
      <div>
        <Container fluid className="px-0">
            <Card>
              <CardBody>
                <div className="mb-4 h4 card-title">{Labels.according_producer}</div>
                <BootstrapTable
                  hover={true}
                  remote={true}
                  data={dataFulfillService || []}
                  columns={columns}
                  keyField={"fulfillServiceId"}
                  rowStyle={{ height: 60 }}
                  wrapperClasses={"table-responsive  mh-auto mh-auto"}
                  headerWrapperClasses={"table-light"}
                  noDataIndication={() => <p className="text-danger">{t(Labels.no_data_available)}</p>}
                />
              </CardBody>
            </Card>
        </Container>
      </div>
    </Fragment>
  )
}



interface propsOverviewComponent {
  totalStats?: totalStats
  title?: string
}

const roundNumber = (number?: number) => number?.toLocaleString() || 0

export const OverviewComponent = (props: propsOverviewComponent) => {
  const { totalStats, title } = props

  return (
    <Card>
      <CardBody>
        <div className="mb-3 h4 card-title">{title}</div>
        <Col className="d-flex w-100 justify-content-between">
          <div className=" ">
            <p className="text-center text-muted fw-medium mb-2">{Labels.settlemented}</p>
            <h5 className="text-center">{roundNumber(totalStats?.completed)}$</h5>
          </div>
          <div className="">
            <p className="text-center text-muted fw-medium mb-2">{Labels.pending_payment}</p>
            <h5 className="text-center">{roundNumber(totalStats?.pending)}$</h5>
          </div>
          <div className="">
            <p className="text-center text-muted fw-medium mb-2">{Labels.broke}</p>
            <h5 className="text-center">{roundNumber(totalStats?.cancelled)}$</h5>
          </div>
          <div className=" ">
            <p className="text-center text-muted fw-medium mb-2">{Labels.total_revenue_title}</p>
            <h5 className="text-center">{roundNumber(totalStats?.subtotal)}$</h5>
          </div>
        </Col>
      </CardBody>
    </Card>
  )
}
