import { ResourceEntities } from './../types/permission-type';
import { Labels } from "common/labels";
import { t } from "core/translations";
import { isEmpty, isObject } from "lodash";

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

export const unflattenNestedObject = (rootItem: any = {}, item: any, nestedObjectKeys: string[]) => {
  let result = {} as any;
  let targetKeys = Object.keys(item)?.filter(key => key.includes("."));
  for (let nestedKey of nestedObjectKeys) {
    let nestedObj = { ...(rootItem[nestedKey] || {}) } as any;
    for (let targetKey of targetKeys) {
      let key = targetKey.replace(`${nestedKey}.`, "");
      nestedObj[key] = item[targetKey];
    }
    if (Object.keys(nestedObj).length > 0) {
      result[nestedKey] = nestedObj;
    }
  }

  return result;
};

export const flattenNestedObject = (item: any, nestedObjectKeys: string[]) => {
  let result = {} as any;
  for (let nestedKey of nestedObjectKeys) {
    let nestedObj = item[nestedKey];
    if (!nestedObj) continue;

    for (let key in nestedObj) {
      result[`${nestedKey}.${key}`] = nestedObj[key];
    }
  }

  return result;
};

export const omitFlattenNestedObject = (item: any, startWithKeys: string[], splitBy = ".") => {
  let checkKeys = startWithKeys?.length && splitBy ? startWithKeys.map((key: any) => key + ".") : startWithKeys || [];
  let omittedItem = {} as any;
  for (let key in item) {
    if (!checkKeys.some(startKey => key.startsWith(startKey))) {
      omittedItem[key] = item[key];
    }
  }
  return omittedItem;
};

export const isHtml = (str: string) => {
  let doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

export const convertToReactElement = (value: any) => {
  if(isHtml(value)) {
    const reactElement = htmlToReactParser.parse(value);
    return reactElement
  } else if(isObject(value)) {
    return JSON.stringify(value)
  }

  return value
}

export const delay = (value: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(null)
    },value)
  })
}

export const toUint8Array = (data: any) => {
  let bytes = new Uint8Array(data.length);
  for (let i = 0; i < data.length; i++) {
    bytes[i] = data[i];
  }

  return bytes
}

export interface PreprocessPageType {
  params: any
  history: any
  pageName: string
  model?: any
  primaryProperty?: string
}

export function preprocessPage(props: PreprocessPageType) {
  const { params, history, pageName, model, primaryProperty } = props
  if(params.id != "create" && !(+params.id)){
    history.replace(`${history.location.pathname}/404`)
  } else {
    setDocumentTitle(pageName, model, !(+params.id), primaryProperty)
  }
}

export const setDocumentTitle = (pageName: string, model: any = {}, isCreate?: boolean, primaryProperty?: string) => {
  if (isCreate) {
    document.title = t(pageName) + ' - ' + t(Labels.create)
  } else {
    if (isEmpty(model)) {
      document.title = pageName
    } else {
      let suffix = [primaryProperty && model[primaryProperty], model.name, model.title, model.number, model.id, model.username].find(str => !!str)
      document.title = createDocumentPageName(`${pageName}${suffix ? ' - ' : ''}${suffix || ''}`)
    }
  }
}

export const createDocumentPageName = (pageName: string) => {
  return `${pageName || ''} | ${Labels.app_name}`
}

export const debounce = (fn: Function, ms: number, ...params: any) => {
  console.log('params', params)
  let timer: any;
  return () => {
    if(timer) clearTimeout(timer);
    timer = setTimeout(() => fn(...params), ms)
  }
}

export const cartesian = (...a: Array<any>[]) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));

export const getName = (user?: Record<string, string>) => user?.name || user?.username || ""

export const getIdAndName = (user?: Record<string, string>) => user ? `${user.id} - ${user.name || user.username || ''}` : ""