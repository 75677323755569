import { QueryParams } from "core/rest-api/query-builder";
import { FulfillVariantMapperServices } from "data/services/fulfill-variant-mapper.service";
import { atomFamily, selector, selectorFamily } from "recoil";
import { defaultPageInfo } from "../../components/table/RemoteTable";

export const fulfillVariantMapperQueryState = atomFamily<QueryParams<any>, any>({
  key: 'fulfillVariantMapperQueryState',
  default: (params: any) => {
    return {...params}
  }
})

export const fulfillVariantMapperSelector = selector({
  key: 'fulfillVariantMapperSelector',
  get: async ({ get }) => {
    let query = get(fulfillVariantMapperQueryState(defaultPageInfo))
    let pageData = await FulfillVariantMapperServices.query(query, { snoozeErrorMessage: true })
    return pageData;
  },
});

export const fulfillVariantMapperIdState = atomFamily<QueryParams<any>, any>({
  key: "fulfillVariantMapperIdState",
  default: (id: any) => {
    return id;
  }
});

export const fulfillVariantMapperByIdSelector = selectorFamily({
  key: "fulfillVariantMapperByIdSelector",
  get: (id: any) => async ({ get }) => {
    let variationId = get(fulfillVariantMapperIdState(id));

    if (+variationId > 0) {
      let data = await FulfillVariantMapperServices.getById(+variationId, { snoozeErrorMessage: false });
      return data;
    }

    return {};
  }
});
