import { Labels } from "common/labels";
import { t } from "core/translations";
import { ProductTemplateDto, ProductVariantDto, ProductVariantOption } from "data/services/product-template.service";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { Button, Col, InputGroup, InputGroupText, Row, Input } from "reactstrap";
import { ProductTemplateChildrenFormProps } from "../product-template.page";
import DataGrid, { Column, CopyEvent, EditorProps, FillEvent, PasteEvent, RowsChangeData, TextEditor } from "react-data-grid";
import { MdDelete, MdEdit } from "react-icons/md";
import { SelectEditor } from "../artwork-bulk-create.page";
import { Forms } from "utils/forms";
import { FormValidation } from "components/form/FormikForm";
import { Link } from "react-router-dom";
import { TooltipComponent } from "components/common/TooltipComponent";
import { BiHistory } from "react-icons/bi";
import { cartesian } from "utils/utils";
import { Global } from "common/global";

export interface ProductTemplateVariantFormProps {
  data?: ProductTemplateDto;
  id: string | number;
  validation: FormValidation;
  variantsTable: any
  originalVariants: any
  setOriginalVariants: Function
}

export const ProductTemplateVariantForm = (props: ProductTemplateVariantFormProps) => {
  const { validation, data, id, variantsTable, originalVariants, setOriginalVariants } = props
  const [variants, setVariants] = useState<ProductVariantDto[]>([])
  const [countAddVariant, setCountAddVariant] = useState(1)
  const [listTitleVariant, setListTitleVariant] = useState<any>([])
  const [listOptionsName, setListOptionsName] = useState<any>()
  const isEditing = +id
  const enableAutoVariation = Global?.settings?.enableProductTemplateAutoVariation

  const onRowChangeData = (newValue: any, onRowChange: Function, commitChanges?: boolean) => {
    if (isEditing) {
      onRowChange({...newValue, _bulkUpdated: true}, commitChanges)
    } else {
      onRowChange(newValue, commitChanges)
    }
  }

  const columnDataGrid: Column<any, any>[] = useMemo(() => [
    {
      key: "variantTitle",
      name: t(Labels.title),
      minWidth: 400,
      // eslint-disable-next-line react/display-name
      formatter: ({ row }: any = {}) => <div>
        {row?.title ? row.title : <p style={{ opacity: 0.7 }}>{t(Labels.title_hint)}</p>}
      </div>,
      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: any = {}) => {
        let variantsTitleExisted = validation.values?.variants?.map((variant: any) => variant?.title)
        return (
          <>
            <SelectEditor
              embedHeight={45}
              openMenuOnFocus={true}
              menuPortalTarget={document.body}
              options={Forms.createSelectOptions(listTitleVariant?.filter((variant: any) => !variantsTitleExisted?.includes(variant)))}
              getOptionLabel={(option: any) => option?.value || option}
              getOptionValue={(option: any) => option?.value || option}
              value={() => ({label: row?.title, value: row?.title})}
              onChange={(value: any) => {
                let newValue = {
                  ...row,
                  title: value.value,
                  options: value.value?.split(' / ')?.map((str: string, index: number) => ({
                    name: listOptionsName[index],
                    value: str
                  }))
                }
                onRowChangeData(newValue, onRowChange, true)
              }}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            />
          </>
        )
      }
    },
    {
      key: "variantPrice",
      name: t(Labels.price),
      width: 100,
      // eslint-disable-next-line react/display-name
      formatter: ({ row, onRowChange }: any = {}) => <div>
        {row?.price?.toString() ? row?.price?.toString() : <p style={{ opacity: 0.7 }}>{t(Labels.price_hint)}</p>}
      </div>,
      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: any = {}) => {
        return (
          <input 
            type="number" 
            value={row?.price ? row.price : undefined}
            className={'p-0 m-0 ps-2 border-0 w-100'} 
            onChange={(value: any) => {
              onRowChangeData({...row, price: +value.target.value}, onRowChange)
            }}
            autoFocus={true}
          />
        )
      }
    },
    {
      key: "variantSku",
      name: t(Labels.sku),
      width: 220,
      // eslint-disable-next-line react/display-name
      formatter: ({ row }: any = {}) => <div>
        {row?.sku ? row.sku : <p style={{ opacity: 0.7 }}>{t(Labels.sku_hint)}</p>}
      </div>,
      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: any = {}) => {
        return (
          <input 
            type="text" 
            value={row?.sku ? row.sku : undefined} 
            className={'p-0 m-0 ps-2 border-0 w-100'} 
            onChange={(value: any) => {
              onRowChangeData({...row, sku: value.target.value}, onRowChange)
            }}
            autoFocus={true}
          />
        )
      }
    },
    {
      key: "action",
      name: '',
      width: 30,
      // eslint-disable-next-line react/display-name
      formatter: ({ row, onRowChange }: any = {}) => {
        return (
          <div className="d-flex flex-wrap align-content-center justify-content-center gap-2">
            <a onClick={() => removeRow(row.idx)} >
              <MdDelete size={18} color={'#ef2f2f'} />
            </a>
            {
              !row["_bulkUpdated"] ? null :
              <div>
                <Link
                  to={"#"}
                  onClick={() => onRowChange({ ...row, __changedKeys: ["__bulkResetRowChange"] }, true)}
                >
                  <TooltipComponent tooltip={t(Labels.reset_change)}>
                    <BiHistory size={22} className="me-2" />
                  </TooltipComponent>
                </Link>
              </div>
            }
          </div>
        )
      },
    }
  ], [validation.values?.variants])

  const getListOptionsName = (options: ProductVariantOption[]) => {
    let listOptions = options?.filter((option: ProductVariantOption) => !isEmpty(option?.optionValues))
    let listOptionsName = listOptions?.map((option: ProductVariantOption) => option?.name)
    return listOptionsName
  }

  useEffect(() => setOriginalVariants(data?.variants), [data])

  const convertOptionsValues = (options: ProductVariantOption[]) => {
    let listOptions = options?.filter((option: ProductVariantOption) => !isEmpty(option?.optionValues))
    let listOptionsValues = listOptions?.map((option: ProductVariantOption) => option?.optionValues)
    let listOptionsName = getListOptionsName(options)
    let optionsValuesTransfer = (listOptionsValues && listOptionsValues?.length) ? cartesian(...listOptionsValues as any) : []
    let listVariants = optionsValuesTransfer?.map((value: any, i: number) => ({
      title: Array.isArray(value) ? value?.join(' / ') : value,
      sku: validation.values?.sku,
      price: validation.values?.maxPrice,
      options: Array.isArray(value) 
        ? value?.map((str: string, index: number) => ({
          name: listOptionsName[index],
          value: str
        }))
        : [{
          name: listOptionsName[0],
          value: value
        }]
    }))
    return listVariants
  }

  const createVariantsMapper = () => {
    let current = validation.values?.variants
    let newVariants = !current ? [{}] : [...current]
    return newVariants?.map((item: any, idx: number) => ({ ...item, idx }))
  }

  const removeRow = (idx: any) => {
    let variantsFiltered = validation.values?.variants?.filter((item: any, index: number) => index !== idx)
    variantsFiltered = isEmpty(variantsFiltered) ? [{}] : variantsFiltered
    validation.setFieldValue("variants", variantsFiltered)
  }

  const addFulfillVariant = () => {
    if(countAddVariant > 0){
      const newVariant = Array.from({length: countAddVariant}, (v, idx) => {
        return {}
      })
      validation.setFieldValue("variants", [...(validation.values?.variants || []), ...newVariant])
    }
  }

  useEffect(() => {
    let listVariants: any = []
    if (isEditing) {
      listVariants = validation.values?.variants
    } else {
      listVariants = convertOptionsValues(validation.values?.options)
    }

    setListOptionsName(getListOptionsName(validation.values?.options))
    setListTitleVariant(convertOptionsValues(validation.values?.options)?.map((variant: any) => variant?.title))

    if (enableAutoVariation) {
      validation.setFieldValue('variants', listVariants as ProductVariantDto[])
    }
  }, [validation.values?.options])

  useEffect(() => {
    setVariants(createVariantsMapper())
  }, [validation.values?.variants])

  useEffect(() => {
    if (isEditing) {
      updateRowData(variantsTable)
    }
  }, [variantsTable])

  const updateRowData = (variantsTable: any) => {
    let newRows: any = variantsTable
    newRows?.forEach((row: any, index: number) => {
      if (originalVariants && row?.title != originalVariants[index]?.title && index < originalVariants?.length) {
        newRows[index] = {
          ...newRows[index],
          _bulkUpdated: true
        }
      }
    })
    validation.setFieldValue("variants", newRows)
  }

  const onRowsChange = (rows: any, data: RowsChangeData<any>) => {
    let { indexes, column } = data;

    for (let index of indexes) {
      let rowData = rows[index];
      if (!rowData) continue;
      if (rowData.__changedKeys?.includes("__bulkResetRowChange")) {
        if (index < originalVariants?.length) {
          rows[index] = originalVariants[index]
        } else {
          rows.splice(index, 1)
        }
      }
    } 
    validation.setFieldValue("variants", rows)
  }

  const rowKeyGetter = (row: any = {}) => {
    return `${row?.title}-${row?.idx}`
  }

  return (
    <React.Fragment>
      <DataGrid 
        columns={columnDataGrid}
        rows={variants}
        rowKeyGetter={rowKeyGetter}
        onRowsChange={onRowsChange}
        rowHeight={45}
        className={"fill-grid rdg-dark rdg-light"}
        style={{ height: "100%", blockSize: "100%" }}
        rowClass={(row: any) => (row._bulkUpdated ? "react-data-grid-updated-row" : "react-data-grid-normal-row")}
      />
      <div className="mt-2">
        <InputGroup className="col-auto" style={{zIndex: 1}}>
          <Input
            name={"countAddVariants"}
            values={countAddVariant}
            min={1}
            defaultValue={1}
            type="number"
            onChange={event => setCountAddVariant(+event.target.value)}
            className="d-inline-block"
            style={{ maxWidth: 80 }}
          />
          <InputGroupText>
            {t(Labels.rows)}
          </InputGroupText>
          <Button className="ms-1 flex-1" color="primary" onClick={addFulfillVariant}>
            {t(Labels.add_rows)}
          </Button>
        </InputGroup>
      </div>
    </React.Fragment>
  )
}
