import { Labels } from "common/labels";
import { t } from "core/translations";
import { StatusBaseModel } from "../data/actions/fulfillment-order.action";

export enum FulfillmentOrderStatus {
  NEW = 'new', // Chờ xử lý
  DECLINED = 'declined', // Request không hợp lệ, đang thay đổi thông tin
  ACCEPTED = 'accepted', // Request đang chờ gửi
  SUBMITTING = 'submitting', // Request đã export, đang gửi
  SUBMITTED = 'submitted', // Request đã được gửi đi
  FAILED = 'failed', // Request không được được chấp nhận fulfill
  COMPLETED = 'completed', // Request được được chấp nhận fulfill
  CANCELLED = 'cancelled', // Request đã hủy
}

export const checkRequireUpdateFulfillmentOrderReason = (selectedItems: StatusBaseModel[], status: FulfillmentOrderStatus) => {
  const fromStatuses = [...new Set(selectedItems.map(item => item.status))].filter(fromStatus => fromStatus != status)
  return fromStatuses.length && fromStatuses.some(fromStatus => {
    return !noneRequireUpdateReasonStatuses[fromStatus] || noneRequireUpdateReasonStatuses[fromStatus] != status
  })
}

const noneRequireUpdateReasonStatuses: Record<any, FulfillmentOrderStatus> = {
  [FulfillmentOrderStatus.NEW] : FulfillmentOrderStatus.ACCEPTED,
  [FulfillmentOrderStatus.ACCEPTED] : FulfillmentOrderStatus.SUBMITTING
}

export const fulfillmentOrderStatusValues = [
  FulfillmentOrderStatus.NEW,
  FulfillmentOrderStatus.ACCEPTED,
  FulfillmentOrderStatus.DECLINED,
  FulfillmentOrderStatus.SUBMITTING,
  FulfillmentOrderStatus.SUBMITTED,
  FulfillmentOrderStatus.COMPLETED,
  FulfillmentOrderStatus.FAILED,
  FulfillmentOrderStatus.CANCELLED
]

export const FulfillmentOrderNames: Record<FulfillmentOrderStatus, string> = {
  [FulfillmentOrderStatus.NEW]: t(Labels.new),
  [FulfillmentOrderStatus.ACCEPTED]: t(Labels.accepted),
  [FulfillmentOrderStatus.DECLINED]: t(Labels.declined),
  [FulfillmentOrderStatus.SUBMITTING]: t(Labels.submitting),
  [FulfillmentOrderStatus.SUBMITTED]: t(Labels.submitted),
  [FulfillmentOrderStatus.COMPLETED]: t(Labels.completed),
  [FulfillmentOrderStatus.FAILED]: t(Labels.failed),
  [FulfillmentOrderStatus.CANCELLED]: t(Labels.cancelled),
}

export const FulfillmentOrderColor: Record<FulfillmentOrderStatus, string> = {
  [FulfillmentOrderStatus.NEW]: 'light',
  [FulfillmentOrderStatus.ACCEPTED]: 'primary',
  [FulfillmentOrderStatus.DECLINED]: 'danger',
  [FulfillmentOrderStatus.FAILED]: 'danger',
  [FulfillmentOrderStatus.SUBMITTING]: 'info',
  [FulfillmentOrderStatus.SUBMITTED]: 'warning',
  [FulfillmentOrderStatus.COMPLETED]: 'success',
  [FulfillmentOrderStatus.CANCELLED]: 'dark',
}

export enum FulfillmentDesignStatus {
  NULL = 'null',
  PENDING = 'pending',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
}


export const fulfillmentDesignStatusValues = [
  FulfillmentDesignStatus.NULL,
  FulfillmentDesignStatus.PENDING,
  FulfillmentDesignStatus.UPLOADING,
  FulfillmentDesignStatus.UPLOADED,
  FulfillmentDesignStatus.FAILED
]

export const FulfillmentDesignStatusNames: Record<string, string> = {
  [FulfillmentDesignStatus.NULL]: t(Labels.null),
  [FulfillmentDesignStatus.PENDING]: t(Labels.pending),
  [FulfillmentDesignStatus.UPLOADING]: t(Labels.uploading),
  [FulfillmentDesignStatus.UPLOADED]: t(Labels.uploaded),
  [FulfillmentDesignStatus.FAILED]: t(Labels.failed)
}


export const FulfillmentDesignColor: Record<string, string> = {
  [FulfillmentDesignStatus.NULL]: 'light',
  [FulfillmentDesignStatus.PENDING]: 'info',
  [FulfillmentDesignStatus.UPLOADING]: 'primary',
  [FulfillmentDesignStatus.UPLOADED]: 'success',
  [FulfillmentDesignStatus.FAILED]: 'danger'
}