import React from 'react';
import { RouteComponentProps } from "react-router-dom";

const NonAuthLayout = (props: NonAuthLayoutProp) => {
  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
}

interface NonAuthLayoutProp extends RouteComponentProps {
  children: any
}

export default NonAuthLayout
