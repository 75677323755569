import { apiBaseUrls } from "../rest-api-config"
import { CrudService } from "./crud.service"
import { FulfillProductServices } from "./fulfill-product.service"
import {FulfillOrderApiPaths, FulfillOrderDto} from "./fulfill-order.service"
import {OrderActionEnum, OrderDto} from "./order.service"

export interface FulfillMentDto {
  id?: string
  fulfillOrderId?: string | number
  orderId?: string | number
  name?: string
  quantity?: number
  baseCost?: number
  totalBaseCost?: number
  note?: string
  shipmentStatus?: string // ShipmentStatus
  shippingAddress?: any // ShippingAddress
  trackingNumber?: string
  trackingUrl?: string
  notifyEmail?: boolean
  notifyCustomer?: boolean
  notifyContent?: string
  order?: {
    id?: string
    number?: string
    status?: string
    fulfillmentStatus?: string
  }
  fulfillOrder?: FulfillOrderDto
  fulfillOrders?: FulfillOrderDto[]
  carrierCode?: string
  carrierName?: string
}

export interface CreateFulfillmentDto {
  fulfillOrderId: string | number
  orderId: string | number
  name?: string
  quantity?: number
  baseCost?: number
  totalBaseCost?: number
  note?: string
  shipmentStatus: string // ShipmentStatus
  shippingAddress: any // ShippingAddress
  trackingNumber: string
  trackingUrl: string
  notifyEmail: boolean
  notifyCustomer: boolean
  notifyContent: string
}

export interface UpdateFulfillmentDto extends CreateFulfillmentDto {}
export interface FulfillmentDto extends CreateFulfillmentDto {
  fulfillOrder: FulfillOrderDto
  order: OrderDto
}

export const FulfillmentApiPaths = {
  BASE: "/fulfillment/fulfillments",
  WITH_ID: "/fulfillment/fulfillments/{id}",
  QUERY: "/fulfillment/fulfillments/query",
  BULK: "/fulfillment/fulfillments/bulk",
  COUNT_STATUS: "/fulfillment/fulfillments/count/status",
  ACTIONS: "/fulfillment/fulfillments/actions",
}

export enum FulfillmentActionEnum {
  UPDATE_FULFILLMENT_STATUS = 'update_fulfillment_status',
  UPLOAD_TRACKING_NUMBER_TO_CHANNEL = 'upload_tracking_number_to_channel',
}

export class FulfillmentService extends CrudService<
  any,
  CreateFulfillmentDto,
  UpdateFulfillmentDto
> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: FulfillmentApiPaths.WITH_ID,
        updateById: FulfillmentApiPaths.WITH_ID,
        deleteById: FulfillmentApiPaths.WITH_ID,
        query: FulfillmentApiPaths.QUERY,
        base: FulfillmentApiPaths.BASE,
        deleteMany: FulfillmentApiPaths.BULK,
        updateMany: FulfillmentApiPaths.BULK,
      },
    })
  }

  async search(searchValue: string) {
    let filter = searchValue ? { name: { contains: searchValue } } : {}
    let pageData = await this.query({ filter, page: 1 })
    return pageData?.pageItems || []
  }

  async getCountByStatus() {
    const response = await this.restApi.get(FulfillmentApiPaths.COUNT_STATUS, { snoozeErrorMessage: false })
    return response
    // return this.returnDataOrThrow(response)
  }

  async uploadTrackingNumberToChannel(ids: string[] | number[]) {
    let response = await this.restApi.post(FulfillmentApiPaths.ACTIONS, {
      data: {
        ids,
        action: FulfillmentActionEnum.UPLOAD_TRACKING_NUMBER_TO_CHANNEL,
      },
    })
    return this.returnDataOrThrow(response)
  }

  async updateFulfillmentStatus(ids: string[] | number[]) {
    let response = await this.restApi.post(FulfillmentApiPaths.ACTIONS, {
      data: {
        ids,
        action: FulfillmentActionEnum.UPDATE_FULFILLMENT_STATUS,
      },
    })
    return this.returnDataOrThrow(response)
  }
}

export const FulfillmentServices = new FulfillmentService()
