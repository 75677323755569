import React from "react"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css" // optional

interface TooltipTippyProps {
  children: any
  label?: string
  content?: React.ReactNode
}

export const TooltipTippy = (props: TooltipTippyProps) => {
  const { label, content } = props
  if (label || content) {
    return <Tippy content={content || <span className="font-size-12">{label}</span>}>{props.children}</Tippy>
  }

  return <>{props.children}</>
}
