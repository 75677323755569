import { isEmpty, omit } from "lodash";
import React, { useEffect, useState } from "react";
import { ROW_HEIGHT } from "../../components/bulk/BulkEditor";
import DataGrid, { Column, CopyEvent, FillEvent, PasteEvent, TextEditor } from "react-data-grid";
import { t } from "core/translations";
import { LabelProps } from "reactstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Labels } from "common/labels";

interface MetaTableProps extends LabelProps {
  json: Record<any, any> | Array<any>,
  readOnly?: boolean,
  onChange: (value: any) => void
}

const createMetadata = (json: Record<any, any> | Array<any>) => {
  return !json || Array.isArray(json) ? json || [] : Object.entries(json).map(([key, value]) => ({ key, value }))
}

export const MetaTable = (props: MetaTableProps) => {
  let { keyField = 'key', valueField = 'value', keyLabel = t(Labels.key), labelField, readOnly, json, onChange } = props

  const [bulkCreateData, setBulkCreateData] = useState<any[]>(createMetadata(json));
  const [gridRowData, setGridRowData] = useState<any>([]);

  useEffect(() => {
    setBulkCreateData(createMetadata(json))
  }, [json])

  useEffect(() => {
    let data = bulkCreateData.map((row: any, index: number) => ({ ...row, id: row.id || index + 1}))
    let lastRow = data[data.length - 1];
    if(!readOnly && (isEmpty(data) || !isEmpty(omit(lastRow, 'id')))) {
      data.push({ id: data.length + 1})
    }
    setGridRowData(data)
  }, [bulkCreateData])

  const removeRow = (id: number | string) => {
    setGridRowData((prev: any) => {
      let newData = prev.filter((item: any) => item.id !== id).map((item: any, idx: number) => ({...item, id: idx + 1}))
      setImmediate(() => onRowsChange(newData))
      return newData
    })
  }

  const labelColumn: any = labelField ? {
      key: labelField,
      name: t(Labels.name),
      editable: !readOnly,
      width: '25%',
      editor: TextEditor,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row[labelField] ? row[labelField] : <p style={{ opacity: 0.7 }}>{"Ex: Name"}</p>}
        </div>
      )
    } : null as any

  const columns: Column<any, any>[] = [
    {
      key: keyField,
      name: keyLabel,
      width: labelField ? '25%' : '40%',
      editable: !readOnly,
      editor: TextEditor,
      // eslint-disable-next-line react/display-name
      formatter: ({ row }: any = {}) => (
        <div>
          {row[keyField] ? row[keyField] : <p style={{ opacity: 0.7 }}>{`Ex: ${keyLabel}`}</p>}
        </div>
      )
    },
    ...(labelColumn ? [labelColumn] : []),
    {
      key: valueField,
      name: t(Labels.value),
      editable: !readOnly,
      editor: TextEditor,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => (
        <div>
          {row[valueField] ? row[valueField] : <p style={{ opacity: 0.7 }}>{"Ex: Value"}</p>}
        </div>
      ),
    },
    ...(readOnly ? [] : [
    {
      key: "",
      name: "",
      width: 40,
      minWidth: 40,
      // eslint-disable-next-line react/display-name
      formatter: ({ row } : any = {}) => {
        return <button onClick={() => removeRow(row.id)} className="w-100 border-0 bg-transparent h-100 d-flex align-items-center justify-content-center ">
          <i className="mdi mdi-delete font-size-18 danger text-danger" />
        </button>
      }
    }])
  ];

  function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<any>) {
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

  const handlePaste = ({
                         sourceColumnKey,
                         sourceRow,
                         targetColumnKey,
                         targetRow
                       }: PasteEvent<any>) => {
    const incompatibleColumns = ["id"];
    if (sourceColumnKey !== targetColumnKey
      && (incompatibleColumns.includes(targetColumnKey) || incompatibleColumns.includes(sourceColumnKey))) {
      return targetRow;
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
  };

  function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<any>): void {
    if (window.isSecureContext) {
      let value = sourceRow[sourceColumnKey];
      navigator.clipboard.writeText(value);
    }
  }

  function rowKeyGetter(row: any) {
    return `${row.id}-${row[keyField || '']}-${row[labelField] || ''}`;
  }

  const onRowsChange = (rows: any) => {
    setBulkCreateData(rows);

    let changedData = null
    if(Array.isArray(json)) {
      if(!json.length || json[0].id) {
        changedData = rows.map((row: any) => omit(row, 'id'))
      } else {
        changedData = rows
      }

      if (rows.length) {
        let lastRow = changedData[changedData.length - 1]
        if(isEmpty(omit(lastRow, 'id'))) changedData.pop()
      }

    } else {
      changedData = rows.reduce((prev: any, { key, value }: any) => {
        return key && value != undefined ? {...prev, [key] : value } : prev
      }, {})
    }

    onChange && onChange(changedData)
  };

  return (
    <DataGrid
      columns={columns}
      rows={gridRowData}
      rowKeyGetter={rowKeyGetter}
      onRowsChange={onRowsChange}
      onFill={handleFill}
      onCopy={handleCopy}
      onPaste={handlePaste}
      rowHeight={ROW_HEIGHT}
      defaultColumnOptions={{ resizable: true }}
      className={"fill-grid rdg-dark rdg-light"}
      style={{ height: "100%", blockSize: "100%" }}
      rowClass={(row: any) => (row._bulkUpdated ? "react-data-grid-updated-row" : "react-data-grid-normal-row")}
    />
  )
};
