import { RestApiService } from "core/rest-api/rest-api.service";
import { apiBaseUrls } from "../rest-api-config";
import { AccountServiceApiPath } from "./account.service";

export const IdentityApiPaths = {
  LOGIN: `/auth/login`,
  REGISTER: `/auth/register`,
  REFRESH_TOKEN: `/auth/refresh`,
  RESEND_VERIFICATION_TOKEN: `/auth/resend-verification`,
  REFRESH_VERIFICATION_TOKEN: `/auth/refresh-verification`,
  VERIFY_EMAIL: `/auth/verify`,
  BASE: "/users",
  WITH_ID: "/users/{id}",
  QUERY: "/users/query",
  BULK: "/users/bulk",
  GET_ROLES: "/roles",
  GET_TENANTS: "/tenants",
  GET_TEAMS: "/teams"
}

export class IdentityService extends RestApiService {
  constructor() {
    super({
      baseUrl: apiBaseUrls.identity,
      snoozeErrorMessage: true,
    })
  }

  async login(params: any) {
    return await this.restApi.post(IdentityApiPaths.LOGIN, { data: params })
  }

  async refresh() {
    return await this.restApi.get(IdentityApiPaths.REFRESH_TOKEN, { snoozeAllErrorMessage: true })
  }

  async register(params: any) {
    return await this.restApi.post(IdentityApiPaths.REGISTER, { data: params })
  }

  async resendVerification(params: any) {
    return await this.restApi.post(IdentityApiPaths.RESEND_VERIFICATION_TOKEN, {
      data: params,
    })
  }

  async refreshToken(params: any) {
    return await this.restApi.get(IdentityApiPaths.REFRESH_VERIFICATION_TOKEN, {
      data: params,
    })
  }

  async verify(params: any) {
    const response = await this.restApi.post(IdentityApiPaths.VERIFY_EMAIL, {
      data: params,
    })
  }

  async getRoleList() {
    let response = await this.restApi.get(IdentityApiPaths.GET_ROLES,{ snoozeErrorMessage: true })
    return this.returnDataOrThrow(response)
  }

  async getTenantList() {
    let response = await this.restApi.get(IdentityApiPaths.GET_TENANTS,{ snoozeErrorMessage: true })
    return this.returnDataOrThrow(response)
  }

  async getTeamList() {
    let response = await this.restApi.get(IdentityApiPaths.GET_TEAMS,{ snoozeErrorMessage: true })
    return this.returnDataOrThrow(response)
  }
}

export const IdentityServices = new IdentityService()
