import { Labels } from "common/labels"
import { WithPermission } from "components/common/WithPermission"
import RemoteTable, { createDeleteAction } from "components/table/RemoteTable";
import { ActionOption, RemoteTableSelectionData } from "components/table/SelectedRowAction"
import { FilterOption, SearchOption } from "components/table/TableFilter"
import { t } from "core/translations"
import { portableModalState } from "data/atoms/app.atom"
import { fulfillVariantMapperByIdSelector, fulfillVariantMapperQueryState, fulfillVariantMapperSelector } from "data/atoms/fulfill-variant-mapper.atom"
import React, { useEffect, useState } from "react"
import { ColumnDescription } from "react-bootstrap-table-next"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumb from "../../components/layout/Breadcrumb";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil"
import { RouteNames } from "routes"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { createDocumentPageName } from "utils/utils"
import { LoadableComponent } from "components/common/LoadableComponent"
import { fulfillVariantByIdSelector } from "data/atoms/fulfill-variation.atom"
import { isEmpty } from "lodash"
import { FormikForm } from "components/form/FormikForm"
import { FormikHandlers, FormikHelpers, useFormik } from "formik"
import { FulfillVariantMapperDto } from "data/services/fulfill-variant-mapper.service"
import { FormLayout, FormLayoutMain } from "components/layout/FormLayout"
import { FormikInput } from "components/form/FormikInput"
import TeamsSelect, { TeamDto } from "pages/settings/components/teams.select"
import FulfillVariantSelect from "./components/fulfill-variation.select"
import NewFulfillVariantSelect from "./components/fulfill-variation-new.select"
import TeamsDefaultValueSelect from "pages/settings/components/teams-default.select"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import { Forms } from "utils/forms"
import { FulfillVariantMapperActions } from "data/actions/fulfill-variant-mapper.action"
import { Logger } from "core/logger"
import { Toasts } from "core/notification/notifications"
import { Messages } from "common/messages"
import { fulfillVariantMapperSchema } from "./validations/fulfill-variant-mapper.validation"
import { FulfillVariantActions } from "../../data/actions/fulfill-variation.action";

export interface PortableDataTypes {
  id?: number,
  isOpen?: boolean
}

export const FulfillVariantMappersPage = () => {
  const [portableModal, setPortableModal] = useRecoilState(portableModalState)
  const refresh = useRecoilRefresher_UNSTABLE(fulfillVariantMapperSelector)

  useEffect(() => { document.title = createDocumentPageName(t(Labels.fulfill_variant_mapper)) }, [])

  useEffect(() => {
    if (!portableModal.open) { refresh() }
  }, [portableModal])

  const handleOpenDetail = (id: number) => {
    setPortableModal({
      open: true,
      title: t(Labels.fulfill_variant_mapper),
      size: 'lg',
      content: (
        <FulfillVariantMapperDetail id={id} />
      ),
    })
  }

  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { width: 50 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <span 
            className="fw-bold" 
            style={{color: '#4458b8', cursor: 'pointer'}}
            onClick={() => handleOpenDetail(row.id)}
          > 
            {`${row.id}`}
          </span>
        </>;
      }
    },
    {
      dataField: 'productVariation',
      text: t(Labels.product_variation),
    },
    {
      dataField: 'fulfillVariantTitle',
      text: t(Labels.fulfill_variant_title),
    },
    {
      dataField: 'fulfillService',
      text: t(Labels.fulfill_service),
      headerStyle: { width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <p>{`${row?.fulfillService?.name}`}</p>
      }
    },
    {
      dataField: 'team',
      text: t(Labels.team),
      headerStyle: { width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        // return <p>{`${row?.team?.name || row?.teamId}`}</p>
        return <p>{row?.team ? row?.team?.name : row?.teamId ? "" : t(Labels.all_team)}</p>
      }
    },
  ];

  let searchOptions: SearchOption[] = [
    {
      field: "productVariation",
      label: t(Labels.product_variation)
    },
    {
      field: 'fulfillVariantTitle',
      label: t(Labels.fulfill_variant_title),
    }
  ];

  let actionOptions: ActionOption[] = [
    createDeleteAction(FulfillVariantMapperActions, ResourceEntities.fulfillVariantMapperEntity)
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={t(Labels.fulfill_variant_mapper)}
          >
            <WithPermission resource={ResourceEntities.fulfillVariantEntity} action={ActionEntities.create}>
              <Button
                type="button"
                className="btn btn-success"
                onClick={() => handleOpenDetail(0)}
              >
                <i className="bx bx-plus font-size-16 align-middle"></i>
                {t(Labels.create)}
              </Button>
            </WithPermission>
          </Breadcrumb>
          <RemoteTable
            dataLoader={[fulfillVariantMapperSelector, fulfillVariantMapperQueryState]}
            searchOptions={searchOptions}
            // filterOptions={filterOptions}
            tabOptions={[]}
            actionOptions={actionOptions}
            // searchHint={t(Labels.search_by_variation)}
            // defaultSearchField={"variantTitle"}
            keyField={"id"}
            columns={columns}
            refresh={refresh}
            filterKey={'fulfillVariantMappers'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export interface FulfillVariantMapperDetailProps {
  id: number
}

export const FulfillVariantMapperDetail = (props: FulfillVariantMapperDetailProps) => {
  const { id } = props
  const dataLoadable = useRecoilValueLoadable(fulfillVariantMapperByIdSelector(id));
  const [fulfillVariantMapper, setFulfillVariantMapper] = useState<any>({});
  const isEditing = id ? true : false
  
  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(fulfillVariantMapperByIdSelector(id))
  
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[id])

  useEffect(() => {
    if (dataLoadable.state == "hasValue") {
      setFulfillVariantMapper((current: any) => ({
        ...current,
        ...(dataLoadable.contents || {})
      }));
    }
  }, [dataLoadable.state]);

  const validation = useFormik({
    initialValues: {...fulfillVariantMapper},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: fulfillVariantMapperSchema,
    onSubmit: (value, helpers) => handleSubmit(value, helpers)
  });

  const handleSubmit = async (values: FulfillVariantMapperDto, helpers: FormikHelpers<any>) => {
    let changedData = isEditing ? Forms.getChangedValues(values, fulfillVariantMapper) : values;
  
    try {
      helpers.setErrors({})
      helpers.setSubmitting(true)
      let savedData = await FulfillVariantMapperActions.save(id, changedData)

      if (savedData?.id) {
        setFulfillVariantMapper((current: any) => ({ ...current, ...savedData }))
        Toasts.success(t(Messages.save_fulfill_variant_mapper_successfully))
      }
    } catch (e) {
      helpers.setSubmitting(false)
      Logger.debug('error ', e)
    }
  }

  return (
    <>
      <ModalContent>
        <LoadableComponent loadable={dataLoadable}>
          <FormikForm validation={validation}>
            <FormLayout>
              <FormLayoutMain>
                <Row className="d-flex flex-column gap-2">
                  <Col>
                    <FormikInput
                      name="teamId"
                      label={t(Labels.team)}
                      validation={validation}
                      placeholder={t(Labels.team_hint)}
                      customInput={({ handleChange, handleBlur}) => (
                        <TeamsDefaultValueSelect
                          validation={validation}
                          teamId={validation.values?.teamId}
                          onChange={(value: TeamDto) => {validation.setFieldValue('teamId', value?.id)}}
                        />
                      )}
                    />
                  </Col>
                  <Col>
                    <FormikInput
                      name="productVariation"
                      label={t(Labels.product_variation)}
                      validation={validation}
                      placeholder={t(Labels.product_variation_hint)}
                    />
                  </Col>
                  <Col>
                    <FormikInput
                      name="fulfillVariant"
                      label={t(Labels.fulfill_variant)}
                      validation={validation}
                      placeholder={t(Labels.fulfill_variant_hint)}
                      customInput={({ handleChange, handleBlur}) => (
                        <NewFulfillVariantSelect
                          value={validation.values?.fulfillVariant}
                          validation={validation}
                          onChange={(value: any) => {
                            let newValue: FulfillVariantMapperDto = {
                              ...validation.values,
                              fulfillVariant: value,
                              fulfillVariantId: value?.id,
                              fulfillServiceId: value?.fulfillService?.id,
                              fulfillVariantName: value?.variantName,
                            }
                            validation.setValues(newValue)
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </FormLayoutMain>
            </FormLayout>
          </FormikForm>
        </LoadableComponent>
      </ModalContent>
      <ModalButtons
        confirmText={id ? t(Labels.save) : t(Labels.create)}
        confirm={() => validation.submitForm()}
        closeAfterConfirm={false}
      />
    </>
    
  )
}