import { Messages } from 'common/messages';
import * as Yup from "yup";
import { t } from "../../../core/translations";
import { Labels } from "../../../common/labels";
import { TestContext } from "yup/lib/util/createValidation";
import { isEmpty, uniq } from 'lodash';

export const fulfillServiceSchema = Yup.object({
  code: Yup.string().required(t(Labels.code_required)),
  name: Yup.string().required(t(Labels.name_required)),
  skuFormat: Yup.object({
    position: Yup.string().required(t(Labels.sku_position_required)),
    searchText: Yup.string().required(t(Labels.sku_search_text_required)),
  }),
  mapping: Yup.array().min(1, t(Messages.mapping_required))
    .of(
      Yup.object({
        key: Yup.string(),
        fromName: Yup.string(),
        exportColumn: Yup.string(),
      }).test("requireInput", (value: any, context: TestContext) => {
        if (!value?.key || !value?.fromName || !value?.exportColumn) {
          return context.createError({ message: t(Messages.input_mapping_item_required) })
        }
        return true
      })
    ).test('checkKeyDuplicate', (value: any, context: TestContext) => {
      // let lengthOfDuplicateElementRemoved = uniq(value?.map((item: any) => item?.key))?.length
      // if (lengthOfDuplicateElementRemoved != value?.length) {
      //   return context.createError({ message: t(Messages.mapping_duplicated) })
      // }
      return true
    }),
})