import * as React from "react";
import { createRef, Fragment, useEffect, useLayoutEffect, useState } from "react";
import BootstrapTable, {
  ColumnDescription,
  PageListRendererOptions,
  PaginationOptions,
  SelectRowProps,
  TableChangeState,
  TableChangeType
} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Card, CardBody, Col, Row } from "reactstrap";
import "../../assets/scss/custom/components/_remote-table.scss";
import ReactDOM from "react-dom";
import { t } from "../../core/translations";
import { Filter, QueryParams } from "core/rest-api/query-builder";
import TableFilter, { FilterOption, SearchOption, SortOption, TabOption } from "./TableFilter";
import { Labels } from "../../common/labels";
import {
  Loadable,
  RecoilState,
  RecoilValueReadOnly,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState
} from "recoil";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Messages } from "../../common/messages";
import { ActionOption, SelectedRowAction, RemoteTableSelectionData } from "./SelectedRowAction";
import { CrudActions } from "../../data/actions/crud.action";
import { Logger } from "../../core/logger";
import { useLocation } from "react-router-dom";
import { ActionEntities } from "types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import { remoteTableQuerySelector, RemoteTableQueryStateType, tableSelector } from "data/atoms/table.atom";
import { DEFAULT_SIZE_PER_PAGE } from "../../common/constants";
import { checkboxColumnType } from "./CustomizeColumns";
import { SelectedActionNestedDropdown } from "pages/fulfillments/components/SelectActionInvoice";

let isMobile = !!(window.screen.width <= 998)
const paginationTotalRenderer = (from: any, to: any, size: any) => isMobile ? (<></>) : 
  (<span className="ms-2 react-bootstrap-table-pagination-total">
    Showing {from}-{to} of {size}
  </span>)

const pageListRenderer =( {pages, onPageChange}: PageListRendererOptions ) => {
  return <div className="react-bootstrap-table-pagination-list">
    <ul className="pagination react-bootstrap-table-page-btns-ul">
      {
        pages.map(p => {
          if(p.active || (typeof p.page === 'string')) {
            return <li className={`page-item ${p.active ? 'active' : ''}`} onClick={() => onPageChange(p.page, p.page)} key={p.page}>
              <a href="#" className="page-link">{ p.page }</a>
              </li>
          }
        })
      }
    </ul>
  </div>
}


const defaultPaginationOptions: PaginationOptions = {
  page: 1,
  sizePerPage: DEFAULT_SIZE_PER_PAGE,
  totalSize: 0,
  showTotal: true,
  sizePerPageList: [DEFAULT_SIZE_PER_PAGE, 50, 100, 200],
  paginationTotalRenderer,
  // hideSizePerPage: isMobile,
  pageListRenderer: isMobile ? pageListRenderer : undefined
};

const defaultTableState: TableChangeState<any> = {
  data: [],
  filters: {},
  sortField: '',
  page: 1,
  sizePerPage: DEFAULT_SIZE_PER_PAGE,
  sortOrder: "desc",
  cellEdit: { dataField: undefined, newValue: undefined, rowId: "" }
};

export interface RemoteTableProps<T extends object = any, K = number> {
  dataLoadable?: [Loadable<any>] | [Loadable<any>, SetterOrUpdater<any>]
  dataLoader?: [RecoilValueReadOnly<RemotePageData<T>>, (param: QueryParams<T>) => RecoilState<QueryParams<T>>]
  sortOptions?: SortOption[]
  searchOptions?: SearchOption[]
  filterOptions?: FilterOption[]
  tabOptions?: TabOption[]
  actionOptions?: ActionOption[]
  searchHint?: string
  defaultSearchField?: string
  keyField: string
  paginationOptions?: false | PaginationOptions
  columns: ColumnDescription[]
  scrollOffset?: any,
  onDataUpdate?: (data: RemotePageData<T>) => void
  onSelectedChange?: (selectedIds: any[]) => void
  onTabChange?: (activeTab: any, columns: ColumnDescription[]) => ColumnDescription[]
  onTableChange?: any,
  editing?: boolean,
  selectRowOptions?: SelectRowProps<any> | boolean
  selectedRows?: number[]
  refreshSilent?: boolean
  renderInCard?: boolean
  tableClasses?: string
  simple?: boolean
  refreshInterval?: number
  refresh?: Function
  filterKey?: string
  filterByIds?: string | undefined
  updatedData?:  Record<string, any> /* {id: data id} */
}

export interface RemotePageData<T extends object = any> {
  pageItems: any[];
  pageInfo: OffsetPageInfo;
}

export interface OffsetPageInfo {
  page: number;
  limit: number;
  pageCount: number;
  totalCount: number;
  totalPages?: number;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  initial?: boolean // True nếu tự động load dữ liệu cho page mặc định.
}
//! can't push 'filter' key into defaultPageInfo
export const defaultPageInfo: OffsetPageInfo = {
  page: 1,
  limit: DEFAULT_SIZE_PER_PAGE,
  pageCount: 0,
  totalCount: 0
};

const defaultSelectRowOptions = {
  mode: "checkbox",
  clickToSelect: false
};

export const createDeleteAction = (service: CrudActions<any, any, any>, resource: string) => {
  return {
    name: "delete_selected",
    label: t(Labels.delete_selected_item),
    // eslint-disable-next-line react/display-name
    customRenderer: (menuItemComponent: any, key: any) => (
      <WithPermission action={ActionEntities.delete} resource={resource} key={key}>
        {menuItemComponent}
      </WithPermission>
    ),
    onClick: async ({ selectedIds, refresh, selectedIdsUpdater } = {} as RemoteTableSelectionData) => {
      let confirmed = window.confirm(t(Messages.confirm_remove_selected_items));
      if (confirmed) {
        await service.deleteMany(selectedIds);
        selectedIdsUpdater([]);
        refresh && refresh();
      }
    }
  } as ActionOption
}

export const createDefaultSortOptions = (keyField: string) => {
  return [
    { field: keyField, direction: "asc", label: t(Labels.sort_key_field_asc, { field: keyField }) },
    { field: keyField, direction: "desc", label: t(Labels.sort_key_field_desc, { field: keyField }) },
    { field: "createdAt", direction: "asc", label: t(Labels.sort_created_oldest) },
    { field: "createdAt", direction: "desc", label: t(Labels.sort_created_newest) },
    { field: "updatedAt", direction: "asc", label: t(Labels.sort_updated_oldest) },
    { field: "updatedAt", direction: "desc", label: t(Labels.sort_updated_newest) },
  ];
};

const CardWrapComponent = ({ children, renderInCard }: any) => {
  if(renderInCard == false) {
    return (
      <Fragment>
        {children}
      </Fragment>
    )
  } else {
    return (
      <Card>
        <CardBody>
          { children}
        </CardBody>
      </Card>
    )
  }
}

function RemoteTable<T extends object = any>(props: RemoteTableProps<T> = { keyField: "id" } as any) {
  let { scrollOffset, refreshInterval, searchOptions = [], refresh = () => {}, dataLoadable = [], dataLoader = [], filterKey} = props;

  let tablePaginationOptions: PaginationOptions = props.simple ? {} : { ...defaultPaginationOptions, ...(props.paginationOptions || {}) };
  let tableSortOptions: SortOption[] = props.sortOptions ? props.sortOptions : createDefaultSortOptions(props.keyField);
  let tableRef = createRef<any>();
  let [offsetX, setOffsetX] = useState(0);
  let [loading, setLoading] = useState(false);
  let [dataInitialed, setDataInitialed] = useState(false);
  let [filter] = useState<Filter<T>>({});
  let [sortOption, setSortOption] = useState<SortOption>();
  let [tableState] = useState<TableChangeState<T>>(defaultTableState);
  let [tableFilterState, setTableFilterState] = useState<any>();
  let [pageInfo, setPageInfo] = useState<OffsetPageInfo>(defaultPageInfo);
  let [paginationOptions, setPaginationOptions] = useState<PaginationOptions>(tablePaginationOptions);
  let [tableData, setTableData] = useState<any[]>([]);
  let [activeTab, setActiveTab] = useState<any>();
  let [selectedRowIds, setSelectedRowIds] = useState<any[]>(props.selectedRows || []);
  let [selector, queryState] = dataLoader
  let loadable = dataLoadable[0] || (selector && useRecoilValueLoadable(selector)) || {} as Loadable<any>
  let dataLoadableStateSetter = dataLoadable[1] || (queryState && useSetRecoilState(queryState(defaultPageInfo))) || (() => {})
  // let [sizePerPageAtom, setSizePerPageAtom] = useRecoilState(sizePerPageSelector)
  // let [tableAtom, setTableAtom] = useRecoilState(tableSelector)
  // hidden columns must have at least 1 element hidden = false
  const parseHiddenColumns = () => props.columns?.map((c: any, index: number) => !c?.hidden && index < props.columns?.length - 1
    ? {...c, hidden: true} : c)
  let [remoteTableQuery, setRemoteTableQuery] = useRecoilState(remoteTableQuerySelector)

  // TODO check logic hiddenColumns
  const [customizeColumns, setCustomizeColumns] = useState<ColumnDescription[]>(parseHiddenColumns())

  // const [customizeColumns, setCustomizeColumns] = useState<ColumnDescription[]>(props.columns)

  useEffect(() => {
    setActiveTab(remoteTableQuery.query?.activeTab)
    props.onTabChange && setCustomizeColumns(props.onTabChange(remoteTableQuery.query?.activeTab?.id, customizeColumns))
  }, [remoteTableQuery.query?.activeTab])
  
  useEffect(() => setSelectedRowIds(props.selectedRows || []), [props.selectedRows]);
  // useEffect(() => {
  //   let tableDataIds = tableData.map(item => item[props.keyField])
    // setSelectedRowIds((selectedIds) => selectedIds.filter(id => tableDataIds.includes(id)))
  // }, [tableData]);
  useEffect(() => props.onSelectedChange && props.onSelectedChange(selectedRowIds), [selectedRowIds]);
  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      let refreshIntervalSchedule = setInterval(() => refresh && refresh(), refreshInterval);
      return () => clearInterval(refreshIntervalSchedule);
    }
  });

  useEffect(() => {
    // setCustomizeColumns(props.columns)
    setCustomizeColumns(remoteTableQuery.key === filterKey ? remoteTableQuery?.columns : props.columns)
  }, [])

  const getRemoteTableState = () => {
    if(remoteTableQuery.key !== filterKey) {
      return  {
        ...remoteTableQuery.query,
        size: pageInfo.limit,
        page: pageInfo.page,
      }
    }

    return { ...remoteTableQuery.query }
  }

  /* Keep scroll on editing when re-renderer */
  if (props.editing) {
    useLayoutEffect(() => {
      if (scrollOffset && tableRef) {
        // eslint-disable-next-line react/no-find-dom-node
        let wrapper = ReactDOM.findDOMNode(tableRef.current) as any;
        if (wrapper) {
          let tableContainer = wrapper.querySelector("div.react-bootstrap-table");
          let scrollLeft: any = offsetX - scrollOffset?.left;
          tableContainer.scrollLeft = scrollLeft;
        }
      }
    });

    useEffect(() => {
      if (tableRef) {
        // eslint-disable-next-line react/no-find-dom-node
        let table = (ReactDOM.findDOMNode(tableRef.current) as any)?.querySelector("div.react-bootstrap-table > .table");
        if (table) {
          setOffsetX(table?.getBoundingClientRect()?.left || 0);
        }
      }
    }, []);
  }

  useLayoutEffect(() => {
    if(remoteTableQuery.key !== filterKey && filterKey){
      setRemoteTableQuery(prev => {
        return {
          ...prev,
          query: {
            ...prev.query,
            page: pageInfo.page,
            size: pageInfo.limit
          }
        }
      })
    }
  }, [filterKey]) 

  useEffect(() => {
    setLoading(loadable.state == "loading");

    if (loadable.state == "hasValue") {
      let pageInfo = loadable.contents?.pageInfo || { ...defaultPageInfo };
      const remoteTableState = getRemoteTableState()
      setPaginationOptions({
        ...paginationOptions,
        page: remoteTableState.page,
        sizePerPage: remoteTableState.size,
        totalSize: pageInfo.totalCount,
      });

      setTableData(loadable.contents?.pageItems || []);
      props.onDataUpdate && props.onDataUpdate(loadable.contents);
      if (!dataInitialed) {
        setDataInitialed(true);
      }
    } else {
      setTableData([]);
      props.onDataUpdate && props.onDataUpdate({} as any);
    }
  }, [loadable.contents]);

  // useEffect(() =>{
  //   setPaginationOptions({
  //     ...paginationOptions,
  //     page: filterKey == tableAtom.key ? tableAtom.data?.page : pageInfo.page,
  //     totalSize: pageInfo.totalCount,
  //     sizePerPage: filterKey == tableAtom.key ? tableAtom.data?.size : pageInfo.limit
  //   });
  // }, [])

  const onTableFilterChange = (filter: Filter<T> | Filter<T>[], sorting?: QueryParams<T>, resetPage?: boolean) => {
    if(JSON.stringify(tableFilterState) != JSON.stringify(filter)) {
      setTableFilterState(filter)
      setSelectedRowIds([])
    }
    let newState = {
      // Make sure filter has value to check differ from default page info
      filter: props.filterByIds 
      ? {
          id: {contains: props.filterByIds.split(',')},
          ...filter,
        }
      : (filter || {}),
      page: resetPage ? 1 : paginationOptions.page,
      limit: paginationOptions.sizePerPage,
      sortBy: sorting?.sortBy || tableState.sortField as any,
      sortDirection: sorting?.sortDirection || tableState.sortOrder
    }
    setRemoteTableQuery((currVal: RemoteTableQueryStateType) => (
      {
        ...currVal,
        key: filterKey,
        query: {
          ...currVal.query,
          page: newState.page
        }
      }
    ))

    dataLoadableStateSetter && dataLoadableStateSetter(newState);
  };

  const onTableSortChange = (sort: any) => {
    setSelectedRowIds([])
    setSortOption(sort)
  }

  const onTableTabChange = (tab: any) => {
    setActiveTab(tab)
  }

  const refreshTable = () => {
    setSelectedRowIds([])
    if(dataLoadableStateSetter) {
      dataLoadableStateSetter((prev) => ({ ...prev }))
    } else {
      refresh && refresh()
    }
  }

  const onRemoteTableDataChange = (type: TableChangeType, newState: TableChangeState<T>) => {
    if (type == "pagination") {
      if (filterKey != undefined) {
        setRemoteTableQuery((currVal: RemoteTableQueryStateType) => {
          return {
            ...currVal,
            key: filterKey,
            query: {
              ...currVal.query,
              size: newState.sizePerPage,
              page: newState.page || 1
            }
          }
        })
      }
      dataLoadableStateSetter && dataLoadableStateSetter((current: any) => {
        return ({ ...current, limit: newState.sizePerPage, page: newState.page || 1 })
      });
    }
    props.onTableChange && props.onTableChange(type, newState);
  };

  const getNoDataIndication = () => {
    if (loadable.state == "loading") {
      return <div className="spinner-border" role="status" />;
    } else if (loadable.state == "hasError") {
      return loadable.contents?.message || t(Messages.unknown);
    }
    return <p className="text-danger">{t(Labels.no_data_available)}</p>;
  };

  const handleOnSelect = (row: any, isSelect: boolean) => {
    setSelectedRowIds((current) => isSelect ? [...current, row.id] : current?.filter(id => id != row.id));
  };

  const handleOnSelectAll = (isSelect: boolean, rows: any[]) => {
    setSelectedRowIds((prev) => isSelect ? [...prev, ...rows.map(r => r.id)] : []);
  };

  let selectRowOptions = props.selectRowOptions == false ? null : {
    ...defaultSelectRowOptions,
    ...(props.paginationOptions == true ? {} : props.paginationOptions),
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selected: selectedRowIds
  } as SelectRowProps<any>;

  const handleChangeCustomizeColumn = (value: any) => {
    let tempColumns = customizeColumns?.map((item: ColumnDescription) => {
      if (item.dataField == value.value) {
        // item.hidden = !value.checked
        return {...item, hidden : !value.checked}
      }
      return item
    })
    setCustomizeColumns(tempColumns)
  }

  useEffect(() => {
    if(props.updatedData){
      setTableData((prev: any[]) => {
        const newTableData = prev?.map((item: any) => {
          if(props.updatedData?.hasOwnProperty(item.id)){
            return {...item, ...props.updatedData[item.id]}
          }
          return item
        })
        return newTableData
      })
    }
  }, [props.updatedData])

  const resetColumns = () => setCustomizeColumns(props.columns)

  const pageNestedDropdown = ['invoice']
  
  return (
    <CardWrapComponent renderInCard={props.renderInCard}>
      <Col>
        {
	        props.simple ? null : (
	          <Row>
	            <TableFilter
	              value={filter}
	              filterOptions={props.filterOptions || []}
	              tabOptions={props.tabOptions || []}
	              searchOptions={searchOptions}
	              sortOptions={tableSortOptions || []}
	              onTableFilterChange={onTableFilterChange}
	              onTableSortChange={onTableSortChange}
	              onTableTabChange={onTableTabChange}
	              filterKey={filterKey}
	              customizeColumns={customizeColumns}
	              handleChangeCustomizeColumn={handleChangeCustomizeColumn}
	             resetColumns={resetColumns}
	              filterByIds={props.filterByIds}
	      		/>
          </Row>
        )}
        {pageNestedDropdown.includes(filterKey || "") ? (
          <SelectedActionNestedDropdown
            refresh={refreshTable}
            activeTab={activeTab}
            actions={props.actionOptions}
            selectedIds={selectedRowIds}
            selectedIdsUpdater={setSelectedRowIds}
            selectedItems={tableData?.filter(item => selectedRowIds.includes(item[props.keyField]))}
          />
        ) : (
          <SelectedRowAction
            refresh={refreshTable}
            activeTab={activeTab}
            actions={props.actionOptions}
            selectedIds={selectedRowIds}
            selectedIdsUpdater={setSelectedRowIds}
            selectedItems={tableData?.filter(item => selectedRowIds.includes(item[props.keyField]))}
          />
        )}
        <Row>
          <BootstrapTable
            // xuanduc edit
            hover={true}
            ref={tableRef}
            remote={true}
            data={loading ? (refreshInterval && refreshInterval > 0 ? tableData : []) : tableData}
            columns={customizeColumns}
            keyField={props.keyField}
            rowStyle={{ height: 60 }}
            onTableChange={onRemoteTableDataChange}
            wrapperClasses={props.tableClasses || "table-responsive"}
            headerWrapperClasses={"table-light"}
            noDataIndication={getNoDataIndication}
            {...(props.simple ? {} : { selectRow: selectRowOptions, pagination: paginationFactory(paginationOptions)}) }
            // overlay={overlayFactory({ spinner: true, background: 'transparent', styles: { top: 0 }})}
            sort={{ dataField: props.keyField, order: "desc" }}
            {...{ loading: refreshInterval && dataInitialed ? false : loading } as any}
          />
        </Row>
      </Col>
    </CardWrapComponent>
  );
}

export default RemoteTable;
