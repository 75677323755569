import { SaleChannelDto } from './sale-channel-service';
import { AccountDto } from './account.service';
import { Metafield } from 'types/metafield.type';
import { ProductTypeDto } from './product-type.service';
import { ArtworkDto } from 'data/services/artwork.service';
import { apiBaseUrls } from 'data/rest-api-config';
import { CrudService } from './crud.service';
import { PublishStatus } from "../../pages/products/types/product-status.type";

export interface ProductDto {
  id: number
  sku: string
  title: string
  status: string
  regularPrice: number
  salePrice: number
  artwork: ArtworkDto
  productType: ProductTypeDto
  owner: AccountDto
  publicationChannelIds: SaleChannelDto[]
  description?: string
  tags: Array<string>
  categories: Array<string>
  images: Array<string>
  publishedByChannel: Map<string, string>
  metafields: Metafield[]
  publishStatus: PublishStatus
  imageSuffixes: Array<string>
}

export const ProductApiPaths = {
  BASE: '/products',
  WITH_ID: '/products/{id}',
  QUERY: '/products/query',
  BULK: '/products/bulk',
  IMPORT: '/products/import',
  SEARCH_VENDOR: '/products/vendor',
  ACTION: '/products/actions' ,
  PUBLISH_ID: '/products/{id}/publish',
  COUNT_STATUS: '/products/count/status'
}

export interface UpdateProductDto extends ProductDto { }

export class ProductService extends CrudService<any, ProductDto, UpdateProductDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: ProductApiPaths.WITH_ID,
        updateById: ProductApiPaths.WITH_ID,
        deleteById: ProductApiPaths.WITH_ID,
        query: ProductApiPaths.QUERY,
        base: ProductApiPaths.BASE,
        deleteMany: ProductApiPaths.BULK,
        updateMany: ProductApiPaths.BULK,
        searchVendor: ProductApiPaths.SEARCH_VENDOR
      }
    });
  }

  
  async queryProductBySku (sku: string) {
    let filter = sku ? { artworkSku: { contains: sku } } : {}
    let pageData = await this.query({ filter })
    return pageData?.pageItems || []
  }

  async searchByUsername(searchText: string) {
    let pageData: any = await this.restApi.get(ProductApiPaths.SEARCH_VENDOR, { data: {q: searchText}});
    return pageData?.data;
  }

  async publishActionByIds(ids: string[]) {
    let response: any = await this.restApi.post(ProductApiPaths.ACTION, {
      data: {
        ids:ids,
        action: "publish"
      }
    })
    return this.returnDataOrThrow(response)
  } 

  async publishById(id: string, replaceImages?: boolean) {
    let response: any = await this.restApi.post(`${ProductApiPaths.PUBLISH_ID}?replace_images=${replaceImages}`, {
      params: {
        id,
      }
    })
    return this.returnDataOrThrow(response)
  }

  async getCountByStatus() {
    const response = await this.restApi.get(ProductApiPaths.COUNT_STATUS, { snoozeErrorMessage: true, snoozeAllErrorMessage: true })
    return this.returnDataOrThrow(response)
  }
}

export const ProductServices = new ProductService()