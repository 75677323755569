import { Labels } from "common/labels"
import { Messages } from "common/messages"
import { SelectOption } from "common/types"
import RadioGroup from "components/input/RadioGroup"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import { Toasts } from "core/notification/notifications"
import { t } from "core/translations"
import { portableModalState } from "data/atoms/app.atom"
import { ProductServices } from "data/services/product.service"
import { VendorDto } from "pages/products/components/vendor.select"
import { useState } from "react"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import { useSetRecoilState } from "recoil"
import { RouteNames } from "routes"
import { ResultUpdateVendor } from "./popup-result-update-vendor"
import ResultModule from "./result-module"

export interface BulkUpdateVendorPopupProps {
  onSubmit: Function
  onDone?: Function
  confirmText?: string
}
export const BulkUpdateVendorPopup = ({ onSubmit, onDone, confirmText }: BulkUpdateVendorPopupProps) => {
  const [value, setValue] = useState<any>(undefined)
  const [openChooseVendor, setOpenChooseVendor] = useState<any>({value: "1", label: Labels.choose_vendor})
  const setPortableModal = useSetRecoilState(portableModalState)


  const optionsRadio = [
    {value: "0", label: Labels.auto_update_vendor},
    {value: "1", label: Labels.choose_vendor_for_order_item},
  ]

  const onClose = () => setPortableModal({open: false, title: ""})

  return (
    <>
      <ModalContent>
        <RadioGroup
            items={optionsRadio}
            name={''}
            checkedValue={openChooseVendor?.value}
            onChange={(option: SelectOption) => setOpenChooseVendor(option)}
        />
        {+openChooseVendor?.value ? (
          <AsyncSelect
            placeholder={t(Labels.choose_vendor)}
            defaultOptions={true}
            name="option_value"
            isSearchable={true}
            getOptionValue={(option: VendorDto) => (option ? option?.username : "")}
            getOptionLabel={(option: VendorDto) =>
              option ? (option?.name ? `${option?.username} - ${option?.name}` : `${option?.username}`) : ""
            }
            value={value}
            loadOptions={(searchText: any) => ProductServices.searchByUsername(searchText)}
            onChange={(option: any) => setValue(option)}
          />
        ) : (
          <></>
        )}
      </ModalContent>
      <ModalButtons
        confirmText={confirmText}
        confirm={async () => {
          let data: any = null
          if (value != null && value != undefined) {
            data = await onSubmit(value.value == null || value.value == undefined ? value : value.value)
          }
          if (!(+openChooseVendor?.value)){
            data = await onSubmit({}) 
          }
          if(!value && !data){
            return Toasts.warning(t(Labels.please_choose_vendor))
          }
          if(data){
            Toasts.success(t(Messages.create_invoice_successfully, { number: data.all?.lenght }));
            onDone && onDone();
            setPortableModal({
              open: true,
              size: 'lg',
              title: t(Labels.update_vendor_result),
              content: ( <>
                  <ResultUpdateVendor
                    data={data?.data}
                    linkViewDetail={RouteNames.ORDER_ITEMS}
                    onClose={onClose}
                  />
                </>
              )
            })
          }
        }}
        closeAfterConfirm={false}
      />
    </>
  )
}
