import { Labels } from "common/labels";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import errorImg from "../../assets/images/error-img.png";
import { t } from "../../core/translations";

export default function FallbackErrorPage({ error, resetErrorBoundary, forceBackToHome }: any) {
  const history = useHistory()

  const reset = () => {
    resetErrorBoundary && resetErrorBoundary()
    if(forceBackToHome) {
      history.replace('/')
    }
  }

  return (
    <Container>
      <Row>
        <Col lg="12">
          <div className="text-center mb-5" style={{ marginTop: '5em' }}>
            <h4 className="text-uppercase">{ t(Labels.something_went_wrong) }</h4>
            <div className="mt-5 text-center">
              <Button className="btn btn-primary" onClick={reset}>
                    { forceBackToHome ? t(Labels.back_to_home) : t(Labels.try_again) }
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8" xl="6">
          <div>
            <img src={errorImg} alt="" className="img-fluid" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
