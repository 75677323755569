import { Col, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useEffect, useState } from "react";
import { FilterInput } from "./FilterInput";
import { Labels } from "../../common/labels";
import { t } from "../../core/translations";
import { FilterOption } from "./TableFilter";

export interface TableFilterModalProps {
  open: boolean,
  onSubmit: () => void,
  filters: FilterOption[],
  currentFilters: any,
  removeFilter: any,
  onFilterChange: any,
  changeFilterFields: any,
  collapsingIndex?: number,
}

export const ModalTableFilter = (props: TableFilterModalProps) => {
  let { onSubmit, filters, currentFilters, removeFilter, changeFilterFields, onFilterChange } = props
  let [open, setOpen] = useState(props.open)
  let [collapsingIndex, setCollapsingIndex] = useState(props.collapsingIndex)

  const onToggle = (openState = !open) => {
    setOpen(openState)
    onSubmit && onSubmit()
  }

  useEffect(() => setOpen(props.open), [props.open])

  return (
    <Modal
      isOpen={ open }
      toggle={() => onToggle()}
      style={{
        width: '22vw',
        minWidth: 320,
        height: '100vh',
        margin: 0,
        marginLeft: 'auto'
      }}
      contentClassName='h-100'
    >
      <ModalHeader>
        <p className='modal-title mt-0' id='myModalLabel'>
          {Labels.more_filters}
        </p>
        <button
          type='button'
          className='close'
          data-dismiss='modal'
          aria-label='Close'
          onClick={() => onToggle(false)}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </ModalHeader>
      <ModalBody style={{ height: 'full', overflow: 'auto' }}>
        <div className='accordion accordion-flush' id='accordionFlushExample'>
          {
            filters
              // .filter(filter => filter.hidden)
              .map((filter: any, index: any) => (
                <div key={index} className='accordion-item border-0'>
                  <h2
                    className='accordion-header'
                    id='headingFlushOne'
                  >
                    <button
                      className='accordion-button fw-medium collapsed'
                      onClick={() => setCollapsingIndex(collapsingIndex == index ? null : index)}
                      style={{
                        cursor: filter.disabled ? 'default' : 'pointer',
                        color: filter.disabled ? '#74788d' : undefined,
                        backgroundColor: filter.disabled ? 'transparent' : undefined
                      }}>
                      <div>
                        <div className='mb-2 font-size-14'>{filter.label}</div>
                      </div>
                    </button>
                  </h2>
                  <Collapse isOpen={index === collapsingIndex } className='accordion-collapse'>
                    <div className='accordion-body pt-0'>
                      <FilterInput
                        style={{ padding: '0 1.5rem 0 1rem' }}
                        containerComponent={Col}
                        filter={filter}
                        currentFilters={currentFilters}
                        onFilterChange={onFilterChange}
                        changeFilterFields={changeFilterFields}
                      />
                    </div>
                  </Collapse>
                </div>
              )
            )
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type='button'
          className='btn btn-outline-secondary'
          onClick={() => {
            changeFilterFields(null, true)
            onToggle(false)
          }}
        >
          {t(Labels.reset_all)}
        </button>
        <button
          type='button'
          className='btn btn-primary'
          onClick={() => onToggle(false)}
        >
          {t(Labels.apply)}
        </button>
      </ModalFooter>
    </Modal>
  )
}
