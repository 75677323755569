import { UncontrolledTooltip } from "reactstrap";
import { useState } from "react";

export interface TooltipComponentProps {
  tooltip: string,
  placement?: 'top' | 'bottom' | 'left' | 'right',
  className?: string
  children: any
}

export const TooltipComponent = (props: TooltipComponentProps) => {
  const [id] = useState(`tooltip-${Math.random() * 10}`.replace('.', ''))
  return (
    <div id={id} className={props.className || ''}>
      {
        props.tooltip ? 
          <UncontrolledTooltip placement={props.placement || 'top'} target={id}>
            {props.tooltip}
          </UncontrolledTooltip> 
          :
          null
      }
      { props.children }
    </div>
  );
};
