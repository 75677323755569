import { defaultPageInfo } from '../../components/table/RemoteTable';
import { QueryParams } from "core/rest-api/query-builder";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { ProductServices } from 'data/services/product.service';

export const productIdState = atomFamily<QueryParams<any>, any>({
  key: "productsIdState",
  default: (id: any) => {
    return id;
  }
});

export const productSelector = selectorFamily({
  key: 'productSelector',
  get: (id: any) => async ({get}) => {
    let productId = get(productIdState(id));

    if (+productId > 0) {
      let data = await ProductServices.getById(+productId, { snoozeErrorMessage: false });
      return data
    }

    return {};
  }
})

export const productQueryState = atomFamily<QueryParams<any>, any>({
  key: 'productQueryState',
  default: (params?: any) => {
    return {...defaultPageInfo, ...(params || {})}
  }
})

export const productsSelector = selector({
  key: 'productsSelector',
  get: async ({get}) => {
    let query = get(productQueryState(defaultPageInfo))
    let pageData = await ProductServices.query(query, {
      snoozeErrorMessage: true,
    })
    return pageData
  }
})

export const productBySkuState = atomFamily<any, any>({
  key: "productBySkuState",
  default: (sku: any) => {
    return sku;
  }
});

export const productBySkuSelector = selectorFamily({
  key: 'productBySkuSelector',
  get: (sku: string) => async ({get}) => {
    let skuState = get(productBySkuState(sku));

    if (skuState) {
      let data = await ProductServices.queryProductBySku(skuState)
      return data
    }

    return {};
  }
})

export const productStatusCountSelector = selector({
  key: 'productStatusCountSelector',
  get: async ({ get }) => {
    let countByStatus = await ProductServices.getCountByStatus()
    return countByStatus;
  },
});
