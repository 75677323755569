import { CrudActions } from "./crud.action";
import {
  CreateProductTypeDto,
  ProductTypeDto,
  ProductTypeService,
  ProductTypeServices,
  UpdateProductTypeDto
} from "../services/product-type.service";
import { Logger } from "../../core/logger";
import { AdminUtils } from "../../utils/admin-utils";
import { Filter } from "../../core/rest-api/query-builder";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { withPermission } from "common/hooks/use-permission";
import { format } from "date-fns";

class ProductTypeAction extends CrudActions<ProductTypeService, CreateProductTypeDto, UpdateProductTypeDto> {
  constructor() {
    super(new ProductTypeService());
  }
  
  async searchByProduceType(searchText: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.productTypeEntity,
      async () => {
        let filter: Filter<any> = {};
        if (searchText) {
          filter.code = { contains: searchText };
        }
        let pageData = await ProductTypeServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }
  

  async searchByProductTypeByName(searchText: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.productTypeEntity,
      async () => {
        let filter: Filter<ProductTypeDto> = {};
        if (searchText) {
          filter.name = { contains: searchText };
        }
        let pageData = await ProductTypeServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async importProductTypes(file: any, replaceable?: boolean) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const data: any = await ProductTypeServices.importProductTypes(formData, replaceable);
      return data
    } catch (e) {
      Logger.warn("importProductTypes failed: ", e);
    }
    return false;
  }

  async searchByCode(searchText: string ) {
    let filter: Filter<any> = {};
    if (searchText) {
      let newValue: any = searchText.split(',').map((option: string) => option.trim()).filter((item) => item)
      if(newValue.length == 1){
        newValue = newValue[0]
      }
      filter.code = { contains: newValue };
    }
    let pageData = await ProductTypeServices.query({ filter, page: 1 });
    return pageData?.pageItems || [];
  }
}

export const ProductTypeActions = new ProductTypeAction();
