import { Filter } from 'core/rest-api/query-builder';
import { ProductApiPaths, ProductDto, ProductService, ProductServices, UpdateProductDto } from 'data/services/product.service';
import { CrudActions } from './crud.action';
import { Toasts } from 'core/notification/notifications';
import { t } from 'core/translations';
import { Messages } from 'common/messages';

class ProductAction extends CrudActions<ProductService, ProductDto, UpdateProductDto >{
  constructor() {
    super(new ProductService())
  }
  async search(searchText: string) {
    let filter: Filter<any> = {}
    if (searchText) {
      filter.key = { contains: searchText }
    }
    // let pageData = await ProductServices.temp
  }

  async publishAction(ids: string[], replaceImages?: boolean, refresh?: Function) {
    try {
      let result = await this.service.bulkAction(ProductApiPaths.ACTION, ids, "publish", { replaceImages })
      if(result?.success){
        Toasts.success(t(Messages.your_request_execute_successfully))
        refresh && refresh()
        return result
      }
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  }

  async publishById(id: string, replaceImages?: boolean) {
    try {
      let data =  await ProductServices.publishById(id, replaceImages)
      Toasts.success(t(Messages.your_request_execute_successfully))
      return data
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  } 

  async updateSaleChannel(ids: string[], publicationChannelIds: string[], refresh?: Function) {
    try {
      let result = await this.service.bulkAction(ProductApiPaths.ACTION, ids, "add_sale_channels", { publicationChannelIds })
      if(result?.success){
        Toasts.success(t(Messages.your_request_execute_successfully))
        refresh && refresh()
        return result
      }
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  }

  async removeSaleChannel(ids: string[], publicationChannelIds: string[], refresh?: Function) {
    try {
      let result = await this.service.bulkAction(ProductApiPaths.ACTION, ids, "remove_sale_channels", { publicationChannelIds })
      if(result?.success){
        Toasts.success(t(Messages.your_request_execute_successfully))
        refresh && refresh()
        return result
      }
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  }
}

export const ProductActions = new ProductAction()