import { PortableModalStateProps, ViewExcelStateProps, ViewImgaesStateProps } from "../../types/modal.type";
import { atom, selector } from "recoil";
import { SettingServices } from "../services/setting-service";
import { ChannelOrderSetting } from "../../common/types";
import { isEmpty } from "lodash";

export const portableModalState = atom<PortableModalStateProps>({
  key: 'portableModalState',
  default: {
    open: false,
    title: '',
  }
});

export const viewImagesState = atom<ViewImgaesStateProps>({
  key: 'viewImgaesState',
  default: {
    open: false,
    images: [],
    url: ""
  }
});

export const viewExcelState = atom<ViewExcelStateProps>({
  key: 'viewExcelState',
  default: {
    open: false,
    title: '',
    url: "",
    data: undefined,
  }
});

//! TODO: define type
export const cardDropdownState = atom<any>({
  key: 'cardDropdownState',
  default: {
    open: false,
  }
});

export const channelOrderSettingState = atom<ChannelOrderSetting>({
  key: 'channelOrderSettingState',
  default: {} as any
});

export const channelOrderSettingSelector = selector({
  key: 'channelOrderSettingSelector',
  get: async ({ get }) => {
    const dataState =  get(channelOrderSettingState)

    if(!isEmpty(dataState)){
      return dataState
    }
    let newData = await SettingServices.getOne({ filter: { key: { equalTo: "order" } }}, { snoozeErrorMessage: true })
    return newData.value
  },
});