/* eslint-disable react/display-name */

import { RecoilState, useRecoilRefresher_UNSTABLE, useRecoilState } from "recoil";
import { isEmpty, omit } from "lodash";
import { BulkEditingState, BulkEditorView, defaultBulkEditingState } from "../../../components/bulk/bulk-editor.type";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { BaseBulkEditorProps, defaultSubmitDataBuilder, ROW_HEIGHT } from "../../../components/bulk/BulkEditor";
import DataGrid, {
  Column,
  CopyEvent,
  EditorProps,
  FillEvent,
  PasteEvent,
  RowsChangeData,
  TextEditor
} from "react-data-grid";
import { Link } from "react-router-dom";
import { t } from "core/translations";
import { Button, Card, Col, Input, Label } from "reactstrap";
import { FulfillOrderDto } from "../../../data/services/fulfill-order.service";
import { flattenNestedObject, unflattenNestedObject } from "../../../utils/utils";
import { Labels } from "common/labels";
import { ImWarning } from "react-icons/im";
import { BsCheckCircle, BsFillCheckCircleFill, BsQuestionCircle } from "react-icons/bs";
import { RouteNames } from "routes";
import { Confirmations } from "core/notification/notifications";
import { Messages } from "common/messages";
import { MdError, MdMoneyOff } from "react-icons/md";
import { TooltipComponent } from "../../../components/common/TooltipComponent";
import { Metafield } from "../../../types/metafield.type";
import { BiLinkExternal } from "react-icons/bi";
import { ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { HiOutlineRefresh } from "react-icons/hi";
import { bulkEditFulfillOrderSelector } from "data/atoms/fulfill-order.atom";
import { createUrlEmbed } from "utils/embed";


const designCustomTextKey = 'designCustomData.customText';
const customFieldNamePrefix = 'designCustomData.customField'

const createCustomFieldKey = (idx: any) => `${customFieldNamePrefix}${idx}`
const createCustomizeText = (customFields: any[]) => customFields?.map((attr) => attr.value)?.join(' | ') || ''

const cleanEmptyCustomFields = (rowData: any, customFields: Metafield[]) => {
  let customCount = Math.max(0, rowData.designCustomData?.customCount || rowData.designCustomByArtwork?.customCount || 0)
  while(customFields.length > customCount) {
    if(isEmpty(customFields[customFields.length - 1]?.value)) {
      customFields.pop()
    } else {
      break
    }
  }
  return customFields
}

const reviewCustomColumns = (data: any) => {
  return [
    {
      key: "orderNumber",
      minWidth: 120,
      width: 120,
      editable: true,
      name: t(Labels.order_number),
      formatter: ({ row }: { row: FulfillOrderDto }) => (
        <>
          <a target="_blank" rel="noreferrer" href={createUrlEmbed(`${RouteNames.ORDERS_DETAIL.replace(":id", row.id as string)}`)} className="me-1 text-info fw-bold">
            <BiLinkExternal size="16" />
          </a>
          {`${row.orderNumber}`}
        </>
      ),
      editor: ({ row }: any = {}) => (
        <Input
          value={row["orderNumber"] || ""}
          className={'h-100'}
        />
      )
    },
    {
      key: "sku",
      minWidth: 160,
      name: t(Labels.fulfill_sku),
      formatter: ({ row }: { row: FulfillOrderDto }) => (
        <>
          <a target="_blank" rel="noreferrer" href={createUrlEmbed(`${RouteNames.ORDER_ITEM_DETAIL.replace(":id", row.id as string)}`)} className="me-1 text-info fw-bold">
            <BiLinkExternal size="16" />
          </a>
          {`${row.sku}`}
        </>
      ),
      editor: TextEditor
    },
    {
      editable: true,
      key: "customReviewed",
      minWidth: 85,
      width: 85,
      name: t(Labels.reviewed),
      formatter: ({ row, onRowChange }: any = {}) => (
        <div className="text-center h-100 d-flex justify-content-center align-items-center">
          <Input
            type="checkbox"
            className="form-check-Input font-size-16"
            defaultChecked={["1", "true"].includes(`${row["customReviewed"]}`)}
            checked={["1", "true"].includes(`${row["customReviewed"]}`)}
            onChange={event => {
              onRowChange({ ...row, customReviewed: event.target.checked, __changedKeys: ["customReviewed"] }, true)
            }}
          />
        </div>
      ),
      editor: ({ row, onRowChange }: any = {}) => (
        <div className="text-center h-100 d-flex justify-content-center align-items-center">
          <Input
            type="checkbox"
            className="form-check-Input font-size-16"
            defaultChecked={["1", "true"].includes(`${row["customReviewed"]}`)}
            checked={["1", "true"].includes(`${row["customReviewed"]}`)}
            onChange={event => {
              onRowChange({ ...row, customReviewed: event.target.checked, __changedKeys: ["customReviewed"] }, true)
            }}
          />
        </div>
      ),
    },
    {
      key: "customStatus",
      name: t(Labels.custom_status),
      minWidth: 220,
      editable: false,
      frozen: true,
      formatter: ({ row, onRowChange }: any) => {
        const getCurrentDesugnCustomStatus = () => {
          switch (row?.designCustomByArtwork?.status) {
            case "warn":
              return <ImWarning color="orange" fontSize={18} />
            case "ok":
              return <BsCheckCircle color="green" fontSize={18} />
            case "missing":
              return <MdError color="red" fontSize={18} />
            default:
              return null
          }
        }
        const message = row.designCustomByArtwork?.message
        const unpaidCustomFields =  row.designCustomData?.unpaidCustomFields ;
        const unusedCustomFields = row.designCustomData?.unusedCustomFields ;
        return (
          <div className={`d-flex align-items-center h-100 mb-1 ${message ? "" : 'justify-content-center'}`}>
            <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popoverRight(row)}>
              <Button className="bg-transparent shadow-none border-0 d-flex justify-content-center align-items-center gap-1 ps-0">
                {!unpaidCustomFields?.length && !unusedCustomFields?.length ? (
                  <>
                    <TooltipComponent tooltip={t(`${Labels.all_custom_fields_have_been_used}. ${Labels.click_to_view_detail}`)}>
                      <BsFillCheckCircleFill size={20} color={"green"} />
                    </TooltipComponent>
                  </>
                ) : (
                  <>
                    {unpaidCustomFields?.length ? (
                      <TooltipComponent tooltip={t(`${Labels.has_unpaid_custom_fields}. ${Labels.click_to_view_detail}`)}>
                        <MdMoneyOff size={22} color="orange" />
                      </TooltipComponent>
                    ) : (
                      <></>
                    )}
                    {unusedCustomFields?.length ? (
                      <TooltipComponent tooltip={t(`${Labels.has_unused_custom_fields}. ${Labels.click_to_view_detail}`)}>
                        <BsQuestionCircle size={18} color="red" />
                      </TooltipComponent>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Button>
            </OverlayTrigger>

            {
              row["_bulkUpdated"] ? (
                <>
                  <Link
                    className="me-2 pt-1"
                    to={"#"}
                    onClick={() => onRowChange({ ...row, __changedKeys: ["__bulkResetRowChange"] }, true)}
                  >
                    <TooltipComponent tooltip={t(Labels.reset_change)}>
                      <i className="mdi mdi-history font-size-20" />
                    </TooltipComponent>
                  </Link>
                  {t(Messages.modified)}
                </>
              )
              :
              <div>
                <span className="mb-1 me-2">{getCurrentDesugnCustomStatus()}</span>{message}
              </div>
            }
          </div>
        )
      },
    },
    ...Array.from({ length: data[0]?.__customCount || 0})?.map((_item: any, idx: number) => {
      const key = createCustomFieldKey(idx)
      return {
        key: key,
        name: t(`Custom ${idx + 1}`),
        editable: true,
        formatter: ({ row }: { row: any }) => {
          return row[key]?.value || ""
        },
        minWidth: 180,
        editor: ({ row, onRowChange }: EditorProps<any, any>) => {
          return (
            <Input
              value={row[key]?.value || ""}
              onChange={(e: any) => onRowChange({ ...row, [key]: { ...row[key], value: e.target.value }})}
              className={'h-100'}
              autoFocus={true}
              onBlur={(e: any) => onRowChange({ ...row, [key]: { ...row[key], value: e.target.value }, __changedKeys: [designCustomTextKey]}, true)}
            />
          )
        },
      }
    }),
    {
      key: 'designCustomModifyNote',
      minWidth: 150,
      name: t(Labels.modify_note),
      editable: true,
      editor: TextEditor
    },
    {
      key: designCustomTextKey,
      minWidth: 200,
      name: t(Labels.customize),
      cellClass: "normalLineHeight" ,
      formatter: ({ row }: { row: any }) => {
        let customText = row[designCustomTextKey]
        let customTextByArtwork = row?.designCustomByArtwork?.customText?.trim()
        const compareString = customText == customTextByArtwork
        return (
          <>
            {customText != customTextByArtwork ? (
              <Col className="h-100 d-flex justify-content-center flex-column">
                <table>
                  <tbody>
                  <tr>
                    <td><Label className="d-inline ps-0">{t(Labels.current)}:</Label></td>
                    <td className="ps-1 w-100">{customText}</td>
                  </tr>
                  <tr>
                    <td><Label className="d-inline ps-0">{t(Labels.by_artwork)}:</Label></td>
                    <td className="ps-1 w-100">{customTextByArtwork}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
            ) : (
              <p className="m-0 h-100 d-flex align-items-center">{customText}</p>
            )}
          </>
        )
      },
    },
    {
      key: "customerNote",
      minWidth: 150,
      name: t(Labels.customer_note),
      editable: true,
      editor: ({ row }: any = {}) => (
        <Input
          value={row["customerNote"] || ""}
          className={'h-100'}
        />
      )
    },
    {
      key: "paidCustomFee",
      minWidth: 100,
      name: t(Labels.custom_fee),
      formatter: ({ row }: { row: any }) => {
        return <div className="text-center h-100 d-flex justify-content-center align-items-center">
          <Input
            type="checkbox"
            className="form-check-Input font-size-16"
            checked={["1", "true"].includes(`${row["paidCustomFee"]}`)}
            onChange={() => {}}
          />
        </div>
      }
    },
    {
      key: "designCustomData.unusedCustomFields",
      name: t(Labels.unused_custom_fields),
      minWidth: 200,
      formatter: ({ row }: { row: any }) => {
        return <p>{row?.designCustomData?.unusedCustomFields?.map((item: any) => `${item.key}: ${item.value}`).join(" | ")}</p>
      },
      editable: true,
      editor: ({ row }: any = {}) => (
        <Input
          value={row?.designCustomData?.unusedCustomFields?.map((item: any) => `${item.key}: ${item.value}`).join(" | ") || ""}
          className={'h-100'}
        />
      )
    },
    {
      key: "designCustomData.unpaidCustomFields",
      name: t(Labels.unpaid_custom_fields),
      minWidth: 250,
      formatter: ({ row }: { row: any }) => {
        return <p>{row.designCustomData?.unpaidCustomFields?.map((item: any) => `${item.key}: ${item.value}`).join(" | ")}</p>
      },
      editable: true,
      editor: ({ row }: any = {}) => (
        <Input
          value={row.designCustomData?.unpaidCustomFields?.map((item: any) => `${item.key}: ${item.value}`).join(" | ") || ''}
          className={'h-100'}
        />
      )
    },
    {
      key: "customFields",
      name: t(Labels.all_custom_fields),
      minWidth: 250,
      formatter: ({ row }: { row: any }) => {
        return <p>{row.customFields?.map((item: any) => `${item.key}: ${item.value}`).join(" | ")}</p>
      },
      editable: true,
      editor: ({ row }: any = {}) => (
        <Input
          value={row.customFields?.map((item: any) => `${item.key}: ${item.value}`).join(" | ") || ""}
          className={'h-100'}
        />
      )
    }
  ]
}

const createEditData = (data: any) => {
  let editData = []
  const __customCount = Math.max(5, Math.max(...(data as any).map((item: any) => item?.designCustomData?.customFields?.length || 0)))

  for(let item of data) {
    const customFieldProperties: any = {}
    let customFields = item.designCustomData?.customFields || []
    customFields.forEach((customField: any, idx: number) => {
      const key = createCustomFieldKey(idx)
      customFieldProperties[key] = customField
    })
    customFieldProperties[designCustomTextKey] = item.designCustomData?.customText?.trim() || []

    editData.push({ ...item, ...customFieldProperties, __customCount})
  }
  return editData
}

export const PersonalCustomBulkEdit = (props: BaseBulkEditorProps<FulfillOrderDto>) => {
  const [editData, setEditData] = useState(createEditData(props.data))
  const columns = useMemo(() => reviewCustomColumns(editData), [editData]);

  useEffect(() => setEditData(createEditData(props.data)), [props.data])

  const submitDataBuilder = (data: any) => {
    let newData = {} as any
    for(let id in data) {
      let updatedData = data[id]
      let submitData = updatedData
      let rawData = editData.find(item => item.id == id)
      if(rawData) {
        let customFieldKeys = Object.keys(updatedData).filter(key => key.startsWith(customFieldNamePrefix))
        if(customFieldKeys.length) {
          let customFields = []
          for(let i = 0; i < rawData.__customCount; i++) {
            let updatedValue = updatedData[createCustomFieldKey(i)]
            let originValue = (rawData.designCustomData?.customFields || [])[i]
            customFields.push({ name: `Custom ${i + 1}`, value: "", ...(updatedValue || originValue)})
          }

          cleanEmptyCustomFields(rawData, customFields)

          submitData = {
            ...omit(updatedData, ...customFieldKeys, designCustomTextKey),
            designCustomData: {
              ...rawData.designCustomData,
              customFields,
              customText: createCustomizeText(customFields)
            },
            [designCustomTextKey]: createCustomizeText(customFields)
          }
        }
      }
      newData[id] = submitData
    }

    return newData
  }

  const updateRowDataBuilder = (key: string, cellValue: any, rowData: any) => {
    let newData: any = {};
    if (cellValue == null || cellValue == undefined) return newData;

    if (key.startsWith(customFieldNamePrefix)) {
      let customFields = Object.keys(rowData).filter(customKey => customKey.startsWith(customFieldNamePrefix))
        .map(customKey => customKey == key ? cellValue : rowData[customKey] || '')

      cleanEmptyCustomFields(rowData, customFields)
      newData[designCustomTextKey] = createCustomizeText(customFields)
    }

    return newData;
  };

  return (
    <Fragment>
      <BulkEditorReviewCustom
        {...props}
        data={editData}
        columns={{[BulkEditorView.REVIEW_CUSTOM]: columns}}
        bulkEditSelector={props.bulkEditSelector}
        submitDataBuilder={submitDataBuilder}
        updateRowDataBuilder={updateRowDataBuilder}
      />
    </Fragment>
  )
}

export interface BulkEditorProps<T> extends BaseBulkEditorProps<T> {
  columns: Record<string, Column<T, any>[]>
  customOptions?: any
  nestedFields?: Record<string, string[]>
  customRowDataBuilder?: (currentRowData: T, newRowData: T) => { customOptions: any; newRowData: any }
  updateRowDataBuilder?: (key: string, cellValue: any, rowData: T) => any
  submitDataBuilder?: (data: T, nestedObjectKeys?: string[], rowData?: any) => any
}

const BulkEditorReviewCustom = (props: BulkEditorProps<any>) => {
  let { submitDataBuilder = defaultSubmitDataBuilder } = props
  
  const [bulkEditingState, setBulkEditingState] = useRecoilState<BulkEditingState>(props.bulkEditSelector)
  const columns = props.columns[props.editor] || []
  const nestedObjectKeys = (props.nestedFields && props.nestedFields[props.editor]) || []
  
  const createGridRowData = () => {
    let data = props.data || []
    let rows: any[] = []
    let updateDataById = {} as any
    
    for (let i = 0; i < data.length; i++) {
      let row = {
        ...data[i],
        ...flattenNestedObject(data[i], nestedObjectKeys),
      }
      
      let updatedData = { ...(bulkEditingState.updatedData[row.id] || {}) }
      if (!isEmpty(updatedData)) {
        updateDataById[row.id] = updatedData
        rows.push({
          ...row,
          ...updatedData,
          _bulkUpdated: true,
        })
      } else {
        rows.push({
          ...row,
          _bulkUpdated: false,
        })
      }
    }

    return rows
  }

  const onRowsChange = (rows: any, data: RowsChangeData<any>) => {
    let { indexes, column } = data

    setBulkEditingState((current: any) => {
      let newBulkUpdateData = { ...current.updatedData }
      for (let index of indexes) {
        let rowData = rows[index]
        if (!rowData) continue

        if (rowData.__changedKeys?.includes("__bulkResetRowChange")) {
          rowData.__changedKeys.length = 0 // Reset array
          newBulkUpdateData = omit(newBulkUpdateData, rowData.id)
          continue
        }

        let changedData = {} as any
        if (column) {
          let value = rowData[column.key]
          if (value != null || value != undefined) {
            changedData[column.key] = value
            let customUpdateData = props.updateRowDataBuilder ? props.updateRowDataBuilder(column.key, value, rowData) : {}
            changedData = {
              ...changedData,
              ...(customUpdateData || {}),
            }
          }
        } else if (rowData.__changedKeys) {
          for (let key of rowData.__changedKeys) {
            changedData[key] = rowData[key]
          }
        }

        let nestedChangeData = unflattenNestedObject(rowData, changedData, nestedObjectKeys)
        newBulkUpdateData = {
          ...newBulkUpdateData,
          [rowData.id]: { ...(bulkEditingState.updatedData[rowData.id] || {}), ...changedData, ...nestedChangeData },
        }
      }

      let newSubmitData = submitDataBuilder(newBulkUpdateData, nestedObjectKeys)
      return {
        ...current,
        updatedData: newBulkUpdateData,
        submitData: newSubmitData,
      }
    })
  }

  function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<any>) {
    if(columnKey.startsWith(customFieldNamePrefix)){
      let value = sourceRow[columnKey]
      let customUpdateData = props.updateRowDataBuilder ? props.updateRowDataBuilder(columnKey, value, targetRow) : {}
      return {...targetRow, [columnKey]: sourceRow[columnKey],...customUpdateData}
      
    }
    return { ...targetRow, [columnKey]: sourceRow[columnKey] }
  }

  const handlePaste = ({ sourceColumnKey, sourceRow, targetColumnKey, targetRow }: PasteEvent<any>) => {
    const incompatibleColumns = ["id"]
    if (
      sourceColumnKey !== targetColumnKey &&
      (incompatibleColumns.includes(targetColumnKey) || incompatibleColumns.includes(sourceColumnKey))
    ) {
      return targetRow
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] }
  }

  function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<any>): void {
    if (window.isSecureContext) {
      let value = sourceRow[sourceColumnKey]
      navigator.clipboard.writeText(value)
    }
  }

  function rowKeyGetter(row: any) {
    return row.id
  }

  const calculateRowHeight = ({row}: any) => {
    const isSameCustomText = row['designCustomData.customText'] == row?.designCustomByArtwork?.customText?.trim()
    return isSameCustomText ? ROW_HEIGHT : ROW_HEIGHT * 2 - 20
  }

  return (
    <div style={{ height: "100%" }}>
      <Card>
        <DataGrid
          columns={columns}
          rows={createGridRowData()}
          rowKeyGetter={rowKeyGetter}
          onRowsChange={onRowsChange}
          onFill={handleFill}
          onCopy={handleCopy}
          onPaste={handlePaste}
          rowHeight={calculateRowHeight}
          defaultColumnOptions={{ resizable: true }}
          className={"fill-grid rdg-dark rdg-light"}
          style={{ height: "100%", blockSize: "100%" }}
          rowClass={(row: any) => (row._bulkUpdated ? "react-data-grid-updated-row" : "react-data-grid-normal-row")}
        />
      </Card>
     
    </div>
  )
}

const popoverRight = (data: any) => {
  const [columns, setColumns] = useState<any>([])

  useEffect(() => {
    const newColumns: ColumnDescription[] = [
      {
        dataField: "key",
        editable: false,
        text: t(Labels.key),
        headerStyle: { minWidth: 160 },
        // eslint-disable-next-line react/display-name
        formatter: (cell: any, row: any) => {
          return <>{`${row.name || row.key || ""}`}</>
        },
      },
      {
        dataField: "value",
        editable: false,
        text: t(Labels.value),
      },
      {
        dataField: "status",
        editable: false,
        headerStyle: { width: 100 },
        text: t(Labels.status),
        // eslint-disable-next-line react/display-name
        formatter: (cell: any, row: any) => {
          const unpaidCustomFields = data.designCustomData?.unpaidCustomFields
          const unusedCustomFields = data.designCustomData?.unusedCustomFields
          return (
            <>
              {unpaidCustomFields?.length ? (
                <div className="d-flex justify-content-center align-items-center h-100 w-100">
                  <MdMoneyOff color="red" size={22}/>
                  <h6 className="mb-0 me-1">{t(Labels.unpaid)}</h6>
                </div>
              ) : (
                <></>
              )}
              {unusedCustomFields?.length ? (
                <div className="d-flex justify-content-center align-items-center h-100 w-100">
                  <BsQuestionCircle color="red" size={16}/>
                  <h6 className="mb-0 ms-1">{t(Labels.unused)}</h6>
                </div>
              ) : (
                <></>
              )}
              {!unpaidCustomFields?.length && !unusedCustomFields?.length ? (
                <div className="d-flex justify-content-center align-items-center h-100 w-100">
                  <BsFillCheckCircleFill size={18} color={"green"} />
                  <h6 className="mb-0 ms-1">{t(Labels.used)}</h6>
                </div>
              ) : (
                <></>
              )}
            </>
          )
        },
      },
    ]
    setColumns(newColumns)
  }, [])

  return (
    <Popover id="popover-positioned-right" className="overflow-auto popover-review-custom-data-grid" title="Popover right">
      <BootstrapTable
        cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
        keyField={"id"}
        data={data?.customFields || []}
        columns={columns}
        wrapperClasses="react-bootstrap-table-small"
        headerWrapperClasses={"table-light"}
        sort={{ order: "asc" }}
        noDataIndication={t(Labels.no_data_available)}
      />
    </Popover>
  )
}

export interface ReviewCustomBulkEditActionProps<T> {
  bulkEditSelector: RecoilState<T>
  dataRefresh: () => void
  onSubmit: (data: Record<string, T>) => Promise<boolean | undefined> // true if updated, false otherwise
  onExit: () => void
}

export const ReviewCustomBulkEditAction = (props: ReviewCustomBulkEditActionProps<any>) => {
  const [updating, setUpdating] = useState(false)
  const [bulkEditingState, setBulkEditingState] = useRecoilState<BulkEditingState>(props.bulkEditSelector);

  const handleSaveBulkUpdateData = async () => {
    try {
      setUpdating(true)
      let updated = await props.onSubmit(bulkEditingState.submitData)
      if(updated) {
        setBulkEditingState((current: any) => ({ ...current, ...defaultBulkEditingState, updatedData: {}}))
        props.dataRefresh()
      }
    } finally {
      setUpdating(false)
    }
  }

  const handleCancelBulkEdit = () => {
    if(!isEmpty(bulkEditingState.submitData)) {
      Confirmations.confirm(
        t(Messages.do_you_want_to_discard_the_updated_information),
        () => {
          setBulkEditingState(defaultBulkEditingState)
          props.onExit()
        }
      )
    } else {
      setBulkEditingState(defaultBulkEditingState)
      props.onExit()
    }
  }

  return (
    <Fragment>
      <Button color="info" className="me-2" disabled={updating} onClick={() => props.dataRefresh()}>
        <HiOutlineRefresh size={16} className="me-1 mb-1" />
        {t(Labels.refresh)}
      </Button>
      <Button
        color="success"
        className="me-2"
        disabled={updating}
        onClick={handleSaveBulkUpdateData}
      >
        <i className="bx bx-save pt-1 me-1"></i>
        {t(Labels.save)}
      </Button>
      <Button
        color="danger"
        className="me-2"
        disabled={updating}
        onClick={handleCancelBulkEdit}
      >
        <i className="bx bx-x pt-1 me-1"></i>
        {t(Labels.close)}
      </Button>
    </Fragment>
  )
}