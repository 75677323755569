import { Labels } from "../../../common/labels";

export enum CustomType {
  PERSONAL_CUSTOM = 'personal_custom',
  NO_CUSTOM = 'no_custom',
  DEFAULT_CUSTOM = 'default_custom',
  GENERATED_CUSTOM = 'generated_custom',
  UNKNOWN = 'unknown_artwork'
}

export const CustomTypeOptions = [
{ label: Labels.no_custom, value: "no_custom" },
{ label: Labels.default_custom, value: "default_custom" },
{ label: Labels.generated_custom, value: "generated_custom" },
{ label: Labels.personal_custom, value: "personal_custom" },
{ label: Labels.unknown_artwork, value: "unknown" },
]