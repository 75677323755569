import { Labels } from "common/labels";
import RemoteTable, { defaultPageInfo } from "components/table/RemoteTable";
import { FilterOption, SearchOption, TabOption } from "components/table/TableFilter";
import { t } from "core/translations";
import { Fragment, useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Badge, Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { fulfillmentInvoiceQueryState, fulfillmentInvoiceSelector } from "data/atoms/fulfillment-invoice.atom";
import { portableModalState } from "data/atoms/app.atom";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { RouteNames } from "routes";
import { isNumber } from "lodash";
import { InvoicesStatusColor, InvoicesStatusName } from "types/invoices-status.type";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { ModalButtons, ModalContent } from "components/modal/portable-modal";
import ActionButton from "components/input/ActionButton";
import Breadcrumb from "components/layout/Breadcrumb";
import {FiSave} from "react-icons/fi"
import { Toasts } from "core/notification/notifications";
import { Messages } from "common/messages";
import { BiLinkExternal } from "react-icons/bi";
import { PopupCreateComponent } from "./components/invoice-popup-create";
import { FaRegCopy } from "react-icons/fa";
import { ActionOption, RemoteTableSelectionData } from "../../components/table/SelectedRowAction";
import { FulfillmentInvoiceActions } from "data/actions/fulfillment-invoice.action";
import { FulfillmentInvoiceStatusName, FulfillmentInvoiceStatusValues } from "types/fulfillment-invoice.type";
import { TooltipComponent } from "components/common/TooltipComponent";
import { getIdAndName } from "utils/utils";
import { SearchableSelect } from "components/input/SearchableSelect";
import AsyncSelect from "react-select/async";
import { FulfillServiceActions } from "data/actions/fulfill-service.action";
import { ImportSettledInvoicePopup } from "./components/fulfillment-invoice-import-settled";
import DropdownComponent from "components/dropdown/DropdownComponent";
import { createLinkEmbedParam } from "utils/embed";


const FulfillmentInvoicesPage = () => {
  const setPortableModal = useSetRecoilState(portableModalState)
  const [queryState, setQueryState] = useRecoilState(fulfillmentInvoiceQueryState(defaultPageInfo))
  const loadable = useRecoilValueLoadable(fulfillmentInvoiceSelector)
  const [countByStatus, setCountByStatus] = useState<any>({})
  const refresh = useRecoilRefresher_UNSTABLE(fulfillmentInvoiceSelector);



  const popupViewInvoice = (data: any) => {
    setPortableModal({
      open: true,
      size: 'lg',
      title: `${Labels.edit_invoices} ${data.id}`,
      content: (<PopupCreateComponent data={data}/>)
    })
  }


  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { minWidth: 80, width: 80 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Link {...createLinkEmbedParam("#")} onClick={() => popupViewInvoice(row)}>{cell}</Link>
      },
    },
    {
      dataField: "orderNumber",
      text: t(Labels.order_title),
      headerStyle: { minWidth: 160 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Col>
          <span>{`${row.fulfillmentOrderId || ""} ${cell ? `- ${cell}` : "" }`}</span>
          {/* <Row> */}
          {row.fulfillmentSku && row.fulfillmentOrderId ? (
              <span>
                {/* {row.fulfillmentSku} */}
                <Link target="_blank" className="ms-1" to={createLinkEmbedParam(RouteNames.FULFILLMENT_ORDERS_DETAIL.replace(":id", row.fulfillmentOrderId)).to}>
                  <BiLinkExternal />
                </Link>
              </span>
            ) : (
              <></>
            )}
            {cell ? (
              <span>
              <TooltipComponent tooltip={t(Labels.order_number)} className='d-inline'>
                <Link {...createLinkEmbedParam("")}
                  onClick={(e: any) => {
                    e.preventDefault()
                    navigator.clipboard.writeText(cell)
                    Toasts.info(t(Messages.order_number_is_copied_to_clipboard))
                  }}
                  className='ms-1'
                >
                  <FaRegCopy />
                </Link>
                </TooltipComponent>
              </span>
            ) : (
              <></>
            )}
          </Col>
        )
      },
    },
    {
      dataField: "fulfillService",
      text: t(Labels.propduct),
      headerStyle: { minWidth: 120 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Fragment>
            <Col>
              <Row>
                <span>{row?.fulfillService?.name}</span>
              </Row>
              <Row>
                <span>{getIdAndName(row?.fulfillmentVendor)}</span>
              </Row>
            </Col>
          </Fragment>
        )
      },
    },
    {
      dataField: "vendorInvoiceStatus",
      text: t(Labels.the_state),
      headerStyle: { minWidth: 125 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Fragment>
            <Col>
              <Row>
                <span>{FulfillmentInvoiceStatusName[row?.vendorInvoiceStatus]}</span>
              </Row>
              <Row>
                <span>{FulfillmentInvoiceStatusName[row?.fulfillInvoiceStatus]}</span>
              </Row>
            </Col>
          </Fragment>
        )
      },
    },
    {
      dataField: "price",
      text: t(Labels.unit_price),
      headerStyle: { minWidth: 120 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Fragment>
            <Col>
              <Row>
                <span>{isNumber(cell) ? `${cell}$` : ""}</span>
              </Row>
              <Row>
                <span>
                  {row.quantity || 0} {Labels.produce}
                </span>
              </Row>
            </Col>
          </Fragment>
        )
      },
    },
    {
      dataField: "discount",
      text: t(Labels.discount),
      headerStyle: { minWidth: 80, width: 80 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Fragment>
            <Col>
              <Row>
                <span>{cell || isNumber(cell) ? `${cell}$` : ""}</span>
              </Row>
            </Col>
          </Fragment>
        )
      },
    },
    {
      dataField: "total",
      text: t(Labels.into_money),
      headerStyle: { minWidth: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Fragment>
            <span>{cell || isNumber(cell) ? `${cell}$` : ""}</span>
          </Fragment>
        )
      },
    },
    {
      dataField: "tax",
      headerStyle: { minWidth: 140 },
      text: t(Labels.Taxes_Fees),
      hidden: true,
      formatter: (cell: any, row: any) => {
        return (
          <Fragment>
            <span>{cell}</span>
          </Fragment>
        )
      },
    },
    {
      dataField: "note",
      headerStyle: { minWidth: 200 },
      text: t(Labels.note),
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Fragment>
            <span>{cell}</span>
          </Fragment>
        )
      },
    },
    {
      dataField: "invoiceDate",
      text: t(Labels.release_date),
      headerStyle: { minWidth: 140 },
      formatter: (cell: any, row: any) => {
        return format(new Date(cell), "MMM dd, yyyy")
      },
    },
  ]
  
  let searchOptions: SearchOption[] = [
    {
      field: 'orderNumber',
      label: t(Labels.order_number),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values.length <= 1 ? values[0] || "" : values }
      },
    },
    {
      field: 'id',
      label: t(Labels.id),
      filterBuilder: value => {
        return { equalTo: value.trim() }
      },
    },
    {
      field: 'fulfillmentVendorId',
      label: t(Labels.vendor_Id),
      filterBuilder: value => {
        return { equalTo: value.trim() }
      },
    },
    {
      field: 'fulfillmentOrderId',
      label: t(Labels.fulfillment_order_id),
      filterBuilder: value => {
        return { equalTo: value.trim() }
      },
    },
  ];
  
  let actionOptions: ActionOption[] = [
    {
      name: "change_vendor_status",
      label: t(Labels.change_vendor_status),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {},
      option: {
        listOption: FulfillmentInvoiceStatusValues.map((item) => ({label: FulfillmentInvoiceStatusName[item], value: item})),
        onClickOption: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData, valueOption: any) => {
          setPortableModal({
            open: true,
            title: t(Labels.change_vendor_status),
            content: (
                <>
                  <ModalContent>
                  <Label dangerouslySetInnerHTML={{__html: t(Messages.confirm_change_vendor_status, { vendorInvoiceStatus: `<span class="text-danger">${valueOption.label}</span>?` })}}></Label>
                  </ModalContent>
                  <ModalButtons cancelText={Labels.no} hiddenConfirmButton={true}>
                    <ActionButton
                      className="btn btn-success"
                      onClick={async () => {
                        const result = await FulfillmentInvoiceActions.changeVendorStatus(selectedIds, valueOption.value)
                        if(result){
                          setPortableModal({open: false, title: ""})
                          refresh && refresh()
                        }
                      }}
                    >
                      {t(Labels.yes)}
                    </ActionButton>
                  </ModalButtons>
                </>
              )
            })
          },
        },
      },
    {
      name: "change_fulfill_service_status",
      label: t(Labels.change_fulfill_service_status),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {},
      option: {
        listOption: FulfillmentInvoiceStatusValues.map((item) => ({label: FulfillmentInvoiceStatusName[item], value: item})),
        onClickOption: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData, valueOption: any) => {
          setPortableModal({
            open: true,
            title: t(Labels.change_fulfill_service_status),
            content: (
                <>
                  <ModalContent>
                      <Label dangerouslySetInnerHTML={{__html: t(Messages.confirm_change_invoice_status, { fulfillInvoiceStatus: `<span class="text-danger">${valueOption.label}</span>?` })}}></Label>
                      <Label>
                      </Label>
                  </ModalContent>
                  <ModalButtons cancelText={Labels.no} hiddenConfirmButton={true}>
                    <ActionButton
                      className="btn btn-success"
                      onClick={async () => {
                        const result = await FulfillmentInvoiceActions.changeFulfillInvoiceStatus(selectedIds, valueOption.value)
                        if(result){
                          setPortableModal({open: false, title: ""})
                          refresh && refresh()
                        }
                      }}
                    >
                      {t(Labels.yes)}
                    </ActionButton>
                  </ModalButtons>
                </>
              )
            })
          },
        },
      }
  ]

  const filterOptions: FilterOption[] = [
    {
      name: "fulfillServiceId",
      label: t(Labels.propduct),
      type: "custom",
      filterBuilder: (values: any[]) => ({ contains: values?.map(item => item.id) }),
      componentCreator: (value: any, onChange: any) => {
        return <AsyncSelect 
          isMulti={true}
          isSearchable
          cacheOptions
          defaultOptions={true}
          value={value}
          loadOptions={(searchText: any) => FulfillServiceActions.search(searchText) }
          getOptionValue={(option: any) => option?.id}
          getOptionLabel={(option: any) => option?.name}
          onChange={(values: any) => {
            onChange('fulfillServiceId', values)
          }}
          blurInputOnSelect={false}
        />
      },
      hidden: true
    },
    {
      name: "fulfillInvoiceStatus",
      label: t(Labels.fulfill_service_status),
      type: "checkbox",
      value: FulfillmentInvoiceStatusValues.map(value => ({ value, label: FulfillmentInvoiceStatusName[value] || value })),
      hidden: true,
    },
    {
      name: "vendorInvoiceStatus",
      label: t(Labels.vendor_status),
      type: "checkbox",
      value: FulfillmentInvoiceStatusValues.map(value => ({ value, label: FulfillmentInvoiceStatusName[value] || value })),
      hidden: true,
    },
    {
      name: "invoiceDate",
      label: t(Labels.release_date),
      type: "date",
      filterBuilder: value => ({ between: value }),
      hidden: true,
    },
  ]

  let tabOptions: TabOption[] = [
    {
      id: "all",
      label: t(Labels.all),
      count: countByStatus['all'] || 0,
      filter: {},
    },
    ...FulfillmentInvoiceStatusValues.map(value => ({
      id: value,
      label: FulfillmentInvoiceStatusName[value],
      filter: { fulfillInvoiceStatus: { equalTo: value } },
      count: countByStatus[value] || 0
    })),
  ]

  const refreshData = () => {
    setQueryState((prev: any) => ({ ...prev }))
  }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={t(Labels.goods_bill)}>
            <TopBarButton refresh={refresh} />
          </Breadcrumb>
          <RemoteTable
            dataLoadable={[loadable, setQueryState]}
            searchOptions={searchOptions}
            filterOptions={filterOptions}
            tabOptions={tabOptions}
            keyField={"id"}
            columns={columns}
            filterKey={"invoice"}
            refresh={refreshData}
            actionOptions={actionOptions}
          />
        </Container>
      </div>
    </Fragment>
  )
}


const TopBarButton = (props: any) => {
  const setPortableModal = useSetRecoilState(portableModalState)

  const popupCreate = () => {
    setPortableModal({
      open: true,
      size: 'lg',
      title: Labels.create_invoice,
      content: (<PopupCreateComponent />)
    })
  }

  const importSettledInvoice = () => {
    setPortableModal({
      open: true,
      title: t(Labels.import_settled_invoices),
      size: 'lg',
      content: (
        <>
          <ImportSettledInvoicePopup refresh={props.refresh} />
        </>
      ),
    })
  }

  return (
    <Fragment>
      <DropdownComponent onMenuItemClick="toggle" alignRight>
          <DropdownComponent.Toggle>
            <Button outline color="secondary" className="me-2">
              {t(Labels.actions)}
              <i className="bx bx-import ms-1"></i>
            </Button>
          </DropdownComponent.Toggle>
          <DropdownComponent.Menu>
            <div className="mb-0 p-2 overflow-auto bg-white" style={{ maxHeight: 295 }}>
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={importSettledInvoice}
              >
                {t(Labels.update_base_cost)}
              </Button>
            </div>
          </DropdownComponent.Menu>
        </DropdownComponent>
      <WithPermission action={ActionEntities.create} resource={ResourceEntities.baseCostEntity}>
        <Link {...createLinkEmbedParam('#')}>
          <Button className="btn btn-success" onClick={popupCreate}>
            <i className="bx bx-plus font-size-16 align-middle"></i>
            {t(Labels.create)}
          </Button>
        </Link>
      </WithPermission>
    </Fragment>
  )
}


export default FulfillmentInvoicesPage

