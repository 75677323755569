import { Labels } from "common/labels"
import Breadcrumb from "components/layout/Breadcrumb"
import RemoteTable, { createDeleteAction } from "components/table/RemoteTable"
import { ActionOption, RemoteTableSelectionData } from "components/table/SelectedRowAction"
import { FilterOption, SearchOption } from "components/table/TableFilter"
import { t } from "core/translations"
import { ArtworkActions } from "data/actions/artwork.action"
import { artworkQueryState, artworksSelector } from "data/atoms/artwork.atom"
import { AiFillEye } from "react-icons/ai"
import {FaWindowClose} from "react-icons/fa"
import {
  ImportModal,
  ImportType,
} from "pages/fulfillments/components/import-modal"
import { useEffect, useState } from "react"
import { ColumnDescription } from "react-bootstrap-table-next"
import { Link } from "react-router-dom"
import { Button, Container } from "reactstrap"
import { useRecoilRefresher_UNSTABLE, useSetRecoilState, useRecoilValueLoadable } from "recoil"
import { RouteNames } from "routes"
import { TooltipComponent } from "components/common/TooltipComponent"
import { BiLinkExternal } from "react-icons/bi"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { WithPermission } from "components/common/WithPermission"
import { createDocumentPageName } from "../../utils/utils";
import { ArtworkBulkCreatePage } from "./artwork-bulk-create.page"
import { portableModalState } from "data/atoms/app.atom"
import { BulkUpdateSelectPopup } from "components/modal/bulk-update-popup"
import { SaleChannelSelect } from "./components/sale-channel.select"
import { SaleChannelDto } from "data/services/sale-channel-service"
import { createLinkEmbedParam, createUrlEmbed } from "utils/embed"

const ArtworksPage = () => {
  const setPortableModal = useSetRecoilState(portableModalState)
  const artworksLoadable = useRecoilValueLoadable(artworksSelector)
  const [listArtworks, setListArtworks] = useState<any>([])
  const [artworksSelectedIds, setArtworksSelectedIds] = useState<any>([])

  useEffect(() => {
    if (artworksLoadable?.state == 'hasValue') {
      artworksLoadable?.contents && setListArtworks(artworksLoadable?.contents?.pageItems)
    }
  }, [artworksLoadable?.state])

  useEffect(() => { document.title = createDocumentPageName(t(Labels.artworks)) }, [])

  let columns: ColumnDescription[] = [
    {
      dataField: "sku",
      text: t(Labels.sku),
      //   eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <Link
              style={{ fontWeight: 500 }}
              {...createLinkEmbedParam(`${RouteNames.ARTWORKS}/${row.id}`)}
            >
              {" "}
              {`${row.sku}`}
            </Link>
          </>
        )
      },
    },
    {
      dataField: "title",
      text: t(Labels.title),
    },
    {
      dataField: "template",
      text: t(Labels.artwork_templates),
      headerStyle: { width: 200 },
      formatter: (cell: any, row: any) => {
        return `${row?.template?.name || ''}`
      }
    },
    {
      dataField: "categories",
      text: t(Labels.categories),
      headerStyle: { width: 200 },
      //   eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <span
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            {cell?.join ? cell.join(", ") : ""}
          </span>
        )
      },
    },
    {
      dataField: "tags",
      text: t(Labels.tags),
      headerStyle: { width: 150 },
      //   eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <span
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            {cell?.join ? cell.join(", ") : ""}
          </span>
        )
      },
    },
    {
      dataField: "owner",
      text: t(Labels.vendor),
      headerStyle: { width: 100 },
      formatter: (cell: any, row: any) => {
        return `${row?.owner?.name || row?.owner?.username || ''}`
      }
    },
    {
      dataField: "url",
      text: Labels.design,
      headerStyle: { width: 80 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
            <TooltipComponent tooltip={t(Labels.view_design)}>
          <div className="d-flex justify-content-center">
            <Link

              target="_blank"
              rel="noreferrer"
              to={{pathname: createUrlEmbed(row.url || "")}}
              className={row.url ? "" :"text-danger" }
            >
                {row.url ? <BiLinkExternal size="16" /> : <FaWindowClose /> }
            </Link>
          </div>
              </TooltipComponent>
        )
      },
    },
  ]

  let searchOptions: SearchOption[] = [
    {
      field: "sku",
      label: t(Labels.artwork_sku),
    },
    {
      field: "categories",
      label: t(Labels.categories),
    },
    {
      field: "tags",
      label: t(Labels.tags),
    },
  ]

  let filterOptions: FilterOption[] = [
    {
      name: "customize",
      label: t(Labels.customize),
      type: "radio",
      value: [
        { label: t(Labels.yes), value: "1" },
        { label: t(Labels.no), value: "0" },
      ],
      hidden: true,
    },
    {
      name: "url",
      label: t(Labels.design_url),
      type: "radio",
      value: [
        { label: t(Labels.yes), value: "1" },
        { label: t(Labels.no), value: "0" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
      hidden: true,
    },
  ]

  const refresh = useRecoilRefresher_UNSTABLE(artworksSelector)
  const [importType, setImportType] = useState<ImportType>(ImportType.NULL)
  const actionOptions: ActionOption[] = [
    createDeleteAction(ArtworkActions, ResourceEntities.artworkEntity),
    {
      name: 'edit_sale_channel',
      label: t(Labels.add_sale_channel),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.artworkEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.add_sale_channel),
          content: (
            <BulkUpdateSelectPopup
              placeholder={`${t(Labels.add_sale_channel)}...`}
              selectBuilder={(onChange) => (
                <SaleChannelSelect
                  isMulti={true}
                  getOptionLabel={(option: SaleChannelDto) => option.domain}
                  getOptionValue={(option: SaleChannelDto) => option.id}
                  onChange={onChange}
                />
              )}
              onSubmit={async (value: any) => {
                let publicationChannelIds = value?.map((v: any) => v?.id)
                if (publicationChannelIds.length) {
                  value && await ArtworkActions.updateSaleChannel(selectedIds as string[], publicationChannelIds)
                }
              }}
              onDone={refresh}
            />
          ),
        })
      },
    },
    {
      name: 'remove_sale_channel',
      label: t(Labels.remove_sale_channel),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.artworkEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.remove_sale_channel),
          content: (
            <BulkUpdateSelectPopup
              placeholder={`${t(Labels.remove_sale_channel)}...`}
              selectBuilder={(onChange) => (
                <SaleChannelSelect
                  isMulti={true}
                  getOptionLabel={(option: SaleChannelDto) => option.domain}
                  getOptionValue={(option: SaleChannelDto) => option.id}
                  onChange={onChange}
                />
              )}
              onSubmit={async (value: any) => {
                let publicationChannelIds = value?.map((v: any) => v?.id)
                if (publicationChannelIds.length) {
                  value && await ArtworkActions.removeSaleChannel(selectedIds as string[], publicationChannelIds)
                }
              }}
              onDone={refresh}
            />
          ),
        })
      }
    },
    {
      name: "edit_selected_artwork",
      label: t(Labels.edit_selected_artwork),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.artworkEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        if (selectedIds) {
          setArtworksSelectedIds(selectedIds as Array<string>)
        }
      }
    }
  ]

  const handleCloseBulkUpdate = () => {
    setArtworksSelectedIds([])
  }
  
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <ImportModal
            replaceable={true}
            type={importType}
            onClose={() => setImportType(ImportType.NULL)}
          />
          {
            artworksSelectedIds?.length
              ? <ArtworkBulkCreatePage ids={artworksSelectedIds} onClose={handleCloseBulkUpdate} />
              :
              <>
                <Breadcrumb title={t(Labels.artworks)}>
                  <WithPermission action={ActionEntities.create} resource={ResourceEntities.artworkEntity}>
                    <Link {...createLinkEmbedParam(`${RouteNames.ARTWORKS}/bulk-create`)}>
                      <Button
                        outline
                        color="primary"
                        className="me-2">
                        <i className="bx bx-plus font-size-16 align-middle"></i>
                        {t(Labels.bulk_create)}
                      </Button>
                    </Link>
                  </WithPermission>
                  <WithPermission action={ActionEntities.create} resource={ResourceEntities.artworkEntity}>
                    <Button
                      outline
                      color="secondary"
                      className="me-2"
                      onClick={() => {
                        setImportType(ImportType.ARTWORK)
                      }}
                    >
                      <i className="bx bx-import font-size-16 align-middle me-1"></i>
                      {t(Labels.import)}
                    </Button>
                  </WithPermission>
                  <WithPermission action={ActionEntities.create} resource={ResourceEntities.artworkEntity}>
                    <Link {...createLinkEmbedParam(`${RouteNames.ARTWORKS}/create`)}>
                      <Button type="button" className="btn btn-success">
                        <i className="bx bx-plus font-size-16 align-middle"></i>
                        {t(Labels.create_new)}
                      </Button>
                    </Link>
                  </WithPermission>
                </Breadcrumb>
                <RemoteTable
                  dataLoader={[artworksSelector, artworkQueryState]}
                  searchOptions={searchOptions}
                  filterOptions={filterOptions}
                  tabOptions={[]}
                  actionOptions={actionOptions}
                  searchHint={t(Labels.search_by_name)}
                  defaultSearchField={"name"}
                  keyField={"id"}
                  columns={columns}
                  refresh={refresh}
                  filterKey={'artworks'}
                />
              </>
          }
        </Container>
      </div>
    </>
  )
}

export default ArtworksPage
