import { FulfillVariantActions } from 'data/actions/fulfill-variation.action';
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { ProductTypeServices } from "../services/product-type.service";
import { isEmpty } from 'lodash';

export const productTypeIdState = atomFamily<QueryParams<any>, any>({
  key: "productTypeIdState",
  default: (id: any) => {
    return id;
  }
});

export const fullfillVariantList = atomFamily<QueryParams<any>, any>({
  key: 'fullfillVariantList',
  default: (id: any) => {
    return id;
  }
})

export const fullfillVariantListSelector = selectorFamily({
  key: 'fullfillVariantListSelector',
  get: (id: any) => async ({get}) => {
    let data = await FulfillVariantActions.searchById(id)
    return data
  }
})

export const productTypeIdSelector = selectorFamily({
  key: "productTypeIdSelector",
  get: (id: any) => async ({ get }) => {
    let productTypeId = get(productTypeIdState(id));

    if (+productTypeId > 0) {
      let data = await ProductTypeServices.getById(+productTypeId, { snoozeErrorMessage: false });
      return data;
    }

    return {};
  }
});

export const productTypesQueryState = atomFamily<QueryParams<any>, QueryParams<any>>({
  key: "productTypesQueryState",
  default: (params: any) => ({ ...defaultPageInfo, ...params })
});

export const productTypesSelector = selector({
  key: "productTypesSelector",
  get: async ({ get }) => {
    let query = get(productTypesQueryState(defaultPageInfo));
    let pageData = await ProductTypeServices.query(query, { snoozeErrorMessage: false });
    return pageData;
  }
});

export const suggestionVariantOptionKeySelector = selector({
  key: 'suggestionVariantOptionKeySelector',
  get: async ({ get }) => {
    let suggestionValues: string[] = await ProductTypeServices.getVariantOptionsSuggestion()
    return suggestionValues
  },
});