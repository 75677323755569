import React, { FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AsyncProps } from "react-select/async";
import { isEmpty } from "lodash";
import { createCustomSelectStyles } from "../bulk/BulkEditor";
import Select from "react-select";
import { useAsync } from "react-async";

export type SearchableSelectProps = { embedHeight?: number, blurOnSelect?: boolean, getSearchValue?: (option: any) => string } & AsyncProps<any, any, any>

const searchDebounce = (fn: Function, ms: number) => {
  let timer: any;
  return () => {
    if(timer) clearTimeout(timer);
    timer = setTimeout(() => fn(), ms)
  }
}

export const SearchableSelect = (props: SearchableSelectProps) => {
  const {
    onChange,
    value,
    styles,
    onInputChange,
    getOptionValue,
    getOptionLabel,
    getSearchValue,
    loadOptions,
    closeMenuOnSelect,
    onBlur,
    blurOnSelect,
    embedHeight,
    ...rest
  } = props

  const getInputText = (valueOption:any) =>{
    if(getSearchValue){
      return getTextSearchValue(valueOption)
    }
    if(getOptionLabel){
      return getTextOptionLabel(valueOption)
    }
    return valueOption?.label
  }

  const getTextSearchValue = (valueOption: any) => {
      return getSearchValue && getSearchValue(valueOption)
  }

  const getTextOptionLabel = (valueOption: any) => {
      return getOptionLabel && getOptionLabel(valueOption) || valueOption?.label
  }

  // const [text, setText] = useState(getInputText(value))
  const [text, setText] = useState(undefined)
  const [valueSelect, setValueSelect] = useState<any>({})
  const selectRef = useRef<any>();
  const [searchAtomic, setSearchAtomic] = useState('')
  const optionLoader = useAsync(useCallback(() => loadOptions && loadOptions(text || '', () => {}), [searchAtomic]));
  const noOptionsMessage = useMemo(() => (optionLoader.isLoading ? () => 'Loading…' : undefined), [optionLoader.isLoading]);
  const changeSearchText = useRef(searchDebounce(() => setSearchAtomic(Date.now() + ''), 100)).current
  const filterOption = useCallback(() => true, []);
  useEffect(() => changeSearchText(), [text])
  useEffect(() => handleChange(value), [value])

  const handleChange = (valueOption: any, submitChangedValue?: boolean, actionMeta?: any) => {
    if (!valueOption) { 
      setValueSelect(undefined)
      return 
    }
    setValueSelect(valueOption)
    if(submitChangedValue){
      setTimeout(() => setText( getTextOptionLabel(valueOption) ), 0)
    }
    submitChangedValue && onChange && onChange(valueOption, actionMeta)
  }

  const handleInputChange = (valueInput: any, actionMeta: any) => {
    if (
      actionMeta?.action === "input-change" ||
      actionMeta?.action === "set-value"
    ) {
      setText(valueInput)
      // TODO: fix request backend 2 lần
      onInputChange && onInputChange(valueInput || '', actionMeta)
    }
  }

  const handleFocus = () => {
    setText(getInputText(value))
  }

  // const customLoadOptions = _.throttle(loadOptions, 1000)
 
  const handleBlur = (e: FocusEvent<any>) => {
    setText(undefined)
    onBlur && onBlur(e)
  }

  return (
    <Select
      inputValue={text}
      value={isEmpty(valueSelect) ? undefined : valueSelect}
      openMenuOnFocus={true}
      menuPortalTarget={document.body}
      onInputChange={handleInputChange}
      onChange={(value: any) => handleChange(value, true)}
      ref={selectRef}
      onMenuClose={() => blurOnSelect && selectRef.current?.blur()}
      styles={{
        ...createCustomSelectStyles(embedHeight),
        ...(styles || {})
      }}
      blurInputOnSelect={true}
      getOptionValue = {getOptionValue}
      getOptionLabel ={getOptionLabel}
      onFocus={handleFocus}
      onBlur={handleBlur}
      options={optionLoader.value as any || []}
      isLoading={optionLoader.isLoading}
      filterOption={filterOption}
      noOptionsMessage={noOptionsMessage}
      { ...(embedHeight ? { closeMenuOnSelect: closeMenuOnSelect == undefined ? true : closeMenuOnSelect } : {})}
      { ...{ placeholder: 'Search...', ...rest }}
    />
  )
}
