import { Labels } from "common/labels"
import DropdownComponent from "components/dropdown/DropdownComponent"
import CheckboxGroup from "components/input/CheckboxGroup"
import { t } from "core/translations"
import { Component, useEffect, useState } from "react"
import { ColumnDescription } from "react-bootstrap-table-next"
import { MdStackedBarChart } from "react-icons/md"
import { Button, Card } from "reactstrap"

export interface customizeColumnsProps {
  customizeColumns?: ColumnDescription[]
  handleChangeCustomizeColumn: Function
  resetColumns: Function
}

export interface checkboxColumnType {
  value: string,
  label: string,
  hidden: boolean
}

export const CustomizeColumns = (props: customizeColumnsProps) => {
  const {customizeColumns, handleChangeCustomizeColumn, resetColumns} = props
  let [dropdownOpen, setDropdownOpen] = useState<boolean>();
  const [checkboxColumn, setCheckboxColumn] = useState<checkboxColumnType[]>([])
  let style = { padding: '1.5rem 1.5rem 1rem 1.5rem' }

  useEffect(() => {
    let tempCheckboxColumn: checkboxColumnType[] = []
    customizeColumns?.length 
    && customizeColumns.forEach((item: ColumnDescription) => {
      tempCheckboxColumn.push({
        value: item.dataField,
        label: item.text,
        hidden: item.hidden ? true : false
      })
    })
    setCheckboxColumn(tempCheckboxColumn)
  }, [customizeColumns])

  return (
      <DropdownComponent
        alignRight={true}
        opening={dropdownOpen}
        className="ms-1"
      >
        <DropdownComponent.Toggle>
          <button className="btn btn-outline-secondary" style={
            {
              borderLeft: "1px solid",
              borderRight: "1px solid",
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
            }
          }>
            <MdStackedBarChart />
            {t(Labels.columns)}
          </button>
        </DropdownComponent.Toggle>
        <DropdownComponent.Menu>
          <Card className="mb-1 overflow-auto" style={{ maxHeight: 250, ...style }}>
            <CheckboxGroup 
              name={'test'}
              items={checkboxColumn as any[]}
              checkedItems={checkboxColumn?.filter((c: any) => !c.hidden) as any[]}
              onChange={(name, value) => {
                handleChangeCustomizeColumn(value)
              }}
            />
            <div
              onClick={() => props.resetColumns()}
              style={{ cursor: "pointer" }}
              className="text-primary interactive--link w-fit">
              {t(Labels.reset_to_default)}
            </div>
          </Card>
        </DropdownComponent.Menu>
      </DropdownComponent>
  )
  
}