import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { SettingServices } from "data/services/setting-service";

export const SettingKeys = {
  ORDER: 'order',
  STORAGE: 'storage', 
  TRACKING: 'tracking',
  DESIGN_STORAGE: 'design-storage',
  WEB_ADMIN: 'web-admin-settings',
}

export const settingState = atomFamily<QueryParams<any>, any>({
  key: "settingState",
  default: (key: any) => {
    return key;
  }
});

export const settingSelector = selectorFamily({
  key: "settingSelector",
  get: (key: any) => async ({ get }) => {
    let idSetting = get(settingState(key));
    let data = await SettingServices.getById(idSetting as number, { snoozeErrorMessage: false });
    return data || {}
  }
});