import { Labels } from "common/labels";
import { WithPermission } from "components/common/WithPermission";
import RemoteTable, { defaultPageInfo } from "components/table/RemoteTable";
import { SearchOption } from "components/table/TableFilter";
import { t } from "core/translations";
import { Fragment, useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { Button, Container, FormGroup, Input, Label } from "reactstrap";
import Breadcrumb from "components/layout/Breadcrumb";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { useRecoilState, useSetRecoilState } from "recoil";
import { createDocumentPageName } from "utils/utils";
import { ImportModal, ImportType } from "pages/fulfillments/components/import-modal";
import { sourceQueryState, sourceSelector } from "data/atoms/source.atom";
import { portableModalState } from "data/atoms/app.atom";
import { ModalButtons, ModalContent } from "components/modal/portable-modal";
import { SourceServices } from "data/services/source.service";
import { Toasts } from "core/notification/notifications";
import { format } from "date-fns";
import { createLinkEmbedParam } from "utils/embed";

const SourcesPage = () => {
  const [importType, setImportType] = useState<ImportType>(ImportType.NULL)
  const setPortableModal = useSetRecoilState(portableModalState)
  const [queryState, setQueryState] = useRecoilState(sourceQueryState(defaultPageInfo))

  
  useEffect(() => { document.title = createDocumentPageName(t(Labels.users)) }, [])
  let columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: t(Labels.id),
      headerStyle: {minWidth: 100, width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Fragment>
          <Link style={{fontWeight: 500}} {...createLinkEmbedParam(`#`)}>{row.id}</Link>
        </Fragment>
      }
    },
    {
      dataField: 'userId',
      text: t(Labels.user_id),
      headerStyle: { minWidth: 150, width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Fragment>
          <Link style={{fontWeight: 500}} {...createLinkEmbedParam(`#`)}>{row.userId || row.user?.id}</Link>
        </Fragment>
      }
    },
    {
      dataField: 'username',
      text: t(Labels.username),
      headerStyle: { minWidth: 150, width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Fragment>
          <p className="mb-0">{row?.user?.username}</p>
        </Fragment>
      }
    },
    {
      dataField: 'source',
      text: t(Labels.utm_source),
      headerStyle: { minWidth: 200 },
    },
    {
      dataField: "updatedAt",
      text: t(Labels.last_modified),
      headerStyle: { width: 150 },
      formatter: (cell: any, row: any) => {
        return format(new Date(cell), "MMM dd, yyyy HH:mm:ss")
      },
    }
  ]
  
  let searchOptions: SearchOption[] = [
    {
      field: 'source',
      label: t(Labels.utm_source),
      filterBuilder: value => {
        return { contains: value.trim() }
      },
    },
    {
      field: 'userId',
      label: t(Labels.user_id),
      filterBuilder: value => {
        return { equalTo: value.trim() }
      },
    },
  ];

  const refreshData = () => {
    setQueryState((prev: any) => ({ ...prev }))
  }
  
  const handleCreate = () => {
    const onDone = () => {
      refreshData()
      setPortableModal({ open: false, title: '' })
    }
    setPortableModal({
      open: true,
      title: t(Labels.create_source),
      content: (
        <PopupCreateSource onDone={onDone} />
      )
    })
  }

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={t(Labels.utm_tracking)}
          >
            <TopBarButtonSource changeImportType={setImportType} handleCreate={handleCreate} />
          </Breadcrumb>
          <ImportModal type={importType} onClose={() => setImportType(ImportType.NULL)} onDone={refreshData} replaceable={true}/>
          <RemoteTable
            dataLoader={[sourceSelector, sourceQueryState]}
            searchOptions={searchOptions}
            filterOptions={[]}
            tabOptions={[]}
            keyField={'id'}
            columns={columns}
            filterKey={'source'}
          />
        </Container>
      </div>
    </Fragment>
  )
}

export default SourcesPage

export interface TopBarButtonSourceProps {
  changeImportType: Function
  handleCreate: Function
}

export const TopBarButtonSource = (props: TopBarButtonSourceProps) => {
  const { changeImportType, handleCreate } = props
  return (
    <Fragment>
      <WithPermission action={ActionEntities.create} resource={ResourceEntities.utmEntity}>
        <Button 
          outline 
          color="secondary" 
          className="me-2" 
          onClick={() => changeImportType(ImportType.SOURCE) }
        >
          {t(Labels.import)}
          <i className="bx bx-import ms-1"></i>
        </Button>
        <Button 
          className="btn btn-success" 
          onClick={() => handleCreate() }
        >
          <i className="bx bx-plus font-size-16 align-middle"></i>
          {t(Labels.create_new)}
        </Button>
      </WithPermission>
    </Fragment>
  )
}

interface propsPopupCreateSource {
  onDone: Function
}

interface dataCreateSource {username:string, source: string}

const PopupCreateSource = (props: propsPopupCreateSource) => {
  const [data, setData] = useState<dataCreateSource>({ username: "", source: "" })

  return (
    <>
      <ModalContent>
        <FormGroup className="mb-3">
          <Label>{Labels.username}</Label>
          <Input
            type={"text"}
            placeholder={Labels.username_hint}
            value={data.username}
            onChange={(e: any) => {
              setData((prev: dataCreateSource) => ({...prev, username: e.target.value}))
            }}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <Label>{Labels.utm_source}</Label>
          <Input
            type={"text"}
            placeholder={Labels.utm_source_hint}
            value={data.source}
            onChange={(e: any) => {
              setData((prev: dataCreateSource) => ({...prev, source: e.target.value}))
            }}
          />
        </FormGroup>
      </ModalContent>
      <ModalButtons
        confirm={async () => {
          if(!data.username){
            Toasts.error(t(Labels.username_required))
          }
          else if(!data.source){
            Toasts.error(t(Labels.utm_source_required))
          }
          else {
            const result = await SourceServices.handleCreateSource(data)
            if(result?.id) {
              Toasts.success(t(Labels.create_source_success))
            }
            props.onDone && props.onDone()
          }
        }}
        closeAfterConfirm={false}
      />
    </>
  )
}