import React, { useEffect } from "react";
import { Button, Container } from "reactstrap";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { SearchOption } from "../../components/table/TableFilter";
import { ActionOption } from "../../components/table/SelectedRowAction";
import { t } from "../../core/translations";
import Breadcrumb from "../../components/layout/Breadcrumb";
import RemoteTable from "../../components/table/RemoteTable";
import { fulfillServiceQueryState, fulfillServiceSelector } from "../../data/atoms/fulfill-service.atom";
import { Labels } from "common/labels";
import { createDocumentPageName } from "../../utils/utils";
import { RouteNames } from "routes";
import { createLinkEmbedParam } from "utils/embed";

export const FulfillServicesPage = () => {

  useEffect(() => { document.title = createDocumentPageName(t(Labels.fulfill_services)) }, [])

  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.FULFILL_SERVICES}/${row.id}`)}> {`#${row.id}`}</Link>
          {/* <Link style={{ fontWeight: 500 }} to={`#`}> {`${row.id}`}</Link> */}
        </>;
      }
    },
    {
      dataField: "code",
      text: t(Labels.code),
      headerStyle: { width: 300 }
    },
    {
      dataField: "name",
      text: t(Labels.name),
      headerStyle: { width: 300 }
    },
    {
      dataField: "skuFormat",
      text: t(Labels.custom_sku_position),
      formatter: (cell: any, row: any) => {
        return Object.keys(cell).map(key => `${key}: ${cell[key]}`).join(', ') || ''
      }
    },
  ];

  let searchOptions: SearchOption[] = [
    {
      field: "name",
      label: t(Labels.name)
    }
  ];

  const actionOptions: ActionOption[] = []

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={t(Labels.fulfill_services)}
          >
            <Link {...createLinkEmbedParam(`${RouteNames.FULFILL_SERVICES}/create`)}>
              <Button
                type="button"
                className="btn btn-success"
              >
              <i className="bx bx-plus font-size-16 align-middle"></i>
                {t(Labels.create)}
              </Button>
            </Link>
          </Breadcrumb>
          <RemoteTable
            dataLoader={[fulfillServiceSelector, fulfillServiceQueryState]}
            searchOptions={searchOptions}
            filterOptions={[]}
            tabOptions={[]}
            actionOptions={actionOptions}
            searchHint={t(Labels.search_by_name)}
            defaultSearchField={"name"}
            keyField={"id"}
            columns={columns}
            filterKey={'fulfillServices'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}
