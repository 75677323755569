import { CrudService } from "./crud.service";
import { apiBaseUrls } from "data/rest-api-config";

export interface AccountDto {
  username?: string
  email?: string
  password?: string
  name?: string
  firstName?: string
  lastName?: string
  picture?: string
  options?: {}
  profile?: {}
  active?: false
  verified?: false
  lang?: string
  roles?: []
  grantedTenants?: string[]
}

export const AccountServiceApiPath = {
  BASE: "/users",
  WITH_ID: "/users/{id}",
  QUERY: "/users/query",
  BULK: "/users/bulk",
  FORGOT_PASSWORD: `/user/forgot-password`,
  RESET_PASSWORD: `/user/reset-password`,
  CHANGE_PASSWORD: `/users/change-password`,
}

export class AccountService extends CrudService<any, any, any> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: AccountServiceApiPath.WITH_ID,
        updateById: AccountServiceApiPath.WITH_ID,
        deleteById: AccountServiceApiPath.WITH_ID,
        query: AccountServiceApiPath.QUERY,
        base: AccountServiceApiPath.BASE,
        deleteMany: AccountServiceApiPath.BULK,
        updateMany: AccountServiceApiPath.BULK,
      },
    })
  }

  async changePassword(params: any) {
    const response = await this.restApi.post(
      AccountServiceApiPath.CHANGE_PASSWORD,{ data:params }
    )

    return this.returnDataOrThrow(response)
  }

  async forgetPassword(params: any) {
    return await this.restApi.get(AccountServiceApiPath.FORGOT_PASSWORD, { data: params })
  }

  async resetPassword(params: any) {
    return await this.restApi.post(AccountServiceApiPath.RESET_PASSWORD, { data: params })
  }
}

export const AccountServices = new AccountService()
