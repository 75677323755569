import React, { Fragment, useEffect, useState } from 'react'
import { SmallContainer } from 'components/layout/SmallContainer'
import Breadcrumb from 'components/layout/Breadcrumb'
import { t } from 'core/translations'
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap'
import { FormikForm, FormValidation } from 'components/form/FormikForm'
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { settingSelector, SettingKeys } from 'data/atoms/setting.atom'
import { FormikHelpers, useFormik } from 'formik'
import { LoadableComponent } from 'components/common/LoadableComponent'
import { FormikInput } from 'components/form/FormikInput'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { Labels } from 'common/labels'
import { isEmpty, isPlainObject, update } from 'lodash'
import { StorageSettingDto, UpdateSettingDto, UpdateStorageSettingDto } from 'data/services/setting-service'
import { SettingActions } from "../../data/actions/setting.action";
import { Toasts } from "../../core/notification/notifications";
import { FormLayout, FormLayoutMain } from 'components/layout/FormLayout'
import { storageSettingSchema } from './validations/storage-setting.validation'
import { Messages } from 'common/messages'
import { WithPermission } from 'components/common/WithPermission'
import { ActionEntities, ResourceEntities } from 'types/permission-type'
import { createDocumentPageName } from "../../utils/utils";

const storageServiceValues = {
  GOOGLE_DRIVE: "google-drive",
  // DIGITAL_OCEAN_SPACES: "do-spaces"
};


const storageServiceName: Record<string, string> = {
  [storageServiceValues.GOOGLE_DRIVE]: "Google Drive",
  // [storageServiceValues.DIGITAL_OCEAN_SPACES]: "DigitalOcean Spaces"
};

const storageServiceOptions = [
  { label: storageServiceName[storageServiceValues.GOOGLE_DRIVE], value: storageServiceValues.GOOGLE_DRIVE },
  // {
  //   label: storageServiceName[storageServiceValues.DIGITAL_OCEAN_SPACES],
  //   value: storageServiceValues.DIGITAL_OCEAN_SPACES
  // }
];

export const StorageSettingPage = () => {
  const dataLoadable = useRecoilValueLoadable(settingSelector(SettingKeys.STORAGE));
  const isEditing = true;
  const [setting, setSetting] = useState<any>({});
  const validation = useFormik({
    initialValues: setting?.value || {},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: storageSettingSchema,
    onSubmit: (value, helpers) => handleSubmit(value, helpers)
  })

  useEffect(() => {
    dataLoadable.state === 'hasValue' && setSetting(dataLoadable?.contents)
  }, [dataLoadable.state])

    // TODO remove fakeRefreshDataLoadable 
    const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(settingSelector(SettingKeys.STORAGE))
    useEffect(() => {
      ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
    },[SettingKeys.STORAGE])

  useEffect(() => { document.title = createDocumentPageName(t(Labels.storage_setting)) }, [])

  useEffect(() => {
    // convert object credentials to JSON
    if (isPlainObject(validation.values?.config?.credentials)) {
      validation.setFieldValue('config.credentials', JSON.stringify(validation.values?.config?.credentials, null, 2))
    }
  }, [validation.values?.config?.credentials])

  const handleSubmit = async (values: StorageSettingDto, helpers: FormikHelpers<any>) => {
    let data = {
      ...values,
      config: {
        ...values.config, credentials: JSON.parse(values?.config?.credentials)
      }
    }

    try {
      helpers.setErrors({})
      helpers.setSubmitting(true);

      let updateData: UpdateStorageSettingDto = {
        value: data
      };

      if (isEditing) {
        let result = await SettingActions.updateStorageSettingByKey(SettingKeys.STORAGE, updateData);
        if (result?.id) {
          setSetting((prev: any) => ({
            ...prev,
            ...result.value
          }));
          // TODO optimize code not using setTimeout
          setTimeout(() => Toasts.success(t(Messages.update_setting_successfully)), 100);
        }
      }
    } catch (e) {
      // Show toast error
      Toasts.error(t(Messages.your_request_execute_unsuccessfully));
    } finally {
      helpers.setSubmitting(false);
    }
  }

  return (
    <Fragment>
      <div className="page-content">
        <SmallContainer>
          <Breadcrumb
            title={`${Labels.edit} ${t(Labels.storage_setting)}`}
          >
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.settingEntity}>
              <Button
                color='success'
                onClick={() => validation.submitForm()}
              >
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </Button>
            </WithPermission>
          </Breadcrumb>
          <Card>
            <CardBody>
              <LoadableComponent loadable={dataLoadable} >
                <FormikForm validation={validation}>
                  <FormLayout>
                    <FormLayoutMain>
                      <StorageSettingForm validation={validation} />
                    </FormLayoutMain>
                  </FormLayout>
                </FormikForm>
              </LoadableComponent>
            </CardBody>
          </Card>
        </SmallContainer>
      </div>
    </Fragment>
  )
}

export interface StorageSettingFormProps {
  validation: FormValidation
}

export const StorageSettingForm = (props: StorageSettingFormProps) => {
  const { validation } = props

  return (
    <Fragment>
      {/*<Form>*/}
        <Row className='mb-3'>
          <FormikInput
            validation={validation}
            name={'type'}
            label={t(Labels.type)}
            customInput={({ handleChange, handleBlur, errorData }) => (
              <Select
                isSearchable={true}
                options={storageServiceOptions}
                value={storageServiceOptions.filter(option => option.value === validation?.values?.type)}
                getOptionLabel={(option: any) => t(option.label)}
                getOptionValue={(option: any) => option.value}
                onBlur={handleBlur}
                onChange={(value: any) => handleChange(value.value)}
                styles={errorData.reactSelectErrorStyle}
              />
            )}
          />
        </Row>
        <Row className='mb-3'>
          <FormikInput
            validation={validation}
            label={t(Labels.credentials)}
            name={'credentials'}
            type={'textarea'}
            rows={6}
            value={validation.values?.config?.credentials}
            onChange={(values: any) => {
              validation.setFieldValue('config.credentials', values)
            }}
          />
        </Row>
        <Row className='mb-3'>
          <FormikInput
            label={t(Labels.upload_drive_id)}
            type='text'
            name={'driveId'}
            placeholder={t(Labels.upload_drive_id_hint)}
            validation={validation}
            value={validation?.values?.config?.driveId}
            onChange={(value: any) => validation.setFieldValue('config.driveId', value)}
          />
        </Row>
        <Row className='mb-3'>
          <FormikInput
            label={t(Labels.upload_folder_name)}
            type='text'
            name={'parentFolder'}
            placeholder={t(Labels.folder_name_hint)}
            validation={validation}
            value={validation?.values?.config?.parentFolder}
            onChange={(value: any) => validation.setFieldValue('config.parentFolder', value)}
          />
        </Row>
        <Row className='mb-3'>
          <FormikInput
            validation={validation}
            label={t(Labels.read_only_drive_id)}
            name={'readOnlyDriveIds'}
            customInput={({ handleChange, handleBlur, errorData }) => (
              <Creatable
                isMulti={true}
                formatCreateLabel={(value) => `${t(Labels.create)} '${value}'`}
                placeholder={t(Labels.read_only_drive_id_hint)}
                value={validation?.values?.config?.readOnlyDriveIds?.map((item: string) => ({ value: item }))}
                getOptionLabel={(option: any) => t(option.label || option.value)}
                getOptionValue={(option: any) => option.value}
                onBlur={handleBlur}
                onChange={(values: any) => validation.setFieldValue('config.readOnlyDriveIds', values.map((item: any) => item.value))}
                styles={errorData.reactSelectErrorStyle}
              />
            )}
          />
        </Row>
      {/*</Form>*/}
    </Fragment>
  )
}