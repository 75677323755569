import qs from "qs";
import axios, { AxiosRequestConfig } from "axios";
import { HttpErrorCode } from "./http-error";
import { Toasts } from "../notification/notifications";
import { t } from "../translations";
import { AppErrorCode, appEventEmitter, EventName } from "../event/app-event.emitter";
import { HttpStatus } from "./http-status";
import { Messages } from "../../common/messages";
import { ApiQuery, QueryBuilder, QueryParams } from "./query-builder";
import { RestApiErrors } from "./rest-api.error";
import { Storages } from "utils/storages";
import { omit } from "lodash";
import { isNoneTenantApiPath } from "data/rest-api-config";
import { Logger } from "../logger";
import { Global } from "../../common/global";
import { RouteNames } from "../../routes";
// import { noneTenantApiPaths } from "data/rest-api-config";

interface ErrorHandlerOptions {
  skipHttpStatuses?: any[],
  skipErrorCodes?: HttpErrorCode[],
  snoozeErrorMessage?: boolean
  snoozeAllErrorMessage?: boolean
}

export interface RestApiOptions extends ErrorHandlerOptions {
  baseUrl?: string,
  bearerLocalStorageKey?: string,
  getAuthorization?: () => Promise<string>
}

interface RestApiError {
  code: string | number,
  message: string,
  details?: any
}

export interface RestApiResponse {
  status: string | number,
  statusText?: string,
  error?: RestApiError,
  message?: string,
  data?: any
}

export interface RequestOptions extends AxiosRequestConfig, RestApiOptions {}

const defaultErrorMessageByCode: Record<string, string> = {
  ['er_duplicate_entry']: Messages.er_duplicate_entry
}

const errorMessagesHttp: Array<number> = [
  HttpStatus.FORBIDDEN,
  HttpStatus.INTERNAL_SERVER_ERROR,
  HttpStatus.BAD_REQUEST,
  HttpStatus.NOT_FOUND,
]

class RestApi {
  options: RestApiOptions;

  constructor(options: RestApiOptions) {
    this.options = {
      bearerLocalStorageKey: "token",
      ...options
    };
  }

  buildApiUrl(path: string, params: any) {
    let url = `${this.options.baseUrl}${path}`;
    if (params) {
      const parts = url.split(/{(.*?)}/g).map((v) => {
        const replaced = v.replace(/{/g, "");
        if (params instanceof FormData) {
          return params.get(replaced) || replaced;
        }
        return params[replaced] || replaced;
      });
      return parts.join("");
    }

    return url;
  }

  async getAuthorization(isEmbed: boolean) {
    let authorization = null
    if(isEmbed) {
      const embedToken = new URLSearchParams(window.location.search).get('embedToken')
      authorization = `Bearer ${embedToken}`;
    } else {
      authorization = this.options.getAuthorization && await this.options.getAuthorization();
      if (!authorization) {
        let bearerToken = Storages.getLocalJson(this.options.bearerLocalStorageKey || "token") || "";
        authorization = `Bearer ${bearerToken}`;
      }
    }

    return authorization;
  }

  async request<T>(apiRelativePath: string, options: RequestOptions = { ...this.options }) {
    try {

      let responseData: RestApiResponse;
      let queryParam = options.method?.toLowerCase() === "get" ? `?${qs.stringify(options?.data || {})}` : "";
      let url = `${this.buildApiUrl(apiRelativePath, options?.params)}${queryParam}`;

      const isEmbed = window && window.location.search.includes('embedToken')
      if(isEmbed) {
        let embedUrl = new URL(url);
        const searchParams = embedUrl.searchParams
        searchParams.append('embed', '1')
        url = `${embedUrl.origin}${embedUrl.pathname}?${searchParams.toString()}`
      }

      let tenant = Global.tenant || Storages.getLocalJson("tenant")
      let team = Global.team || Storages.getLocalJson("team")
      let user = Global.user || Storages.getLocalJson("user")
      let tenantId = tenant?.id;
      let teamId = team?.id;

      if(!isEmbed) {
        if(!tenantId && !isNoneTenantApiPath(apiRelativePath)){
          window.location.replace(RouteNames.SIGN_IN);
        }
        const isInvalidTeam = !teamId && !isNoneTenantApiPath(apiRelativePath)
        if(isInvalidTeam && user?.teamId) {
          if(!options.snoozeErrorMessage){
            Toasts.error(t(Messages.invalid_team))
          }
          window.location.replace(RouteNames.SIGN_IN);
        }
      }

      options.params = {}
      options.headers = {
        "Authorization": await this.getAuthorization(isEmbed),
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        ...(tenantId ? {"grcommerce-tenant-id": `${tenantId}`} : {}),
        ...(teamId ? {"grcommerce-team-id": `${teamId}`} : {}),
        ...(options.headers || {})
      };

      let res = await axios(url, options);
      return this.handleOkResponse(res.data, options);
    } catch (e) {
      return this.onError(e, options);
    }
  }

  async queryPaging<T>(apiPath: string, pagingQuery: QueryParams<T>, options?: RequestOptions) {
    if (!pagingQuery.hasOwnProperty('filter') && !pagingQuery.initial) {
      return {}
    } else {
      pagingQuery = omit(pagingQuery, 'initial')
    }
    let query = QueryBuilder.createQuery<T>(pagingQuery?.filter, pagingQuery);
    return this.query(apiPath, query, { skipErrorCodes: ['network_error'], ...options });
  }

  async query<T>(apiPath: string, query: ApiQuery<T>, options?: RequestOptions) {
    return this.post(apiPath, { ...(options || {}), data: query });
  }

  async get(apiPath: string, options: RequestOptions) {
    return this.request(apiPath, { method: "get", ...this.options, ...options });
  }

  async post(apiPath: string, options: RequestOptions) {
    return this.request(apiPath, { method: "post", ...this.options, ...options });
  }

  async put(apiPath: string, options: RequestOptions) {
    return this.request(apiPath, { method: "put", ...this.options, ...options });
  }

  async patch(apiPath: string, options: RequestOptions) {
    return this.request(apiPath, { method: "patch", ...this.options, ...options });
  }

  async delete(apiPath: string, options: RequestOptions) {
    return this.request(apiPath, { method: "delete", ...this.options, ...options });
  }

  onError(e: any, options: RequestOptions = {}) {
    let response = e.response;
    let error = response?.data?.error;
    let status = response?.data?.status;

    if (e.message.includes("Network Error")) {
      status = 0;
      error = { code: "network_error", message: "Cannot send request to server...", e };
    } else {
      error = error || { code: "unknown_error", message: "Something went wrong !", details: e };
    }

    return this.handleResponse({ status, error }, options);
  }

  handleResponse(response: RestApiResponse, options: RequestOptions) {
    if (response.message) Toasts.info(response.message);

    let status = +response.status;
    if (status >= 200 && status < 300) {
      return this.handleOkResponse(response, options);
    } else {
      if (response?.error?.code == "network_error") {
        if(!options.snoozeAllErrorMessage && !options?.skipErrorCodes?.includes('network_error')) {
          Toasts.error(t(Messages.network_error));
        }
        return { error: { code: "network_error", message: t(RestApiErrors.network_error) } };
      } else if (response.status == 401 || `${response?.error?.code}` == "401") {
        appEventEmitter.emit(EventName.ERROR, { status: HttpStatus.UNAUTHORIZED });
        return { error: { code: "network_error", message: t(RestApiErrors.unauthorized) } };
      } else {
        return this.handleOtherErrorResponse(response, options);
      }
    }
  }

  handleOtherErrorResponse(response: RestApiResponse, options: RequestOptions) {
    if (options?.skipHttpStatuses?.includes(response.status)) return;

    const { result, errorMessage } = this.parseErrorResponse(response, options, response.status as number)
    errorMessage && Toasts.error(errorMessage)
    return result;
  }
  
  parseErrorResponse(response: RestApiResponse, options: RequestOptions, code: number) {
    let result: RestApiResponse | null = response;
    let { error } = response;
    let message: string = error?.message || "";
    let { snoozeErrorMessage, snoozeAllErrorMessage } = options || {};
    switch (code) {
      case HttpStatus.FORBIDDEN:
        appEventEmitter.emit(EventName.ERROR, { handled: true, status: HttpStatus.FORBIDDEN });
        message = message || t(Messages.forbidden);
        snoozeErrorMessage = snoozeAllErrorMessage || false;
        break;
      case HttpStatus.INTERNAL_SERVER_ERROR:
        appEventEmitter.emit(EventName.ERROR, { handled: true, status: HttpStatus.INTERNAL_SERVER_ERROR });
        message = message || t(Messages.internal_server_error);
        result = null;
        snoozeErrorMessage = snoozeAllErrorMessage || false;
        break;
      case HttpStatus.NOT_FOUND:
        appEventEmitter.emit(EventName.ERROR, { handled: true, status: HttpStatus.NOT_FOUND });
        result = null;
        message = t(Messages.not_found);
        // snoozeErrorMessage = false;
        break;
      case HttpStatus.SERVICE_UNAVAILABLE:
        appEventEmitter.emit(EventName.ERROR, { status: HttpStatus.SERVICE_UNAVAILABLE });
        result = null;
        message = t(Messages.unknown_error);
        break;
      case HttpStatus.BAD_REQUEST:
        // Auto build validation error message
        message = t(Messages.bad_request);
        break;

      // TODO add more status code

      default:
        if (!snoozeErrorMessage) {
          appEventEmitter.emit(EventName.ERROR, { handled: true, status: AppErrorCode.UNKNOWN, error: response.error });
        }
        message = message || t(Messages.unknown_error);
    }

    let errorMessage: string = ''
    if (!snoozeErrorMessage && message) {
      errorMessage = message;
    }

    return { result, errorMessage }
  }

  handleOkResponse(response?: RestApiResponse, options?: RequestOptions) {
    if (response?.error) {
      if (options?.skipErrorCodes?.includes(response?.error?.code)) return;
      if (!options?.snoozeErrorMessage) {
        let message: string = response?.error?.message
        if(!message) {
          if(response?.error?.code) {
            let isDefaultHandleHttpErrorCode = errorMessagesHttp.find((e: number) => e == response?.error?.code)
            if (isDefaultHandleHttpErrorCode) {
              message = this.parseErrorResponse(response, options as RequestOptions, isDefaultHandleHttpErrorCode).errorMessage
            }

            if(!message) {
              message = defaultErrorMessageByCode[response?.error?.code] || t(Messages.server_unknown_error, response?.error)
            }
          } else {
            message = Messages.unknown_error
          }
        }
        Toasts.error(message);
      }
    }

    return response;
  }
}

export default RestApi;
