import { Labels } from "common/labels"
import { CloseModalButtons, ModalButtons, ModalContent } from "components/modal/portable-modal"
import { t } from "core/translations"
import { Link } from "react-router-dom"
import { Row } from "reactstrap"
import { Toasts } from "../../../core/notification/notifications"
import { Messages } from "../../../common/messages"
import { BiLinkExternal, BiStop } from "react-icons/bi"
import { BulkActionResult, BulkActionResultDetail, FulfillmentOrderResultProps } from "./fulfillment-order-result"
import { createUrlEmbed } from "utils/embed"

interface ResultDetailComponentProps {
  ids?: string[]
  linkViewDetail: string
}

export const ResultDetailComponent = (props: ResultDetailComponentProps) => {
  const { linkViewDetail } = props
  const onClickCopy = (e: any) => {
    e.preventDefault()
    navigator.clipboard.writeText(props.ids?.join(",") || "")
    Toasts.info(t(Messages.the_ids_is_copied_to_clipboard))
  }

  return (
    <span className="ms-2 d-flex">
      {props.ids?.length ? (
        <>
          <a href="#" onClick={onClickCopy}>
            {Labels.click_to_copy_ids}
          </a>
          <span className="mx-1 fw-medium">or</span>
          <a target="_blank" rel="noreferrer" href={createUrlEmbed(`${linkViewDetail}?ids=${props.ids?.join(",")}`)}>
            {Labels.view_details}
            <BiLinkExternal style={{ marginLeft: 2 }} size={12} />
          </a>
        </>
      ) : null}
    </span>
  )
}

export interface resultModuleProps {
  data: BulkActionResult
  linkViewDetail: string
  onClose?: any
}

const ResultModule = (props: resultModuleProps) => {
  const { data, linkViewDetail, onClose } = props
  return (
    <>
      <ModalContent>
        <Row>
          {
            <div className="d-flex flex-column">
              <div className="mb-2 me-2 d-flex">
                <p className="fw-bold">{t(Labels.total) + ` (${data?.all?.length || 0}): `}</p>
                <ResultDetailComponent linkViewDetail={linkViewDetail} ids={data?.all} />
              </div>
              <div className="mb-2 me-2 d-flex">
                <p className="fw-bold">{t(Labels.success) + ` (${data?.success?.length || 0}): `}</p>
                <ResultDetailComponent linkViewDetail={linkViewDetail} ids={data?.success} />
              </div>
              <div className="mb-2 me-2 d-flex">
                <p className="fw-bold">{t(Labels.not_found) + ` (${data?.notFound?.length || 0}): `}</p>
                <ResultDetailComponent linkViewDetail={linkViewDetail} ids={data?.notFound} />
              </div>
              <div className="mb-2 me-2 d-flex">
                <p className="fw-bold">{t(Labels.skipped) + ` (${data?.skipped?.length || 0}): `}</p>
                <ResultDetailComponent linkViewDetail={linkViewDetail} ids={data?.skipped} />
              </div>
              <div className="mb-2">
                <p className="fw-bold mb-1">{t(Labels.failed) + ` (${data?.failed?.length || 0}): `} </p>
                {data?.failedDetails &&
                  data?.failedDetails?.map((item: BulkActionResultDetail, index: number) => (
                    <div className="ms-4 my-2 d-flex" key={index + (item?.message || "")}>
                      <div className="d-flex align-items-center">
                        <BiStop size={10} />
                      </div>
                      <span className="ms-1 fw-bold">{`${item?.message || ""} (${item?.ids?.length}): `}</span>
                      <ResultDetailComponent linkViewDetail={linkViewDetail} ids={item?.ids} />
                    </div>
                  ))}
              </div>
            </div>
          }
        </Row>
        <CloseModalButtons cancel={onClose} />
      </ModalContent>
    </>
  )
}

export default ResultModule
