import { resourceGroup } from 'types/permission-type';
import { permissionsSelector } from 'data/atoms/auth.atom';
import { useRecoilValue } from 'recoil';
import { Keys } from 'common/keys';


export function usePermission(action: string, resource: string) {
  const roles = useRecoilValue(permissionsSelector)
  return _checkPermission({ action, resource, roles })
}

export function withPermission(action: string, resource: string, cb?: Function) {
  return _checkPermission({ action, resource }, cb)
}

export interface CheckPermissionParams {
  action: string,
  resource: string,
  roles?: any
}

function _checkPermission(params: CheckPermissionParams, cb?: Function) {
  const { action, resource, roles } = params
  let allow = false
  let CheckRoles = roles
  
  if (!roles) {
    if (JSON.parse(localStorage.getItem(Keys.roles) as string) || '') {
      CheckRoles = JSON.parse(localStorage.getItem(Keys.roles) as string) || ''
    } else {
      return false
    }
  }

  let arrayResource: Array<string> = []
  if (action === 'any') {
    Object.keys(CheckRoles).forEach((role: any) => {
      if (CheckRoles[role]?.includes('*')) {
        allow = true
      } else {
        arrayResource = arrayResource?.concat(CheckRoles[role])
        if (CheckRoles[role]?.includes(resource)) {
          allow = true
        }
      }
    })
    if (arrayResource?.length && allow == false) {
      allow = arrayResource?.some((item: any) => resourceGroup[resource]?.includes(item))
    }
  } else {
    allow = CheckRoles[action]?.includes('*')
      || CheckRoles[action]?.includes(resource) 
      || CheckRoles['*']?.includes('*') 
      || CheckRoles['*']?.includes(resource)
  }

  if(cb) {
    if (allow) {
      return cb()
    } else {
      return new Promise((resolve) => resolve([]))
    }
  } else {
    return allow
  }
}