import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "components/layout/Breadcrumb";
import { t } from "../../core/translations";
import { Badge, Card, CardBody, CardHeader, CardLink, CardTitle, Col, Container, Input, Label, Row } from "reactstrap";
import { LoadableComponent } from "components/common/LoadableComponent";
import { FormikForm, FormValidation } from "components/form/FormikForm";
import { FormikInput } from "components/form/FormikInput";
import { FormLayout, FormLayoutMain } from "components/layout/FormLayout";
import { Loadable, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable } from "recoil";
import { artworkSelector, customilyTemplateNameSelector } from "data/atoms/artwork.atom";
import { productBySkuSelector } from "data/atoms/product.atom";
import { FormikHelpers, useFormik } from "formik";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { Labels } from "common/labels";
import { isEmpty, omit, pick } from "lodash";
import { ArtworkDto } from "data/services/artwork.service";
import { Forms } from "utils/forms";
import { ArtworkActions } from "data/actions/artwork.action";
import { Toasts } from "core/notification/notifications";
import { RouteNames } from "routes";
import { Link, useHistory, useParams } from "react-router-dom";
import { artworkSchema } from "./validations/artwork.validation";
import ActionButton from "components/input/ActionButton";
import { SearchableSelect } from "components/input/SearchableSelect";
import { ArtworkTemplateActions } from "data/actions/artwork-template.action";
import googleDriveImage from "../../assets/images/brands/google-drive.jpg";
import { BiLinkExternal } from "react-icons/bi";
import { ProductTypeActions } from "data/actions/product-type.action";
import { createCustomSelectStyles, ROW_HEIGHT } from "components/bulk/BulkEditor";
import DataGrid, { Column, CopyEvent, FillEvent, PasteEvent, TextEditor } from "react-data-grid";
import { ProductStatus, ProductStatusColor, ProductStatusName, ProductStatusOptions, PublishStatusColor, PublishStatusName, PublishStatusOptions } from "./types/product-status.type";
import { ArtworkHelper } from "./helpers/artwork.helper";
import { SaleChannelDto } from "data/services/sale-channel-service";
import { SaleChannelSelect } from "./components/sale-channel.select";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { OptionalLabel } from "components/common/OptionalLabel";
import { withPermission } from "common/hooks/use-permission";
import { Messages } from "common/messages";
import AsyncSelect from "react-select/async";
import { preprocessPage } from "utils/utils";
import { createLinkEmbedParam } from "utils/embed";

const storageServiceValues = {
  GOOGLE_DRIVE: "google-drive",
  DIGITAL_OCEAN_SPACES: "do-spaces",
  DIRECT: "direct"
};

const storageServiceName: Record<string, string> = {
  [storageServiceValues.GOOGLE_DRIVE]: "Google Drive",
  [storageServiceValues.DIGITAL_OCEAN_SPACES]: "DigitalOcean Spaces",
  [storageServiceValues.DIRECT]: "Direct Url"
};

const storageServiceOptions = [
  { label: storageServiceName[storageServiceValues.GOOGLE_DRIVE], value: storageServiceValues.GOOGLE_DRIVE },
  {
    label: storageServiceName[storageServiceValues.DIGITAL_OCEAN_SPACES],
    value: storageServiceValues.DIGITAL_OCEAN_SPACES
  },
  {
    label: storageServiceName[storageServiceValues.DIRECT],
    value: storageServiceValues.DIRECT
  }
];

export const ArtworkPage = () => {
  const history = useHistory();
  const params = useParams<Record<string, any>>();
  const isEditing = +params.id > 0;
  const [artwork, setArtwork] = useState<any>({});
  const dataLoadable = useRecoilValueLoadable(artworkSelector(params.id));
  const productLoad = useRecoilValueLoadable(productBySkuSelector(
    withPermission(ActionEntities.read, ResourceEntities.productEntity) ? artwork?.sku : null
  ))

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.artwork), model: artwork, primaryProperty: 'sku'})
  }, [artwork])

  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(artworkSelector(params.id));
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable();
  }, [params.id]);

  useEffect(() => {
    if (dataLoadable.state == "hasValue") {
      setArtwork((prevState: any) => ({
        ...(prevState || {}),
        ...(dataLoadable.contents),
        products: productLoad.contents || []
      }));
    }
  }, [dataLoadable.state]);

  useEffect(() => {
    if(productLoad.state == "hasValue"){
      setArtwork((prevState: any) => ({
        ...(prevState || {}),
        products: productLoad.contents || []
      }));
    }
  }, [productLoad.state])


  const validation = useFormik({
    initialValues: artwork,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: artworkSchema,
    onSubmit: (values, helpers) => handleSubmit(values, helpers)
  });

  const handleSubmit = async (values: ArtworkDto, helpers: FormikHelpers<any>) => {
    let changedData = values // isEditing ? Forms.getChangedValues(values, artwork) : values;

    if (!validation.values.customize) {
      changedData = omit(changedData, "customOptions");  // remove customOptions key if customize = false or customOptions is undefined
    }

    if(changedData.productTypes) {
      changedData.productTypes = changedData.productTypes.map((productType: any) => pick(productType, 'id'))
    }

    try {
      helpers.setErrors({});
      helpers.setSubmitting(true);

      let savedData = await ArtworkActions.save(params.id, changedData);
      if (savedData?.id) {
        setArtwork((current: any) => ({
          ...current,
          ...savedData
        }));
        Toasts.success(t(Messages.save_artwork_successfully));

        // Optimize not using setTimeout
        !isEditing && setTimeout(() => {
          history.replace(RouteNames.ARTWORKS_DETAIL.replace(":id", savedData.id));
        }, 100);
      }
    } catch (e) {
      // Show toast error
      Toasts.error(t(Messages.your_request_execute_unsuccessfully));
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumb
            goBack={RouteNames.ARTWORKS}
            title={`${isEditing ? t(Labels.edit) : t(Labels.create)} ${t(Labels.artwork)}`.trim()}
          >
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.artworkEntity}>
              <ActionButton color="success"
                            onClick={() => validation.submitForm()}
                            
              >
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable}>
            <FormikForm validation={validation}>
              <FormLayout>
                <FormLayoutMain>
                  <ArtworkPageForm validation={validation} />
                </FormLayoutMain>
              </FormLayout>
            </FormikForm>
          </LoadableComponent>
        </Container>
      </div>
    </Fragment>
  );
};

export interface ArtworkProps {
  validation: FormValidation,
}

export interface CustomOption {
  name: string,
  defaultValue?: string
  keys?: any
}

export const ArtworkPageForm = (props: ArtworkProps) => {
  const { validation } = props;

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle>{t(Labels.artwork_info)}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Label className="col-sm-3 col-form-label">{t(Labels.sku)}</Label>
            <Col sm={9}>
              <FormikInput
                name="sku"
                type="text"
                validation={validation}
                placeholder={t(Labels.artwork_sku_hint)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-3 col-form-label">{t(Labels.title)}</Label>
            <Col sm={9}>
              <FormikInput
                name="title"
                type="text"
                validation={validation}
                placeholder={t(Labels.artwork_title_hint)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-3 col-form-label">{t(Labels.categories)}</Label>
            <Col sm={9}>
              <FormikInput
                name="categories"
                validation={validation}
                customInput={({ handleChange, handleBlur, errorData }) => (
                  <Creatable
                    isMulti={true}
                    formatCreateLabel={(value) => `${t(Labels.create)} "${value}"`}
                    placeholder={t(Labels.artwork_categories_hint)}
                    value={validation?.values?.categories?.map((item: string) => ({ value: item }))}
                    getOptionLabel={(option: any) => option.label || option.value}
                    getOptionValue={(option: any) => option.value}
                    onBlur={handleBlur}
                    onChange={(values: any) => handleChange(values.map((item: any) => item.value))}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-3 col-form-label">{t(Labels.tags)}</Label>
            <Col sm={9}>
              <FormikInput
                name="tags"
                validation={validation}
                customInput={({ handleChange, handleBlur, errorData }) => (
                  <Creatable
                    isMulti={true}
                    formatCreateLabel={(value) => `${t(Labels.create)} "${value}"`}
                    placeholder={t(Labels.artwork_tags_hint)}
                    value={validation?.values?.tags?.map((item: string) => ({ value: item }))}
                    getOptionValue={(option: any) => option.value}
                    getOptionLabel={(option: any) => option.label || option.value}
                    onBlur={handleBlur}
                    onChange={(values: any) => handleChange(values.map((item: any) => item.value))}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Label className="col-sm-3 col-form-label">{t(Labels.artwork_store_service)}</Label>
            <Col sm={9}>
              <FormikInput
                name="storageService"
                validation={validation}
                customInput={({ handleChange, handleBlur, errorData }) => (
                  <Select
                    isSearchable={true}
                    options={storageServiceOptions}
                    value={storageServiceOptions.filter(option => option.value == validation?.values?.storageService).pop()}
                    getOptionValue={(option: any) => option.value}
                    getOptionLabel={(option: any) => option.label || option.value}
                    onBlur={handleBlur}
                    onChange={(value: any) => handleChange(value.value)}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="artwork">
            <Col sm={3}>
              <Label className="col-form-label">{t(Labels.default_artwork_url)}</Label>
              <CardLink
                target="_blank"
                href={validation.values.url || ""}
                className="ps-1"
              >
                <span> </span>
                <BiLinkExternal size="18" />
              </CardLink>
            </Col>
            <Col sm={9}>
              <Row>
                <FormikInput
                  name={"url"}
                  placeholder={Labels.url_hint}
                  validation={validation}
                  value={validation.values.url}
                />
              </Row>
              <img
                style={{
                  maxHeight: 140,
                  maxWidth: 120,
                  marginTop: "10px",
                  objectFit: "cover",
                  border: "1 solid rgb(239, 242, 247)"
                }}
                src={validation.values.url?.startsWith("https://drive.google.com") ? googleDriveImage : validation.values.url || ""}
                width={"100%"}
              />
            </Col>
            <div className="dropdown-divider" />
          </Row>
        </CardBody>
      </Card>
      <CustomOptions validation={validation} />
      <CardProduct validation={validation} />
    </Fragment>
  );
};

interface CardProductProps {
  validation: FormValidation;
}

const CardProduct = (props: CardProductProps) => {
  const { validation } = props;
  const customilyTemplateLoadable = (withPermission(ActionEntities.read, ResourceEntities.artworkTemplateEntity) ?
    useRecoilValueLoadable(customilyTemplateNameSelector) : null) as Loadable<any>
  const [customilyTemplates, setCustomilyTemplates] = useState();
  const [dataColumn, setDataColumn] = useState<any[]>([]);

  useEffect(() => {
    if (customilyTemplateLoadable?.state == "hasValue") {
      setCustomilyTemplates(customilyTemplateLoadable && customilyTemplateLoadable.contents?.map((item: any) => ({ value: item, key: item })) || []);
    }
  }, [customilyTemplateLoadable?.contents]);

  // let columns: Column<any, any>[] = [
  //   {
  //     key: 'id',
  //     frozen: true,
  //     name:t(Labels.id),
  //     width: 100,
  //     minWidth: 50,
  //     // eslint-disable-next-line react/display-name
  //     formatter: ({ row = {}, onRowChange }: any = {}) => {
  //       if(!row.id) {
  //         return  <></>
  //       } 
  //       return <Link className="text-info fw-bold" to={{ pathname:`${RouteNames.PRODUCT_DETAIL.replace(":id", row.id)}` }} target="_blank">#{row.id}</Link> 
  //     },
  //   },
  //   {
  //     key: "sku",
  //     editable: true,
  //     name: t(Labels.product_sku),
  //     editor: TextEditor,
  //     minWidth: 200
  //   },

  //   {
  //     key: "regularPrice",
  //     editable: true,
  //     width: 106,
  //     minWidth: 106,
  //     name: t(Labels.regular_price),
  //     editor: TextEditor
  //   },
  //   {
  //     editable: true,
  //     key: "salePrice",
  //     width: 90,
  //     minWidth: 90,
  //     name: t(Labels.sale_price),
  //     editor: TextEditor,
  //     formatter: ({ row }: any = {}) => {
  //       return row.salePrice == 0 ? "" : row.salePrice || "";
  //     }
  //   },
  //   {
  //     key: "categories",
  //     editable: true,
  //     name: t(Labels.categories),
  //     minWidth: 180,
  //     formatter: ({ row }: any = {}) => {
  //       return row.categories?.join(",") || "";
  //     },
  //     // eslint-disable-next-line react/display-name
  //     editor: ({ row, onRowChange }: any = {}) => (
  //       <Input
  //         type="text"
  //         autoFocus={true}
  //         defaultValue={row.categories.join(",") || ""}
  //         onChange={(event) => {
  //           onRowChange({ ...row, categories: event.target.value.trim().split(",") }, true);
  //         }}
  //       />
  //     )
  //   },
  //   {
  //     key: "tags",
  //     editable: true,
  //     name: t(Labels.tags),
  //     minWidth: 180,
  //     formatter: ({ row }: any = {}) => {
  //       // eslint-disable-next-line react/prop-types
  //       return row.tags?.join(",") || "";
  //     },
  //     // eslint-disable-next-line react/display-name
  //     editor: ({ row, onRowChange }: any = {}) => (
  //       <Input
  //         type="text"
  //         autoFocus={true}
  //         defaultValue={row.tags.join(",") || ""}
  //         onChange={(event) => {
  //           onRowChange({ ...row, tags: event.target.value.trim().split(",") });
  //         }}
  //       />
  //     )
  //   },
  //   {
  //     key: "description",
  //     name: t(Labels.description),
  //     editable: true,
  //     editor: TextEditor,
  //     minWidth: 200
  //   },
  //   {
  //     key: "status",
  //     name: t(Labels.status_and_publish),
  //     minWidth: 150,
  //     editable: false,
  //     editor: TextEditor,
      
  //     formatter: ({ row }: any = {}) => {
  //       // eslint-disable-next-line react/prop-types
  //       return (ProductStatusOptions.filter((item: any) => item.value === row.status).pop()?.label + `/${PublishStatusOptions.find((item: any) => item.value === row.publishStatus)?.label}`) as any;
  //     },
  //     // eslint-disable-next-line react/display-name
  //     // editor: ({ row, onRowChange }: any = {}) => {
  //     //   return (
  //     //     <Select
  //     //       options={ProductStatusOptions}
  //     //       blurInputOnSelect={true}
  //     //       menuPortalTarget={document.body}
  //     //       openMenuOnFocus={true}
  //     //       autoFocus={true}
  //     //       value={ProductStatusOptions.filter((item: any) => item.value === row.status).pop()}
  //     //       styles={createCustomSelectStyles(ROW_HEIGHT)}
  //     //       onChange={(option: any) => onRowChange({ ...row, status: option.value })}
  //     //       {...{ placeholder: `${t("Select")}...` }}
  //     //     />
  //     //   );
  //     // }
  //   }
  // ];

  let columns: Column<any, any>[] = [
    {
      key: "title",
      name: t(Labels.product_title),
      width: 580,
      minWidth: 480,
      // eslint-disable-next-line react/display-name
      formatter: ({ row = {}, onRowChange }: any = {}) => {
        if (!row.id) {
          return <></>
        }
        return (
          <Link className="text-info fw-bold" to={createLinkEmbedParam(`${RouteNames.PRODUCT_DETAIL.replace(":id", row.id)}`).to} target="_blank">
            #{row.title}
          </Link>
        )
      },
    },
    {
      key: "sku",
      editable: true,
      name: t(Labels.product_sku),
      editor: TextEditor,
      minWidth: 240,
    },
    {
      key: "status",
      editable: true,
      name: t(Labels.status),
      width: 80,
      minWidth: 80,
      // eslint-disable-next-line react/display-name
      formatter: ({ row = {} }: any) => {
        return (
          <Col>
            <Badge className="me-1" color={ProductStatusColor[row.status]} pill>
              {ProductStatusName[row.status as string] || row.status}
            </Badge>
          </Col>
        )
      },
    },
    {
      key: "publishStatus",
      editable: true,
      name: t(Labels.publish_status),
      width: 120,
      minWidth: 120,
      // eslint-disable-next-line react/display-name
      formatter: ({ row = {} }: any) => {
        return (
          <Col>
            <Badge className="me-1" color={PublishStatusColor[row.publishStatus]} pill>
              {PublishStatusName[row.publishStatus] || row.publishStatus}
            </Badge>
          </Col>
        )
      },
    },
    {
      key: "publishIssues",
      editable: true,
      name: t(Labels.publish_issues),
      minWidth: 120,
      // TODO define type for PublicationChannel
      // eslint-disable-next-line react/display-name
      formatter: ({ row = {} }: any) => {
        return (
          // eslint-disable-next-line react/jsx-no-comment-textnodes
          <Fragment>
            { row.publishIssues?.filter((val: any) => val).join(',')}
          </Fragment>
        )
      },
    }
  ]

  function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<any>) {
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

  const handlePaste = ({
                         sourceColumnKey,
                         sourceRow,
                         targetColumnKey,
                         targetRow
                       }: PasteEvent<any>) => {
    const incompatibleColumns = ["id"];
    if (sourceColumnKey !== targetColumnKey
      && (incompatibleColumns.includes(targetColumnKey) || incompatibleColumns.includes(sourceColumnKey))) {
      return targetRow;
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
  };

  function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<any>): void {
    if (window.isSecureContext) {
      let value = sourceRow[sourceColumnKey];
      navigator.clipboard.writeText(value);
    }
  }

  function rowKeyGetter(row: any) {
    // eslint-disable-next-line react/prop-types
    return row?.sku;
  }

  // useEffect(() => {
  //   validation.setFieldValue('products',createGridRowData())
  // }, [validation.values.productTypes])
  const createGridRowData = () => {
    let artwork = validation.values;
    let products = [...(artwork.products || [])];

    artwork.productTypes?.forEach((productType: any, idx: number) => {
      const {id, ...restProduct} = productType
      let sku = `${artwork.sku}-${productType.code}`;
      let product = artwork.products?.find((product: any) => product.sku === sku);
      if (!product) {
        products.push({
          ...restProduct,
          ...ArtworkHelper.createProduct(artwork, productType)
        });
      }
    });

    return products;
  };

    useEffect(() => {
    setDataColumn(validation.values.products || [])
  }, [validation.values.products ])

  const updateRowDataBuilder = (key: string, cellValue: any, rowData: any) => {
    let newData: any = {};
    if (cellValue == null || cellValue == undefined) return newData;

    if (key == "categories") {
      newData.categories = cellValue.trim().split(", ").map((item: string) => item);
    }

    if (key == "tags") {
      newData.tags = cellValue.trim().split(", ").map((item: string) => item);
    }

    return newData;
  };

  const onRowsChange = (rows: any, data: any) => {
    validation.setFieldValue("products", rows);
  };

  return (<Card>
      <CardHeader>
        <CardTitle>{t(Labels.product)}</CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="mb-2">
          <Col>
            <FormikInput
              name="productTypes"
              validation={validation}
              label={t(Labels.product_type)}
              customInput={({ handleChange, handleBlur, errorData }) => 
              <SearchableSelect
                isMulti={true}
                value={validation.values?.productTypes}
                defaultValue={validation.values?.productTypes}
                getOptionValue={(option: any) => option?.id || ""}
                getOptionLabel={(option: any) => option?.code || ""}
                loadOptions={(search: string) => ProductTypeActions.searchByProduceType(search)}
                onChange={(values: any) => {
                  validation.setFieldValue("productTypes", values);
                }}
                onBlur={handleBlur}
                styles={errorData.reactSelectErrorStyle}
              />
              }

            />
          </Col>
          <Col>
          <FormikInput
              name="publicationChannels"
              validation={validation}
              label={t(Labels.stores)}
              customInput={({ handleChange, handleBlur, errorData }) => ( 
              <SaleChannelSelect
                isMulti={true}
                value={validation.values.publicationChannels}
                getOptionLabel={(option: SaleChannelDto) => option.domain}
                getOptionValue={(option: SaleChannelDto) => option.id}
                onChange={(value: any) => handleChange(value)}
              />)}
          />      
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <FormikInput
              label={t(Labels.customily_template)}
              name="customilyTemplate"
              type="text"
              validation={validation}
              customInput={({ handleChange, handleBlur, errorData }) => {
                return <Select
                  value={validation.values.customilyTemplate ? {
                    key: validation.values.customilyTemplate,
                    value: validation.values.customilyTemplate
                  } : undefined}
                  options={customilyTemplates}
                  getOptionValue={(option: any) => option?.key}
                  getOptionLabel={(option: any) => option?.value}
                  onBlur={handleBlur}
                  onChange={(value: any) => {
                    validation.setFieldValue("customilyTemplate", value.key);
                  }}
                  styles={errorData.reactSelectErrorStyle}
                />;
              }}
            />
          </Col>
          <Col>
            <FormikInput
              label={t(Labels.customily_product_id)}
              name="customilyProductId "
              type="text"
              value={validation.values.customilyProductId}
              validation={validation}
              placeholder={t(Labels.customily_product_id_hint)}
              onChange={(value: any) => {
                validation.setFieldValue("customilyProductId", value);
              }}
            />
          </Col>
        </Row>
        <Col>
          <Label>{t(Labels.products)}</Label>
          <DataGrid
            columns={columns || []}
            rows={dataColumn}
            rowKeyGetter={rowKeyGetter}
            onRowsChange={onRowsChange}
            onFill={handleFill}
            onCopy={handleCopy}
            onPaste={handlePaste}
            rowHeight={35}
            defaultColumnOptions={{ resizable: true }}
            className={"fill-grid rdg-dark rdg-light"}
            style={{ height: "100%", blockSize: "100%" }}
            rowClass={(row: any) => (row._bulkUpdated ? "react-data-grid-updated-row" : "react-data-grid-normal-row")}
          />
        </Col>
      </CardBody>
    </Card>
  );
};


export const CustomOptions = (props: any) => {

  const { validation } = props;
  const customize = validation.values?.customize || false;

  return <Card>
    <CardHeader>
      <CardTitle>{t(Labels.custom_option)}</CardTitle>
    </CardHeader>
    <CardBody>
      <Row className="mb-2">
        <Col>
          <Col xs={"auto"} className="pe-0 pt-0 col-sm-3 col-form-label">
            <Label className="mb-0" style={{ fontWeight: "normal" }}>{t(Labels.artwork_template)}</Label>
            <span className="p-0">
              <Link target="_blank" to={createLinkEmbedParam(`${RouteNames.ARTWORK_TEMPLATES_DETAIL.replace(":id", validation.values.template?.id)}`).to} className="ps-1">
                <BiLinkExternal size="18" />
              </Link>
            </span>
          </Col>
          <FormikInput
            name="template"
            validation={validation}
            customInput={({ handleChange, handleBlur, errorData }) => {
              // const promiseOptions = () => {
              //   return new Promise(async (resolve) => {
              //     let data = await ArtworkTemplateActions.getArtworkTemplate();
              //     resolve([{ label: t(Labels.use_custom_options) }, ...data]);
              //   });
              // };

              return <AsyncSelect
                cacheOptions={true}
                defaultOptions={true}
                value={validation.values?.template}
                defaultValue={validation.values?.template}
                getOptionValue={(option: any) => option?.name || ""}
                getOptionLabel={(option: any) => option?.name || option?.label}
                loadOptions={(search: string) => ArtworkTemplateActions.searchTemplateByName(search)}
                onChange={(values: any) => {
                  validation.setFieldValue("template", values);
                  if (values.name) {
                    validation.setFieldValue("customize", true);
                  }
                }}
                onBlur={handleBlur}
                styles={errorData.reactSelectErrorStyle}
              />
            }}
          />
        </Col>
      </Row>
      {!validation.values?.template?.name ? <CustomizeForm validation={validation} />: <></> }
    </CardBody>
  </Card>;
};


export const CustomizeForm = (props: any) => {
  const { validation } = props;
  const readOnly = !!validation.values.template?.name

  return (<>
    <Col>
      <Row className="mb-3">
        <Col>
          <Label className="col-sm-3 col-form-label">{t(Labels.customize)}</Label>
          <FormikInput
            name="customize"
            validation={validation}
            readOnly={readOnly}
            customInput={({ handleChange, handleBlur, errorData }) => (
              <Select
                isDisabled={readOnly}
                isSearchable={true}
                options={[{ label: `${t(Labels.has_custom_option)}`, value: true }, {
                  label: `${t(Labels.no_custom)}`,
                  value: false
                }]}
                value={
                  validation?.values?.customize ?
                    { label: `${t(Labels.has_custom_option)}`, value: true } :
                    { label: `${t(Labels.no_custom_option)}`, value: false }
                }
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                onBlur={handleBlur}
                onChange={(value: any) => handleChange(value.value)}
                styles={errorData.reactSelectErrorStyle}
              />
            )}
          />
        </Col>
      </Row>

      <div className='dropdown-divider'></div>
      <Row className="mt-2">
        <OptionalLabel label={Labels.custom_option}/>
      </Row>

      <Row className="d-flex justify-content-between align-items-center">
        <Col className="flex-1 align-items-center pe-0">
          <Label>{t(Labels.custom_option_keys)}</Label>
        </Col>
        <Col xs={2} className="ps-0">
          <Label>{t(Labels.default_value)}</Label>
        </Col>
        <Col xs={"auto"} className="d-flex align-items-center pe-0">
          <i className="mdi mdi-delete font-size-18 invisible" />
        </Col>
      </Row>
      <CustomizeOptionFields validation={validation} readOnly={readOnly}/>
    </Col>
  </>);
};


export interface CustomizeOptionFieldProps {
  validation: FormValidation;
  readOnly?: boolean
}

export const CustomizeOptionFields = (props: CustomizeOptionFieldProps) => {
  const { validation, readOnly } = props;

  // auto create new option when page is loaded
  const createOption = (currentOpt = validation.values.customOptions) => {
    let option = !currentOpt?.length ? [{}] : [...currentOpt];
    if (!option?.length || !isEmpty(option[option.length - 1])) {
      option.push({} as any);
    }
    return option;
  };

  const [optionState, setOptionState] = useState(createOption());
  useEffect(() => setOptionState(createOption()), [validation.values.customOptions]);

  const updateOption = (newValues: any, indexOpt: number) => {
    let newOption = [];

    newOption = optionState.map((item: any) => item);
    newOption[indexOpt] = {
      ...newOption[indexOpt],
      ...newValues
    };
    return checkEmptyObject(newOption);
  };

  const handleChangeOption = (newValues: any, indexOpt: number) => {
    validation.setFieldValue("customOptions", updateOption(newValues, indexOpt));
  };

  const removeOption = (removeIndex: number) => {
    let newOption = validation.values.customOptions?.filter((item: any, index: number) => index !== removeIndex);
    validation.setFieldValue("customOptions", checkEmptyObject(newOption));
  };

  const checkEmptyObject = (value: any) => {
    if (value?.length && isEmpty(value[value.length - 1])) {
      value.pop();
    }
    return value;
  };

  const addCustomOptionItem = () => {
    let defaultOptionValues = validation.values.customOptions || [];
    let lastValue = defaultOptionValues[defaultOptionValues.length - 1];

    if (!lastValue || !isEmpty(lastValue)) {
      validation.setFieldValue("customOptions", [...defaultOptionValues, {}]);
    }
  };

  const customOptions = validation.values?.customOptions?.length ? validation.values?.customOptions : [{}];
  return (
    <>
      {(readOnly ? customOptions : optionState)?.map((option: CustomOption, index: number) => {
        return <Row className="d-flex justify-content-between align-items-center mb-3"
                    key={index + ((option.keys && option.keys[0]) || option.name || "")}>
          <Col className="flex-1 pe-0">
            <FormikInput
              validation={validation}
              name="customOptions"
              // label={`Custom Option Keys ${index + 1}`}
              customInput={({ handleBlur, handleChange, errorData }) => {
                return <Creatable
                  isMulti={true}
                  formatCreateLabel={(value) => `${t(Labels.create)} "${value}"`}
                  placeholder={t(Labels.variant_option_keys_hint)}
                  value={option?.keys?.map((item: any) => ({ value: item, label: item }))}
                  // styles={{
                  //   control: (prev) => ({
                  //     ...prev,
                  //     ">div:first-child": { maxHeight: 56, overflowY: "auto" }
                  //   })
                  // }}
                  isDisabled={readOnly}
                  // options={validation?.values?.customOptions}
                  getOptionValue={(option: any) => option.value || option}
                  getOptionLabel={(option: any) => option.label || option.value || option}
                  onBlur={props.validation.handleBlur}
                  onChange={(values: any, indexds: any) => {
                    // If template is defined, left this input readonly
                    if (validation.values.template?.name) return;
                    let newValues = [...(validation.values.customOptions || [])];
                    let option = newValues[index];
                    if (option) {
                      newValues[index] = {
                        ...option,
                        keys: values.map((value: any) => value.value || value)
                      };
                    }
                    else {
                      return validation.setFieldValue("customOptions",
                        [...newValues,
                          {defaultValue: option?.defaultValue || "",keys: values.map((value: any) => value.value || value)}
                        ]
                      );
                    }
                    validation.setFieldValue("customOptions", newValues);
                  }}
                styles={errorData.reactSelectErrorStyle}
                />;
              }}
            />
          </Col>

          <Col xs={2} className="pe-0">
            <FormikInput
              name={"defaultValue"}
              type={"text"}
              validation={validation}
              disabled={readOnly}
              value={!option.defaultValue ? "" : option.defaultValue}
              placeholder={t(Labels.default_value_hint)}
              onChange={(value: any) => handleChangeOption({ defaultValue: value }, index)}
            />
          </Col>
          <Col xs={"auto"} className="d-flex align-items-center">
            <Link {...createLinkEmbedParam("#")} onClick={() => removeOption(index)}>
              <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} />
            </Link>
          </Col>

          {/* </CardBody> */}
        </Row>;
      })}

      {/* <Col className="p-0">
            <Button
              color={'light'}
              onClick={ addCustomOptionItem }
            >
              <i className="bx bx-plus font-size-16 align-middle"></i>
              { t("Thêm custom option") }
            </Button>
          </Col> */}
    </>
  );
};

