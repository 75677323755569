import { RecoilState, useRecoilState, useSetRecoilState } from "recoil";
import { isEmpty, isEqual, omit } from "lodash";
import { BulkEditingState, BulkEditorView, defaultBulkEditingState } from "../../../components/bulk/bulk-editor.type";
import React, {
  createContext,
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { BaseBulkEditorProps, defaultSubmitDataBuilder, ROW_HEIGHT } from "../../../components/bulk/BulkEditor";
import {
  CalculatedColumn,
  Column,
  CopyEvent,
  EditorProps,
  FillEvent,
  HeaderRendererProps,
  PasteEvent,
  RowsChangeData
} from "react-data-grid";
import { t } from "core/translations";
import { Badge, Button, Card, Input, Row } from "reactstrap";
import { FulfillOrderDto } from "../../../data/services/fulfill-order.service";
import { flattenNestedObject, unflattenNestedObject } from "../../../utils/utils";
import { Labels } from "common/labels";
import { Confirmations, Toasts } from "core/notification/notifications";
import { Messages } from "common/messages";
import { portableModalState } from "data/atoms/app.atom";
import ReactDataGridCustomize from "components/bulk/react-data-grid-customize";
import { AsyncSelectEditor } from "pages/products/artwork-bulk-create.page";
import { Link } from "react-router-dom";
import { RouteNames } from "routes";
import DropdownComponent from "components/dropdown/DropdownComponent";
import { BiHistory, BiLinkExternal } from "react-icons/bi";
import { TooltipComponent } from "components/common/TooltipComponent";
import { HiOutlineRefresh } from "react-icons/hi";
import { FulfillDesignStatusColor, FulfillDesignStatusName } from "types/fulfill-status.type";
import { FulfillOrderActions } from "../../../data/actions/fulfill-order.action";
import { createLinkEmbedParam, createUrlEmbed } from "utils/embed";

const FilterContext = createContext<any>(undefined)

const editFulfillItemColumns: any = (data: any, setFilters: any) => {
  return [
    {
      key: "sku",
      minWidth: 200,
      name: t(Labels.design_sku),
      headerCellClass: "filter-cell ps-2",
      editable: true,
      editor: ({ row, onRowChange }: any = {}) => <Input value={row.sku} onChange={() => {}} />,
    },
    {
      key: "__bulkResetRowChange",
      minWidth: 60,
      width: 60,
      name: t(Labels.reset),
      headerCellClass: "filter-cell ps-2",
      cellClass: "d-flex justify-content-center align-items-center",
      frozen: true,
      formatter: ({ row, onRowChange }: any = {}) => {
        return <span>
        {!row["_bulkUpdated"] ? null : (
          <div>
            <Link {...createLinkEmbedParam("#")} onClick={() => {
              onRowChange({ ...row, __changedKeys: ["__bulkResetRowChange"] }, true)}
            }>
              <TooltipComponent tooltip={t(Labels.reset_change)} className='d-flex justify-content-center align-items-center'>
                <BiHistory size={22} />
              </TooltipComponent>
            </Link>
          </div>
        )}
      </span>
      }
    },
    {
      key: "designStatus",
      minWidth: 150,
      name: t(Labels.design_status),
      headerCellClass: "filter-cell ps-2 ",
      cellClass: "d-flex justify-content-center",
      editable: true,
      formatter: ({ row, onRowChange }: any = {}) => (
        <>
          {row.designStatus ? (
            <TooltipComponent tooltip={""} className="d-inline ">
              <Badge className="pt-1 d-inline" color={FulfillDesignStatusColor[row.designStatus] || "light"}>
                {FulfillDesignStatusName[row.designStatus]}
              </Badge>
            </TooltipComponent>
          ) : (
            <></>
          )}
        </>
      )
    },
    {
      key: "orderNumber",
      minWidth: 150,
      name: t(Labels.order_number),
      headerCellClass: "filter-cell ps-2",
      editable: true,
      editor: ({ row, onRowChange }: any = {}) => <Input value={row.orderNumber} onChange={() => {}} />,
    },
    {
      key: "uploadDesignOptions.designName",
      name: t(Labels.design_name),
      headerCellClass: "filter-cell",
      minWidth: 300,
      frozen: true,
      // eslint-disable-next-line react/display-name
      formatter: ({ row }: any) => {
        return row.uploadDesignOptions?.designName ? (
          <p>{row.uploadDesignOptions?.designName}</p>
        ) : (
          <p style={{ opacity: 0.7 }}>{t(Labels.choose_design_name)}</p>
        )
      },
      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: EditorProps<any>) => {
        const options = row.uploadDesignOptions?.allDesignNames?.map((design: string) => ({ label: design, value: design })) || []
        const value = { label: row.uploadDesignOptions?.designName, value: row.uploadDesignOptions?.designName }
        return (
          <div style={{ zIndex: Number.MAX_SAFE_INTEGER }}>
            <AsyncSelectEditor
              defaultOptions={true}
              options={options}
              loadOptions={() => FulfillOrderActions.loadUploadDesignOptions(row.id)}
              value={value}
              getOptionLabel={(option: any) => option.value}
              getOptionValue={(option: any) => option.value}
              embedHeight={35}
              onChange={values => {
                onRowChange(
                  {
                    ...row,
                    uploadDesignOptions: { ...row.uploadDesignOptions, designName: values.value },
                    __changedKeys: ["uploadDesignOptions"],
                  },
                  true
                )
              }}
            />
            ;
          </div>
        )
      },
      headerRenderer: (p: any) => (
        <FilterRenderer<Row, unknown, HTMLInputElement> {...p}>
          {({ filters, setSelectedRows, ...rest }) => (
            <input
              {...rest}
              placeholder={t(Labels.filter_option_design_name)}
              className={"filterClassname font-size-13"}
              value={filters?.designName}
              onChange={e => {
                setFilters((prev: any) => ({
                  ...prev,
                  designName: e.target.value,
                }))
                setSelectedRows(() => new Set())
              }}
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },

    {
      key: "uploadDesignOptions.mockupName",
      name: t(Labels.mockup_name),
      headerCellClass: "filter-cell",
      minWidth: 300,
      frozen: true,
      // eslint-disable-next-line react/display-name
      formatter: ({ row }: any) => {
        return row.uploadDesignOptions?.mockupName ? (
          <p>{row.uploadDesignOptions?.mockupName}</p>
        ) : (
          <p style={{ opacity: 0.7 }}>{t(Labels.choose_mockup_name)}</p>
        )
      },
      // eslint-disable-next-line react/display-name
      editor: ({ row, onRowChange }: EditorProps<any>) => {
        const options = row.uploadDesignOptions?.allMockupNames?.map((mockUp: string) => ({ label: mockUp, value: mockUp })) || []
        const value = { label: row.uploadDesignOptions?.mockupName, value: row.uploadDesignOptions?.mockupName }

        return (
          <div style={{ zIndex: Number.MAX_SAFE_INTEGER }}>
            <AsyncSelectEditor
              defaultOptions={true}
              options={options}
              loadOptions={() => FulfillOrderActions.loadUploadDesignOptions(row.id)}
              value={value}
              getOptionLabel={(option: any) => option.value}
              getOptionValue={(option: any) => option.value}
              embedHeight={35}
              onChange={values => {
                onRowChange(
                  {
                    ...row,
                    uploadDesignOptions: { ...row.uploadDesignOptions, mockupName: values.value },
                    __changedKeys: ["uploadDesignOptions"],
                  },
                  true
                )
              }}
            />
            ;
          </div>
        )
      },
      headerRenderer: (p: any) => (
        <FilterRenderer<Row, unknown, HTMLInputElement> {...p}>
          {({ filters, setSelectedRows, ...rest }) => (
            <input
              {...rest}
              placeholder={t(Labels.filter_option_mockup_name)}
              className={"filterClassname font-size-13"}
              value={filters?.mockupName}
              onChange={e => {
                setFilters((prev: any) => ({
                  ...prev,
                  mockupName: e.target.value,
                }))
                setSelectedRows(() => new Set())
              }}
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      editable: true,
      key: "id",
      minWidth: 90,
      width: 90,
      name: t(Labels.id),
      formatter: ({ row, onRowChange }: any = {}) => {
        if (!row.id) {
          return <p className="mb-0"></p>
        }
        return (
          <>
            <span className="mt-1 d-inline-block">{row.id}</span>
            <a target="_blank" rel="noreferrer" href={createUrlEmbed(RouteNames.ORDER_ITEM_DETAIL?.replace(":id", row.id))}>
              {" "}
              <BiLinkExternal />
            </a>
          </>
        )
      },
      editorOptions: {
        editOnClick: true,
      },
      cellClass: "text-center mb-0",
      headerCellClass: "filter-cell ps-2",
    },
  ]
}

export interface FulfillItemBulkUploadProps extends BaseBulkEditorProps<FulfillOrderDto> {
  ref: any
  setBulkEditSelectedIds?: (selectedIds: string[]) => any
}

export const FulfillItemBulkUpload = forwardRef((props: FulfillItemBulkUploadProps, ref: any) => {
  const [filters, setFilters] = useState<Record<string, any>>({
    designName: "",
    mockupName: "",
  })
  const [editData, setEditData] = useState(props.data)
  const columns = useMemo(() => editFulfillItemColumns(editData, setFilters), [editData])

  useEffect(() => setEditData(props.data), [props.data])

  const submitDataBuilder = (data: any) => {
    let newData = {} as any

    for (let id in data) {
      let updatedData = data[id]
      let submitData = updatedData
      let rawData: {} = editData.find(item => item.id == id)
      if (rawData) {
      }
      newData[id] = submitData
    }

    return newData
  }

  const updateRowDataBuilder = (key: string, cellValue: any, rowData: any) => {
    let newData: any = {}
    if (cellValue == null || cellValue == undefined) return newData

    return newData
  }

  return (
    <Fragment>
      <FulfillItemGrid
        {...props}
        ref={ref}
        data={editData}
        columns={{ [BulkEditorView.EDIT_DESIGN_OPTION]: columns }}
        bulkEditSelector={props.bulkEditSelector}
        submitDataBuilder={submitDataBuilder}
        updateRowDataBuilder={updateRowDataBuilder}
        setBulkEditSelectedIds={props.setBulkEditSelectedIds}
        filters={filters}
      />
    </Fragment>
  )
})

interface RowsMultiChangeData<R, SR = unknown> {
  indexes: number[]
  columns: Array<CalculatedColumn<R, SR>>
}

export interface BulkEditorProps<T> extends BaseBulkEditorProps<T> {
  columns: Record<string, Column<T, any>[]>
  customOptions?: any
  nestedFields?: Record<string, string[]>
  customRowDataBuilder?: (currentRowData: T, newRowData: T) => { customOptions: any; newRowData: any }
  updateRowDataBuilder?: (key: string, cellValue: any, rowData: T) => any
  submitDataBuilder?: (data: T, nestedObjectKeys?: string[], rowData?: any) => any
  setBulkEditSelectedIds?: (selectedIds: string[]) => any
  filters: Record<string, any>
}

const FulfillItemGrid = forwardRef((props: BulkEditorProps<any>, ref) => {
  let { submitDataBuilder = defaultSubmitDataBuilder, filters } = props
  const [bulkEditingState, setBulkEditingState] = useRecoilState<BulkEditingState>(props.bulkEditSelector)
  const columns = props.columns[props.editor] || []
  const nestedObjectKeys = (props.nestedFields && props.nestedFields[props.editor]) || []
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(() => new Set())
  const setPortableModal = useSetRecoilState(portableModalState)

  const createGridRowData = (filters: any) => {
    let data = props.data || []
    let rows: any[] = []
    let updateDataById = {} as any

    for (let i = 0; i < data.length; i++) {
      let row = {
        ...data[i],
        ...flattenNestedObject(data[i], nestedObjectKeys),
      }

      let updatedData = { ...(bulkEditingState.updatedData[row.id] || {}) }
      if (!isEmpty(updatedData)) {
        updateDataById[row.id] = updatedData
        rows.push({
          ...row,
          ...updatedData,
          _bulkUpdated: true,
        })
      } else {
        rows.push({
          ...row,
          _bulkUpdated: false,
        })
      }
    }
    if (filters.designName) {
      rows = rows?.filter((row: any) =>
        row.uploadDesignOptions?.allDesignNames?.find((design: string) => design.includes(filters.designName))
      )
    }

    if (filters.mockupName) {
      rows = rows?.filter((row: any) =>
        row.uploadDesignOptions?.allMockupNames?.find((design: string) => design.includes(filters.mockupName))
      )
    }

    if (Array.from(selectedRows)?.length) {
      let newSelectedRows = Array.from(selectedRows)?.filter(item => rows?.find((row: any) => row.id === item))
      !isEqual(Array.from(selectedRows), newSelectedRows) && setSelectedRows(new Set(newSelectedRows))
    }

    return rows
  }

  
  useImperativeHandle(ref, () =>  ({
    autoDesignName: () => handleAutoDesignName(),
    autoMockUpName: () => handleAutoMockUpName(),
    autoDesignAndMockup: () => handleAutoDesignAndMockup()
  }))

  const handleAutoDesignName = ()=> {
    let rowData = []
    const checkNoUploadDesign = createGridRowData(filters)?.filter((item: any) => item.uploadDesignOptions && !item.uploadDesignOptions?.designName)
    if(checkNoUploadDesign?.length < 1) {
      return Toasts.warning(Labels.fill_design_status_unsuccess)
    }
    
    for (let item of createGridRowData(filters)) {
      let editingItem = { ...item };
      
      if(item.uploadDesignOptions?.allDesignNames?.length > 0 && !item.uploadDesignOptions?.designName){
        editingItem.uploadDesignOptions = {
          ...editingItem.uploadDesignOptions,
          designName: item.uploadDesignOptions?.allDesignNames[0]
        }
        editingItem.__changedKeys = 'uploadDesignOptions'
      }
      rowData.push(editingItem)
    }
    

    if(rowData?.length) {
      let indexes = rowData.map((item, index) => item.__changedKeys === "uploadDesignOptions" ? index : -1).filter(index => index >= 0)
      onRowsChange(rowData, { indexes, columns: [
        { key: 'uploadDesignOptions' } as any
      ]}
      )
    }
  }
  const handleAutoMockUpName = ()=> {
    let rowData = []

    const checkNoUploadMockup = createGridRowData(filters)?.filter((item: any) => item.uploadDesignOptions && !item.uploadDesignOptions?.mockupName)
    if(checkNoUploadMockup?.length < 1) {
      return Toasts.warning(Labels.fill_mockup_unsuccess)
    }
    for (let item of createGridRowData(filters)) {
      let editingItem = { ...item };

      if(item.uploadDesignOptions?.allMockupNames?.length > 0  && !item.uploadDesignOptions?.mockupName){
        editingItem.uploadDesignOptions = {
          ...editingItem.uploadDesignOptions,
          mockupName: item.uploadDesignOptions?.allMockupNames[0]
        }
        editingItem.__changedKeys = 'uploadDesignOptions'
      }
      rowData.push(editingItem)
    }

    if(rowData?.length) {
      let indexes = rowData.map((item, index) => item.__changedKeys === "uploadDesignOptions" ? index : -1).filter(index => index >= 0)
      onRowsChange(rowData, { indexes, columns: [
        { key: 'uploadDesignOptions' } as any
      ]}
      )
    }
  }

  const handleAutoDesignAndMockup = ()=> {
    let rowData = []
    const checkNoUploadDesign = createGridRowData(filters)?.find((item: any) => item.uploadDesignOptions)
    if(!checkNoUploadDesign) {
      return Toasts.warning(Labels.fill_design_status_and_mockup_unsuccess)
    }
    
    for (let item of createGridRowData(filters)) {
      let editingItem = { ...item };
      
      if(item.uploadDesignOptions?.allDesignNames?.length > 0 && !item.uploadDesignOptions?.designName){
        editingItem.uploadDesignOptions = {
          ...editingItem.uploadDesignOptions,
          designName: item.uploadDesignOptions?.allDesignNames[0]
        }
        editingItem.__changedKeys = 'uploadDesignOptions'
      }

      if(item.uploadDesignOptions?.allMockupNames?.length > 0 && !item.uploadDesignOptions?.mockupName){
        editingItem.uploadDesignOptions = {
          ...editingItem.uploadDesignOptions,
          mockupName: item.uploadDesignOptions?.allMockupNames[0]
        }
        editingItem.__changedKeys = 'uploadDesignOptions'
      }
      rowData.push(editingItem)
    }

    if(rowData?.length) {
      let indexes = rowData.map((item, index) => item.__changedKeys === "uploadDesignOptions" ? index : -1).filter(index => index >= 0)
      onRowsChange(rowData, { indexes, columns: [
        { key: 'uploadDesignOptions' } as any
      ]}
      )
    }
  }
  
  const onRowsChange = (rows: any, data: RowsMultiChangeData<any> | RowsChangeData<any>) => {
    let indexes = data?.indexes
    let columns = (data as any).column ? [(data as any).column] : (data as any).columns || []

    setBulkEditingState((current: any) => {
      let newBulkUpdateData = { ...current.updatedData }
      for (let index of indexes) {
        let rowData = rows[index]
        if (!rowData) continue
        if (rowData.__changedKeys?.includes("__bulkResetRowChange")) {
          rowData.__changedKeys.length = 0 // Reset array
          newBulkUpdateData = omit(newBulkUpdateData, rowData.id)
          continue
        }

        let changedData = {} as any
        for (let column of columns) {
          if (column) {
            let value = rowData[column.key]
            if (value != null || value != undefined) {
              changedData[column.key] = value
              let customUpdateData = props.updateRowDataBuilder ? props.updateRowDataBuilder(column.key, value, rowData) : {}
              changedData = {
                ...changedData,
                ...(customUpdateData || {}),
              }
            }
          } else if (rowData.__changedKeys) {
            for (let key of rowData.__changedKeys) {
              changedData[key] = rowData[key]
            }
          }

          if (column.key?.startsWith("uploadDesignOptions")) {
            changedData["uploadDesignOptions"] = rowData["uploadDesignOptions"]
          }
        }

        let nestedChangeData = unflattenNestedObject(rowData, changedData, nestedObjectKeys)
        newBulkUpdateData = {
          ...newBulkUpdateData,
          [rowData.id]: { ...(bulkEditingState.updatedData[rowData.id] || {}), ...changedData, ...nestedChangeData },
        }
      }

      let newSubmitData = submitDataBuilder(newBulkUpdateData, nestedObjectKeys)
      return {
        ...current,
        updatedData: newBulkUpdateData,
        submitData: newSubmitData,
      }
    })
  }

  function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<any>) {
    if (columnKey.startsWith("uploadDesignOptions")) {
      const [uploadDesignOptions, ...rest] = columnKey.split(".")
      const keyChange = rest?.join("")
      const uploadDesignValue = sourceRow["uploadDesignOptions"]

      const checkValueInOptionsDesign = targetRow.uploadDesignOptions?.allDesignNames?.find(
        (mockUp: string) => mockUp === uploadDesignValue?.designName
      )
      const checkValueInOptionsMockup = targetRow.uploadDesignOptions?.allMockupNames?.find(
        (mockUp: string) => mockUp === uploadDesignValue?.mockupName
      )
      const checkEmtyValueSourceDesign = !sourceRow?.uploadDesignOptions?.designName && targetRow.uploadDesignOptions
      const checkEmtyValueSourceMockup = !sourceRow?.uploadDesignOptions?.mockupName && targetRow.uploadDesignOptions

      if (keyChange === "designName" && (checkValueInOptionsDesign || checkEmtyValueSourceDesign)) {
        return {
          ...targetRow,
          uploadDesignOptions: { ...targetRow.uploadDesignOptions, [keyChange]: sourceRow["uploadDesignOptions"]?.designName || "" },
        }
      }
      if (keyChange === "mockupName" && (checkValueInOptionsMockup || checkEmtyValueSourceMockup)) {
        return {
          ...targetRow,
          uploadDesignOptions: { ...targetRow.uploadDesignOptions, [keyChange]: sourceRow["uploadDesignOptions"]?.mockupName || "" },
        }
      }
      return targetRow
    }

    return { ...targetRow, [columnKey]: sourceRow[columnKey] }
  }

  const handlePaste = ({ sourceColumnKey, sourceRow, targetColumnKey, targetRow }: PasteEvent<any>) => {
    const incompatibleColumns = ["id"]
    if (
      sourceColumnKey !== targetColumnKey &&
      (incompatibleColumns.includes(targetColumnKey) || incompatibleColumns.includes(sourceColumnKey))
    ) {
      return targetRow
    }

    if (sourceColumnKey.startsWith("uploadDesignOptions")) {
      const [uploadDesignOptions, ...rest] = sourceColumnKey.split(".")
      const keyChange = rest?.join("")
      const uploadDesignValue = sourceRow["uploadDesignOptions"]

      const checkValueInOptionsDesign = targetRow.uploadDesignOptions?.allDesignNames?.find(
        (mockUp: string) => mockUp === uploadDesignValue?.designName
      )
      const checkValueInOptionsMockup = targetRow.uploadDesignOptions?.allMockupNames?.find(
        (mockUp: string) => mockUp === uploadDesignValue?.mockupName
      )
      const checkEmtyValueSourceDesign = !sourceRow?.uploadDesignOptions?.designName && targetRow.uploadDesignOptions
      const checkEmtyValueSourceMockup = !sourceRow?.uploadDesignOptions?.mockupName && targetRow.uploadDesignOptions

      if (keyChange === "designName" && (checkValueInOptionsDesign || checkEmtyValueSourceDesign)) {
        return {
          ...targetRow,
          uploadDesignOptions: { ...targetRow.uploadDesignOptions, [keyChange]: sourceRow["uploadDesignOptions"]?.designName || "" },
        }
      }
      if (keyChange === "mockupName" && (checkValueInOptionsMockup || checkEmtyValueSourceMockup)) {
        return {
          ...targetRow,
          uploadDesignOptions: { ...targetRow.uploadDesignOptions, [keyChange]: sourceRow["uploadDesignOptions"]?.mockupName || "" },
        }
      }
      return targetRow
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] }
  }

  function handleCopy({ sourceRow, sourceColumnKey }: CopyEvent<any>): void {
    if (window.isSecureContext) {
      let value = sourceRow[sourceColumnKey]
      navigator.clipboard.writeText(value)
    }
  }

  function rowKeyGetter(row: any) {
    return row.id
  }

  return (
    <div style={{ height: "100%" }}>
      <Card>
        <FilterContext.Provider value={{ filters, setSelectedRows }}>
          <ReactDataGridCustomize
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            columns={columns}
            rows={createGridRowData(filters)}
            rowKeyGetter={rowKeyGetter}
            onRowsChange={onRowsChange}
            onFill={handleFill}
            onCopy={handleCopy}
            onPaste={handlePaste}
            rowHeight={ROW_HEIGHT}
            defaultColumnOptions={{ resizable: true }}
            className={"fill-grid rdg-dark rdg-light RDGcustomCheckbox"}
            style={{ height: "100%", blockSize: "100%" }}
            rowClass={(row: any) => (row._bulkUpdated ? "react-data-grid-updated-row" : "react-data-grid-normal-row")}
            headerRowHeight={ROW_HEIGHT * 2}
          />
        </FilterContext.Provider>
      </Card>
    </div>
  )
})

function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
    event.stopPropagation()
  }
}

function FilterRenderer<R, SR, T extends HTMLOrSVGElement>({
  isCellSelected,
  column,
  children,
}: HeaderRendererProps<R, SR> & {
  children: (args: { filters: any; setSelectedRows: any }) => React.ReactElement
}) {
  const { filters, setSelectedRows } = useContext(FilterContext)

  return (
    <>
      <div>{column.name}</div>
      {<div>{children({ filters, setSelectedRows })}</div>}
    </>
  )
}

interface propsActionUpload {
  actions?: any
  updating?: boolean 
  editorRef?: any
  dataRefresh: any
  bulkEditSelector: RecoilState<any>
  onSubmit: (data: Record<string, any>) => Promise<boolean | undefined>
  onExit: () => void
}

export const ActionUploadDesign = (props: propsActionUpload) => {
  const { actions,  editorRef, dataRefresh } = props
  const [updating, setUpdating] = useState(false)
  const [bulkEditingState, setBulkEditingState] = useRecoilState<BulkEditingState>(props.bulkEditSelector);


  const handleSaveBulkUpdateData = async () => {
    try {
      setUpdating(true)
      let updated = await props.onSubmit(bulkEditingState.submitData)
      if(updated) {
        setBulkEditingState((current: any) => ({ ...current, ...defaultBulkEditingState }))
        props.dataRefresh()
      }
    } finally {
      setUpdating(false)
    }
  }

  const handleCancelBulkEdit = () => {
    if(!isEmpty(bulkEditingState.submitData)) {
      Confirmations.confirm(
        t(Messages.do_you_want_to_discard_the_updated_information),
        () => {
          setBulkEditingState(defaultBulkEditingState)
          props.onExit()
        }
      )
    } else {
      setBulkEditingState(defaultBulkEditingState)
      props.onExit()
    }
  }
  return (
    <>
      <DropdownComponent onMenuItemClick="toggle" alignRight>
        <DropdownComponent.Toggle>
          <Button outline color="secondary" className="me-2 h-100">
            {t(Labels.actions)}
            <i className="bx bx-caret-down font-size-16 align-middle" />
          </Button>
        </DropdownComponent.Toggle>
        <DropdownComponent.Menu>
          <div className="mb-0 p-2 overflow-auto bg-white" style={{ maxHeight: 295 }}>
            <Button
              color="light"
              outline
              className="border-0 d-block w-100 text-start"
              disabled={updating}
              onClick={() => {
                editorRef?.current?.autoDesignName()
              }}
            >
              {t(Labels.auto_design_name)}
            </Button>
            <Button
              color="light"
              outline
              className="border-0 d-block w-100 text-start"
              disabled={updating}
              onClick={() => {
                editorRef?.current?.autoMockUpName()
              }}
            >
              {t(Labels.auto_mockup_name)}
            </Button>
            <Button
              color="light"
              outline
              className="border-0 d-block w-100 text-start"
              disabled={updating}
              onClick={() => {
                editorRef?.current?.autoDesignAndMockup()
              }}
            >
              {t(Labels.auto_design_and_mockup)}
            </Button>
          </div>
        </DropdownComponent.Menu>
      </DropdownComponent>
      <Button color="info" className="me-2" disabled={updating} onClick={() => dataRefresh()}>
        <HiOutlineRefresh size={16} className="me-1 mb-1" />
        {t(Labels.refresh)}
      </Button>
      <Button color="success" className="me-2" disabled={updating} onClick={handleSaveBulkUpdateData}>
        <i className="bx bx-save pt-1 me-1"></i>
        {t(Labels.save)}
      </Button>
      <Button color="danger" className="me-2" disabled={updating} onClick={handleCancelBulkEdit}>
        <i className="bx bx-x pt-1 me-1"></i>
        {t(Labels.close)}
      </Button>
    </>
  )
}
