import React, { useState } from "react";
import { Badge, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { FulfillOrderDto } from "../../../data/services/fulfill-order.service";
import RemoteTable from "../../../components/table/RemoteTable";
import { fulfillOrderByOrderIdSelector, fulfillOrderByOrderIdState } from "../../../data/atoms/fulfill-order.atom";
import {
  FulfillStatusColor,
  FulfillStatusIssueColor,
  FulfillStatusIssueName,
  FulfillStatusName,
  FulfillDesignStatusColor,
  FulfillDesignStatusName
} from "../../../types/fulfill-status.type";
import { RouteNames } from "../../../routes";
import { t } from "../../../core/translations";
import { Labels } from "../../../common/labels";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { portableModalState } from "../../../data/atoms/app.atom";
import { FulfillOrderActions } from "../../../data/actions/fulfill-order.action";
import { OrderActions } from "../../../data/actions/order.action";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { withPermission } from "common/hooks/use-permission";
import { createLinkEmbedParam } from "utils/embed";

export interface FulfillOrdersPortableProps {
  orderId: string | number
}

export const FulfillOrdersPortable = (props: FulfillOrdersPortableProps) => {
  const [fulfillOrderById, setFulfillOrderById] = useRecoilState(fulfillOrderByOrderIdState(props.orderId))
  const fulfillOrderLoadable = useRecoilValueLoadable(
      fulfillOrderByOrderIdSelector(
        withPermission(ActionEntities.read, ResourceEntities.fulfillOrderEntity) && fulfillOrderById
      )
    )

  const handleFulfillThisOrder = async () => {
    await OrderActions.recreateFulfillProduct([props.orderId as number], () => setFulfillOrderById(+props.orderId + 0))
  }

  let columns: ColumnDescription<FulfillOrderDto>[] = [
    {
      dataField: "sku",
      text: t(Labels.fulfill_sku),
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any, index: number) => {
        return <>
          <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.ORDER_ITEMS}/${row.id}`)}> {`${index + 1}. ${cell}`}</Link>
        </>;
      }
    },
    {
      dataField: "variationTitle",
      text: t(Labels.variantion)
    },
    {
      dataField: "status",
      text: t(Labels.status),
      headerStyle: { width: 120 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return <Col style={{ marginTop: -10, marginBottom: -10}}>
          <Badge color={FulfillStatusColor[cell] || "light"} pill>{FulfillStatusName[cell] || cell}</Badge>
          { row.designStatus ? (
            <Row className="m-0 mt-1" xs="auto">
              <Badge color={FulfillDesignStatusColor[cell] || "light"} pill>
                { FulfillDesignStatusName[row.designStatus] || row.designStatus }
              </Badge>
            </Row>
          ) : null
          }
        </Col>;
      }
    },
    {
      dataField: "statusIssue",
      text: t(Labels.stage),
      headerStyle: { width: 120 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return <Col style={{ marginTop: -10, marginBottom: -10}}>
          <Badge color={FulfillStatusIssueColor[cell] || "light"} pill>{FulfillStatusIssueName[cell] || cell}</Badge>
        </Col>;
      }
    }
  ];

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col className="m-0 p-0">
            <h5 className="m-0 py-2">{t(Labels.order_items)}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="p-2">
        <RemoteTable
          dataLoadable={[fulfillOrderLoadable]}
          keyField={"id"}
          renderInCard={false}
          simple={true}
          tableClasses="react-bootstrap-table-small"
          columns={columns}
        />
      </CardBody>
    </Card>
  );
}
