import { Labels } from "common/labels";
import { Messages } from "common/messages";
import { LoadableComponent } from "components/common/LoadableComponent";
import { WithPermission } from "components/common/WithPermission";
import { FormikForm, FormValidation } from "components/form/FormikForm";
import { FormikInput } from "components/form/FormikInput";
import ActionButton from "components/input/ActionButton";
import Breadcrumb from "components/layout/Breadcrumb";
import { SmallContainer } from "components/layout/SmallContainer";
import { Logger } from "core/logger";
import { Toasts } from "core/notification/notifications";
import { t } from "core/translations";
import { ProductTemplateActions } from "data/actions/product-template.action";
import { channelOrderSettingState, portableModalState } from "data/atoms/app.atom";
import { productTemplateIdSelector, suggestionVariantOptionKeySelector } from "data/atoms/product-template.atom";
import { ProductTemplateDto, ProductVariantDto, ProductVariantOption, UpdateProductTemplateDto } from "data/services/product-template.service";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { FormikHandlers, FormikHelpers, FormikState, useFormik } from "formik";
import htmlToDraft from "html-to-draftjs";
import { isEmpty, isEqual, isNumber, omit, pick } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardLink, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { RouteNames } from "routes";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { AdminUtils } from "utils/admin-utils";
import { Forms } from "utils/forms";
import { cartesian, preprocessPage } from "utils/utils";
import { productTemplateSchema } from "./validations/product-template.validation";
import { FormikInputVariant } from "./components/FormikInputVariant";
import Creatable from "react-select/creatable";
import Select, { MultiValue } from "react-select";
import { BiLinkExternal } from "react-icons/bi";
import { FulfillProductSelect } from "pages/fulfillments/components/fulfill-product.select";
import { FulfillProductType } from "types/fulfill-product-type.type";
import { FormLabel } from "react-bootstrap";
import { TooltipComponent } from "components/common/TooltipComponent";
import { FaRegCopy } from "react-icons/fa";
import { ModalButtons, ModalContent } from "components/modal/portable-modal";
import Gallery from "react-grid-gallery";
import { ProductTemplateVariantForm } from "./components/product-template-variation";
import { FulfillProductDto } from "data/services/fulfill-product.service";
import { Global } from "common/global";
import { ProductTemplateOptionCreatableSelect } from "components/common/product-template-option-creatable-select";
import { FormikInputField } from "../../components/form/FormikInputField";
import { FulfillOptionProductTemplateForm } from "./components/FulfillVariantProductTemplate";
import { FormikInputCustom } from "components/form/FormikInputCustom";
import { DragDropContext, Droppable, Draggable, DroppableProvided } from "react-beautiful-dnd";
import { GrDrag } from "react-icons/gr";
import { createLinkEmbedParam } from "utils/embed";

const propertiesPickClone = [ 'handle', 'regularPrice', 'salePrice', 'categories', 'tags', 'imageUrls', 'name', 'description', 'options', 'maxPrice', 'minPrice', 'sku', 'variants']

//! TODO: wrong went clone data
export const ProductTemplatePage = (props: any) => {
  const history = useHistory();
  const params = useParams<Record<string, any>>();
  const location = useLocation();
  const isEditing = +params.id > 0;
  const parentProductTemplate = (location.state as any)?.productTemplate
  const [productTemplate, setProductTemplate] = useState<any>({});
  const dataLoadable = useRecoilValueLoadable(productTemplateIdSelector(params.id));
  const channelOrderSetting = useRecoilValue(channelOrderSettingState);
  const [initialData, setInitialData] = useState({} as any)
  const [variantsTable, setVariantsTable] = useState([] as any)
  const [originalVariants, setOriginalVariants] = useState<any>()
  
  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.product_template), model: productTemplate})
  }, [productTemplate])

  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(productTemplateIdSelector(params.id));
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state =="hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable();
  }, [params.id]);

  useEffect(() => {
    if (dataLoadable.state == "hasValue" && isEditing) {
      let newProductTemplate = {
        ...(isEditing
          ? {}
          : {
              options: AdminUtils.defaultProductVariantOptions,
              customDataKeys: channelOrderSetting?.customDataKeys || [],
            }),
        ...(dataLoadable.contents
          ? {
              ...dataLoadable.contents,
              options: dataLoadable.contents?.options?.map((item: any) => (item.id ? item : { ...item, id: item?.name })),
            }
          : {}),
      }
      setProductTemplate((current: any) => {
        return {...current || {}, ...newProductTemplate}
      });
      setInitialData(newProductTemplate)
    }
  }, [dataLoadable.state]);
  
  useEffect(() => {
    const newProductTemplate = pick(parentProductTemplate, propertiesPickClone)
    setProductTemplate(newProductTemplate)
    if (parentProductTemplate) {
      setInitialData(newProductTemplate)
    }
  }, [parentProductTemplate])

  useEffect(() => {
    if (props.location.state?.fulfillProduct) {
      const fulfillProduct: FulfillProductDto = props.location.state?.fulfillProduct
      let fulfillProductModelTransfer: ProductTemplateDto = {
        name: fulfillProduct?.name,
        options: fulfillProduct?.variantOptions?.map((option: any) => ({
          id: option?.name || Math.random(),
          name: option?.name,
          optionValues: option?.value
        })),
        fulfillableProducts: [fulfillProduct],
        minPrice: fulfillProduct?.baseCost,
        maxPrice: fulfillProduct?.baseCost,
        fulfillProduct: fulfillProduct
      }
      setProductTemplate(fulfillProductModelTransfer)
    }
  }, [])

  const validation = useFormik({
    initialValues: {...productTemplate, parentId: parentProductTemplate?.id},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: productTemplateSchema,
    onSubmit: (value: any, helpers) => handleSubmit(value, helpers)
  });

  useEffect(() => {
    setOriginalVariants(initialData?.variants)
  }, [initialData])

  const handleSubmit = async (values: ProductTemplateDto, helpers: FormikHelpers<any>) => {
    try {
      helpers.setErrors({});
      helpers.setSubmitting(true);

      const newFulfillOptions: any = values.fulfillOptions?.filter((item: any) => !isEmpty(item)).map((item: any) => ({...item, fulfillVariantId: item.fulfillVariant?.id})) ;
      const newVariants = values?.variants?.map((item: any) => omit(item, ['_bulkUpdated', 'idx']))?.filter((item: any) => !isEmpty(item))
      values = {
        ...values,
        fulfillOptions: newFulfillOptions?.map((item: any) => omit(item, ['idx','fulfillVariant'])) || [],
        options: values?.options?.filter(option => !isEmpty(option))?.map(option => omit(option, 'id')) || [],
        variants: newVariants
      };
      let changedData: UpdateProductTemplateDto = isEditing ? Forms.getChangedValues(values, productTemplate) : values;

      let result = await ProductTemplateActions.save(productTemplate.id || "", changedData);
      if (result?.id) {
        setProductTemplate((current: any) => {
          return {
          ...current,
          ...{...result, options: result.options?.map((option: any) => ({...option, id: option?.name}))}
        }});
        !isEditing && setTimeout(() => {
          history.replace(RouteNames.PRODUCTS_TEMPLATES_DETAIL.replace(":id", result.id));
        }, 100);
        Toasts.success(t(Messages.save_product_successfully));
      }
    } catch (e) {
      helpers.setSubmitting(false);
      Logger.error("submit error", e);
    }
  };

  const handleClone = () => {
    history.push(createLinkEmbedParam(`${RouteNames.PRODUCTS_TEMPLATES}/create`).to, { productTemplate })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <SmallContainer>
          <Breadcrumb goBack={RouteNames.PRODUCTS_TEMPLATES} title={isEditing ? t(Labels.product_templates) : t(Labels.create_product_template)}>
          {isEditing && (
              <WithPermission action={ActionEntities.update} resource={ResourceEntities.productTemplateEntity}>
                <Button className="me-2" color="info" onClick={handleClone}>
                  <i className="bx bx-duplicate font-size-16 align-middle me-1"></i>
                  {t(Labels.clone)}
                </Button>
              </WithPermission>
            )}
            <WithPermission
              action={isEditing ? ActionEntities.update : ActionEntities.create}
              resource={ResourceEntities.productTemplateEntity}
            >
              <ActionButton color="success" onClick={() => validation.submitForm()}>
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable}>
            <FormikForm validation={validation}>
              <Card>
                <CardHeader className="fw-400 fs-5 text-info">{t(Labels.general_information)}</CardHeader>
                <CardBody>
                  <ProductTemplateForm validation={validation} data={initialData} id={params.id} />
                </CardBody>
              </Card>
              <ProductTemplateImage validation={validation} data={initialData} id={params.id} />
              <Card>
                <CardHeader className="fw-400 fs-5 text-info">
                  <Label className="col-form-label p-0">{t(Labels.options)}</Label>
                </CardHeader>
                <CardBody>
                  <ProductTemplateVariantOption 
                    validation={validation} 
                    data={initialData} 
                    id={params.id}
                    setVariantsTable={setVariantsTable}
                    originalVariants={originalVariants}
                    setOriginalVariants={setOriginalVariants}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="fw-400 fs-5 text-info">{t(Labels.variants)}</CardHeader>
                <CardBody>
                  <ProductTemplateVariantForm
                    validation={validation}
                    data={initialData}
                    id={params.id}
                    variantsTable={variantsTable}
                    originalVariants={originalVariants}
                    setOriginalVariants={setOriginalVariants}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="fw-400 fs-5 text-info">{t(Labels.fulfill_information)}</CardHeader>
                <CardBody className="pt-2">
                <WithPermission action={ActionEntities.update} resource={ResourceEntities.productTemplateEntity}>
                  <FulfilProductTemplateForm validation={validation} data={initialData} id={params.id} />
                </WithPermission>
                </CardBody>
              </Card>
            </FormikForm>
          </LoadableComponent>
        </SmallContainer>
      </div>
    </React.Fragment>
  );
};

export interface ProductTemplateChildrenFormProps {
  data?: ProductTemplateDto;
  id: string | number;
  validation: FormValidation;
}

export const ProductTemplateForm = (props: ProductTemplateChildrenFormProps) => {
  const { validation, id } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const isEditing = +id

  const onEditorStateChange = (editorState: any, onInputChange: Function) => {
    setEditorState(editorState);
    const edittorToHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onInputChange(edittorToHtml)
  };
  useEffect(() => {
    const htmlDescription = props.data?.description;
    if (htmlDescription) {
      const contentBlock = htmlToDraft(htmlDescription);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState1 = EditorState.createWithContent(contentState);
        setEditorState(editorState1);
      }
    }
  }, [props.data?.description]);
  
  const handleCopyArray = (value: Array<string> | null) => {
    if (value?.length) {
      let valueTranser = value?.map((str: string) => str)?.join(',')
      navigator.clipboard.writeText(valueTranser)
      Toasts.info(Labels.copied_to_clipboard)
    } else {
      Toasts.warning(Labels.copied_fail)
    }
  }

  useEffect(() => {
    if (!isEditing) {
      let newVariants = validation.values?.variants?.map((variant: any) => ({
        ...variant,
        price: validation.values?.maxPrice,
        sku: validation.values?.sku
      }))
      validation.setFieldValue('variants', newVariants)
    }
  }, [validation.values?.maxPrice, validation.values?.sku])

  return (
    <>
      <Row className="mb-2">
        <Col sm={6}>
          <FormikInputField
            name="sku"
            label={t(Labels.sku)}
            type="text"
            validation={validation}
            placeholder={t(Labels.sku_hint)}
          />
        </Col>
        <Col sm={6}>
          <FormikInputField
            name="name"
            label={t(Labels.product_name)}
            type="text"
            validation={validation}
            placeholder={t(Labels.product_name_hint)}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={6}>
          <FormikInputField
            label={t(Labels.min_price)}
            name="minPrice"
            type="number"
            validation={validation}
            placeholder={t(Labels.min_price_hint)}
          />
        </Col>
        <Col sm={6}>
          <FormikInputField
            label={t(Labels.max_price)}
            name="maxPrice"
            type="number"
            validation={validation}
            placeholder={t(Labels.max_price_hint)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={6}>
          <Row className="d-flex flex-column">
            <Col className="d-flex gap-1">
              <FormLabel>{`${t(Labels.categories)}`}</FormLabel>
              <TooltipComponent tooltip={t(Labels.click_to_copy_categories)}>
                <FaRegCopy 
                  size={16} 
                  color={'blue'} 
                  cursor={'pointer'} 
                  className={'mb-1'} 
                  onClick={() => handleCopyArray(validation.values?.categories)} 
                />
              </TooltipComponent>
            </Col>
            <Col>
              <FormikInput
                name="categories"
                validation={validation}
                customInput={({ handleChange, handleBlur, errorData }) => (
                  <Creatable
                    isMulti={true}
                    formatCreateLabel={(value) => `${t(Labels.create)} "${value}"`}
                    placeholder={t(Labels.categories_hint)}
                    value={validation?.values?.categories?.map((item: string) => ({ value: item }))}
                    getOptionLabel={(option: any) => option.label || option.value}
                    getOptionValue={(option: any) => option.value}
                    onBlur={handleBlur}
                    onChange={(values: any) => handleChange(values.map((item: any) => item.value))}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
        <Row className="d-flex flex-column">
            <Col className="d-flex gap-1">
              <FormLabel>{`${t(Labels.tags)}`}</FormLabel>
              <TooltipComponent tooltip={t(Labels.click_to_copy_tags)}>
                <FaRegCopy 
                  size={16} 
                  color={'blue'} 
                  cursor={'pointer'} 
                  className={'mb-1'} 
                  onClick={() => handleCopyArray(validation.values?.tags)} 
                />
              </TooltipComponent>
            </Col>
            <Col>
            <FormikInput
              name="tags"
              validation={validation}
              customInput={({ handleChange, handleBlur, errorData }) => (
                <Creatable
                  isMulti={true}
                  formatCreateLabel={(value) => `${t(Labels.create)} "${value}"`}
                  placeholder={t(Labels.tags_hint)}
                  value={validation?.values?.tags?.map((item: string) => ({ value: item }))}
                  getOptionLabel={(option: any) => option.label || option.value}
                  getOptionValue={(option: any) => option.value}
                  onBlur={handleBlur}
                  onChange={(values: any) => handleChange(values.map((item: any) => item.value))}
                  styles={errorData.reactSelectErrorStyle}
                />
              )}
            />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <FormikInputCustom
            label={t(Labels.description)}
            name="description"
            type="text"
            validation={validation}
            placeholder={t(Labels.product_template_desc_hint)}
            customInput={({ handleBlur, onInputChange, handleFocus }) => {
              return <Editor
                editorState={editorState}
                editorClassName="editorClassName"
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                onEditorStateChange={(editorState: any) => onEditorStateChange(editorState, onInputChange)}
                onFocus={() => handleFocus()}
              />;
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export interface ProductTemplateVariantOptionProps {
  data?: ProductTemplateDto;
  id?: string | number;
  validation: FormValidation;
  setVariantsTable: Function
  originalVariants: any
  setOriginalVariants: Function
}

export const ProductTemplateVariantOption = (props: ProductTemplateVariantOptionProps) => {
  const { validation, setVariantsTable } = props;
  const [autoFocusName, setAutoFocusName] = useState<boolean>(false)

  const addVariantOption = () => {
    let lastValue = validation.values?.options?.length
      ? validation.values?.options[validation.values?.options?.length - 1] : {};
      lastValue = omit(lastValue, 'id')

    if (!lastValue || (!isEmpty(lastValue) && Object.values(lastValue)?.filter(v => v)?.length)) {
      validation.setFieldValue("options", [...validation.values?.options, {id: Math.random()}]);
    }
    if (isEmpty(lastValue) && !validation.values?.options?.length) {
      validation.setFieldValue("options", [{id: Math.random()}]);
    }
    setAutoFocusName(true)
  };

  const [items, setItems] = useState<any[]>(validation.values?.options);

  useEffect(() => {
    setItems(validation.values?.options as any[])
  }, [validation.values?.options])

  const handleDragEnd = useCallback(({ source, destination }) => {
    if(isNumber(source.index)){
      setItems(oldItems => {
        const newItems = oldItems.slice(); // Duplicate
        const [temp] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, temp);
        validation.setFieldValue('options', newItems) 
        const newVariants = convertOptionsValues(newItems)
        validation.setFieldValue('variants', newVariants)
        return newItems;
      });
    }
  }, [validation.values?.sku, validation.values?.maxPrice, validation.values.variants]);

const getListOptionsName = (options: ProductVariantOption[]) => {
  let listOptions = options?.filter((option: ProductVariantOption) => !isEmpty(option?.optionValues))
  let listOptionsName = listOptions?.map((option: ProductVariantOption) => option?.name)
  return listOptionsName
}


const convertOptionsValues = (options: ProductVariantOption[]) => {
  let listOptions = options?.filter((option: ProductVariantOption) => !isEmpty(option?.optionValues))
  let listOptionsValues = listOptions?.map((option: ProductVariantOption) => option?.optionValues)
  let listOptionsName = getListOptionsName(options)
  let optionsValuesTransfer = (listOptionsValues && listOptionsValues?.length) ? cartesian(...listOptionsValues as any) : []

  let listVariants = validation.values.variants?.map((variant: any, idx: number) => {
    const optionValue = optionsValuesTransfer && optionsValuesTransfer[idx]

    return {
      ...variant,
      title: Array.isArray(optionValue) ? optionValue?.join(' / ') : optionValue
    }
  })  

  return listVariants
}

  return (
    <>
      <Row className="mb-2">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="root">
          {(provided: DroppableProvided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items?.map((option, index) => (
                  <VariantOptionComponent 
                    key={option.id} 
                    option={option} 
                    index={index} 
                    validation={validation} 
                    fieldName="options"
                    setVariantsTable={setVariantsTable}
                    originalVariants={props.originalVariants}
                    setOriginalVariants={props.setOriginalVariants}
                    autoFocusName={autoFocusName}
                    setAutoFocusName={setAutoFocusName}
                  />
                ))}
                {provided.placeholder}
                <Row>
                  <Col>
                    <Button color={"light"} onClick={addVariantOption}>
                      <i className="bx bx-plus font-size-16 align-middle mb-1"></i>
                      {t(Labels.add_variant_option)}
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          }}
          </Droppable>
      </DragDropContext>
      </Row>
    </>
  )
};

export interface VariantOptionComponentProps {
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  option: ProductVariantOption & {id: string | number};
  fieldName: string;
  index: number;
  setVariantsTable: Function
  originalVariants: any
  setOriginalVariants: Function
  autoFocusName: boolean
  setAutoFocusName: Function
}

export interface DeleteValue {
  name: string
  value: string
}

const VariantOptionComponent = (props: VariantOptionComponentProps) => {
  let { validation, option, index, setVariantsTable, originalVariants, setOriginalVariants } = props;
  const [optionName, setOptionName] = useState(option?.name)
  const setPortableModal = useSetRecoilState(portableModalState)
  const enableAutoVariation = Global?.settings?.enableProductTemplateAutoVariation
  const [optionValuesBackup, setOptionValuesBackup] = useState()

  const getVariantOptions = () => {
    return validation.values?.options?.length ? validation.values?.options : [{}];
  };

  const handleCopyValue = (index: number) => {
    let value = validation?.values?.options[index]?.optionValues?.map((v: string) => v).join(',') || ''
    if (value) {
      navigator.clipboard.writeText(value)
      Toasts.info(Labels.copied_to_clipboard)
    } else {
      Toasts.warning(Labels.copied_fail)
    }
  }

  const deleteOptionAndFilterVariants = (deleteOption: any, listVariants = validation.values?.variants) => {
    // Delete option in list variants
    let variantsDeleted: any = listVariants
    deleteOption?.optionValues?.forEach((deleteValue: any, index: number) => {
      variantsDeleted = variantsDeleted?.map((variant: any) => {
        let newOptions = variant?.options?.filter((opt: any) => opt?.name != deleteOption?.name || opt?.value != deleteValue)
        let newTitle = newOptions?.map((opt: any) => opt?.value)?.join(' / ')
        return {...variant, title: newTitle, options: newOptions}
      })
    })
    
    // Filter title duplicate
    let variantsFiltered: any = []
    variantsDeleted?.forEach((variant: any) => {
      if (!checkExistTitle(variantsFiltered, variant)) {
        variantsFiltered.push(variant)
      }
    })
    return variantsFiltered
  }

  const checkOptionExistInVariants = (deleteOption: any) => {
    let listVariants = validation.values?.variants
    let isExist = listVariants?.some((variant: any) => {
      return variant?.options?.some((option: any) => option?.name == deleteOption?.name)
    })
    return isExist
  }

  const handleDeleteOption = () => {
    // Prevent remove option if value exist in options variants
    let newValues = [...getVariantOptions()]
    let deleteOption = newValues[props.index]

    let isExistVariants = checkOptionExistInVariants(deleteOption)
    if (isExistVariants) {
      Toasts.warning(t(Messages.cannot_delete_option))
    } else {
      let variantsFiltered = deleteOptionAndFilterVariants(deleteOption)
      validation.setFieldValue('variants', variantsFiltered)
      newValues.splice(props.index, 1)
      validation.setFieldValue("options", newValues)
    }
  }

  const getNewOptionsValues = (values: MultiValue<any>) => {
    let newOptionsValues: any = []
    values?.map((value: any) => value?.value || value)?.forEach((value: string) => {
      if (value?.includes(',')) {
        newOptionsValues.push(...value?.split(',')?.map((str: string) => str?.trim()))
      } else {
        newOptionsValues.push(value)
      }
    })
    return newOptionsValues
  }

  const getListOptionsName = (options: ProductVariantOption[]) => {
    let listOptions = options?.filter((option: ProductVariantOption) => !isEmpty(option?.optionValues))
    let listOptionsName = listOptions?.map((option: ProductVariantOption) => option?.name)
    return listOptionsName
  }

  const convertOptionsValues = (options: ProductVariantOption[]) => {
    let listOptions = options?.filter((option: ProductVariantOption) => !isEmpty(option?.optionValues))
    let listOptionsValues = listOptions?.map((option: ProductVariantOption) => option?.optionValues)
    let listOptionsName = getListOptionsName(options)
    let optionsValuesTransfer = (listOptionsValues && listOptionsValues?.length) ? cartesian(...listOptionsValues as any) : []

    let listVariants = optionsValuesTransfer?.map((value: any, i: number) => ({
      title: Array.isArray(value) ? value?.join(' / ') : value,
      sku: `${validation.values?.sku}-${Array.isArray(value) ? value?.join('-') : value }`,
      price: validation.values?.maxPrice,
      options: Array.isArray(value) 
        ? value?.map((str: string, index: number) => ({
            name: listOptionsName[index],
            value: str
          }))
        : [{
            name: listOptionsName[0],
            value: value
          }]
    }))
    return listVariants
  }

  const checkExistTitle = (listVariant: Array<any>, variant: any) => {
    return listVariant?.some((item: any) => item?.title == variant?.title)
  }

  const variantsWhenAddNewOption = (newValueAdded: any, newOptions: any) => {
    let newVariants: any = []
    if (validation.values?.variants?.length > 0) {
      newVariants = validation.values?.variants?.map((variant: any) => {
        let listNamesOption = newOptions?.map((option: any) => option?.name)
        let newOption = listNamesOption?.map((name: string, index: number) => ({
          name: name,
          value: variant?.options?.find((option: any) => option?.name == name)?.value 
            || newValueAdded?.value
        }))
        return {
          ...variant,
          title: newOption?.map((option: any) => option?.value)?.join(' / '),
          options: newOption
        }
      })
    } else {
      newVariants = [...convertOptionsValues(newOptions)]
    }
    return newVariants
  }

  const deleteValueOption = (deleteValue: DeleteValue, listVariants: any) => {
    let variantsDeleted: any = []

    listVariants?.forEach((variant: any) => {
      let isExistOptionVariant = variant?.options?.some((opt: any) => opt?.name == deleteValue?.name && opt?.value == deleteValue?.value)
      if (!isExistOptionVariant) {
        variantsDeleted.push(variant)
      }
    })

    return variantsDeleted
  }
  
  const updateVariantsAndOptions = (options: any, option: any, values: MultiValue<any>) => {
    let newValues = values?.filter((value: any) => value?.__isNew__)
    let newVariants: any = []
    if (!isEmpty(newValues)) {
      let values = getNewOptionsValues(newValues)
      let newOptions = options?.map((opt: any) => {
        if (opt?.name == option?.name) {
          return {...opt, optionValues: values}
        }
        return opt
      })
      const newValueOption = options?.find((opt: any) => opt?.name == option?.name)
      const newValueOptionAdded = newOptions?.find((opt: any) => opt?.name == option?.name)
      const isAddNewValueOption = newValueOptionAdded?.optionValues?.length == newValueOption?.optionValues?.length

      if (isAddNewValueOption) {
        newValueOptionAdded?.optionValues?.forEach((value: any, index: number) => {
          if (index == 0) {
            newVariants = variantsWhenAddNewOption({name: newValueOptionAdded?.name, value: newValueOptionAdded?.optionValues[0]}, newOptions)
          } else {
            let newOptionsDeletedFirstValue = newOptions?.map((option: any) => {
              if (option?.name == newValueOptionAdded?.name) {
                return {
                  ...option,
                  optionValues: option?.optionValues?.filter((value: any, idx: number) => idx != 0)
                }
              } else { return {...option} }
            })
            newVariants = [...newVariants, ...convertOptionsValues(newOptionsDeletedFirstValue)]
          }
        })
      } else {
        newVariants = [...validation.values?.variants, ...convertOptionsValues(newOptions)]
      }
      validation.setFieldValue("options", options)
    } else {
      let deleteValue: any = {}
      options?.forEach((opt: any) => {
        if (opt?.name == option?.name) {
          option?.optionValues?.forEach((str: string, idx: number) => {
            if (!opt?.optionValues?.includes(str)) {
              deleteValue = {...opt, value: str}
            }
          })
        }
      })
      newVariants = validation.values?.variants
      let variantsDeletedValueOption = deleteValueOption(deleteValue, validation.values?.variants)
      let newOriginalVariants = deleteValueOption(deleteValue, originalVariants)

      if (newOriginalVariants?.length < originalVariants?.length) {
        setPortableModal({
          open: true,
          title: t(Labels.warning),
          content: (
            <>
              <ModalContent>
                <FormGroup className="mb-3">
                  <p>{t(Messages.really_want_to_delete_value, {value: deleteValue?.value})}</p>
                </FormGroup>
              </ModalContent>
              <ModalButtons
                confirm={() => handleDeleteVariant(newOriginalVariants, variantsDeletedValueOption, options)}
                confirmText={t(Labels.yes)}
              />
            </>
          )
        })
      } else {
        newVariants = variantsDeletedValueOption
        setOriginalVariants(newOriginalVariants)
        validation.setFieldValue("options", options)
      }
    }
    setVariantsTable(newVariants)
  }

  const handleDeleteVariant = (newOriginalVariants: any, newVariants: any, options: any) => {
    setOriginalVariants(newOriginalVariants)
    setVariantsTable(newVariants)
    validation.setFieldValue("options", options)
  }

  const handleChangeValues = (values: MultiValue<any>) => {
    let newValues = [...getVariantOptions()]
    let option = newValues[props.index]
    let newOptionsValues: any = getNewOptionsValues(values)
    if (option) {
      newValues[props.index] = {
        ...option, optionValues: newOptionsValues
      }
    }

    if (enableAutoVariation) {
      updateVariantsAndOptions(newValues, option, values)
    } else {
      validation.setFieldValue("options", newValues)
    }
  };

  const handleBlurOptionName = (e: any) => {
    let newOptions: any
    let checkExistOptionName = getVariantOptions()?.some((option: any, index: number) => 
      option?.name == e.target.value && props.index != index);
    if (checkExistOptionName) {
      Toasts.error(t(Messages.please_change_option_name))
      newOptions = getVariantOptions()?.map((option: any, index: number) => {
        if (index == props.index) {
          setOptionValuesBackup(option?.optionValues)
          return {}
        } 
        return option
      })
    } else {
      newOptions = getVariantOptions().map((option: any, idx: any) => {
        if (idx === props.index) {
          if (optionValuesBackup) {
            return { ...option, name: e.target.value, optionValues: optionValuesBackup }
          }
          return { ...option, name: e.target.value }
        }
        return option
      }) || []
    }
    props.setAutoFocusName(false)
    validation.setFieldValue("options", newOptions)
  }

  return (
    <Draggable draggableId={`${option?.id}`} index={index}>
      {(provided, snapshot) => {

    return <div className="mb-2 d-flex" ref={provided.innerRef}  {...provided.draggableProps} >
      <Col className="pe-0 d-flex">
        <Col className="flex-grow-0 d-flex flex-column justify-content-center me-2">
          <span className="mt-1 pt-2" {...provided.dragHandleProps}>
              <GrDrag size={20} color="inkLightest" />
          </span>
        </Col>
        <Col  className="d-flex gap-3">
          <Col className="mb-2" md={4}>
            <FormikInputVariant
              name={`options`}
              label={`${t(Labels.name)} ${props.index + 1}`}
              style={{ height: 38 }}
              placeholder={t(Labels.name_hint)}
              validation={validation}
              index={index}
              onChange={(value = "") => setOptionName(value)}
              value={optionName || ""}
              onBlur={(e: any) => handleBlurOptionName(e)}
              autoFocus={props.autoFocusName}
            />
          </Col>
          <Col className="ps-0 flex-grow-1" >
            <Col className="d-flex flex-column">
              <Col className="d-flex gap-1">
                <FormLabel>
                  {`${t(Labels.value)} ${props.index + 1}`}
                </FormLabel>
                <TooltipComponent tooltip={t(Labels.click_to_copy_variant_option_value)}>
                  <FaRegCopy 
                    size={16} 
                    color={'blue'} 
                    cursor={'pointer'} 
                    className={'mb-1'} 
                    onClick={() => handleCopyValue(props.index)} 
                  />
                </TooltipComponent>
              </Col>
              <Col className="px-0 ">
                <FormikInput
                  validation={validation}
                  name="optionsValue"
                  customInput={({ handleBlur, handleChange, errorData }) => (
                    <ProductTemplateOptionCreatableSelect
                      placeholder={t(Labels.value_hint)}
                      value={props.option?.optionValues?.map((value: string) => ({ value }))}
                      onChange={(values: MultiValue<any>) => handleChangeValues(values)}
                      inputValue={validation.values?.options[index]?.name ? undefined : ''}
                    />
                  )}
                />
              </Col>
            </Col>
          </Col>
        </Col>
      </Col>
      <div className="d-flex align-items-center col-auto">
        <a
          className="ms-2 pt-2"
          color={"danger"}
          onClick={
            enableAutoVariation 
            ? () => {
              setPortableModal({
                open: true,
                title: t(Labels.warning),
                content: (
                  <>
                    <ModalContent>
                      <FormGroup className="mb-3">
                        <p>{t(Messages.really_want_to_delete_option)}</p>
                      </FormGroup>
                    </ModalContent>
                    <ModalButtons
                      confirm={handleDeleteOption}
                      confirmText={t(Labels.yes)}
                    />
                  </>
                )
              })
            }
            : () => {
              let newValues = [...getVariantOptions()]
              newValues.splice(props.index, 1)
              validation.setFieldValue("options", newValues)
            }}
        >
          <i className="mdi mdi-delete font-size-18 " style={{ color: "red" }} />
        </a>
      </div>
    </div>
      }}
    </Draggable>
  )
};


const FulfilProductTemplateForm = (props: ProductTemplateChildrenFormProps) => {
  const { validation } = props;
  const refIdFulfillProduct = useRef<any>(false)

  return (
    <>
      <Row className="mb-2">
        <Col xs={"auto"} className="pe-0">
          <Label className="col-form-label">{t(Labels.fulfillable_Products)}</Label>
        </Col>
        <FormikInput
          validation={validation}
          name="fulfillableProducts"
          customInput={({ handleChange, handleBlur, errorData }) => {
            const values = validation.values.fulfillableProducts?.map((fulfillableProduct: any) => {
              if (!fulfillableProduct.fulfillService) {
                const fulfillService = (validation.values?.fulfillableServices || [validation.values.fulfillService]).find(
                  (item: any) => item && item.id === fulfillableProduct.fulfillServiceId
                )
                return { ...fulfillableProduct, fulfillService }
              }
              return { ...fulfillableProduct }
            })
            return (
              <FulfillProductSelect
                validation={validation}
                isMulti={true}
                value={values || []}
                onChange={(value: any) => {
                  handleChange(value)
                  const findFulfillProduct = value?.find((item: any) => item.id === validation.values.fulfillProduct?.id)
                  if (!findFulfillProduct && validation.values.fulfillProduct) {
                    validation.setFieldValue("fulfillProduct", undefined)
                  }
                }}
                onBlur={handleBlur}
                styles={errorData.reactSelectErrorStyle}
              />
            )
          }}
        />
      </Row>
      <Row className="mb-2">
        <Col xs={"auto"} className="pe-0">
          <Label className="col-form-label">{t(Labels.default_fulfill_product)}</Label>
        </Col>
        <Col className="p-0 d-flex align-items-center">
          <Link
            target="_blank"
            to={createLinkEmbedParam(validation.values.fulfillProduct?.id ? `${RouteNames.FULFILL_PRODUCTS}/${validation.values.fulfillProduct?.id}` : "").to}
            className="ps-1"
          >
            <span> </span>
            <BiLinkExternal size="18" />
          </Link>
        </Col>
        <FormikInput
          validation={validation}
          name="fulfillProduct"
          customInput={({ handleChange, handleBlur, errorData }) => {
            const values = validation.values.fulfillProduct && {
              ...validation.values.fulfillProduct,
              fulfillService:
                validation.values.fulfillProduct?.fulfillService ||
                validation.values.fulfillableServices?.find(
                  (value: any) => value.id === validation.values.fulfillProduct?.fulfillServiceId
                ) ||
                validation.values.fulfillService,
            }
            const options = validation.values.fulfillableProducts?.map((fulfillableProduct: any) => {
              if (!fulfillableProduct.fulfillService) {
                const fulfillService = (validation.values?.fulfillableServices || [validation.values.fulfillService]).find(
                  (item: any) => item && item?.id === fulfillableProduct.fulfillServiceId
                )
                return { ...fulfillableProduct, fulfillService }
              }
              return { ...fulfillableProduct }
            })

            return (
              <Select
                options={options}
                value={values || null}
                onChange={(value: any) => {
                  handleChange(value)
                  refIdFulfillProduct.current = true
                }}
                getOptionValue={(option: FulfillProductType) => option?.id}
                getOptionLabel={(option: FulfillProductType) =>
                  t(option?.name && `${option?.name} (${option.fulfillService?.name || "Unknown"})`)
                }
                styles={errorData.reactSelectErrorStyle}
              />
            )
          }}
        />
      </Row>
      <Col>
        <FulfillOptionProductTemplateForm isCreateFulfillOption={refIdFulfillProduct.current} validation={validation} />
      </Col>
    </>
  )
};



export interface GalleryType {
  src: string,
  thumbnail: string
  thumbnailWidth: number | string
  thumbnailHeight: number | string
  isSelected: boolean
}

export const ProductTemplateImage = (props: ProductTemplateChildrenFormProps) => {
  const { validation, data } = props
  const [imagesState, setImagesState] = useState<GalleryType[]>([])
  const [deleteImageStatus, setDeleteImageStatus] = useState(false)
  const setPortableModal = useSetRecoilState(portableModalState)

  useEffect(() => {
    const check = imagesState?.filter((item: GalleryType) => item.isSelected === true)
    if (check?.length) {
      setDeleteImageStatus(true)
    } else {
      setDeleteImageStatus(false)
    }
  }, [imagesState])

  useEffect(() => {
    const listImageFromValidation = validation.values?.imageUrls?.map((item: string) => ({
      src: item,
      thumbnail: item,
      thumbnailHeight: 'auto',
      thumbnailWidth: 'auto',
      isSelected: false
    }))
    setImagesState(listImageFromValidation)
  }, [validation.values?.imageUrls])

  const handleSelectImage = (index: number, image: any) => {
    let newImages: GalleryType[] = [...imagesState]
    newImages[index].isSelected = !imagesState[index].isSelected
    setImagesState(newImages)
  } 

  const handleDeleteImage = () => {
    const newImages = imagesState?.filter((item) => item.isSelected === false)
    validation.setFieldValue('imageUrls', newImages.map((item: GalleryType) => item.src))
    setImagesState(newImages)
  }

  const handleAddMedia = () => {
    setPortableModal({
      open: true,
      title: t(Labels.add_image),
      content: (
        <>
          <AddMediaPopup validation={validation} />
        </>
      )
    })
  }
  
  const setStyleAddMedia = (node: any) => {
    node?.style.setProperty("min-height", "150px", "important")
  }

  return (
    <Card>
      <CardHeader>
        <div className="d-flex justify-content-between">
          <div className="">
            <CardTitle>{t(Labels.images)}</CardTitle>
          </div>
          <div className="d-flex flex-row">
            {
              deleteImageStatus
              ? <Button className={`m-1`} color="danger" onClick={handleDeleteImage}>{t(Labels.delete_image)}</Button>
              : null
            }
            <Button className="m-1" color="primary" onClick={handleAddMedia}>{t(Labels.add_media_from_url)}</Button>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Row className="mb-4">
          {
            imagesState?.length
            ? <Gallery 
                images={imagesState} 
                onSelectImage={handleSelectImage} 
                backdropClosesModal={true}
              />
            : <div>
                <div className={`dropzone mt-1`} ref={setStyleAddMedia}>
                  <div className='dz-message needsclick mt-2'>
                    <CardLink
                      className="pt-2"
                      style={{borderBottom: '1px solid', fontSize: '15px'}}
                      onClick={handleAddMedia}
                    >
                      {t(Labels.add_media_from_url)}
                    </CardLink>
                  </div>
                </div>
              </div>
          }
        </Row>
      </CardBody>
    </Card>
  )
}

export interface AddMediaPopupProps {
  validation: FormValidation
}

export const AddMediaPopup = (props: AddMediaPopupProps) => {
  const {validation} = props
  const [listImageUrl, setListImageUrl] = useState('')

  const handleChangeImageUrl = (value: any) => {
    setListImageUrl(value.target.value)
  }

  const handleSubmitPopup = () => {
    const images = (listImageUrl.trim().replace(/\s/g, ',').split(','))?.filter((item: string) => item !== '')
    const imagesFilter = images.filter((item) => item.startsWith('https://'))

    const temp = [
      ...(validation.values?.imageUrls ? validation.values?.imageUrls : []),
      ...imagesFilter
    ]
    validation.setFieldValue('imageUrls', temp)
  }

  return (
    <>
      <ModalContent>
        <FormGroup className="mb-3">
          <Label>{t(Labels.list_url)}</Label>
          <Input type="textarea" rows="6" value={listImageUrl} onChange={handleChangeImageUrl}/>
        </FormGroup>
      </ModalContent>
      <ModalButtons
        confirm={handleSubmitPopup}
      />
    </>
  )
} 


export default ProductTemplatePage