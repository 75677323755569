import { AccountServices } from "./../services/account.service";
import { defaultPageInfo } from "./../../components/table/RemoteTable";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "core/rest-api/query-builder";
import { IdentityServices } from "../services/identity.service";

export const accountQueryState = atomFamily<QueryParams<any>, any>({
  key: "accountQueryState",
  default: (params: any) => {
    return { ...params }
  },
})

export const accountSelector = selector({
  key: "accountSelect",
  get: async ({ get }) => {
    let query = get(accountQueryState(defaultPageInfo))
    let pageData = await AccountServices.query(query, {
      snoozeErrorMessage: true,
    })
    return pageData
  },
})

export const listRoleAtom = atom({
  key: "listRoleAtom",
  default: [],
})

export const listRoleSelector = selector({
  key: "listRoleSelector",
  get: async ({ get }) => {
    const dataState = get(listRoleAtom)

    if(dataState.length){
      return dataState
    }
    const newData = await IdentityServices.getRoleList()
    return newData
  },
})

export const accountIdState = atomFamily<QueryParams<any>, any>({
  key: "fulfillProductIdState",
  default: (id: any) => {
    return id
  },
})

export const accountIdSelector = selectorFamily({
  key: "fulfillProductIdSelector",
  get:
    (id: any) =>
    async ({ get }) => {
      let accountId = get(accountIdState(id))

      if (+accountId > 0) {
        let data = await AccountServices.getById(+accountId, {
          snoozeErrorMessage: false,
        })
        return data
      }

      return {}
    },
})

export const listTenantAtom = atom({
  key: "listTenantAtom",
  default: [],
})

export const listTenantSelector = selector({
  key: "listTenantSelector",
  get: async ({ get }) => {
    const listTenantState = get(listTenantAtom)
    const newData = await IdentityServices.getTenantList()
    return newData
  },
})


export const getListTeamAtom = atom({
  key: "getListTeamAtom",
  default: [],
})

export const getListTeamSelector = selector({
  key: "getListTeamSelector",
  get: async ({ get }) => {
    const listTeamState = get(getListTeamAtom)
    const newData = await IdentityServices.getTeamList()
    return newData
  },
})
