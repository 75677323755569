import { Col, Container, Row } from "reactstrap";

export const SmallContainer = (props: any) => {
  let { children, ...rest } = props
  return (
    <Container { ...rest }>
      <Row>
        <Col xs={12} md={10}>
          { children }
        </Col>
        <Col>
        </Col>
      </Row>
    </Container>
  )
}