import { apiBaseUrls } from "../rest-api-config";
import { CrudService } from "./crud.service";
import { Metafield } from "../../types/metafield.type";
import { FulfillmentStatus } from "../../types/fulfillment-status.type";
import { OrderStatus } from "../../types/order-status.type";
import { OrderLineItem } from "../../types/order.type";
import { FulfillOrderApiPaths } from "./fulfill-order.service";

export interface OrderDto {
  id: string | number;
  status: string;
  fulfillmentStatus: string;
  customerNote?: string;
  phone?: string;
  email?: string;
  subtotal?: string;
  shippingMethod?: string;
  shippingTotal?: string;
  shippingAddress?: any;
  billingAddress?: any;
  number?: string;
  total?: string;
  orderCreatedAt?: Date;
  lineItems?: OrderLineItem[];
  metafields?: Metafield[];
  //xuanduc edit
  saleChannel?: any;
  channelOrderId?: string;
  childId: string;
  parentId: string;
  fulfillmentIssue?: string
}

export interface CreateOrderDto {
  fulfillmentStatus: FulfillmentStatus;
}

export interface UpdateOrderDto {
  fulfillmentStatus?: FulfillmentStatus,
  status?: OrderStatus
}

export enum OrderActionEnum {
  FULFILL = "fulfill",
  CHANGE_FULFILL_SERVICE = 'change_fulfill_service',
  CHECK_DESIGN_FOLDER = 'check_design_folder',
  RECREATE_FULFILL_ORDER = "recreate_fulfill_order",
  CREATE_FULFILL_DESIGN = 'create_fulfill_design',
  SET_FULFILL_DESIGN_URL = 'set_fulfill_design_url',
  CREATE_FULFILLMENT_ORDER = 'create_fulfillment_order',
  CHANGE_VENDOR = 'change_vendor'
}

export interface OrderActionResponse {
  success: boolean;
}

export const OrderApiPaths = {
  BASE: "/orders",
  WITH_ID: "/orders/{id}",
  QUERY: "/orders/query",
  BULK: "/orders/bulk",
  BULK_ACTION: "/orders/actions",
  COUNT_FULFILLMENT_STATUS: '/orders/count/fulfillment-status'
};

export class OrderService extends CrudService<any, CreateOrderDto, UpdateOrderDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: OrderApiPaths.WITH_ID,
        updateById: OrderApiPaths.WITH_ID,
        deleteById: OrderApiPaths.WITH_ID,
        query: OrderApiPaths.QUERY,
        base: OrderApiPaths.BASE,
        deleteMany: OrderApiPaths.BULK,
        updateMany: OrderApiPaths.BULK
      }
    });
  }

  async fulfillOrders(ids: string[] | number[], value?: any) {
    let response = await this.restApi.post(OrderApiPaths.BULK_ACTION, {
      data: {
        ids,
        action: OrderActionEnum.FULFILL, 
        data: value
      }
    });
    return this.returnDataOrThrow(response);
  }

  async getCountByFulfillmentStatus() {
    const response = await this.restApi.get(OrderApiPaths.COUNT_FULFILLMENT_STATUS, { snoozeErrorMessage: true });
    return this.returnDataOrThrow(response);
  }
}

export const OrderServices = new OrderService();
