import { ActionMeta, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import React, { useEffect, useState } from "react";
import { FormikHandlers, FormikState } from "formik/dist/types";
import { FormikHelpers } from "formik";
import { FulfillProductDto } from "../../../data/services/fulfill-product.service";
import { t } from "../../../core/translations";
import { FulfillServiceDto, FulfillServices } from "../../../data/services/fulfill-service.service";
import { Labels } from "common/labels";

export interface FulfillServiceSelectProps {
  validation?: FormikHandlers & FormikState<any> & FormikHelpers<any>
  onChange: (value: SingleValue<FulfillProductDto>, actionMeta?: ActionMeta<any>) => void
  value?: FulfillProductDto | any,
  menuPortalTarget?: any
  isMulti?: boolean
  autoFocus?: boolean
}

export const FulfillServiceSelect = (props: FulfillServiceSelectProps) => {
  const [value, setValue] = useState(props.value)
  useEffect(() => { setValue(props.value) }, [props.value])

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={true}
      isMulti={props.isMulti || false}
      value={props.isMulti ? value : value?.id ? value : undefined}
      getOptionValue={(option: FulfillServiceDto) => `${option?.id}`}
      getOptionLabel={(option: FulfillServiceDto) => t(option?.name)}
      loadOptions={(search: string) => FulfillServices.search(search)}
      placeholder={t(Labels.select_provider_hint) + '...'}
      onBlur={props.validation?.handleBlur}
      onChange={props.onChange}
      autoFocus={props.autoFocus}
      />
  )
}