import { RequestOptions, RestApiOptions } from "../../core/rest-api/rest.api";
import { QueryParams } from "../../core/rest-api/query-builder";
import { RestApiService } from "../../core/rest-api/rest-api.service";

export interface CrudPathNames {
  getById: string
  updateById: string
  deleteById: string
  query: string,
  base: string,
  updateMany?: string,
  deleteMany?: string,
  searchVendor?: string
}

export interface CrudServiceOptions extends RestApiOptions {
  pathNames: CrudPathNames
}

export class CrudService<DTO, CreateDTO, UpdateDto> extends RestApiService {
  pathNames: CrudPathNames

  constructor(options: CrudServiceOptions) {
    super(options);
    this.pathNames = options.pathNames
  }

  async getById(id: number, options?: RequestOptions) {
    let response = await this.restApi.get(this.pathNames.getById, {
      params: { id },
      ...options
    })
    return this.returnDataOrThrow(response)
  }

  async getByIds(ids: string[] | number[], options?: RequestOptions) {
    let response = await this.restApi.query(this.pathNames.query, { filter: { id: { in: ids } }, limit: Math.max(200, ids.length)}, options)
    return this.returnDataOrThrow(response)
  }

  async query(params: QueryParams<DTO>, options?: RequestOptions) {
    let response = await this.restApi.queryPaging(options?.baseUrl || this.pathNames.query, params, options)
    return this.returnDataOrThrow(response)
  }

  async getOne(params: QueryParams<DTO>, options?: RequestOptions) {
    let response = await this.restApi.queryPaging(this.pathNames.query, { ...params, limit: 1 }, options)
    let data = this.returnDataOrThrow(response)
    return data.pageItems && data.pageItems[0]
  }

  async createOne(createData: CreateDTO, options: RequestOptions = {}) {
    let response = await this.restApi.post(this.pathNames.base, {
      data: createData,
      ...options
    })
    return this.returnDataOrThrow(response)
  }

  async updateOne(id: number, updateData: UpdateDto, options: RequestOptions = {}) {
    let response = await this.restApi.put(this.pathNames.updateById, {
      params: { id },
      data: updateData,
      ...options
    })
    return this.returnDataOrThrow(response)
  }

  async deleteOne(id: number, options: RequestOptions = {}) {
    let response = await this.restApi.delete(this.pathNames.deleteById, {
      params: { id },
      ...options
    })
    return this.returnDataOrThrow(response)
  }

  async deleteMany(ids: number[] | string[], options: RequestOptions = {}) {
    if(!this.pathNames.deleteMany) throw 'Unsupported API'

    let response = await this.restApi.delete(this.pathNames.deleteMany, {
      data: { ids },
      ...options
    })
    return this.returnDataOrThrow(response)
  }

  async updateMany(ids: number[] | string[], updateData: UpdateDto, options: RequestOptions = {}) {
    if(!this.pathNames.updateMany) throw 'Unsupported API'

    let response = await this.restApi.put(this.pathNames.updateMany, {
      data: { ids, data: updateData },
      ...options
    })
    return this.returnDataOrThrow(response)
  }

  async bulkUpdate(dataById: Record<number, UpdateDto>, options: RequestOptions = {}) {
    if(!this.pathNames.updateMany) throw 'Unsupported API'

    let response = await this.restApi.put(this.pathNames.updateMany, {
      data: { dataById },
      ...options
    })
    return this.returnDataOrThrow(response)
  }

  async bulkAction(path: string, ids: string[] | number[], action: string, data?: any) {
    let response = await this.restApi.post(path, {
      data: {
        ids,
        action: action,
        data
      }
    });
    return this.returnDataOrThrow(response);
  }

  async import(data: FormData, apiPath: string, options?: RequestOptions) {
    let response = await this.restApi.post(apiPath, {
      data: data,
      timeout: 60 * 60000,
      ...(options || {})
    })
    return response
  }
}
