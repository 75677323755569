import { Labels } from "common/labels"
import { t } from "core/translations"

export enum FulfillmentInvoiceStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export const FulfillmentInvoiceStatusValues = [
  FulfillmentInvoiceStatus.PENDING,
  FulfillmentInvoiceStatus.COMPLETED,
  FulfillmentInvoiceStatus.CANCELLED,
]

export const FulfillmentInvoiceStatusName: Record<string, string> = {
  [FulfillmentInvoiceStatus.PENDING]: t(Labels.pending_payment),
  [FulfillmentInvoiceStatus.COMPLETED]: t(Labels.settlemented),
  [FulfillmentInvoiceStatus.CANCELLED]: t(Labels.revoke),
  
}
