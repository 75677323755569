import React, { useEffect, useState } from "react";
import { FormikHelpers } from "formik";
import { Alert, Form, FormProps } from "reactstrap";
import { FormikHandlers, FormikState } from "formik/dist/types";
import { Forms } from "../../utils/forms";
import { Toasts } from "../../core/notification/notifications";
import { t } from "../../core/translations";
import { Labels } from "../../common/labels";
import { Messages } from "../../common/messages";
import { Logger } from "core/logger";
import { flattenDeep } from "lodash";

export interface ProductTypeFormProps extends FormProps {
  error?: string;
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  children: any;
}

export type FormValidation = FormikHandlers & FormikState<any> & FormikHelpers<any>;

export const FormikForm = (props: ProductTypeFormProps) => {
  const { children, error, validation, onSubmit, ...rest } = props;
  const [errorMessage, setErrorMessage] = useState(error);
  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => setErrorMessage(props.error || ""), [error]);
  useEffect(() => {
    // Logger.debug('submitData', validation.values)
    let errors = Forms.getFormikErrorMessages(validation.errors);
    if(validation.isSubmitting){
      setErrors(errors);
    }
    if (validation.isSubmitting && errors.length) {
      Toasts.error(t(Messages.form_has_invalid_field));
    }
  }, [validation.isSubmitting, validation.errors]);

  const onFormChange = () => {
    validation.setErrors({});
    // validation.setTouched({});
  };

  return (
    <Form
      {...rest}
      onChange={onFormChange}
      onSubmit={props.onSubmit ? props.onSubmit : (e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}>
      {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}
      {!errors.length ? null : (
        <Alert color="danger">
          {
          	// fix error display error message
            flattenDeep(errors).map((msg, index) => <li key={index}>{msg}</li>)
          }
        </Alert>
      )
      }
      {children}
    </Form>
  );
};
