import { useState } from "react"
import { BsQuestionCircle } from "react-icons/bs"
import { Label, UncontrolledTooltip } from "reactstrap";

export interface LabelTooltipProps {
  tooltip: string,
  label?: string,
  required?: boolean,
  className?: string,
  children?: any
}

export const LabelTooltip = (props: LabelTooltipProps) => {
  const { tooltip, className, children } = props
  const [id] = useState(`tooltip-${Math.random() * 10}`.replace('.', ''))
  return (
    <div className={className || ''}>
      <UncontrolledTooltip placement={'top'} target={id}>
        { tooltip }
      </UncontrolledTooltip>
      <div className="d-flex">
        { children }
        <BsQuestionCircle id={id} className={'mx-1'}/>
      </div>
    </div>
  )
}