export enum ResourceEntities {
  settingEntity = 'SettingEntity',
  saleChannelEntity = 'SaleChannelEntity',
  fulfillServiceEntity = 'FulfillServiceEntity',
  fulfillProductEntity = 'FulfillProductEntity',
  fulfillVariantEntity = 'FulfillVariantEntity',
  fulfillmentEntity = 'FulfillmentEntity',
  orderEntity = 'OrderEntity',
  artworkTemplateEntity = 'ArtworkTemplateEntity',
  productTypeEntity = 'ProductTypeEntity',
  productEntity = 'ProductEntity',
  artworkEntity = 'ArtworkEntity',
  fulfillOrderEntity = 'FulfillOrderEntity',
  userEntity = 'UserEntity',
  fulfillmentOrderEntity = 'FulfillmentOrderEntity',
  utmEntity = 'UtmEntity',
  productTemplateEntity = 'ProductTemplateEntity',
  fulfillVariantMapperEntity = 'FulfillVariantMapperEntity',
  baseCostEntity = 'BaseCostEntity',
  fulfillmentInvoiceEntity = 'FulfillmentInvoiceEntity',
  reportEntity = 'ReportEntity',
}

export const ResourceGroupEntities = {
  orders: 'Orders',
  products: 'Products',
  fulfillments: 'Fulfillments',
  settings: 'Settings',
  accounts: 'Accounts',
  analytics: 'Analytics'
}

export const resourceGroup: Record<string, Array<string>> = {
  [ResourceGroupEntities.orders]: [
    ResourceEntities.orderEntity,
    ResourceEntities.fulfillOrderEntity
  ],
  [ResourceGroupEntities.products]: [
    ResourceEntities.productEntity,
    ResourceEntities.artworkEntity,
    ResourceEntities.artworkTemplateEntity,
    ResourceEntities.productTemplateEntity,
    ResourceEntities.utmEntity,
  ],
  [ResourceGroupEntities.fulfillments]: [
    ResourceEntities.productTypeEntity,
    ResourceEntities.fulfillServiceEntity,
    ResourceEntities.fulfillProductEntity,
    ResourceEntities.fulfillVariantEntity,
    ResourceEntities.fulfillmentEntity,
    ResourceEntities.fulfillmentOrderEntity,
    ResourceEntities.fulfillVariantMapperEntity,
  ],
  [ResourceGroupEntities.settings]: [
    ResourceEntities.settingEntity
  ],
  [ResourceGroupEntities.accounts]: [
    ResourceEntities.userEntity,
  ],
  [ResourceGroupEntities.analytics]: [
    ResourceEntities.fulfillmentInvoiceEntity,
    ResourceEntities.baseCostEntity,
    ResourceEntities.reportEntity
  ],
}

export enum ActionEntities {
  all = '*',
  any = 'any',
  read = 'read',
  update = 'update',
  create = 'create',
  delete = 'delete',
}