import { apiBaseUrls } from "../rest-api-config";
import { CrudService } from "./crud.service";
import { FulfillProductDto } from "./fulfill-product.service";
import { RequestOptions } from "../../core/rest-api/rest.api";
import { Logger } from "../../core/logger";

export interface ProductTypeDto {
  id: string | number
  slug?: string
  name?: string,
  fulfillProductId?: number
  fulfillProduct?: FulfillProductDto
  fulfillServiceId?: number
  fulfillService?: any
  customDataKeys?: string[]
  productTypeKey?: string
  variantOptions: ProductTypeOption[]
  variantValueMapper: OptionValueMapper[]
  fulfillVariantMapper: FulfillVariantMapper[]
  description: string,
  code: string
  regularPrice: number
  salePrice: number
  imageBaseUrl: string
  imageSuffixes: string[]
  fulfillOptions: FulfillVariantOption[]
}

export interface FulfillVariantMapper {
  productTypeValue: string
  fulfillVariantId: number
}
export interface VariantOption {
  optionName: string
  values: string[]
}

export interface FulfillVariantOption {
  fulfillVariantId: number
  variantOptions: VariantOption[]
}
export interface CreateProductTypeDto {
  slug?: string
  name?: string,
  fulfillProduct?: FulfillProductDto
  productTypeKey?: string,
  customDataKeys?: string[]
  variantOptions?: ProductTypeOption[]
}

export interface OptionValueMapper {
  optionName: string,
  from: string[]
  to: string
}

export interface ProductTypeOption {
  name: string,
  optionKeys: string[],
  optionValues?: string[],
  fulfillOptionName?: string,
  valueMapper: Record<string, string>
}

export interface UpdateProductTypeDto extends CreateProductTypeDto {}

export const ProductTypeApiPaths = {
  BASE: '/products/types',
  WITH_ID: '/products/types/{id}',
  QUERY: '/products/types/query',
  BULK: '/products/types/bulk',
  IMPORT: '/products/types/import',
  SUGGESTION_VARIANT_OPTION_KEYS: '/common/data/variant-option-keys' 
}

export class ProductTypeService extends CrudService<any, CreateProductTypeDto, UpdateProductTypeDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: ProductTypeApiPaths.WITH_ID,
        updateById: ProductTypeApiPaths.WITH_ID,
        deleteById: ProductTypeApiPaths.WITH_ID,
        query: ProductTypeApiPaths.QUERY,
        base: ProductTypeApiPaths.BASE,
        deleteMany: ProductTypeApiPaths.BULK,
        updateMany: ProductTypeApiPaths.BULK
      }
    });
  }

  importProductTypes(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    return super.import(data, `${ProductTypeApiPaths.IMPORT}?replace_existed=${replaceExisted}`, options)
  }

  async getVariantOptionsSuggestion() {
    try {
      const response = await  this.restApi.get(ProductTypeApiPaths.SUGGESTION_VARIANT_OPTION_KEYS, {})
      return this.returnDataOrThrow(response);
    } catch (e) {
      Logger.warn(`Get variant option failed with error: ${e}`)
      return []
    }
  }
}

export const ProductTypeServices = new ProductTypeService()
