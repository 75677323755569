import React from "react";
import { Link } from "react-router-dom";

//i18n
import SidebarContent from "./SidebarContent";

import logo from "../assets/images/logo.svg";
import logoLightPng from "../assets/images/logo-light.png";
import logoLightSvg from "../assets/images/logo-light.svg";
import logoDark from "../assets/images/logo-dark.png";
import ProfileMenu from "components/dropdown/ProfileMenu";
import { createLinkEmbedParam } from "utils/embed";

const Sidebar = (props: any) => {
  const closeSidebar = () => {
    if(document.body.classList.contains("sidebar-enable")){
        document.body.classList.remove("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box" style={{paddingLeft: 0, paddingRight: 0}}>
          <Link {...createLinkEmbedParam("/")} className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="22" />
            </span>
          </Link>

          <Link {...createLinkEmbedParam("/")} className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="22" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100 bg-white">
          <SidebarContent />
        </div>
        
        <div className="profile-user">
            {/*<NotificationDropdown />*/}
            <ProfileMenu />
        </div>
        <div className="sidebar-background"></div>
        <div className="close-sidebar" onClick={closeSidebar}></div>
      </div>
    </React.Fragment>
  )
}

export default Sidebar
