import { selector } from 'recoil';
import { atom } from 'recoil';
import { SearchOption } from "../../components/table/TableFilter";
import { defaultPageInfo } from "../../common/constants";

interface dataTableDto {
  filter: any,
  size: number,
  page: number,
  sort: any,
  tab: any,
}

export interface tableDto {
  key: string,
  data: dataTableDto
}

export const tableState = atom<tableDto>({
  key: 'tableState',
  default: {
    key: '',
    data: {} as dataTableDto
  }
})

export const tableSelector = selector({
  key: 'tableSelector',
  get: ({get}) => get(tableState),
  set: ({set}, newValue: any) => {
    if (newValue) {
      set(tableState, newValue)
    }
  }
})

export interface RemoteTableQuery {
  activeTab?: any,
  size?: number,
  page?: number,
  searchOption?: SearchOption,
  searchText?: string,
  sort?: any,
  filter?: any,
}

export interface RemoteTableQueryStateType {
  key?: string,
  query?: RemoteTableQuery
  columns?: any
}

export const remoteTableQueryState = atom<RemoteTableQueryStateType>({
  key: 'remoteTableQueryState',

  default: {
    key: '',
    columns: [],
    query: {
      size: defaultPageInfo.limit,
      page: defaultPageInfo.page
    }
  }
})

export const remoteTableQuerySelector = selector<RemoteTableQueryStateType>({
  key: 'remoteTableQuerySelector',
  get: ({get}) => get(remoteTableQueryState),
  set: ({set}, newValue: any) => {
    if (newValue) {
      set(remoteTableQueryState, newValue)
    }
  }
})