import { SelectOption } from "../../common/types";
import { Input, Label, Row } from "reactstrap";

interface RadioGroupProps {
  name: string;
  items: SelectOption[];
  checkedValue: any;
  onChange: (item: any) => void;
  classNameItem?: string;
  itemsDisabled?: Array<any>
  sideComponent?: Function
}

export default function RadioGroup({ name, items, checkedValue, onChange, classNameItem, itemsDisabled, sideComponent }: RadioGroupProps) {

  const getDisableItem = (value: string) => {
    if (itemsDisabled && itemsDisabled?.length) {
      let found = itemsDisabled.some((item: any) => item == value)
      return found
    }
    return false
  }

  return (
    <>
      {
        items.map((item) => (
          <Row key={item.value}>
            <div className={`form-check form-check-inline ms-2 ${classNameItem ? classNameItem : 'mb-3'}`} key={item.value}>
              <Input
                type="radio"
                id={`${name}-${item.value}`}
                name={name}
                className="form-check-input"
                value={item.value}
                checked={item.value === checkedValue}
                onChange={() => onChange(item)}
                disabled={getDisableItem(item.value)}
              />
              <Label
                className="form-check-label"
                htmlFor={`${name}-${item.value}`}
              >
                {item.label}
              </Label>
              {sideComponent ? sideComponent(name, item.value) : null}
            </div>
          </Row>
        ))
      }
    </>
  );
}
