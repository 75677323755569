
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { FulfillOrderServices } from "../services/fulfill-order.service";
import { BulkEditorView, BulkEditingState } from "../../components/bulk/bulk-editor.type";
import { Logger } from "../../core/logger";
import { t } from "../../core/translations";
import { Messages } from "../../common/messages";

export const bulkEditFulfillOrderAtom = atomFamily<BulkEditingState | any, any>({
  key: 'bulkUpdateFulfillOrderState',
  default: (editor?: BulkEditorView) => ({
    editor: editor || BulkEditorView.FULFILL_ORDER,
    editing: false,
    updatedData: {},
    submitData: {}
  })
});

export const fulfillOrderByOrderIdState = atomFamily<any, any>({
  key: 'fulfillOrderByOrderIdState',
  default: (orderId: any) => {
    Logger.debug('fulfillOrderByOrderIdState', orderId)
    return orderId
  }
})

export const fulfillOrderByOrderIdSelector = selectorFamily({
  key: 'fulfillOrderByOrderIdSelector',
  get: (orderId: any) => async ({ get }) => {
    orderId = get(fulfillOrderByOrderIdState(orderId))

    if(!orderId) { return []}

    let query: QueryParams<any> = { filter: { orderId: { equalTo: orderId } }}
    let data = await FulfillOrderServices.query(query, { snoozeErrorMessage: true })
    return data;
  },
});

export const fulfillOrderByIdState = atomFamily<any, any>({
  key: 'fulfillOrderByIdState',
  default: (orderId: any) => {
    Logger.debug('fulfillOrderByIdState', orderId)
    return orderId
  }
})

export const fulfillOrderByIdsSelector = selectorFamily({
  key: 'fulfillOrderByIdSelector',
  get: (orderIds: any[]) => async ({ get }) => {
    orderIds = get(fulfillOrderByIdState(orderIds))

    if(!orderIds) { return []}

    let query: QueryParams<any> = { filter: { id: { contains: orderIds } }, limit: Math.max(200, orderIds.length) }
    let data = await FulfillOrderServices.query(query, { snoozeErrorMessage: true })
    return data;
  },
});

export const fulfillOrderQueryState = atomFamily<QueryParams<any>, any>({
  key: 'fulfillOrderQueryState',
  default: (params?: any) => {
    return {...defaultPageInfo, ...(params || {})}
  }
})

export const fulfillOrderSelector = selector({
  key: 'fulfillOrderSelector',
  get: async ({ get }) => {
    let query = get(fulfillOrderQueryState(defaultPageInfo))
    let fulfillOrder = await FulfillOrderServices.query(query, { snoozeErrorMessage: true })
    return fulfillOrder;
  },
});

export const fulfillOrderStatusCountSelector = selector({
  key: 'fulfillOrderStatusCountSelector',
  get: async ({ get }) => {
    let query = get(fulfillOrderQueryState(defaultPageInfo))
    let countByStatus = await FulfillOrderServices.getCountByStatus()
    return countByStatus;
  },
});

export const fulfillOrderIdState = atomFamily<any, any>({
  key: "fulfillOrderIdState",
  default: (id: any) => {
    return id;
  }
});

export const fulfillOrderIdSelector = selectorFamily({
  key: "saleChannelIdSelector",
  get: (fulfillOrderId: any) => async ({ get }) => {
    if (+fulfillOrderId > 0) {
      let data = await FulfillOrderServices.getById(+fulfillOrderId, { snoozeErrorMessage: false });
      return data;
    }
    return {};
  }
});

export type BulkEditByIdsType = {
  ids: string[] | number[],
  atomic?: any,
  editor?: string
}

export const bulkEditFulfillOrderState = atomFamily<BulkEditByIdsType, any>({
  key: 'bulkEditFulfillOrderState',
  default: (state: BulkEditByIdsType) => {
    return state
  }
})

export const bulkEditFulfillOrderSelector = selectorFamily({
  key: 'bulkEditFulfillOrderSelector',
  get: (state: BulkEditByIdsType) => async ({ get }) => {
    let editState = get(bulkEditFulfillOrderState(state))
    return FulfillOrderServices.queryForBulkEdit(editState?.ids, editState.editor, { snoozeErrorMessage: true })
  },
});
