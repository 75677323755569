import { OffsetPageInfo } from "../components/table/RemoteTable";
import { Labels } from "./labels";

export const DEFAULT_SIZE_PER_PAGE = 20;

export const defaultPageInfo: OffsetPageInfo = {
  page: 1,
  limit: DEFAULT_SIZE_PER_PAGE,
  pageCount: 0,
  totalCount: 0
};


export const trackingServiceOptions = [
  { label: Labels.tracking_more, value: "tracking_more" },
  { label: Labels.grcommerce_tracking, value: "grcommerce_tracking" }
]