import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { FilterOption, SearchOption } from "../../components/table/TableFilter";
import { ActionOption, RemoteTableSelectionData } from "../../components/table/SelectedRowAction";
import Breadcrumb from "../../components/layout/Breadcrumb";
import { t } from "../../core/translations";
import RemoteTable, { createDeleteAction } from "../../components/table/RemoteTable";
import { fulfillVariantQueryState, fulfillVariantSelector } from "../../data/atoms/fulfill-variation.atom";
import { RouteNames } from "../../routes";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { portableModalState } from "../../data/atoms/app.atom";
import { SingleInputModal } from "../../components/modal/SingleInputModal";
import { FulfillVariantActions } from "../../data/actions/fulfill-variation.action";
import { Labels } from "../../common/labels";
import { WithPermission } from "components/common/WithPermission";
import { ResourceEntities, ActionEntities } from "types/permission-type";
import { createDocumentPageName } from "../../utils/utils";
import { allFulfillServicesSelector } from "data/atoms/fulfill-service.atom";
import { createLinkEmbedParam } from "utils/embed";

export const FulfillVariantsPage = () => {
  const setPortableModal = useSetRecoilState(portableModalState)
  const refresh = useRecoilRefresher_UNSTABLE(fulfillVariantSelector)
  const [FulfillServices, setFulfillServices] = useState([])
  const fulfillServiceLoadable = useRecoilValueLoadable(allFulfillServicesSelector)

  useEffect(() => { document.title = createDocumentPageName(t(Labels.fulfill_variants)) }, [])

  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.FULFILL_VARIANTS}/${row.id}`)}> {`${row.id}`}</Link>
        </>;
      }
    },
    {
      dataField: "sku",
      text: t(Labels.fulfill_variant_sku)
    },
    {
      dataField: "variantTitle",
      text: t(Labels.variant_title)
    },
    {
      dataField: "baseCost",
      text: t(Labels.base_cost),
      headerStyle: { width: 100 },
      formatter: (cell: any, row: any) => {
        return `$${cell}`
      }
    },
    {
      dataField: "fulfillService",
      text: t(Labels.fulfill_service),
      headerStyle: { width: 140 },
      formatter: (cell: any, row: any) => {
        return `${cell?.name || ''}`
      }
    },
    {
      dataField: "fulfillProduct",
      text: t(Labels.fulfill_product),
      headerStyle: { width: 180 },
      formatter: (cell: any, row: any) => {
        return `${cell?.name || ''}`
      }
    }
  ];

  let searchOptions: SearchOption[] = [
    {
      field: "sku",
      label: t(Labels.fulfill_variant_sku)
    },
    {
      field: "variantTitle",
      label: t(Labels.variant_title)
    },
    {
      field: "id",
      label: t(Labels.id),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values }
      },
    },
  ];

  
  let filterOptions: FilterOption[] = [
    {
      name: "fulfillServiceId",
      label: t(Labels.fulfill_services),
      type: "checkbox",
      value: FulfillServices?.map((fs: any) => ({ value: fs.id, label: fs.name })) || [],
    },
  ]

  
  useEffect(() => {
    if (fulfillServiceLoadable.state == "hasValue") {
      setFulfillServices(fulfillServiceLoadable.contents || {})
    }
  }, [fulfillServiceLoadable.state])


  const actionOptions: ActionOption[] = [
    {
      name: "change_base_cost",
      label: t(Labels.change_base_cost),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillVariantEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.change_base_cost),
          content: (
            <SingleInputModal
              inputType='number'
              inputLabel={t(Labels.base_cost)}
              inputPlaceholder={t(Labels.new_base_cost_hint)}
              onSubmit={async (value: number) => value && FulfillVariantActions.bulkUpdateByIds(selectedIds, { baseCost: value })}
              onDone={refresh}
            />
          )
        })
      }
    },
    createDeleteAction(FulfillVariantActions, ResourceEntities.fulfillVariantEntity)
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={t(Labels.fulfill_variants)}
          >
            <WithPermission resource={ResourceEntities.fulfillVariantEntity} action={ActionEntities.create}>
              <Link {...createLinkEmbedParam(`${RouteNames.FULFILL_VARIANTS}/create`)}>
                <Button
                  type="button"
                  className="btn btn-success"
                >
                  <i className="bx bx-plus font-size-16 align-middle"></i>
                  {t(Labels.create)}
                </Button>
              </Link>
            </WithPermission>
          </Breadcrumb>
          <RemoteTable
            dataLoader={[fulfillVariantSelector, fulfillVariantQueryState]}
            searchOptions={searchOptions}
            filterOptions={filterOptions}
            tabOptions={[]}
            actionOptions={actionOptions}
            searchHint={t(Labels.search_by_variation)}
            defaultSearchField={"variantTitle"}
            keyField={"id"}
            columns={columns}
            refresh={refresh}
            filterKey={'fulfillVariants'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}
