import { Labels } from "common/labels";
import { WithPermission } from "components/common/WithPermission";
import DropdownComponent from "components/dropdown/DropdownComponent";
import Breadcrumb from "components/layout/Breadcrumb";
import RemoteTable from "components/table/RemoteTable";
import { FilterOption, SearchOption, TabOption } from "components/table/TableFilter";
import { ActionOption, RemoteTableSelectionData } from "../../components/table/SelectedRowAction";
import { t } from "core/translations";
import { portableModalState, viewImagesState } from "data/atoms/app.atom";
import {
  bulkEditFulfillmentOrderAtom,
  bulkEditFulfillmentOrderSelector,
  bulkEditFulfillmentOrderState,
  fulfillmentOrderQueryState,
  fulfillmentOrderSelector,
  fulfillmentOrderStatusCountSelector
} from "data/atoms/fulfillment-order.atom";
import { allFulfillServicesSelector } from "data/atoms/fulfill-service.atom";
import { format } from "date-fns";
import React, { createRef, Fragment, useEffect, useMemo, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Badge, Button, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import {
  RecoilState,
  SetterOrUpdater,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState
} from "recoil";
import { RouteNames } from "routes";
import {
  checkRequireUpdateFulfillmentOrderReason,
  FulfillmentDesignColor,
  FulfillmentDesignStatus,
  FulfillmentDesignStatusNames,
  fulfillmentDesignStatusValues,
  FulfillmentOrderColor,
  FulfillmentOrderNames,
  FulfillmentOrderStatus,
  fulfillmentOrderStatusValues
} from "types/fulfillment-order-type";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { HandleFileResultType, ImportModal, ImportType } from "./components/import-modal";
import { defaultPageInfo } from "common/constants";
import { Filter, QueryBuilder, QueryFilter } from "core/rest-api/query-builder";
import { Toasts } from "core/notification/notifications";
import { Messages } from "common/messages";
import { ExportFulfillmentOrderType, FulfillmentOrderActions, StatusBaseModel } from "data/actions/fulfillment-order.action";
import { BiLinkExternal } from "react-icons/bi";
import { ModalButtons, ModalContent } from "../../components/modal/portable-modal";
import ActionButton from "../../components/input/ActionButton";
import { TooltipComponent } from "../../components/common/TooltipComponent";
import { PortableModalStateProps } from "../../types/modal.type";
import { AdminUtils } from "utils/admin-utils";
import { BulkEditorView } from "components/bulk/bulk-editor.type";
import { BulkEditingType } from "./fulfill-orders.page";
import {
  FulfillmentOrderBulkEditor,
  FulfillmentOrderBulkEditorActions,
  FulfillmentOrderBulkEditorRef
} from "./components/fulfillment-order-bulk-edit";
import { Loading } from "components/common/Loading";
import Select from "react-select";
import { isArray, isEmpty, isNumber} from "lodash";
import { OptionalLabel } from "../../components/common/OptionalLabel";
import { exportRadioDataValues } from "./components/export-modal";
import { ExportType } from "types/export.type";
import { ModalExportFulfillmentOrder } from "./components/fulfillment-order-export-popup";
import { FaCircle, FaRegCopy } from "react-icons/fa";
import { getListTeamSelector } from "data/atoms/account.atom";
import { VendorSelect } from "pages/products/components/vendor.select";
import { BulkUpdateVendorSelectPopup } from "./components/bulk-update-vendor";
import { AccountSelect } from "./components/account.select";
import { ImportSubmittedFulfillmentOrderPopup } from "./components/fulfillment-order-import-submitted";
import moment from "moment";
import { UpdateFulfillmentDate } from "./components/fulfillment-order-update-date";
import { DateInput } from "./components/date-input";
import { SearchableSelect } from "../../components/input/SearchableSelect";
import { Image } from "react-bootstrap";
import FulfillmentOrderResult from "./components/fulfillment-order-result";
import ResultModule from "./components/result-module";
import { CreateInvoiceResultModal } from "./components/create-invoice-result-modal";
import { FulfillmentOrderBulkUpload } from "./components/upload-design";
import { PopupCreateComponent } from "./components/invoice-popup-create";
import { FulfillmentInvoiceServices } from "data/services/fulfillment-invoice.service";
import { Logger } from "core/logger";
import {  AiOutlineEye } from "react-icons/ai";
import { FulfillmentActions } from "../../data/actions/fulfillment.action";
import { createLinkEmbedParam } from "utils/embed";


export const FulfillmentOrdersPage = () => {
  const [importType, setImportType] = useState<ImportType>(ImportType.NULL)
  const refresh = useRecoilRefresher_UNSTABLE(fulfillmentOrderSelector);
  const countByStatusLoadable = useRecoilValueLoadable(fulfillmentOrderStatusCountSelector)
  const [countByStatus, setCountByStatus] = useState<any>({})
  const [FulfillServices, setFulfillServices] = useState([])
  const fulfillServiceLoadable = useRecoilValueLoadable(allFulfillServicesSelector)
  const [queryState, setQueryState] = useRecoilState(fulfillmentOrderQueryState(defaultPageInfo))
  const setPortableModal = useSetRecoilState(portableModalState)
  const [selectedIdsState, setSelectedIdsState] = useState<any[]>([])
  const [bulkEditorState, setBulkEditorState] = useState<BulkEditingType | any>()
  const history = useHistory()
  const [bulkEditFulfillmentOrderIds, setBulkEditFulfillmentOrderIds] = useRecoilState(bulkEditFulfillmentOrderState([]))
  const bulkEditFulfillmentOrderLoadable = useRecoilValueLoadable(bulkEditFulfillmentOrderSelector([] as any)) //bulkEditFulfillmentOrderIds
  const [bulkEditSelector, setBulkEditSelector] = useState<RecoilState<any>>(bulkEditFulfillmentOrderAtom(BulkEditorView.FULFILLMENT_ORDER))
  const search = useLocation().search;
  const ids = new URLSearchParams(search).get('ids') as string;
  const editType = new URLSearchParams(search).get('editType')
  const loadable = useRecoilValueLoadable(fulfillmentOrderSelector)
  const [exportType, setExportType] = useState<ExportType>(ExportType.NULL)
  const [totalItems, setTotalItems] = useState<number>()
  const fulfillmentOrderEditorRef = createRef<FulfillmentOrderBulkEditorRef>()
  let markAsSubmitting = false
  const [bulkEditSelectedIds, setBulkEditSelectedIds] = useState<Array<string>>([])
  const [teams, setTeams] = useState([])
  const loadableTeams = useRecoilValueLoadable(getListTeamSelector)
  const [modalState, setModal] = useRecoilState(viewImagesState);



  useEffect(() => {
    if(ids && editType === BulkEditorView.FULFILLMENT_ORDER){
      startBulkEdit(editType, ids?.split(',') as string[])
    }
  }, [ids])
  useEffect(() => {
    if (loadable.state == 'hasValue') {
      setTotalItems(loadable.contents?.pageInfo?.totalCount)
    }
  }, [loadable.state])
  useEffect(() => {
    if(bulkEditFulfillmentOrderLoadable.state == 'hasError') {
      setBulkEditorState((preState: any) => ({}))
    } else {
      setBulkEditorState((preState: any) => ({
        ...preState,
        data: bulkEditFulfillmentOrderLoadable.contents?.pageItems || [],
        loading: bulkEditFulfillmentOrderLoadable.contents instanceof Promise
      }))
    }
  }, [bulkEditFulfillmentOrderLoadable.contents])
  

  useEffect(() => {
    if (countByStatusLoadable.state == "hasValue") {
      setCountByStatus(countByStatusLoadable.contents || {})
    }
  }, [countByStatusLoadable.state])

  useEffect(() => {
    if (loadableTeams.state == "hasValue") {
      setTeams(loadableTeams.contents || {})
    }
  }, [loadableTeams.state])

  useEffect(() => {
    if (fulfillServiceLoadable.state == "hasValue") {
      setFulfillServices(fulfillServiceLoadable.contents || {})
    }
  }, [fulfillServiceLoadable.state])

  const startBulkEdit = (editor: BulkEditorView, ids: string[]) => {
    setBulkEditorState({ editor, data: [], loading: true })
    setBulkEditFulfillmentOrderIds({ ids, atomic: Date.now(), editor })
  }

  const popupViewInvoice = (data: any) => {
    setPortableModal({
      open: true,
      size: 'lg',
      title: Labels.edit_invoices,
      content: (<PopupCreateComponent data={data}/>)
    })
  }
  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { width: 80, minWidth: 80 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Row className="flex-column">
          <Col>
            <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.FULFILLMENT_ORDERS}/${row.id}`)}>
              {`${row?.id}`}
            </Link>
          </Col>
        </Row>;
      }
    },
    {
      dataField: "orderNumber",
      text: t(Labels.order),
      headerStyle: { minWidth: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        const handleCopy = (e: any, value: string) => {
          e.preventDefault();
          if(value){
            navigator.clipboard?.writeText(value)
          Toasts.info(Labels.copied_to_clipboard)
          }
        }
        return (
          <Row className="flex-column">
            <Col className="d-flex">
              <span style={{marginTop: 3}} className="mb-0 text-nowrap">{`${row?.orderNumber || ""}`}</span>
              {row?.orderId ? (
                <>
                  {row?.external ? (
                    <></>
                  ) : (
                    <>
                      <TooltipComponent tooltip={t(`Open order ${row.orderId}`)} className="ms-1">
                        <Link {...createLinkEmbedParam(RouteNames.ORDERS_DETAIL.replace(":id", row?.orderId))} target="_blank">
                          <BiLinkExternal />
                        </Link>
                      </TooltipComponent>
                    </>
                  )}
                  <TooltipComponent tooltip={t(`Click to copy order id ${row.orderId}`)} className="ms-1">
                    <Link {...createLinkEmbedParam("#")} onClick={e => handleCopy(e, row.orderId)}>
                      <FaRegCopy />
                    </Link>
                  </TooltipComponent>
                </>
              ) : null}
            </Col>
            <Col className="d-flex">
              <span style={{marginTop: 3}} className="mb-0 text-nowrap">{`${row?.orderItemSku || ""}`}</span>
              {row?.fulfillOrderId ? (
                <>
                  <TooltipComponent tooltip={t(`Open order item ${row.fulfillOrderId}`)} className="ms-1">
                    <Link {...createLinkEmbedParam(RouteNames.ORDER_ITEM_DETAIL.replace(":id", row?.fulfillOrderId))} target="_blank">
                      <BiLinkExternal />
                    </Link>
                  </TooltipComponent>
                  <TooltipComponent tooltip={t(`Click to copy order item id ${row.fulfillOrderId}`)} className="ms-1">
                    <Link {...createLinkEmbedParam("#")} onClick={e => handleCopy(e, row.fulfillOrderId)}>
                      <FaRegCopy />
                    </Link>
                  </TooltipComponent>
                </>
              ) : null}
            </Col>
          </Row>
        )
      }
    },
    {
      dataField: 'productVariationTitle',
      text: t(Labels.product_variation),
      headerStyle: { minWidth: 300 },
      formatter: (cell: any, row: any) => {
        const isValidFulfillVariant = row.fulfillVariant?.variantName == row.fulfillVariantName && row.fulfillService?.name == row.fulfillServiceName
        const textClassName = isValidFulfillVariant ? '' : "text-warning"
        return (
          <Row className="flex-column">
            <Col>
              <p className="mb-0">{cell}</p>
            </Col>
            <Col>
              {row.fulfillServiceName || row.fulfillVariantName ? (
                <>
                  <span className={`${textClassName} me-1 fw-medium`}>{row.fulfillServiceName || ""}:</span>
                  <span className={`${textClassName}`}>{row.fulfillVariantName || ""}</span>
                </>
              ) : null}
            </Col>
          </Row>
        )
      }
    },
    {
      dataField: "fulfillVariant",
      text: t(Labels.fulfillment),
      headerStyle: {minWidth: 400},
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any = {}) => {
        const isValidFulfillVariant = row.fulfillVariant?.variantName == row.fulfillVariantName && row.fulfillService?.name == row.fulfillServiceName
        const textClassName = isValidFulfillVariant ? '' : "text-warning"
        let status = row.status as FulfillmentOrderStatus;

        return (
          <div className="d-flex">
            <Col>
              <TooltipComponent tooltip={isValidFulfillVariant ? "" : t(Messages.This_order_is_not_linked_to_any_fulfill_variant)}>
                <p className={`${textClassName} me-1 mb-0 ${row?.fulfillSku ? "" : "text-warning"}`}>
                  <span className="fw-medium"></span> {row?.fulfillSku || Labels.no_fulfillment_variant}
                </p>
                {/* {
                  row.fulfillServiceName || row.fulfillVariantName ? (
                    <>
                      <span className={`${textClassName} me-1 fw-medium`}>{row.fulfillServiceName || ''}:</span>
                      <span className={`${textClassName}`}>{row.fulfillVariantName || ""}</span>
                    </>
                  ) : null
                } */}
                <Col>
                  {!row?.status ? null : (
                    <TooltipComponent tooltip={Labels.status} className="d-inline">
                      <Badge className="pt-1" color={FulfillmentOrderColor[status] || "light"}>
                        {FulfillmentOrderNames[status]}
                      </Badge>
                    </TooltipComponent>
                  )}
                  { !Array.isArray(row?.statusMessages) ? null : row?.statusMessages?.slice(0,2)?.map((item: any, idx: number) => (
                      <small className="blockquote-footer px-1 ms-1 alert-warning" key={idx}>
                        {item}
                      </small>
                    ))
                  }
                  <TooltipComponent tooltip={t(Labels.tracking_number)} className="d-inline">
                    {row.trackingNumber ? <span className="ms-1">{`#${row.trackingNumber}`}</span> : <></>}
                  </TooltipComponent>
                </Col>
              </TooltipComponent>
            </Col>
            <div className="w-auto"></div>
          </div>
        )
      }
    },
    {
      dataField: "baseCost",
      text: t(Labels.base_cost),
      headerStyle: {  minWidth: 180 },
      // hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        const equalBaseCost = row.fulfillBaseCost == cell;
        return (
          <Row className="flex-column">
            <Col>
              <p className="mb-0">
                {equalBaseCost && isNumber(+row.fulfillBaseCost) ? `$${row.fulfillBaseCost}` : `$${row.fulfillBaseCost} (${cell})`}
              </p>
            </Col>
            <Col>
              <p className={`pt-1 fw-bolde`}>
                <FaCircle color={row.invoiceId ? "green" : "gray"} className="me-1" />
                <span>
                  {row.invoiceId ? (
                    <>
                      <span>{Labels.created_order}</span>
                      <Link
                        onClick={async () => {
                          try {
                            setPortableModal({
                              open: true,
                              size: "lg",
                              title: Labels.edit_invoices,
                              content: (
                                <div className="p-3">
                                  <Loading />
                                </div>
                              ),
                            })

                            const result = await FulfillmentInvoiceServices.getById(row.invoiceId)
                            popupViewInvoice(result)
                          } catch (error: any) {
                            Logger.log('error ', error);
                          }
                        }}
                        to={"#"}
                        className='ms-1'
                      >
                        <AiOutlineEye size="18" />
                      </Link>
                    </>
                  ) : (
                    Labels.invoice_created_yet
                  )}
                </span>
              </p>
            </Col>
          </Row>
        )
      }
    },
    {
      dataField: "statusMessages",
      text: t(Labels.status_message),
      headerStyle: { minWidth: 150 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return cell?.map((item: any, idx: number) => <span className="d-block" key={idx}>{item}</span>)
      },
    },
    {
      dataField: "fulfiller",
      hidden: true,
      text: t(Labels.fulfiller),
      headerStyle: { width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <p className="mb-0">
            {row?.fulfiller ? (row?.fulfiller?.name || row?.fulfiller?.username) : ''}
          </p>
        </>;
      }
    },
    {
      dataField: "vendor",
      text: t(Labels.vendor),
      headerStyle: { minWidth: 100 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Col>
            <Row className="m-0">{cell?.name}</Row>
            <Row className="m-0">{cell?.username}</Row>
          </Col>
        )
      },
    },
    {
      dataField: 'customerNote',
      text: t(Labels.customer_note),
      headerStyle: { minWidth: 130 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <Fragment>
              <p className="mb-0">{t(cell)}</p>
          </Fragment>
        )
      },
    },
    {
      dataField: "design",
      text: t(Labels.design),
      headerStyle: { minWidth: 120, width: 140 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        const mockupUrl = row.designPreviewUrl
        const designUrl = row.designProductUrl
        const designFolderUrl = row.designFolderUrl

        const listImage = [mockupUrl, designUrl, designFolderUrl]

        return (
          <Col>
            <Row>
              <span>
                {row.designStatus ? (
                  <TooltipComponent tooltip={Labels.design_status} className="d-inline">
                    <Badge className="pt-1 d-inline" color={FulfillmentDesignColor[row.designStatus] || "light"}>
                      {FulfillmentDesignStatusNames[row.designStatus]}
                    </Badge>
                  </TooltipComponent>
                ) : (
                  <></>
                )}
              </span>
            </Row>
            <Row>
              <div className="d-flex gap-2">
                <a
                  href={`${designFolderUrl || "#"}`}
                  className={designFolderUrl ? "text-success" : "text-danger"}
                  onClick={e => {
                    e.preventDefault()
                    setModal({ open: true, images: listImage, url: designFolderUrl })
                  }}
                >
                  <TooltipComponent tooltip={t(Labels.design_folder_url)}>
                    <i className={`mdi ${designFolderUrl ? "mdi-cloud-check" : "mdi-close-box"} font-size-18`} />
                  </TooltipComponent>
                </a>
                <a
                  href={`${mockupUrl || "#"}`}
                  className={mockupUrl ? "text-success" : "text-danger"}
                  onClick={e => {
                    e.preventDefault()
                    setModal({ open: true, images: listImage, url: mockupUrl })
                  }}
                >
                  <TooltipComponent tooltip={t(Labels.mockup_url)}>
                    <i className={`mdi ${mockupUrl ? "mdi-cloud-check" : "mdi-close-box"} font-size-18`} />
                  </TooltipComponent>
                </a>
                <a
                  href={`${designUrl || "#"}`}
                  className={designUrl ? "text-success" : "text-danger"}
                  onClick={e => {
                    e.preventDefault()
                    setModal({ open: true, images: listImage, url: designUrl })
                  }}
                >
                  <TooltipComponent tooltip={t(Labels.design_url)}>
                    <i className={`mdi ${designUrl ? "mdi-cloud-check" : "mdi-close-box"} font-size-18`} />
                  </TooltipComponent>
                </a>
              </div>
            </Row>
          </Col>
        )
      },
    },
    {
      dataField: 'productName',
      text: t(Labels.product_name),
      headerStyle: { minWidth: 430 },
      hidden: true,
    },
    {
      dataField: "tracking",
      text: t(Labels.tracking),
      headerStyle: {  minWidth: 100 },
      // hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Row>
          {
            !row.trackingNumber ? null :
            <Col>
              <Row>
                <Col className="text-start">
                {
                  row.notifySaleChannel == "1" || row.notifySaleChannel == true ?
                    <Badge color='success' pill>{ t(Labels.channel_uploaded) }</Badge>
                    :
                    <Badge color='primary' pill>{ t(Labels.pending) }</Badge>
                }
                </Col>
              </Row>
            </Col>
          }
          <Col>
            <p className="mb-0">{row.trackingNumber}</p>
          </Col>
          <Col>
            <p className="mb-0">{row.carrierName}</p>
          </Col>
        </Row>;
      }
    },
    {
      dataField: "submittedTime",
      text: t(Labels.submitted_time),
      headerStyle: { minWidth: 140 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          {
            row?.submittedTime
              ? (<>
                  <p className="mb-0"> {format(new Date(cell), "MMM dd, yyyy")}</p>
                  <p className="mb-0"> {format(new Date(cell), "HH:mm")}</p>
                </>)
              : ''
          }
        </>;
      }
    },
    {
      dataField: "completedTime",
      text: t(Labels.completed_time),
      headerStyle: { minWidth: 140 },
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          {
            row?.completedTime
            ? (<>
                <p className="mb-0"> {format(new Date(cell), "MMM dd, yyyy")}</p>
                <p className="mb-0"> {format(new Date(cell), "HH:mm")}</p>
              </>)
            : ''
          }
        </>;
      }
    },
    {
      dataField: "fulfillmentDate",
      text: t(Labels.fulfillment_date),
      headerStyle: { minWidth: 120 },
      hidden: true,
      //eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Col>
          <Row className="m-0 w-100">{format(new Date(cell), "MMM dd, yyyy")}</Row>
        </Col>
      },
    },
    {
      dataField: "customer",
      text: t(Labels.customer),
      headerStyle: { minWidth: 380 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any = {}) => {
        let billingAddress = [row.shippingAddress1 , row.shippingAddress2, row.shippingCity, row.shippingProvinceCode, row.shippingCountryCode, row.shippingZip].filter((item: string) => item).join(", ").trim()

        return (
          <Fragment>
            <Col className="mb-0">
              <h6 className="me-1 mb-1 d-inline-block">
                {row.shippingFirstName} {row.shippingLastName}
              </h6>
              {row.shippingPhone ? <span>{`(${row.shippingPhone})`}</span> : <></>}
            </Col>
            <Col className="mb-0">
              <p className="mb-0">{billingAddress}</p>
            </Col>
          </Fragment>
        )
      },
    },
  ]

  const changeColumnOnTabChange = (activeTab: any, currColumns: ColumnDescription[]) => {
    const isSelectedCompleted = activeTab == FulfillmentOrderStatus.COMPLETED
    return currColumns.map((column) => {
      const newColumn = { ...column }
      if(column.dataField == 'tracking') {
        newColumn.hidden = !isSelectedCompleted
      }

      if(column.dataField == 'design') {
        newColumn.hidden = isSelectedCompleted
      }

      return newColumn
    })
  }

  let searchOptions: SearchOption[] = [
    {
      field: "orderNumber",
      label: t(Labels.order_number),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values.length <= 1 ? values[0] || "" : values }
      },
    },
    {
      field: "orderItemSku",
      label: t(Labels.order_item_sku),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values.length <= 1 ? values[0] || "" : values }
      },
    },
    {
      field: "id",
      label: t(Labels.id),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.split('-')[0].trim())
          .filter((val: any) => val)
        return { contains: values }
      },
    },
    {
      field: "productVariationTitle",
      label: t(Labels.product_variation),
      filterBuilder: value => {
        return { contains: value.trim() }
      },
    },
    {
      field: "fulfillVariantName",
      label: t(Labels.fulfill_variation),
      filterBuilder: value => {
        return { contains: value.trim() }
      },
    },
    {
      field: "trackingNumber",
      label: t(Labels.tracking_number),
      filterBuilder: value => {
        let values = value
          .trim()
          .split(/[, ]+/)
          .map((val: any) => val.trim())
          .filter((val: any) => val)
        return { contains: values.length <= 1 ? values[0] || "" : values }
      },
    },
  ];

  let tabOptions: TabOption[] = [
    {
      id: "all",
      label: t(Labels.all),
      filter: {},
      count: countByStatus['all'],
    },
    ...fulfillmentOrderStatusValues.map(value => ({
        id: value,
        label: FulfillmentOrderNames[value],
        filter: { status: { equalTo: value } },
        count: countByStatus && countByStatus[value] || 0
      })
    )
  ]

  let filterOptions: FilterOption[] = [
    {
      name: "status",
      label: t(Labels.status),
      type: "checkbox",
      value: fulfillmentOrderStatusValues?.map((value: any) => ({ value, label: FulfillmentOrderNames[value as FulfillmentOrderStatus] })) || [],
      hidden: true,
    },
    {
      name: "notifySaleChannel",
      label: t(Labels.tracking_status),
      type: "radio",
      hidden: true,
      value: [
        { label: t(Labels.channel_uploaded), value: "1" },
        { label: t(Labels.pending), value: "0" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
    },
    {
      name: "designStatus",
      label: t(Labels.design_file_status),
      type: "checkbox",
      value: fulfillmentDesignStatusValues?.map((value: any) => ({ value, label: FulfillmentDesignStatusNames[value as FulfillmentDesignStatus] })) || [],
      hidden: true,
    },
    {
      name: "designFolderUrl",
      label: t(Labels.design_folder_url),
      type: "radio",
      value: [
        { label: Labels.yes, value: "1" },
        { label: Labels.no, value: "0" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
      hidden: true,
    },
    {
      name: "designProductUrl",
      label: t(Labels.design_product_url),
      type: "radio",
      value: [
        { label: Labels.yes, value: "1" },
        { label: Labels.no, value: "0" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
      hidden: true,
    },
    {
      name: "fulfillServiceId",
      label: t(Labels.fulfill_services),
      type: "checkbox",
      value: FulfillServices?.map((fs: any) => ({ value: fs.id, label: fs.name })) || [],
    },
    {
      name: "fulfillVariantId",
      label: t(Labels.fulfill_variation),
      type: "radio",
      value: [
        { label: Labels.yes, value: "1" },
        { label: Labels.no , value: "0" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
      hidden: true,
    },
    {
      name: "vendorId",
      label: t(Labels.vendor),
      type: "custom",
      filterBuilder: (values: any[]) => ({ contains: values?.map(item => item.id) }),
      componentCreator: (value: any, onFilterChange: any) => {
        return <AccountSelect
          isMulti={true}
          value={value}
          onChange={(value: any) => {
            // onFilterChange('vendorId', value)
            onFilterChange('vendorId', value?.map((item: any) => ({...item, name: item.name || item.username})))
          }}
        />
      },
      hidden: true
    },
    {
      name: "teamId",
      label: t(Labels.teams),
      type: "custom",
      filterBuilder: (values: any[]) => ({ contains: values?.map(item => item.id) }),
      componentCreator: (value: any, onChange: any) => {
        return <Select 
          isMulti={true}
          isSearchable={true}
          value={value}
          options={teams || []}
          getOptionValue={(option: any) => option?.id}
          getOptionLabel={(option: any) => option?.name}
          onChange={(values: any) => {
            onChange('teamId', values)
          }}
        />
      },
      hidden: true
    },
    {
      name: "external",
      label: t(Labels.order_item_type),
      type: "checkbox",
      value: [
        { label: Labels.external_fulfill_order, value: "1" },
        { label: Labels.internal_fulfill_order, value: "0" },
      ],
      hidden: true,
    },
    {
      name: "fulfillmentId",
      label: t(Labels.fulfillment),
      type: "checkbox",
      value: [
        { label: Labels.created, value: "1" },
        { label: Labels.pending , value: "0" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
      hidden: true,
    },
    {
      name: "invoiceId",
      label: t(Labels.status_invoice),
      type: "radio",
      value: [
        { label: Labels.not_created_yet, value: "0" },
        { label: Labels.created, value: "1" },
      ],
      filterBuilder: value => ({ isEmpty: value != "1" }),
      hidden: true,
    },
    {
      name: "fulfillmentDate",
      label: t(Labels.fulfillment_date),
      type: "date",
      filterBuilder: value => ({ between: value }),
      hidden: true,
    },
    {
      name: "submittedTime",
      label: t(Labels.submitted_time),
      type: "date",
      filterBuilder: value => ({ between: value }),
      hidden: true,
    },
    {
      name: "completedTime",
      label: t(Labels.completed_time),
      type: "date",
      filterBuilder: value => ({ between: value }),
      hidden: true,
    },
  ]

  let actionOptions: ActionOption[] = [
    {
      name: "edit_selected_items",
      label: t(Labels.bulk_edit),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds } = {} as RemoteTableSelectionData) => {
        if(selectedIds){
          startBulkEdit(BulkEditorView.FULFILLMENT_ORDER, selectedIds as string[])
        }
      },
    },
    {
      name: "change_request_status",
      label: t(Labels.change_status),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, selectedItems, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.change_request_status),
          size: 'lg',
          content: (
            <UpdateStatusModal selectedItems={selectedItems} onClose={() => refresh()}/>
          )
        })
      },
    },
    {
      name: "change_vendor",
      label: t(Labels.change_vendor),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, selectedItems, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.change_vendor),
          content: (
            <>
              <BulkUpdateVendorSelectPopup
                onSubmit={async (value: any) => await FulfillmentOrderActions.changeVendor(selectedIds, value.id)}
                onDone={refresh}
              />
            </>
          ),
        })
      },
    },
    {
      name: "change_fulfillment_date",
      label: t(Labels.change_fulfillment_date),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {

        setPortableModal({
          open: true,
          title: t(Labels.change_fulfillment_date),
          content: (
            <UpdateFulfillmentDate selectedIds={selectedIds} refresh={refresh}/>
          )
        })
      },
    },
    {
      name: "upload_design",
      label: t(Labels.upload_design_file),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        const result = await FulfillmentOrderActions.bulkUpLoadDesignFile(selectedIds)
        const data = {all: selectedIds, success: [], notFound: [], skipped: [], failed: [], ...result}
        
        if(result){
          setPortableModal({
            open: true,
            title: "Design",
            content: (
              <ResultModule data={data} linkViewDetail={RouteNames.FULFILLMENT_ORDERS} onClose={refresh} />
            ),
          })
        }
      },
    },
    {
      name: "update_design_url_by_sku",
      label: t(Labels.update_design_url_by_sku),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        const result = await FulfillmentOrderActions.bulkUpdateDesignUrlBySku(selectedIds)
        const data = {all: selectedIds, success: [], notFound: [], skipped: [], failed: [], ...result}

        if(result){
          setPortableModal({
            open: true,
            title: t(Labels.update_design_url_by_sku),
            content: (
              <ResultModule data={data} linkViewDetail={RouteNames.FULFILLMENT_ORDERS} onClose={refresh} />
            ),
          })
        }
      },
    },
    {
      name: "create_invoice",
      label: t(Labels.create_invoice),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        const onClose = () => setPortableModal({open: false, title: ""})
        const data = await FulfillmentOrderActions.createInvoice(selectedIds)

        if(data){
          Toasts.success(t(Messages.create_invoice_successfully, { number: data.all?.lenght }))
          refresh()
          setPortableModal({
            open: true,
            size: 'lg',
            title: t(Labels.create_invoice_result),
            preventToggle: true,
            content: (
              <CreateInvoiceResultModal
                result={data}
                onClose={onClose}
              />
            )
          })
        }
      },
    },
    {
      name: "edit_design_options",
      label: t(Labels.edit_design_option),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds } = {} as RemoteTableSelectionData) => {
        if(selectedIds){
          startBulkEdit(BulkEditorView.EDIT_DESIGN_OPTION, selectedIds as string[])
        }
      },
    },
    {
      name: "upload_tracking_to_channel",
      label: t(Labels.upload_tracking_to_channel),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.fulfillmentOrderEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        await FulfillmentOrderActions.uploadTrackingNumberToChannel(selectedIds, refresh)
      },
    },
  ]

  const refreshData = () => {
    setQueryState(prev => ({ ...prev }))
  }

  const handleExport = async (type: ExportFulfillmentOrderType, selectedIds?: string[] | number[] | QueryFilter<any>, markAsSubmitting?: boolean, onDone?: Function) => {
    let filter: any = Array.isArray(selectedIds) ? (selectedIds as number[]) : selectedIds
    let exportResult = await FulfillmentOrderActions.exportFulfillmentOrderNew(type, filter, markAsSubmitting)
    
    if (exportResult.success) {
      Toasts.success(t(Messages.export_successfully))
      setPortableModal({
        open: true,
        size: "lg",
        title: t(Labels.export_fulfillment_order_result),
        content: (
          <ExportResultFulfillmentOrder 
            exportResult={exportResult} 
            onClose={() => {
              setPortableModal({open: false, title: ''})
              if(type != ExportFulfillmentOrderType.DEFAULT) {
                refreshData()
              }
            }}
          />
        )
      })
    }
  }
  
  const getExportData = (idsOrFilter: string[] | number[] | QueryFilter<any>, exportData: string, customExportData?: any) => {
    let filter = ((Array.isArray(idsOrFilter) && !isNaN(Number(idsOrFilter[0])) && exportData === exportRadioDataValues.selected)
      ? { id: { in: idsOrFilter }}
      : QueryBuilder.createFilter(queryState.filter as any)) as Filter<any>
    // console.log('filter order', filter, idsOrFilter);
    let dataExport: any = {...customExportData}
    let idsInCurrentPage = loadable.contents ? loadable.contents?.pageItems?.map((item: any) => item.id) : []

    switch(exportData) {
      case exportRadioDataValues.selected:
        if (!selectedIdsState.length) {
          Toasts.error(t(Messages.export_invalid))
          return
        } else {
          dataExport = {
            ...dataExport,
            filter: filter
          }
        }
        return dataExport
      case exportRadioDataValues.current_page:
        dataExport = {
          ...dataExport,
          filter: {id: {in: idsInCurrentPage}}
        }
        return dataExport
      case exportRadioDataValues.all_pages:
        dataExport = {
          ...dataExport,
          filter: filter
        }
        return dataExport
      default:
        return {}
    }
  }

  const handleExportNew = async (exportData: string, exportDataType: string) => {
    // console.log('handleExportNew ', exportData, exportDataType, markAsSubmitting)
    // let filter: any = Array.isArray(selectedIdsState) ? (selectedIdsState as number[]) : selectedIdsState
    let filter: any = getExportData(selectedIdsState, exportData)


    let exportResult: any
    if (filter) {
      let type: ExportFulfillmentOrderType;

      switch (exportType) {
        case ExportType.SUBMITTING_FULFILLMENT_ORDER:
          type = ExportFulfillmentOrderType.SUBMITTING
          break;
        case ExportType.COMPLETED_FULFILLMENT_ORDER:
          type = ExportFulfillmentOrderType.COMPLETED
          break;
        default:
          type = ExportFulfillmentOrderType.DEFAULT
          break;
      }

      exportResult = await FulfillmentOrderActions.exportFulfillmentOrderNew(type, filter, markAsSubmitting)
    }
    
    if (exportResult?.success) {
      Toasts.success(t(Messages.export_successfully))
      setPortableModal({
        open: true,
        size: "lg",
        title: t(Labels.export_fulfillment_order_result),
        content: (
          <ExportResultFulfillmentOrder 
            exportResult={exportResult} 
            onClose={() => {
              setPortableModal({open: false, title: ''})
              if(exportType === ExportType.SUBMITTING_FULFILLMENT_ORDER ) {
                refreshData()
              }
            }}
            exportType={exportType}
          />
        )
      })
    }
    else {
      setPortableModal({ open: false, title: '' });
    }
    return exportResult
  }

  // const onClickExportMenu = async (type: ExportFulfillmentOrderType, selectedIds?: string[] | number[] | QueryFilter<any>) => {
  //   if (!selectedIds) {
  //     Toasts.success(t(Messages.export_invalid))
  //     return
  //   }

  //   let markAsSubmitting = false
  //   if(type == ExportFulfillmentOrderType.SUBMITTING) {
  //     setPortableModal({
  //       open: true,
  //       title: t(Labels.export_fulfillment_order_for_submitting),
  //       content: (
  //         <>
  //           <ModalContent>
  //             <div className="text-center">
  //               { t(Messages.confirm_mask_exporting_fulfillment_order_as_submitting) }
  //             </div>
  //           </ModalContent>
  //           <ModalButtons
  //             confirmText={t(Labels.export_only)}
  //             confirm={ async () => { handleExport(ExportFulfillmentOrderType.SUBMITTING, selectedIds, false) }}
  //             customButtons={(setModal: SetterOrUpdater<PortableModalStateProps>) => (
  //               <ActionButton
  //                 type="button"
  //                 color='primary'
  //                 onClick={async () => handleExport(ExportFulfillmentOrderType.SUBMITTING, selectedIds, true, () => setModal({ open: false })) }
  //               >
  //                 { t(Labels.export_and_mask_as_submitting) }
  //               </ActionButton>
  //             )}
  //           >
  //           </ModalButtons>
  //         </>
  //       ),
  //     })
  //   } else {
  //     handleExport(ExportFulfillmentOrderType.DEFAULT, selectedIds, false)
  //   }
  // }


  const customizeModalButton = () => {
    return (
      <ModalButtons
        confirmText={t(Labels.export_only)}
        confirm={ async () => { handleExport(ExportFulfillmentOrderType.SUBMITTING, selectedIdsState, false) }}
        customButtons={(setModal: SetterOrUpdater<PortableModalStateProps>) => (
          <ActionButton
            type="button"
            color='primary'
            onClick={async () => handleExport(ExportFulfillmentOrderType.SUBMITTING, selectedIdsState, true, () => setModal({ open: false })) }
          >
            { t(Labels.export_and_mark_as_submitting) }
          </ActionButton>
        )}
      >
      </ModalButtons>
    )
  }

  const handleExitBulkEditAction = () => {
    setBulkEditorState(undefined)
    history.push(RouteNames.FULFILLMENT_ORDERS)
  }

  const handleChangeFulfillService = () => {
    // setPortableModal({
    //   open: true,
    //   title: t(Labels.change_fulfill_service),
    //   content: (
    //     <BulkUpdateSelectPopup
    //       placeholder={`${t(Labels.choose_fulfill_service)}...`}
    //       selectBuilder={(onChange) => (
    //         <FulfillServiceSelect
    //           isMulti={false}
    //           autoFocus={true}
    //           onChange={onChange}
    //         />
    //       )}
    //       onSubmit={async (value: any) => {
    //         if (!bulkEditSelectedIds?.length) {
    //           Toasts.warning(t(Messages.please_select_least_one_item))
    //         } else {
    //           console.log('submit ', value, bulkEditSelectedIds)
    //           value && await FulfillmentOrderActions.bulkEditChangeFulfillService(bulkEditSelectedIds, value?.id)
    //         }
    //       }}
    //       // onDone={refresh}
    //     />
    //   ),
    // })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={t(Labels.fulfillment_orders)}>
            {bulkEditorState?.editor ? (
              <FulfillmentOrderBulkEditorActions
                editorRef={fulfillmentOrderEditorRef}
                bulkEditSelector={bulkEditSelector}
                bulkEditorState={bulkEditorState}
                onSubmit={data => FulfillmentOrderActions.saveEditFulfillmentOrders({ ...(data || {}) })}
                onExit={() => handleExitBulkEditAction()}
                dataRefresh={() => {
                  setBulkEditFulfillmentOrderIds({
                    ids: bulkEditorState.data.map((item: any) => item.id),
                    atomic: Date.now(),
                    editor: bulkEditorState.editor,
                  })
                }}
                handleChangeFulfillService={handleChangeFulfillService}
              />
            ) : (
              <TopBarButtonFulfillmentOrder changeImportType={setImportType} changeExportType={setExportType} />
            )}
            {
              ImportType.SUBMITTED_FULFILLMENT_ORDER == importType ? (
                  <ImportSubmittedFulfillmentOrderPopup
                    onClose={() => setImportType(ImportType.NULL)}
                    onDone={refreshData}
                    replaceable={true}
                  />
                ) : <ImportModal type={importType} onClose={() => setImportType(ImportType.NULL)} onDone={refreshData} replaceable={true} />
            }
            
            <ModalExportFulfillmentOrder 
              type={exportType}
              onClose={() => setExportType(ExportType.NULL)}
              handleExport={handleExportNew}
              selectedIds={selectedIdsState}
              totalItems={totalItems}
            >
              {
                exportType == ExportType.SUBMITTING_FULFILLMENT_ORDER
                ? <div className="mt-1">
                    <p className="mb-2 fw-bold">{`${t(Labels.other_option)}:`}</p>
                    <div className="d-flex gap-2">
                      <Input type="checkbox" id="maskSubmitting" active={markAsSubmitting} onClick={() => {markAsSubmitting = !markAsSubmitting}}/>
                      <div className="d-flex gap-1">
                        <Label className="form-check-label" htmlFor='maskSubmitting'>{t(Labels.export_and_mark_as_submitting)}</Label>
                      </div>
                    </div>
                  </div>
                : null
              }
            </ModalExportFulfillmentOrder>
          </Breadcrumb>
          {bulkEditorState?.editor ? (
            bulkEditorState.loading ? (
              <Loading />
            ) : bulkEditorState.editor === BulkEditorView.EDIT_DESIGN_OPTION ? (
              <FulfillmentOrderBulkUpload
                ref={fulfillmentOrderEditorRef}
                data={bulkEditorState.data}
                editor={bulkEditorState.editor}
                bulkEditSelector={bulkEditSelector}
                setBulkEditSelectedIds={setBulkEditSelectedIds}
              />
            ) : (
              <FulfillmentOrderBulkEditor
                ref={fulfillmentOrderEditorRef}
                data={bulkEditorState.data}
                editor={bulkEditorState.editor}
                bulkEditSelector={bulkEditSelector} 
                setBulkEditSelectedIds={setBulkEditSelectedIds}
              />
            )
          ) : (
            <RemoteTable
              keyField={"id"}
              dataLoader={[fulfillmentOrderSelector, fulfillmentOrderQueryState]}
              columns={columns}
              searchOptions={searchOptions}
              tabOptions={tabOptions}
              filterOptions={filterOptions}
              actionOptions={actionOptions}
              onTabChange={changeColumnOnTabChange}
              defaultSearchField={"orderNumber"}
              refresh={refresh}
              filterKey={"fulfillmentOrder"}
              onSelectedChange={setSelectedIdsState}
              filterByIds={search ? ids : undefined}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export interface UpdateStatusModalProps {
  selectedItems: StatusBaseModel[],
  onClose: Function
}

export interface UpdateFulfillmentOrderStatusData {
  label?: string
  value?: FulfillmentOrderStatus
  message?: string
}

export const UpdateStatusModal = (props: UpdateStatusModalProps) => {
  const { selectedItems, onClose } = props
  const [updateRequestStatusData, setUpdateRequestStatusData] = useState<UpdateFulfillmentOrderStatusData>()
  const requestStatusOptions: Array<UpdateFulfillmentOrderStatusData> = fulfillmentOrderStatusValues.map((status) => ({ label: FulfillmentOrderNames[status], value: status }))
  const setPortableModal = useSetRecoilState(portableModalState)
  const [fulfillmentDate, setFulfillmentDate] = useState<any>();
  const isRequireUpdateReasonStatus = useMemo(() => () => {
    const status = updateRequestStatusData?.value
    return checkRequireUpdateFulfillmentOrderReason(selectedItems, status as FulfillmentOrderStatus)
  }, [updateRequestStatusData?.value])

  const handleChangeUpdateRequestStatusState = (newValue: UpdateFulfillmentOrderStatusData) => {
    setUpdateRequestStatusData((prevState: any) => {
      return {
        ...prevState,
        ...newValue
      }
    })
  }
  const hiddenInputDate = updateRequestStatusData?.value === FulfillmentOrderStatus.SUBMITTED

  return (
    <>
      <ModalContent>
        <FormGroup>
          <Label>{Labels.change_request_status}</Label>
          <Select
            placeholder={`${t(Labels.choose_fulfillment_order_status)}...`}
            name="option_value"
            getOptionLabel={option => option.label || ''}
            getOptionValue={option => option.value || ''}
            value={updateRequestStatusData}
            options={requestStatusOptions}
            onChange={(option: any) => handleChangeUpdateRequestStatusState(option)}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          { hiddenInputDate ? (
            <>
              <Label>{Labels.fulfillment_date}</Label>
              <DateInput value={fulfillmentDate} onChange={(date: Date) => setFulfillmentDate(date)} className="w-100 inputDatePicker" />
            </>
          ) : (
            <></>
          )}
        </FormGroup>
        <FormGroup className="mb-3">
          {isRequireUpdateReasonStatus() ? <Label>{Labels.modify_note}</Label> : <OptionalLabel>{Labels.modify_note}</OptionalLabel>}
          <Input
            type={"textarea"}
            rows={"4"}
            placeholder={Labels.modify_note_hint}
            value={updateRequestStatusData?.message}
            onChange={(e: any) => handleChangeUpdateRequestStatusState({ message: e.target.value })}
          />
        </FormGroup>
      </ModalContent>
      <ModalButtons hiddenConfirmButton={true}>
        <ActionButton
          className="btn btn-success"
          onClick={async () => {
            if (!isEmpty(updateRequestStatusData)) {
              if (!updateRequestStatusData?.value) {
                Toasts.error(t(Messages.please_input_request_status))
              } else if (!updateRequestStatusData?.message && isRequireUpdateReasonStatus()) {
                Toasts.error(t(Messages.please_input_modify_note))
              } else {
                let success = await FulfillmentOrderActions.bulkUpdateStatus(
                  selectedItems,
                  updateRequestStatusData.value,
                  updateRequestStatusData.message,
                  (hiddenInputDate && fulfillmentDate) ? moment(fulfillmentDate).toISOString() : null
                )
                if (success) {
                  setPortableModal({ open: false, title: "" })
                  onClose()
                }
              }
            }
          }}
        >
          {t(Labels.save)}
        </ActionButton>
      </ModalButtons>
    </>
  )
}

interface propsUpdateFulfillServiceModal {
  options: Array<any>
  selectedItems: StatusBaseModel[],
  onClose: Function
}

export const UpdateFulfillServiceModal = (props: propsUpdateFulfillServiceModal) => {
  const { selectedItems, onClose } = props
  const [updateRequestStatusData, setUpdateRequestStatusData] = useState<UpdateFulfillmentOrderStatusData>()

  const setPortableModal = useSetRecoilState(portableModalState)

  const isRequireUpdateReasonStatus = useMemo(() => () => {
    const status = updateRequestStatusData?.value
    return checkRequireUpdateFulfillmentOrderReason(selectedItems, status as FulfillmentOrderStatus)
  }, [updateRequestStatusData?.value])

  const handleChangeUpdateRequestStatusState = (newValue: UpdateFulfillmentOrderStatusData) => {
    setUpdateRequestStatusData((prevState: any) => {
      return {
        ...prevState,
        ...newValue
      }
    })
  }

  return (
    <>
      <ModalContent>
        <FormGroup>
          <Label>{Labels.change_fulfill_service}</Label>
          <Select
            placeholder={`${t(Labels.choose_fulfill_service)}...`}
            name="option_value"
            getOptionLabel={option => option.label || ''}
            getOptionValue={option => option.value || ''}
            value={updateRequestStatusData}
            options={props.options}
            onChange={(option: any) => handleChangeUpdateRequestStatusState(option)}
          />
        </FormGroup>
          <FormGroup className="mb-3">
              {
                isRequireUpdateReasonStatus() ? <Label>{Labels.modify_note}</Label> : <OptionalLabel>{Labels.modify_note}</OptionalLabel>
              }
              <Input 
                type={"textarea"}
                rows={'4'}
                placeholder={Labels.modify_note_hint}
                value={updateRequestStatusData?.message}
                onChange={(e: any) => handleChangeUpdateRequestStatusState({ message: e.target.value })}
              />
            </FormGroup>
      </ModalContent>
      <ModalButtons hiddenConfirmButton={true}>
        <Button className="btn btn-success" onClick={async () => {
          if (!isEmpty(updateRequestStatusData)) {
            if (!updateRequestStatusData?.value) {
              Toasts.error(t(Messages.please_input_request_status))
            } else if (!updateRequestStatusData?.message && isRequireUpdateReasonStatus()) {
                Toasts.error(t(Messages.please_input_modify_note))
              } else {
                let success = await FulfillmentOrderActions.bulkUpdateStatus(selectedItems, updateRequestStatusData.value, updateRequestStatusData.message)
                if (success) {
                  setPortableModal({ open: false, title: '' })
                  onClose()
                }
              }
          }
        }}>{t(Labels.save)}</Button>
      </ModalButtons>
    </>
  )
}

export interface TopBarButtonFulfillmentOrderProps {
  changeImportType: Function
  changeExportType: Function
}

export const TopBarButtonFulfillmentOrder = (props: TopBarButtonFulfillmentOrderProps) => {
  return (
    <React.Fragment>
      <WithPermission action={ActionEntities.create} resource={ResourceEntities.fulfillmentOrderEntity}>
        <DropdownComponent onMenuItemClick="toggle" alignRight>
          <DropdownComponent.Toggle>
            <Button outline color="secondary" className="me-2">
              {t(Labels.import)}
              <i className="bx bx-import ms-1"></i>
            </Button>
          </DropdownComponent.Toggle>
          <DropdownComponent.Menu>
            <div className="mb-0 p-2 overflow-auto bg-white" style={{ maxHeight: 295 }}>
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => {
                  props.changeImportType(ImportType.EXTERNAL_FULFILLMENT_ORDER)
                }}
              >
                {t(Labels.external_fulfillment_orders)}
              </Button>
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => {
                  props.changeImportType(ImportType.SHOPBASE_FULFILLMENT_ORDER)
                }}
              >
                {t(Labels.shopbase_fulfillment_order)}
              </Button>
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => {
                  props.changeImportType(ImportType.SUBMITTED_FULFILLMENT_ORDER)
                }}
              >
                {t(Labels.submitted_fulfillment_orders)}
              </Button>
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => {
                  props.changeImportType(ImportType.COMPLETED_FULFILLMENT_ORDER)
                }}
              >
                {t(Labels.completed_fulfillment_orders)}
              </Button>
              <Button
                outline
                color="light"
                className="border-0 d-block w-100 text-start"
                onClick={() => {
                  props.changeImportType(ImportType.UPDATE_BASE_COST)
                }}
              >
                {t(Labels.update_base_cost)}
              </Button>
            </div>
          </DropdownComponent.Menu>
        </DropdownComponent>
        <WithPermission action={ActionEntities.read} resource={ResourceEntities.fulfillOrderEntity}>
          <DropdownComponent onMenuItemClick="toggle" alignRight>
            <DropdownComponent.Toggle>
              <Button outline color="secondary" className="me-2">
                {t(Labels.export)}
                <i className="bx bx-export ms-1"></i>
              </Button>
            </DropdownComponent.Toggle>
            <DropdownComponent.Menu>
              <div className="mb-0 p-2 overflow-auto bg-white" style={{ maxHeight: 295 }}>
                <Button
                  outline
                  color="light"
                  className="border-0 d-block w-100 text-start"
                  onClick={() => props.changeExportType(ExportType.REVIEW_FULFILLMENT_ORDER)}
                >
                  {t(Labels.for_review_fulfillment_order)}
                </Button>
                <Button
                  outline
                  color="light"
                  className="border-0 d-block w-100 text-start"
                  onClick={() => props.changeExportType(ExportType.SUBMITTING_FULFILLMENT_ORDER)}
                >
                  {t(Labels.for_submitting_fulfillment_order)}
                </Button>
                <Button
                  outline
                  color="light"
                  className="border-0 d-block w-100 text-start"
                  onClick={() => props.changeExportType(ExportType.COMPLETED_FULFILLMENT_ORDER)}
                >
                  {t(Labels.completed_requests)}
                </Button>
              </div>
            </DropdownComponent.Menu>
          </DropdownComponent>
        </WithPermission>
      </WithPermission>
    </React.Fragment>
  )
}

export interface ExportResultFulfillmentOrderProps {
  exportResult: HandleFileResultType,
  onClose: any,
  exportType?: ExportType
}

export const ExportResultFulfillmentOrder = (props: ExportResultFulfillmentOrderProps) => {
  const { exportResult, onClose, exportType } = props

  return (
    <>
      <ModalContent>
        <Row>
          <div className="d-flex flex-column mb-1">
            <h5 className="mb-4">{exportResult.stats ? t(Labels.finish_exporting): exportResult?.message || t(Messages.export_successfully)}</h5>
            <div className="d-flex flex-column">
              {
                exportResult.stats && Object.entries(exportResult.stats)?.map((value: any, index: number) => (
                  <p className="mb-3 me-2 fw-bold" key={index}>{t(value[0])}: {value[1]}</p>
                ))
              }
              <div className="mb-2">
                <p className="fw-bold d-inline">{t(Labels.output_file)}:</p>
                <p className="ms-1 d-inline">{t(Labels.click_to_dowload_again)}</p>
                <span
                  className="ms-1 d-inline"
                  style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                  onClick={() => AdminUtils.startAutoDownloadOutputFile(exportResult.rawData, 'fulfillment-order')}
                >
                  {t(Labels.retry_to_dowload)}
                </span>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <Button color="secondary" onClick={() => onClose()}>{t(Labels.close)}</Button>
          </Col>
        </Row>
      </ModalContent>
    </>
  )
}