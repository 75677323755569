import { Labels } from 'common/labels';
import { Messages } from 'common/messages';
import { t } from 'core/translations';
import * as Yup from 'yup';

export const productSchema = Yup.object({
  sku: Yup.string().required(t(Messages.field_required, { field: t(Labels.sku)})),
  title: Yup.string().required(t(Messages.field_required, { field: t(Labels.title)})),
  status: Yup.string().required(t(Messages.field_required, { field: t(Labels.status)})),
  publishStatus: Yup.string().required(t(Messages.field_required, { field: t(Labels.publish_status)})),
  regularPrice: Yup.number().required(t(Messages.field_required, { field: t(Labels.regular_price)})),
  artwork: Yup.object({}).required(t(Messages.field_required, { field: t(Labels.artwork_desgin)})),
  productType: Yup.object({}).required(t(Messages.field_required, { field: t(Labels.product_type)})),
  publicationChannels: Yup.array().min(1, t(Labels.sale_channel_required)),
  owner: Yup.object({}).required(t(Messages.field_required, { field: t(Labels.vendor)})),
})