import { atomFamily, selector, selectorFamily } from "recoil"
import { QueryParams } from "../../core/rest-api/query-builder"
import { Logger } from "../../core/logger"
import { defaultPageInfo } from "../../components/table/RemoteTable"
import { FulfillmentServices } from "../services/fulfillment.service"
export const fulfillmentQueryState = atomFamily<QueryParams<any>, any>({
  key: "fulfillmentQueryState",
  default: (params: any) => {
    return { ...params }
  },
})

export const fulfillmentSelector = selector({
  key: "fulfillmentSelector",
  get: async ({ get }) => {
    let query = get(fulfillmentQueryState(defaultPageInfo))
    let pageData = await FulfillmentServices.query(query, {
      snoozeErrorMessage: false,
    })
    return pageData
  },
})


export const fulfillmentTrackingStatusCountSelector = selector({
  key: 'fulfillmentTrackingSettingStatusCountSelector',
  get: async ({ get }) => {
    let query = get(fulfillmentQueryState(defaultPageInfo))
    let countByStatus = await FulfillmentServices.getCountByStatus()
    return countByStatus;
  },
});

export const fulfillMentIdState = atomFamily<QueryParams<any>, any>({
  key: "fulfillMentIdState",
  default: (id: any) => {
    return id
  },
})

export const fulfillMentIdSelector = selectorFamily({
  key: "fulfillMentIdSelector",
  get:
    (id: any) =>
    async ({ get }) => {
      let fulfillMentId = get(fulfillMentIdState(id))

      if (+fulfillMentId > 0) {
        let data = await FulfillmentServices.getById(+fulfillMentId, {
          snoozeErrorMessage: false,
        })
        return data
      }

      return {}
    },
})
