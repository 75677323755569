import React, { useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Labels } from "../common/labels";
import { RouteNames } from "./index";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities, ResourceGroupEntities } from "types/permission-type";


const LinkCloseNav = (props: any) => {
  const {path} = props;
  document.body.classList.remove("sidebar-enable")
  return <Link to={path}>{props.children}</Link>;
}

type SidebarContentPropTypes = {
  location?: any
}



const SidebarContent = (props: SidebarContentPropTypes) => {
  const ref = useRef<any>();
  const location = useLocation()
  const [t] = useTranslation()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul?.getElementsByTagName("a") || []
      for (let i = 0; i < items.length; ++i) {
        if (pathName == items[i].pathname || pathName.match(new RegExp(`^${items[i].pathname}/([0-9]|create)+$`))) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item: any) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <LinkCloseNav path={"/dashboard"}>
                <i className="bx bx-home-circle"></i>
                <span>{t(Labels.home)}</span>
              </LinkCloseNav>
            </li>
            <li>
              <WithPermission action={ActionEntities.any} resource={ResourceGroupEntities.products}>
                <Link to="/#" >
                  <i className="bx bx-cart-alt"></i>
                  <span>{t(Labels.products)}</span>
                </Link>
              </WithPermission>
              <ul className="sub-menu" aria-expanded="false">
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.productEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.PRODUCT}>
                      {t(Labels.product)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.artworkEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.ARTWORKS}>
                      {t(Labels.artworks)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.artworkTemplateEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.ARTWORK_TEMPLATES}>
                      {t(Labels.artwork_templates)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.utmEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.SOURCES}>
                      {t(Labels.utm_tracking)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
              </ul>
            </li>
            <li>
              <WithPermission action={ActionEntities.any} resource={ResourceGroupEntities.orders}>
                <Link to="/#" >
                  <i className="bx bx-cart"></i>
                  <span>{t(Labels.orders)}</span>
                </Link>
              </WithPermission>
              <ul className="sub-menu" aria-expanded="false">
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.orderEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.ORDERS}>
                      {t(Labels.all_orders)}  
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.fulfillOrderEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.ORDER_ITEMS}>
                      {t(Labels.all_order_items)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
              </ul>
            </li>
            <li>
              <WithPermission action={ActionEntities.any} resource={ResourceGroupEntities.fulfillments}>
                <Link to="/#" >
                  <i className="bx bx-cart-alt"></i>
                  <span>{t(Labels.fulfillments)}</span>
                </Link>
              </WithPermission>
              <ul className="sub-menu" aria-expanded="false">
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.fulfillServiceEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.FULFILLMENT_ORDERS}>
                      {t(Labels.fulfillment_orders)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.fulfillProductEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.FULFILL_PRODUCTS}>
                      {t(Labels.fulfillment_products)}  
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <li>
                  <WithPermission action={ActionEntities.any} resource={ResourceEntities.fulfillVariantEntity}>
                    <LinkCloseNav path={RouteNames.FULFILL_VARIANTS}>
                      {t(Labels.fulfillment_variants)}  
                    </LinkCloseNav>
                  </WithPermission>
                </li>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.fulfillServiceEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.FULFILL_SERVICES}>
                      {t(Labels.fulfillment_services)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.fulfillVariantMapperEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.FULFILL_VARIANT_MAPPING}>
                      {t(Labels.fulfill_variant_mapper)}  
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.fulfillmentEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.FULFILLMENTS}>
                      {t(Labels.fulfillments)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.productTypeEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.PRODUCT_TYPES}>
                      {t(Labels.product_types)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
              </ul>
            </li>
            <li>
              <WithPermission action={ActionEntities.any} resource={ResourceGroupEntities.analytics}>
                <Link to="/#" >
                  <i className="bx bx-chart"></i>
                  <span>{t(Labels.reports)}</span>
                </Link>
              </WithPermission>
              <ul className="sub-menu" aria-expanded="false">
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.reportEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.REPORTS_IMPORTS}>
                      {t(Labels.imports)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
              </ul>
            </li>
            <li>
              <WithPermission action={ActionEntities.any} resource={ResourceGroupEntities.accounts }>
                <li>
                  <LinkCloseNav path={RouteNames.USERS}>
                    <i className="bx bxs-user-badge"></i>
                    {t(Labels.accounts)}
                  </LinkCloseNav>
                </li>
              </WithPermission>
            </li>
            <WithPermission action={ActionEntities.any} resource={ResourceEntities.saleChannelEntity}>
              <li>
                <LinkCloseNav path={RouteNames.STORES}>
                  <i className="bx bx-store"></i>
                  <span>{t(Labels.stores)}</span>
                </LinkCloseNav>
              </li>
            </WithPermission>
            <li>
              <WithPermission action={ActionEntities.any} resource={ResourceGroupEntities.settings}>
                <Link to="/#" >
                  <i className="bx bx-cog"></i>
                  <span>{t(Labels.settings)}</span>  
                </Link>
              </WithPermission>
              <ul className="sub-menu" aria-expanded="false">
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.settingEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.SETTING_DESIGN_STORAGE}>
                      {t(Labels.design_storage)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.settingEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.SETTINGS_CHANNEL_ORDER}>
                      {t(Labels.channel_orders)}  
                    </LinkCloseNav>
                  </li>
                </WithPermission>
                <WithPermission action={ActionEntities.any} resource={ResourceEntities.settingEntity}>
                  <li>
                    <LinkCloseNav path={RouteNames.SETTINGS_FULFILLMENT_TRACKING}>
                      {t(Labels.shipment_tracking)}
                    </LinkCloseNav>
                  </li>
                </WithPermission>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent
