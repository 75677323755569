import { cloneElement, useEffect, useState } from "react";

const Toggle = ({ children, ...props }: any) => cloneElement(children, { ...props });
const Menu = ({ children, ...props }: any) => (
  <div
    {...props}
  >
    {children}
  </div>
);

export interface DropdownComponentProps {
  children: any,
  alignRight?: any,
  id?: any,
  display?: any,
  onClose?: any,
  className?: string,
  opening?: boolean,
  onMenuItemClick?: 'toggle' | 'none' | Function
}

const DropdownComponent = (props: DropdownComponentProps) => {
  let { children, alignRight, id, display, onClose, className, opening, onMenuItemClick = 'none' } = props;
  const [open, setOpen] = useState(false);
  const changeOpenState = (open: boolean) => {
    setOpen(open);
    if (!open) {
      onClose && onClose();
    }
  };

  useEffect(() => {
    if (open == true && opening == false) {
      setOpen(false);
    }
  });

  return (
    <span style={{ position: "relative" }} className={className}>
      {
        cloneElement(children[0], { onClick: () => changeOpenState(!open) })
      }
      {
        cloneElement(children[1], {
          className: "p-0",
          onClick: () => onMenuItemClick == 'toggle' ? changeOpenState(!open) : onMenuItemClick != 'none' && onMenuItemClick(),
          style: {
            width: "max-content",
            minWidth: 200,
            boxShadow: "0 1rem 3rem rgb(0 0 0 / 18%)",
            position: "absolute",
            display: open ? display || "block" : "none",
            zIndex: 999999,
            right: alignRight ? 0 : undefined
          }
        })
      }
      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          content: "",
          top: "0px",
          left: "0px",
          opacity: 0.3,
          display: open ? display || "block" : "none",
          zIndex: 999998
        }}
        onClick={() => changeOpenState(false)}
        id={id}
      />
        </span>
  );
};

DropdownComponent.Toggle = Toggle;
DropdownComponent.Menu = Menu;
export default DropdownComponent;