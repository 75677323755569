import { isString } from "lodash"
import * as H from "history"

const paramsEmbed = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const embedToken = params.get("embedToken")
  const embedOrigin = params.get("embedOrigin") || params.get("domain")
  return { params, embedToken, embedOrigin }
}

export const createLinkEmbedParam = (url: string) => {
  const { embedToken, embedOrigin } = paramsEmbed()

  if (embedToken && url !== "#" && url !== "/") {
    let newUrl = url.endsWith("#") ? url.slice(0, -1) : url
    newUrl = embedOrigin ? embedOrigin + newUrl : newUrl
    return { to: { pathname: newUrl }, target: "_top" }
  }
  return { to: url }
}

export const createUrlEmbed = (url: string) => {
  const { embedToken, embedOrigin } = paramsEmbed()

  console.log('createUrlEmbed', embedOrigin, url)

  if (embedOrigin) {
    let cleanedUrl = url.endsWith("#") ? url.slice(0, -1) : url
    return embedOrigin + cleanedUrl
  }

  return url
}

interface LocationDescriptor {
  pathname: string
  state?: any
}

export const navigateRouteEmbed = (history: H.History<any>, location: string | LocationDescriptor, method: "push" | "replace") => {
  let newPath = isString(location) ? location : (location as LocationDescriptor).pathname
  const { embedToken, embedOrigin } = paramsEmbed()

  if (embedToken && newPath !== "#" && newPath !== "/") {
    let newUrl = newPath.endsWith("#") ? newPath.slice(0, -1) : newPath
    newUrl = embedOrigin ? embedOrigin + newUrl : newUrl
    window.open(newUrl, "_top")
    return
  }
  history[method](location)
}
