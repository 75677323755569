import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import { FulfillVariantActions } from "../../../data/actions/fulfill-variation.action";
import { PageData } from "../../../types/page-data";
import { FormikHandlers, FormikState } from "formik/dist/types";
import { FormikHelpers } from "formik";
import { createCustomSelectStyles } from "../../../components/bulk/BulkEditor";
import { SearchableSelect } from "components/input/SearchableSelect";
import { t } from "core/translations";

export const createProductSelectOption = (productData: any, select?: boolean, test?: any) => {
  if (productData?.value && productData?.label && productData?.searchText) return productData;

  let option = _.isEmpty(productData) ? undefined : {
    id: productData.id,
    product: _.pick(productData, ["id", "template", "supplier"]),
    value: (select ? productData.id : productData.template) || "",
    label: (productData.template || "") + (productData.supplier ? ` (${productData.supplier})` : ""),
    searchText: (productData.template || "")
  };

  return option;
};

export function getFulfillProductLabel(item: { variantTitle?: string, fulfillService?: { name: string } }) {
  return item?.variantTitle ?  `${item?.variantTitle} ${item?.fulfillService?.name ? `(${item?.fulfillService?.name})` : ""}` : ''
}

export interface FulfillVariantSelectProps {
  value: any
  onChange: (value: any) => void
  onBlur?: Function | any
  embedHeight?: number
  select?: boolean,
  disabled?: boolean,
  placeholder?: string,
  validation?: FormikHandlers & FormikState<any> & FormikHelpers<any>,
  styles?: any
}

export default function FulfillVariantSelect(props: FulfillVariantSelectProps) {
  const {
    embedHeight,
    value,
    onChange,
    select = false,
    disabled = false,
    placeholder = "Select...",
    validation,
    styles
  } = props

  const [productsData, setProductsData] = useState<PageData>();
  const [selectedVariant, setSelectedVariant] = useState(value);
  const [searchText, setSearchText] = useState(selectedVariant?.variantTitle || '');
  const productSelectRef = useRef<any>();

  useEffect(() => {
    setSearchText(selectedVariant?.variantTitle || '')
  }, [selectedVariant]);

  useEffect(() => {
    setSelectedVariant(value)
  }, [value])

  const onFocus = (event: any) => {
    setSearchText(selectedVariant?.variantTitle);
  }

  const onSelectChange = (value: any, actionMeta: any) => {
    if(!value) return
    setSelectedVariant(value);
    fireOnChange(value);
  };

  const fireOnChange = async (value: any) => {
    onChange && onChange(value);
  };

  const onInputChange = (value: string, actionMeta: any) => {
    if(actionMeta?.action == 'input-change' || actionMeta?.action == 'set-value') {
      setSearchText(value)
    }
  }

  return (
    <div style={{ zIndex: 999999 }}>
      <SearchableSelect
        onChange={onSelectChange}
        // onInputChange={onInputChange}
        // onFocus={onFocus}
        // inputValue={searchText}
        // ref={productSelectRef}
        blurOnSelect={true}
        isDisabled={disabled}
        // defaultOptions={embedHeight ? true : false}
        menuPortalTarget={document.body}
        styles={{
          ...createCustomSelectStyles(embedHeight),
          ...styles
        }}
        name="form-field-name"
        // onMenuClose={() => productSelectRef.current?.blur()}
        onBlur={props.onBlur}
        loadOptions={FulfillVariantActions.search}
        autoFocus={embedHeight ? true : false}
        defaultMenuIsOpen={embedHeight ? true : false}
        getOptionValue={(item: any) => item?.id}
        getOptionLabel={(item: any) => t(getFulfillProductLabel(item))}
        value={selectedVariant}
        placeholder={placeholder}
        getSearchValue={(option: any) => option?.variantTitle}
      />
    </div>
  );
}
