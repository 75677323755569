import { FulfillVariantOption, ProductTypeOption } from './product-type.service';
import { CreateFulfillProductDto, FulfillProductDto } from './fulfill-product.service';
import { CreateFulfillServiceDto, FulfillServiceDto } from 'data/services/fulfill-service.service';
import { Logger } from "core/logger";
import { apiBaseUrls } from "data/rest-api-config";
import { CrudService } from "./crud.service";
import { Option } from 'common/types';

export interface ProductTemplateDto {
  id?: string | number
  name?: string
  handle?: string
  sku?: string
  imageUrl?: string
  imageUrls?: Array<string>
  bodyHtml?: string
  description?: string
  categories?: Array<string>
  tags?: Array<string>
  regularPrice?: string
  salePrice?: string
  minPrice?: number
  maxPrice?: number
  variantOptions?: ProductVariantOption[]
  options?: ProductVariantOption[]
  fulfillOptions?: FulfillVariantOption[]
  fulfillProductId?: any
  fulfillServiceId?: any
  fulfillableProductIds?: Array<string>
  fulfillableServiceIds?: Array<string>
  fulfillProduct?: FulfillProductDto
  fulfillService?: FulfillServiceDto
  fulfillableServices?: FulfillServiceDto[]
  fulfillableProducts?: FulfillProductDto[]
  variants?: ProductVariantDto[]
}

export interface ProductVariantOption {
  name?: string,
  optionValues?: string[],
}

export interface ProductVariantDto {
  title?: string
  price?: number
  sku?: string
  options?: Option[]
}

export interface CreateProductTemplateDto {
  id?: number
  tenantId?: string
  name?: string
  categories?: Array<string>
  variantOptions?: ProductTypeOption[]
  handle?: string
  imageUrl?: string
  bodyHtml?: string
  description?: string
  regularPrice?: string
  salePrice?: string
  fulfillOptions?: FulfillVariantOption
  fulfillProduct?: CreateFulfillProductDto
  fulfillService?: CreateFulfillServiceDto
  fulfillableProducts?: any
  fulfillProductId?: number
  fulfillServiceId?: number
}

export interface UpdateProductTemplateDto extends CreateProductTemplateDto {}

export const ProductTemplateApiPaths = {
  BASE: '/products/templates',
  WITH_ID: '/products/templates/{id}',
  QUERY: '/products/templates/query',
  BULK: '/products/templates/bulk',
  IMPORT: '/products/templates/import',
  SUGGESTION_VARIANT_OPTION_KEYS: '/common/data/variant-option-keys' 
}

export class ProductTemplateService extends CrudService<any, any, any> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: ProductTemplateApiPaths.WITH_ID,
        updateById: ProductTemplateApiPaths.WITH_ID,
        deleteById: ProductTemplateApiPaths.WITH_ID,
        query: ProductTemplateApiPaths.QUERY,
        base: ProductTemplateApiPaths.BASE,
        deleteMany: ProductTemplateApiPaths.BULK,
        updateMany: ProductTemplateApiPaths.BULK
      }
    });
  }



  async getVariantOptionsSuggestion() {
    try {
      const response = await  this.restApi.get(ProductTemplateApiPaths.SUGGESTION_VARIANT_OPTION_KEYS, {})
      return this.returnDataOrThrow(response);
    } catch (e) {
      Logger.warn(`Get variant option failed with error: ${e}`)
      return []
    }
  }
}

export const ProductTemplateServices = new ProductTemplateService()
