import { ModalButtons, ModalContent } from "./portable-modal";
import { FormGroup, Input, Label } from "reactstrap";
import { useState } from "react";
import { InputType } from "reactstrap/types/lib/Input";

export interface SingleInputModal {
  inputType: InputType
  inputLabel: string
  inputPlaceholder: string
  onSubmit: (value: any) => void
  onDone?: Function
}

export const SingleInputModal = (props: SingleInputModal) => {
  const [value, setValue] = useState<any>(undefined);

  return (<>
    <ModalContent>
      <FormGroup className="mb-3">
        <Label>{props.inputLabel}</Label>
        <Input
          type={props.inputType}
          placeholder={props.inputPlaceholder}
          onChange={(value) => setValue(value.target.value)} />
      </FormGroup>
    </ModalContent>
    <ModalButtons
      confirm={async () => {
        await props.onSubmit(+value)
        props.onDone && props.onDone()
      }}
    />
  </>);
};