import { Labels } from "common/labels";
import { FilterOption, SearchOption, TabOption } from "components/table/TableFilter";
import { t } from "core/translations";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Link, useHistory } from "react-router-dom";
import { Badge, Button, Container } from "reactstrap";
import { RouteNames } from "routes";
import Breadcrumb from "components/layout/Breadcrumb";
import RemoteTable, { createDeleteAction, defaultPageInfo } from "components/table/RemoteTable";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { productQueryState, productsSelector, productStatusCountSelector } from "data/atoms/product.atom";
import { ActionOption, RemoteTableSelectionData } from "components/table/SelectedRowAction";
import { ProductActions } from "data/actions/product.action";
import { VendorSelect } from "./components/vendor.select";
import { TooltipComponent } from "components/common/TooltipComponent";
import {
  ProductStatus,
  ProductStatusColor,
  ProductStatusOptions,
  PublishStatus,
  PublishStatusColor,
  PublishStatusOptions,
  ProductPublishStatusTabName,
  ProductPublishStatusTabValues,
  ProductStatusName,
  ProductStatusValues
} from "./types/product-status.type";
import { ActionEntities, ResourceEntities } from "../../types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import { BulkUpdateSelectPopup, BulkUpdateWithReplaceConfirmationPopup } from "../../components/modal/bulk-update-popup";
import React, { useEffect, useState } from "react";
import { portableModalState } from "../../data/atoms/app.atom";
import { Messages } from "../../common/messages";
import { createDocumentPageName } from "../../utils/utils";
import { SaleChannelSelect } from "./components/sale-channel.select";
import { FulfillServiceSelect } from "pages/fulfillments/components/fulfill-service.select";
import { SaleChannelDto } from "data/services/sale-channel-service";
import { ProductServices } from "data/services/product.service";
import { Logger } from "core/logger";
import { createLinkEmbedParam } from "utils/embed";


export const ProductsPage = () => {
  // const history = useHistory()
  const loadable = useRecoilValueLoadable(productsSelector)
  const [queryState, setQueryState] = useRecoilState(productQueryState(defaultPageInfo))
  const setPortableModal = useSetRecoilState(portableModalState)
  const countByStatusLoadable = useRecoilValueLoadable(productStatusCountSelector)
  const [countByStatus, setCountByStatus] = useState<any>({})

  useEffect(() => {document.title = createDocumentPageName(t(Labels.products))}, [])

  const getPublishStatus = (value: string) => {
    const res: any = PublishStatusOptions.filter((item) => item.value == value)
    let [temp] = res
    return temp?.label
  }

  const getStatus = (value: string) => {
    const res: any = ProductStatusOptions.filter((item) => item.value == value)
    let [temp] = res
    return temp?.label
  }

  useEffect(() => {
    if (countByStatusLoadable.state == "hasValue") {
      setCountByStatus(countByStatusLoadable.contents || {})
    }
  }, [countByStatusLoadable.state])

  useEffect(() => {
    const interval = setInterval(getCountByStatus, 10000)
    return () => clearInterval(interval)
  }, [])

  const getCountByStatus = async () => {
    try {
      let countByStatus = await ProductServices.getCountByStatus()
      setCountByStatus(countByStatus)
    } catch (error) {
      Logger.debug('error ', error)
    }
  }

  let columns: ColumnDescription[] = [
    // {
    //   dataField: 'images',
    //   text: t(Labels.image),
    //   headerStyle: {width: 170},
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cell: any, row: any) => {
    //     return (
    //       <>
    //         <CardImg className="mt-n2 mb-n2" style={{height: '70px', width: 'auto'}} src={(row?.images && row?.images[0]) ? row?.images[0] : ImagExample}/>
    //       </>
    //     )
    //   }
    // },
    {
      dataField: "title",
      text: t(Labels.product_title_and_sku),
      //   eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <>
            <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.PRODUCT}/${row.id}`)}>
              <p className="mb-0">{row.title}</p>
            </Link>
            <p className="mb-0">{`${Labels.sku}: ${row?.sku}`}</p>
          </>
        )
      },
    },
    {
      dataField: "artworkSku",
      text: t(Labels.product_type_and_artwork),
      headerStyle: { minWidth: 200 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <>
            <p className="mb-0">{row?.productType?.name}</p>
            <p className="mb-0">{row?.artworkSku}</p>
          </>
        )
      },
    },
    {
      dataField: "owner",
      text: t(Labels.vendor),
      headerStyle: { width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <>
            <p className="mb-0">{row?.owner?.name || row?.owner?.username}</p>
          </>
        )
      },
    },
    {
      dataField: "status",
      text: `${t(Labels.status)}/${t(Labels.publish)}`,
      headerStyle: { width: 150 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <TooltipComponent tooltip={t(`${row.publishIssues?.join(", ") || ""}`)}>
            <div className="d-flex flex-column gap-2 align-items-start">
              <Badge color={ProductStatusColor[row?.status || ProductStatus.DRAFT]} pill>
                {getStatus(row?.status || ProductStatus.DRAFT)}
              </Badge>
              <Badge color={PublishStatusColor[row?.publishStatus || PublishStatus.PENDING]} pill>
                {getPublishStatus(row?.publishStatus || PublishStatus.PENDING)}
              </Badge>
            </div>
          </TooltipComponent>
        )
      },
    },
    {
      dataField: "publishIssues",
      text: t(Labels.message),
      headerStyle: { maxWidth: 200, minWidth: 200 },
      // eslint-disable-next-line react/display-name
      formatter: (cell, row: any) => {
        return (
          <TooltipComponent tooltip={cell?.join(", ")}>
            <p
              className="mb-0"
              style={{ maxWidth: 200, WebkitLineClamp: 2, display: "-webkit-box", WebkitBoxOrient: "vertical", overflow: "hidden" }}
            >
              {cell?.join(", ")}
            </p>
          </TooltipComponent>
        )
      },
    },
  ]

  let tabOptions: TabOption[] = [
    {
      id: "all",
      label: t(Labels.all),
      filter: {},
      // count: countByStatus?.status && countByStatus?.status?.all
    },
    ...ProductStatusValues.map(value => ({
      id: value,
      label: ProductStatusName[value],
      filter: { status: { equalTo: value } },
      count: countByStatus?.status && countByStatus?.status[value] || 0,
    })),
    ...ProductPublishStatusTabValues.map(value => ({
      id: value,
      label: ProductPublishStatusTabName[value],
      filter: { publishStatus: { equalTo: value } },
      count: countByStatus?.publishStatus && countByStatus?.publishStatus[value] || 0,
    }))
  ]

  let searchOptions: SearchOption[] = [
    {
      field: 'sku',
      label: t(Labels.sku)
    },
    {
      field: 'title',
      label: t(Labels.title),
    },
  ]

  let filterOptions: FilterOption[] = [
    {
      name: 'ownerId',
      label: t(Labels.vendor),
      type: 'custom',
      // eslint-disable-next-line react/display-name
      componentCreator: (filterValue: any, onFilterChange: (name: string, value: any) => void) => (
        <VendorSelect
          value={filterValue}
          onChange={(value: any) => {
            onFilterChange("ownerId", [value])
          }}
        />
      ),
      filterBuilder: (vendor: any[]) => (vendor?.length ? { contains: vendor.map(item => item.id)} : undefined),
      hidden: true
    },
    // {
    //   name: 'productType',
    //   label: t(Labels.product_type),
    //   type: 'custom',
    //   // eslint-disable-next-line react/display-name
    //   componentCreator: (filterValue: any, onFilterChange: (name: string, value: any) => void) => (
    //     <ProductTypeSelect
    //       value={filterValue}
    //       onChange={(value: any) => {
    //         onFilterChange("productType", [value])
    //       }}
    //     />
    //   ),
    //   filterBuilder: (productTypes: any[]) => (productTypes?.length ? { contains: productTypes.map(item => item.name) } : undefined),
    //   hidden: true
    // },
    {
      name: "publishStatus",
      label: t(Labels.publish_status),
      type: "checkbox",
      value: PublishStatusOptions,
      hidden: true,
    }
  ]

  const refresh = useRecoilRefresher_UNSTABLE(productsSelector)
  const actionOptions: ActionOption[] = [
    createDeleteAction(ProductActions, ResourceEntities.productEntity),
    {
      name: "publish",
      label: t(Labels.publish_products),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.productEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh }) => {
        setPortableModal({
          open: true,
          title: t(Labels.publish_products),
          content: (
            <BulkUpdateWithReplaceConfirmationPopup
              replaceLabel={t(Labels.publish_and_replace_images)}
              noneReplaceLabel={t(Labels.publish)}
              confirmMessage={Messages.publish_products_confirm_message}
              onSubmit={async (replaceImages: boolean) => ProductActions.publishAction(selectedIds as string[], replaceImages) }
              onDone={refresh}
            />
          ),
        })
      },
    },
    {
      name: 'edit_sale_channels',
      label: t(Labels.add_sale_channel),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.productEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.add_sale_channel),
          content: (
            <BulkUpdateSelectPopup
              placeholder={`${t(Labels.add_sale_channel)}...`}
              selectBuilder={(onChange) => (
                <SaleChannelSelect
                  isMulti={true}
                  getOptionLabel={(option: SaleChannelDto) => option.domain}
                  getOptionValue={(option: SaleChannelDto) => option.id}
                  onChange={onChange}
                />
              )}
              onSubmit={async (value: any) => {
                let publicationChannelIds = value?.map((v: any) => v?.id)
                if (publicationChannelIds.length) {
                  value && await ProductActions.updateSaleChannel(selectedIds as string[], publicationChannelIds)
                }
              }}
              onDone={refresh}
            />
          ),
        })
      },
    },
    {
      name: 'remove_sale_channels',
      label: t(Labels.remove_sale_channel),
      // eslint-disable-next-line react/display-name
      customRenderer: (menuItemComponent: any) => (
        <WithPermission action={ActionEntities.update} resource={ResourceEntities.productEntity}>
          {menuItemComponent}
        </WithPermission>
      ),
      onClick: async ({ selectedIds, refresh } = {} as RemoteTableSelectionData) => {
        setPortableModal({
          open: true,
          title: t(Labels.remove_sale_channel),
          content: (
            <BulkUpdateSelectPopup
              placeholder={`${t(Labels.remove_sale_channel)}...`}
              selectBuilder={(onChange) => (
                <SaleChannelSelect
                  isMulti={true}
                  getOptionLabel={(option: SaleChannelDto) => option.domain}
                  getOptionValue={(option: SaleChannelDto) => option.id}
                  onChange={onChange}
                />
              )}
              onSubmit={async (value: any) => {
                let publicationChannelIds = value?.map((v: any) => v?.id)
                if (publicationChannelIds.length) {
                  value && await ProductActions.removeSaleChannel(selectedIds as string[], publicationChannelIds)
                }
              }}
              onDone={refresh}
            />
          ),
        })
      },
    },
  ]

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={t(Labels.product)}>
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.productEntity}>
              <Link {...createLinkEmbedParam(`${RouteNames.PRODUCT}/create`)}>
                <Button type="button" className="btn btn-success">
                  <i className="bx bx-plus font-size-16 align-middle"></i>
                  {t(Labels.create_new)}
                </Button>
              </Link>
            </WithPermission>
          </Breadcrumb>
          <RemoteTable
            dataLoadable={[loadable, setQueryState]}
            searchOptions={searchOptions}
            filterOptions={filterOptions}
            tabOptions={tabOptions}
            actionOptions={actionOptions}
            searchHint={t(Labels.search_by_name)}
            defaultSearchField={'title'}
            keyField={"id"}
            columns={columns}
            refresh={refresh}
            filterKey={'products'}
          />
        </Container>
      </div>
    </>
  )
}
