import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Form, Label, Row } from "reactstrap"
import { t } from "../../core/translations"
import Breadcrumb from "components/layout/Breadcrumb"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import * as Yup from "yup"
import { RouteNames } from "../../routes"
import { FormikInput } from "../../components/form/FormikInput"
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil"
import { LoadableComponent } from "../../components/common/LoadableComponent"
import { Toasts } from "../../core/notification/notifications"
import { FormikHelpers, useFormik } from "formik"
import { FulfillVariantActions } from "../../data/actions/fulfill-variation.action"
import { CreateFulfillVariantDto, FulfillVariantDto, UpdateFulfillVariantDto } from "../../data/services/fulfill-variation.service"
import { Messages } from "../../common/messages"
import { isEmpty, pick } from "lodash"
import { fulfillVariantByIdSelector } from "../../data/atoms/fulfill-variation.atom"
import JsonTable from "../../components/table/JsonTable"
import { FulfillProductSelect } from "./components/fulfill-product.select"
import { FormikForm, FormValidation } from "../../components/form/FormikForm"
import { fulfillVariantSchema } from "./validations/fulfill-variant.validation"
import Select from "react-select"
import ActionButton from "components/input/ActionButton"
import { BiLinkExternal } from "react-icons/bi"
import { Labels } from "common/labels"
import { OptionalLabel } from "components/common/OptionalLabel"
import { WithPermission } from "components/common/WithPermission"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { MetaTable } from "../../components/table/MetaTable";
import { preprocessPage } from "utils/utils"
import { createLinkEmbedParam } from "utils/embed"

export const FulfillVariantPage = () => {
  const params = useParams<Record<string, any>>()
  const location = useLocation()
  const isEditing = +params.id > 0
  const history = useHistory()
  const [fulfillVariant, setFulfillVariant] = useState<any>({})
  const dataLoadable = useRecoilValueLoadable(fulfillVariantByIdSelector(params.id))

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.fulfill_variant), model: fulfillVariant})
  }, [fulfillVariant])
  
  // TODO remove fakeRefreshDataLoadable
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(fulfillVariantByIdSelector(params.id))
  useEffect(() => {
    (dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents) && fakeRefreshDataLoadable()
  }, [params.id])

  useEffect(() => {
    if (dataLoadable.state == "hasValue") {
      setFulfillVariant(dataLoadable.contents || {})
    }
  }, [dataLoadable.state])

  const validation = useFormik({
    initialValues: fulfillVariant,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: fulfillVariantSchema,
    onSubmit: (value: any, helpers) => handleSubmit(value, helpers),
  })

  const handleSubmit = async (values: FulfillVariantDto, helpers: FormikHelpers<any>) => {
    try {
      helpers.setErrors({})
      helpers.setSubmitting(true)

      let result: any = null
      let updateData: UpdateFulfillVariantDto = {
        sku: values.sku,
        baseCost: values.baseCost,
        fulfillProduct: pick(values.fulfillProduct, "id"),
        variantOptions: values.variantOptions,
      }
      if (isEditing) {
        result = await FulfillVariantActions.update(+params.id, updateData)
        if (result?.id) {
          Toasts.success(t(Messages.update_product_successfully))
        }
      } else {
        result = await FulfillVariantActions.save(params.id, updateData)
        if (result?.id) {
          setFulfillVariant((prev: any) => ({
            ...prev,
            ...result,
          }))
          // TODO optimize not using setTimeout
          setTimeout(() => {
            history.replace(RouteNames.FULFILL_VARIANT.replace(":id", result.id))
            Toasts.success(t(Messages.create_product_successfully))
          }, 100)
        }
      }
    } catch (e) {
      helpers.setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumb goBack={RouteNames.FULFILL_VARIANTS} title={isEditing ? t(Labels.edit_fulfill_variant) : t(Labels.create_fulfill_variant)}>
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.fulfillVariantEntity}>
              <ActionButton color="success" onClick={() => validation.submitForm()} >
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
    
          <LoadableComponent loadable={dataLoadable}>
            <FormikForm validation={validation} className="form-horizontal">
              <Card>
                <CardBody>
                    <FulfillProductForm data={dataLoadable?.contents || {}} id={params.id} validation={validation} />
                </CardBody>
              </Card>
            </FormikForm>
          </LoadableComponent>
        </Container>
      </div>
    </React.Fragment>
  )
}

export interface FulfillProductFormProps {
  data: FulfillVariantDto
  validation: FormValidation
  id: string | number
}

export const FulfillProductForm = (props: FulfillProductFormProps) => {
  const isEditing = +props.id > 0
  const { validation } = props

  return (
    <Form onChange={() => validation.setErrors({})}>
      <>
        <Row className="mb-4">
          <Label className="col-sm-3 col-form-label">{t(Labels.sku)}</Label>
          <Col sm={9}>
            <FormikInput name="sku" type="text" validation={validation} placeholder={t(Labels.fulfill_product_sku_hint)} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={3} className='d-flex'>
            <Label className="col-form-label mt-0">{t(Labels.fulfill_product)}</Label>
            <Link target="_blank" to={createLinkEmbedParam(`${RouteNames.FULFILL_PRODUCTS}/${validation.values.fulfillProduct?.id}`).to} className="pt-2 px-1">
              <BiLinkExternal size="18" />
            </Link>
          </Col>
          <Col sm={9}>
            <FormikInput
              validation={validation}
              name="fulfillProduct"
              required={true}
              customInput={({ handleChange, handleBlur, errorData }) => (
                <FulfillProductSelect
                  validation={validation}
                  value={{
                    fulfillService: validation.values.fulfillService,
                    ...(validation.values.fulfillProduct || {}),
                  }}
                  onChange={value => {
                    validation.setFieldValue("fulfillService", value?.fulfillService)
                    handleChange(value)
                  }}
                  onBlur={handleBlur}
                  styles={errorData.reactSelectErrorStyle}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Label className="col-sm-3 col-form-label">{t(Labels.base_cost)}</Label>
          <Col sm={9}>
            <FormikInput
              name={"baseCost"}
              type={"number"}
              style={{ height: 38 }}
              placeholder={t(Labels.base_cost_hint)}
              validation={validation}
              value={validation.values.baseCost}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Label className="col-sm-3 col-form-label">{t(Labels.variant_options)}</Label>
          <Col sm={9}>
            <FormikInput
              validation={validation}
              name="variantOptions"
              customInput={({ handleChange, handleBlur, errorData  }) => (
                <MetaTable
                  json={validation.values.variantOptions || []}
                  keyField={"name"}
                  keyLabel={t(Labels.name)}
                  valueField={"value"}
                  readOnly={false}
                  onChange={handleChange}
                />
                // <JsonTable
                //   json={validation.values.variantOptions}
                //   keyField={"name"}
                //   keyLabel={t(Labels.name)}
                //   valueField={"value"}
                //   renderInCard={false}
                //   fixedOptions={true}
                //   onChange={handleChange}
                // />
              )}
            />
          </Col>
        </Row>
      </>
    </Form>
  )
}
