import { atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { FulfillVariantServices } from "../services/fulfill-variation.service";
import { PageData } from "../../types/page-data";
import { Logger } from "../../core/logger";

export const fulfillVariantQueryState = atomFamily<QueryParams<any>, any>({
  key: 'fulfillVariantQueryState',
  default: (params: any) => {
    return {...params}
  }
})

export const fulfillVariantSelector = selector({
  key: 'fulfillVariantSelector',
  get: async ({ get }) => {
    let query = get(fulfillVariantQueryState(defaultPageInfo))
    let pageData = await FulfillVariantServices.query(query, { snoozeErrorMessage: true })
    return pageData;
  },
});

export const fulfillVariantIdState = atomFamily<QueryParams<any>, any>({
  key: "fulfillVariantIdState",
  default: (id: any) => {
    return id;
  }
});

export const fulfillVariantByIdSelector = selectorFamily({
  key: "fulfillVariantByIdSelector",
  get: (id: any) => async ({ get }) => {
    let variationId = get(fulfillVariantIdState(id));

    if (+variationId > 0) {
      let data = await FulfillVariantServices.getById(+variationId, { snoozeErrorMessage: false });
      return data;
    }

    return {};
  }
});

export const fulfillVariantByFulfillProductIdState = atomFamily<any, any>({
  key: "fulfillVariantByFulfillProductIdState",
  default: (id: any) => {
    return id;
  }
});

export const fulfillVariantByFulfillProductIdSelector = selectorFamily({
  key: "fulfillVariantByFulfillProductIdSelector",
  get: (id: any) => async ({ get }) => {
    let queryId = get(fulfillVariantByFulfillProductIdState(id));

    if (+queryId > 0) {
      let pageData: PageData = await FulfillVariantServices.getByFulfillProductId(+queryId, { snoozeErrorMessage: false });
      Logger.debug('pageData', pageData)
      return pageData || {} as PageData;
    }

    return {} as PageData;
  }
});



export const fulfillVariantByFulfillProductIdsState = atomFamily<any, any>({
  key: 'fulfillVariantByIdState',
  default: (orderId: any) => {
    return orderId
  }
})

export const fulfillVariantByFulfillProductIdsSelector = selectorFamily({
  key: 'fulfillVariantByFulfillProductIdsSelector',
  get: (fulfillProductIds: any[]) => async ({ get }) => {
    fulfillProductIds = get(fulfillVariantByFulfillProductIdsState(fulfillProductIds))

    if(!fulfillProductIds?.length) { return []}

    let query: QueryParams<any> = { filter: { fulfillProductId: { contains: fulfillProductIds } }, limit: 250 }
    let data = await FulfillVariantServices.query(query, { snoozeErrorMessage: true })
    return data;
  },
});


export const fulfillVariantByIdsState = atomFamily<any, any>({
  key: 'fulfillVariantByIdsState',
  default: (ids: any) => {
    return ids
  }
})

export const fulfillVariantByIdsSelector = selectorFamily({
  key: 'fulfillVariantByIdsSelector',
  get: (ids: any[]) => async ({ get }) => {
    ids = get(fulfillVariantByIdsState(ids))

    if(!ids?.length) { return []}

    let query: QueryParams<any> = { filter: { id: { contains: ids } }, limit: Math.max(200, ids.length) }
    let data = await FulfillVariantServices.query(query, { snoozeErrorMessage: true })
    return data;
  },
});
