import React, { Fragment, useEffect, useState } from "react";
import { SmallContainer } from "components/layout/SmallContainer";
import Breadcrumb from "components/layout/Breadcrumb";
import { t } from "core/translations";
import { Button, Card, CardBody, CardHeader, CardTitle, Row } from "reactstrap";
import { FormikForm, FormValidation } from "components/form/FormikForm";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import { SettingKeys, settingSelector } from "data/atoms/setting.atom";
import { FormikHelpers, useFormik } from "formik";
import { LoadableComponent } from "components/common/LoadableComponent";
import { FormikInput } from "components/form/FormikInput";
import Creatable from "react-select/creatable";
import { Labels } from "common/labels";
import { isEmpty, isPlainObject } from "lodash";
import { NewStorageSettingDto } from "data/services/setting-service";
import { SettingActions } from "../../data/actions/setting.action";
import { Toasts } from "../../core/notification/notifications";
import { FormLayout, FormLayoutMain } from "components/layout/FormLayout";
import { Messages } from "common/messages";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { createDocumentPageName } from "../../utils/utils";
import TeamsSelect from "./components/teams.select";
import { FormLabel } from "react-bootstrap";
import { designStorageSettingSchema } from "./validations/design-storage-setting.validation";

export const StorageTypes = {
  googleDrive: 'google-drive',
  doSpaces: 'do-spaces'
}

const storageServiceValues = {
  GOOGLE_DRIVE: "google-drive",
  DIGITAL_OCEAN_SPACES: "do-spaces"
};

const storageServiceName: Record<string, string> = {
  [storageServiceValues.GOOGLE_DRIVE]: "Google Drive",
  [storageServiceValues.DIGITAL_OCEAN_SPACES]: "DigitalOcean Spaces"
};

const storageServiceOptions = [
  { label: storageServiceName[storageServiceValues.GOOGLE_DRIVE], value: storageServiceValues.GOOGLE_DRIVE },
  {
    label: storageServiceName[storageServiceValues.DIGITAL_OCEAN_SPACES],
    value: storageServiceValues.DIGITAL_OCEAN_SPACES
  }
];

export const DesignStorageSettingPage = () => {
  const dataLoadable = useRecoilValueLoadable(settingSelector(SettingKeys.DESIGN_STORAGE));
  const isEditing = true;
  const [setting, setSetting] = useState<any>({});
  const validation = useFormik({
    initialValues: {
      teamId: setting?.teamId,
      value: setting?.value,
    } || {},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: designStorageSettingSchema,
    onSubmit: (value, helpers) => handleSubmit(value, helpers)
  })

  useEffect(() => {
    dataLoadable.state === 'hasValue' && setSetting(dataLoadable?.contents)
  }, [dataLoadable.state, dataLoadable.contents])

    // TODO remove fakeRefreshDataLoadable 
    const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(settingSelector(SettingKeys.DESIGN_STORAGE))
    useEffect(() => {
      ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
    },[SettingKeys.DESIGN_STORAGE])

  useEffect(() => { document.title = createDocumentPageName(t(Labels.storage_setting)) }, [])

    const checkExistKey = (key: string) => {
      if (validation.values?.value?.hasOwnProperty(key)) {
        return true
      }
      return false
    }

  const handleSubmit = async (values: NewStorageSettingDto, helpers: FormikHelpers<any>) => {
    let data = {
      ...values,
      value: {
        [StorageTypes.doSpaces]: values?.value[StorageTypes.doSpaces] || {},
        [StorageTypes.googleDrive]: {
          ...(values?.value[StorageTypes.googleDrive] || {}),
          credentials: JSON.parse((values?.value[StorageTypes.googleDrive] as any)?.credentials)
        }
      }
    }
    try {
      helpers.setErrors({})
      helpers.setSubmitting(true);

      if (isEditing) {
        let result = await SettingActions.updateStorageSettingByKey(SettingKeys.DESIGN_STORAGE, data as any);
        if (result?.id) {
          setSetting((prev: any) => ({
            ...prev,
            ...result.value
          }));
          // TODO optimize code not using setTimeout
          setTimeout(() => Toasts.success(t(Messages.update_setting_successfully)), 100);
        }
      }
    } catch (e) {
      // Show toast error
      Toasts.error(t(Messages.your_request_execute_unsuccessfully));
    } finally {
      helpers.setSubmitting(false);
    }
  }

  return (
    <Fragment>
      <div className="page-content">
        <SmallContainer>
          <Breadcrumb
            title={`${Labels.edit} ${t(Labels.storage_setting)}`}
          >
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.settingEntity}>
              <Button
                color='success'
                onClick={() => validation.submitForm()}
              >
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </Button>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable} >
            <FormikForm validation={validation}>
              <FormLayout>
                <FormLayoutMain>
                  <StorageSettingForm validation={validation} />
                </FormLayoutMain>
              </FormLayout>
            </FormikForm>
          </LoadableComponent>
        </SmallContainer>
      </div>
    </Fragment>
  )
}

export interface StorageSettingFormProps {
  validation: FormValidation
}

export const StorageSettingForm = (props: StorageSettingFormProps) => {
  const { validation } = props

  return (
    <Fragment>
      {/*<Card>*/}
      {/*  <CardBody>*/}
      {/*    <Row className='mb-2'>*/}
      {/*      <FormLabel>{ t(Labels.teams) }</FormLabel>*/}
      {/*      <TeamsSelect*/}
      {/*        validation={validation}*/}
      {/*        teamId={validation.values?.teamId}*/}
      {/*        onChange={(value: any) => {*/}
      {/*          console.log(value)*/}
      {/*          validation.setFieldValue('teamId', value.id)*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </Row>*/}
      {/*  </CardBody>*/}
      {/*</Card>*/}
      <Card>
        <CardHeader>
          <CardTitle className='m-0'>{t(Labels.google_drive)}</CardTitle>
        </CardHeader>
        <CardBody>
          <GoogleDriveStorageSettingForm validation={validation} type={StorageTypes.googleDrive} />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle className='m-0'>{t(Labels.do_spaces)}</CardTitle>
        </CardHeader>
        <CardBody>
          <DigitalOceanSpacesStorageSettingForm validation={validation} type={StorageTypes.doSpaces} />
        </CardBody>
      </Card>
    </Fragment>
  )
}

export interface CommonStorageSettingFormProps extends StorageSettingFormProps {
  type: string
}

export const GoogleDriveStorageSettingForm = (props: CommonStorageSettingFormProps) => {
  const { validation, type } = props

  useEffect(() => {
    // convert object credentials to JSON
    const credentialsData = validation.values?.value
      ? validation.values?.value[type]?.credentials
      : undefined
    if (isPlainObject(credentialsData)) {
      validation.setFieldValue(`value.${type}.credentials`, JSON.stringify(credentialsData, null, 2))
    }
  }, [validation.values?.value ? validation.values?.value[type]?.credentials : undefined])

  return (
    <>
      <Row className='mb-2'>
        <FormikInput
          validation={validation}
          label={t(Labels.credentials)}
          name={'credentials'}
          type={'textarea'}
          rows={6}
          value={validation.values?.value
            ? validation.values?.value[type]?.credentials
            : undefined
          }
          onChange={(values: any) => {
            validation.setFieldValue(`value.${type}.credentials`, values)
          }}
        />
      </Row>
      <Row className='mb-2'>
        <FormikInput
          label={t(Labels.upload_drive_id)}
          type='text'
          name={'driveId'}
          placeholder={t(Labels.upload_drive_id_hint)}
          validation={validation}
          value={validation.values?.value
            ? validation.values?.value[type]?.driveId
            : undefined
          }
          onChange={(value: any) => validation.setFieldValue(`value.${type}.driveId`, value)}
        />
      </Row>
      <Row className='mb-2'>
        <FormikInput
          label={t(Labels.upload_folder_name)}
          type='text'
          name={'parentFolder'}
          placeholder={t(Labels.folder_name_hint)}
          validation={validation}
          value={validation.values?.value
            ? validation.values?.value[type]?.parentFolder
            : undefined
          }
          onChange={(value: any) => validation.setFieldValue(`value.${type}.parentFolder`, value)}
        />
      </Row>
      <Row className='mb-2'>
        <FormikInput
          validation={validation}
          label={t(Labels.read_only_drive_id)}
          name={'readOnlyDriveIds'}
          customInput={({ handleChange, handleBlur, errorData }) => (
            <Creatable
              isMulti={true}
              formatCreateLabel={(value) => `${t(Labels.create)} '${value}'`}
              placeholder={t(Labels.read_only_drive_id_hint)}
              value={validation.values?.value
                ? validation.values?.value[type]?.readOnlyDriveIds?.map((item: string) => ({ value: item }))
                : undefined
              }
              getOptionLabel={(option: any) => t(option.label || option.value)}
              getOptionValue={(option: any) => option.value}
              onBlur={handleBlur}
              onChange={(values: any) => validation.setFieldValue(`value.${type}.readOnlyDriveIds`, values.map((item: any) => item.value))}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Row>
    </>
  )
}

export const DigitalOceanSpacesStorageSettingForm = (props: CommonStorageSettingFormProps) => {
  const { validation, type } = props
  return (
    <>
      <Row className='mb-2'>
        <FormikInput
          label={t(Labels.access_id)}
          type='text'
          name={'accessId'}
          placeholder={t(Labels.access_id_hint)}
          validation={validation}
          value={validation.values?.value
            ? validation.values?.value[type]?.accessId
            : undefined
          }
          onChange={(value: any) => validation.setFieldValue(`value.${type}.accessId`, value)}
        />
      </Row>
      <Row className='mb-2'>
        <FormikInput
          label={t(Labels.access_key)}
          type='text'
          name={'accessKey'}
          placeholder={t(Labels.access_key_hint)}
          validation={validation}
          value={validation.values?.value
            ? validation.values?.value[type]?.accessKey
            : undefined
          }
          onChange={(value: any) => validation.setFieldValue(`value.${type}.accessKey`, value)}
        />
      </Row>
      <Row className='mb-2'>
        <FormikInput
          label={t(Labels.storage_endpoint)}
          type='text'
          name={'driveId'}
          placeholder={t(Labels.storage_endpoint_hint)}
          validation={validation}
          value={validation.values?.value 
            ? validation.values?.value[type]?.endpoint
            : undefined
          }
          onChange={(value: any) => validation.setFieldValue(`value.${type}.endpoint`, value)}
        />
      </Row>
      <Row className='mb-2'>
        <FormikInput
          label={t(Labels.storage_bucket)}
          type='text'
          name={'bucket'}
          placeholder={t(Labels.storage_bucket_hint)}
          validation={validation}
          value={validation.values?.value
            ? validation.values?.value[type]?.bucket
            : undefined
          }
          onChange={(value: any) => validation.setFieldValue(`value.${type}.bucket`, value)}
        />
      </Row>
      <Row className='mb-2'>
        <FormikInput
          label={t(Labels.root_folder)}
          type='text'
          required={false}
          name={'rootFolder'}
          placeholder={t(Labels.root_folder_hint)}
          validation={validation}
          value={validation.values?.value 
            ? validation.values?.value[type]?.rootFolder
            : undefined
          }
          onChange={(value: any) => validation.setFieldValue(`value.${type}.rootFolder`, value)}
        />
      </Row>
    </>
  )
}