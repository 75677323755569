import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SetterOrUpdater, useRecoilState } from "recoil";
import { portableModalState } from "../../data/atoms/app.atom";
import ActionButton from "../input/ActionButton";
import { Labels } from "../../common/labels";
import { t } from "../../core/translations";
import { PortableModalStateProps } from "../../types/modal.type";

export default function PortableModal() {
  const [open, setOpen] = useState<boolean>(false);
  const [modalState, setModal] = useRecoilState(portableModalState);

  useEffect(() => setOpen(modalState.open), [modalState]);

  return (
    <Modal
      isOpen={open}
      toggle={() => modalState?.preventToggle ? false : setModal({ open: false, title: '' })}
      centered
      style={modalState.style}
      size={modalState.size}
    >
      <ModalHeader>
        <h5 className="modal-title mt-0">
          {modalState.title}
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setModal({ open: false, title: '' })}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      {modalState.content}
    </Modal>
  )
}

const ModalContent = ({ children }: any) => (
  <ModalBody style={{ height: 'full' }}>
    {children}
  </ModalBody>
)

interface ModalButtonsProps {
  cancelText?: string;
  confirmText?: string;
  closeAfterConfirm?: boolean;
  confirmColor?: 'success' | 'danger' | 'warning' | 'info' | 'primary';
  cancelColor?: 'success' | 'danger' | 'warning' | 'info' | 'primary';
  confirm?: (...args: any[]) => void;
  cancel?: (...args: any[]) => void;
  customButtons?: (setModal: SetterOrUpdater<PortableModalStateProps>) => any
  children?: any
  hiddenConfirmButton?: boolean
}

export const CloseModalButtons = (props: ModalButtonsProps) => {
  let { cancel, cancelText = ModalButtons.defaultProps.cancelText, cancelColor = ModalButtons.defaultProps.cancelColor } = props
  const [, setModal] = useRecoilState(portableModalState);
  return (
    // <div  className="d-flex align-content-center justify-content-center">
      <ModalFooter className="d-flex justify-content-center">
        <Button
          color={cancelColor}
          onClick={() => {
            if (cancel) {
              cancel && cancel();
            }
            setModal({ open: false, title: '' })
          }
          }
        >
          {cancelText}
        </Button>
        {
          props.children
        }
      </ModalFooter>
    // </div>
  )
}

const ModalButtons = (props: ModalButtonsProps) => {
  let { cancelText, confirmText, confirm, cancel, confirmColor, cancelColor, closeAfterConfirm, customButtons, hiddenConfirmButton } = props
  const [, setModal] = useRecoilState(portableModalState);
  return (
    <ModalFooter>
      <button
        type="button"
        className={`btn btn-${cancelColor}`}
        onClick={() => {
          if (cancel) {
            cancel && cancel();
          }
          setModal({ open: false, title: '' })
        }
        }
      >
        {cancelText}
      </button>
      { customButtons && customButtons(setModal)}
      {
        props.children
      }
      <ActionButton
        type="button"
        className={`btn btn-${confirmColor}`}
        hidden={hiddenConfirmButton}
        onClick={async () => {
          confirm && await confirm();
          if (closeAfterConfirm) {
            setModal({ open: false, title: '' });
          }
        }}
      >
        {confirmText}
      </ActionButton>
    </ModalFooter>
  )
}

ModalButtons.defaultProps = {
  cancelText: t(Labels.close),
  confirmText: t(Labels.save),
  confirmColor: 'success',
  cancelColor: 'secondary',
  closeAfterConfirm: true
}

export { ModalContent, ModalButtons }
