import { QueryParams } from "core/rest-api/query-builder";
import { RequestOptions } from "core/rest-api/rest.api";
import { apiBaseUrls } from "data/rest-api-config";
import { isEmpty } from "lodash";
import { FulfillmentOrderStatus } from "types/fulfillment-order-type";
import { CrudService } from "./crud.service";
import { FulfillVariantDto } from "./fulfill-variation.service";
import { Storages } from "utils/storages";
import { FulfillmentActionEnum, FulfillmentApiPaths } from "./fulfillment.service";

export interface FulfillmentOrderDto {
  fulfillerId: number | string
  fulfillOrderId: number | string
  orderId?: string
  fulfillVariantId: string | number
  fulfillServiceId: string | number
  status: FulfillmentOrderStatus  
  orderNumber: string
  trackingNumber?: string
  baseCost?: number
  sku: string
  requestSku :string
  fulfillVariantTitle?: string
  fulfillServiceName?: string
  id: string | number,
  submitData?: any
  completedData?: any
  completedTime?: Date
  fulfillVariant?: FulfillVariantDto
  suggestionFulfillVariant?: FulfillVariantDto
  orderItemSku?: string
  designSku?: string
  productVariation?: string
  fulfillSku?: string
}

export interface UpdateFulfillmentOrderDto {
  fulfillerId?: number | string
  fulfillOrderId?: number | string
  orderId?: string
  fulfillVariantId?: string | number
  fulfillServiceId?: string | number
  status?: FulfillmentOrderStatus  
  orderNumber?: string
  trackingNumber?: string
  baseCost?: number
  sku?: string
  requestSku?: string
  fulfillVariantTitle?: string
  fulfillServiceName?: string
  id?: string | number,
  submitData?: any
  completedData?: any
  completedTime?: Date,
  updateMessage?: string
  saveAsSuggestion?: boolean
  shippingAddress1?: string
  shippingAddress2?: string
  shippingCity?: string
  shippingCompany?: string
  shippingCountryCode?: string
  shippingFirstName?: string
  shippingLastName?: string
  shippingEmail?: string
  shippingProvinceCode?: string
  shippingPhone?: string
  shippingZipCode?: string
  shippingMethod?: string
  vendorId?: string
  fulfillmentDate?: any
}

export enum FulfillmentOrderActionEnum {
  CHANGE_FULFILL_SERVICE = 'change_fulfill_service',
  CHANGE_VENDOR = 'change_vendor',
  CHANGE_FULFILLMENT_DATE = 'change_fulfillment_date',
  UPLOAD_DESIGN = 'upload_design',
  CREATE_INVOICE = 'create_invoice',
  UPLOAD_TRACKING_NUMBER_TO_CHANNEL = 'upload_tracking_number_to_channel',
  UPDATE_DESIGN_URL_BY_SKU = 'update_design_url_by_sku',
}

export enum FulfillVariantSuggestionType {
  FULFILL_VARIANT = 'fulfill_variant',
  FULFILL_VARIANT_FOR_REPLACEMENT = 'fulfill_variant_for_replacement',
  FULFILL_VARIANT_BY_FULFILL_SERVICE = 'fulfill_variant_by_fulfill_service',
}

export const FulfillmentOrderApiPaths = {
  BASE: '/fulfillments/orders',
  WITH_ID: '/fulfillments/orders/{id}',
  QUERY: '/fulfillments/orders/query',
  QUERY_BULK_EDIT: '/fulfillments/orders/query/bulk-edit',
  BULK: '/fulfillments/orders/bulk',
  ACTIONS: '/fulfillments/orders/actions',
  BULK_UPDATE_STATUS: '/fulfillments/orders/bulk/status',
  IMPORT_EXTERNAL_FULFILLMENT_ORDERS: '/fulfillments/orders/import/external',
  IMPORT_COMPLETED_FULFILLMENT_ORDERS: '/fulfillments/orders/import/completed',
  IMPORT_SUBMITTED_FULFILLMENT_ORDERS: '/fulfillments/orders/import/submitted',
  COUNT_STATUS: '/fulfillments/orders/count/status',
  EXPORT_SUBMITTING_FULFILLMENT_ORDER: '/fulfillments/orders/export/submitting',
  EXPORT_REVIEW_FULFILLMENT_ORDER: '/fulfillments/orders/export/review',
  EXPORT_FULFILLMENT_ORDER: '/fulfillments/orders/export',
  IMPORT_SHOPBASE_FULFILLMENT_ORDER: '/fulfillments/orders/import/external/shopbase',
  QUERY_FULFILLMENT_ORDER_SUGESTION: '/fulfillments/orders/query/suggestion',
  VARIANT_MAPPER: '/fulfillment/products/variants/mapper',  
  UPDATE_VARIANT_MAPPER: '/fulfillment/products/variants/mapper/{id}',
  UPLOAD_FULFILLMENT_DESIGN: '/fulfillments/orders/{id}/upload-design',
  GET_UPLOAD_DESIGN_OPTIONS: '/fulfillments/orders/{id}/upload-design-options',
  BULK_CREATE_INVOICE: '/fulfillments/orders/invoices/bulk',
  IMPORT_UPDATE_BASE_COST_FULFILLMENT_ORDERS: '/fulfillments/orders/import/base-cost'
}

const FulfillmentOrderImportAsyncApiPaths = {
  IMPORT_UPDATE_BASE_COST_FULFILLMENT_ORDERS: '/fulfillments/orders/import/async/base-cost',
  IMPORT_SHOPBASE_FULFILLMENT_ORDER: '/fulfillments/orders/import/async/external/shopbase',
  IMPORT_EXTERNAL_FULFILLMENT_ORDERS: '/fulfillments/orders/import/async/external',
  IMPORT_COMPLETED_FULFILLMENT_ORDERS: '/fulfillments/orders/import/async/completed',
  IMPORT_SUBMITTED_FULFILLMENT_ORDERS: '/fulfillments/orders/import/async/submitted',
}

export class FulfillmentOrderService extends CrudService<FulfillmentOrderDto, UpdateFulfillmentOrderDto, UpdateFulfillmentOrderDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: FulfillmentOrderApiPaths.WITH_ID,
        updateById: FulfillmentOrderApiPaths.WITH_ID,
        deleteById: FulfillmentOrderApiPaths.WITH_ID,
        query: FulfillmentOrderApiPaths.QUERY,
        base: FulfillmentOrderApiPaths.BASE,
        deleteMany: FulfillmentOrderApiPaths.BULK,
        updateMany: FulfillmentOrderApiPaths.BULK
      }
    });
  }

  getDisableAsyncImport = (name: keyof typeof FulfillmentOrderImportAsyncApiPaths ) => {
    const disableAsyncImport = !Storages.getItem('disableAsyncImport')
    if(disableAsyncImport){
      return FulfillmentOrderApiPaths.hasOwnProperty(name) && FulfillmentOrderApiPaths[name]
    }
    return FulfillmentOrderImportAsyncApiPaths[name]
  }

  async handleImportRequest(data: FormData, apiPath: string, options?: RequestOptions) {
    let response = await this.restApi.post(apiPath, {
      data: data,
      timeout: 60 * 60000,
      ...(options || {})
    })
    return response
  }

  importCompletedRequest(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    // code temporary
    if(!Storages.getItem('disableAsyncImport')){
      return this.handleImportRequest(data, `${FulfillmentOrderImportAsyncApiPaths.IMPORT_COMPLETED_FULFILLMENT_ORDERS}?replace_existed=${replaceExisted}`, options) 
    }
    return this.handleImportRequest(data, `${FulfillmentOrderApiPaths.IMPORT_COMPLETED_FULFILLMENT_ORDERS}?replace_existed=${replaceExisted}`, options)
  }

  importExternalRequest(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    // code temporary
    if(!Storages.getItem('disableAsyncImport')){
      return this.handleImportRequest(data, `${FulfillmentOrderImportAsyncApiPaths.IMPORT_EXTERNAL_FULFILLMENT_ORDERS}?replace_existed=${replaceExisted}`, options) 
    }
    return this.handleImportRequest(data, `${FulfillmentOrderApiPaths.IMPORT_EXTERNAL_FULFILLMENT_ORDERS}?replace_existed=${replaceExisted}`, options)
  }

  importSubmittedRequest(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    // code temporary
    if(!Storages.getItem('disableAsyncImport')){
      return this.handleImportRequest(data, `${FulfillmentOrderImportAsyncApiPaths.IMPORT_SUBMITTED_FULFILLMENT_ORDERS}?replace_existed=${replaceExisted}`, options) 
    }
    return this.handleImportRequest(data, `${FulfillmentOrderApiPaths.IMPORT_SUBMITTED_FULFILLMENT_ORDERS}?replace_existed=${replaceExisted}`, options)
  }

  importShopbaseRequest(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    // code temporary
    if(!Storages.getItem('disableAsyncImport')){
      return this.handleImportRequest(data, `${FulfillmentOrderImportAsyncApiPaths.IMPORT_SHOPBASE_FULFILLMENT_ORDER}?replace_existed=${replaceExisted}`, options) 
    }
    return this.handleImportRequest(data, `${FulfillmentOrderApiPaths.IMPORT_SHOPBASE_FULFILLMENT_ORDER}?replace_existed=${replaceExisted}`, options)
  }

  importUpdateBaseCostRequest(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    // code temporary
    if(!Storages.getItem('disableAsyncImport')){
      return this.handleImportRequest(data, `${FulfillmentOrderImportAsyncApiPaths.IMPORT_UPDATE_BASE_COST_FULFILLMENT_ORDERS}?replace_existed=${replaceExisted}`, options) 
    }
    return this.handleImportRequest(data, `${FulfillmentOrderApiPaths.IMPORT_UPDATE_BASE_COST_FULFILLMENT_ORDERS}?replace_existed=${replaceExisted}`, options)
  }

  async getCountByStatus(){
    const response = await this.restApi.get(FulfillmentOrderApiPaths.COUNT_STATUS, { snoozeErrorMessage: true })
    return this.returnDataOrThrow(response)
  }

  async exportFulfillmentOrderForSubmitting(query: QueryParams<any>, maskAdSubmitting: boolean, options?: RequestOptions) {
    let apiPath = `${FulfillmentOrderApiPaths.EXPORT_SUBMITTING_FULFILLMENT_ORDER}?mark_as_submitting=${maskAdSubmitting}`;
    let response = await this.restApi.post(apiPath, {
      data: query,
      ...options,
    })
    return response
  }

  async exportFulfillmentOrderForReview(query: QueryParams<any>, options?: RequestOptions) {
    let response = await this.restApi.post(FulfillmentOrderApiPaths.EXPORT_REVIEW_FULFILLMENT_ORDER, {
      data: query,
      ...options,
    })
    return response
  }

  async exportFulfillmentOrderCompleted(query: QueryParams<any>, options?: RequestOptions) {
    let response = await this.restApi.post(FulfillmentOrderApiPaths.EXPORT_FULFILLMENT_ORDER, {
      data: query,
      ...options,
    })
    return response
  }

  async bulkUpdateStatus(ids: number[] | string[], updateDto: UpdateFulfillmentOrderDto, options: RequestOptions = {}) {
    if(!this.pathNames.updateMany) throw 'Unsupported API'

    let updateStatusData: any = { status: updateDto.status, updateMessage: updateDto.updateMessage };
    if(updateDto.fulfillmentDate) {
      updateStatusData.fulfillmentDate = updateDto.fulfillmentDate
    }

    let response = await this.restApi.put(FulfillmentOrderApiPaths.BULK_UPDATE_STATUS, { data: { ids, data: updateStatusData }, ...options })
    return this.returnDataOrThrow(response)
  }
  
  async queryForBulkEdit(ids: string[] | number[], editor?: any, options?: RequestOptions) {
    let query: QueryParams<any> = { filter: { id: { contains: ids } }, limit: Math.max(200, ids?.length )}
    return this.query(query, { ...options, baseUrl: FulfillmentOrderApiPaths.QUERY_BULK_EDIT })
  }
  
  async saveEditFulfillmentOrders(bulkUpdateData: Record<string, any>) {
    if (!isEmpty(bulkUpdateData)) {
      let dataById = {} as any
      for (const key in bulkUpdateData) {
        dataById[key] = bulkUpdateData[key]
      }
      let response = await this.restApi.put(FulfillmentOrderApiPaths.BULK, { data: { dataById } })
      return this.returnDataOrThrow(response)
    }
  }

  async bulkChangeFulfillService(ids: string[], fulfillServiceId?: string | number) {
    let response = await this.restApi.post(FulfillmentOrderApiPaths.QUERY_FULFILLMENT_ORDER_SUGESTION, {
      data: {
        ids,
        data: {
          fulfillServiceId,
          type: FulfillVariantSuggestionType.FULFILL_VARIANT_BY_FULFILL_SERVICE,
        }
      }
    });
    return response
  }

  async getSuggestionFulfillVariant(ids: string[], replacement = false) {
    let response = await this.restApi.post(FulfillmentOrderApiPaths.QUERY_FULFILLMENT_ORDER_SUGESTION, {
      data: {
        ids,
        data: {
          type: replacement ? FulfillVariantSuggestionType.FULFILL_VARIANT_FOR_REPLACEMENT : FulfillVariantSuggestionType.FULFILL_VARIANT
        }
      }
    });
    return response
  }

  async variantMapper(data: Record<string, any>) {
    let response = await this.restApi.post(FulfillmentOrderApiPaths.VARIANT_MAPPER, {
      data,
      snoozeErrorMessage: true,

    });
    return response
  }

  async updateVariantMapper(id: number | string, data: Record<string, any>) {
    let response = await this.restApi.put(FulfillmentOrderApiPaths.UPDATE_VARIANT_MAPPER, { params: { id: id }, data })
    return response
  }

  async uploadDesign(id: number | string) {
    let response = await this.restApi.put(FulfillmentOrderApiPaths.UPLOAD_FULFILLMENT_DESIGN, { params: { id: id }, snoozeAllErrorMessage: true })
    return this.returnDataOrThrow(response)
  }

  async createInvoice(ids: string[] | number[]) {
    let response = await this.restApi.post(FulfillmentOrderApiPaths.BULK_CREATE_INVOICE, {
      data: {
        ids,
      },
    })
    return this.returnDataOrThrow(response)
  }

  async uploadTrackingNumberToChannel(ids: string[] | number[]) {
    let response = await this.restApi.post(FulfillmentOrderApiPaths.ACTIONS, {
      data: {
        ids,
        action: FulfillmentOrderActionEnum.UPLOAD_TRACKING_NUMBER_TO_CHANNEL,
      },
    })
    return this.returnDataOrThrow(response)
  }

  async loadUploadDesignOptions(id: string | number) {
    let response = await this.restApi.get(FulfillmentOrderApiPaths.GET_UPLOAD_DESIGN_OPTIONS, { params: { id } })
    return this.returnDataOrThrow(response)
  }
}

export const FulfillmentOrderServices = new FulfillmentOrderService()