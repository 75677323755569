import Breadcrumb from "components/layout/Breadcrumb"
import { SearchOption } from "components/table/TableFilter"
import { t } from "core/translations"
import { accountQueryState, accountSelector, listRoleSelector } from "data/atoms/account.atom"
import React, { Fragment, useEffect, useState } from "react"
import { ColumnDescription } from "react-bootstrap-table-next"
import { Link } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap";
import RemoteTable from "../../components/table/RemoteTable";
import { RouteNames } from "../../routes";
import { Labels } from "../../common/labels";
import { WithPermission } from "components/common/WithPermission"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { useRecoilValueLoadable } from "recoil"
import { createDocumentPageName } from "../../utils/utils";
import format from "date-fns/format";
import { createLinkEmbedParam } from "utils/embed"

export const AccountsPage = () => {
  const [roles, setRoles] = useState<any[]>([])
  const loadListRole = useRecoilValueLoadable(listRoleSelector)
  useEffect(() => {
      if(loadListRole.state == "hasValue"){
        setRoles(loadListRole.contents)
      }
  }, [loadListRole.state])
  
  useEffect(() => { document.title = createDocumentPageName(t(Labels.users)) }, [])

  let columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: t(Labels.id),
      headerStyle: { width: 100, minWidth: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Fragment>
          <Link style={{fontWeight: 500}} {...createLinkEmbedParam(`/users/${row.id}`)}>{`${row.id}`}</Link>
        </Fragment>
      }
    },
    {
      dataField: 'name',
      text: t(Labels.name),
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Col>
         <Row><span>{cell}</span></Row>
        </Col>
      }
    },
    {
      dataField: 'username',
      text: t(Labels.username_and_email),
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <Col>
          <Row><span>{cell}</span></Row>
          <Row><span>{row.email}</span></Row>
        </Col>
      }
    },

    // {
    //   dataField: 'email',
    //   text: t(Labels.email),
    //   headerStyle: {width: 150},
    // },
    {
      dataField: 'team',
      text: t(Labels.team_and_role),
      formatter: (cell: any, row: any) => {
        return (
          <Col>
            <Row><span>{cell?.name}</span></Row>
            {
              row.role ? (
                <Row><span className="font-size-12">{ `${t(Labels.role)}: ${row.role}` }</span></Row>
              ) : null
            }
          </Col>
        )
        return <span>{cell?.name}</span>
      }
    },
    {
      dataField: 'grantedTeams',
      text: t(Labels.manage_teams),
      formatter: (cell: any, row: any) => {
        return <span>{cell?.map((item: any) => item.name)?.join(", ")}</span>
      }
    },
    {
      dataField: 'lastLogin',
      text: t(Labels.last_login),
      headerStyle: { width: 170 },
      formatter: (cell: any, _row: any) => {
        return format(new Date(cell), "MMM dd, yyyy HH:mm");
      }
    },
  ]

  let searchOptions: SearchOption[] = [
    {
      field: 'name',
      label: t(Labels.name)
    },
    {
      field: 'username',
      label: t(Labels.username)
    },
    {
      field: 'email',
      label: t(Labels.email)
    }
  ];

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={t(Labels.users)}
          >
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.userEntity}>
              <Link {...createLinkEmbedParam(RouteNames.USER.replace(':id', 'create'))}>
                <Button className="btn btn-success">
                  <i className="bx bx-plus font-size-16 align-middle"></i>
                  {t(Labels.create_new)}
                </Button>
              </Link>
            </WithPermission>
          </Breadcrumb>
          <RemoteTable
            dataLoader={[accountSelector, accountQueryState]}
            searchOptions={searchOptions}
            filterOptions={[]}
            tabOptions={[]}
            keyField={'id'}
            columns={columns}
            filterKey={'accounts'}
          />
        </Container>
      </div>
    </Fragment>
  )
}