export enum OrderStatus {
  PROCESSING = 'processing',
  ON_HOLD = 'on-hold',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  REFUNDED = 'refunded',
  FAILED = 'failed',
}

export const OrderStatusValues: OrderStatus[] = [
  OrderStatus.PROCESSING,
  OrderStatus.COMPLETED,
  OrderStatus.ON_HOLD,
  OrderStatus.CANCELLED,
  OrderStatus.REFUNDED
]

export const OrderStatusName: Record<string, string> = {
  [OrderStatus.PROCESSING]: 'Processing',
  [OrderStatus.COMPLETED]: 'Completed',
  [OrderStatus.ON_HOLD]: 'On Hold',
  [OrderStatus.CANCELLED]: 'Cancelled',
  [OrderStatus.REFUNDED]: 'Refunded'
}

export const OrderStatusColor: Record<string, string> = {
  [OrderStatus.PROCESSING]: 'primary',
  [OrderStatus.COMPLETED]: 'success',
  [OrderStatus.ON_HOLD]: 'secondary',
  [OrderStatus.CANCELLED]: 'dark',
  [OrderStatus.REFUNDED]: 'warning',
  [OrderStatus.FAILED]: 'danger',
}