import { Labels } from "common/labels";
import { CloseModalButtons, ModalButtons, ModalContent } from "components/modal/portable-modal";
import { t } from "core/translations";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, CardText, Col, Row } from "reactstrap";
import { RouteNames } from "routes";
import { Fragment } from "@fullcalendar/core";
import { Toasts } from "../../../core/notification/notifications";
import { Messages } from "../../../common/messages";
import { BiLinkExternal, BiStop } from "react-icons/bi";
import { createLinkEmbedParam } from "utils/embed";

export interface BulkActionResult {
  all?: string[]; // All bulk action ids
  success?: string[]; // success ids
  failed?: string[]; // failed ids
  skipped?: string[]; // skipped ids
  notFound?: string[]; // not found ids
  failedDetails?: BulkActionResultDetail[]; // Chi tiết lỗi của các id lỗi
  data?: any; // Dữ liệu khác
}

export interface BulkActionResultDetail {
  ids?: string[]; // Ids
  message?: string; // Tin nhắn lỗi theo ids
}

export interface FulfillmentOrderResultProps {
  data: BulkActionResult,
  onClose?: any
}

interface ResultDetailComponentProps {
  ids?: string[];
}

const ResultDetailComponent = (props: ResultDetailComponentProps) => {
  const onClickCopy = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(props.ids?.join(",") || "");
    Toasts.info(t(Messages.the_ids_is_copied_to_clipboard));
  };

  return (
    <span className="ms-2 d-flex">
      <a href="#" onClick={onClickCopy}>
        {Labels.click_to_copy_ids}
      </a>
        {
          props.ids?.length ?
            <>
              <span className="mx-1 fw-medium">or</span>
              <Link target={"_blank"} to={createLinkEmbedParam(`${RouteNames.ORDER_ITEMS}?ids=${props.ids?.join(",")}`).to}>
                {Labels.view_details}
                <BiLinkExternal style={{ marginLeft: 2 }} size={12} />
              </Link>
            </>
            :
            null
        }
    </span>
  );
};

const FulfillmentOrderResult = (props: FulfillmentOrderResultProps) => {
  const { data, onClose } = props;
  return (
    <>
      <ModalContent>
        <Row>
          {
            <div className="d-flex flex-column">
              <div className="mb-2 me-2 d-flex">
                <p className="fw-bold">{t(Labels.total) + ` (${data?.all?.length}): `}</p>
                <ResultDetailComponent ids={data?.all} />
              </div>
              <div className="mb-2 me-2 d-flex">
                <p className="fw-bold">{t(Labels.success) + ` (${data?.success?.length}): `}</p>
                <ResultDetailComponent ids={data?.success} />
              </div>
              <div className="mb-2 me-2 d-flex">
                <p className="fw-bold">{t(Labels.not_found) + ` (${data?.notFound?.length}): `}</p>
                <ResultDetailComponent ids={data?.notFound} />
              </div>
              <div className="mb-2 me-2 d-flex">
                <p className="fw-bold">{t(Labels.skipped) + ` (${data?.skipped?.length}): `}</p>
                <ResultDetailComponent ids={data?.skipped} />
              </div>
              <div className="mb-2">
                <p className="fw-bold mb-1">{t(Labels.failed) + ` (${data?.failed?.length}): `} </p>
                {
                  data?.failedDetails && data?.failedDetails?.map((item: BulkActionResultDetail, index: number) => (
                    <div className="ms-4 my-2 d-flex" key={index + (item?.message || "")}>
                      <div className="d-flex align-items-center"><BiStop size={10} /></div>
                      <span className="ms-1 fw-bold">{`${item?.message || ""} (${item?.ids?.length}): `}</span>
                      <ResultDetailComponent ids={item?.ids} />
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </Row>
        <CloseModalButtons/>
      </ModalContent>
    </>
  );
};

export default FulfillmentOrderResult;