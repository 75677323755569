import * as Yup from "yup";
import { Labels } from "../../../common/labels";
import { t } from "../../../core/translations";
import { Messages } from "../../../common/messages";
import { TestContext } from "yup/lib/util/createValidation";
import { Option } from "../../../common/types";

export const fulfillProductSchema = Yup.object({
  name: Yup.string().required(t(Messages.field_required, {field: t(Labels.name)})),
  fulfillService: Yup.object({
    id: Yup.number().min(1, t(Labels.fulfill_service_required))
  }).required(t(Messages.field_required, {field: t(Labels.fulfill_service)})),
  baseCost: Yup.number().required(t(Messages.field_required, {field: t(Labels.base_cost)})).min(0, t(Messages.field_required_min, { field: t(Labels.base_cost), min: 0 })),
  variantOptions: Yup.array().min(1, t(Labels.variant_options_required)),
  productTypeKey: Yup.string().required(t(Labels.product_type_option_required)),
  variants: Yup.array().of(Yup.object({
    sku: Yup.string().required(t(Messages.field_required, {field: t(Labels.sku)})),
    baseCost: Yup.number().min(0, t(Messages.field_required_min, { field: t(Labels.base_cost), min: 0 })),
    variantOptions: Yup.array().min(1, t(Labels.variant_options_required))
  }))
    .min(0)
    .test('duplicateFulfillVariants', (value: any, context: TestContext) => {
      let isDuplicatedVariants = value?.some((variant: any) => variant.__duplicated)
      if (isDuplicatedVariants) {
        return context.createError({ message: t(Messages.multiple_fulfillment_variant_with_sku) });
      }
      return true
    })
    .test('missVariantOptionValue', (variants: any, context: TestContext) => {
      let optionNames = context.parent.variantOptions?.map((option: Option) => option?.name).filter((v: any) => v) || []

      let missVariantOptionValue = variants?.some((variant: any) => {
        let variantOptionNames = variant.variantOptions?.map((option: Option) => option?.name).filter((v: any) => v)
        return !optionNames?.every((name: any) => variantOptionNames?.includes(name))
      })
      if (missVariantOptionValue) {
        return context.createError({ message: t(Messages.enter_enough_value_for_all_variant) });
      }
      return true
    })
})