import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { ColumnDescription } from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { RouteNames } from "../../routes";
import { SearchOption } from "../../components/table/TableFilter";
import RemoteTable, { createDeleteAction } from "../../components/table/RemoteTable";
import { t } from "../../core/translations";
import { productTypesQueryState, productTypesSelector } from "../../data/atoms/product-type.atom";
import Breadcrumb from "../../components/layout/Breadcrumb";
import { ActionOption } from "../../components/table/SelectedRowAction";
import { ProductTypeActions } from "../../data/actions/product-type.action";
import { ImportModal, ImportType } from "../fulfillments/components/import-modal";
import { Labels } from "../../common/labels";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import { createDocumentPageName } from "../../utils/utils";
import { createLinkEmbedParam } from "utils/embed";

export const ProductTypesPage = () => {
  const [importType, setImportType] = useState<ImportType>(ImportType.NULL)

  useEffect(() => { document.title = createDocumentPageName(t(Labels.products_types)) }, [])

  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.PRODUCT_TYPES}/${row.id}`)}> {`${row.id}`}</Link>
        </>;
      }
    },
    {
      dataField: "name",
      text: t(Labels.name),
      headerStyle: { width: 250 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Row>
            <Row className="mb-1">{row.name}</Row>
            {row.code && <Row>{`Code: ${row.code}`}</Row>}
          </Row>
        </>;
      }
    },
    {
      dataField: "productTypeValues",
      text: t(Labels.type_option_values),
      formatter: (cell: any, row: any) => {
        return cell?.join ? cell.join(', ') : ''
      }
    },
    {
      dataField: "productTypeKey",
      text: t(Labels.product_type_option),
      headerStyle: { width: 180 }
    },
    {
      dataField: "fulfillProduct",
      text: t(Labels.fulfill_product),
      headerStyle: { width: 180 },
      formatter: (cell: any, row: any) => {
        return row.fulfillProduct?.name || "";
      }
    },
    {
      dataField: "fulfillService",
      text: t(Labels.fulfill_service),
      headerStyle: { width: 150 },
      formatter: (cell: any, row: any) => {
        return row.fulfillService?.name || "";
      }
    }
  ];

  let searchOptions: SearchOption[] = [
    {
      field: "name",
      label: t(Labels.product_type_name)
    },
    {
      field: "productTypeValues",
      label: t(Labels.product_type_value)
    },
    {
      field: "code",
      label: t(Labels.product_type_code)
    }
  ];

  const actionOptions: ActionOption[] = [
    createDeleteAction(ProductTypeActions, ResourceEntities.productTypeEntity)
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={t(Labels.product_types)}
          >
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.productTypeEntity}>
              <Button
                outline
                color="secondary"
                className="me-2"
                onClick={() => {
                  setImportType(ImportType.PRODUCT_TYPE)
                }}>
                <i className="bx bx-import font-size-16 align-middle me-1"></i>
                {t(Labels.import)}
              </Button>

              <Link {...createLinkEmbedParam(`${RouteNames.PRODUCT_TYPES}/create`)}>
                <Button
                  type="button"
                  color="success"
                >
                  <i className="bx bx-plus font-size-16 align-middle"></i>
                  {t(Labels.create)}
                </Button>
              </Link>
            </WithPermission>
          </Breadcrumb>
          <ImportModal replaceable={true} type={importType} onClose={() => setImportType(ImportType.NULL)} />
          <RemoteTable
            dataLoader={[productTypesSelector, productTypesQueryState]}
            searchOptions={searchOptions}
            filterOptions={[]}
            tabOptions={[]}
            actionOptions={actionOptions}
            searchHint={t(Labels.search_by_name)}
            defaultSearchField={"name"}
            keyField={"id"}
            columns={columns}
            filterKey={'productTypes'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

