import React, { useEffect } from "react";

import { Badge, Button, Col, Container, Row } from "reactstrap";

import { t } from "../../core/translations";
import { RouteNames } from "../../routes";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import RemoteTable, { createDeleteAction } from "../../components/table/RemoteTable";
import { ColumnDescription } from "react-bootstrap-table-next";
import { ActionOption } from "../../components/table/SelectedRowAction";
import Breadcrumb from "../../components/layout/Breadcrumb";
import { SearchOption } from "../../components/table/TableFilter";
import { saleChannelQueryState, saleChannelSelector } from "../../data/atoms/sale-channel.atom";
import { ChannelSyncStatusColor, ChannelSyncStatusName } from "../../types/channel-sync-status.type";
import { SaleChannelActions } from "../../data/actions/sale-channel.action";
import { Labels } from "common/labels";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { WithPermission } from "components/common/WithPermission";
import { createDocumentPageName } from "../../utils/utils";
import { createLinkEmbedParam } from "utils/embed";

export const SaleChannel = () => {
  useEffect(() => { document.title = createDocumentPageName(t(Labels.stores))}, [])

  let columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: t(Labels.id),
      headerStyle: { width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Link style={{ fontWeight: 500 }} {...createLinkEmbedParam(`${RouteNames.STORES}/${row.id}`)}> {`${row.id}`}</Link>
        </>;
      }
    },
    {
      dataField: "type",
      text: t(Labels.type),
      headerStyle: { width: 180 }
    },
    {
      dataField: "name",
      text: t(Labels.name)
    },
    {
      dataField: "domain",
      text: t(Labels.domain)
    },
    {
      dataField: "team",
      text: t(Labels.team),
      headerStyle: { minWidth: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <Col>
            <Row><span>{cell?.name || ''}</span></Row>
            {
              row.owner?.name || row.owner?.username ? (
                <Row><span className="font-size-12">{ `${t(Labels.owner)}: ${row.owner?.name || row.owner?.username}` }</span></Row>
              ) : null
            }
          </Col>
        )
      }
    },
    {
      dataField: "lastSyncStatus",
      text: t(Labels.status),
      headerStyle: { width: 100 },
      // eslint-disable-next-line react/display-name
      formatter: (cell) => {
        let color = ChannelSyncStatusColor[cell] || "light";
        return <>
          <Badge color={color} pill>{ChannelSyncStatusName[cell] || cell}</Badge>
        </>;
      }
    },
    {
      dataField: "lastSyncTime",
      text: t(Labels.last_sync),
      headerStyle: { width: 170 },
      formatter: (cell: any, _row: any) => {
        return format(new Date(cell), "MMM dd, yyyy HH:mm");
      }
    },
    {
      dataField: "action",
      text: t(Labels.action),
      headerStyle: { width: 80, textAlign: "center" },
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <div className="text-center">
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.orderEntity} key={row.id}>
              <Link
                to={"#"}
                id={`sync-${row.id}`}
                onClick={() => SaleChannelActions.sync(row.id)}
              >
                <i className="mdi mdi-cloud-refresh font-size-18" />
              </Link>
            </WithPermission>
          </div>
        );
      }
    }
  ];

  const searchOptions: SearchOption[] = [
    {
      field: "name",
      label: t(Labels.name)
    }
  ];

  const actionOptions: ActionOption[] = [
    createDeleteAction(SaleChannelActions, ResourceEntities.saleChannelEntity)
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb>
            <div className="d-flex gap-2">
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.orderEntity}>
              <Button type="button" color="primary" className="btn" onClick={() => SaleChannelActions.syncAll()}>
                <i className="bx bx-refresh font-size-16 align-middle px-1"/>
                {t(Labels.sync_all)}
              </Button>
            </WithPermission>
            <WithPermission action={ActionEntities.create} resource={ResourceEntities.saleChannelEntity}>
              <Link {...createLinkEmbedParam(`${RouteNames.STORES}/create`)}>
                <Button
                  type="button"
                  className="btn btn-success"
                >
                  <i className="bx bx-plus font-size-16 align-middle"/>
                  {t(Labels.create_new)}
                </Button>
              </Link>
            </WithPermission>
            </div>
          </Breadcrumb>
          <RemoteTable
            // refreshInterval={10000}
            dataLoader={[saleChannelSelector, saleChannelQueryState]}
            searchOptions={searchOptions}
            actionOptions={actionOptions}
            searchHint={t(Labels.search_by_name)} // TODO change hint
            defaultSearchField={"name"}
            keyField={"id"}
            columns={columns}
            filterOptions={[]}
            tabOptions={[]}
            filterKey={'saleChannels'}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SaleChannel;
