export enum BulkEditorView {
  NULL= 'null',
  FULFILL_ORDER= 'fulfill_order',
  FULFILL_ORDER_SHIPPING_ADDRESS = 'fulfill_order_shipping_address',
  REVIEW_CUSTOM = 'review_custom',
  FULFILLMENT_ORDER = 'fulfillment_order',
  FULFILL_ORDER_EDIT_VARIANT = 'fulfill_order_edit_variant',
  EDIT_DESIGN_OPTION = 'edit_design_option',
}

export const defaultBulkEditingState = {
  updatedData: {},
  submitData: {}
}
export interface BulkEditingState {
  editor?: BulkEditorView
  editing?: boolean
  updatedData: any,
  submitData: any,
  customOptions?: any
  updateAtomic?: number
}

