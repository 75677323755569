import { apiBaseUrls } from "data/rest-api-config"
import { RequestOptions } from "core/rest-api/rest.api"
import { CrudService } from "./crud.service"
import {  QueryParams } from "../../core/rest-api/query-builder";


export interface ArtworkDto {
  sku: string
  title: string
  url: string
  storageService: StorageService
  categories?: string[]
  tags?: string[]
  customize: boolean
  isDirectory: boolean
  artworkSku: string
  customOptions?: ArtworkCustomOption[]
  products?: any[]
  productTypes?: any[]
  template?: any
  description?: string
  customilyTemplate?: string
  customilyProductId?: string
}

export interface ArtworkCustomOption {
  name: string
  defaultValue?: string
}

export interface CreateArtworkDto {
  name: string
}

export enum StorageService {
  GOOGLE_DRIVE = "google-drive",
  DIGITALOCEAN_SPACES = "do-spaces",
}

export interface UpdateArtworkDto extends ArtworkDto { }

export const ArtworkTypeApiPaths = {
  BASE: '/artworks',
  WITH_ID: '/artworks/{id}',
  QUERY: '/artworks/query',
  BULK: '/artworks/bulk',
  IMPORT: '/artworks/import',
  PRODUCT: '/products',
  KEYS: '/common/settings/{key}/keys',
  CUSTOMILY_TEMPLATE: '/common/data/customily-template',
  ACTION: '/artworks/actions',
  BULK_EDIT: '/artworks/query/bulk-edit'
}

export class ArtworkService extends CrudService<any, ArtworkDto, UpdateArtworkDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: ArtworkTypeApiPaths.WITH_ID,
        updateById: ArtworkTypeApiPaths.WITH_ID,
        deleteById: ArtworkTypeApiPaths.WITH_ID,
        query: ArtworkTypeApiPaths.QUERY,
        base: ArtworkTypeApiPaths.BASE,
        deleteMany: ArtworkTypeApiPaths.BULK,
        updateMany: ArtworkTypeApiPaths.BULK
      }
    });
  }

  importArtworks(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    return super.import(data, `${ArtworkTypeApiPaths.IMPORT}?replace_existed=${replaceExisted}`, options)
  }

  async getDataProduct () {
    const response = await this.restApi.get(ArtworkTypeApiPaths.PRODUCT, { snoozeErrorMessage: false })
    return this.returnDataOrThrow(response)
  }

  async searchKeys (params: any, options?: RequestOptions) {
    let response = await this.restApi.get(ArtworkTypeApiPaths.CUSTOMILY_TEMPLATE, {
      params: params, 
      ...options,
      snoozeErrorMessage: true,
    })
    if(!response || response?.error){
      response = await this.restApi.get(ArtworkTypeApiPaths.KEYS, {
        params: params, 
        ...options
      })
    }
    return this.returnDataOrThrow(response)

  }

  async createMany(createData: ArtworkDto[], options: RequestOptions = {}) {
    let response = await this.restApi.post(ArtworkTypeApiPaths.BULK, {
      data: createData,
      ...options
    })
    return this.returnDataOrThrow(response)
  }

  async getBulkEditArtwork(ids: string[]) {
    let query: QueryParams<any> = { filter: { and: { id: { in: ids } } }, limit: Math.max(200, ids.length )}
    return this.restApi.post(ArtworkTypeApiPaths.BULK_EDIT, { data: query })
  }

}

export const ArtworkServices = new ArtworkService()