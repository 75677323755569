import { Labels } from "common/labels";
import { Messages } from "common/messages";
import { Logger } from "core/logger";
import { Toasts } from "core/notification/notifications";
import { Filter, QueryBuilder, QueryFilter } from "core/rest-api/query-builder";
import { t } from "core/translations";
import FileSaver from "file-saver";
import { toUint8Array } from "utils/utils";
import {
  FulfillmentOrderActionEnum,
  FulfillmentOrderApiPaths,
  FulfillmentOrderDto,
  FulfillmentOrderService,
  FulfillmentOrderServices,
  UpdateFulfillmentOrderDto
} from "../services/fulfillment-order.service";
import { CrudActions } from "./crud.action";
import { FulfillmentOrderStatus } from "../../types/fulfillment-order-type";
import { AdminUtils } from "utils/admin-utils";
import { HandleFileResultType } from "pages/fulfillments/components/import-modal";
import { isEmpty } from "lodash";
import { RequestOptions } from "core/rest-api/rest.api";
import { withPermission } from "common/hooks/use-permission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { FulfillmentActionEnum, FulfillmentApiPaths, FulfillmentServices } from "../services/fulfillment.service";


export interface StatusBaseModel {
  id: any
  status: any
}

export enum ExportFulfillmentOrderType {
  DEFAULT = 'default',
  SUBMITTING = 'submitting',
  COMPLETED = 'completed'
}

class FulfillmentOrderAction extends CrudActions<
FulfillmentOrderService,
FulfillmentOrderDto,
UpdateFulfillmentOrderDto
> {
  constructor() {
    super(FulfillmentOrderServices)
  }

  async importCompletedFulfillmentOrder(file: any, replaceable?: boolean) {
    try {
      const formData = new FormData();
      // formData.append('file', file)
      file.forEach((file: any) => formData.append('files', file))
      return await FulfillmentOrderServices.importCompletedRequest(formData, replaceable, { snoozeErrorMessage: true, snoozeAllErrorMessage: true })
    } catch (e) {
      Logger.warn('import completed request failed: ', e)
    }
  }

  async importExternalFulfillmentOrder(file: any, replaceable?: boolean) {
    try {
      const formData = new FormData();
      formData.append('file', file)
      return await FulfillmentOrderServices.importExternalRequest(formData, replaceable, { snoozeErrorMessage: true, snoozeAllErrorMessage: true })
    } catch (e) {
      Logger.warn('import external request failed: ', e)
    }
  }

  async importSubmittedFulfillmentOrder(files: any, replaceable?: boolean, payload?: Record<string, any>) {
    try {
      const formData = new FormData();
      files.forEach((file: any) => formData.append('files', file))
      if(payload){
        for (const [key, value] of Object.entries(payload)) {
          formData.append(key, value)
        }
      }
      return await FulfillmentOrderServices.importSubmittedRequest(formData, replaceable, { snoozeErrorMessage: true, snoozeAllErrorMessage: true })
    } catch (e) {
      Logger.warn('import submitted request failed: ', e)
    }
  }

  async importShopbaseFulfillmentOrder(file: any, replaceable?: boolean) {
    try {
      const formData = new FormData();
      formData.append('file', file)
      return await FulfillmentOrderServices.importShopbaseRequest(formData, replaceable, { snoozeErrorMessage: true, snoozeAllErrorMessage: true })
    } catch (e) {
      Logger.warn('import submitted request failed: ', e)
    }
  }

  async importUpdateBaseCost(file: any, replaceable?: boolean) {
    try {
      const formData = new FormData();
      file.forEach((file: any) => formData.append('files', file))
      return await FulfillmentOrderServices.importUpdateBaseCostRequest(formData, replaceable, { snoozeErrorMessage: true, snoozeAllErrorMessage: true })
    } catch (e) {
      Logger.warn('import update base cost request failed: ', e)
    }
  }

  async exportFulfillmentOrder(type: ExportFulfillmentOrderType, idsOrFilter: string[] | number[] | QueryFilter<any>, markAsSubmitting: boolean = false) {
    let filter = ((Array.isArray(idsOrFilter) && !isNaN(Number(idsOrFilter[0])))
      ? { id: { in: idsOrFilter }}
      : QueryBuilder.createFilter(idsOrFilter as any)) as Filter<any>
    try {
      let data: any
      if(type == ExportFulfillmentOrderType.SUBMITTING) {
        data = await FulfillmentOrderServices.exportFulfillmentOrderForSubmitting({ filter }, markAsSubmitting);
      } else {
        data = await FulfillmentOrderServices.exportFulfillmentOrderForReview({ filter });
      }
      if(data?.type == 'Buffer') {
        let uint8Array = toUint8Array(data.data);
        const file = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(file, `fulfillment-order_${AdminUtils.getSavingFileSubName()}.xlsx`);
        return true
      }
    } catch (e) {
      Toasts.error(t(Messages.export_unsuccessfully))
      Logger.warn('exportForFulfillService failed: ', e)
    }
  }

  async exportFulfillmentOrderNew(type: ExportFulfillmentOrderType, filter: any, markAsSubmitting: boolean = false) {
    
    let exportResult: HandleFileResultType = {}
    try {
      if(type == ExportFulfillmentOrderType.SUBMITTING) {
        exportResult.rawData = await FulfillmentOrderServices.exportFulfillmentOrderForSubmitting( filter , markAsSubmitting);
      }
      else if(type == ExportFulfillmentOrderType.COMPLETED) {
        exportResult.rawData = await FulfillmentOrderServices.exportFulfillmentOrderCompleted( filter );
      }
      else {
        exportResult.rawData = await FulfillmentOrderServices.exportFulfillmentOrderForReview( filter );
      }
      let resData = exportResult.rawData?.type ? { rawData: exportResult.rawData } : exportResult.rawData

      if(resData.data?.type == 'Buffer') {
        let uint8Array = toUint8Array(resData.data.data);
        //! TODO: Remove fileType
        if (exportResult.rawData?.fileType == 'zip' || exportResult.rawData?.ext == '.zip') {
          const file = new Blob([uint8Array], { type: 'application/octet-stream' });
          FileSaver.saveAs(file, `fulfillment-order_${AdminUtils.getSavingFileSubName()}.zip`);
        } else {
          const file = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          FileSaver.saveAs(file, `fulfillment-order_${AdminUtils.getSavingFileSubName()}.xlsx`);
        }
        return {rawData: resData, success: true}
      } else {
        return {rawData: null, success: false}
      }
    } catch (e) {
      Toasts.error(t(Messages.export_unsuccessfully))
      Logger.warn('exportForFulfillService failed: ', e)
      return {rawData: null, success: false}
    }
  }

  async bulkUpdateStatus(selectedItems: StatusBaseModel[], status: FulfillmentOrderStatus, updateMessage?: string, fulfillmentDate?: any) {
    if(!selectedItems.length) {
      Toasts.error(t(Messages.invalid_update_data))
      return
    }

    const fromStatuses = [...new Set(selectedItems.map((item: any) => item.status))].filter(fromStatus => fromStatus != status)
    if(!fromStatuses.length) {
      Toasts.info(t(Messages.status_is_up_to_date))
      return
    }

    const ids = selectedItems.map((item: any) => item.id)
    try {
      let response = await this.service.bulkUpdateStatus(ids, { status, updateMessage, fulfillmentDate })
      let success = !!response
      success ? Toasts.success(t(Messages.update_successfully)) : Toasts.error(t(Messages.update_unsuccessfully))
      return success
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async saveEditFulfillmentOrders(bulkUpdateData: Record<string, any>) {
    let result = await FulfillmentOrderServices.saveEditFulfillmentOrders(bulkUpdateData)
    result ? Toasts.success(t(Messages.update_successfully)) : Toasts.error(t(Messages.update_unsuccessfully))
    return !!result
  }

  async changeFulfillService(ids: string[] | number[], fulfillServiceId?: string | number) {
    return super.runBulkAction(FulfillmentOrderApiPaths.ACTIONS, ids, FulfillmentOrderActionEnum.CHANGE_FULFILL_SERVICE, { fulfillServiceId: fulfillServiceId as number })
  }

  async bulkEditChangeFulfillService(ids: string[], fulfillServiceId?: string | number) {
    try {
      let result: any = await FulfillmentOrderServices.bulkChangeFulfillService(ids, fulfillServiceId)
      if(result.data){
        Toasts.success(t(Messages.changed_fulfill_service_in_bulk_fulfillment_order))
      }
      // success ? Toasts.success(t(Messages.update_successfully)) : Toasts.error(t(Messages.update_unsuccessfully))
      return result.data
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async getSuggestionFulfillVariant(ids: string[], replacement = false) {
    try {
      let result: any = await FulfillmentOrderServices.getSuggestionFulfillVariant(ids, replacement)
      if(result.data){
        isEmpty(result.data) ? Toasts.warning(t(Labels.fill_suggestion_unsuccessfully)) : Toasts.success(t(Labels.fill_suggestion_successfully))
      }
      return result.data
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async changeVendor (ids: string[] | number[], vendorId: string) {
    return super.runBulkAction(FulfillmentOrderApiPaths.ACTIONS,  ids, FulfillmentOrderActionEnum.CHANGE_VENDOR, { vendorId })
  }

  
  async variantMapper(payload: Record<string, any>) {
    try {
      let result: any = await FulfillmentOrderServices.variantMapper(payload)
      return result
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async updateVariantMapper(id: string | number, payload: Record<string, any>) {
    try {
      let result: any = await FulfillmentOrderServices.updateVariantMapper(id ,payload)
      return result.data
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async queryForBulkEdit(ids: string[] | number[], editor?: any, options?: RequestOptions) {
    try {
      let result: any = await FulfillmentOrderServices.queryForBulkEdit(ids, editor, options)
      return result
    } catch (e) {
      if (e) {
        Toasts.error((e as any)?.message)
      } else {
        Toasts.error(t(Messages.load_bulkd_edit_data_error))
      }
      Logger.debug('query bulk edit failed: ', e)
    }
  }

  async bulkUpdateDate(ids: any[], fulfillmentDate: any) {
    return super.runBulkAction(FulfillmentOrderApiPaths.ACTIONS, ids, FulfillmentOrderActionEnum.CHANGE_FULFILLMENT_DATE, { fulfillmentDate })
  }

  async bulkUpLoadDesignFile(ids: any[]) {
    try {
      let response = await this.service.bulkAction(FulfillmentOrderApiPaths.ACTIONS, ids, FulfillmentOrderActionEnum.UPLOAD_DESIGN, {})
      let success = !!response
      success ? Toasts.success(t(Messages.bulk_action_successfully)) : Toasts.error(t(Messages.bulk_action_unsuccessfully))
      return response
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async bulkUpdateDesignUrlBySku(ids: any[]) {
    try {
      let response = await this.service.bulkAction(FulfillmentOrderApiPaths.ACTIONS, ids, FulfillmentOrderActionEnum.UPDATE_DESIGN_URL_BY_SKU, {})
      let success = !!response
      success ? Toasts.success(t(Messages.bulk_action_successfully)) : Toasts.error(t(Messages.bulk_action_unsuccessfully))
      return response
    } catch (e) {
      Logger.warn('bulkUpdate failed: ', e)
    }
  }

  async uploadDesign(id: string | number, hooks: { setError?: Function } = {}): Promise<any> {
    let { setError } = hooks;

    try {
      const response: any = await this.service.uploadDesign(+id);
      let success = !!response
      if(success) {
        Toasts.success(t(Messages.request_upload_design_accepted))
      } else {
        Toasts.error(t(Messages.request_failed))
      }
      return response
    } catch (error: any) {
      if(setError) {
        setError(error.message || t(Messages.unknown_error));
      } else {
        Toasts.error(t(Messages.request_failed))
      }
    }
  }

  async searchByFulfillSku(searchText: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillmentOrderEntity,
      async () => {
        let filter: Filter<FulfillmentOrderDto> = {}
        if (searchText) {
          filter.id = { contains: searchText }
        }
        let pageData = await FulfillmentOrderServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async createInvoice(ids: string[] | number[]) {
    try {
      const data = await this.service.bulkAction(FulfillmentOrderApiPaths.ACTIONS, ids, FulfillmentOrderActionEnum.CREATE_INVOICE, {})
      
      return data
    } catch (error) {
      Toasts.error(t(Messages.your_request_execute_unsuccessfully))
    }
  }

  async uploadTrackingNumberToChannel(ids: string[] | number[], refresh: Function) {
    return withPermission(
      ActionEntities.update,
      ResourceEntities.fulfillmentOrderEntity,
      async () => {
        let result = await FulfillmentOrderServices.uploadTrackingNumberToChannel(ids)
        if(result?.success) {
          Toasts.success(t(Messages.upload_tracking_to_channel_enqueued, { number: ids.length }))
          refresh && refresh()
        } else {
          Toasts.error(t((Messages.your_request_execute_unsuccessfully)))
        }
      })
  }

  async loadUploadDesignOptions(id: string | number) {
    if (uploadDesignCache?.id == id && uploadDesignCache?.options?.length) {
      return uploadDesignCache.options
    }

    return withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillmentOrderEntity,
      async () => {

        let uploadDesignOptions = await FulfillmentOrderServices.loadUploadDesignOptions(id)
        const options = uploadDesignOptions?.allDesignNames?.map((design: string) => ({ label: design, value: design })) || []
        if(options?.length) {
          uploadDesignCache = { id, options }
        } else {
          uploadDesignCache = null
        }

        return options
      })
  }
}

let uploadDesignCache: { id: any, options: any[]} | null = null

export const FulfillmentOrderActions = new FulfillmentOrderAction()