import { BulkEditorView } from './../../components/bulk/bulk-editor.type';
import { Logger } from 'core/logger';
import { apiBaseUrls } from "../rest-api-config"
import { CrudService } from "./crud.service"
import { Metafield } from "../../types/metafield.type"
import { FulfillStatus, FulfillStatusIssue, FulfillDesignStatus } from "../../types/fulfill-status.type"
import { RequestOptions } from "../../core/rest-api/rest.api"
import { QueryParams } from "../../core/rest-api/query-builder"
import { OrderActionEnum, OrderApiPaths, OrderDto } from "./order.service"
import { FulfillServiceDto } from "./fulfill-service.service"
import { FulfillVariantDto } from "./fulfill-variation.service"
import { ProductTypeApiPaths } from "./product-type.service";
import { isEmpty } from "lodash";
import { Toasts } from "../../core/notification/notifications";
import { t } from "../../core/translations";
import { Messages } from "../../common/messages";
import { FulfillmentOrderApiPaths, FulfillVariantSuggestionType } from "./fulfillment-order.service";

export interface CreateFulfillOrderDto {
  parentId?: string | number
  orderId?: number
  lineItemId?: number
  orderNumber?: string
  sku?: string
  productName?: string
  quantity?: number
  shippingMethod?: string
  shippingAddress?: any
  totalPrice?: number
  subtotalPrice?: number
  orderCreatedAt?: Date
  customize?: boolean
  customFields?: Metafield[]
  mockupUrl?: string
  designUrl?: string
  artworkUrl?: string
  designBackUrl?: string
  note?: string
  variantOptions?: Metafield[]
  status?: FulfillStatus
  statusIssue?: FulfillStatusIssue
  designStatus?: FulfillDesignStatus
  fulfillProductId?: number
  fulfillServiceId?: number
  fulfillmentId?: number
  fulfillService?: FulfillServiceDto
  fulfillVariant?: FulfillVariantDto
  fulfillment?: any // TODO define type
  variationName?: string
  variationTitle?: string
  customerNote?: string
  lineItems?: any[]
  order?: OrderDto
  artwork?: { url: string, id: string}
  // xuanduc edit
  fulfillDesign?: string
  fulfillMockup?: string
  artworkSku?: string
  customType?:string
  designCustomHistories?: any
  trackingNumber: any
  fulfillmentOrderId?: string
  vendor?: any
  statusMessages?: Array<any>
  fulfillMessages?: Array<any>,
  fulfillDesignThumbnail?: string
  fulfillDesignFolder?: string
}

export interface FulfillOrderDto extends CreateFulfillOrderDto {
  id?: string | number
  fulfillService?: FulfillServiceDto
  fulfillVariant?: FulfillVariantDto
  fulfillment?: any // TODO define type
  variationName?: string
  variationTitle?: string
  customerNote?: string
  lineItems?: any[]
  order?: OrderDto
}

export interface UpdateFulfillOrderDto {
  orderId?: number
  lineItemId?: number
  orderNumber?: string
  sku?: string
  productName?: string
  quantity?: number
  shippingMethod?: string
  shippingAddress?: any
  totalPrice?: number
  subtotalPrice?: number
  orderCreatedAt?: Date
  customize?: boolean
  customFields?: Metafield[]
  mockupUrl?: string
  designUrl?: string
  designBackUrl?: string
  note?: string
  variantOptions?: Metafield[]
  status?: FulfillStatus
  statusIssue?: FulfillStatusIssue
  designStatus?: FulfillDesignStatus
  fulfillProductId?: number
  fulfillServiceId?: number
  customType?: string
  vendorId?: string
}

export const FulfillOrderApiPaths = {
  FULFILL_ORDERS: "/fulfillment/orders",
  FULFILL_ORDERS_WITH_ID: "/fulfillment/orders/{id}",
  FULFILL_ORDERS_QUERY: "/fulfillment/orders/query",
  FULFILL_ORDERS_BULK: "/fulfillment/orders/bulk",
  EXPORT_FOR_FULFILL_SERVICE: "/fulfillment/orders/export/supplier",
  EXPORT_FOR_AUTO_DESIGN: "/fulfillment/orders/export/auto-design",
  EXPORT_PERSONAL_CUSTOM: "/fulfillment/orders/export/custom-design",
  EXPORT_MANUAL: "/fulfillment/orders/export/manual-design",
  IMPORT_FULFILLING: "/fulfillment/orders/import/fulfilling",
  IMPORT_FULFILLED: "/fulfillment/orders/import/fulfilled",
  COUNT_STATUS: "/fulfillment/orders/count/status",
  ACTIONS: "/fulfillment/orders/actions",
  CREATE_FULFILL_DESIGN: "/fulfillment/orders/{id}/create-fulfill-design",
  REVIEW_DESIGN_CUSTOM: "/fulfillment/orders/query/review-design-custom",
  CREATE_DESIGN_CUSTOM: "/fulfillment/orders/actions/create-design-custom",
  SAVE_DESIGN_CUSTOM: "/fulfillment/orders/actions/save-design-custom",
  REQUEST_FULFILL: "/fulfillment/orders/{id}/fulfill",
  QUERY_FULFILL_ORDER_SUGESTION: '/fulfillment/orders/query/suggestion',
  GET_UPLOAD_DESIGN_OPTIONS: '/fulfillment/orders/{id}/upload-design-options',
}

export class FulfillOrderService extends CrudService<FulfillOrderDto, CreateFulfillOrderDto, UpdateFulfillOrderDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: FulfillOrderApiPaths.FULFILL_ORDERS_WITH_ID,
        updateById: FulfillOrderApiPaths.FULFILL_ORDERS_WITH_ID,
        deleteById: FulfillOrderApiPaths.FULFILL_ORDERS_WITH_ID,
        query: FulfillOrderApiPaths.FULFILL_ORDERS_QUERY,
        base: FulfillOrderApiPaths.FULFILL_ORDERS,
        deleteMany: FulfillOrderApiPaths.FULFILL_ORDERS_BULK,
        updateMany: FulfillOrderApiPaths.FULFILL_ORDERS_BULK,
      },
    })
  }

  async createFulfillDesign(id: string, options?: RequestOptions) {
    let response = await this.restApi.post(FulfillOrderApiPaths.CREATE_FULFILL_DESIGN, { params: { id: id }, ...options })
    return response
  }

  async requestFulfillOrder(id: string, options?: RequestOptions) {
    let response = await this.restApi.post(FulfillOrderApiPaths.REQUEST_FULFILL, { params: { id: id }, ...options })
    return response
  }

  async loadDesignUrls(ids: string[] | number[]) {
    let response = await this.restApi.post(FulfillOrderApiPaths.ACTIONS, {
      data: {
        ids,
        action: OrderActionEnum.CHECK_DESIGN_FOLDER,
      },
    })
    return this.returnDataOrThrow(response)
  }

  async uploadFulfillDesign(ids: string[] | number[]) {
    let response = await this.restApi.post(FulfillOrderApiPaths.ACTIONS, {
      data: {
        ids,
        action: OrderActionEnum.CREATE_FULFILL_DESIGN,
      },
    })
    return this.returnDataOrThrow(response)
  }

  async createFulfillmentOrder(ids: string[] | number[]) {
    let response = await this.restApi.post(FulfillOrderApiPaths.ACTIONS, {
      data: {
        ids,
        action: OrderActionEnum.CREATE_FULFILLMENT_ORDER,
      },
    })
    return this.returnDataOrThrow(response)
  }

  async recreateFulfillProduct(ids: string[] | number[]) {
    let response = await this.restApi.post(FulfillOrderApiPaths.ACTIONS, {
      data: {
        ids,
        action: OrderActionEnum.RECREATE_FULFILL_ORDER,
      },
    })
    return this.returnDataOrThrow(response)
  }

  async exportForFulfillService(query: QueryParams<FulfillOrderDto>, options?: RequestOptions) {
    let response = await this.restApi.post(FulfillOrderApiPaths.EXPORT_FOR_FULFILL_SERVICE, {
      data: query,
      ...options,
    })
    return response
  }

  async exportForAutoDesign(query: QueryParams<FulfillOrderDto>, options?: RequestOptions) {
    let response = await this.restApi.post(FulfillOrderApiPaths.EXPORT_FOR_AUTO_DESIGN, {
      data: query,
      ...options,
    })
    return response
  }

  async exportReviewedPersonalCustom(query: QueryParams<FulfillOrderDto>, options?: RequestOptions) {
    let response = await this.restApi.post(FulfillOrderApiPaths.EXPORT_PERSONAL_CUSTOM, {
      data: query,
      ...options,
    })
    return response
  }

  async exportManual(query: QueryParams<FulfillOrderDto>, options?: RequestOptions) {
    let response = await this.restApi.post(FulfillOrderApiPaths.EXPORT_MANUAL, {
      data: query,
      ...options,
    })
    return response
  }

  async importFulfillingOrders(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    let response = await this.restApi.post(`${FulfillOrderApiPaths.IMPORT_FULFILLING}?replace_existed=${replaceExisted}`, {
      data: data,
      ...options,
    })
    return response
  }

  async importFulfilledOrders(data: FormData, replaceExisted?: boolean, options?: RequestOptions) {
    let response = await this.restApi.post(`${FulfillOrderApiPaths.IMPORT_FULFILLED}?replace_existed=${replaceExisted}`, {
      data: data,
      ...options,
    })
    return response
  }

  async getCountByStatus() {
    const response = await this.restApi.get(FulfillOrderApiPaths.COUNT_STATUS, { snoozeErrorMessage: true })
    return this.returnDataOrThrow(response)
  }

  async queryForBulkEdit(ids: string[] | number[], editor?: any, options?: RequestOptions) {
    if(editor === BulkEditorView.REVIEW_CUSTOM){
      const response = await this.restApi.post(FulfillOrderApiPaths.REVIEW_DESIGN_CUSTOM, { data: { ids }})
      return { pageItems: this.returnDataOrThrow(response) }
    }

    let query: QueryParams<any> = { filter: { id: { contains: ids } }, limit: Math.max(200, ids.length )}
    return this.query(query, options)
  }

  async createDesignCustom (ids: string[] | number[]) {
    let response = await this.restApi.post(FulfillOrderApiPaths.CREATE_DESIGN_CUSTOM, {
      data: {
        ids,
      },
    })
    return this.returnDataOrThrow(response)
  }

  async saveReviewDesignCustom(bulkUpdateData: Record<string, any>) {
    if (!isEmpty(bulkUpdateData)) {
      let dataById = {} as any
      for (const key in bulkUpdateData) {
        dataById[key] = bulkUpdateData[key]
      }
      let response = await this.restApi.post(FulfillOrderApiPaths.SAVE_DESIGN_CUSTOM, { data: { dataById } })
      return this.returnDataOrThrow(response)
    }
  }

  async saveEditFulfillmentOrders(bulkUpdateData: Record<string, any>) {
    if (!isEmpty(bulkUpdateData)) {
      let dataById = {} as any
      for (const key in bulkUpdateData) {
        dataById[key] = bulkUpdateData[key]
      }
      let response = await this.restApi.put(FulfillOrderApiPaths.FULFILL_ORDERS_BULK, { data: { dataById } })
      return this.returnDataOrThrow(response)
    }
  }

  async bulkChangeFulfillService(ids: string[], fulfillServiceId?: string | number) {
    let response = await this.restApi.post(FulfillOrderApiPaths.QUERY_FULFILL_ORDER_SUGESTION, {
      data: {
        ids,
        data: {
          fulfillServiceId,
          type: FulfillVariantSuggestionType.FULFILL_VARIANT_BY_FULFILL_SERVICE,
        }
      }
    });
    return response
  }

  async getSuggestionFulfillVariant(ids: string[], replacement = false) {
    let response = await this.restApi.post(FulfillOrderApiPaths.QUERY_FULFILL_ORDER_SUGESTION, {
      data: {
        ids,
        data: {
          type: replacement ? FulfillVariantSuggestionType.FULFILL_VARIANT_FOR_REPLACEMENT : FulfillVariantSuggestionType.FULFILL_VARIANT
        }
      }
    });
    return response
  }

  async loadUploadDesignOptions(id: string | number) {
    let response = await this.restApi.get(FulfillOrderApiPaths.GET_UPLOAD_DESIGN_OPTIONS, { params: { id } })
    return this.returnDataOrThrow(response)
  }
}

export const FulfillOrderServices = new FulfillOrderService()
