import RestApi, { RestApiOptions, RestApiResponse } from "./rest.api";

export class RestApiService {
  restApi: RestApi

  constructor(options: RestApiOptions = {} as any) {
    this.restApi = new RestApi({
      snoozeErrorMessage: false,
      ...options
    });
  }

  returnDataOrThrow(response?: RestApiResponse | any) {
    if(response?.error) throw response?.error
    return response?.data
  }
}