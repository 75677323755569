import { CrudActions } from "./crud.action";
import { Logger } from "../../core/logger";
import { AdminUtils } from "../../utils/admin-utils";
import { ArtworkDto, ArtworkService, ArtworkServices, UpdateArtworkDto } from "data/services/artwork.service";
import { Filter } from "core/rest-api/query-builder";
import { ArtworkTemplateDto, ArtworkTemplateService, ArtworkTemplateServices, UpdateArtworkTemplateDto } from "data/services/artwork-template.service";
import { ProductTypeServices } from "../services/product-type.service";
import { t } from "../../core/translations";
import { Messages } from "../../common/messages";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { withPermission } from "common/hooks/use-permission";

class ArtworkTemplateAction extends CrudActions<ArtworkTemplateService, ArtworkTemplateDto,UpdateArtworkTemplateDto >{
  constructor() {
    super(new ArtworkTemplateService());
  }

  async getArtworkTemplate() {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.artworkTemplateEntity,
      async () => {
        let pageData = await ArtworkTemplateServices.query({ page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async searchTemplateByName(searchText: string, withNoneTemplate?: boolean) {
    let filter: Filter<any> = {};
    if (searchText) {
      filter.name = { contains: searchText };
    }
    let pageData = await ArtworkTemplateServices.query({ filter, page: 1, limit: 20 });
    let templates = pageData?.pageItems || []
    if (withNoneTemplate && templates.length) {
      templates = [{ name: t(Messages.choose_custom_tempalte) }, ...templates]
    }
    return templates
  }

}

export const ArtworkTemplateActions = new ArtworkTemplateAction()
