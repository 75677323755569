import { Labels } from "common/labels"
import { Messages } from "common/messages"
import { ModalContent } from "components/modal/portable-modal"
import { Toasts } from "core/notification/notifications"
import { t } from "core/translations"
import { BiLinkExternal } from "react-icons/bi"
import { Link } from "react-router-dom"
import { Alert, Button, Col, Row } from "reactstrap"
import { RouteNames } from "routes"
import { AdminUtils } from "utils/admin-utils"

export interface resultInvoice {
  data?: any[]
  failed?: string[]
  success?: string[]
  skipped?: string[]
  notFound?: string[]
  all?: string[]
}

export interface propsCreateInvoiceResultModal {
  result: resultInvoice
  onClose: Function
}

export const CreateInvoiceResultModal = (props: propsCreateInvoiceResultModal) => {
  const { result, onClose } = props
  return (
    <>
      <ModalContent>
        <div style={{ maxHeight: 550 }}>
          <Row>
            <div className="d-flex flex-column">
              <h5 className="mb-4">{t(Messages.create_invoice_successfully, { number: result.all?.length })}</h5>
              <div className="d-flex flex-column">
                <>
                  <p className="mb-3 me-2 fw-bold d-flex">
                    {t(Labels.total_rows)}: {result?.all?.length}
                    <ResultDetailComponent ids={result?.all} />
                  </p>
                  <p className="mb-3 fw-bold d-flex">
                    {t(Labels.successfully_imported)}: {result?.success?.length}
                    <ResultDetailComponent ids={result?.success} />
                  </p>
                  <p className="mb-3 fw-bold d-flex">
                    {t(Labels.failed)}: {result?.failed?.length}
                    <ResultDetailComponent ids={result?.failed} />
                  </p>
                  <p className="mb-4 fw-bold d-flex">
                    {t(Labels.skipped)}: {result?.skipped?.length}
                    <ResultDetailComponent ids={result?.skipped} />
                  </p>
                </>
              </div>
            </div>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mb-2">
              <Button color="secondary" onClick={() => onClose()}>
                {t(Labels.close)}
              </Button>
            </Col>
          </Row>
        </div>
      </ModalContent>
    </>
  )
}

interface ResultDetailComponentProps {
  ids?: string[]
}

const ResultDetailComponent = (props: ResultDetailComponentProps) => {
  const onClickCopy = (e: any) => {
    e.preventDefault()
    navigator.clipboard.writeText(props.ids?.join(",") || "")
    Toasts.info(t(Messages.the_ids_is_copied_to_clipboard))
  }

  return (
    <span className="ms-2 d-flex">
      {props.ids?.length ? (
        <a href="#" onClick={onClickCopy}>
          {Labels.click_to_copy_ids}
        </a>
      ) : // <>
      //   <span className="mx-1 fw-medium">or</span>
      //   <Link target={"_blank"} to={`${RouteNames.FULFILLMENT_ORDERS}?ids=${props.ids?.join(",")}`}>
      //     {Labels.view_details}
      //     <BiLinkExternal style={{ marginLeft: 2 }} size={12} />
      //   </Link>
      // </>
      null}
    </span>
  )
}
