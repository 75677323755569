import { defaultPageInfo } from "common/constants"
import { Labels } from "common/labels"
import { Messages } from "common/messages"
import { FormikForm } from "components/form/FormikForm"
import { FormikInput } from "components/form/FormikInput"
import ActionButton from "components/input/ActionButton"
import { SearchableSelect } from "components/input/SearchableSelect"
import { ModalButtons, ModalContent } from "components/modal/portable-modal"
import { Toasts } from "core/notification/notifications"
import { t } from "core/translations"
import { FulfillmentInvoiceActions } from "data/actions/fulfillment-invoice.action"
import { FulfillmentOrderActions } from "data/actions/fulfillment-order.action"
import { portableModalState } from "data/atoms/app.atom"
import { fulfillmentInvoiceQueryState, invoiceByIdSelector } from "data/atoms/fulfillment-invoice.atom"
import { FormikHelpers, useFormik } from "formik"
import { isEmpty, omit, pick } from "lodash"
import { useEffect, useState } from "react"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil"
import { Dates } from "utils/dates"
import { Forms } from "utils/forms"
import { Storages } from "utils/storages"
import * as Yup from "yup"
import { DateInput } from "./date-input"

const invoiceSchema = Yup.object({
  parentInvoiceId: Yup.string()
    .nullable(),
  fulfillmentOrderId: Yup.string()
    .required(t(Messages.field_required, { field: t(Labels.order_fulfillment) }))
    .nullable(),
  // invoiceDate: Yup.date().required(t(Messages.field_required, { field: t(Labels.Invoice_issue_date) })),
  quantity: Yup.number()
    .min(1, t(Messages.field_required_min, { field: t(Labels.amount), min: 0 }))
    .required(t(Messages.field_required, { field: t(Labels.amount) })),
  price: Yup.number()
    .required(t(Messages.field_required, { field: t(Labels.price_order) })),
  discount: Yup.number()
    .min(0, t(Messages.field_required_min, { field: t(Labels.promotion), min: 0 }))
    .required(t(Messages.field_required, { field: t(Labels.promotion) })),
    // .test("checkTotalNegative", (value: any,conText: Yup.TestContext) => {
    //   if(conText.parent.discount >  conText.parent.price * conText.parent.quantity) {
    //     return conText.createError({ message: t(Labels.promotion_less_price_order) })
    //   }
    //   return true
    // }),
  total: Yup.number().nullable()
})

interface propsPopupCreateComponent {
  data?: any
}
export const PopupCreateComponent = (props: propsPopupCreateComponent) => {
  const isCreate = !props.data
  const id = props.data && props.data.id
  const setPortableModal = useSetRecoilState(portableModalState)
  const invoiceLoad = useRecoilValueLoadable(invoiceByIdSelector(props.data?.parentInvoiceId))
  const [invoice, setInvoice] = useState<any>({ quantity: 1, price: 0, tax: 0, discount: 0 })
  const [queryState, setQueryState] = useRecoilState(fulfillmentInvoiceQueryState(defaultPageInfo))


  useEffect(() => {
    if(invoiceLoad.state === 'hasValue' && id){
        setInvoice((prev: any) => ({...prev, invoice: invoiceLoad.contents}))
    }
  }, [invoiceLoad.contents])

  const refreshData = () => {
    setQueryState((prev: any) => ({ ...prev }))
  }
  const validation = useFormik<any>({
    initialValues: invoice,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: invoiceSchema,
    onSubmit: (value, helpers) => handleSubmit(value, helpers),
  })
  useEffect(() => {
    if (!isCreate) {
      setInvoice((prev: any) => ({ ...prev, ...props.data }))
    }
  }, [props.data])

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    const payload = {
      fulfillInvoiceStatus: "pending",
      vendorInvoiceStatus: "pending",
      vendorStatus: 'pending',
      invoiceDate: Dates.toDefaultLocaleDate(new Date())
    }

    let changedData = !isCreate ? Forms.getChangedValues(values, invoice) : {...payload, ...values}

    let data = omit(changedData, "invoice")

    try {
      helpers.setErrors({})
      helpers.setSubmitting(true)

      let savedData = await FulfillmentInvoiceActions.save(id, data)
      if (savedData?.id) {
        refreshData()
        Toasts.success(t(isCreate ? Messages.create_fulfillment_invoice_successfully : Messages.save_fulfillment_invoice_successfully))
        setPortableModal({ open: false, title: "" })
      }
    } catch (error) {
      helpers.setSubmitting(false)
    }
  }

  const valueSubtotal = validation.values.quantity * validation.values.price 
  const valueTotal = validation.values.quantity * validation.values.price - (+validation.values.discount || 0) + (+validation.values.tax || 0)

  useEffect(() => {
    validation.setFieldValue("subtotal", valueSubtotal)
    validation.setFieldValue("total", valueTotal)
  }, [valueSubtotal, validation.values.discount, validation.values.tax])

  useEffect(() => {
    if(validation.values.fulfillmentOrder){
      validation.setFieldValue("fulfillmentOrderId", validation.values.fulfillmentOrder?.id)
    }
  }, [validation.values.fulfillmentOrder, invoice])

  useEffect(() => {
      validation.setFieldValue("parentInvoiceId", +validation.values.invoice?.id ? validation.values.invoice?.id : 0)
  }, [validation.values.invoice, invoice])

  useEffect(() => {
    if(validation.values.invoice?.fulfillmentOrder){
      validation.setFieldValue("fulfillmentOrder", validation.values.invoice?.fulfillmentOrder)
    }
  }, [validation.values.invoice])

  return (
    <>
      <ModalContent>
        <FormikForm validation={validation}>
            <FormGroup>
              <Col>
                <FormikInput
                  name={"parentInvoiceId"}
                  label={t(Labels.add_invoice)}
                  required={true}
                  validation={validation}
                  customInput={({ handleChange, handleBlur, errorData }) => {
                    const newGetDataOptions = async (search: string) => {
                      const data = await FulfillmentInvoiceActions.searchById(search === Labels.new_create ? "" : search)
                      return [{id: Labels.new_create, value: 'noOption'}, ...data || []]
                    }
                    return (
                      <SearchableSelect
                        loadOptions={newGetDataOptions}
                        onChange={value => {
                          validation.setFieldValue("invoice", value)
                        }}
                        value={validation.values.invoice}
                        getOptionLabel={(option: any) => option?.id}
                        getOptionValue={(option: any) => option?.id}
                        menuPortalTarget={document.querySelector("select")}
                        styles={errorData}
                        onBlur={handleBlur}
                        defaultOptions={true}
                        placeholder={Labels.add_invoice_select}
                      />
                    )
                  }}
                />
              </Col>
              <Col>
                <FormikInput
                  name={"fulfillmentOrderId"}
                  label={t(Labels.order_fulfillment)}
                  validation={validation}
                  customInput={({ handleChange, handleBlur, errorData }) => {
                    const fulfillmentOrder = validation.values.fulfillmentOrder
                    if(validation.values.invoice?.fulfillmentOrder){
                      return <Input disabled={true} value={`${fulfillmentOrder?.id || ""}${fulfillmentOrder?.orderNumber ? ` - ${fulfillmentOrder.orderNumber}` : ""}`} />
                    }
                    return (
                      <SearchableSelect
                        loadOptions={FulfillmentOrderActions.searchByFulfillSku}
                        onChange={value => {
                          validation.setFieldValue("fulfillmentOrder", value)
                        }}
                        getSearchValue={(option: any) => option?.id}
                        value={fulfillmentOrder}
                        getOptionLabel={(option: any) =>  `${option?.id || ""}${option?.orderNumber ? ` - ${option.orderNumber}` : ""}`}
                        getOptionValue={(option: any) => option?.id}
                        menuPortalTarget={document.querySelector("select")}
                        styles={errorData}
                        onBlur={handleBlur}
                        defaultOptions={true}
                        placeholder={Labels.order_fulfillment_select}
                      />
                    )
                  }}
                />
              </Col>
              {/* <Row>
                <Col lg={6}>
                  <FormikInput
                    name={"fulfillService"}
                    label={t(Labels.propduct)}
                    validation={validation}
                    placeholder={Labels.propduct_hint}
                    disabled={true}
                    value={validation.values.fulfillmentOrder?.fulfillService?.name || validation.values.fulfillmentOrder?.fulfillServiceName}
                    readOnly={true}
                  />
                </Col>
                <Col lg={6}>
                  <FormikInput
                    name={"fulfillmentVendor"}
                    label={t(Labels.seller)}
                    validation={validation}
                    placeholder={Labels.seller_hint}
                    disabled={true}
                    value={validation.values.fulfillmentOrder?.vendor?.name || validation.values.fulfillmentVendor?.name}
                    readOnly={true}
                  />
                </Col>
              </Row> */}
              <Row>
                <Label>{Labels.Invoice_issue_date}</Label>  
                <DateInput
                  value={!isCreate ? new Date(validation.values.invoiceDate || new Date()) : validation.values.invoiceDate || new Date()}
                  className="w-100 mb-2 inputDatePicker"
                  placeholderText={Labels.Invoice_issue_date}
                  onChange={(date: any) => validation.setFieldValue("invoiceDate", date)}
                />
              </Row>
              <Row>
                <Col lg={6}>
                  <FormikInput
                    name={"quantity"}
                    label={t(Labels.amount)}
                    type="number"
                    placeholder={Labels.amount_hint}
                    validation={validation}
                    value={validation.values.quantity}
                  />
                </Col>
                <Col lg={6}>
                  <FormikInput
                    name={"price"}
                    label={t(Labels.price_order)}
                    type="number"
                    validation={validation}
                    placeholder={Labels.price_order_hint}
                    value={validation.values.price}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormikInput
                    name={"discount"}
                    label={t(Labels.promotion)}
                    type="number"
                    validation={validation}
                    placeholder={Labels.promotion_hint}
                    value={validation.values.discount}
                  />
                </Col>
                <Col lg={6}>
                <FormikInput
                    name={"tax"}
                    label={t(Labels.tax)}
                    type="number"
                    placeholder={Labels.tax_hint}
                    validation={validation}
                    value={validation.values.tax}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikInput
                    name={"total"}
                    type="number"
                    label={t(Labels.into_money)}
                    validation={validation}
                    value={valueTotal}
                    placeholder={Labels.into_money_hint}
                    readOnly={true}
                    min={0}
                  />
                </Col>
                {/* <Col lg={6}>
                  <FormikInput
                    name={"fee"}
                    label={t(Labels.fee)}
                    type="number"
                    validation={validation}
                    placeholder={Labels.fee_hint}
                    value={validation.values.fee}
                  />
                </Col> */}
              </Row>
            </FormGroup>
        </FormikForm>
      </ModalContent>
      <ModalButtons hiddenConfirmButton={true}>
        <ActionButton className="btn btn-success" onClick={() => validation.submitForm()}>
          {isCreate ? t(Labels.create) : t(Labels.save)}
        </ActionButton>
      </ModalButtons>
    </>
  )
}
