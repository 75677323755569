import { FulfillVariantActions } from 'data/actions/fulfill-variation.action';
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { isEmpty } from 'lodash';
import { ProductTemplateActions } from 'data/actions/product-template.action';

export const productTemplateIdState = atomFamily<QueryParams<any>, any>({
  key: "productTemplateIdState",
  default: (id: any) => {
    return id;
  }
});

export const productTemplateIdSelector = selectorFamily({
  key: "productTemplateIdSelector",
  get: (id: any) => async ({ get }) => {
    let productTemplateId = get(productTemplateIdState(id));

    if (+productTemplateId > 0) {
      let data = await ProductTemplateActions.getById(+productTemplateId, { snoozeErrorMessage: false });
      return data;
    }

    return {};
  }
});

export const productTemplatesQueryState = atomFamily<QueryParams<any>, QueryParams<any>>({
  key: "productTemplatesQueryState",
  default: (params: any) => ({ ...defaultPageInfo, ...params })
});

export const productTemplatesSelector = selector({
  key: "productTemplatesSelector",
  get: async ({ get }) => {
    let query = get(productTemplatesQueryState(defaultPageInfo));
    let pageData = await ProductTemplateActions.query(query, { snoozeErrorMessage: false });
    return pageData;
  }
});

export const suggestionVariantOptionKeySelector = selector({
  key: 'suggestionVariantOptionKeySelector',
  get: async ({ get }) => {
    let suggestionValues: string[] = await ProductTemplateActions.getVariantOptionsSuggestion()
    return suggestionValues
  },
});