import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { QueryParams } from "../../core/rest-api/query-builder";
import { defaultPageInfo } from "../../components/table/RemoteTable";
import { SaleChannelServices } from "../services/sale-channel-service";

export const allSaleChannelsState = atom({
  key: 'allSaleChannelsState',
  default: { ...defaultPageInfo, limit: 250, initial: true}
})

export const allSaleChannelsSelector = selector({
  key: 'allSaleChannelsSelector',
  get: async ({ get }) => {
    let query = get(allSaleChannelsState)
    let pageData = await SaleChannelServices.query(query, { snoozeErrorMessage: true })
    return pageData?.pageItems || [];
  },
});

export const saleChannelQueryState = atomFamily<QueryParams<any>, QueryParams<any>>({
  key: 'saleChannelQueryState',
  default: (params: QueryParams<any>) => ({ ...defaultPageInfo, ...params })
})

export const saleChannelSelector = selector({
  key: 'saleChannelSelector',
  get: async ({ get }) => {
    let query = get(saleChannelQueryState(defaultPageInfo))
    let commerces = await SaleChannelServices.query(query, { snoozeErrorMessage: true })
    return commerces;
  },
});

export const saleChannelQueryIdState = atomFamily<QueryParams<any>, any>({
  key: "saleChannelQueryIdState",
  default: (id: any) => {
    return id;
  }
});

export const saleChannelIdSelector = selectorFamily({
  key: "saleChannelIdSelector",
  get: (id: any) => async ({ get }) => {
    let commerceId = get(saleChannelQueryIdState(id));

    if (+commerceId > 0) {
      let data = await SaleChannelServices.getById(+commerceId, { snoozeErrorMessage: false });
      return data;
    }
    return {};
  }
});