import { apiBaseUrls } from "../rest-api-config";
import { CrudService } from "./crud.service";
import { FulfillProductServices } from "./fulfill-product.service";


export interface ColumnMapping {
  key: string,
  fromName: string,
  exportColumn: string
}

export const ColumnMappingKeyName = {
  KEY: 'key',
  NAME: 'fromName',
  COLUMN: 'exportColumn'
}

export interface FulfillServiceDto {
  id?: string
  slug?: string
  name: string
  mapping?: Array<ColumnMapping>
  designStorageType?: string
  teamId?: string
  skuFormat?: {
    position: string
    searchText: string
  }
}

export interface CreateFulfillServiceDto {
  slug?: string
  name: string,
  fulfillFulfillServiceId: number
}

export interface UpdateFulfillServiceDto extends CreateFulfillServiceDto {}

export const FulfillServiceApiPaths = {
  BASE: '/fulfillment/services',
  WITH_ID: '/fulfillment/services/{id}',
  QUERY: '/fulfillment/services/query',
  BULK: '/fulfillment/services/bulk',
  MAPPING_DEFAULT: '/fulfillment/services/mapping/default',
}

export class FulfillService extends CrudService<any, CreateFulfillServiceDto, UpdateFulfillServiceDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: FulfillServiceApiPaths.WITH_ID,
        updateById: FulfillServiceApiPaths.WITH_ID,
        deleteById: FulfillServiceApiPaths.WITH_ID,
        query: FulfillServiceApiPaths.QUERY,
        base: FulfillServiceApiPaths.BASE,
        deleteMany: FulfillServiceApiPaths.BULK,
        updateMany: FulfillServiceApiPaths.BULK
      }
    });
  }

  async search(searchValue: string) {
    let filter = searchValue ? { name: { contains: searchValue } } : {};
    let pageData = await FulfillServices.query({ filter, page: 1 });
    return pageData?.pageItems || [];
  }

  async getDefaultMappingData() {
    let response = await this.restApi.get(FulfillServiceApiPaths.MAPPING_DEFAULT, { snoozeErrorMessage: true });
    return this.returnDataOrThrow(response)
  }
}

export const FulfillServices = new FulfillService()
