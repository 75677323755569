import { RequestOptions } from "core/rest-api/rest.api";
import { apiBaseUrls } from "data/rest-api-config";
import { CrudService } from "./crud.service";


export interface FulfillVariantMapperDto {
  id?: string | number
  productVariation?: string
  lastModifiedBy?: any
  fulfillServiceId?: number
  fulfillVariantId?: number
  fulfillVariantName?: string
  fulfillVariant?: any
  fulfillService?: any
  // fulfillVariant?: FulfillVariantEntity
  // fulfillService?: FulfillServiceEntity
  tenantId?: string
  teamId?: string
}

export interface CreateFulfillVariantMapperDto {
  tenantId?: string
  teamId?: string
  productVariation?: string
  fulfillVariantId?: number
  fulfillServiceId?: number
  fulfillVariantName?: string

}

export interface UpdateFulfillVariantMapperDto extends CreateFulfillVariantMapperDto {}

export const FulfillVariantMapperApiPaths = {
  BASE: '/fulfillment/products/variants/mapper',
  WITH_ID: '/fulfillment/products/variants/mapper/{id}',
  QUERY: '/fulfillment/products/variants/mapper/query',
  BULK: '/fulfillment/products/variants/mapper/bulk',
}


export class FulfillVariantMapperService extends CrudService<FulfillVariantMapperDto, CreateFulfillVariantMapperDto, UpdateFulfillVariantMapperDto> {
  constructor() {
    super({
      baseUrl: apiBaseUrls.admin,
      snoozeErrorMessage: false,
      pathNames: {
        getById: FulfillVariantMapperApiPaths.WITH_ID,
        updateById: FulfillVariantMapperApiPaths.WITH_ID,
        deleteById: FulfillVariantMapperApiPaths.WITH_ID,
        query: FulfillVariantMapperApiPaths.QUERY,
        base: FulfillVariantMapperApiPaths.BASE,
        deleteMany: FulfillVariantMapperApiPaths.BULK,
      }
    });
  }

  // async getByFulfillProductId(fulfillProductId: number, options?: RequestOptions) {
  //   return this.query({
  //     limit: 250,
  //     filter: { fulfillProductId: { equalTo: fulfillProductId }}
  //   }, options)
  // }

  // async searchFulfillVariantsByVariantTitle(searchValue: string, fulfillServiceId?: any) {
  //   let filter: any = {
  //     variantTitle: { contains: searchValue },
  //     ...(fulfillServiceId ? { fulfillServiceId: { equalTo: fulfillServiceId } } : {}),
  //   } 

  //   let pageData = await this.query({ filter, page: 1 });
  //   return pageData?.pageItems || [];
  // }
}

export const FulfillVariantMapperServices = new FulfillVariantMapperService()