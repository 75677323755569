import { Button } from "reactstrap"
import { createRef, useEffect, useMemo, useState } from "react"
import { SetterOrUpdater } from "recoil"
import ActionComponent from "components/input/ActionComponent"
import { t } from "core/translations"
import { Labels } from "common/labels"
import { Logger } from "core/logger"
import DropdownComponent from "components/dropdown/DropdownComponent"
import { TabOption } from "components/table/TableFilter"
import { ActionOption, RemoteTableSelectionData } from "components/table/SelectedRowAction"

export const SelectedActionNestedDropdown = (props: RemoteTableSelectionData) => {
  let [open, setOpen] = useState<boolean>(false)
  let [disabled, setDisabled] = useState<boolean>(false)
  useEffect(() => setOpen(props.selectedIds.length > 0), [props.selectedIds])
  let otherActionLabel = t(Labels.other_action)

  const isGroupAction = Boolean(props.actions?.find(action => action.groupName))
  const groupNames: any[] = [...new Set(props.actions?.map(action => action.groupName).filter(Boolean))]
  groupNames.push(otherActionLabel) // Other Action always at the end

  return !open ? null : (
    <div className="btn-group me-3 mb-2" role="group" aria-label="Basic example">
      <Button outline color="secondary" className="btn btn-outline-secondary mb-2">
        {props.selectedIds.length} selected
      </Button>
      {isGroupAction ? (
        groupNames.map((groupName: string, idx: number) => {
          return (
            <NestedDropdownSelect
              key={idx}
              actions={props.actions?.filter(
                action => action.groupName === groupName || (groupName === otherActionLabel && !action.groupName)
              )}
              labelSelect={groupName}
              disabled={disabled}
              setDisabled={setDisabled}
              tableSelectionData={props}
            />
          )
        })
      ) : (
        <NestedDropdownSelect actions={props.actions} disabled={disabled} setDisabled={setDisabled} tableSelectionData={props} />
      )}
    </div>
  )
}

interface NestedDropdownSelectProps {
  disabled: boolean
  setDisabled: any
  actions?: ActionOption[]
  tableSelectionData: RemoteTableSelectionData
  labelSelect?: string
}

const NestedDropdownSelect = (props: NestedDropdownSelectProps) => {
  const otherActionLabel = t(Labels.other_action)
  const { disabled, setDisabled, tableSelectionData, labelSelect } = props
  const [execute, setExecute] = useState<boolean>(false)
  const [open, setOpen] = useState(false)

  const handleClick = async (action: ActionOption) => {
    setExecute(true)
    setDisabled(true)
    setOpen(false)
    try {
      await action.onClick(tableSelectionData)
    } catch (error) {
      Logger.error(error)
    } finally {
      setExecute(false)
      setDisabled(false)
    }
  }

  return (
    <ActionComponent
      execute={execute && disabled}
      exComponent={
        <button disabled className="btn btn-outline-secondary mb-2 btn btn-outline-secondary">
          {t(Labels.action_running)}
        </button>
      }
    >
      <span style={{ position: "relative" }}>
        <button
          type="button"
          className="btn btn-outline-secondary mb-2"
          onClick={() => setOpen(prev => !prev)}
          style={
            !labelSelect || labelSelect === otherActionLabel
              ? {
                  borderRight: "1px solid",
                  borderLeft: 0,
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                }
              : { borderLeft: 0, borderRadius: 0 }
          }
          disabled={disabled}
        >
          {t(labelSelect || Labels.choose_actions)} <i className="bx bx-caret-down font-size-16 align-middle" />
        </button>
        <MenuDropdown open={open} >
          <div className="mb-0 overflow-auto bg-white py-2" style={{ maxHeight: 295 }}>
            {props.actions?.map((action: ActionOption, index: number) => {
              const enable = useMemo(() => {
                return action.enableCondition ? action.enableCondition(action, tableSelectionData) : true
              }, [tableSelectionData.selectedIds, tableSelectionData.activeTab, tableSelectionData.selectedItems])

              if (action.name === "separator") {
                return <hr key={index} className="my-1" />
              } else if (action.name === "group") {
                return (
                  <div key={index} className="m-2 font-size-16">
                    {action.label}
                  </div>
                )
              } else if (action.option && action.option.listOption?.length > 0) {
                const [openDropdown, setOpenDropdown] = useState<boolean>(false)
                return (
                  <div
                    onMouseLeave={() => setOpenDropdown(false)}
                    onMouseMove={() => setOpenDropdown(true)}
                    style={{ minWidth: 200, maxHeight: 295 }}
                  >
                    <ButtonComponent onClick={() => setOpenDropdown(true)} label={action.label} enable={enable} />
                    <ul className={`dropdown-menu dropdown-submenu dropdown-submenu-right ${openDropdown ? "d-block" : ""}`}>
                      {action.option.listOption?.map((item, idx) => {
                        return (
                          <li key={idx} className="position-relative">
                            <ButtonComponent
                              key={index}
                              onClick={() => {
                                setOpen(false)
                                if (enable) {
                                  action.option?.onClickOption(tableSelectionData, item)
                                }
                              }}
                              label={item.label}
                              enable={enable}
                            />
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              } else {
                let component = (
                  <ButtonComponent
                    key={index}
                    onClick={() => {
                      if (enable) {
                        handleClick(action)
                      }
                    }}
                    label={action.label}
                    enable={enable}
                  />
                )
                return action?.customRenderer ? action.customRenderer(component, index) : component
              }
            })}
          </div>
        </MenuDropdown>
        <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          content: "",
          top: "0px",
          left: "0px",
          opacity: 0.3,
          display: open ? "block" : "none",
          zIndex: 999998
        }}
        onClick={() => setOpen(false)}
      />
      </span>
    </ActionComponent>
  )
}

interface propsButton {
  onClick: Function
  label: string
  enable: boolean
}

let ButtonComponent = (props: propsButton) => {
  const { onClick, label, enable } = props

  return (
    <Button
      outline
      color="light"
      className="border-0 d-block w-100 text-start px-3 py-2 shadow-none"
      disabled={!enable}
      style={enable ? {} : { color: "rgba(0, 0, 0, 0.9)" }}
      onClick={() => {
        if (enable) {
          onClick()
        }
      }}
    >
      {label}
    </Button>
  )
}

const MenuDropdown = (props: any) => {
  const { open, display, alignRight } = props
  return (
    <div
      style={{
        width: "max-content",
        minWidth: 200,
        boxShadow: "0 1rem 3rem rgb(0 0 0 / 18%)",
        position: "absolute",
        display: open ? "block" : "none",
        zIndex: 999999,
        right: alignRight ? 0 : undefined,
      }}
    >
      {props.children}
    </div>
  )
}
