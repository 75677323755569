import { withPermission } from "common/hooks/use-permission"
import { SearchableSelect } from "components/input/SearchableSelect"
import { t } from "core/translations"
import { SaleChannelActions } from "data/actions/sale-channel.action"
import { SaleChannelDto } from "data/services/sale-channel-service"
import { FormikHandlers, FormikHelpers, FormikState } from "formik"
import { ActionMeta } from "react-select"
import { ActionEntities, ResourceEntities } from "types/permission-type"


export interface SaleChannelSelectProps {
  validation?: FormikHandlers & FormikState<any> & FormikHelpers<any>
  value?: SaleChannelDto | any
  getOptionValue?:Function
  getOptionLabel?:Function
  isMulti?:boolean
  onChange: (value: any, actionMeta?: ActionMeta<any>) => void,
  styles?: any
}

export const SaleChannelSelect = (props: SaleChannelSelectProps) => {
  let { value, validation, onChange, getOptionValue, getOptionLabel, isMulti, ...rest} = props
  return (
    <SearchableSelect
      isMulti={isMulti}
      cacheOptions
      defaultOptions={true}
      value={value}
      defaultValue={value}
      defaultInputValue={value?.sku || ''}
      getOptionValue={getOptionValue ? ((option: SaleChannelDto) => option?.name || '') : undefined}
      getOptionLabel={getOptionLabel ? ((option: SaleChannelDto) => t(option?.name || '')) : undefined}
      loadOptions={SaleChannelActions.searchByDomain}
      onBlur={validation?.handleBlur}
      onChange={props.onChange}
      styles={{menuPortal: styles => ({...styles, zIndex: 99999})}}
      {...rest}
    />
  )
}