import * as Yup from "yup";
import { Labels } from "../../../common/labels";
import { t } from "../../../core/translations";
import { Messages } from "../../../common/messages";

export const fulfillVariantSchema = Yup.object({
  sku: Yup.string().required(t(Messages.field_required, {field: t(Labels.sku)})),
  baseCost: Yup.number().required(t(Messages.field_required, {field: t(Labels.base_cost)})).min(0, t(Messages.field_required_min, { field: t(Labels.base_cost), min: 0 })),
  fulfillProduct: Yup.object({
    id: Yup.number().min(1, t(Labels.fulfill_product_required))
  }).required(t(Messages.field_required, {field: t(Labels.fulfill_product)})),
  variantOptions: Yup.array().min(1, t(Labels.variant_options_required)).nullable()
})