
export const ProxyAuth = () => {
  return (
    <section  className="min-vh-100 bg-white">
      <div  className="container d-flex align-items-center min-vh-100 px-4 py-5">
        <div className="d-flex flex-column align-items-center max-w-lg mx-auto text-center">
          <p className="fs-base fw-medium text-primary">407 error</p>
          <h1 className="fs-1 fw-semibold text-black md-fs-3">
            The change you requested was rejected
          </h1>
          <p style={{maxWidth: '32rem'}} className="mt-4 text-secondary">
            Please provide valid authentication information to proceed with accessing this resource. If you are unsure about the
            authentication details, kindly contact the system administrator or your service provider for assistance.
          </p>
        </div>
      </div>
    </section>
  )
}
