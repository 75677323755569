import React, { useEffect, useState, Fragment, useRef } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormText, Label, Row } from "reactstrap";
import { t } from "../../core/translations";
import Breadcrumb from "components/layout/Breadcrumb";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Labels } from "../../common/labels";
import { RouteNames } from "../../routes";
import { FormikInput } from "../../components/form/FormikInput";
import { Loadable, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { fullfillVariantListSelector, productTypeIdSelector, suggestionVariantOptionKeySelector } from "../../data/atoms/product-type.atom";
import {
  OptionValueMapper,
  ProductTypeDto,
  ProductTypeOption,
  UpdateProductTypeDto,
  FulfillVariantMapper,
  FulfillVariantOption,
  VariantOption
} from "../../data/services/product-type.service";
import { LoadableComponent } from "../../components/common/LoadableComponent";
import { Toasts } from "../../core/notification/notifications";
import { FormikHelpers, useFormik } from "formik";
import Select, { MultiValue } from "react-select";
import Creatable from "react-select/creatable";
import { ProductTypeActions } from "../../data/actions/product-type.action";
import { FormikHandlers, FormikState } from "formik/dist/types";
import { FulfillProductSelect } from "../fulfillments/components/fulfill-product.select";
import { AdminUtils } from "../../utils/admin-utils";
import { FormikForm, FormValidation } from "../../components/form/FormikForm";
import { Logger } from "core/logger";
import { productTypeSchema } from "./validations/product-type.validation";
import { Forms } from "../../utils/forms";
import { isEmpty, omit, pick } from "lodash";
import { channelOrderSettingState } from "../../data/atoms/app.atom";
import { BiLinkExternal } from "react-icons/bi";
import {
  fulfillVariantByFulfillProductIdSelector,
} from "data/atoms/fulfill-variation.atom";
import ActionButton from "components/input/ActionButton";
import FulfillVariantSelect, { getFulfillProductLabel } from "pages/fulfillments/components/fulfill-variation.select";
import { SmallContainer } from "components/layout/SmallContainer";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { WithPermission } from "components/common/WithPermission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { withPermission } from "common/hooks/use-permission";
import { Messages } from "common/messages";
import { CreatableMultiValues } from "components/common/CreatableMultiValues";
import { LabelTooltip } from "components/common/LabelTooltip";
import { FulfillProductType } from "types/fulfill-product-type.type";
import { FulfillOptionForm } from "./components/FulfillVariant.table";
import { preprocessPage } from "utils/utils";
import { TooltipComponent } from "../../components/common/TooltipComponent";
import { FormikInputVariant } from "./components/FormikInputVariant";
import { createLinkEmbedParam, createUrlEmbed } from "utils/embed";


const propertiesPickClone = [ 'code', 'regularPrice', 'salePrice', 'imageSuffixes', 'name', 'productionTime', 'description', 'variantOptions', 'productTypeKey', 'group']

export const ProductTypePage = () => {
  const history = useHistory();
  const params = useParams<Record<string, any>>();
  const location = useLocation();
  const isEditing = +params.id > 0;
  const parentProductType = (location.state as any)?.productType
  const [productType, setProductType] = useState<any>({});
  const dataLoadable = useRecoilValueLoadable(productTypeIdSelector(params.id));
  const channelOrderSetting = useRecoilValue(channelOrderSettingState);
  const suggestionLoadable = useRecoilValueLoadable(suggestionVariantOptionKeySelector);
  const [variantOptionKeys, setVariantOptionKeys] = useState<any>([])

  useEffect(() =>{
    if (suggestionLoadable.state == "hasValue") {
      setVariantOptionKeys(suggestionLoadable.contents || [])
    }
  }, [suggestionLoadable.state])

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.product_type), model: productType})
  }, [productType])

  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(productTypeIdSelector(params.id));
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state =="hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable();
  }, [params.id]);


  useEffect(() => {
    if (dataLoadable.state == "hasValue") {
      let newProductType = {
        ...(isEditing ? {} : {
          variantOptions: AdminUtils.defaultProductVariantOptions,
          customDataKeys: channelOrderSetting?.customDataKeys || []
        }),
        ...(dataLoadable.contents || {})
      };
      // newProductType.fulfillVariantMapper = newProductType.fulfillVariantMapper || [{}];
      setProductType((current: any) => {
        return {...current || {}, ...newProductType}
      });
    }
  }, [dataLoadable.state]);

  const validation = useFormik({
    initialValues: {...productType, parentId: parentProductType?.id},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: productTypeSchema,
    onSubmit: (value: any, helpers) => handleSubmit(value, helpers)
  });

  useEffect(() => {
    const newProductType = pick(parentProductType, propertiesPickClone)
    setProductType(newProductType)
  }, [parentProductType])


  const handleSubmit = async (values: ProductTypeDto, helpers: FormikHelpers<any>) => {
    try {
      helpers.setErrors({});
      helpers.setSubmitting(true);

      const newFulfillOptions: any = values.fulfillOptions?.filter((item: any) => !isEmpty(item)).map((item: any) => ({...item, fulfillVariantId: item.fulfillVariant?.id})) ;

      values = {
        ...values,
        fulfillOptions: newFulfillOptions?.map((item: any) => omit(item, ['idx','fulfillVariant'])) || [],
        variantOptions: values.variantOptions?.filter(option => !isEmpty(option)) || [],
        // variantValueMapper: values.variantValueMapper?.filter(mapper => !isEmpty(mapper)) || [],
        // fulfillVariantMapper: values.fulfillVariantMapper?.filter(mapper => !isEmpty(mapper)) || [],
      };
      let changedData: UpdateProductTypeDto = isEditing ? Forms.getChangedValues(values, productType) : values;

      // productTypeKey is required for backend handle
      changedData = {
        ...changedData,
        productTypeKey: values.productTypeKey
      };
      let result = await ProductTypeActions.save(productType.id || "", changedData);
      if (result?.id) {
        setProductType((current: any) => ({
          ...current,
          ...result
        }));
        !isEditing && setTimeout(() => {
          history.replace(RouteNames.PRODUCT_TYPES_DETAIL.replace(":id", result.id));
        }, 100);
        Toasts.success(t(Messages.save_product_successfully));
      }
    } catch (e) {
      helpers.setSubmitting(false);
      Logger.error("submit error", e);
    }
  };

  const handleClone = () => {
    history.push(createLinkEmbedParam(`${RouteNames.PRODUCT_TYPES}/create`).to, { productType })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <SmallContainer>
          <Breadcrumb goBack={RouteNames.PRODUCT_TYPES} title={isEditing ? t(Labels.edit_product_type) : t(Labels.create_product_type)}>
          {isEditing && (
              <WithPermission action={ActionEntities.update} resource={ResourceEntities.productTypeEntity}>
                <Button className="me-2" color="info" onClick={handleClone}>
                  <i className="bx bx-duplicate font-size-16 align-middle me-1"></i>
                  {t(Labels.clone)}
                </Button>
              </WithPermission>
            )}
            <WithPermission
              action={isEditing ? ActionEntities.update : ActionEntities.create}
              resource={ResourceEntities.productTypeEntity}
            >
              <ActionButton color="success" onClick={() => validation.submitForm()}>
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </WithPermission>
          </Breadcrumb>
          <LoadableComponent loadable={dataLoadable}>
            <FormikForm validation={validation}>
              <Card>
                <CardHeader className="fw-400 fs-5 text-info">{t(Labels.general_information)}</CardHeader>
                <CardBody>
                  <ProductTypeForm validation={validation} data={dataLoadable?.contents || {}} id={params.id} />
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="fw-400 fs-5 text-info">
                  <LabelTooltip tooltip={Labels.variant_option_values_note} className="align-items-center">
                    <Label className="col-form-label p-0">{t(Labels.variant_options)}</Label>
                  </LabelTooltip>
                </CardHeader>
                <CardBody>
                  <ProductTypeVariantOption variantOptionKeys={variantOptionKeys} validation={validation} data={dataLoadable?.contents || {}} id={params.id} />
                </CardBody>
              </Card>
              <Card>
                <CardHeader className="fw-400 fs-5 text-info">{t(Labels.fulfill_information)}</CardHeader>
                <CardBody className="pt-2">
                  <FulfilProductTypeForm validation={validation} data={dataLoadable?.contents || {}} id={params.id} />
                </CardBody>
              </Card>
              {/*<Card>*/}
              {/*  <CardHeader className="fw-400 fs-5 text-info">{t(Labels.deprecated_options)}</CardHeader>*/}
              {/*  <CardBody>*/}
              {/*    <DeprecatedOptionTypeForm validation={validation} data={dataLoadable?.contents || {}} id={params.id} />*/}
              {/*  </CardBody>*/}
              {/*</Card>*/}
            </FormikForm>
          </LoadableComponent>
        </SmallContainer>
      </div>
    </React.Fragment>
  );
};

// const DeprecatedOptionTypeForm = (props: any) => {
//   const { validation } = props
//   const [defaultAutoGenerateValues] = useState([{}])
//
//   const getDefaultVariantValue = () => {
//     let defaultOptionValues = validation.values.defaultOptionValues
//     defaultOptionValues = !validation.values.defaultOptionValues?.length ? defaultAutoGenerateValues : validation.values.defaultOptionValues
//     return defaultOptionValues
//   }
//
//   const getVariantValueMapper = () => {
//     let variantValueMapper = validation.values.variantValueMapper
//     variantValueMapper = !validation.values.variantValueMapper?.length ? defaultAutoGenerateValues : validation.values.variantValueMapper
//     return variantValueMapper
//   }
//
//   const handleRemoveVariantOptionMapper = (removeIndex: number) => {
//     validation.setFieldValue(
//       "variantValueMapper",
//       validation.values.variantValueMapper?.filter((val: any, index: number) => index !== removeIndex) || []
//     )
//   }
//
//   const addChangeVariantOptionValueMapper = () => {
//     let variantValueMapper = validation.values.variantValueMapper || []
//     let lastValue = variantValueMapper[variantValueMapper.length - 1]
//     if (!lastValue || !isEmpty(lastValue)) {
//       validation.setFieldValue("variantValueMapper", [...variantValueMapper, {}])
//     }
//   }
//
//   const onVariantOptionValueMappingChange = (value: OptionValueMapper, index: number) => {
//     let newValue = []
//     if (!validation.values.variantValueMapper?.length || index >= validation.values.variantValueMapper?.length) {
//       newValue = [value]
//     } else {
//       newValue = validation.values.variantValueMapper?.map((curVar: any, preIndex: number) => (preIndex == index ? value : curVar)) || [
//         value,
//       ]
//     }
//     validation.setFieldValue("variantValueMapper", newValue)
//   }
//
//   return (
//     <>
//       <Row className="mb-4 mt-4">
//         <Col>
//           <Label className="col-form-label">{t(Labels.variant_option_value_transform_table)}</Label>
//           <Row className="m-0">
//             <FormText className="p-0 mb-2">{t(Labels.variant_option_value_transform_desc)}</FormText>
//           </Row>
//         </Col>
//         <Col sm={9}>
//           {getVariantValueMapper()?.map((mapper: OptionValueMapper, index: any) => (
//             <VariantOptionValueMapping
//               key={`${index}${new Date().getTime()}`}
//               index={index}
//               validation={validation}
//               onChange={value => onVariantOptionValueMappingChange(value, index)}
//               handleRemove={() => handleRemoveVariantOptionMapper(index)}
//               optionMapper={mapper}
//             />
//           ))}
//           <Row>
//             <Col>
//               <Button color={"light"} onClick={addChangeVariantOptionValueMapper}>
//                 <i className="bx bx-plus font-size-16 align-middle"></i>
//                 {t(Labels.add_value_transform)}
//               </Button>
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//       <FulfillVariantMapping validation={validation} />
//     </>
//   )
// }

const ProductTypeVariantOption = (props: any) => {
  const { validation } = props;
  const channelOrderSetting = useRecoilValue(channelOrderSettingState);

  const addVariantOption = () => {
    let lastValue = validation.values.variantOptions && validation.values.variantOptions[validation.values.variantOptions.length - 1];
    if (!lastValue || !isEmpty(lastValue)) {
      validation.setFieldValue("variantOptions", [...(validation.values.variantOptions || []), {}]);
    }
  };

  const onDefaultOptionValuesChange = (value: DefaultOptionValue, index: number) => {
    let newValue = [];
    if (!validation.values.defaultOptionValues?.length || index >= validation.values.defaultOptionValues?.length) {
      newValue = [value];
    } else {
      newValue = validation.values.defaultOptionValues?.map((curVar: any, preIndex: number) => preIndex == index ? value : curVar) || [value];
    }
    validation.setFieldValue("defaultOptionValues", newValue);
  };


  const handleRemoveDefaultVariantOption = (removeIndex: number) => {
    validation.setFieldValue("defaultOptionValues", validation.values.defaultOptionValues?.filter((val: any, index: number) => index !== removeIndex) || []);
  };


  const addDefaultVariantValue = () => {
    let defaultOptionValues = validation.values.defaultOptionValues || [];
    let lastValue = defaultOptionValues[defaultOptionValues.length - 1];

    if (!lastValue || !isEmpty(lastValue)) {
      validation.setFieldValue("defaultOptionValues", [...defaultOptionValues, {}]);
    }
  };


  // const handleResetProductTypeValue = (value: any) => {
  //   return // Block to update deprecated fulfillVariantMapper option
  //   if (value.name !== validation.values?.productTypeKey) {
  //     validation.values?.fulfillVariantMapper &&
  //     validation.values?.fulfillVariantMapper?.map((item: any, index: number) => {
  //       validation.setFieldValue(`fulfillVariantMapper[${index}].productTypeValue`, "");
  //     });
  //   }
  // };

  return (
    <>
      <Row className="mb-4">
          {validation.values.variantOptions?.map((option: any, index: any) => {
            return <VariantOptionComponent variantOptionKeys={props.variantOptionKeys} key={`${option.name}_${option.optionKeys?.join(",")}`} option={option} index={index} validation={validation} fieldName="variantOptions" />
          })}
          <Row>
            <Col>
              <Button color={"light"} onClick={addVariantOption}>
                <i className="bx bx-plus font-size-16 align-middle mb-1"></i>
                {t(Labels.add_variant_option)}
              </Button>
            </Col>
          </Row>
      </Row>
      <hr />
      <Row className="mb-2">
        <Col>
          <Label className="col-sm-3 col-form-label">{t(Labels.product_type_option)}</Label>
        </Col>
        <FormikInput
          validation={validation}
          name="productTypeKey"
          customInput={({ handleChange, handleBlur, errorData }) => (
            <Select
              isSearchable={true}
              options={validation.values.variantOptions?.filter((option: any) => option?.name)}
              value={validation.values?.productTypeKey && { name: validation.values?.productTypeKey }}
              getOptionValue={(option: any) => option.name || ""}
              getOptionLabel={(option: any) => option.name || ""}
              maxMenuHeight={120}
              onBlur={handleBlur}
              placeholder={t(Labels.choose_product_type)}
              onChange={(value: any) => {
                // handleResetProductTypeValue(value)
                handleChange(value.name || value)
              }}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Row>
      {/* <Row className="mb-4">
          <Col>
            <Label className="col-form-label">{t("Giá trị mặc định variant option")}</Label>
            <Row className="m-0">
              <FormText className="p-0 mb-2">
                {t('Dùng cho plugin woofunnel')}
              </FormText>
            </Row>
          </Col>
          <Col sm={9}>
            {
              getDefaultVariantValue()?.map(
                (defaultValue:DefaultOptionValue,index:any) => 
                <DefaultVariantOptionValue
                  validation={validation} 
                  onChange={((value : any) => onDefaultOptionValuesChange(value,index))}
                  key={index + defaultValue.optionName || ""}
                  handleRemove={() => handleRemoveDefaultVariantOption(index)}
                  index={index}
                  defaultOptionValues={defaultValue}
                />
              )
            }

            <Row>
              <Col>
                <Button
                  color={'light'}
                  onClick={ addDefaultVariantValue }
                >
                  <i className="bx bx-plus font-size-16 align-middle"></i>
                  { t("Thêm giá trị mặc định") }
                </Button>
              </Col>
            </Row>
          </Col>
        </Row> */}
    </>
  )
};


export interface ProductTypeFormProps {
  data: ProductTypeDto;
  id: string | number;
  validation: FormValidation;
}

export const ProductTypeForm = (props: ProductTypeFormProps) => {
  const { validation } = props;
  const isEditing = +props.id > 0;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    const edittorToHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    validation.setFieldValue("description", edittorToHtml);
  };
  useEffect(() => {
    const htmlDescription = props.data?.description;
    if (htmlDescription) {
      const contentBlock = htmlToDraft(htmlDescription);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState1 = EditorState.createWithContent(contentState);

        setEditorState(editorState1);
      }
    }
  }, [props.data.description]);

  const optionProductionTime = [
    {
      label: t(Labels.unknown),
      value: "Unknown"
    },
    {
      label: t(Labels.normal_production),
      value: "Normal Production"
    },
    {
      label: t(Labels.fast_production),
      value: "Fast Production"
    }
  ];

  return (
    <>
      <Row className="mb-2">
        <Col sm={6}>
          <FormikInput
            name="code"
            label={t(Labels.product_type_code)}
            type="text"
            validation={validation}
            placeholder={t(Labels.product_type_code_hint)}
          />
        </Col>
        <Col sm={6}>
          <FormikInput
            name="name"
            label={t(Labels.product_type_name)}
            type="text"
            validation={validation}
            placeholder={t(Labels.product_type_name_hint)}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm={6}>
          <FormikInput
            label={t(Labels.regular_price)}
            name="regularPrice"
            type="number"
            validation={validation}
            placeholder={t(Labels.product_type_regular_price_hint)}
          />
        </Col>
        <Col sm={6}>
          <FormikInput
            label={t(Labels.sale_price)}
            name="salePrice"
            type="number"
            validation={validation}
            placeholder={t(Labels.product_type_sale_price_hint)}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        {/* <Label className="col-sm-3 col-form-label">{t("Group")}</Label> */}
        <Col sm={6}>
          <FormikInput
            label={t(Labels.group)}
            name="group"
            type="text"
            validation={validation}
            placeholder={t(Labels.product_type_group_hint)}
          />
        </Col>
        <Col sm={6}>
          <FormikInput
            label={t(Labels.estimate_product_time)}
            name="productionTime"
            type="text"
            validation={validation}
            placeholder={t(Labels.estimate_production_time_hint)}
            customInput={({ handleBlur, handleChange, errorData }) => (
              <Select
                menuPortalTarget={document.body}
                placeholder={`${t(Labels.estimate_production_time_hint)}...`}
                options={optionProductionTime}
                getOptionValue={(option: any) => option.value || ""}
                getOptionLabel={(option: any) => option.label || ""}
                value={optionProductionTime.find(option => option.value === validation.values.productionTime)}
                onBlur={handleBlur}
                onChange={(option: any) => {
                  validation.setFieldValue("productionTime", option.value);
                }}
                styles={errorData.reactSelectErrorStyle}
              />
            )}
          />
        </Col>
      </Row>

      <Row className="mb-2">
        <LabelTooltip tooltip={t(Messages.default_product_type_image_create_instructions)}><Label>{t(Labels.image_suffixes)}</Label></LabelTooltip>
        <FormikInput
          // label={t(Labels.image_suffixes)}
          optional
          name="imageSuffixes"
          type="text"
          validation={validation}
          placeholder={t(Labels.product_type_image_suffixes_hint)}
          customInput={({ handleBlur, handleChange, errorData }) => (
            <CreatableMultiValues
              placeholder={t(Labels.product_type_image_suffixes_hint)}
              value={validation.values.imageSuffixes?.map((value: string) => ({ value, label: value }))}
              options={validation.values.imageSuffixes?.map((item: string) => ({ value: item, label: item })) || []}
              onChange={(values: any) => {
                let valuesConverted = Forms.handleMultiValue(values).map((item: any) => item.value)
                validation.setFieldValue('imageSuffixes', valuesConverted);
              }}
            />
          )}
        />
      </Row>


      <Row className="mb-2">
        {/* <Label className="col-sm-3 col-form-label">{t("Default Image Suffixes")}</Label> */}
        <Col>
          <FormikInput
            label={t(Labels.description)}
            name="description"
            type="text"
            validation={validation}
            placeholder={t(Labels.product_type_desc_hint)}
            customInput={({ handleBlur, handleChange, errorData  }) => {
              return <Editor
                editorState={editorState}
                editorClassName="editorClassName"
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                onEditorStateChange={onEditorStateChange}
              />;
            }}
          />
        </Col>
      </Row>
    </>
  );
};


interface DefaultOptionValue {
  optionName?: string;
  value?: string;
}

export interface VariantOptionValueMappingProps {
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  optionMapper: OptionValueMapper;
  onChange: (value: OptionValueMapper) => void;
  handleRemove: () => void;
  index: number;
}

export interface DefaultVariantOptionValueProps {
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  defaultOptionValues: DefaultOptionValue;
  onChange: (value: DefaultOptionValue) => void;
  handleRemove: () => void;
  index: number;
}

const DefaultVariantOptionValue = (props: DefaultVariantOptionValueProps) => {
  const { validation } = props;
  const [defaultValues, setDefaultValues] = useState(props.defaultOptionValues);

  useEffect(() => setDefaultValues(props.defaultOptionValues), [props.defaultOptionValues]);
  useEffect(() => props.onChange(defaultValues), [defaultValues]);

  return (
    <Row>
      <Col xs={12} lg={3} className="p-0">
        <FormikInput
          name="defaultOptionValues"
          label={t(Labels.default_option_values)}
          style={{ height: 38 }}
          validation={validation}
          customInput={({ handleBlur, handleChange, errorData }) => (
            <Select
              placeholder={`${t(Labels.fulfill_option_hint)}...`}
              options={validation?.values?.variantOptions?.filter((option: any) => option.name != validation.values?.productTypeKey)}
              getOptionValue={(option: any) => option.value}
              getOptionLabel={(option: any) => option.name}
              onBlur={handleBlur}
              value={defaultValues?.optionName && typeof defaultValues?.optionName === "string" ? { name: defaultValues.optionName } : undefined}
              onChange={(value: any) => setDefaultValues((prev) => {
                return { ...prev, optionName: value.name };
              })}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>

      <Col>
        <Row className="p-0">
          <Col className="p-0 ps-1">
            <FormikInput
              name="value"
              label={t(Labels.default_price)}
              style={{ height: 38 }}
              placeholder={t(Labels.default_price_hint)}
              validation={validation}
              value={defaultValues.value}
              onChange={(value: string) => setDefaultValues((prev) => {
                return { ...prev, value: value };
              })}
            />
          </Col>
          <Col xs="auto" className={`p-0 mt-4 pt-1`}>
            <Button
              className="ms-1"
              color={"danger"}
              onClick={() => props.handleRemove()}
            > {"x"}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
const VariantOptionValueMapping = (props: VariantOptionValueMappingProps) => {
  const { validation, index } = props;
  const firstUpdate = useRef(true);
  const [optionMapper, setOptionMapper] = useState(props.optionMapper);
  const getStoreOptionValues = () => validation.values.variantOptions?.filter((option: any) => option.name == optionMapper?.optionName)?.pop()?.optionValues;
  // const getFulfillOptionValues = () => validation.values.fulfillProduct?.variantOptions.filter((option: any) => option.name == optionMapper?.optionName)?.pop()?.value;
  const getFulfillOptionValues = () => validation.values.fulfillProduct?.variantOptions.filter((option: any) => option.name !== validation.values.fulfillProduct?.productTypeKey).map((item: any) => item.value).flat()


  useEffect(() => {
    if (!firstUpdate.current) {
      props.onChange(optionMapper)
    }
    firstUpdate.current = false;
  }, [optionMapper]);
  useEffect(() => setOptionMapper(props.optionMapper), [props.optionMapper]);

  return (
    <Row>
      <Col xs={12} lg={3} className="pe-0 mb-2">
        <FormikInput
          name="fulfillVariantOption"
          label={index == 0 ? t(Labels.fulfill_option) : ""}
          style={{ height: 38 }}
          validation={validation}
          customInput={({ handleBlur, handleChange, errorData }) => (
            <Select
              menuPortalTarget={document.body}
              placeholder={`${t(Labels.select)}...`}
              options={validation?.values?.variantOptions?.filter((option: any) => option.name != validation.values?.productTypeKey)}
              getOptionValue={(option: any) => option.value}
              getOptionLabel={(option: any) => option.name}
              value={optionMapper?.optionName ? { name: optionMapper.optionName } : undefined}
              onBlur={handleBlur}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              onChange={(value: any) => {
                setOptionMapper((prev) => ({ ...prev, optionName: value.name }));
              }}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>
      <Col xs={12} lg={5} className={`p-0 px-1 ${index != 0 ? 'mb-3' : "mb-2"}`}>
        <FormikInput
          name="fulfillVariantOption"
          label={index == 0 ? t(Labels.value_by_store) : ""}
          style={{ height: 38 }}
          validation={validation}
          customInput={({ handleBlur, handleChange, errorData }) => (
            <Select
              menuPortalTarget={document.body}
              isMulti={true}
              placeholder={`${t(Labels.option_value_hint)}...`}
              options={getStoreOptionValues()?.map((name: any) => ({ name })) || []}
              getOptionValue={(option: any) => option.name}
              getOptionLabel={(option: any) => option.name}
              value={optionMapper?.from?.length ? optionMapper?.from.map((name: string) => ({ name })) : undefined}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              onBlur={handleBlur}
              onChange={(values: any) => {
                let newValues = values.map((value: any) => value.name);
                setOptionMapper((prev) => ({ ...prev, from: newValues }));
              }}
              styles={errorData.reactSelectErrorStyle}
            />
          )}
        />
      </Col>
      <Col className="sdfsd mb-2">
        <Row className="me-0">
          <Col className="p-0">
            <FormikInput
              name="fulfillVariantOption"
              label={index == 0 ? t(Labels.fulfill_value) : ""}
              style={{ height: 38 }}
              validation={validation}
              customInput={({ handleBlur, handleChange, errorData }) => (
                <Select
                  // xuanduc edit
                  menuPortalTarget={document.body}
                  options={getFulfillOptionValues()?.map((name: any) => ({ name })) || []}
                  getOptionValue={(option: any) => option.name}
                  getOptionLabel={(option: any) => option.name}
                  value={optionMapper?.to ? { name: optionMapper.to } : undefined}
                  onBlur={handleBlur}
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  onChange={(value: any) => {
                    setOptionMapper((prev) => ({ ...prev, to: value.name }));

                  }}
                  styles={errorData.reactSelectErrorStyle}
                />
              )}
            />
          </Col>
          <Col xs="auto" className={`p-0 ${index == 0 ? "mt-4 pt-1" : ""}`}>
            <Button
              className="ms-1"
              color={"danger"}
              onClick={() => props.handleRemove()}
            > {"x"}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export interface VariantOptionComponentProps {
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  option: ProductTypeOption;
  fieldName: string;
  index: number;
  variantOptionKeys: any
}

const VariantOptionComponent = (props: VariantOptionComponentProps) => {
  let { validation, option, variantOptionKeys, index } = props;
  const channelOrderSetting = useRecoilValue(channelOrderSettingState);
  const [optionName, setOptionName] = useState(option?.name)

  const getVariantOptions = () => {
    return validation.values.variantOptions?.length ? validation.values.variantOptions : [{}];
  };

  const handleAddVariantOption = () => {
    validation.setFieldValue("variantOptions", [...getVariantOptions(), {}]);
  };

  return (
    <Card>
      <CardHeader className="p-0">
        <CardTitle className="d-flex align-items-center justify-content-between">
          {t(`${Labels.variant} ${props.index + 1}`)}
          <a
            className="ms-1"
            color={"danger"}
            onClick={() => {
              let newValues = [...getVariantOptions()]
              newValues.splice(props.index, 1)
              validation.setFieldValue("variantOptions", newValues)
            }}
          >
            <i className="mdi mdi-delete font-size-18 " style={{ color: "red" }} />
          </a>
        </CardTitle>
      </CardHeader>
      <CardBody className="ps-0 pe-0">
        <Row>
          <Col className="mb-2" md={4}>
            <FormikInputVariant
              name={`variantOptions`}
              label={t(Labels.option_name)}
              style={{ height: 38 }}
              placeholder={t(Labels.variant_option_hint)}
              validation={validation}
              index={index}
              onChange={(value = "") => setOptionName(value)}
              value={optionName || ""}
              onBlur={(e: any) => {
                validation?.setFieldTouched('variantOptions', true);
                const newOptions =
                  getVariantOptions().map((option: any, idx: any) => {
                    if (idx === props.index) {
                      return { ...option, name: e.target.value }
                    }
                    return option
                  }) || []
                validation.setFieldValue("variantOptions", newOptions)
              }}
            />
          </Col>
          <Col className="ps-0" md={8}>
            <FormikInput
              validation={validation}
              name="variantOptionsValue"
              label={t(Labels.variant_option_values)}
              customInput={({ handleBlur, handleChange, errorData }) => (
                <Creatable
                  isMulti={true}
                  formatCreateLabel={value => `${t(Labels.create)} "${value}"`}
                  placeholder={t(Labels.variant_option_values_hint)}
                  value={props.option?.optionValues?.map((value: string) => ({ value }))}
                  getOptionValue={(option: any) => option.value || option}
                  getOptionLabel={(option: any) => option.label || option.value || option}
                  onBlur={handleBlur}
                  onChange={(values: MultiValue<any>) => {
                    let newValues = [...getVariantOptions()]
                    let option = newValues[props.index]
                    if (option) {
                      newValues[props.index] = {
                        ...option,
                        optionValues: values.map(value => value.value || value),
                      }
                    }
                    validation.setFieldValue("variantOptions", newValues)
                    // handleChange(newValues)
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={errorData.reactSelectErrorStyle}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-1" md={4}>
            <FormikInput
              name="defaultOptionValues"
              label={t(Labels.woofunnels_default_value)}
              style={{ height: 38 }}
              validation={validation}
              customInput={({ handleChange, handleBlur, errorData }) => {
                let findVariantOption = validation.values.variantOptions.find((item: any) => item.name === option.name)
                let optionSelect = findVariantOption.optionValues?.map((item: string) => ({
                  optionName: option.name,
                  value: item,
                }))
                return (
                  <Select
                    value={validation.values.defaultOptionValues?.find((item: any) => item.optionName === props.option.name)?.value}
                    options={optionSelect}
                    getOptionLabel={(option: any) => option.value}
                    getOptionValue={(option: any) => option.value}
                    placeholder={t(Labels.woofunnels_default_value_hit)}
                    onChange={(value: any) => {
                      let findOption = validation.values.defaultOptionValues?.find((item: any) => item.optionName === props.option.name)

                      if (!findOption) {
                        validation.setFieldValue("defaultOptionValues", [
                          ...(validation.values?.defaultOptionValues || []),
                          {
                            value,
                            optionName: props.option.name,
                          },
                        ])
                      } else {
                        let newDefaultOption = [...validation.values?.defaultOptionValues]
                        newDefaultOption = newDefaultOption.map((item: any) => {
                          if (item.optionName === props.option.name) {
                            return { ...item, value }
                          }
                          return item
                        })
                        validation.setFieldValue("defaultOptionValues", newDefaultOption)
                      }
                    }}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )
              }}
            />
          </Col>
          <Col className="mt-1 ps-0" md={8}>
            <FormikInput
              validation={validation}
              name="variantOptionsKey"
              label={t(Labels.variant_option_keys)}
              customInput={({ handleBlur, handleChange, errorData }) => (
                <Creatable
                  isMulti={true}
                  formatCreateLabel={value => `${t(Labels.create)} "${value}"`}
                  placeholder={t(Labels.variant_option_keys_hint)}
                  value={props.option?.optionKeys?.map((value: string) => ({ value }))}
                  options={variantOptionKeys?.map((value: string) => ({ value }))}
                  getOptionValue={(option: any) => option.value || option}
                  getOptionLabel={(option: any) => option.label || option.value || option}
                  onBlur={props.validation.handleBlur}
                  onChange={(values: MultiValue<any>) => {
                    let newValues = [...getVariantOptions()]
                    let option = newValues[props.index]
                    if (option) {
                      newValues[props.index] = {
                        ...option,
                        optionKeys: values.map(value => value.value || value),
                      }
                    }
                    validation.setFieldValue("variantOptions", newValues)
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={errorData.reactSelectErrorStyle}
                />
              )}
            />
          </Col>
        </Row>
        {/* <div className="dropdown-divider mt-3 mb-3"></div> */}
      </CardBody>
    </Card>
  )
};


const FulfilProductTypeForm = (props: ProductTypeFormProps) => {
  const { validation } = props;
  const [defaultAutoGenerateValues] = useState([{}]);
  // const fulfillproductLoadable = withPermission(ActionEntities.read, ResourceEntities.fulfillVariantEntity)
  //   ? useRecoilValueLoadable(fulfillVariantByFulfillProductIdSelector(validation.values?.fulfillProduct?.id))
  //   : null;

  let columns = [
    {
      dataField: "sku",
      text: t(Labels.sku)
    },
    {
      dataField: "variantTitle",
      text: t(Labels.variant_title)
    },
    {
      dataField: "view",
      text: t(Labels.view),
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return <>
          <Link style={{ fontWeight: 500 }} target="_blank"
                to={{ pathname: createUrlEmbed(`${RouteNames.FULFILL_VARIANTS}/${row.id}`) }}><BiLinkExternal size="16" /></Link>
        </>;
      }
    }
  ];
  
  
  const refIdFulfillProduct = useRef<any>(false)

  return (
    <>
      <Row className="mb-2">
        <Col xs={"auto"} className="pe-0">
          <Label className="col-form-label">{t(Labels.fulfillable_Products)}</Label>
        </Col>
        <FormikInput
          validation={validation}
          name="fulfillableProducts"
          customInput={({ handleChange, handleBlur, errorData }) => {
            const values = validation.values.fulfillableProducts?.map((fulfillableProduct: any) => {
              if (!fulfillableProduct.fulfillService) {
                const fulfillService = (validation.values?.fulfillableServices || [validation.values.fulfillService]).find(
                  (item: any) => item && item.id === fulfillableProduct.fulfillServiceId
                )
                return { ...fulfillableProduct, fulfillService }
              }
              return { ...fulfillableProduct }
            })
            return (
              <FulfillProductSelect
                validation={validation}
                isMulti={true}
                // value={{ fulfillService: validation.values.fulfillService, ...(validation.values.fulfillProduct || {}) }}
                value={values || []}
                onChange={(value: any) => {
                  handleChange(value)
                  const findFulfillProduct = value?.find((item: any) => item.id === validation.values.fulfillProduct?.id)
                  if (!findFulfillProduct && validation.values.fulfillProduct) {
                    validation.setFieldValue("fulfillProduct", undefined)
                  }
                }}
                onBlur={handleBlur}
                styles={errorData.reactSelectErrorStyle}
              />
            )
          }}
        />
      </Row>
      <Row className="mb-2">
        <Col xs={"auto"} className="pe-0">
          <Label className="col-form-label">{t(Labels.default_fulfill_product)}</Label>
        </Col>
        <Col className="p-0 d-flex align-items-center">
          <Link
            target="_blank"
            to={createLinkEmbedParam(validation.values.fulfillProduct?.id ? `${RouteNames.FULFILL_PRODUCTS}/${validation.values.fulfillProduct?.id}` : "").to}
            className="ps-1"
          >
            <span> </span>
            <BiLinkExternal size="18" />
          </Link>
        </Col>
        <FormikInput
          validation={validation}
          name="fulfillProduct"
          customInput={({ handleChange, handleBlur, errorData }) => {
            const values = validation.values.fulfillProduct && {
              ...validation.values.fulfillProduct,
              fulfillService:
                validation.values.fulfillProduct?.fulfillService ||
                validation.values.fulfillableServices?.find(
                  (value: any) => value.id === validation.values.fulfillProduct?.fulfillServiceId
                ) ||
                validation.values.fulfillService,
            }
            const options = validation.values.fulfillableProducts?.map((fulfillableProduct: any) => {
              if (!fulfillableProduct.fulfillService) {
                const fulfillService = (validation.values?.fulfillableServices || [validation.values.fulfillService]).find(
                  (item: any) => item && item?.id === fulfillableProduct.fulfillServiceId
                )
                return { ...fulfillableProduct, fulfillService }
              }
              return { ...fulfillableProduct }
            })

            return (
              <Select
                options={options}
                value={values || null}
                onChange={(value: any) => {
                  handleChange(value)
                  refIdFulfillProduct.current = true
                }}
                getOptionValue={(option: FulfillProductType) => option?.id}
                getOptionLabel={(option: FulfillProductType) =>
                  t(option?.name && `${option?.name} (${option.fulfillService?.name || "Unknown"})`)
                }
                styles={errorData.reactSelectErrorStyle}
              />
            )
          }}
        />
      </Row>

      {/* <div className="d-flex" style={{ overflowX: "auto" }}> */}
      <Col>
        <FulfillOptionForm isCreateFulfillOption={refIdFulfillProduct.current} validation={validation} />
      </Col>
      {/* </div> */}
     
     

      {/*<Row className="mb-4">*/}
      {/*  <Col md={3}><Label className="col-form-label">{t(Labels.fulfill_variants)}</Label></Col>*/}
      {/*  <Col>*/}
      {/*    <Row>*/}
      {/*      <RemoteTable*/}
      {/*        dataLoadable={[fulfillproductLoadable as Loadable<any>]}*/}
      {/*        keyField={"id"}*/}
      {/*        renderInCard={false}*/}
      {/*        simple={true}*/}
      {/*        tableClasses="react-bootstrap-table-small"*/}
      {/*        columns={columns}*/}
      {/*      />*/}
      {/*    </Row>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </>
  )
};


// export const FulfillVariantMapping = (props: any) => {
//   const { validation } = props;
//   // const
//
//   const createVariantsMapper = () => {
//     let current = validation.values.fulfillVariantMapper;
//     let variantMapper = !current ? [] : [...current];
//     if (!variantMapper?.length || !isEmpty(variantMapper[variantMapper.length - 1])) {
//       variantMapper.push({} as any);
//     }
//     validation.setFieldValue("fulfillVariantMapper", variantMapper);
//   };
//   const [variantMapper, setVariantMapper] = useState([]);
//
//   useEffect(() => setVariantMapper(validation.values.fulfillVariantMapper || [{}]), [validation.values.fulfillVariantMapper]);
//
//   const createNewVariantMapper = (prev: any, newVariantMapper: any, newIndex: number | undefined) => {
//     let newVariantMappers = [];
//     if (Array.isArray(newVariantMapper)) {
//       newVariantMappers = newVariantMapper;
//     }
//     if (!newVariantMapper) {
//       newVariantMappers = prev.map((item: any) => item);
//     } else if (newIndex && newIndex >= prev.length) {
//       let newVariantMappers = prev.map((item: any) => item);
//       newVariantMappers.push(newVariantMapper);
//     } else {
//       newVariantMappers = prev?.map((item: any, preIndex: number) => {
//         return preIndex == newIndex ? {
//           ...item,
//           ...newVariantMapper
//         } : item;
//       });
//     }
//     return newVariantMappers;
//   };
//
//   const onVariantMapperChange = (newVariantMapper: any, variantMapperIndex: number | undefined, submitChange = true) => {
//     setVariantMapper(newVariantMapper);
//     if (submitChange) {
//       handleInputBlur(createNewVariantMapper(variantMapper, newVariantMapper, variantMapperIndex));
//     }
//   };
//
//   const handleInputBlur = (value = variantMapper) => {
//     setVariantMapper(value);
//     validation.setFieldValue("fulfillVariantMapper", value);
//   };
//
//   const createUpdateVaraintMapper = (value: any, index: number) => {
//     let current = validation.values.fulfillVariantMapper && validation.values.fulfillVariantMapper[index] || {};
//     let newVariantMapper = {
//       ...current,
//       ...(value || {})
//     };
//     return newVariantMapper;
//   };
//
//   const removeOption = (indexVariant: number) => {
//     let tempFulfillVariantMapper = validation.values.fulfillVariantMapper.filter((item: any, index: number) => index !== indexVariant);
//
//     tempFulfillVariantMapper = isEmpty(tempFulfillVariantMapper) ? [{}] : tempFulfillVariantMapper;
//
//     validation.setFieldValue("fulfillVariantMapper", tempFulfillVariantMapper);
//   };
//
//   const handleShowValue = (id: number) => {
//     let data = (withPermission(ActionEntities.read, ResourceEntities.fulfillVariantEntity)
//       ? useRecoilValueLoadable(fullfillVariantListSelector(id)) : null) as Loadable<any>;
//
//     if (data?.state === "hasValue") {
//       return data?.contents;
//     }
//   };
//
//   return (
//     <Fragment>
//       <Row className="mb-4">
//         <Col sm={3}><Label className="col-form-label">{t(Labels.fulfill_variant_mapping)}</Label></Col>
//         <Col sm={9}>
//           {/* <Row>
//               <Col><Label className="col-form-label">{t(`${validation.values?.productTypeKey}`)}</Label></Col>
//               <Col md={8}><Label className="col-form-label">{t("Fulfillment Variant")}</Label></Col>
//               <Col md={"auto"} className={`d-flex align-items-center`}>
//                 <div className={`text-center invisible`}>
//                   <Link
//                     to={"#"}
//                   >
//                     <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} />
//                   </Link>
//                 </div>
//               </Col>
//             </Row> */}
//           {variantMapper?.map((variantMapperItem: FulfillVariantMapper, variantMapperIndex: number) => (
//              <Row key={`${variantMapperIndex} + ${variantMapperItem.fulfillVariantId}`} className="mb-2 m-0">
//               <Col className="p-0 col-lg-4 col-sm-12">
//
//                 <FormikInput
//                   label={variantMapperIndex == 0 ? (validation.values?.productTypeKey ? t(`${validation.values?.productTypeKey}`) : t(Labels.product_type_option)) : ""}
//                   name={"productTypeValue"}
//                   validation={validation}
//                   customInput={({ handleChange, handleBlur, errorData }) => {
//                     const value = variantMapperItem.productTypeValue ? {
//                       name: variantMapperItem.productTypeValue,
//                       value: variantMapperItem.productTypeValue
//                     } : undefined;
//                     const option = validation.values.variantOptions?.find((variant: any) => variant.name === validation.values.productTypeKey);
//                     return <Select
//                       isSearchable={true}
//                       menuPortalTarget={document.body}
//                       options={Forms.createSelectOptions(option?.optionValues as string[] || [])}
//                       value={value}
//                       getOptionLabel={(option: any) => option?.value}
//                       getOptionValue={(option: any) => option?.value}
//                       onBlur={handleBlur}
//                       onChange={(value) => onVariantMapperChange(
//                         createUpdateVaraintMapper({ productTypeValue: value?.value }, variantMapperIndex), variantMapperIndex
//                       )}
//                       styles={errorData.reactSelectErrorStyle}
//                     />;
//                   }}
//                 />
//               </Col>
//               <Col className="p-0 ps-1 col-lg-8 col-sm-12">
//                 <Row className="m-0">
//                   <Col xs={11} className="p-0">
//                     <FormikInput
//                       name={"fulfillVariant"}
//                       label={variantMapperIndex == 0 ? t(Labels.fulfill_variants) : ""}
//                       validation={validation}
//                       customInput={({ handleChange, handleBlur, errorData }) => (
//                         <FulfillVariantSelect
//                           value={handleShowValue(variantMapperItem?.fulfillVariantId)}
//                           onChange={(value) => onVariantMapperChange(
//                             createUpdateVaraintMapper({ fulfillVariantId: value.id }, variantMapperIndex), variantMapperIndex
//                           )}
//                           onBlur={handleBlur}
//                           styles={errorData.reactSelectErrorStyle}
//                         />
//                       )}
//                     />
//                   </Col>
//                   <Col xs={1} className={`p-0 ${variantMapperIndex == 0 ? "mt-4 pt-1" : ""}`}>
//                     <Button
//                       className="ms-1"
//                       color={"danger"}
//                       onClick={() => removeOption(variantMapperIndex)}
//                     > {"x"}
//                     </Button>
//                   </Col>
//                 </Row>
//               </Col>
//             </Row>
//           ))}
//           <Row>
//             <Col>
//               <Button
//                 color={"light"}
//                 onClick={createVariantsMapper}
//               >
//                 <i className="bx bx-plus font-size-16 align-middle"></i>
//                 {t(Labels.add_fulfill_variant_mapping)}
//               </Button>
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </Fragment>
//   );
// };


