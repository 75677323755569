import React, { Fragment, useEffect, useRef, useState } from "react"
import { Badge } from "reactstrap"
import Select, { ActionMeta } from "react-select"
import { FormValidation } from "../form/FormikForm"
import { createCustomSelectStyles } from "../bulk/BulkEditor"
import { t } from "core/translations"

export interface BadgeStatusProps {
  status: any
  color: Record<string, string>
  name: Record<string, string>
}

export const BadgeStatus = (props: BadgeStatusProps) => {
  let color = props.color[props.status] || "light"
  return (
    <Fragment>
      <Badge color={color} pill>
        {props.name[props.status] || props.status}
      </Badge>
    </Fragment>
  )
}

export interface StatusSelectProps {
  name: string
  placeholder: string
  height?: number
  value?: any
  autoFocus?: boolean
  defaultMenuIsOpen?: boolean
  statusValues: Array<string>
  statusNames: Record<string, string>
  onBlur?: any
  onChange: (value: string, meta?: ActionMeta<any>) => void
  validation?: FormValidation
  styles?: any
}

export const StatusSelect = (props: StatusSelectProps) => {
  const selectRef = useRef<any>()
  const [options, setOptions] = useState<any>([])
  useEffect(() => {
    let newOptions = props.statusValues.map(status => ({
      label: props.statusNames[status] || status,
      value: status,
    })) as any[]
    setOptions(newOptions)
  }, [props.statusValues])

  return (
    <div style={{ zIndex: Number.MAX_SAFE_INTEGER }}>
      <Select
        ref={selectRef}
        name={props.name}
        isSearchable={false}
        menuPortalTarget={document.body}
        placeholder={props.placeholder}
        onMenuClose={() => selectRef.current.blur()}
        getOptionLabel={(option: any) => t(option.label)}
        getOptionValue={(option: any) => option.value}
        value={options
          .filter((option: any) => option.value == props.value)
          .pop()}
        autoFocus={props.autoFocus}
        defaultMenuIsOpen={props.defaultMenuIsOpen}
        options={options}
        onBlur={props.onBlur}
        onChange={(value: any, meta: any) =>
          props.onChange(value.value, meta)
        }
        styles={{
          ...createCustomSelectStyles(props.height),
          ...props?.styles
        }}
      />
    </div>
  )
}

// TODO fix z-index filfullment > StatusSelect
