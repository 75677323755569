
export enum ChannelSyncStatus {
    SUCCESS = 'success',
    SYNCING = 'syncing',
    ERROR = 'error',
}

export const ChannelSyncStatusValues: ChannelSyncStatus[] = [
    ChannelSyncStatus.SUCCESS,
    ChannelSyncStatus.SYNCING,
    ChannelSyncStatus.ERROR
]

export const ChannelSyncStatusName: Record<string, string> = {
    [ChannelSyncStatus.SUCCESS]: 'success',
    [ChannelSyncStatus.SYNCING]: 'syncing',
    [ChannelSyncStatus.ERROR]: 'error'
}

export const ChannelSyncStatusColor: Record<string, string> = {
    [ChannelSyncStatus.SUCCESS]: 'success',
    [ChannelSyncStatus.SYNCING]: 'primary',
    [ChannelSyncStatus.ERROR]: 'danger'
}