import React, { useState } from "react";
import Select from "react-select";
import { ModalButtons, ModalContent } from "./portable-modal";
import { SelectOption } from "../../common/types";
import { t } from "core/translations";
import { FormGroup, Input, Label } from "reactstrap";
import { Labels } from "../../common/labels";
import ActionButton from "../input/ActionButton";
import { WithPermission } from "../common/WithPermission";
import { ResourceEntities } from "../../types/permission-type";
import { SetterOrUpdater } from "recoil";
import { PortableModalStateProps } from "../../types/modal.type";

export interface BulkUpdateSelectPopupProps {
  options?: Record<string, string> | SelectOption[]
  selectBuilder?: (onChange: (option: any) => void) => any
  placeholder?: string
  onSubmit: Function
  onDone?: Function
  confirmText?: string
}
export const BulkUpdateSelectPopup = ({ options = [], placeholder, onSubmit, onDone, selectBuilder, confirmText }: BulkUpdateSelectPopupProps) => {
  const [value, setValue] = useState<any>(undefined);
  options = Array.isArray(options) ? options : Object.entries(options).map(([key, value]) => ({ label: value, value: key}))

  return (
    <>
      <ModalContent>
        {
          selectBuilder ? selectBuilder((option) => setValue(option)) : (
            <Select
              placeholder={placeholder}
              name="option_value"
              getOptionLabel={option => t(option.label)}
              getOptionValue={option => option.value}
              value={value}
              options={options}
              onChange={(option) => setValue(option)}
            />
          )
        }
      </ModalContent>
      <ModalButtons
        confirmText={confirmText}
        confirm={ async () => {
          if(value != null && value != undefined) {
            await onSubmit(value.value == null || value.value == undefined ? value : value.value)
            onDone && onDone()
          }
        }}
      />
    </>
  )
}

export interface BulkUpdateWithReplaceConfirmationPopupProps {
  confirmMessage?: string,
  noneReplaceLabel?: string,
  replaceLabel?: string,
  onSubmit: Function
  onDone?: Function
}
export const BulkUpdateWithReplaceConfirmationPopup = (props: BulkUpdateWithReplaceConfirmationPopupProps) => {
  const [value, setValue] = useState<any>(undefined);
  const { replaceLabel, confirmMessage, noneReplaceLabel, onSubmit } = props

  const handleSubmit = async (replace: boolean, onDone?: Function) => {
    await onSubmit(replace)
    onDone && onDone()
    props.onDone && props.onDone()
  }

  return (
    <>
      <ModalContent>
        {
          confirmMessage ? (
            <div className="text-center">{ confirmMessage }</div>
          )
          :
          null
        }
      </ModalContent>
      <ModalButtons
        confirmText={noneReplaceLabel}
        confirm={ async () => handleSubmit(false) }
        customButtons={(setModal: SetterOrUpdater<PortableModalStateProps>) => (
          <ActionButton
            type="button"
            color='primary'
            onClick={async () => handleSubmit(true, () => setModal({ open: false }))}
          >
            { replaceLabel }
          </ActionButton>
        )}
      >

      </ModalButtons>
    </>
  )
}
