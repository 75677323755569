import { Labels } from 'common/labels';
import { Option } from "../common/types"
import { Logger } from "../core/logger"
import { isObject, isString } from "lodash";
import _ from "lodash"
import { t } from 'core/translations';

export class Forms {
  static getFormikErrorMessages(errors: any): any {
    if (!errors) return []

    return Object.entries(errors)
    .map(([name, message]) => {
      let newMessage = message
      if(Array.isArray(newMessage)){
        newMessage = [...new Set(newMessage)] 
      }
      return Forms.getFormikErrorMessage(newMessage, name)
      })
      .filter(msg => msg)
  }
  static getFormikErrorMessage(errors: any, name: string): any {
    if (!errors || isString(errors)) return errors

    if (Array.isArray(errors)) {
      errors = errors.filter((item) => item)
      let error = errors[0]

      if(typeof error === 'string'){
        return error && errors.map((value:any) => this.getFormikErrorMessage(value, name))
      }
      return (
        error && this.getFormikErrorMessage(Object.values(errors[0])[0], name)
      )
      // for (let error of errors) {
      //   const msg: any = this.getFormikErrorMessage(error, name);
      //   if (msg) return msg;
      // }
    } else if (_.isPlainObject(errors)) {
      return Object.entries(errors)
        .map(([name, message]) => Forms.getFormikErrorMessage(message, name))
        .filter(msg => msg)
    } else {
      return this.getFormikErrorMessage((errors as any)[name], name)
    }
  }

  /**
   * @param values
   * @param initialValues
   * @param nestedArrayKeys: Check change for nested object of array
   */
  static getChangedValues(values: any, initialValues: any, nestedArrayKeys?: string[]) {
    let changedValue =  Object
      .entries(values)
      .reduce((result: any, [key, value]) => {
        let initialValue = initialValues[key]
        if(!key && !initialValue) return result

        if(Array.isArray(value) && nestedArrayKeys?.includes(key)) {
          let changedItems = []
          for (let i = 0; i < value.length; i++) {
            if(initialValue[i] !== value[i]) {
              changedItems.push(value[i])
            }
          }
          if(changedItems.length > 0) {
            result[key] = changedItems
          }
        } else {
          let hasChanged = false
          if(isObject(value)) {
            hasChanged = JSON.stringify(value) != JSON.stringify(initialValue)
          } else {
            hasChanged = initialValue !== value
          }

          if(hasChanged) {
            result[key] = value
          }
        }

        return result
      }, {})

    return changedValue
  }

  static createSelectOptions(values: string[]) {
    return values?.map(value => ({ value, name: value })) || ([] as Option[])
  }

  static createSelectOptionValue(value: string, selectValues: string[]) {
    return selectValues?.includes(value) ? { value, name: value } : undefined
  }

  static handleMultiValue = (values: Array<any>) => {
    let tempValues = values
    if (tempValues.length > 0) { 
      let labelLastElement = values[values.length - 1]?.label || values[values.length - 1]?.value || values[values.length - 1]
      if( labelLastElement?.indexOf(',') != -1 ){
        let tempArr = labelLastElement.split(',').map((item: string) => item.trim());
        tempValues.pop()
        tempArr.forEach((item: string) => {
          tempValues = tempValues.concat({value: item})
        });
      }
    }
    return tempValues
  }

  static createLabelMultiValues = (value: string) => {
    if (value?.indexOf(',') != -1) {
      let labelValue = value.split(',').map((item: string) => `"${item}"`).join()
      return `${t(Labels.create)} ${labelValue}`
    } else {
      return `${t(Labels.create)} "${value}"`
    }
  }
}
