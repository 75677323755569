import { Labels } from 'common/labels';
import DropdownComponent from 'components/dropdown/DropdownComponent';
import { ActionOption } from 'components/table/SelectedRowAction';
import { t } from 'core/translations';
import React, { useState } from 'react'
import { Button } from 'reactstrap';

interface ActionComponentProps {
  children: any;
  execute:boolean;
  exComponent:any;
}

const ActionComponent = (props: any) => {
    

  if(props.execute){
    return <>{props.exComponent}</>
  }
  return <>{props.children}</>
}

export default ActionComponent