import { isEmpty } from "lodash";

export interface ComparisonBetweenType<FieldType> {
  lower: FieldType;
  upper: FieldType;
}

export interface QueryComparisonGroup<FieldType> {
  and?: FieldComparison<FieldType>[],
  or?: FieldComparison<FieldType>[],
}

export interface FieldComparison<FieldType> {
  is?: boolean | null;
  isNot?: boolean | null;
  eq?: FieldType;
  neq?: FieldType;
  gt?: FieldType;
  gte?: FieldType;
  lt?: FieldType;
  lte?: FieldType;
  in?: FieldType[];
  notIn?: FieldType[];
  between?: ComparisonBetweenType<FieldType>;
  notBetween?: ComparisonBetweenType<FieldType>;
  like?: string;
  notLike?: string;
  iLike?: string;
  notILike?: string;
}

export const Operator = {
  equalTo: 'equalTo',
  notEqualTo: 'notEqualTo',
  greaterThan: 'greaterThan',
  greaterThanEqual: 'greaterThanEqual',
  lessThan: 'lessThan',
  lessThanEqual: 'lessThanEqual',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
  contains: 'contains',
  notContains: 'notContains',
  isEmpty: 'isEmpty',
  between: 'between', // value = [lower, upper]
  notBetween: 'notBetween', // value = [lower, upper]
}
export const OperatorKeys = Object.keys(Operator)
export type OperatorKey = keyof typeof Operator

export type Comparison<T> = {
  [K in OperatorKey]?: T | T[];
}

export type Filter<T> = {
  [K in keyof T]?: Comparison<T[K]>;
}

export type ComparisonFilter<T> = {
  [K in keyof T]?: FieldComparison<T[K]>
}

export type ApiQuery<T> = {
  filter: ComparisonFilter<T>,
  limit?: number
  page?: number
  sortBy?: keyof T
  sortDirection?: 'desc' | 'asc'
}

export type QueryFilter<T> = Filter<T> | Filter<T>[] | Record<keyof T, any>

export type QueryParams<T> = {
  filter?: QueryFilter<T>
  limit?: number
  page?: number
  sortBy?: keyof T
  sortDirection?: 'desc' | 'asc',
  initial?: boolean // True nếu tự động load dữ liệu cho page mặc định.
}

export type Sorting<T> = {
  sortBy?: keyof T
  sortDirection?: 'desc' | 'asc'
}

export class QueryBuilder {

  private static createFieldQueryComparison<T>(field: string, comparisonKey: string, value: any): QueryComparisonGroup<T> | FieldComparison<T> | undefined {

    switch (comparisonKey) {
      case 'equalTo':
        return (typeof value == 'boolean' || value === null) ? { [field]: { is: value}} : { [field]: { eq: value} }
      case 'notEqualTo':
        return (typeof value == 'boolean' || value === null) ? { [field]: { isNot: value} } : { [field]: { neq: value} }
      case 'greaterThan':
        return { [field]: { gt: value } }
      case 'greaterThanEqual':
        return { [field]: { gte: value } }
      case 'lessThan':
        return { [field]: { lt: value } }
      case 'lessThanEqual':
        return { [field]: { lte: value } }
      case 'startsWith':
        return { [field]: { like: `%${value}` } }
      case 'endsWith':
        return { [field]: { like: `${value}%` } }
      case 'contains':
        if(Array.isArray(value) && !value.length) return undefined
        if(Array.isArray(value)) {
          if (value.includes(null)) {
            let condition: any = { or: [{ [field]: { is: null } }]}
            let noneNullValues = value.filter(val => val != null)
            if(noneNullValues.length) {
              condition.or.push({ [field]: { in: noneNullValues } })
            }
            return condition
          } else {
            return  { [field]: { in: value } }
          }
        } else {
          return { [field]: { like: `%${value}%` } }
        }
        // return Array.isArray(value) ? { [field]: { in: value } } : { [field]: { like: `%${value}%` } }
      case 'notContains':
        if(Array.isArray(value) && !value.length) throw new Error('Invalid parameters')
        if(Array.isArray(value)) {
          if (value.includes(null)) {
            let condition: any = { and: [{ [field]: { isNot: null } }]}
            let noneNullValues = value.filter(val => val != null)
            if(noneNullValues.length) {
              condition.and.push({ [field]: { notIn: noneNullValues } })
            }
            return condition
          } else {
            return  { [field]: { notIn: value } }
          }
        } else {
          return { [field]: { notLike: `%${value}%` } }
        }

        // return Array.isArray(value) ? { [field]: { notIn: value } } : { [field]: { notLike: `%${value}%` } }
      case 'isEmpty':
        if(value == true) {
          return { or: [{ [field]: { is: null } }, { [field]: { eq: '' as any } }] }
        } else {
          return { and: [{ [field]: { isNot: null } }, { [field]: { neq: '' as any } }] }
        }
      case 'notBetween':
        return { or: [{ [field]: { lte: value[0] } }, { [field]: { gte: value[1] } }] }
      case 'between':
        return { and: [{ [field]: { gt: value[0] } }, { [field]: { lt: value[1] } }] }
    }
  }

  private static createQueryComparisons<T>(filter: Filter<T>) {
    let queryComparisons = [] as FieldComparison<any>[]

    for(let field in filter) {
      let comparison: any = filter[field] || {}
      let keys = Object.keys(comparison || {}) as OperatorKey[]
      for(let key of keys) {
        let value = comparison[key]
        let queryComparison = this.createFieldQueryComparison(field, key, value) as any
        if(queryComparison) {
          if(queryComparison.and) {
            queryComparison.and.forEach((qc: any) => queryComparisons.push(qc))
          } else {
            queryComparisons.push(queryComparison)
          }
        }
      }
    }

    return queryComparisons
  }

  static createFilter<T>(filter: Filter<T> | Filter<T>[], any?: boolean ) {
    let comparisonGroups = [] as any[]
    let filters = Array.isArray(filter) ? filter : [filter]
    for(let filter of filters) {
      let comparisons = this.createQueryComparisons(filter)
      comparisonGroups.push(comparisons)
    }

    if(comparisonGroups.length == 0) {
      return {} as any
    } else {
      if(any) {
        return { or: comparisonGroups.map(comparisons => comparisons.length == 1 ? comparisons[0] : { and: comparisons }) }
      } else {
        return { and: comparisonGroups.reduce((result, comparisons) => ([...result, ...comparisons]), []) }
      }
    }
  }

  static createSimpleFilter(filterObj: Record<string, any> = {}) {
    let filter = Object.entries(filterObj).reduce((result: Filter<any>, [key, value]) => ({ ...result, [key]: { equalTo: value }}), {})
    return this.createFilter(filter)
  }

  static createQuery<T>(filterData: Filter<T> | Filter<T>[] | Record<string, any> | undefined, queryOptions?: QueryParams<T>) {
    let query: ApiQuery<T> = {
      filter: {},
      limit: queryOptions?.limit,
      page: queryOptions?.page,
      sortBy: queryOptions?.sortBy,
      sortDirection: queryOptions?.sortDirection,
    }

    if(filterData) {
      if(Array.isArray(filterData) || this.isComparisonFilter(filterData)) {
        query.filter = this.createFilter(filterData)
      } else {
        query.filter = this.createSimpleFilter(filterData)
      }
    }

    return query
  }

  static isComparisonFilter(data: any) {
    if(!data) return false

    let operatorKeys = []
    for(let value of Object.values(data)) {
      operatorKeys.push(...Object.keys(value as any))
    }

    return operatorKeys.length > 0 && operatorKeys.every(key => key && OperatorKeys.includes(key))
  }
}
