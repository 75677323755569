import React, { ChangeEventHandler } from "react";
import { CardLink, Col, Label, Row } from "reactstrap";
import { FormikInput } from "./FormikInput";
import { FormikHandlers, FormikState } from "formik/dist/types";
import { FormikHelpers } from "formik";
import googleDriveImage from '../../assets/images/brands/google-drive.jpg'
import { Link } from "react-router-dom";
import { OptionalLabel } from "components/common/OptionalLabel";
import { viewImagesState } from "data/atoms/app.atom";
import { useRecoilState } from "recoil";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";


interface SingleLineImageViewProps {
  name: string;
  label: string;
  placeholder: string;
  url?: string;
  required?: boolean;
  onChange?:ChangeEventHandler<any> | ((value: any) => void);
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  images?: string[]
}

export const SingleLineImageView = (props: SingleLineImageViewProps) => {
  const {
    label,
    name,
    placeholder,
    url,
    onChange,
    required,
    validation,
    images
  } = props;
  const [modalState, setModal] = useRecoilState(viewImagesState);

  // const isDrive = (url || validation.values[name] || "").startsWith("https://drive.google.com")
  const isDrive = false
  
  return (
    <Row style={{ minHeight: 100}}>
      <Col xs={3} style={{ padding: 10}}>
        {
          isDrive ?
          <iframe style={{ maxHeight: 140,  border: "1 solid rgb(239, 242, 247)"}}
          src= {url || validation.values[name] || '' }
          width={ "100%" }/> :
          <img
          style={{ maxHeight: 140, objectFit:"cover", border: "1 solid rgb(239, 242, 247)"}}
          src= {url?.startsWith("https://drive.google.com") ? googleDriveImage : validation.values[name] || '' }
          width={ "100%" }
          />
      }
      </Col>
      <Col xs={9}>
        <Row>
          <Col xs={'auto'} className="pe-0">
          {
            required === false
            ? <OptionalLabel label={label} className='qq'/>
            : label && <Label>{label}</Label>
          }
          </Col>
          <Col className="p-0">
            <EyeViewImages images={images || []} url={url} />
          </Col>
        </Row>
        <FormikInput
          name={name}
          placeholder={placeholder}
          validation={validation}
          onChange={onChange}
          value={url || validation.values[name]}
        />
      </Col>
      <div className="dropdown-divider"/>
    </Row>
  );
};

interface eyeViewImagesProps {
  images: (string | undefined)[]
  url: string | undefined
}

export const EyeViewImages = (props: eyeViewImagesProps) => {
  const { images, url } = props
  const [modalState, setModal] = useRecoilState(viewImagesState)

  return (
    <>
      {url ? (
        <Link
          to={"#"}
          className="ps-1"
          onClick={e => {
            e.preventDefault()
            setModal({ open: true, images, url })
          }}
        >
          <AiOutlineEye size="18" />
        </Link>
      ) : (
        <></>
      )}
    </>
  )
}