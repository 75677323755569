import { CrudActions } from "./crud.action";
import {
  CreateFulfillProductDto, FulfillProductDto,
  FulfillProductService,
  FulfillProductServices,
  UpdateFulfillProductDto
} from "../services/fulfill-product.service";
import { Filter } from "../../core/rest-api/query-builder";
import { Logger } from "../../core/logger";
import { ProductTypeServices } from "../services/product-type.service";
import { AdminUtils } from "../../utils/admin-utils";
import { withPermission } from "common/hooks/use-permission";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { format } from "date-fns";

class FulfillProductAction extends CrudActions<FulfillProductService, CreateFulfillProductDto, UpdateFulfillProductDto>{
  constructor() {
    super(new FulfillProductService());
  }

  async openCreateProductTypeForm(productType: string) {
    Logger.debug('Create openCreateProductTypeForm')
  }

  async search(searchValue: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.fulfillProductEntity,
      async () => {
        let filter: Filter<FulfillProductDto> = {} as any
        if (searchValue) {
          filter.name = { contains: searchValue }
        }
        let pageData = await FulfillProductServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async importFulfillProducts(file: any, replaceable?: boolean) {
    try {
      const formData = new FormData();
      formData.append('file', file)
      const data: any = await FulfillProductServices.importFulfillProducts(formData, replaceable)
      return data
    } catch (e) {
      Logger.warn('importFulfillProducts failed: ', e)
    }
    return false
  }
}

export const FulfillProductActions = new FulfillProductAction()
