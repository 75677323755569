import React from "react";
import Header from "./Header";
import Sidebar from "../../routes/Sidebar";
import Footer from "./Footer";

type VerticalLayoutProps = {
  children: any
}

const AuthLayout = (props: VerticalLayoutProps) => {
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar
          theme={"dark"}
          // type={leftSideBarType}
          // isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AuthLayout;
