import React, { Fragment, useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap"
import { SmallContainer } from "components/layout/SmallContainer"
import { FormikInput } from "components/form/FormikInput"
import { t } from "../../core/translations"
import { FormikHelpers, useFormik } from "formik"
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from "recoil"
import { SettingKeys, settingSelector } from "../../data/atoms/setting.atom"
import { LoadableComponent } from "../../components/common/LoadableComponent"
import { Labels } from "common/labels"
import Breadcrumb from "components/layout/Breadcrumb"
import { FormikForm, FormValidation } from "components/form/FormikForm"
import { SettingDto, UpdateSettingDto } from "data/services/setting-service"
import { SettingActions } from "../../data/actions/setting.action"
import { Toasts } from "../../core/notification/notifications"
import { Messages } from "../../common/messages"
import { FormLayout, FormLayoutMain } from "components/layout/FormLayout"
import * as Yup from "yup"
import Select from "react-select"
import { WithPermission } from "components/common/WithPermission"
import { ActionEntities, ResourceEntities } from "types/permission-type"
import { MetaTable } from "../../components/table/MetaTable";
import { createDocumentPageName } from "../../utils/utils";
import { isEmpty } from "lodash"
import { getListTeamSelector } from "data/atoms/account.atom"
import TeamsSelect, { TeamDto } from "./components/teams.select"
import { trackingServiceOptions } from "../../common/constants";

export const FulfillmentTrackingSettingPage = () => {
  const dataLoadable = useRecoilValueLoadable(settingSelector(SettingKeys.TRACKING))
  const isEditing = true
  const [setting, setSetting] = useState<any>({})

  const fulfillmentTrackingSettingSchema = Yup.object({
    value: Yup.object({
      trackingService: Yup.string().required(t(Messages.field_required, { field: t(Labels.tracking_services) })),
      apiKey: Yup.string().required(t(Messages.field_required, { field: t(Labels.api_key) })),
    })
  })

  const validation = useFormik({
    initialValues: {
      teamId: setting?.teamId,
      value: setting?.value
    } || {},
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: fulfillmentTrackingSettingSchema,
    onSubmit: (value, helpers) => handleSubmit(value, helpers),
  })

  useEffect(() => { document.title = createDocumentPageName(t(Labels.shipment_tracking)) }, [])

  useEffect(() => {
    dataLoadable.state === "hasValue" && setSetting(dataLoadable?.contents)
  }, [dataLoadable.state])

  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(settingSelector(SettingKeys.TRACKING))
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[SettingKeys.TRACKING])

  const handleSubmit = async (value: SettingDto, helpers: FormikHelpers<any>) => {
    try {
      helpers.setErrors({})
      helpers.setSubmitting(true)

      let updateData: UpdateSettingDto = {
        value: validation.values?.value,
        teamId: validation.values?.teamId
      }
      if (isEditing) {
        let result = await SettingActions.updateByKey(SettingKeys.TRACKING, updateData)
        if (result?.id) {
          // Nếu cập nhật thành công thì sẽ trả về object có property là id
          setSetting((prev: any) => ({
            ...prev,
            ...result?.value,
          }))
          // TODO optimize code not using setTimeout
          setTimeout(() => Toasts.success(t(Messages.update_successfully)), 100)
        }
      }
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <SmallContainer>
          <Breadcrumb title={`${isEditing ? t(Labels.edit) : t(Labels.create)} ${t(Labels.shipment_tracking)} ${Labels.setting}`}>
            <WithPermission action={isEditing ? ActionEntities.update : ActionEntities.create} resource={ResourceEntities.settingEntity}>
              <Button color="success" onClick={() => validation.submitForm()}>
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </Button>
            </WithPermission>
          </Breadcrumb>
          <Card>
            <CardBody>
              <LoadableComponent loadable={dataLoadable}>
                <FormikForm validation={validation}>
                  <FormLayout>
                    <FormLayoutMain>
                      <SettingForm validation={validation} />
                    </FormLayoutMain>
                  </FormLayout>
                </FormikForm>
              </LoadableComponent>
            </CardBody>
          </Card>
        </SmallContainer>
      </div>
    </React.Fragment>
  )
}

export interface SettingFormProps {
  validation: FormValidation
}

export const SettingForm = (props: SettingFormProps) => {
  const { validation } = props
  return (
    <Fragment>
      {/*<Col className="mb-3">*/}
      {/*  <TeamsSelect*/}
      {/*    label={t(Labels.teams)}*/}
      {/*    validation={validation}*/}
      {/*    teamId={validation.values?.teamId}*/}
      {/*    onChange={(value: any) => validation.setFieldValue('teamId', value.id)}*/}
      {/*  />*/}
      {/*</Col>*/}

      <Col className="mb-3">
        <FormikInput
          validation={validation}
          name="value.trackingService"
          label={t(Labels.tracking_services)}
          placeholder={t(Labels.tracking_services_hint)}
          customInput={({ handleChange, handleBlur, errorData  }) => (
            <Select
              isSearchable={true}
              options={trackingServiceOptions}
              value={trackingServiceOptions.find(item => item.value === validation.values?.value?.trackingService)}
              getOptionLabel={(option: any) => t(option.label)}
              getOptionValue={(option: any) => t(option.value)}
              onBlur={handleBlur}
              onChange={(value: any) => handleChange(value.value)}
            />
          )}
        />
      </Col>
      <Col className="mb-3">
        <FormikInput
          validation={validation}
          name="value.apiKey"
          label={t(Labels.api_key)}
          placeholder={t(Labels.api_key_hint)}
          value={validation.values?.value?.apiKey}
        />
      </Col>
      <Col className="mb-3">
        <FormikInput
          validation={validation}
          name="value.apiBaseUrl"
          label={t(Labels.base_api_url)}
          placeholder={t(Labels.base_api_url)}
          value={validation.values?.value?.apiBaseUrl}
        />
      </Col>
      {/* <Row >
        <Label className="col-sm-3 pt-0 col-form-label">{t(Labels.title)}</Label>
      </Row>
      <Col>
          <FormikInput
            validation={validation}
            name="variantOptions"
            customInput={({ handleChange, handleBlur, errorData  }) => (
              <MetaTable
                json={validation.values?.value?.csvTitles || []}
                keyLabel={t(Labels.name)}
                readOnly={true}
                onChange={handleChange}
              />
              // <JsonTable
              //   json={validation.values.csvTitles}
              //   keyField={"name"}
              //   keyLabel={t(Labels.name)}
              //   valueField={"value"}
              //   renderInCard={false}
              //   fixedOptions={true}
              //   onChange={handleChange}
              //   readOnly={true}
              // />
            )}
          />
        </Col> */}
    </Fragment>
  )
}
