import * as Yup from 'yup'
import { Messages } from 'common/messages'
import { t } from "../../../core/translations";

export const channelOrderSettingSchema = Yup.object({
  value: Yup.object({
    customDataKeys: Yup.array().min(1, t(Messages.field_required, { field: t(Messages.custom_data_keys) })),
    variantOptionKeys: Yup.array().min(1, t(Messages.field_required, { field: t(Messages.variant_option_keys) })),
    sku: Yup.object({
      noneProductSkus: Yup.array().min(1, t(Messages.field_required, { field: t(Messages.sku_is_not_product) })),
      skuCleanerSplitter: Yup.array().min(1, t(Messages.field_required, { field: t(Messages.sku_length_validate) }))
    })
  })
})