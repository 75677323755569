import DateRangePicker from "react-bootstrap-daterangepicker";
import { useRef } from "react";
import * as moment from "moment";

export default function DateFilter(props: { name: string, currentValue: Date[], onFilterChange: Function }) {
  let { currentValue, onFilterChange, name } = props;
  const ref = useRef<any>(null);

  return (
    <div className="form-check form-check-inline mb-3 d-flex align-items-center px-0 mx-0" style={{ minWidth: 250 }}>
      <DateRangePicker
        initialSettings={{
            startDate: currentValue && currentValue[0] || new Date(),
            endDate: currentValue && currentValue[1] || new Date(),
            locale: { format: 'DD-MM-YYYY' }
        }}
        onApply={(e: any, p: any) => {
          onFilterChange(name, {
            value: [p.startDate.toDate(), p.endDate.toDate()],
            label: `${p.startDate.format("DD-MM-YYYY")} to ${p.endDate.format("DD-MM-YYYY")}`,
            operator: 'from'
          });
        }}
        ref={ref}
      >
        <input type="text" className="form-control" />
      </DateRangePicker>
    </div>
  );
}
