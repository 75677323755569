import { useEffect, useState } from "react"
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/dist/styles.css"
import { useRecoilState } from "recoil"
import { viewImagesState } from "data/atoms/app.atom"
import { ModalContent } from "./portable-modal"

const startsWithDriveImage = "https://drive.google.com/uc?export=view&id="
const startsWithDriveFolder = "https://drive.google.com/embeddedfolderview?id="

const formatUrlDriver = (url: string) => {
  if (!url?.startsWith("https://drive")) return url

  let found = url?.match(/d\/([A-Za-z0-9\-]+)/) || []

  if (url.includes("/folders/")) {
    found = url?.match(/([01A-Z])(?=[\w-]*[A-Za-z])[\w-]+/) || []
    if (found[0]) return startsWithDriveFolder + found[0] + "#grid"
  }
  if (found[1]?.length) return startsWithDriveImage + found[1]

  return url
}

export const ViewImgaes = () => {
  const [open, setOpen] = useState(false)
  const [listImage, setListImage] = useState<string[]>([])
  const [index, setIndex] = useState(0)
  const [modalState, setModal] = useRecoilState(viewImagesState)

  useEffect(() => setOpen(modalState.open), [modalState.open])
  useEffect(() => setListImage(modalState?.images?.filter(image => image) || []), [modalState.images])
  useEffect(() => {
    const idx = modalState?.images?.filter(image => image)?.indexOf(modalState.url) || 0
    setIndex(idx >= 0 ? idx : 0)
  }, [modalState.url])

  return (
    <>
      {listImage?.length ? (
        <ModalContent>
          <Lightbox
            open={open}
            index={index}
            close={() => setModal({ open: false })}
            slides={listImage?.map(image => ({ src: formatUrlDriver(image), width: 1080, height: 700 }))}
            render={{ slide: renderCustomSlide }}
            carousel={{ preload: 1, padding: 0, imageFit: "contain" }}
          />
        </ModalContent>
      ) : (
        <></>
      )}
    </>
  )
}

const renderCustomSlide = (slide: any) => (slide.src?.startsWith("https://drive") ? <CustomSildeDrive slide={slide} /> : null)

interface propsCustomSilde {
  slide: any
}

const CustomSildeDrive = (props: propsCustomSilde) => {
  const { slide } = props
  if (slide.src?.startsWith(startsWithDriveImage)) {
    return <img src={slide.src} className="h-75" alt="" />
  }
  return <iframe className="iframeImage" src={slide.src}></iframe>
}
