import React, { useState } from "react"
import * as Yup from "yup"
import { IdentityServices } from "../../data/services/identity.service"
import {
  Button,
  CardBody,
  Col,
  Label,
} from "reactstrap"

import { t } from "core/translations"
import { useFormik } from "formik"
import { Messages } from "common/messages"
import { FormikForm } from "components/form/FormikForm"
import { FormikInput } from "components/form/FormikInput"
import { Toasts } from "../../core/notification/notifications";
import { Labels } from "common/labels"
import { AccountServices } from "../../data/services/account.service";

interface ChangePasswordComponentProps {
  onDone?: Function
}

const ChangePasswordComponent = (props: ChangePasswordComponentProps) => {
  const [account, setAccount] = useState<any>({})

  const accountSchema = Yup.object({
    currentPassword: Yup.string().required(
      t(Messages.field_required, { field: t(Labels.old_password) })
    ),
    newPassword: Yup.string().required(
      t(Messages.field_required, { field: t(Labels.new_password) })
    ),
    retypePassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t(Labels.reentered_password_doesnt_match))
      .required(t(Messages.field_required, { field: t(Labels.reenter_password) })),
  })
  const validation = useFormik({
    initialValues: account,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: accountSchema,
    onSubmit: (value: any, helpers) => handleSubmit(value, helpers),
  })

  const handleSubmit = async (value: any, helpers: any) => {
    try {
      const res = await AccountServices.changePassword({
        currentPassword: value.currentPassword,
        newPassword: value.newPassword,
      })
      Toasts.success(t(Messages.change_password_successfully))
      props.onDone && props.onDone()
    } catch (e) {
      Toasts.error(t(Messages.change_password_unsuccessfully))
    }
  }
  return (
    <>
      <CardBody>
        <FormikForm validation={validation}>
          <div className="row mb-4">
            <Label className="col-sm-3 col-form-label">
              {t(Labels.old_password)}
            </Label>
            <Col sm={9}>
              <FormikInput
                name="currentPassword"
                type="text"
                validation={validation}
                placeholder={t(Labels.old_password_hint)}
              />
            </Col>
          </div>
          <div className="row mb-4">
            <Label className="col-sm-3 col-form-label">
              {t(Labels.new_password)}
            </Label>
            <Col sm={9}>
              <FormikInput
                validation={validation}
                name="newPassword"
                placeholder={t(Labels.new_password_hint)}
              />
            </Col>
          </div>

          <div className="row mb-4">
            <Label className="col-sm-3 col-form-label">
              {t(Labels.reenter_password)}
            </Label>
            <Col sm={9}>
              <FormikInput
                validation={validation}
                name="retypePassword"
                placeholder={t(Labels.reenter_password_hint)}
              />
            </Col>
          </div>
          <hr />
          <div className=" text-sm-center mt-4">
            <Button color="success" onClick={() => validation.submitForm()}>
              <i className="bx bx-save font-size-16 align-middle me-1"></i>
              {t(Labels.save)}
            </Button>
          </div>
        </FormikForm>
      </CardBody>
    </>
  )
}

export default ChangePasswordComponent
