import { Labels } from "common/labels";
import { LoadableComponent } from "components/common/LoadableComponent";
import { FormikForm, FormValidation } from "components/form/FormikForm";
import { FormikInput } from "components/form/FormikInput";
import ActionButton from "components/input/ActionButton";
import { t } from "core/translations";
import { fulfillmentOrderByIdSelector } from "data/atoms/fulfillment-order.atom";
import { FormikHelpers, useFormik } from "formik";
import { isEmpty, omit } from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Alert, Card, CardBody, CardHeader, CardTitle, Col, Container, Form, Label, Row } from "reactstrap";
import Breadcrumb from "components/layout/Breadcrumb";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { RouteNames } from "routes";
import { FormLayout, FormLayoutMain } from "components/layout/FormLayout";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { FulfillmentOrderDto } from "data/services/fulfillment-order.service";
import { Forms } from "utils/forms";
import { FulfillmentOrderActions } from "data/actions/fulfillment-order.action";
import { Toasts } from "core/notification/notifications";
import { Messages } from "common/messages";
import { StatusSelect } from "components/common/StatusComponent";
import {
  checkRequireUpdateFulfillmentOrderReason,
  FulfillmentDesignStatusNames,
  fulfillmentDesignStatusValues,
  FulfillmentOrderNames,
  FulfillmentOrderStatus,
  fulfillmentOrderStatusValues
} from "types/fulfillment-order-type";
import { TooltipComponent } from "components/common/TooltipComponent";
import { BsCheckCircleFill } from "react-icons/bs";
import { RiCloseCircleFill, RiEdit2Line } from "react-icons/ri";
import { BiLinkExternal } from "react-icons/bi";
import { AddressDetail } from "pages/order/order.page";
import { portableModalState } from "data/atoms/app.atom";
import { ShippingAddressPopup } from "./fulfill-order.page";
import { FulfillVariantActions } from "data/actions/fulfill-variation.action";
import { SearchableSelect } from "components/input/SearchableSelect";
import * as Yup from "yup";
import { TestContext } from "yup";
import CheckDataLoadable from "../../components/common/CheckDataLoadable";
import { preprocessPage } from "utils/utils";
import { VendorSelect } from "../products/components/vendor.select";
import { EyeViewImages } from "components/form/SingleLineImageView";
import { AiOutlineEye } from "react-icons/ai";
import { Loading } from "components/common/Loading";
import { FulfillmentInvoiceServices } from "data/services/fulfillment-invoice.service";
import { PopupCreateComponent } from "./components/invoice-popup-create";
import { Logger } from "core/logger";
import { createLinkEmbedParam } from "utils/embed";

const validationSchemaFulfillmentOrder = (fulfillmentOrder: any = {}) => {

  return Yup.object({
    updateMessage: Yup.string().test("checkRequiredUpdateMessage", (value: any,conText: TestContext) => {
      if(!(conText.parent?.status === fulfillmentOrder.status) && !value ){
        return  conText.createError({ message: t(Messages.field_required, { field: t(Labels.modify_note) }) })
      }
      return true
    }),
  })
}

export const FulfillmentOrderPage = () => {
  const params = useParams<Record<string, any>>();
  const isEditing = +params.id > 0;
  const history = useHistory()

  const [fulfillmentOrder, setFulfillmentOrder] = useState<any>({} as any);
  const dataLoadable = useRecoilValueLoadable(fulfillmentOrderByIdSelector(params.id))

  useEffect(() => {
    preprocessPage({params, history, pageName: t(Labels.fulfillment_order), model: fulfillmentOrder})
  }, [fulfillmentOrder])

  
  // TODO remove fakeRefreshDataLoadable 
  const fakeRefreshDataLoadable = useRecoilRefresher_UNSTABLE(fulfillmentOrderByIdSelector(params.id))
  useEffect(() => {
    ((dataLoadable.state == "hasValue" || dataLoadable.state == "hasError") && !isEmpty(dataLoadable.contents)) && fakeRefreshDataLoadable()
  },[params.id])

  const changeFulfillmentOrderData = (data: any) => {
    if (isEmpty(data)) return {}

    let shippingAddress = {
      address1: data?.shippingAddress1,
      address2: data?.shippingAddress2,
      city: data?.shippingCity,
      company: data?.shippingCompany,
      country: data?.shippingCountry,
      countryCode: data?.shippingCountryCode,
      firstName : data?.shippingFirstName,
      lastName : data?.shippingLastName,
      phone : data?.shippingPhone,
      provinceCode : data?.shippingProvinceCode,
      shippingMethod: data?.shippingMethod,
      email: data?.shippingEmail,
      zip : data?.shippingZipCode
    }
    setFulfillmentOrder({ ...data, shippingAddress })
  }

  useEffect(() => {
    if (dataLoadable.state == "hasValue") {
      changeFulfillmentOrderData(dataLoadable.contents)
    }
  }, [dataLoadable.state])

  const validation = useFormik({
    initialValues: fulfillmentOrder,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: validationSchemaFulfillmentOrder(fulfillmentOrder),
    onSubmit: (value, helpers) => handleSubmit(value, helpers),
  })

  const handleSubmit = async (values: FulfillmentOrderDto, helpers: FormikHelpers<any>) => {
    let changedData = isEditing ? Forms.getChangedValues(values, fulfillmentOrder) : values

    changedData = omit(changedData, 'shippingAddress', 'fulfillVariant')
    if(changedData.fulfillVariantId){
      changedData.designSku = values.designSku
    }

    try {
      helpers.setErrors({})
      helpers.setSubmitting(true)
      let savedData = await FulfillmentOrderActions.save(params.id,
        changedData)
      if (savedData?.id) {
        setFulfillmentOrder((current: any) => ({ ...current, ...savedData }))
        Toasts.success(t(Messages.save_fulfillment_order_successfully))

        // TODO optimize not using setTimeout
        !isEditing && setTimeout(() => {
          history.replace(createLinkEmbedParam(RouteNames.FULFILLMENT_ORDERS_DETAIL.replace(":id",savedData.id)).to);
        }, 100);
      }
    } catch (error) {
      helpers.setSubmitting(false)
    }
  }

  const handleUploadDesignFile = async () => {
    let updated = await FulfillmentOrderActions.uploadDesign(params.id)
    console.log('updated', updated)
    if(updated?.id) {
      changeFulfillmentOrderData(updated)
    }
  }

  return (
    <React.Fragment>
      <CheckDataLoadable 
        loadable={dataLoadable} 
        goBack={RouteNames.FULFILLMENT_ORDERS}
        selector={fulfillmentOrderByIdSelector}
        selectorParams={params.id}
      >
        <div className="page-content">
          <Container>
            <Breadcrumb
              goBack={RouteNames.FULFILLMENT_ORDERS}
              title={isEditing ? t(Labels.edit_fulfillment_order) : t(Labels.create_fulfillment_order)}
            >
              {
                isEditing ? (
                  <ActionButton color="primary" onClick={handleUploadDesignFile} className="me-2">
                    <i className="bx bx-save font-size-16 align-middle me-1"></i>
                    {t(Labels.upload_design_file)}
                  </ActionButton>
                ) : null
              }
              <ActionButton color="success" onClick={() => validation.submitForm()}>
                <i className="bx bx-save font-size-16 align-middle me-1"></i>
                {isEditing ? t(Labels.save) : t(Labels.create)}
              </ActionButton>
            </Breadcrumb>
            <LoadableComponent loadable={dataLoadable}>
              <FormikForm validation={validation} className="form-horizontal">
                <Row>
                  <Col xs={12} md={8}>
                    <FormLayoutMain>
                      <FulfillmentOrderForm validation={validation} fulfillmentOrder={fulfillmentOrder}/>
                      <Fulfillment validation={validation} />
                      <FormLayout>
                        <FormLayoutMain>
                          <Card>
                            <CardHeader>
                              <CardTitle>{t(Labels.fulfillment_order_data)}</CardTitle>
                            </CardHeader>
                            <CardBody className="pt-1">
                              <UpdatefulfillmentOrderTable validation={validation} loadable={dataLoadable} />
                            </CardBody>
                          </Card>
                        </FormLayoutMain>
                      </FormLayout>
                    </FormLayoutMain>
                  </Col>
                  <Col xs={12} md={4}>
                    <FulfillmentOrderFormRight validation={validation} fulfillmentOrder={fulfillmentOrder}/>
                  </Col>
                </Row>
              </FormikForm>
            </LoadableComponent>
          </Container>
        </div>
      </CheckDataLoadable>
    </React.Fragment>
  )
}

const Fulfillment = (props: any) => {
  const { validation } = props
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t(Labels.fulfillment)}</CardTitle>
      </CardHeader>
      <CardBody className="pt-2">
        <div>
          {/* <Row>
            <Col>
              <h4 className="card-title mb-3">{t(Labels.fulfillment)}</h4>
            </Col>
          </Row> */}
          <Col>
            <FormikInput
              name="fulfiller.name"
              type="text"
              readOnly={true}
              label={Labels.fulfiller}
              validation={validation}
              value={validation.values.fulfiller?.name || validation.values.fulfiller?.username}
              onChange={(value: string) => {
                validation.setFieldValue("fulfiller", { ...(validation.values.fulfiller || {}), name: value })
              }}
              placeholder={t(Labels.fulfiller_hint)}
            />
          </Col>
          <Row>
            <Col>
              <FormikInput
                name="carrierCode"
                type="text"
                label={Labels.carrier_code}
                validation={validation}
                placeholder={t(Labels.carrier_code_hint)}
              />
            </Col>
            <Col>
              <FormikInput
                name="carrierName"
                type="text"
                label={Labels.carrier_name}
                validation={validation}
                placeholder={t(Labels.carrier_name_hint)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <Label className="col-form-label pt-0">{t(Labels.tracking_number)}</Label>
              <Row>
                <FormikInput name="trackingNumber" type="text" validation={validation} placeholder={t(Labels.tracking_number_hint)} />
              </Row>
            </Col>
            <Col className="mb-2">
              <Label className="col-form-label pt-0">{t(Labels.base_cost)}</Label>
              <Row>
                <FormikInput name="baseCost" type="number" validation={validation} placeholder={t(Labels.base_cost_hint)} />
              </Row>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  )
}

export interface FulfillmentOrderFormProps {
  validation: FormValidation,
  fulfillmentOrder: FulfillmentOrderDto
}

export const FulfillmentOrderForm = (props: FulfillmentOrderFormProps) => {
  const {validation} = props

  const images = [validation.values.designProductUrl, validation.values.designPreviewUrl]
  return (
    <Form>
      <>
        <Card>
          <CardHeader>
            <CardTitle>{t(Labels.fulfillment_order_information)}</CardTitle>
          </CardHeader>
          <CardBody className="pt-2">
            <Row className="mb-2">
              <FormikInput 
                name="productName" 
                type="text" 
                label={t(Labels.product_name)}
                validation={validation} 
                placeholder={t(Labels.product_name_hint)} 
              />
            </Row>
            <Row className="mb-2">
              <Col lg={6}>
                <FormikInput
                  name="fulfillVariantName"
                  label={Labels.fulfill_variant}
                  validation={validation}
                  customInput={({handleChange, handleBlur, errorData}) => {
                    const getOptionLabel = (item: any = {}) => {
                      return t(`${item.variantName || validation.values.fulfillVariantName || validation.values.fulfillVariant?.variantName} (${item.fulfillService?.name || validation.values.fulfillServiceName || ""})`)
                    }

                    return <SearchableSelect
                      onChange={(value: any) => {
                        validation.setFieldValue('fulfillVariant', value)
                        validation.setFieldValue('fulfillVariantId', value?.id)
                      }}
                      blurOnSelect={true}
                      menuPortalTarget={document.body}
                      name="form-field-name"
                      onBlur={handleBlur}
                      loadOptions={FulfillVariantActions.search}
                      getOptionValue={(item: any) => item?.id}
                      getOptionLabel={(item: any) => getOptionLabel(item)}
                      value={validation.values.fulfillVariant}
                      placeholder={t(Labels.fulfill_variant_hint)}
                      getSearchValue={(option: any) => option?.variantTitle}
                    />
                  }}
                />
              </Col>
              <Col>
                <FormikInput
                  name="fulfillBaseCost"
                  type="number"
                  label={Labels.fulfill_base_cost}
                  validation={validation}
                  placeholder={t(Labels.fulfill_base_cost_hint)}
                />
              </Col>
            </Row>
            <Row className="mb-2">
            <Col>
                  <FormikInput 
                    name="fulfillSku" 
                    type="text" 
                    validation={validation} 
                    label={Labels.fulfillment_sku}
                    placeholder={t(Labels.fulfiller_hint)}
                  />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <FormikInput
                  name="designSku"
                  type="text"
                  validation={validation}
                  label={Labels.design_sku}
                  placeholder={t(Labels.design_sku)}
                />
              </Col>
              <Col>
                <FormikInput
                  name="customizeText"
                  type="text"
                  label={t(Labels.customize_text)}
                  validation={validation}
                  placeholder={t(Labels.customize_hint)}
                />
              </Col>
            </Row>
            {/* <Row className="mb-2">
              <Col>
                  <FormikInput 
                    name="fulfillOrderSku" 
                    type="text" 
                    label={Labels.fulfill_order_sku}
                    validation={validation} 
                    placeholder={t(Labels.status_hint)} 
                  />
              </Col>
              <Col>
                  <FormikInput 
                    name="fulfillServiceSku" 
                    type="text" 
                    validation={validation} 
                    label={Labels.fulfillment_sku}
                    placeholder={t(Labels.fulfiller_hint)}
                  />
              </Col>
            </Row> */}
            {/* <Row className="mb-2">
              <FormikInput 
                name="customizeText" 
                type="text" 
                label={t(Labels.customize)}
                validation={validation} 
                placeholder={t(Labels.customize_hint)} 
              />
            </Row> */}
            <Row className="mb-2">
              <Col>
                  <Label className="col-form-label pt-0">{t(Labels.design_product_url)}</Label>
                  <span className="p-0">
                    {/* <a  href={`${validation.values.designProductUrl}`} rel="noreferrer" target="_blank" className="ps-1">
                      <BiLinkExternal size="18" />
                    </a> */}
                    <EyeViewImages images={images} url={validation.values.designProductUrl} />
                  </span>
                </Col>
              <FormikInput 
                name="designProductUrl" 
                type="text" 
                validation={validation} 
                placeholder={t(Labels.design_product_url_hint)} 
              />
              {/*  */}
            </Row>
            <Row className="mb-2">
              <Col>
                  <Label className="col-form-label pt-0">{t(Labels.design_preview_url)}</Label>
                  <span className="p-0">
                    {/* <a target="_blank" rel="noreferrer" href={`${validation.values.designPreviewUrl}`} className="ps-1">
                      <BiLinkExternal size="18" />
                    </a> */}
                    <EyeViewImages images={images} url={validation.values.designPreviewUrl} />
                  </span>
                </Col>
              <FormikInput 
                name="designPreviewUrl" 
                type="text" 
                validation={validation} 
                placeholder={t(Labels.design_preview_url_hint)} 
              />
            </Row>
            <Row className="mb-2">
              <Col>
                <Label className="col-form-label pt-0">{t(Labels.design_folder)}</Label>
                <span className="p-0">
                    <a target="_blank" rel="noreferrer" href={`${validation.values.designFolderUrl}`} className="ps-1">
                      <BiLinkExternal size="18" />
                    </a>
                  </span>
              </Col>
              <FormikInput
                name="designFolderUrl"
                type="text"
                validation={validation}
                placeholder={t(Labels.design_folder_hint)}
              />
            </Row>

            {/* <Row className="mb-2">
              <Col>
                <Label className="col-form-label pt-0">{t(Labels.status)}</Label>
                <Row>
                  <FormikInput 
                    name="status" 
                    type="text" 
                    validation={validation} 
                    placeholder={t(Labels.status_hint)} 
                    customInput={({handleChange, handleBlur}) => (
                      <StatusSelect 
                        name={"status"}
                        placeholder={t(Labels.status_hint)}
                        value={validation.values?.status}
                        statusValues={FulfillmentOrderValues}
                        statusNames={FulfillmentOrderNames}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  />
                </Row>
              </Col>
              <Col>
                <Label className="col-form-label pt-0">{t(Labels.fulfiller)}</Label>
                <Row>
                  <FormikInput 
                    name="fulfiller" 
                    type="text" 
                    validation={validation} 
                    placeholder={t(Labels.fulfiller_hint)}
                    value={validation.values?.fulfiller?.username}
                    disabled={true}
                  />
                </Row>
              </Col>
            </Row> */}
            
            {/* <Row className="mb-2">
              <Col>
                <Label className="col-form-label">{t(Labels.fulfill_variant)}</Label>
                <Row>
                  <FormikInput 
                    name="variant" 
                    type="text" 
                    validation={validation} 
                    placeholder={t(Labels.fulfill_variants_hint)} 
                    customInput={({handleChange, handleBlur}) => (
                      <FulfillVariantSelect 
                        value={validation.values?.fulfillVariant}
                        onChange={(value: any) => validation.setFieldValue('fulfillVariant', value)}
                      />
                    )}
                  />
                </Row>
              </Col>
            </Row> */}
          </CardBody>
        </Card>
        
      </>
    </Form>
  )
}

export const FulfillmentOrderFormRight = (props: FulfillmentOrderFormProps) => {
  const { validation, fulfillmentOrder } = props
  const setPortableModal = useSetRecoilState(portableModalState)
  const [fromStatus, setFromStatus] = useState<FulfillmentOrderStatus>(fulfillmentOrder?.status);
  useEffect(() => setFromStatus(fulfillmentOrder.status), [fulfillmentOrder])

  const dataSaveShippingAddressFulfillmentOrder = (shippingAddress: any) => {
    return {
      shippingAddress1: shippingAddress.address1,
      shippingAddress2: shippingAddress.address2,
      shippingCity: shippingAddress.city,
      shippingCompany: shippingAddress.company,
      shippingCountryCode: shippingAddress.countryCode,
      shippingFirstName: shippingAddress.firstName,
      shippingLastName: shippingAddress.lastName,
      shippingEmail: shippingAddress.email,
      shippingProvinceCode: shippingAddress.provinceCode,
      shippingPhone: shippingAddress.phone,
      shippingZipCode: shippingAddress.zip,
      shippingMethod: shippingAddress.shippingMethod,
    }
  }

  const viewAddress = () => {
    setPortableModal({
      open: true,
      title: t(Labels.edit_shipping_address),
      size: 'lg',
      content: (
        <ShippingAddressPopup
          isEditing={true}
          title={t(Labels.shipping_information)}
          methodName={"shippingMethod"}
          addressName={"shippingAddress"}
          validation={validation}
          id={validation?.values?.id}
          saveData={async (id: string, changedData: any) => await FulfillmentOrderActions.save(id, dataSaveShippingAddressFulfillmentOrder(changedData?.shippingAddress))}
        />
      ),
    })
  }

  const statusMessages = validation.values.statusMessages

  const popupViewInvoice = (data: any) => {
    setPortableModal({
      open: true,
      size: 'lg',
      title: Labels.edit_invoices,
      content: (<PopupCreateComponent data={data}/>)
    })
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4 className="card-title mb-3">{t(Labels.status)}</h4>
            </Col>
          </Row>
          <Row className="flex-column">
            <Col>
              <FormikInput
                label={t(Labels.status)}
                name={"status"}
                validation={validation}
                customInput={({ handleChange, handleBlur, errorData }) => (
                  <StatusSelect
                    name={"status"}
                    placeholder={t(Labels.status_hint)}
                    value={validation.values.status}
                    statusValues={fulfillmentOrderStatusValues}
                    statusNames={FulfillmentOrderNames}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Col>
            <Col>
              <FormikInput
                label={t(Labels.design_status)}
                name={"designStatus"}
                validation={validation}
                customInput={({ handleChange, handleBlur, errorData }) => (
                  <StatusSelect
                    name={"designStatus"}
                    placeholder={t(Labels.design_status_hint)}
                    value={validation.values.designStatus}
                    statusValues={fulfillmentDesignStatusValues}
                    statusNames={FulfillmentDesignStatusNames}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    styles={errorData.reactSelectErrorStyle}
                  />
                )}
              />
            </Col>
            <Col>
              {checkRequireUpdateFulfillmentOrderReason([{ status: fromStatus, id: validation.values.id }], validation.values?.status) ? (
                <FormikInput
                  type="textarea"
                  rows={4}
                  name="updateMessage"
                  label={t(Labels.modify_note)}
                  placeholder={t(Labels.modify_note_hint)}
                  validation={validation}
                />
              ) : null}
            </Col>
            <Label className="mb-2">{t(Labels.status_message)}</Label>
            <Col className="mb-2">
              {statusMessages
                ? statusMessages?.slice(0,2)?.map((item: string, idx: number) => (
                    <Alert
                      color={"info"}
                      className={`p-1 ${idx === statusMessages.length - 1 ? "mb-0" : "mb-2"} font-size-11`}
                      pill
                      key={idx}
                    >
                      {item}
                    </Alert>
                  ))
                : t(Labels.no_status_messages)}
            </Col>
            <Label className="mb-2">{t(Labels.customer_note)}</Label>
            <Col className="d-flex flex-column gap-2">
              <Alert color={"info"} className="p-1 mb-0 font-size-11" pill>
                {validation.values.customerNote || t(Labels.no_note_customer)}
              </Alert>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4 className="card-title mb-3">{t(Labels.order)}</h4>
            </Col>
          </Row>
          <Row className="mb-2 flex-column">
            <Col>
              <Col>
                <Label className="col-form-label pt-0">{t(Labels.vendor)}</Label>
              </Col>
              <Row className="mb-2">
                <VendorSelect
                  value={validation.values?.vendor}
                  onChange={(value: any) => {
                    validation.setFieldValue("vendor", value)
                    validation.setErrors({})
                  }}
                />
              </Row>
            </Col>
            <Col>
              <Col>
                <Label className="col-form-label pt-0">{t(Labels.order_number)}</Label>
                <span className="p-0">
                  {validation.values.external ? (
                    <></>
                  ) : (
                    <Link target="_blank" to={createLinkEmbedParam(`${RouteNames.ORDERS}/${validation.values.orderId}`).to} className="ps-1">
                      <BiLinkExternal size="18" />
                    </Link>
                  )}
                </span>
              </Col>
              <Row className="mb-2">
                <FormikInput
                  readOnly={true}
                  name="orderNumber"
                  type="text"
                  value={
                    validation.values.orderNumber
                      ? `${validation.values.orderId} - ${validation.values.orderNumber}`
                      : validation.values.orderNumber
                  }
                  validation={validation}
                  placeholder={t(Labels.fulfill_order_number_hint)}
                />
              </Row>
            </Col>
            <Col>
              <Col>
                <Label className="col-form-label pt-0">{t(Labels.order_item)}</Label>
                <span className="p-0">
                  {validation.values.external ? (
                    <></>
                  ) : (
                    <Link
                      target="_blank"
                      to={createLinkEmbedParam(`${RouteNames.ORDER_ITEM_DETAIL.replace(":id", validation.values.fulfillOrderId)}`).to}
                      className="ps-1"
                    >
                      <BiLinkExternal size="18" />
                    </Link>
                  )}
                </span>
              </Col>
              <Row>
                <FormikInput
                  name="fulfillOrder.id"
                  type="text"
                  readOnly={true}
                  value={
                    validation.values.fulfillOrder?.id
                      ? `${validation.values.fulfillOrder?.id}${
                          validation.values.orderItemSku ? ` - ${validation.values.orderItemSku}` : ""
                        }`
                      : ""
                  }
                  validation={validation}
                  placeholder={t(Labels.order_item_hint)}
                />
              </Row>
            </Col>
            <Col>
              <FormikInput name={"external"} type="checkbox" readOnly validation={validation} label={t(Labels.external_request)} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4 className="card-title mb-3">{t(Labels.other_information)}</h4>
            </Col>
          </Row>
          <Row className="mb-2 flex-column">
            <Col>
              <Col>
                <Label className="col-form-label pt-0">{t(Labels.invoice)}</Label>
                <span className="p-0">
                  {!validation.values.invoiceId ? (
                    <></>
                  ) : (
                    <Link {...createLinkEmbedParam(`#`)}
                     onClick={async () => {
                      try {
                        setPortableModal({
                          open: true,
                          size: "lg",
                          title: Labels.edit_invoices,
                          content: (
                            <div className="p-3">
                              <Loading />
                            </div>
                          ),
                        })

                        const result = await FulfillmentInvoiceServices.getById(validation.values.invoiceId)
                        popupViewInvoice(result)
                      } catch (error: any) {
                        Logger.log('error ', error);
                      }
                    }} className="ps-1">
                      <AiOutlineEye size="18" />
                    </Link>
                  )}
                </span>
              </Col>
              <Row className="mb-2">
                <FormikInput
                  readOnly={true}
                  name="orderNumber"
                  type="text"
                  value={validation.values.invoiceId}
                  validation={validation}
                  placeholder={t(Labels.invoice_hint)}
                />
              </Row>
            </Col>
            
          </Row>
        </CardBody>
      </Card>

      {/* <Card>
        <CardBody>
          <div>
            <Row>
              <Col>
                <h4 className="card-title mb-3">{t(Labels.fulfill_variant)}</h4>
              </Col>
              <Col xs={"12"} lg={"auto"}></Col>
            </Row>
            <Row>
              <Label class="col-form-label form-label">
                {t(Labels.fulfill_variant)}
                <Link
                  target="_blank"
                  to={`${RouteNames.FULFILL_VARIANT.replace(":id", validation.values.fulfillVariant?.id)}`}
                  className="ps-1"
                >
                  <BiLinkExternal size="18" />
                </Link>
              </Label>
              <Col className="d-flex flex-column gap-2">
                <FormikInput
                  name="variant"
                  type="text"
                  validation={validation}
                  placeholder={t(Labels.fulfill_variants_hint)}
                  customInput={({ handleChange, handleBlur }) => (
                    <FulfillVariantSelect
                      value={validation.values?.fulfillVariant}
                      onChange={(value: any) => validation.setFieldValue("fulfillVariant", value)}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card> */}
      <Card>
        <CardBody>
          <div>
            <Col>
              <div className="d-flex justify-content-between align-content-center ">
                <h4 className="card-title mb-3">{t(Labels.shipping_information)}</h4>
                <p className="text-primary mb-0 p-1" style={{ cursor: "pointer" }} onClick={() => viewAddress()}>
                  <RiEdit2Line size={18} className="pe-1" />
                  {t(Labels.edit)}
                </p>
              </div>
            </Col>
            <Row>
              <Label className="mb-2">{t(Labels.contact_infomation)}</Label>
              <div className="table-responsive text-muted">
                <div className="ms-2 me-2">
                  <div>
                    <a href="#"> {validation?.values?.shippingAddress?.phone || ""}</a>
                  </div>
                  <div>
                    <a href="#"> {validation?.values?.shippingAddress?.email || ""} </a>
                  </div>
                </div>
              </div>
              <div className="mt-3 mb-0">
                <Label>{t(Labels.shipping_method)}</Label>
                <br />
                <span className="ms-2">{validation?.values?.shippingAddress?.shippingMethod || ""}</span>
              </div>
              <div className="mt-3 mb-0">
                <Label>{t(Labels.shipping_address)}</Label>
                <AddressDetail address={validation?.values?.shippingAddress} />
              </div>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export interface UpdatefulfillmentOrderTableProps {
  validation: FormValidation
  loadable: any
}

export const UpdatefulfillmentOrderTable = (props: UpdatefulfillmentOrderTableProps) => {
  const {validation, loadable} = props
  const [fulfillmentOrderData, setFulfillmentOrderData] = useState([])
  
  useEffect(() => {
    if (!isEmpty(validation.values)) {
      let mergeData = {
        ...validation.values?.submittedData,
        ...validation.values?.completedData
      }
      let newDataFilfillmentOrder: any[] = []
      Object.entries(mergeData)?.forEach((md: any) => {
        let newSubmittedData = validation.values?.submittedData?.hasOwnProperty(`${md[0]}`) 
          ? validation.values?.submittedData[`${md[0]}`] : ''
        let newcompletedData = validation.values?.completedData?.hasOwnProperty(`${md[0]}`) 
          ? validation.values?.completedData[`${md[0]}`] : ''
        newDataFilfillmentOrder.push({
          key: md[0],
          submittedData: newSubmittedData,
          completedData: newcompletedData,
          status: (newSubmittedData == newcompletedData) ? true : false
        })
      })
      setFulfillmentOrderData(newDataFilfillmentOrder as any)
    }
  }, [isEmpty(validation.values)])

  const columns: ColumnDescription<any, any>[] = [
    {
      dataField: 'key',
      text: t(Labels.key),
      headerStyle: {width: '20%'},
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => (
        <div>
          <p className="mb-0">{row?.key && row.key}</p>
        </div>
      ),
    },
    {
      dataField: 'submittedData',
      text: t(Labels.submitted_data),
      headerStyle: {width: '35%'},
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => {
        return (
          <div>
            <p className="mb-0">{row?.submittedData && row.submittedData}</p>
          </div>
        )
      },
    },
    {
      dataField: 'completedData',
      text: t(Labels.completed_data),
      headerStyle: {width: '35%'},
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => (
        <div>
          <p className="mb-0">{row?.completedData && row.completedData}</p>
        </div>
      ),
    },
    {
      dataField: "status",
      text: t(Labels.status),
      headerStyle: {minWidth: 80},
      // eslint-disable-next-line react/display-name
      formatter: (cell: any, row: any) => (
        <div>
          {cell ? (
            <TooltipComponent tooltip={Labels.match} className='d-flex align-items-center justify-content-center'>
              <BsCheckCircleFill size={20} color='green'/>
            </TooltipComponent>
          ) : (
            <TooltipComponent tooltip={Labels.not_match} className='d-flex align-items-center justify-content-center'>
              <RiCloseCircleFill size={24}  color='red' />
            </TooltipComponent>
          )}
        </div>
      ),
    },
  ]

  const getNoDataIndication = () => {
    if (loadable.state == "loading") {
      return <div className="spinner-border" role="status" />;
    } else if (loadable.state == "hasError") {
      return loadable.contents?.message || t(Messages.unknown);
    }
    return <p className="text-danger">{t(Labels.no_data_available)}</p>;
  };

  return (
    <div>
      <BootstrapTable
        columns={columns}
        data={fulfillmentOrderData || []}
        keyField={'id'}
        rowStyle={{height: 40}}
        headerWrapperClasses={"table-light"}
        rowClasses={'border-cell'}
        bordered={true}
        noDataIndication={getNoDataIndication}
        wrapperClasses="minHeightAuto"
      />
    </div>
  )
}
