import { FormFeedback, FormGroup, Input, InputGroup, Label } from "reactstrap";
import { InputProps } from "reactstrap/types/lib/Input";
import { FormikHandlers, FormikState } from "formik/dist/types";
import React, { ChangeEventHandler, Fragment, memo, useEffect, useRef } from "react";
import { FormikHelpers } from "formik";
import { isObject, isString } from "lodash";
import { OptionalLabel } from "components/common/OptionalLabel";
import { LabelTooltip } from "components/common/LabelTooltip";
import { FormikCheckboxInput } from "components/form/FormikInput";

export interface FormikInputProps extends InputProps {
  validation: FormikHandlers & FormikState<any> & FormikHelpers<any>;
  name: string;
  label?: string;
  readOnly?: boolean;
  errors?: any;
  hiddenError?: boolean
  startComponent?: any;
  endComponent?: any;
  required?: boolean;
  tooltip?: string;
  onChange?: ChangeEventHandler<any> | ((value: any) => void);
  customInput?: ({ handleBlur, handleChange }: any) => any;
  index: number
} 

// xuanduc edit
const getErrorMessageGroup = (errors: any, name: string): any => {
  let message = getErrorMessage(errors, name)
  return message
}

const getErrorMessage = (errors: any, name: string): any => {
  if(isString(errors)) return errors;
  if (!errors) return errors;
  
  if (Array.isArray(errors)) {
    for (let error of errors) {
      const msg: any = getErrorMessage(error, name);
      if (msg) return msg;
    }
  } else {
    let error = errors[name];
    if (isObject(error)) {
      return getErrorMessage(error, name);
    } else {
      return error;
    }
  }
};

const FormikInputVariantComponent = (props: FormikInputProps) => {
  const {
    readOnly,
    label,
    children,
    name,
    validation,
    errors,
    onChange,
    startComponent,
    endComponent,
    customInput,
    required,
    tooltip,
    index = 0,
    ...restProps
  } = props;
  const isSubmitting = useRef<any>(null)

  
  useEffect(() => {
    if(validation.isSubmitting){
      isSubmitting.current = true
    }
  }, [validation.isSubmitting])
  const handleChange = (value: any, fieldName = name) => {
    fieldName = isString(fieldName) ? fieldName : name
    if (onChange) {
      onChange(value);
    } else {
      validation.setFieldValue(fieldName, value);
    }

    validation.setFieldError(fieldName, undefined);
  };

  const handleBlur = () => {
    validation?.setFieldTouched(name, true);
  };

  const PreInputComponent = startComponent || Fragment;
  const EndInputComponent = endComponent || Fragment;

  function isInvalidInput() {
    return props.invalid || props.hiddenError ? false : !!(validation && (validation.touched[name] || isSubmitting.current) && validation.errors[name] && (validation.errors[name] as any)[index]);
  }

  const getReactSelectErrorStyle = (base: any) => {
    return {
      ...base,
      borderColor: "#f46a6a",
      boxShadow: "unset",
      '&:hover': {
        boxShadow: "0 0 0 1px #f46a6a",
        borderColor: "#f46a6a"
      }
    }
  }

  const errorData = {
    error: isInvalidInput(),
    errorStyle: {
      boxShadow: "0 0 0 1px #f46a6a",
      borderColor: "#f46a6a"
    },
    reactSelectErrorStyle: isInvalidInput() ? {
      control: (base: any) => getReactSelectErrorStyle(base)
    } : { control: (base: any) => base }
  }

  return props.type == "checkbox" || props.type == "radio" ? <FormikCheckboxInput {...props} /> : (
    <>
      <div className={label ? "mb-2" : ""}>
        {
          tooltip
            ? 
              required == false
              ? <LabelTooltip tooltip={tooltip as string}>
                  <OptionalLabel label={label}/>
                </LabelTooltip>
              : <LabelTooltip tooltip={tooltip as string}>
                  {label && <Label>{label}</Label>}
                </LabelTooltip>
            : 
              required == false
              ? <OptionalLabel label={label} />
              : label && <Label>{label}</Label>
        }
        {
          customInput ? (
              customInput({ handleBlur, handleChange, errorData})
            )
            :
            <InputGroup>
              <PreInputComponent />
              <Input
                name={name}
                onChange={readOnly ? (() => {
                }) : (event) => handleChange(event.target.value)}
                onBlur={validation?.handleBlur}
                {...(props.hasOwnProperty("value") ? {} : { value: validation?.values[name] || "" })}
                {...{ className: "form-control", ...restProps }}
                invalid={isInvalidInput()}
              />
              <EndInputComponent />
            </InputGroup>
        }
        {
          props.hiddenError || !(validation && validation.errors[name] && (validation.touched[name] || isSubmitting.current)  && (validation.errors[name] as any)[index]) ? null :
            <FormFeedback className="d-block" type="invalid">{getErrorMessageGroup(validation?.errors, name)}</FormFeedback>
        }
      </div>
    </>
  );
};



export const FormikInputVariant = memo(FormikInputVariantComponent);