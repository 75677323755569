import { Container, Row, Col, Button } from "reactstrap";
import errorImg from "../../assets/images/error-img.png";
import { Link } from "react-router-dom";
import { createLinkEmbedParam } from "utils/embed";

export default function Page404({ error, resetErrorBoundary }: any) {
  return (
    <Container>
      <Row>
        <Col lg="12">
          <div className="text-center mb-5">
            <h1 className="display-2 font-weight-medium">
              4<i className="bx bx-buoy bx-spin text-primary display-3" />4
            </h1>
            <h4 className="text-uppercase">Sorry, Page Not Found</h4>
            { !error ? null :
              <p className="mt-5 text-center">{error}</p>
            }
            <div className="mt-5 text-center">
              {
                resetErrorBoundary ?
                  (
                    <Button className="btn btn-primary" onClick={resetErrorBoundary}>
                      Try Again
                    </Button>
                  )
                  :
                  (
                    <Link {...createLinkEmbedParam("/")}>
                      <Button className="btn btn-primary">
                        Back to Home
                      </Button>
                    </Link>
                  )
              }
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8" xl="6">
          <div>
            <img src={errorImg} alt="" className="img-fluid" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
