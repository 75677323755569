import { RequestOptions } from 'core/rest-api/rest.api';
import { withPermission } from "common/hooks/use-permission";
import { Logger } from "core/logger";
import { Filter, QueryParams } from "core/rest-api/query-builder";
import { CreateProductTemplateDto, ProductTemplateDto, ProductTemplateService, ProductTemplateServices, UpdateProductTemplateDto } from "data/services/product-template.service";
import { ActionEntities, ResourceEntities } from "types/permission-type";
import { CrudActions } from "./crud.action";



class ProductTemplateAction extends CrudActions<ProductTemplateService, CreateProductTemplateDto, UpdateProductTemplateDto> {
  constructor() {
    super(new ProductTemplateService());
  }
  
  async searchByProduceType(searchText: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.productTemplateEntity,
      async () => {
        let filter: Filter<any> = {};
        if (searchText) {
          filter.code = { contains: searchText };
        }
        let pageData = await ProductTemplateServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }
  

  async searchByProductTemplateByName(searchText: string) {
    return withPermission(
      ActionEntities.read,
      ResourceEntities.productTemplateEntity,
      async () => {
        let filter: Filter<ProductTemplateDto> = {};
        if (searchText) {
          filter.name = { contains: searchText };
        }
        let pageData = await ProductTemplateServices.query({ filter, page: 1 });
        return pageData?.pageItems || [];
      })
  }

  async searchByCode(searchText: string ) {
    let filter: Filter<any> = {};
    if (searchText) {
      let newValue: any = searchText.split(',').map((option: string) => option.trim()).filter((item) => item)
      if(newValue.length == 1){
        newValue = newValue[0]
      }
      filter.code = { contains: newValue };
    }
    let pageData = await ProductTemplateServices.query({ filter, page: 1 });
    return pageData?.pageItems || [];
  }

  async getById (productTemplateId: number, options: RequestOptions) {
    let data: any = await ProductTemplateServices.getById(productTemplateId, options);
    return data;
  }

  async query (params: QueryParams<any>, options?: RequestOptions) {
    let pageData = await ProductTemplateServices.query(params, { snoozeErrorMessage: false });
    return pageData;
  }

  async getVariantOptionsSuggestion() {
    let response = await ProductTemplateServices.getVariantOptionsSuggestion()
    return response
  }
}

export const ProductTemplateActions = new ProductTemplateAction();
