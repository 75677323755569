import { EventEmitter } from "events";

export const appEventEmitter = new EventEmitter()
export const EventName = {
  ERROR: 'error'
}

export const AppErrorCode = {
  UNKNOWN: 0
}
export interface FallbackError {
  handled?: boolean,
  status?: number, // This only using for http error status code
  message?: string
}
